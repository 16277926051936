export const italian = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
  '60',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '69',
  '70',
  '71',
  '72',
  '73',
  '74',
  '75',
  '76',
  '77',
  '78',
  '79',
  '80',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '88',
  '89',
  '90',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '97',
  '98',
  '99',
  '100',
  '101',
  '111',
  '123',
  '200',
  '222',
  '234',
  '300',
  '333',
  '345',
  '400',
  '444',
  '456',
  '500',
  '555',
  '567',
  '600',
  '666',
  '678',
  '700',
  '777',
  '789',
  '800',
  '888',
  '900',
  '999',
  '1000',
  '1111',
  '1234',
  '1492',
  '1500',
  '1600',
  '1700',
  '1800',
  '1900',
  '1910',
  '1920',
  '1925',
  '1930',
  '1935',
  '1940',
  '1945',
  '1950',
  '1955',
  '1960',
  '1965',
  '1970',
  '1975',
  '1980',
  '1985',
  '1990',
  '1991',
  '1992',
  '1993',
  '1994',
  '1995',
  '1996',
  '1997',
  '1998',
  '1999',
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2015',
  '2020',
  '2030',
  '2035',
  '2040',
  '2045',
  '2050',
  '2222',
  '2345',
  '2468',
  '3000',
  '3333',
  '3456',
  '4000',
  '4321',
  '4444',
  '4567',
  '5000',
  '5555',
  '5678',
  '6000',
  '6666',
  '6789',
  '7000',
  '7777',
  '8000',
  '8888',
  '9000',
  '9876',
  '9999',
  '-',
  '--',
  '---',
  '!',
  '!!',
  '!!!',
  '""""',
  '#',
  '##',
  '###',
  '$',
  '$$',
  '$$$',
  '%',
  '%%',
  '%%%',
  '&',
  '(',
  '()',
  ')',
  '*',
  '**',
  '***',
  ':',
  ':(',
  ':-(',
  ':)',
  ':-)',
  '";"',
  '?',
  '??',
  '???',
  '@',
  '+',
  '++',
  '+++',
  '=',
  '==',
  '===',
  'a',
  'aa',
  'aaa',
  'aaaa',
  'ab',
  'abate',
  'abati',
  'abbai',
  'abbaia',
  'abbaio',
  'abbi',
  'abbia',
  'abbina',
  'abbini',
  'abbino',
  'abbona',
  'abboni',
  'abbono',
  'abc',
  'abdica',
  'abdico',
  'abete',
  'abeti',
  'abile',
  'abili',
  'abissi',
  'abisso',
  'abiti',
  'abito',
  'abitua',
  'abitui',
  'abituo',
  'abiura',
  'abiuri',
  'abiuro',
  'abolii',
  'aborti',
  'abroga',
  'abrogo',
  'abusa',
  'abusai',
  'abusi',
  'abuso',
  'ac',
  'accada',
  'accade',
  'accasa',
  'accasi',
  'accaso',
  'acceca',
  'acceco',
  'accede',
  'accesa',
  'accese',
  'accesi',
  'acceso',
  'acciai',
  'accoda',
  'accusa',
  'accusi',
  'accuso',
  'acerbe',
  'acerbi',
  'acerbo',
  'aceti',
  'acida',
  'acide',
  'acidi',
  'acido',
  'acqua',
  'acque',
  'acuta',
  'acute',
  'acuti',
  'acuto',
  'ad',
  'adagi',
  'adagia',
  'adagio',
  'adatta',
  'adatte',
  'adatti',
  'adatto',
  'addii',
  'addio',
  'addita',
  'additi',
  'addito',
  'addome',
  'addomi',
  'adegua',
  'adegui',
  'adeguo',
  'adesso',
  'adora',
  'adorai',
  'adorna',
  'adorni',
  'adoro',
  'adotta',
  'adotti',
  'adotto',
  'adula',
  'adulai',
  'aduli',
  'adulo',
  'adulta',
  'adulte',
  'adulti',
  'adulto',
  'aduna',
  'adunai',
  'aduni',
  'aduno',
  'ae',
  'aerea',
  'aeree',
  'aerei',
  'aereo',
  'af',
  'afa',
  'affama',
  'affami',
  'affamo',
  'affare',
  'affari',
  'affida',
  'affidi',
  'affido',
  'affila',
  'affili',
  'affilo',
  'affine',
  'affini',
  'affoga',
  'affogo',
  'afona',
  'afone',
  'afoni',
  'afono',
  'afosa',
  'afose',
  'afosi',
  'afoso',
  'ag',
  'agenda',
  'agende',
  'agente',
  'agenti',
  'aggira',
  'aggiri',
  'aggiro',
  'aghi',
  'agi',
  'agiata',
  'agiate',
  'agiato',
  'agile',
  'agili',
  'agio',
  'agire',
  'agisca',
  'agita',
  'agitai',
  'agiti',
  'agito',
  'agli',
  'aglio',
  'ago',
  'agonia',
  'agonie',
  'agosto',
  'agrume',
  'agrumi',
  'aguzza',
  'aguzzi',
  'aguzzo',
  'ah',
  'ai',
  'aiuole',
  'aiuta',
  'aiutai',
  'aiuti',
  'aiuto',
  'aj',
  'ak',
  'al',
  'alba',
  'albe',
  'alberi',
  'albero',
  'albina',
  'albine',
  'albino',
  'alcuna',
  'alcune',
  'alcuni',
  'alfa',
  'alga',
  'alghe',
  'ali',
  'aliena',
  'alieni',
  'alieno',
  'aliti',
  'alito',
  'allaga',
  'allago',
  'alle',
  'allega',
  'allego',
  'allena',
  'alleni',
  'alleno',
  'alleva',
  'allevi',
  'allevo',
  'allo',
  'allora',
  'allori',
  'alloro',
  'alluce',
  'alluci',
  'almeno',
  'alta',
  'altare',
  'altari',
  'alte',
  'altero',
  'alti',
  'alto',
  'altra',
  'altre',
  'altri',
  'altro',
  'altrui',
  'altura',
  'alture',
  'alunna',
  'alunne',
  'alunni',
  'alunno',
  'alveo',
  'alza',
  'alzai',
  'alzano',
  'alzare',
  'alzata',
  'alzate',
  'alzato',
  'alzava',
  'alzavi',
  'alzavo',
  'alzi',
  'alzino',
  'alzo',
  'am',
  'ama',
  'amaca',
  'amache',
  'amai',
  'amammo',
  'amando',
  'amano',
  'amante',
  'amanti',
  'amara',
  'amare',
  'amari',
  'amaro',
  'amasse',
  'amassi',
  'amaste',
  'amasti',
  'amata',
  'amate',
  'amato',
  'amava',
  'amavi',
  'amavo',
  'ambito',
  'amena',
  'amene',
  'ameni',
  'ameno',
  'amerai',
  'amerei',
  'amiamo',
  'amiate',
  'amica',
  'amiche',
  'amici',
  'amico',
  'amido',
  'ammala',
  'ammali',
  'ammalo',
  'ammira',
  'ammiri',
  'ammiro',
  'amo',
  'amore',
  'amorfe',
  'amorfi',
  'amorfo',
  'amori',
  'ampi',
  'ampie',
  'ampio',
  'ampli',
  'amplia',
  'amplio',
  'an',
  'anatra',
  'anatre',
  'anca',
  'anche',
  'ancora',
  'ancore',
  'andai',
  'andare',
  'andata',
  'andate',
  'andati',
  'andato',
  'andava',
  'andavi',
  'andavo',
  'andrai',
  'andrei',
  'anelli',
  'anello',
  'anfora',
  'anfore',
  'angeli',
  'angelo',
  'angolo',
  'anima',
  'animai',
  'anime',
  'animi',
  'animo',
  'annate',
  'annega',
  'annego',
  'anni',
  'annidi',
  'annido',
  'anno',
  'annoda',
  'annodi',
  'annodo',
  'annoi',
  'annoia',
  'annoio',
  'annota',
  'annoti',
  'annoto',
  'annusa',
  'annusi',
  'annuso',
  'ansia',
  'ansie',
  'ante',
  'antico',
  'antri',
  'antro',
  'anzi',
  'ao',
  'ap',
  'apatia',
  'apatie',
  'ape',
  'aperse',
  'aperta',
  'aperte',
  'aperti',
  'aperto',
  'api',
  'apice',
  'apici',
  'appaia',
  'appaio',
  'appare',
  'appari',
  'appena',
  'appura',
  'appuri',
  'appuro',
  'apra',
  'apre',
  'apri',
  'aprii',
  'aprile',
  'aprilo',
  'aprire',
  'aprite',
  'apriva',
  'apro',
  'aprono',
  'aq',
  'aquila',
  'aquile',
  'ar',
  'ara',
  'arai',
  'arammo',
  'arance',
  'arando',
  'arano',
  'arare',
  'arasse',
  'arassi',
  'araste',
  'arasti',
  'arata',
  'arate',
  'arati',
  'arato',
  'aratri',
  'aratro',
  'aravi',
  'arcata',
  'archi',
  'arco',
  'ardore',
  'ardori',
  'ardua',
  'ardue',
  'ardui',
  'arduo',
  'area',
  'aree',
  'arerai',
  'arerei',
  'argina',
  'argine',
  'argini',
  'argute',
  'arguti',
  'arguto',
  'ari',
  'aria',
  'ariamo',
  'ariate',
  'arida',
  'aride',
  'aridi',
  'arido',
  'arie',
  'arino',
  'ariosa',
  'ariose',
  'ariosi',
  'arioso',
  'arma',
  'armadi',
  'armai',
  'armano',
  'armare',
  'armata',
  'armate',
  'armati',
  'armato',
  'armava',
  'armavi',
  'armavo',
  'armeno',
  'armi',
  'armino',
  'arnese',
  'arnesi',
  'arnia',
  'aro',
  'aroma',
  'aromi',
  'arpe',
  'arreda',
  'arredi',
  'arredo',
  'arrivi',
  'arrivo',
  'arroga',
  'arrogo',
  'arrota',
  'arroti',
  'arte',
  'arti',
  'arto',
  'as',
  'ascia',
  'asili',
  'asilo',
  'asina',
  'asine',
  'asini',
  'asino',
  'asola',
  'aspira',
  'aspiri',
  'aspiro',
  'aspra',
  'aspre',
  'aspri',
  'aspro',
  'asse',
  'assedi',
  'assi',
  'asso',
  'associ',
  'assoda',
  'assodi',
  'assodo',
  'assuma',
  'assume',
  'astri',
  'astro',
  'astuta',
  'astute',
  'astuti',
  'astuto',
  'at',
  'atleta',
  'atleti',
  'atomi',
  'atomo',
  'atri',
  'atrii',
  'atroce',
  'atroci',
  'attesa',
  'attese',
  'attesi',
  'atti',
  'attimo',
  'attiri',
  'attiro',
  'attiva',
  'attive',
  'attivi',
  'attivo',
  'atto',
  'attore',
  'attori',
  'attua',
  'attuai',
  'attui',
  'attuo',
  'au',
  'audace',
  'audaci',
  'augura',
  'auguri',
  'auguro',
  'aula',
  'aule',
  'auree',
  'aurei',
  'aureo',
  'aurora',
  'aurore',
  'auto',
  'automa',
  'automi',
  'autore',
  'autori',
  'av',
  'avalla',
  'avallo',
  'avanti',
  'avanza',
  'avanzi',
  'avanzo',
  'avara',
  'avare',
  'avari',
  'avaria',
  'avarie',
  'avendo',
  'avente',
  'avere',
  'averla',
  'avesse',
  'avessi',
  'aveste',
  'avete',
  'aveva',
  'avevi',
  'avida',
  'avide',
  'avidi',
  'avido',
  'avrai',
  'avrei',
  'avuto',
  'avvale',
  'avvera',
  'avveri',
  'avvero',
  'avvia',
  'avviai',
  'avvii',
  'avvino',
  'avvio',
  'avvisi',
  'avviso',
  'avvita',
  'avviti',
  'avvito',
  'aw',
  'ax',
  'ay',
  'az',
  'azioni',
  'b',
  'babbo',
  'bachi',
  'baci',
  'bacia',
  'baciai',
  'bacini',
  'bacino',
  'bacio',
  'baco',
  'bada',
  'badai',
  'badano',
  'badare',
  'badata',
  'badate',
  'badati',
  'badato',
  'badava',
  'badavi',
  'badavo',
  'badi',
  'badia',
  'badile',
  'badili',
  'badino',
  'bado',
  'baffi',
  'baffo',
  'bagna',
  'bagnai',
  'bagni',
  'bagno',
  'bah',
  'baia',
  'baie',
  'balena',
  'balene',
  'baleni',
  'baleno',
  'balia',
  'balla',
  'ballai',
  'balli',
  'ballo',
  'balza',
  'balzi',
  'balzo',
  'banale',
  'banali',
  'banana',
  'banane',
  'banca',
  'banche',
  'banchi',
  'banda',
  'bande',
  'bandi',
  'bandii',
  'bara',
  'barai',
  'barano',
  'barare',
  'barata',
  'barate',
  'barati',
  'barato',
  'barava',
  'baravo',
  'barba',
  'barbe',
  'barca',
  'barche',
  'bari',
  'barile',
  'barili',
  'barino',
  'bario',
  'baro',
  'baroni',
  'barra',
  'barre',
  'basa',
  'basare',
  'basata',
  'basate',
  'basati',
  'basato',
  'basava',
  'basavi',
  'basavo',
  'base',
  'basi',
  'basino',
  'basico',
  'basito',
  'bassa',
  'basso',
  'basta',
  'bastai',
  'basti',
  'basto',
  'batta',
  'batte',
  'battei',
  'batti',
  'batto',
  'baule',
  'bauli',
  'bava',
  'bave',
  'baveri',
  'bavero',
  'bb',
  'bbb',
  'bbbb',
  'bc',
  'bcd',
  'bd',
  'be',
  'beata',
  'beate',
  'beati',
  'becchi',
  'becco',
  'beffa',
  'beffe',
  'bella',
  'belle',
  'belli',
  'bello',
  'belva',
  'belve',
  'benda',
  'bendai',
  'bende',
  'bendi',
  'bendo',
  'bene',
  'beni',
  'beone',
  'bere',
  'bestia',
  'bestie',
  'beva',
  'beve',
  'bevete',
  'beveva',
  'bevi',
  'bevilo',
  'bevine',
  'bevo',
  'bevono',
  'bevuto',
  'bf',
  'bg',
  'bh',
  'bi',
  'bianca',
  'bianco',
  'bibbia',
  'bibbie',
  'bibita',
  'bidone',
  'bidoni',
  'biechi',
  'bile',
  'bili',
  'bimba',
  'bimbe',
  'bimbi',
  'bimbo',
  'binari',
  'bionda',
  'bionde',
  'biondi',
  'biondo',
  'birra',
  'birre',
  'bisca',
  'bisce',
  'bische',
  'biscia',
  'bj',
  'bk',
  'bl',
  'blanda',
  'blande',
  'blandi',
  'blando',
  'blocca',
  'blu',
  'bm',
  'bn',
  'bo',
  'bocca',
  'bocce',
  'bocche',
  'bocci',
  'boccia',
  'boccio',
  'bolla',
  'bolle',
  'bolli',
  'bollo',
  'bomba',
  'bombe',
  'bordi',
  'bordo',
  'borghi',
  'borgo',
  'borie',
  'borsa',
  'borse',
  'boschi',
  'botola',
  'botole',
  'botta',
  'botte',
  'bozza',
  'bozze',
  'bp',
  'bq',
  'br',
  'bracco',
  'brace',
  'brama',
  'brami',
  'bramo',
  'branca',
  'branda',
  'brande',
  'brani',
  'brano',
  'brava',
  'brave',
  'bravi',
  'breve',
  'brevi',
  'brezza',
  'brezze',
  'bricco',
  'brilla',
  'brilli',
  'brillo',
  'brina',
  'brinda',
  'brindi',
  'brindo',
  'brine',
  'brio',
  'brocca',
  'brodi',
  'brodo',
  'bronci',
  'bronzi',
  'bronzo',
  'bruci',
  'brucia',
  'brucio',
  'bruco',
  'brulla',
  'brulle',
  'brulli',
  'brullo',
  'bruna',
  'brune',
  'bruni',
  'bruno',
  'brusca',
  'brusco',
  'bruti',
  'brutti',
  'brutto',
  'bs',
  'bt',
  'bu',
  'buca',
  'bucce',
  'buccia',
  'buche',
  'buchi',
  'buco',
  'budini',
  'budino',
  'bue',
  'bufera',
  'bufere',
  'buffa',
  'buffi',
  'buffo',
  'bugia',
  'bugie',
  'buio',
  'bulbi',
  'bulbo',
  'buoi',
  'buona',
  'buone',
  'buoni',
  'buono',
  'burla',
  'burlai',
  'burle',
  'burli',
  'burlo',
  'burro',
  'bussa',
  'bussi',
  'busso',
  'busti',
  'busto',
  'butta',
  'buttai',
  'butti',
  'butto',
  'bv',
  'bw',
  'bx',
  'by',
  'bz',
  'c',
  'cabina',
  'cabine',
  'cacao',
  'caccia',
  'caccio',
  'cadde',
  'caddi',
  'cade',
  'cadere',
  'cadeva',
  'cadono',
  'caduta',
  'cadute',
  'caduti',
  'caduto',
  'cagna',
  'cagne',
  'cala',
  'calai',
  'calano',
  'calare',
  'calata',
  'calate',
  'calati',
  'calava',
  'calavi',
  'calavo',
  'calci',
  'calcio',
  'calco',
  'calda',
  'calde',
  'caldi',
  'caldo',
  'cali',
  'calice',
  'calici',
  'calino',
  'calli',
  'callo',
  'calma',
  'calmai',
  'calme',
  'calmi',
  'calmo',
  'calo',
  'calore',
  'calori',
  'calva',
  'calve',
  'calvi',
  'calvo',
  'calza',
  'calzai',
  'calzi',
  'calzo',
  'cambi',
  'cambia',
  'cambio',
  'camera',
  'camere',
  'camini',
  'camino',
  'campa',
  'campai',
  'campi',
  'campo',
  'canale',
  'canali',
  'canapa',
  'canape',
  'cane',
  'cani',
  'canile',
  'canili',
  'canne',
  'canora',
  'canore',
  'canori',
  'canoro',
  'canta',
  'cantai',
  'canti',
  'canuta',
  'canute',
  'canuti',
  'canuto',
  'capace',
  'capaci',
  'capi',
  'capire',
  'capita',
  'capito',
  'capo',
  'capra',
  'capre',
  'cara',
  'care',
  'carena',
  'carene',
  'cari',
  'carica',
  'carico',
  'carina',
  'carine',
  'carini',
  'carino',
  'carne',
  'carni',
  'caro',
  'carota',
  'carote',
  'carri',
  'carta',
  'carte',
  'casa',
  'caschi',
  'case',
  'casi',
  'caso',
  'cassa',
  'casse',
  'casta',
  'caste',
  'casti',
  'casto',
  'catena',
  'catene',
  'catini',
  'catino',
  'causa',
  'causai',
  'cause',
  'causi',
  'cauta',
  'caute',
  'cauti',
  'cava',
  'cavai',
  'cavano',
  'cavare',
  'cavata',
  'cavati',
  'cavato',
  'cavava',
  'cavavi',
  'cavavo',
  'cave',
  'cavoli',
  'cavolo',
  'cb',
  'cc',
  'ccc',
  'cccc',
  'cd',
  'cde',
  'ce',
  'cede',
  'cedere',
  'cedola',
  'cedole',
  'cedono',
  'cedri',
  'cedro',
  'ceduta',
  'cedute',
  'ceffo',
  'celai',
  'celano',
  'celare',
  'celate',
  'celati',
  'celato',
  'celava',
  'celavi',
  'celavo',
  'celere',
  'celeri',
  'celibe',
  'celibi',
  'celino',
  'cella',
  'celle',
  'cena',
  'cenare',
  'cenata',
  'cenate',
  'cenati',
  'cenava',
  'cenavi',
  'cene',
  'cenere',
  'ceneri',
  'ceni',
  'cenni',
  'cenno',
  'ceno',
  'cento',
  'centra',
  'centro',
  'ceppo',
  'cera',
  'cerca',
  'cerchi',
  'cerco',
  'cere',
  'ceri',
  'cerini',
  'cerino',
  'cero',
  'certa',
  'certe',
  'certi',
  'certo',
  'cervi',
  'cesoia',
  'cessa',
  'cessai',
  'cesta',
  'cesti',
  'cetra',
  'cetre',
  'cf',
  'cg',
  'ch',
  'chi',
  'chiama',
  'chiami',
  'chiamo',
  'chiara',
  'chiare',
  'chiari',
  'chiaro',
  'chiave',
  'chiavi',
  'chieda',
  'chiede',
  'chiedo',
  'chiesa',
  'chiese',
  'chiesi',
  'china',
  'chinai',
  'chine',
  'chini',
  'chino',
  'chiodi',
  'chiodo',
  'chioma',
  'chiome',
  'chiuda',
  'chiude',
  'chiudi',
  'chiudo',
  'chiusa',
  'chiusi',
  'chiuso',
  'ci',
  'cialda',
  'cialde',
  'ciao',
  'cibi',
  'cibo',
  'cicca',
  'cicli',
  'ciclo',
  'cicuta',
  'cicute',
  'cieca',
  'cieche',
  'ciechi',
  'cieco',
  'cielo',
  'cifra',
  'cifre',
  'ciglia',
  'ciglio',
  'cigni',
  'cigno',
  'cigola',
  'cigoli',
  'cigolo',
  'cima',
  'cime',
  'cimice',
  'cimici',
  'cinema',
  'cinica',
  'cinici',
  'cinico',
  'cinque',
  'cinse',
  'cinta',
  'cinto',
  'ciocca',
  'cipria',
  'circa',
  'circo',
  'cirro',
  'cisti',
  'cita',
  'citai',
  'citano',
  'citare',
  'citata',
  'citate',
  'citati',
  'citato',
  'citava',
  'citavi',
  'citavo',
  'citi',
  'citino',
  'cito',
  'ciuffi',
  'ciuffo',
  'ciurma',
  'ciurme',
  'civile',
  'civili',
  'cj',
  'ck',
  'cl',
  'classe',
  'classi',
  'clero',
  'clima',
  'climi',
  'cm',
  'cn',
  'co',
  'coda',
  'code',
  'codice',
  'codici',
  'cofani',
  'cofano',
  'coglie',
  'cola',
  'colai',
  'colano',
  'colare',
  'colata',
  'colate',
  'colati',
  'colato',
  'colava',
  'colavi',
  'colavo',
  'colera',
  'coli',
  'colini',
  'colino',
  'colla',
  'colle',
  'colli',
  'collo',
  'colma',
  'colmai',
  'colme',
  'colmo',
  'colo',
  'coloni',
  'colore',
  'colori',
  'coloro',
  'colpa',
  'colpe',
  'colpi',
  'colpii',
  'colse',
  'colta',
  'colti',
  'colto',
  'cometa',
  'comete',
  'comica',
  'comici',
  'comico',
  'comizi',
  'comoda',
  'comode',
  'comodi',
  'comodo',
  'compie',
  'compri',
  'compro',
  'comune',
  'comuni',
  'concia',
  'concio',
  'condii',
  'coni',
  'conia',
  'coniai',
  'conio',
  'cono',
  'conta',
  'contai',
  'conte',
  'conti',
  'contro',
  'copia',
  'copiai',
  'copie',
  'coppia',
  'coppie',
  'copra',
  'copre',
  'copri',
  'copro',
  'corda',
  'corde',
  'cori',
  'corna',
  'corno',
  'coro',
  'corona',
  'corone',
  'coroni',
  'corono',
  'corpi',
  'corpo',
  'corra',
  'corre',
  'corri',
  'corro',
  'corsa',
  'corse',
  'corsi',
  'corsia',
  'corsie',
  'corso',
  'corte',
  'cortei',
  'corteo',
  'corto',
  'corvi',
  'corvo',
  'cosa',
  'cosca',
  'cosce',
  'coscia',
  'cose',
  'coseno',
  'cosmo',
  'coso',
  'costa',
  'costai',
  'coste',
  'costei',
  'costo',
  'costui',
  'cotone',
  'cotta',
  'cotte',
  'cotti',
  'cotto',
  'cova',
  'covai',
  'covano',
  'covata',
  'covate',
  'covati',
  'covato',
  'covava',
  'covavi',
  'covavo',
  'covi',
  'covino',
  'covo',
  'cozza',
  'cozze',
  'cozzi',
  'cozzo',
  'cp',
  'cq',
  'cr',
  'crampi',
  'crampo',
  'crani',
  'cranio',
  'crea',
  'creai',
  'creano',
  'creare',
  'creata',
  'create',
  'creato',
  'creava',
  'creavi',
  'creavo',
  'crebbe',
  'crebbi',
  'creda',
  'crede',
  'credei',
  'credi',
  'credo',
  'crei',
  'creino',
  'crema',
  'cremai',
  'creme',
  'cremi',
  'cremo',
  'creo',
  'crepa',
  'crepe',
  'crepi',
  'crepo',
  'cresca',
  'cresce',
  'cresci',
  'cresco',
  'crespa',
  'crespe',
  'crespi',
  'crespo',
  'cresta',
  'creste',
  'creta',
  'crete',
  'crisi',
  'cristo',
  'croce',
  'croci',
  'crolla',
  'crolli',
  'crollo',
  'crosta',
  'cruda',
  'crudi',
  'crudo',
  'cruna',
  'crune',
  'crusca',
  'cs',
  'ct',
  'cu',
  'cubi',
  'cubo',
  'cucina',
  'cucine',
  'cucini',
  'cuculi',
  'cuffia',
  'cuffie',
  'cugina',
  'cugine',
  'cugini',
  'cugino',
  'culla',
  'cullai',
  'culli',
  'culto',
  'cumulo',
  'cuoca',
  'cuoche',
  'cuochi',
  'cuocia',
  'cuoio',
  'cuore',
  'cuori',
  'cupa',
  'cupe',
  'cupi',
  'cupo',
  'cupola',
  'cupole',
  'cura',
  'curai',
  'curano',
  'curare',
  'curate',
  'curati',
  'curato',
  'curava',
  'curavi',
  'curavo',
  'cure',
  'curi',
  'curino',
  'curo',
  'curva',
  'curvai',
  'curve',
  'curvi',
  'curvo',
  'cute',
  'cv',
  'cw',
  'cx',
  'cy',
  'cz',
  'd',
  'dacci',
  'dadi',
  'dado',
  'dagli',
  'dai',
  'daini',
  'daino',
  'dalla',
  'dalle',
  'dallo',
  'dama',
  'dame',
  'dammi',
  'dannai',
  'danni',
  'danno',
  'danza',
  'danze',
  'danzi',
  'darai',
  'dare',
  'darei',
  'daremo',
  'dargli',
  'darsi',
  'darti',
  'darvi',
  'data',
  'datai',
  'datano',
  'datare',
  'datata',
  'datate',
  'datati',
  'datato',
  'datava',
  'datavi',
  'datavo',
  'date',
  'datelo',
  'datemi',
  'datevi',
  'dati',
  'datino',
  'dato',
  'datomi',
  'datosi',
  'dava',
  'davamo',
  'davano',
  'davo',
  'dazio',
  'db',
  'dc',
  'dd',
  'ddd',
  'dddd',
  'de',
  'dea',
  'debba',
  'debiti',
  'debito',
  'deboli',
  'decani',
  'decano',
  'decide',
  'decidi',
  'decine',
  'decisa',
  'decise',
  'decisi',
  'decori',
  'decoro',
  'dedica',
  'dedite',
  'dediti',
  'dedito',
  'dee',
  'def',
  'degli',
  'degna',
  'degnai',
  'degne',
  'degni',
  'degno',
  'dei',
  'delega',
  'delego',
  'delira',
  'deliri',
  'deliro',
  'delizi',
  'della',
  'deludo',
  'delusa',
  'deluso',
  'demmo',
  'demoni',
  'denari',
  'denaro',
  'densa',
  'dense',
  'denso',
  'dente',
  'denti',
  'dentro',
  'denuda',
  'denudi',
  'denudo',
  'depose',
  'depura',
  'depuro',
  'deride',
  'derido',
  'derise',
  'derisi',
  'deriva',
  'derivi',
  'derivo',
  'deroga',
  'deruba',
  'derubi',
  'derubo',
  'desse',
  'dessi',
  'desta',
  'destai',
  'desti',
  'desto',
  'destra',
  'desume',
  'desumi',
  'desumo',
  'detta',
  'dettai',
  'dette',
  'detti',
  'detto',
  'deve',
  'devi',
  'devo',
  'devono',
  'devota',
  'devote',
  'devoti',
  'devoto',
  'df',
  'dg',
  'dh',
  'di',
  'dia',
  'diamo',
  'diano',
  'diario',
  'dica',
  'dicano',
  'dicasi',
  'dicci',
  'dice',
  'diceva',
  'dicevo',
  'dici',
  'dico',
  'dicono',
  'dieci',
  'diede',
  'diedi',
  'dieta',
  'diete',
  'dietro',
  'difesa',
  'difese',
  'difesi',
  'difeso',
  'diga',
  'digita',
  'digiti',
  'digli',
  'dilani',
  'dilati',
  'dilato',
  'dilla',
  'dillo',
  'diluii',
  'diluvi',
  'dimena',
  'dimeni',
  'dimeno',
  'dinamo',
  'dinari',
  'dinne',
  'dipana',
  'dipani',
  'dipano',
  'dipesa',
  'dipese',
  'dipesi',
  'dipeso',
  'dirada',
  'diradi',
  'dirado',
  'dirai',
  'dirama',
  'dirami',
  'diramo',
  'dirci',
  'dire',
  'direi',
  'direte',
  'dirgli',
  'dirige',
  'dirigo',
  'dirla',
  'dirle',
  'dirlo',
  'dirmi',
  'dirne',
  'dirsi',
  'dirti',
  'dirupi',
  'dirupo',
  'dirvi',
  'disagi',
  'dischi',
  'disco',
  'disse',
  'dissi',
  'dista',
  'disti',
  'disto',
  'disuso',
  'dita',
  'ditale',
  'dite',
  'ditelo',
  'ditemi',
  'divaga',
  'divago',
  'divani',
  'divano',
  'dive',
  'divide',
  'dividi',
  'divina',
  'divine',
  'divini',
  'divino',
  'divisa',
  'divise',
  'divisi',
  'divora',
  'divori',
  'divoro',
  'dj',
  'dk',
  'dl',
  'dm',
  'dn',
  'do',
  'docce',
  'doccia',
  'docile',
  'docili',
  'dodici',
  'dogana',
  'dogane',
  'dogma',
  'dogmi',
  'dolce',
  'dolci',
  'dolore',
  'dolori',
  'dolosa',
  'dolose',
  'dolosi',
  'doloso',
  'domai',
  'domani',
  'domano',
  'domare',
  'domata',
  'domati',
  'domato',
  'domava',
  'domavo',
  'domina',
  'domini',
  'domino',
  'dona',
  'donai',
  'donare',
  'donate',
  'donati',
  'donato',
  'donava',
  'donavi',
  'donavo',
  'doni',
  'donino',
  'donna',
  'donne',
  'dono',
  'dopo',
  'doppia',
  'doppie',
  'doppio',
  'dorata',
  'dorate',
  'dorato',
  'dorma',
  'dorme',
  'dormi',
  'dormo',
  'dorsi',
  'dorso',
  'dosa',
  'dosai',
  'dosano',
  'dosare',
  'dosata',
  'dosate',
  'dosati',
  'dosato',
  'dosavi',
  'dosavo',
  'dosi',
  'dosino',
  'doso',
  'dota',
  'dotai',
  'dotano',
  'dotare',
  'dotata',
  'dotati',
  'dotato',
  'dotava',
  'dotavi',
  'dotavo',
  'dote',
  'doti',
  'dotino',
  'doto',
  'dotta',
  'dotte',
  'dotti',
  'dove',
  'dovere',
  'dovete',
  'doveva',
  'dovevi',
  'dovevo',
  'dovrai',
  'dovrei',
  'dovuta',
  'dovute',
  'dovuti',
  'dp',
  'dq',
  'dr',
  'draga',
  'draghe',
  'draghi',
  'drago',
  'dramma',
  'drammi',
  'drizza',
  'drizzi',
  'drizzo',
  'ds',
  'dt',
  'du',
  'dubbi',
  'dubbio',
  'dubita',
  'dubiti',
  'dubito',
  'duca',
  'duchi',
  'due',
  'duello',
  'duna',
  'dune',
  'duomi',
  'dura',
  'durai',
  'durano',
  'durare',
  'durata',
  'durate',
  'durati',
  'durato',
  'durava',
  'duravi',
  'duravo',
  'dure',
  'duri',
  'durino',
  'duro',
  'dv',
  'dw',
  'dx',
  'dy',
  'dz',
  'e',
  'eb',
  'ebano',
  'ec',
  'ecceda',
  'eccede',
  'eccedi',
  'eccedo',
  'eccita',
  'ecciti',
  'eccito',
  'ecco',
  'echi',
  'eco',
  'ed',
  'edera',
  'edere',
  'educo',
  'ee',
  'eee',
  'eeee',
  'ef',
  'efg',
  'eg',
  'egli',
  'eguale',
  'eguali',
  'eh',
  'ei',
  'ej',
  'ek',
  'el',
  'elegga',
  'elegge',
  'eleggi',
  'eleggo',
  'elenca',
  'elenco',
  'eletta',
  'elette',
  'eletti',
  'eletto',
  'elevai',
  'eliche',
  'ella',
  'elogi',
  'elogia',
  'elogio',
  'elude',
  'eludi',
  'eludo',
  'elusa',
  'eluse',
  'elusi',
  'eluso',
  'em',
  'emana',
  'emanai',
  'emani',
  'emano',
  'emboli',
  'embolo',
  'emenda',
  'emendi',
  'emendo',
  'emerge',
  'emergi',
  'emergo',
  'emerse',
  'emersi',
  'emerso',
  'emesso',
  'emette',
  'emigra',
  'emigri',
  'emigro',
  'emula',
  'emulai',
  'emuli',
  'emulo',
  'en',
  'enfasi',
  'enigma',
  'enorme',
  'enormi',
  'ente',
  'enti',
  'entra',
  'entrai',
  'entri',
  'entro',
  'eo',
  'ep',
  'epica',
  'epiche',
  'epici',
  'epico',
  'epoca',
  'epoche',
  'eppure',
  'epurai',
  'eq',
  'equa',
  'eque',
  'equi',
  'er',
  'era',
  'erano',
  'erba',
  'erbe',
  'ere',
  'eredi',
  'eresia',
  'eresie',
  'eresse',
  'eressi',
  'eretta',
  'erette',
  'eretti',
  'eretto',
  'erige',
  'erigi',
  'erigo',
  'eroe',
  'eroi',
  'errai',
  'errano',
  'errare',
  'errata',
  'errate',
  'errati',
  'errato',
  'errava',
  'erravi',
  'errino',
  'errore',
  'errori',
  'es',
  'esalta',
  'esalti',
  'esalto',
  'esame',
  'esatta',
  'esatte',
  'esatti',
  'esatto',
  'esca',
  'esce',
  'esci',
  'esco',
  'escono',
  'esegue',
  'esenti',
  'esige',
  'esiguo',
  'esile',
  'esili',
  'esilia',
  'esilio',
  'esimi',
  'esimo',
  'esista',
  'esiste',
  'esiti',
  'esito',
  'esorti',
  'esorto',
  'espia',
  'espiai',
  'espii',
  'espio',
  'essa',
  'esse',
  'essere',
  'esseri',
  'essi',
  'esso',
  'estate',
  'estati',
  'esteri',
  'estero',
  'estesa',
  'estive',
  'estivi',
  'estivo',
  'esula',
  'esule',
  'esuli',
  'esulta',
  'esulti',
  'esulto',
  'et',
  'etere',
  'eterna',
  'eterne',
  'eterni',
  'eterno',
  'etti',
  'etto',
  'eu',
  'ev',
  'evacua',
  'evacui',
  'evacuo',
  'evento',
  'evita',
  'evitai',
  'eviti',
  'evito',
  'evolve',
  'evviva',
  'ew',
  'ex',
  'ey',
  'ez',
  'f',
  'fabbro',
  'facce',
  'faccia',
  'faccio',
  'faceva',
  'facevi',
  'facile',
  'facili',
  'faggi',
  'faggio',
  'fagli',
  'fai',
  'falce',
  'falchi',
  'falci',
  'falcia',
  'falcio',
  'falda',
  'falde',
  'falla',
  'falle',
  'falsa',
  'false',
  'falsi',
  'fama',
  'fame',
  'fammi',
  'famosa',
  'famose',
  'famosi',
  'famoso',
  'fanale',
  'fanali',
  'fanghi',
  'fango',
  'fanne',
  'fanno',
  'fanti',
  'farci',
  'fare',
  'farei',
  'faremo',
  'farete',
  'fargli',
  'fari',
  'farina',
  'farine',
  'farla',
  'farle',
  'farli',
  'farlo',
  'farmi',
  'farne',
  'farsi',
  'farti',
  'fasce',
  'fasci',
  'fascia',
  'fascio',
  'fase',
  'fata',
  'fatale',
  'fatali',
  'fate',
  'fateci',
  'fatela',
  'fatelo',
  'fatene',
  'fatevi',
  'fatica',
  'fatico',
  'fato',
  'fatte',
  'fatti',
  'fatto',
  'favola',
  'favole',
  'favori',
  'fb',
  'fc',
  'fd',
  'fe',
  'febbre',
  'febbri',
  'fecero',
  'fede',
  'fedele',
  'fedeli',
  'federa',
  'federe',
  'fedi',
  'fegati',
  'fegato',
  'felice',
  'feltri',
  'feltro',
  'fende',
  'fendi',
  'fendo',
  'fenice',
  'ferii',
  'ferire',
  'ferita',
  'ferite',
  'feriti',
  'feriva',
  'ferivo',
  'ferma',
  'ferme',
  'fermi',
  'fermo',
  'feroce',
  'feroci',
  'ferri',
  'ferro',
  'festa',
  'fetida',
  'fetide',
  'fetidi',
  'fetido',
  'fetta',
  'fette',
  'ff',
  'fff',
  'ffff',
  'fg',
  'fgh',
  'fh',
  'fi',
  'fiacca',
  'fiacco',
  'fiamma',
  'fichi',
  'fico',
  'fidata',
  'fidate',
  'fidati',
  'fidato',
  'fieni',
  'fieno',
  'fiera',
  'fiere',
  'fieri',
  'fiero',
  'figli',
  'figlia',
  'figlie',
  'figlio',
  'figura',
  'figure',
  'fila',
  'filai',
  'filano',
  'filare',
  'filate',
  'filati',
  'filato',
  'filava',
  'filavi',
  'filavo',
  'file',
  'fili',
  'filino',
  'filo',
  'filtri',
  'filtro',
  'finale',
  'fine',
  'finge',
  'fingi',
  'fingo',
  'fini',
  'finii',
  'finire',
  'finita',
  'finite',
  'finiti',
  'finito',
  'finiva',
  'finivi',
  'fino',
  'finora',
  'finse',
  'finsi',
  'finto',
  'fiocco',
  'fiore',
  'firma',
  'firmai',
  'firme',
  'firmi',
  'firmo',
  'fischi',
  'fisco',
  'fisica',
  'fisici',
  'fissa',
  'fissai',
  'fisse',
  'fissi',
  'fisso',
  'fitta',
  'fitte',
  'fitti',
  'fitto',
  'fiume',
  'fiumi',
  'fiuta',
  'fiutai',
  'fiuti',
  'fiuto',
  'fj',
  'fk',
  'fl',
  'flauti',
  'flauto',
  'flotte',
  'fluano',
  'fluidi',
  'fluido',
  'fluii',
  'fluita',
  'fluite',
  'fluiti',
  'fluito',
  'fluiva',
  'fluivi',
  'fluoro',
  'flussi',
  'flutti',
  'flutto',
  'fm',
  'fn',
  'fo',
  'foca',
  'foche',
  'foci',
  'focosa',
  'focose',
  'focosi',
  'fodera',
  'fodere',
  'foderi',
  'fodero',
  'fogli',
  'foglie',
  'fogne',
  'folla',
  'folle',
  'follie',
  'folta',
  'folte',
  'folti',
  'folto',
  'fonda',
  'fondai',
  'fondi',
  'fondo',
  'fora',
  'forai',
  'forano',
  'forare',
  'forata',
  'forate',
  'forati',
  'forato',
  'forava',
  'foravo',
  'fori',
  'forino',
  'forma',
  'formai',
  'forme',
  'formi',
  'formo',
  'fornai',
  'forni',
  'fornii',
  'forno',
  'foro',
  'forse',
  'forte',
  'forti',
  'forza',
  'forzai',
  'forze',
  'forzi',
  'forzo',
  'fosco',
  'fossa',
  'fosse',
  'fossi',
  'foste',
  'fosti',
  'foto',
  'fp',
  'fq',
  'fr',
  'fra',
  'frana',
  'franai',
  'franco',
  'frane',
  'frano',
  'frase',
  'frasi',
  'frate',
  'frati',
  'frecce',
  'fredda',
  'fredde',
  'freddi',
  'freddo',
  'freni',
  'freno',
  'fresca',
  'fresco',
  'fretta',
  'fritte',
  'frodai',
  'frodi',
  'frodo',
  'fronte',
  'frugai',
  'frughi',
  'frugo',
  'frulla',
  'frullo',
  'frusta',
  'fruste',
  'frusti',
  'frusto',
  'frutta',
  'frutti',
  'frutto',
  'fs',
  'ft',
  'fu',
  'fucila',
  'fucile',
  'fucili',
  'fucilo',
  'fuga',
  'fugai',
  'fugano',
  'fugare',
  'fugata',
  'fugate',
  'fugato',
  'fugava',
  'fugavi',
  'fugavo',
  'fugga',
  'fugge',
  'fuggi',
  'fughe',
  'fugo',
  'fui',
  'fuma',
  'fumai',
  'fumano',
  'fumare',
  'fumata',
  'fumate',
  'fumati',
  'fumato',
  'fumava',
  'fumavi',
  'fumavo',
  'fumi',
  'fumino',
  'fummo',
  'fumo',
  'funge',
  'funghi',
  'fungo',
  'fuochi',
  'fuoco',
  'fuori',
  'furba',
  'furbe',
  'furbo',
  'furono',
  'furore',
  'furori',
  'furti',
  'furto',
  'fusa',
  'fusaro',
  'fuse',
  'fuso',
  'fusti',
  'fusto',
  'futile',
  'futili',
  'futuri',
  'futuro',
  'fv',
  'fw',
  'fx',
  'fy',
  'fz',
  'g',
  'gabbia',
  'gabbie',
  'gaia',
  'gaie',
  'gaio',
  'galera',
  'galere',
  'galli',
  'gallo',
  'gamba',
  'gambe',
  'gambi',
  'gambo',
  'gamma',
  'ganci',
  'gancio',
  'gara',
  'garbo',
  'gare',
  'garza',
  'garze',
  'gb',
  'gc',
  'gd',
  'ge',
  'gela',
  'gelai',
  'gelano',
  'gelare',
  'gelata',
  'gelate',
  'gelati',
  'gelato',
  'gelava',
  'gelavi',
  'gelavo',
  'geli',
  'gelida',
  'gelide',
  'gelidi',
  'gelido',
  'gelino',
  'gelo',
  'gelone',
  'geloni',
  'gelosa',
  'geloso',
  'gelsi',
  'gelso',
  'gemiti',
  'gemito',
  'gemma',
  'gemme',
  'gene',
  'genere',
  'genero',
  'geni',
  'gente',
  'gerani',
  'gerghi',
  'gergo',
  'germe',
  'germi',
  'gessi',
  'gesso',
  'gesti',
  'gesto',
  'getta',
  'gettai',
  'getti',
  'getto',
  'gf',
  'gg',
  'ggg',
  'gggg',
  'gh',
  'ghi',
  'ghiaia',
  'ghiaie',
  'ghisa',
  'gi',
  'giacca',
  'giace',
  'gialla',
  'gialli',
  'giallo',
  'giara',
  'giare',
  'gigli',
  'giglio',
  'gioano',
  'gioca',
  'giocai',
  'giochi',
  'gioco',
  'gioghi',
  'giogo',
  'gioia',
  'gioie',
  'gioii',
  'gioire',
  'gioite',
  'gioiva',
  'gioivi',
  'gioivo',
  'giorno',
  'giova',
  'giovo',
  'girai',
  'girare',
  'girate',
  'girati',
  'girato',
  'girava',
  'giravi',
  'giravo',
  'giri',
  'girino',
  'giro',
  'gita',
  'gite',
  'giugno',
  'giunco',
  'giunga',
  'giunge',
  'giunse',
  'giunsi',
  'giunta',
  'giunte',
  'giunti',
  'giunto',
  'giura',
  'giurai',
  'giuri',
  'giuria',
  'giurie',
  'giuro',
  'giuste',
  'giusto',
  'gj',
  'gk',
  'gl',
  'gli',
  'globi',
  'globo',
  'gloria',
  'gm',
  'gn',
  'go',
  'gobba',
  'gobbe',
  'gobbo',
  'goccia',
  'goffi',
  'goffo',
  'gola',
  'gole',
  'golfi',
  'golfo',
  'golose',
  'golosi',
  'goloso',
  'gomiti',
  'gomito',
  'gomma',
  'gomme',
  'gonfia',
  'gonna',
  'gonne',
  'gorghi',
  'gorgo',
  'gp',
  'gq',
  'gr',
  'gradi',
  'grado',
  'gradua',
  'gradui',
  'graduo',
  'graffe',
  'graffi',
  'grafo',
  'grammo',
  'grana',
  'grande',
  'grandi',
  'grane',
  'grani',
  'grano',
  'grappa',
  'grappe',
  'grassa',
  'grasse',
  'grassi',
  'grata',
  'grate',
  'grati',
  'grato',
  'gratta',
  'gratti',
  'gratto',
  'grava',
  'gravai',
  'grave',
  'gravi',
  'gravo',
  'grazia',
  'grazio',
  'greche',
  'gregge',
  'greggi',
  'grembi',
  'grembo',
  'gremii',
  'grida',
  'gridai',
  'gridi',
  'grido',
  'grigi',
  'grigio',
  'grilli',
  'grinza',
  'grinze',
  'groppa',
  'groppe',
  'grossa',
  'grosse',
  'grossi',
  'grosso',
  'gru',
  'grucce',
  'gruppo',
  'gs',
  'gt',
  'gu',
  'guada',
  'guadai',
  'guadi',
  'guado',
  'guai',
  'guaio',
  'guarda',
  'guardi',
  'guardo',
  'guasta',
  'guasti',
  'guasto',
  'guerra',
  'guerre',
  'gufi',
  'gufo',
  'guglie',
  'guida',
  'guide',
  'guidi',
  'guido',
  'guizza',
  'guizzi',
  'guizzo',
  'gusci',
  'guscio',
  'gusta',
  'gustai',
  'gusti',
  'gusto',
  'gv',
  'gw',
  'gx',
  'gy',
  'gz',
  'h',
  'hai',
  'hb',
  'hc',
  'hd',
  'he',
  'hf',
  'hg',
  'hh',
  'hhh',
  'hhhh',
  'hi',
  'hij',
  'hj',
  'hk',
  'hl',
  'hm',
  'hn',
  'ho',
  'hp',
  'hq',
  'hr',
  'hs',
  'ht',
  'hu',
  'hv',
  'hw',
  'hx',
  'hy',
  'hz',
  'i',
  'ib',
  'ibridi',
  'ibrido',
  'ic',
  'id',
  'ideale',
  'ideali',
  'idee',
  'idioma',
  'idiomi',
  'idoli',
  'idolo',
  'idonea',
  'idonee',
  'idonei',
  'ie',
  'iena',
  'iene',
  'ieri',
  'if',
  'ig',
  'igiene',
  'ignara',
  'ignare',
  'ignari',
  'ignaro',
  'ignora',
  'ignori',
  'ignoro',
  'ignota',
  'ignote',
  'ignoto',
  'ih',
  'ii',
  'iii',
  'iiii',
  'ij',
  'ijk',
  'ik',
  'il',
  'illesa',
  'illese',
  'illesi',
  'illeso',
  'illude',
  'illudi',
  'illusa',
  'illuse',
  'illusi',
  'illuso',
  'im',
  'imbuti',
  'imbuto',
  'imita',
  'imitai',
  'imiti',
  'imito',
  'immuni',
  'impara',
  'impari',
  'imparo',
  'imperi',
  'impero',
  'impeto',
  'impeti',
  'impura',
  'impure',
  'impuri',
  'impuro',
  'imputa',
  'imputi',
  'imputo',
  'in',
  'incide',
  'incisa',
  'incise',
  'incita',
  'inciti',
  'incito',
  'incubi',
  'incubo',
  'indaga',
  'indica',
  'indice',
  'indici',
  'indizi',
  'indole',
  'indugi',
  'inebri',
  'inerme',
  'inermi',
  'inerti',
  'inezia',
  'infama',
  'infame',
  'infami',
  'infamo',
  'infili',
  'infilo',
  'infine',
  'iniqua',
  'inique',
  'iniqui',
  'iniquo',
  'inizi',
  'inizia',
  'inizio',
  'inni',
  'inno',
  'intasa',
  'intasi',
  'intaso',
  'intera',
  'intere',
  'interi',
  'intero',
  'intesa',
  'intese',
  'intesi',
  'inteso',
  'intima',
  'intime',
  'intimo',
  'invano',
  'invasa',
  'invase',
  'invasi',
  'invaso',
  'invece',
  'inviai',
  'invii',
  'invino',
  'invio',
  'invita',
  'inviti',
  'invito',
  'invoca',
  'invoco',
  'io',
  'iodio',
  'ip',
  'iq',
  'ir',
  'ira',
  'ire',
  'iride',
  'ironia',
  'ironie',
  'irriga',
  'irrita',
  'irriti',
  'irrito',
  'is',
  'isola',
  'isolai',
  'isole',
  'isoli',
  'isolo',
  'istigo',
  'istmo',
  'it',
  'iu',
  'iv',
  'ivi',
  'iw',
  'ix',
  'iy',
  'iz',
  'j',
  'jb',
  'jc',
  'jd',
  'je',
  'jf',
  'jg',
  'jh',
  'ji',
  'jj',
  'jjj',
  'jjjj',
  'jk',
  'jkl',
  'jl',
  'jm',
  'jn',
  'jo',
  'jp',
  'jq',
  'jr',
  'js',
  'jt',
  'ju',
  'jv',
  'jw',
  'jx',
  'jy',
  'jz',
  'k',
  'kb',
  'kc',
  'kd',
  'ke',
  'kf',
  'kg',
  'kh',
  'ki',
  'kj',
  'kk',
  'kkk',
  'kkkk',
  'kl',
  'klm',
  'km',
  'kn',
  'ko',
  'kp',
  'kq',
  'kr',
  'ks',
  'kt',
  'ku',
  'kv',
  'kw',
  'kx',
  'ky',
  'kz',
  'l',
  'labbra',
  'labbro',
  'lacche',
  'lacci',
  'lacera',
  'lacere',
  'laceri',
  'lacero',
  'ladra',
  'ladre',
  'ladri',
  'ladro',
  'laghi',
  'lago',
  'laguna',
  'lagune',
  'laica',
  'laiche',
  'laici',
  'laico',
  'lama',
  'lambda',
  'lambii',
  'lame',
  'lamina',
  'lamine',
  'lampi',
  'lampo',
  'lana',
  'lance',
  'lancio',
  'landa',
  'lande',
  'lane',
  'langui',
  'lanosa',
  'lanose',
  'lanosi',
  'lanoso',
  'lapide',
  'lapidi',
  'lardo',
  'larga',
  'larghe',
  'larghi',
  'largo',
  'lasci',
  'lascia',
  'lascio',
  'lastra',
  'lastre',
  'lati',
  'latini',
  'lato',
  'latra',
  'latrai',
  'latri',
  'latro',
  'lattai',
  'latte',
  'lauree',
  'lava',
  'lavabi',
  'lavabo',
  'lavai',
  'lavano',
  'lavata',
  'lavate',
  'lavati',
  'lavato',
  'lavava',
  'lavavi',
  'lavavo',
  'lavi',
  'lavo',
  'lavora',
  'lavori',
  'lavoro',
  'lb',
  'lc',
  'ld',
  'le',
  'leale',
  'leali',
  'lecca',
  'leccai',
  'lecci',
  'leccio',
  'lecco',
  'leciti',
  'lede',
  'ledere',
  'ledi',
  'ledo',
  'lega',
  'legai',
  'legale',
  'legali',
  'legame',
  'legami',
  'legano',
  'legare',
  'legata',
  'legati',
  'legato',
  'legava',
  'legavi',
  'legga',
  'legge',
  'leggi',
  'leggo',
  'leghe',
  'leghi',
  'legna',
  'legni',
  'legno',
  'legume',
  'legumi',
  'lei',
  'lembi',
  'lembo',
  'lenta',
  'lenti',
  'lento',
  'lenza',
  'lenze',
  'leone',
  'leoni',
  'lepre',
  'lepri',
  'lerce',
  'lerci',
  'lercia',
  'lercio',
  'lesa',
  'lese',
  'lesi',
  'leso',
  'lessa',
  'lessai',
  'lesse',
  'lessi',
  'lesso',
  'lesta',
  'leste',
  'lesto',
  'letame',
  'letami',
  'letta',
  'lette',
  'letti',
  'letto',
  'leva',
  'levai',
  'levano',
  'levare',
  'levata',
  'levate',
  'levati',
  'levava',
  'levavi',
  'levavo',
  'leve',
  'levi',
  'leviga',
  'levigo',
  'levino',
  'levo',
  'lf',
  'lg',
  'lh',
  'li',
  'liana',
  'libera',
  'libere',
  'liberi',
  'libero',
  'libra',
  'libre',
  'libri',
  'libro',
  'licei',
  'lidi',
  'lieta',
  'liete',
  'lieti',
  'lieto',
  'lieve',
  'lievi',
  'lilla',
  'lima',
  'limita',
  'limite',
  'limiti',
  'limito',
  'limone',
  'limoni',
  'linda',
  'linde',
  'lindi',
  'lindo',
  'linea',
  'linee',
  'lingua',
  'lingue',
  'lirica',
  'lirici',
  'lirico',
  'lisca',
  'lische',
  'lisci',
  'liscia',
  'liscio',
  'liso',
  'lista',
  'liste',
  'lite',
  'liti',
  'litiga',
  'litigo',
  'litri',
  'litro',
  'livida',
  'livide',
  'lividi',
  'livido',
  'lj',
  'lk',
  'll',
  'lll',
  'llll',
  'lm',
  'lmn',
  'ln',
  'lo',
  'locale',
  'lodai',
  'lodano',
  'lodata',
  'lodate',
  'lodati',
  'lodato',
  'lodava',
  'lodavi',
  'lodavo',
  'lode',
  'lodi',
  'lodino',
  'lodo',
  'logica',
  'logici',
  'logico',
  'logora',
  'logori',
  'logoro',
  'lontra',
  'lontre',
  'lorda',
  'lorde',
  'lordi',
  'lordo',
  'losca',
  'losche',
  'loschi',
  'losco',
  'lotta',
  'lotti',
  'lotto',
  'lp',
  'lq',
  'lr',
  'ls',
  'lt',
  'lu',
  'luce',
  'luci',
  'lucida',
  'lucide',
  'lucidi',
  'lucido',
  'lucore',
  'luglio',
  'lui',
  'lumaca',
  'lume',
  'lumi',
  'luna',
  'lunga',
  'lunghe',
  'lunghi',
  'lungo',
  'luoghi',
  'luogo',
  'lupi',
  'lupo',
  'lussi',
  'lusso',
  'lutti',
  'lutto',
  'lv',
  'lw',
  'lx',
  'ly',
  'lz',
  'm',
  'macera',
  'maceri',
  'macero',
  'macina',
  'macine',
  'madre',
  'madri',
  'maggio',
  'maghi',
  'magica',
  'magici',
  'magico',
  'mago',
  'maglia',
  'maglie',
  'magra',
  'magri',
  'magro',
  'mai',
  'maiale',
  'malata',
  'malate',
  'malati',
  'malato',
  'male',
  'malga',
  'mali',
  'malore',
  'malori',
  'mamma',
  'mamme',
  'manata',
  'manate',
  'manca',
  'mancai',
  'mance',
  'manchi',
  'mancia',
  'manda',
  'mandai',
  'mandi',
  'mando',
  'mangi',
  'mangia',
  'mangio',
  'mani',
  'mania',
  'manica',
  'manici',
  'manie',
  'mano',
  'manzi',
  'manzo',
  'mappa',
  'mappe',
  'marca',
  'marce',
  'marci',
  'marcia',
  'marcio',
  'marco',
  'mare',
  'marea',
  'maree',
  'mari',
  'marina',
  'marini',
  'marino',
  'marita',
  'mariti',
  'marito',
  'marmi',
  'marmo',
  'marzo',
  'maschi',
  'massa',
  'masse',
  'massi',
  'masso',
  'mastri',
  'mastro',
  'matita',
  'matite',
  'matta',
  'matte',
  'matti',
  'matto',
  'matura',
  'mature',
  'maturi',
  'maturo',
  'mazza',
  'mazze',
  'mazzi',
  'mazzo',
  'mb',
  'mc',
  'md',
  'me',
  'media',
  'medici',
  'medico',
  'medie',
  'medio',
  'medita',
  'mediti',
  'medito',
  'medusa',
  'meduse',
  'meglio',
  'mela',
  'mele',
  'meleto',
  'melma',
  'melo',
  'membra',
  'membri',
  'membro',
  'mena',
  'menai',
  'menano',
  'menare',
  'menata',
  'menate',
  'menati',
  'menato',
  'menava',
  'menavi',
  'menavo',
  'meni',
  'menino',
  'meno',
  'mensa',
  'mense',
  'menta',
  'mente',
  'menti',
  'mentii',
  'mento',
  'mentre',
  'merce',
  'merci',
  'meriti',
  'merito',
  'merli',
  'merlo',
  'mese',
  'mesi',
  'messa',
  'messe',
  'messi',
  'messo',
  'mesta',
  'mestai',
  'mesti',
  'mesto',
  'mete',
  'metodi',
  'metodo',
  'metri',
  'metta',
  'mette',
  'metti',
  'mezza',
  'mezze',
  'mezzi',
  'mezzo',
  'mf',
  'mg',
  'mh',
  'mi',
  'mia',
  'micce',
  'miccia',
  'miele',
  'mieta',
  'miete',
  'mietei',
  'mieti',
  'miglio',
  'milite',
  'mille',
  'milza',
  'milze',
  'mina',
  'minata',
  'mine',
  'minima',
  'minime',
  'minimi',
  'minimo',
  'minori',
  'minuta',
  'minute',
  'minuti',
  'minuto',
  'miope',
  'miopi',
  'mira',
  'mirano',
  'mirare',
  'mirata',
  'mirate',
  'mirati',
  'mirato',
  'mirava',
  'miravi',
  'miravo',
  'mire',
  'miri',
  'mirini',
  'mirino',
  'miro',
  'mise',
  'misera',
  'misere',
  'miseri',
  'misi',
  'mista',
  'miste',
  'misti',
  'misto',
  'misura',
  'misuri',
  'misuro',
  'miti',
  'mitiga',
  'mitigo',
  'mito',
  'mj',
  'mk',
  'ml',
  'mm',
  'mmm',
  'mmmm',
  'mn',
  'mno',
  'mo',
  'mobile',
  'mobili',
  'moda',
  'mode',
  'modera',
  'moderi',
  'modero',
  'modi',
  'modica',
  'modici',
  'modico',
  'modo',
  'moduli',
  'modulo',
  'mogano',
  'mogli',
  'moglie',
  'molare',
  'molari',
  'moli',
  'molla',
  'molle',
  'molo',
  'molta',
  'molte',
  'molto',
  'monaca',
  'monaci',
  'monaco',
  'monca',
  'monche',
  'monchi',
  'monco',
  'mondi',
  'moneta',
  'monete',
  'monna',
  'monta',
  'montai',
  'monte',
  'monti',
  'mora',
  'morali',
  'morda',
  'more',
  'mori',
  'moria',
  'morire',
  'moro',
  'morra',
  'morsa',
  'morse',
  'morsi',
  'morso',
  'morta',
  'morte',
  'morti',
  'morto',
  'mosca',
  'mosche',
  'mosci',
  'mossa',
  'mosse',
  'mossi',
  'mosso',
  'mosto',
  'mostra',
  'mostre',
  'mostri',
  'mostro',
  'motivi',
  'motivo',
  'moto',
  'motore',
  'motori',
  'mozza',
  'mozzai',
  'mozzi',
  'mozzo',
  'mp',
  'mq',
  'mr',
  'ms',
  'mt',
  'mu',
  'mucca',
  'mucchi',
  'muffa',
  'muffe',
  'muggii',
  'mugnai',
  'mulini',
  'mulino',
  'mulo',
  'multa',
  'multe',
  'mummia',
  'munito',
  'muoia',
  'muoio',
  'muore',
  'muori',
  'muova',
  'muove',
  'muovi',
  'muovo',
  'mura',
  'muri',
  'museo',
  'musica',
  'muso',
  'muta',
  'mutai',
  'mutano',
  'mutare',
  'mutata',
  'mutate',
  'mutati',
  'mutato',
  'mutava',
  'mutavi',
  'mutavo',
  'mute',
  'muti',
  'mutino',
  'muto',
  'mv',
  'mw',
  'mx',
  'my',
  'mz',
  'n',
  'nacque',
  'nacqui',
  'nafta',
  'nana',
  'nane',
  'nani',
  'nano',
  'nappa',
  'narice',
  'narici',
  'narra',
  'narrai',
  'narri',
  'nasale',
  'nasali',
  'nasce',
  'nasco',
  'nasi',
  'naso',
  'nastri',
  'nata',
  'natale',
  'nati',
  'natica',
  'nativa',
  'native',
  'nativi',
  'nativo',
  'nato',
  'natura',
  'nature',
  'nausea',
  'navale',
  'navali',
  'navata',
  'navate',
  'nave',
  'navi',
  'naviga',
  'navigo',
  'nb',
  'nc',
  'nd',
  'ne',
  'nebbia',
  'nebbie',
  'negai',
  'negano',
  'negare',
  'negata',
  'negate',
  'negati',
  'negato',
  'negava',
  'negavi',
  'negavo',
  'neghi',
  'negli',
  'nego',
  'negozi',
  'nei',
  'nella',
  'nelle',
  'nello',
  'nemici',
  'neo',
  'nere',
  'neri',
  'nero',
  'nervi',
  'nervo',
  'neutra',
  'neutre',
  'neutri',
  'neutro',
  'nevaio',
  'neve',
  'nevica',
  'nevico',
  'nf',
  'ng',
  'nh',
  'ni',
  'nidi',
  'nido',
  'niente',
  'ninfa',
  'ninfe',
  'nipote',
  'nipoti',
  'nj',
  'nk',
  'nl',
  'nm',
  'nn',
  'nnn',
  'nnnn',
  'no',
  'nobile',
  'nobili',
  'noce',
  'noci',
  'nociva',
  'nocive',
  'nocivo',
  'nodo',
  'noi',
  'noia',
  'noie',
  'noiosa',
  'noiose',
  'noiosi',
  'noioso',
  'nomade',
  'nomadi',
  'nome',
  'nomi',
  'nomina',
  'nomine',
  'nomini',
  'nomino',
  'nonna',
  'nonne',
  'nonni',
  'nonno',
  'nop',
  'norma',
  'norme',
  'nostra',
  'nostre',
  'nostri',
  'nostro',
  'notai',
  'notaio',
  'notare',
  'notata',
  'notate',
  'notato',
  'notava',
  'notavi',
  'note',
  'noti',
  'notino',
  'noto',
  'notte',
  'notti',
  'nove',
  'novizi',
  'nozze',
  'np',
  'nq',
  'nr',
  'ns',
  'nt',
  'nu',
  'nubile',
  'nubili',
  'nuca',
  'nuche',
  'nuclei',
  'nucleo',
  'nuda',
  'nudi',
  'nudo',
  'nulla',
  'nullo',
  'numera',
  'numeri',
  'numero',
  'nunzio',
  'nuora',
  'nuota',
  'nuotai',
  'nuoti',
  'nuoto',
  'nuova',
  'nuove',
  'nuovi',
  'nuovo',
  'nutre',
  'nutrii',
  'nuvola',
  'nuvole',
  'nv',
  'nw',
  'nx',
  'ny',
  'nz',
  'o',
  'oasi',
  'ob',
  'oblia',
  'oblii',
  'oblio',
  'oc',
  'oca',
  'occhi',
  'occhio',
  'occupa',
  'occupi',
  'occupo',
  'oceani',
  'oceano',
  'oche',
  'od',
  'ode',
  'odi',
  'odia',
  'odiai',
  'odiamo',
  'odiano',
  'odiare',
  'odiate',
  'odiato',
  'odiava',
  'odiavi',
  'odiavo',
  'odino',
  'odio',
  'odiosa',
  'odiose',
  'odiosi',
  'odioso',
  'odorai',
  'odore',
  'odori',
  'odoro',
  'oe',
  'of',
  'offesa',
  'offese',
  'offesi',
  'offeso',
  'offra',
  'offre',
  'offri',
  'offro',
  'og',
  'oggi',
  'ogni',
  'ognuna',
  'ognuno',
  'oh',
  'oi',
  'oj',
  'ok',
  'ol',
  'olezzi',
  'olezzo',
  'olia',
  'oliai',
  'oliamo',
  'oliare',
  'oliata',
  'oliate',
  'oliati',
  'oliato',
  'oliava',
  'oliavi',
  'oliavo',
  'oliera',
  'oliere',
  'olino',
  'oliva',
  'olive',
  'olmi',
  'olmo',
  'oltre',
  'om',
  'ombra',
  'ombre',
  'omeri',
  'omero',
  'omessa',
  'omesse',
  'omessi',
  'omette',
  'ometto',
  'on',
  'once',
  'onda',
  'onde',
  'ondosa',
  'ondose',
  'ondosi',
  'ondoso',
  'ondula',
  'onduli',
  'ondulo',
  'oneri',
  'onesta',
  'oneste',
  'onesti',
  'onesto',
  'onice',
  'onora',
  'onorai',
  'onore',
  'onori',
  'oo',
  'ooo',
  'oooo',
  'op',
  'opaca',
  'opachi',
  'opaco',
  'opera',
  'operai',
  'opere',
  'operi',
  'opero',
  'opinai',
  'oppure',
  'opq',
  'opta',
  'optai',
  'optano',
  'optare',
  'optata',
  'optate',
  'optati',
  'optato',
  'optava',
  'optavi',
  'optavo',
  'opti',
  'optino',
  'opto',
  'oq',
  'or',
  'ora',
  'orafa',
  'orafe',
  'orafi',
  'orafo',
  'orale',
  'orali',
  'orari',
  'orario',
  'orbite',
  'orchi',
  'orco',
  'orda',
  'orde',
  'ordina',
  'ordine',
  'ordini',
  'ordino',
  'ordire',
  'ordita',
  'ordite',
  'orditi',
  'ordito',
  'ordiva',
  'ordivi',
  'ordivo',
  'oretta',
  'orfana',
  'orfane',
  'orfani',
  'orfano',
  'organi',
  'organo',
  'origli',
  'orla',
  'orlai',
  'orlano',
  'orlare',
  'orlata',
  'orlate',
  'orlati',
  'orlato',
  'orlava',
  'orlavi',
  'orlavo',
  'orli',
  'orlino',
  'orlo',
  'orma',
  'orme',
  'orna',
  'ornai',
  'ornano',
  'ornare',
  'ornata',
  'ornate',
  'ornati',
  'ornato',
  'ornava',
  'ornavi',
  'ornavo',
  'orni',
  'orno',
  'oro',
  'orrore',
  'orrori',
  'orsa',
  'orse',
  'orso',
  'orti',
  'ortica',
  'orto',
  'os',
  'osa',
  'osai',
  'osammo',
  'osando',
  'osano',
  'osare',
  'osasse',
  'osassi',
  'osaste',
  'osasti',
  'osata',
  'osate',
  'osati',
  'osato',
  'osava',
  'osavi',
  'osavo',
  'oscena',
  'oscene',
  'osceni',
  'osceno',
  'oscura',
  'oscure',
  'oscuri',
  'oscuro',
  'oserai',
  'oserei',
  'osi',
  'osiamo',
  'osiate',
  'osino',
  'oso',
  'ospita',
  'ospite',
  'ospiti',
  'ospito',
  'ospizi',
  'ossi',
  'osso',
  'osti',
  'ostile',
  'ostili',
  'ot',
  'otre',
  'otri',
  'ottava',
  'ottici',
  'ottima',
  'ottime',
  'ottimi',
  'ottimo',
  'otto',
  'ottone',
  'ottura',
  'otturi',
  'otturo',
  'ottusa',
  'ottuse',
  'ottusi',
  'ottuso',
  'ou',
  'ov',
  'ovale',
  'ovali',
  'ovatta',
  'ovile',
  'ovvero',
  'ovvi',
  'ovvia',
  'ovvio',
  'ow',
  'ox',
  'oy',
  'oz',
  'ozia',
  'oziai',
  'oziamo',
  'oziano',
  'oziare',
  'oziate',
  'oziava',
  'oziavi',
  'oziavo',
  'ozino',
  'ozio',
  'oziose',
  'oziosi',
  'p',
  'pacata',
  'pacate',
  'pacato',
  'pacchi',
  'pacco',
  'pace',
  'padre',
  'padri',
  'paese',
  'paesi',
  'paga',
  'pagai',
  'pagana',
  'pagane',
  'pagani',
  'pagano',
  'pagare',
  'pagata',
  'pagate',
  'pagati',
  'pagato',
  'pagava',
  'pagavi',
  'pagavo',
  'paghi',
  'pagina',
  'paglia',
  'paglie',
  'pago',
  'paia',
  'paio',
  'paiono',
  'palati',
  'palato',
  'palchi',
  'palco',
  'pale',
  'palesa',
  'palese',
  'palesi',
  'paleso',
  'pali',
  'palla',
  'palma',
  'palme',
  'palo',
  'palpa',
  'palpai',
  'palpi',
  'palpo',
  'palude',
  'panca',
  'pance',
  'panche',
  'pancia',
  'pane',
  'pani',
  'panico',
  'panini',
  'panino',
  'panna',
  'panni',
  'panno',
  'papera',
  'papere',
  'papero',
  'pappa',
  'pappe',
  'parata',
  'parate',
  'parati',
  'parchi',
  'parco',
  'pare',
  'parere',
  'pareri',
  'paresi',
  'parete',
  'pareva',
  'pari',
  'parla',
  'parlai',
  'parli',
  'parola',
  'parole',
  'parsa',
  'parso',
  'parte',
  'parti',
  'parto',
  'parve',
  'pasqua',
  'pasque',
  'passa',
  'passai',
  'passi',
  'passo',
  'pasta',
  'paste',
  'pasti',
  'pasto',
  'patata',
  'patria',
  'patrie',
  'patta',
  'patti',
  'patto',
  'paure',
  'pausa',
  'pause',
  'pavone',
  'pavoni',
  'pazza',
  'pazze',
  'pazzi',
  'pazzia',
  'pazzie',
  'pazzo',
  'pb',
  'pc',
  'pd',
  'pe',
  'pecca',
  'peccai',
  'pecchi',
  'pece',
  'pecore',
  'pedale',
  'pedali',
  'pedana',
  'pedane',
  'pedata',
  'pedate',
  'pedice',
  'pedina',
  'pedine',
  'pedini',
  'pedino',
  'pedone',
  'pedoni',
  'peggio',
  'pegni',
  'pegno',
  'pela',
  'pelai',
  'pelano',
  'pelare',
  'pelata',
  'pelate',
  'pelati',
  'pelato',
  'pelava',
  'pelavi',
  'peli',
  'pelino',
  'pelle',
  'pelli',
  'pelo',
  'pelosa',
  'pelose',
  'pelosi',
  'peloso',
  'pena',
  'penai',
  'penano',
  'penare',
  'penata',
  'penate',
  'penati',
  'penato',
  'penava',
  'penavi',
  'penavo',
  'pende',
  'pendei',
  'pendi',
  'pendo',
  'penino',
  'penna',
  'penne',
  'penosa',
  'penose',
  'penosi',
  'pensa',
  'pensi',
  'pera',
  'perde',
  'pere',
  'peri',
  'perita',
  'perite',
  'periti',
  'perito',
  'perla',
  'perle',
  'perni',
  'perno',
  'pero',
  'perse',
  'persi',
  'perso',
  'pesa',
  'pesai',
  'pesano',
  'pesare',
  'pesata',
  'pesate',
  'pesati',
  'pesato',
  'pesava',
  'pesavi',
  'pesavo',
  'pesca',
  'pescai',
  'pesce',
  'pesche',
  'peschi',
  'pesci',
  'pesco',
  'pese',
  'pesi',
  'pesino',
  'peso',
  'peste',
  'pesti',
  'pesto',
  'petali',
  'petalo',
  'petti',
  'petto',
  'pezza',
  'pezze',
  'pezzi',
  'pezzo',
  'pf',
  'pg',
  'ph',
  'pi',
  'pia',
  'piace',
  'piaci',
  'piaga',
  'piaghe',
  'pialla',
  'pialle',
  'piana',
  'piane',
  'pianga',
  'piange',
  'piangi',
  'piango',
  'piani',
  'piano',
  'pianse',
  'pianta',
  'piante',
  'pianti',
  'pianto',
  'piatta',
  'piatte',
  'piatti',
  'piazza',
  'piazze',
  'picchi',
  'picco',
  'pie',
  'piede',
  'piedi',
  'piega',
  'pieghe',
  'piena',
  'piene',
  'pieni',
  'pietre',
  'pigli',
  'piglia',
  'piglio',
  'pigre',
  'pigro',
  'pii',
  'pila',
  'pile',
  'pilone',
  'pilota',
  'piloti',
  'pineta',
  'pinete',
  'pini',
  'pinna',
  'pinne',
  'pino',
  'pinta',
  'pinte',
  'pinza',
  'pinze',
  'pio',
  'piogge',
  'piomba',
  'piombi',
  'piombo',
  'pioppi',
  'pioppo',
  'piove',
  'piovve',
  'pipa',
  'pirata',
  'pirati',
  'pista',
  'piste',
  'pitoni',
  'piuma',
  'piume',
  'pizza',
  'pizze',
  'pizzi',
  'pizzo',
  'pj',
  'pk',
  'pl',
  'placca',
  'placco',
  'plance',
  'plasma',
  'plasmi',
  'plasmo',
  'platea',
  'platee',
  'plebe',
  'plebi',
  'plichi',
  'plico',
  'pm',
  'pn',
  'po',
  'poca',
  'poche',
  'pochi',
  'poco',
  'podere',
  'poderi',
  'poema',
  'poemi',
  'poesia',
  'poesie',
  'poeta',
  'poggi',
  'poggio',
  'poi',
  'poiana',
  'polare',
  'polari',
  'poli',
  'polipi',
  'polipo',
  'pollai',
  'polli',
  'pollo',
  'polo',
  'polpa',
  'polpe',
  'polsi',
  'polso',
  'pomata',
  'pomate',
  'pomi',
  'pomice',
  'pomici',
  'pomo',
  'pompa',
  'pompe',
  'pompi',
  'pompo',
  'pone',
  'poneva',
  'ponte',
  'popola',
  'popoli',
  'popolo',
  'porge',
  'pori',
  'porlo',
  'poro',
  'porosa',
  'porose',
  'poroso',
  'porrai',
  'porre',
  'porri',
  'porro',
  'porse',
  'porta',
  'portai',
  'porte',
  'porto',
  'porvi',
  'posa',
  'posai',
  'posare',
  'posata',
  'posate',
  'posati',
  'posato',
  'posava',
  'posavi',
  'posavo',
  'pose',
  'posi',
  'posino',
  'poso',
  'possa',
  'posso',
  'posta',
  'posti',
  'posto',
  'potare',
  'potei',
  'potere',
  'potete',
  'poteva',
  'potevi',
  'potevo',
  'potrai',
  'potrei',
  'potuta',
  'potuti',
  'potuto',
  'povera',
  'povere',
  'poveri',
  'povero',
  'pozza',
  'pozze',
  'pozzi',
  'pozzo',
  'pp',
  'ppp',
  'pppp',
  'pq',
  'pqr',
  'pr',
  'pranzi',
  'pranzo',
  'prati',
  'prato',
  'preda',
  'predai',
  'prede',
  'predi',
  'predii',
  'predo',
  'prega',
  'pregai',
  'preghi',
  'pregi',
  'pregio',
  'prema',
  'preme',
  'premei',
  'premi',
  'premia',
  'prenda',
  'prende',
  'prendi',
  'prendo',
  'prese',
  'presi',
  'preso',
  'pressa',
  'pressi',
  'presso',
  'presta',
  'presti',
  'presto',
  'prete',
  'preti',
  'prezzi',
  'prezzo',
  'prima',
  'prime',
  'primi',
  'primo',
  'priori',
  'prisma',
  'priva',
  'prive',
  'privi',
  'privo',
  'prode',
  'prodi',
  'prole',
  'pronte',
  'pronti',
  'pronto',
  'propri',
  'prosa',
  'prose',
  'prova',
  'provai',
  'prove',
  'provi',
  'provo',
  'prugna',
  'prugne',
  'ps',
  'pseudo',
  'psiche',
  'pt',
  'pu',
  'pudica',
  'pudici',
  'pudico',
  'pudore',
  'pudori',
  'pugile',
  'pugni',
  'pulce',
  'pulci',
  'pulii',
  'pulita',
  'pulite',
  'puliti',
  'pulito',
  'puliva',
  'pulivi',
  'pulivo',
  'pulsa',
  'pulsai',
  'pulsi',
  'punge',
  'pungi',
  'pungo',
  'punsi',
  'puntai',
  'punte',
  'punti',
  'punto',
  'puoi',
  'pura',
  'pure',
  'purga',
  'purgai',
  'purghi',
  'purgo',
  'puri',
  'puro',
  'puzzai',
  'puzzi',
  'puzzo',
  'pv',
  'pw',
  'px',
  'py',
  'pz',
  'q',
  'qb',
  'qc',
  'qd',
  'qe',
  'qf',
  'qg',
  'qh',
  'qi',
  'qj',
  'qk',
  'ql',
  'qm',
  'qn',
  'qo',
  'qp',
  'qq',
  'qqq',
  'qqqq',
  'qr',
  'qrs',
  'qs',
  'qt',
  'qu',
  'quadre',
  'quadri',
  'quadro',
  'quale',
  'quali',
  'quando',
  'quante',
  'quanti',
  'quanto',
  'quarzi',
  'quarzo',
  'quasi',
  'quasar',
  'quei',
  'quella',
  'quello',
  'querce',
  'questa',
  'queste',
  'questi',
  'questo',
  'qui',
  'quieta',
  'quiete',
  'quieti',
  'quieto',
  'quindi',
  'quinto',
  'quota',
  'quotai',
  'quote',
  'quoti',
  'quoto',
  'qv',
  'qw',
  'qx',
  'qy',
  'qz',
  'r',
  'rabbia',
  'rabbie',
  'rada',
  'rade',
  'radi',
  'radia',
  'radiai',
  'radice',
  'radici',
  'radino',
  'radio',
  'rado',
  'raduna',
  'raduni',
  'raduno',
  'radura',
  'radure',
  'raggi',
  'raggio',
  'ragno',
  'ramai',
  'ramaio',
  'rami',
  'rampa',
  'rampe',
  'rana',
  'ranci',
  'rancio',
  'rane',
  'ranghi',
  'rango',
  'rapa',
  'rapace',
  'rapaci',
  'rapai',
  'rapano',
  'rapare',
  'rapata',
  'rapate',
  'rapati',
  'rapato',
  'rapava',
  'rapavi',
  'rapavo',
  'rape',
  'rapida',
  'rapide',
  'rapidi',
  'rapido',
  'rapino',
  'rapire',
  'rapita',
  'rapite',
  'rapiti',
  'rapito',
  'rapiva',
  'rapivi',
  'rapivo',
  'rapo',
  'rara',
  'rare',
  'rari',
  'raro',
  'rasa',
  'rase',
  'rasi',
  'raso',
  'rasoi',
  'rasoio',
  'raspa',
  'raspai',
  'raspi',
  'raspo',
  'rata',
  'rate',
  'ratti',
  'ratto',
  'rauca',
  'rauche',
  'rauchi',
  'rauco',
  'razza',
  'razze',
  'razzi',
  'razzo',
  'rb',
  'rc',
  'rd',
  're',
  'rea',
  'reale',
  'reali',
  'reati',
  'reato',
  'recai',
  'recare',
  'recata',
  'recate',
  'recati',
  'recato',
  'recava',
  'recavi',
  'recavo',
  'recede',
  'recedi',
  'recedo',
  'rechi',
  'recide',
  'recidi',
  'recido',
  'recisa',
  'recise',
  'recisi',
  'reciso',
  'recita',
  'recite',
  'reciti',
  'recito',
  'redige',
  'redigi',
  'redigo',
  'redine',
  'redini',
  'reduci',
  'ree',
  'regala',
  'regale',
  'regali',
  'regalo',
  'regate',
  'regge',
  'reggi',
  'reggia',
  'reggo',
  'regime',
  'regimi',
  'regina',
  'regine',
  'regnai',
  'regni',
  'regola',
  'regole',
  'regoli',
  'regolo',
  'rei',
  'relega',
  'relego',
  'rema',
  'remai',
  'remano',
  'remare',
  'remata',
  'remate',
  'remati',
  'remato',
  'remava',
  'remavi',
  'remavo',
  'remi',
  'remino',
  'remo',
  'remota',
  'remote',
  'remoti',
  'remoto',
  'renda',
  'rende',
  'rendi',
  'rendo',
  'rene',
  'reni',
  'renna',
  'renne',
  'reo',
  'reputa',
  'reputi',
  'reputo',
  'resa',
  'rese',
  'resero',
  'resi',
  'resina',
  'resine',
  'reso',
  'ressa',
  'resse',
  'ressi',
  'resta',
  'restai',
  'resti',
  'restia',
  'restie',
  'restii',
  'restio',
  'resto',
  'retata',
  'retate',
  'rete',
  'reti',
  'retro',
  'retta',
  'rette',
  'retto',
  'revoca',
  'revoco',
  'rf',
  'rg',
  'rh',
  'ri',
  'rialza',
  'rialzo',
  'ricada',
  'ricade',
  'ricado',
  'ricama',
  'ricami',
  'ricava',
  'ricavi',
  'ricavo',
  'ricca',
  'ricche',
  'ricchi',
  'ricci',
  'riccio',
  'ricco',
  'riceva',
  'riceve',
  'ricevi',
  'ricevo',
  'ricino',
  'ride',
  'ridere',
  'ridete',
  'rideva',
  'ridevo',
  'ridi',
  'ridici',
  'rido',
  'ridono',
  'riempi',
  'riesca',
  'riesce',
  'riesci',
  'riesco',
  'riga',
  'righe',
  'rigide',
  'rigidi',
  'rigido',
  'rigo',
  'rigore',
  'rigori',
  'rilega',
  'rilego',
  'rileva',
  'rilevi',
  'rilevo',
  'rima',
  'rimane',
  'rimani',
  'rimase',
  'rimasi',
  'rime',
  'rimedi',
  'rimise',
  'rimisi',
  'ringhi',
  'rinvia',
  'rinvii',
  'rinvio',
  'rione',
  'rioni',
  'ripaga',
  'ripago',
  'ripara',
  'ripari',
  'riparo',
  'ripeta',
  'ripete',
  'ripeti',
  'ripeto',
  'ripida',
  'ripide',
  'ripido',
  'riposa',
  'riposi',
  'riposo',
  'ripudi',
  'risa',
  'risaia',
  'risana',
  'risani',
  'risano',
  'risata',
  'risate',
  'rischi',
  'rise',
  'risero',
  'risi',
  'risma',
  'risme',
  'risona',
  'risoni',
  'risono',
  'rissa',
  'risse',
  'riti',
  'ritira',
  'ritiri',
  'ritiro',
  'ritmi',
  'ritmo',
  'rito',
  'ritta',
  'ritte',
  'ritti',
  'ritto',
  'riunii',
  'riva',
  'rivale',
  'rivali',
  'rive',
  'riveda',
  'rivede',
  'rivedi',
  'rivedo',
  'rivela',
  'riveli',
  'rivelo',
  'rj',
  'rk',
  'rl',
  'rm',
  'rn',
  'ro',
  'rocce',
  'roccia',
  'roghi',
  'roma',
  'romba',
  'rombi',
  'rombo',
  'rompa',
  'rompe',
  'rompi',
  'rompo',
  'ronda',
  'ronde',
  'ronza',
  'ronzai',
  'ronzi',
  'ronzo',
  'rosa',
  'rosari',
  'rose',
  'rosola',
  'rosolo',
  'rospi',
  'rospo',
  'rossa',
  'rosse',
  'rossi',
  'rosso',
  'rotaia',
  'rotaie',
  'rotola',
  'rotoli',
  'rotolo',
  'rotta',
  'rotte',
  'rotti',
  'rotto',
  'rovi',
  'rovina',
  'rovine',
  'rovini',
  'rovino',
  'rovo',
  'rozza',
  'rozze',
  'rozzi',
  'rp',
  'rq',
  'rr',
  'rrr',
  'rrrr',
  'rs',
  'rst',
  'rt',
  'ru',
  'ruba',
  'rubai',
  'rubano',
  'rubare',
  'rubata',
  'rubate',
  'rubati',
  'rubato',
  'rubava',
  'rubavi',
  'rubavo',
  'rubi',
  'rubini',
  'rubino',
  'rubo',
  'ruga',
  'rughe',
  'rulla',
  'rullai',
  'rulli',
  'rullii',
  'rullio',
  'rullo',
  'rumina',
  'rumini',
  'rumore',
  'rumori',
  'ruolo',
  'ruota',
  'ruote',
  'rupe',
  'rupi',
  'rurale',
  'rurali',
  'russa',
  'russi',
  'russo',
  'ruvida',
  'ruvide',
  'ruvidi',
  'ruvido',
  'rv',
  'rw',
  'rx',
  'ry',
  'rz',
  's',
  'sabato',
  'sabbia',
  'sacca',
  'sacche',
  'sacchi',
  'sacco',
  'sacra',
  'sacre',
  'sacro',
  'sagace',
  'sagaci',
  'sagge',
  'saggi',
  'saggia',
  'saggio',
  'sai',
  'sala',
  'salai',
  'salame',
  'salami',
  'salano',
  'salare',
  'salata',
  'salate',
  'salava',
  'salavi',
  'salavo',
  'salda',
  'saldai',
  'salde',
  'saldi',
  'saldo',
  'sale',
  'salga',
  'sali',
  'salice',
  'salici',
  'salino',
  'salire',
  'salita',
  'salite',
  'saliti',
  'salito',
  'saliva',
  'salivo',
  'salma',
  'salme',
  'salmi',
  'salpa',
  'salpai',
  'salpi',
  'salpo',
  'salsa',
  'salse',
  'salta',
  'saltai',
  'salti',
  'salto',
  'salume',
  'salumi',
  'saluta',
  'salute',
  'saluti',
  'saluto',
  'salva',
  'salvai',
  'salve',
  'salvia',
  'salvo',
  'sana',
  'sanai',
  'sanano',
  'sanare',
  'sanata',
  'sanati',
  'sanato',
  'sanava',
  'sanavi',
  'sanavo',
  'sane',
  'sangue',
  'sani',
  'sanino',
  'sanno',
  'sano',
  'santa',
  'sante',
  'sapere',
  'sapete',
  'sapeva',
  'sapevi',
  'sapevo',
  'sapone',
  'saponi',
  'sapore',
  'sapori',
  'sappi',
  'sappia',
  'saprai',
  'saputo',
  'sarai',
  'sarei',
  'sarta',
  'sarte',
  'sarti',
  'sarto',
  'sassi',
  'sasso',
  'satira',
  'satire',
  'sazia',
  'saziai',
  'sazie',
  'sazino',
  'sazio',
  'sb',
  'sbagli',
  'sballa',
  'sballi',
  'sballo',
  'sbalza',
  'sbalzi',
  'sbarca',
  'sbarco',
  'sbarra',
  'sbarre',
  'sbarro',
  'sbatta',
  'sbatte',
  'sbatti',
  'sbava',
  'sbavai',
  'sbavi',
  'sbavo',
  'sbenda',
  'sbendo',
  'sbirci',
  'sbocci',
  'sbocco',
  'sbrana',
  'sbrano',
  'sbriga',
  'sbrigo',
  'sbucci',
  'sbuffa',
  'sbuffi',
  'sc',
  'scacci',
  'scada',
  'scade',
  'scadi',
  'scado',
  'scafi',
  'scafo',
  'scala',
  'scalai',
  'scalda',
  'scaldi',
  'scaldo',
  'scale',
  'scali',
  'scalo',
  'scalza',
  'scalze',
  'scalzi',
  'scalzo',
  'scambi',
  'scampa',
  'scampi',
  'scampo',
  'scappa',
  'scappi',
  'scappo',
  'scarpa',
  'scarpe',
  'scarse',
  'scarsi',
  'scarso',
  'scarta',
  'scarto',
  'scassa',
  'scassi',
  'scasso',
  'scatta',
  'scatto',
  'scava',
  'scavi',
  'scavo',
  'scegli',
  'scelga',
  'scelgo',
  'scelse',
  'scelsi',
  'scelta',
  'scelte',
  'scelti',
  'scelto',
  'scempi',
  'scena',
  'scenda',
  'scende',
  'scendi',
  'scendo',
  'scene',
  'scesa',
  'scese',
  'scesi',
  'sceso',
  'scheda',
  'schede',
  'schema',
  'schemi',
  'schifo',
  'sci',
  'scia',
  'sciame',
  'sciami',
  'scie',
  'sciupa',
  'sciupi',
  'sciupo',
  'scogli',
  'scoli',
  'scolo',
  'sconta',
  'sconti',
  'sconto',
  'scopi',
  'scopo',
  'scoppi',
  'scopre',
  'scopri',
  'scopro',
  'scorci',
  'scorda',
  'scordo',
  'scorga',
  'scorge',
  'scorgi',
  'scorgo',
  'scorra',
  'scorre',
  'scorri',
  'scorro',
  'scorse',
  'scorta',
  'scorte',
  'scorti',
  'scorto',
  'scorza',
  'scorze',
  'scossa',
  'scosse',
  'scossi',
  'scosta',
  'scosti',
  'scosto',
  'scotta',
  'scotti',
  'scotto',
  'scovai',
  'screma',
  'scremi',
  'scremo',
  'scriva',
  'scrivi',
  'scrivo',
  'scruta',
  'scruto',
  'scudi',
  'scudo',
  'scuola',
  'scuote',
  'scura',
  'scure',
  'scuri',
  'scuro',
  'scusa',
  'scusai',
  'scusi',
  'scuso',
  'sd',
  'sdegna',
  'sdegni',
  'sdoppi',
  'sdraie',
  'se',
  'secca',
  'secche',
  'secchi',
  'secoli',
  'secolo',
  'sedai',
  'sedano',
  'sedare',
  'sedate',
  'sedati',
  'sedato',
  'sedava',
  'sedavi',
  'sede',
  'sedete',
  'sedeva',
  'sedevo',
  'sedi',
  'sedia',
  'sedie',
  'sedile',
  'sedili',
  'sedino',
  'sedute',
  'seduti',
  'seduto',
  'segale',
  'seggi',
  'seggio',
  'seghe',
  'segna',
  'segni',
  'segno',
  'segue',
  'segugi',
  'seguii',
  'seguo',
  'sei',
  'selci',
  'selcia',
  'selcio',
  'sella',
  'selle',
  'selva',
  'selve',
  'sembra',
  'sembri',
  'sembro',
  'seme',
  'semi',
  'semina',
  'semine',
  'semini',
  'semino',
  'sempre',
  'senapa',
  'senape',
  'senati',
  'senato',
  'seni',
  'senno',
  'seno',
  'sensi',
  'senso',
  'senta',
  'sente',
  'senti',
  'sentii',
  'sento',
  'senza',
  'separa',
  'separi',
  'separo',
  'seppi',
  'seppie',
  'sera',
  'serata',
  'serba',
  'serbi',
  'serbo',
  'sere',
  'serena',
  'serene',
  'sereni',
  'sereno',
  'seri',
  'seria',
  'serie',
  'serio',
  'serra',
  'serrai',
  'serre',
  'serri',
  'serva',
  'serve',
  'servi',
  'servo',
  'sessi',
  'seta',
  'sete',
  'setola',
  'setole',
  'setta',
  'severa',
  'severe',
  'severi',
  'severo',
  'sf',
  'sfama',
  'sfamai',
  'sfami',
  'sfamo',
  'sfarzi',
  'sfarzo',
  'sfasci',
  'sfatta',
  'sfatte',
  'sfatti',
  'sfatto',
  'sfera',
  'sfere',
  'sfibra',
  'sfibri',
  'sfibro',
  'sfida',
  'sfidai',
  'sfide',
  'sfidi',
  'sfido',
  'sfila',
  'sfilai',
  'sfilo',
  'sfinge',
  'sfingi',
  'sfinii',
  'sfiora',
  'sfiori',
  'sfioro',
  'sfitta',
  'sfitte',
  'sfitti',
  'sfitto',
  'sfocia',
  'sfocio',
  'sfogai',
  'sfoghi',
  'sfogli',
  'sfogo',
  'sfonda',
  'sfondi',
  'sfondo',
  'sforzi',
  'sforzo',
  'sfregi',
  'sfrego',
  'sg',
  'sgelo',
  'sgobba',
  'sgobbi',
  'sgobbo',
  'sgorga',
  'sgorgo',
  'sgrana',
  'sgrani',
  'sgrano',
  'sgrava',
  'sgravi',
  'sgravo',
  'sgrida',
  'sgridi',
  'sgrido',
  'sh',
  'si',
  'sia',
  'siamo',
  'siano',
  'siate',
  'sibila',
  'sibili',
  'sibilo',
  'sicari',
  'sicura',
  'sicure',
  'sicuri',
  'sicuro',
  'sidro',
  'sieda',
  'siede',
  'siedi',
  'siedo',
  'siepe',
  'siepi',
  'sieri',
  'siero',
  'siesta',
  'siete',
  'sifone',
  'sifoni',
  'sigari',
  'sigaro',
  'sigla',
  'sigle',
  'sii',
  'silura',
  'siluri',
  'siluro',
  'simile',
  'simili',
  'simula',
  'simuli',
  'simulo',
  'sino',
  'sipari',
  'sirene',
  'situa',
  'situai',
  'situo',
  'sj',
  'sk',
  'sl',
  'slacci',
  'slanci',
  'slargo',
  'slavo',
  'sleale',
  'sleali',
  'slega',
  'slegai',
  'sleghi',
  'slego',
  'slitta',
  'slitte',
  'slitti',
  'slitto',
  'sloga',
  'sloghi',
  'slogo',
  'sm',
  'smacco',
  'smalta',
  'smalti',
  'smalto',
  'smania',
  'smanie',
  'smessa',
  'smesse',
  'smessi',
  'smesso',
  'smetta',
  'smette',
  'smetti',
  'smetto',
  'smezza',
  'smezzi',
  'smezzo',
  'smilza',
  'smilze',
  'smilzi',
  'smilzo',
  'smise',
  'smisi',
  'smonta',
  'smonto',
  'smorta',
  'smorte',
  'smorti',
  'smorto',
  'smorza',
  'smorzi',
  'smorzo',
  'smossa',
  'smosse',
  'smossi',
  'smosso',
  'smuova',
  'smuove',
  'smuovi',
  'smuovo',
  'sn',
  'snoda',
  'snodai',
  'snodi',
  'snodo',
  'so',
  'soave',
  'soavi',
  'sobri',
  'sobria',
  'sobrie',
  'sobrio',
  'soci',
  'socio',
  'soda',
  'sode',
  'sodi',
  'sodio',
  'sodo',
  'soffi',
  'soffia',
  'soffio',
  'soffra',
  'soffre',
  'soffro',
  'sogna',
  'sognai',
  'sogni',
  'sogno',
  'sola',
  'solai',
  'solaio',
  'solca',
  'solcai',
  'solchi',
  'solco',
  'sole',
  'soli',
  'solida',
  'solide',
  'solidi',
  'solido',
  'solite',
  'soliti',
  'solito',
  'solo',
  'somma',
  'somme',
  'sommi',
  'sommo',
  'sonda',
  'sondai',
  'sonde',
  'sondi',
  'sondo',
  'sonni',
  'sonno',
  'sono',
  'sonora',
  'sonore',
  'sonori',
  'sonoro',
  'sopii',
  'sopra',
  'sorcio',
  'sorda',
  'sorde',
  'sordi',
  'sorge',
  'sorse',
  'sorsi',
  'sorso',
  'sorte',
  'sorto',
  'sosia',
  'sosta',
  'sostai',
  'soste',
  'sosti',
  'sosto',
  'sotto',
  'sp',
  'spacca',
  'spade',
  'spaghi',
  'spago',
  'spalla',
  'spalle',
  'spalma',
  'spalmi',
  'spalmo',
  'spanda',
  'spande',
  'spandi',
  'spando',
  'spanna',
  'spanne',
  'spara',
  'sparai',
  'spari',
  'sparii',
  'sparla',
  'sparli',
  'sparlo',
  'sparo',
  'sparsa',
  'sparse',
  'sparsi',
  'sparso',
  'spazi',
  'spazia',
  'spazio',
  'spazza',
  'spazzi',
  'spazzo',
  'specie',
  'speck',
  'spedii',
  'spegne',
  'spegni',
  'spella',
  'spelli',
  'spello',
  'spende',
  'spendo',
  'spenga',
  'spengo',
  'spensi',
  'spenta',
  'spente',
  'spenti',
  'spento',
  'spera',
  'sperai',
  'sperda',
  'sperde',
  'sperdi',
  'sperdo',
  'spersa',
  'sperso',
  'speri',
  'spero',
  'spesa',
  'spesai',
  'spese',
  'spesi',
  'speso',
  'spesso',
  'spetta',
  'spetto',
  'spezia',
  'spezie',
  'spezza',
  'spezzi',
  'spezzo',
  'spia',
  'spiana',
  'spiani',
  'spiano',
  'spicca',
  'spicco',
  'spie',
  'spiedi',
  'spiedo',
  'spiega',
  'spiego',
  'spiga',
  'spighe',
  'spilla',
  'spilli',
  'spina',
  'spine',
  'spinge',
  'spingi',
  'spingo',
  'spinse',
  'spinta',
  'spinte',
  'spinti',
  'spinto',
  'spira',
  'spirai',
  'spiri',
  'spiro',
  'spogli',
  'spola',
  'spole',
  'sponda',
  'sponde',
  'sporco',
  'sporga',
  'sporge',
  'sporgi',
  'sporgo',
  'sporse',
  'sporta',
  'sporte',
  'sporti',
  'sporto',
  'sposa',
  'spose',
  'sposi',
  'sposo',
  'sposta',
  'sposti',
  'sposto',
  'spregi',
  'sprema',
  'spreme',
  'spremi',
  'spremo',
  'sprona',
  'sproni',
  'sprono',
  'spugna',
  'spugne',
  'spuma',
  'spume',
  'spunta',
  'spunti',
  'spunto',
  'sputa',
  'sputi',
  'sputo',
  'sq',
  'squali',
  'squalo',
  'squama',
  'squame',
  'sr',
  'ss',
  'sss',
  'ssss',
  'st',
  'sta',
  'stacca',
  'stacco',
  'stadi',
  'stadio',
  'staffa',
  'staffe',
  'stagni',
  'stagno',
  'stai',
  'stalla',
  'stalle',
  'stammi',
  'stampa',
  'stampe',
  'stampi',
  'stampo',
  'stana',
  'stanai',
  'stanca',
  'stanco',
  'stando',
  'stani',
  'stanno',
  'stano',
  'stanza',
  'stanze',
  'stanzi',
  'stappa',
  'stappi',
  'stappo',
  'starai',
  'starci',
  'starei',
  'staro',
  'starti',
  'stata',
  'state',
  'stati',
  'stato',
  'statua',
  'statue',
  'stavi',
  'stavo',
  'stazza',
  'stazze',
  'stazzi',
  'stazzo',
  'stecca',
  'steli',
  'stella',
  'stelle',
  'stelo',
  'stemmi',
  'stenda',
  'stende',
  'stendi',
  'stendo',
  'stenta',
  'stenti',
  'stento',
  'steppa',
  'steppe',
  'sterco',
  'sterno',
  'sterza',
  'sterzi',
  'sterzo',
  'stesa',
  'stese',
  'stesi',
  'steso',
  'stessa',
  'stesse',
  'stessi',
  'stesso',
  'stette',
  'stia',
  'stiano',
  'stile',
  'stili',
  'stima',
  'stimai',
  'stime',
  'stimi',
  'stimo',
  'stinco',
  'stira',
  'stirai',
  'stiri',
  'stiro',
  'stirpe',
  'stirpi',
  'stiva',
  'stivai',
  'stive',
  'stivo',
  'stizza',
  'sto',
  'stoffa',
  'stoffe',
  'stola',
  'stole',
  'stona',
  'stonai',
  'stoni',
  'stono',
  'stoppa',
  'stoppe',
  'storia',
  'storie',
  'stormi',
  'stormo',
  'storte',
  'storti',
  'strada',
  'strade',
  'strage',
  'stragi',
  'strana',
  'strane',
  'strani',
  'strano',
  'strati',
  'strato',
  'strida',
  'stride',
  'stridi',
  'strido',
  'strofa',
  'strofe',
  'stu',
  'stucca',
  'stucco',
  'studi',
  'studia',
  'studio',
  'stufi',
  'stufo',
  'stuola',
  'stupii',
  'sturai',
  'sturo',
  'su',
  'sua',
  'subito',
  'succhi',
  'succo',
  'suda',
  'sudai',
  'sudano',
  'sudare',
  'sudata',
  'sudate',
  'sudati',
  'sudava',
  'sudavi',
  'sudavo',
  'sudi',
  'sudice',
  'sudici',
  'sudino',
  'sudo',
  'sudore',
  'sudori',
  'sue',
  'sugli',
  'sui',
  'suini',
  'suino',
  'sulla',
  'sulle',
  'sullo',
  'sunto',
  'suo',
  'suoi',
  'suola',
  'suole',
  'suona',
  'suonai',
  'suoni',
  'suono',
  'suora',
  'suore',
  'supera',
  'superi',
  'supero',
  'supine',
  'supini',
  'supino',
  'susina',
  'susine',
  'sutura',
  'suture',
  'sv',
  'svaga',
  'svagai',
  'svaghi',
  'svago',
  'svanii',
  'svaso',
  'svegli',
  'svelta',
  'svelte',
  'svelti',
  'svelto',
  'sverni',
  'sverno',
  'svia',
  'sviai',
  'sviamo',
  'sviare',
  'sviata',
  'sviati',
  'sviato',
  'sviava',
  'sviavi',
  'svio',
  'svisa',
  'svisai',
  'svisi',
  'sviso',
  'svista',
  'sviste',
  'svita',
  'svitai',
  'sviti',
  'svito',
  'svolga',
  'svolge',
  'svolgi',
  'svolgo',
  'svolta',
  'svolte',
  'svolti',
  'svolto',
  'svuota',
  'svuoti',
  'svuoto',
  'sw',
  'sx',
  'sy',
  'sz',
  't',
  'tac',
  'tacchi',
  'taccia',
  'taccio',
  'tacco',
  'tace',
  'tacere',
  'taceva',
  'tacevo',
  'taci',
  'tacita',
  'tacite',
  'taciti',
  'tacito',
  'tacque',
  'tacqui',
  'tafano',
  'tagli',
  'taglia',
  'taglie',
  'talco',
  'tale',
  'tali',
  'talpa',
  'talpe',
  'talune',
  'tana',
  'tane',
  'tanfo',
  'tanga',
  'tanta',
  'tante',
  'tanti',
  'tanto',
  'tappa',
  'tappe',
  'tappi',
  'tappo',
  'tara',
  'tarata',
  'tarate',
  'tarati',
  'tarda',
  'tardai',
  'tardi',
  'tardo',
  'tare',
  'targa',
  'targhe',
  'tarpa',
  'tarpo',
  'tasca',
  'tasche',
  'tassa',
  'tassai',
  'tasse',
  'tassi',
  'tasso',
  'tasta',
  'tastai',
  'tasti',
  'tasto',
  'tatto',
  'tatua',
  'tatuai',
  'tatui',
  'tatuo',
  'tavole',
  'tavoli',
  'tazza',
  'tazze',
  'tb',
  'tc',
  'td',
  'te',
  'teatri',
  'teatro',
  'tedi',
  'tedia',
  'tediai',
  'tedino',
  'tedio',
  'tegame',
  'tegola',
  'tegole',
  'teiera',
  'teiere',
  'tela',
  'telai',
  'tele',
  'teli',
  'telo',
  'tema',
  'temi',
  'tempi',
  'tempia',
  'tempie',
  'tempo',
  'tempra',
  'tempri',
  'tempro',
  'tenaci',
  'tenda',
  'tende',
  'tenera',
  'tenere',
  'teneri',
  'tenero',
  'tenete',
  'teneva',
  'tenevi',
  'tenevo',
  'tenga',
  'tengo',
  'tenne',
  'tenni',
  'tenore',
  'tenori',
  'tenta',
  'tentai',
  'tenti',
  'tento',
  'tenue',
  'tenui',
  'tenuta',
  'tenute',
  'tenuti',
  'tenuto',
  'teoria',
  'teorie',
  'tepore',
  'tepori',
  'terga',
  'terge',
  'tergi',
  'tergo',
  'terme',
  'termos',
  'terna',
  'terne',
  'terno',
  'terra',
  'terrai',
  'terre',
  'tersa',
  'terse',
  'tersi',
  'terso',
  'terzi',
  'terzo',
  'tesa',
  'teschi',
  'tesina',
  'tesla',
  'tese',
  'tesi',
  'teso',
  'tesori',
  'tesoro',
  'tessa',
  'tessei',
  'tessi',
  'tesso',
  'testa',
  'teste',
  'testi',
  'testo',
  'tetano',
  'tetra',
  'tetre',
  'tetri',
  'tetro',
  'tetti',
  'tetto',
  'tf',
  'tg',
  'th',
  'ti',
  'tibia',
  'tibie',
  'tiene',
  'tieni',
  'tienti',
  'tifo',
  'tifone',
  'tifoni',
  'tigli',
  'tiglio',
  'tigna',
  'tigne',
  'tigri',
  'timbra',
  'timbri',
  'timbro',
  'timida',
  'timide',
  'timidi',
  'timido',
  'timone',
  'timore',
  'timori',
  'tini',
  'tino',
  'tinta',
  'tinte',
  'tinti',
  'tinto',
  'tipa',
  'tipe',
  'tipi',
  'tipica',
  'tipici',
  'tipo',
  'tira',
  'tirai',
  'tirare',
  'tirata',
  'tirati',
  'tirato',
  'tirava',
  'tiravi',
  'tiravo',
  'tirchi',
  'tiri',
  'tirino',
  'tiro',
  'tisana',
  'tisane',
  'titoli',
  'titolo',
  'tizi',
  'tizia',
  'tizie',
  'tizio',
  'tj',
  'tk',
  'tl',
  'tm',
  'tn',
  'to',
  'tocca',
  'toccai',
  'tocchi',
  'tocco',
  'toga',
  'toghe',
  'togli',
  'toglie',
  'tolga',
  'tolse',
  'tolsi',
  'tolta',
  'tolte',
  'tolti',
  'tolto',
  'tomaie',
  'tomba',
  'tonaca',
  'tonaci',
  'tonaco',
  'tonchi',
  'tonda',
  'tonde',
  'tondi',
  'tonfi',
  'tonfo',
  'toni',
  'tonni',
  'tonno',
  'tonta',
  'tonte',
  'tonti',
  'tonto',
  'topazi',
  'topo',
  'toppa',
  'toppe',
  'torace',
  'toraci',
  'torba',
  'torbe',
  'torce',
  'torchi',
  'torcia',
  'tori',
  'torna',
  'tornai',
  'torne',
  'tornea',
  'tornei',
  'torneo',
  'torni',
  'tornii',
  'tornio',
  'torno',
  'toro',
  'torre',
  'torta',
  'torte',
  'torti',
  'torto',
  'tosa',
  'tosai',
  'tosano',
  'tosare',
  'tosata',
  'tosate',
  'tosati',
  'tosato',
  'tosava',
  'tosavi',
  'tosavo',
  'tosi',
  'tosino',
  'toso',
  'tosse',
  'tossi',
  'tossii',
  'tosta',
  'tostai',
  'toste',
  'tosti',
  'totale',
  'totali',
  'totani',
  'totano',
  'tozza',
  'tozze',
  'tozzo',
  'tp',
  'tq',
  'tr',
  'tra',
  'tracce',
  'tracci',
  'tradii',
  'trae',
  'traevo',
  'traina',
  'traini',
  'traino',
  'tralci',
  'trama',
  'tramai',
  'trame',
  'trami',
  'tramo',
  'tranci',
  'tranne',
  'trarla',
  'trarre',
  'trasse',
  'tratta',
  'tratte',
  'tratti',
  'tratto',
  'trauma',
  'traumi',
  'trave',
  'travi',
  'travia',
  'travio',
  'tre',
  'tregua',
  'trema',
  'tremai',
  'tremi',
  'tremo',
  'treni',
  'treno',
  'trenta',
  'triade',
  'trilla',
  'trilli',
  'trillo',
  'tripla',
  'triplo',
  'trippa',
  'trippe',
  'triste',
  'tristi',
  'trita',
  'trite',
  'triti',
  'trito',
  'trofei',
  'trofeo',
  'tromba',
  'trombe',
  'tronca',
  'tronco',
  'troni',
  'trono',
  'troppa',
  'troppe',
  'troppi',
  'troppo',
  'trota',
  'trote',
  'trotta',
  'trotti',
  'trotto',
  'trova',
  'trovai',
  'trovi',
  'trovo',
  'trucca',
  'trucco',
  'truffe',
  'truffi',
  'truffo',
  'truppa',
  'truppe',
  'ts',
  'tt',
  'ttt',
  'tttt',
  'tu',
  'tua',
  'tuba',
  'tubano',
  'tubare',
  'tubata',
  'tubate',
  'tubato',
  'tubavo',
  'tuberi',
  'tubero',
  'tubi',
  'tubino',
  'tubo',
  'tue',
  'tuffa',
  'tuffi',
  'tuffo',
  'tufo',
  'tuguri',
  'tulle',
  'tuo',
  'tuona',
  'tuoni',
  'tuono',
  'turai',
  'turba',
  'turbai',
  'turbo',
  'turca',
  'turchi',
  'turni',
  'turno',
  'tutela',
  'tutele',
  'tuteli',
  'tutelo',
  'tutore',
  'tutori',
  'tutta',
  'tutte',
  'tutti',
  'tutto',
  'tuv',
  'tv',
  'tw',
  'tx',
  'ty',
  'tz',
  'u',
  'ub',
  'uc',
  'ud',
  'udendo',
  'udii',
  'udire',
  'udirla',
  'udita',
  'udite',
  'uditi',
  'udivo',
  'ue',
  'uf',
  'uffici',
  'ufo',
  'ug',
  'uguale',
  'uguali',
  'uh',
  'ui',
  'uj',
  'uk',
  'ul',
  'ultima',
  'ultime',
  'ultimi',
  'ultimo',
  'ulula',
  'ululi',
  'ululo',
  'um',
  'umane',
  'umani',
  'umano',
  'umida',
  'umide',
  'umidi',
  'umido',
  'umile',
  'umili',
  'umilia',
  'umilio',
  'umore',
  'umori',
  'un',
  'una',
  'uncini',
  'uncino',
  'unendo',
  'unge',
  'ungere',
  'unghia',
  'unghie',
  'ungi',
  'ungo',
  'ungono',
  'uniamo',
  'uniate',
  'unica',
  'uniche',
  'unici',
  'unico',
  'unii',
  'unione',
  'unioni',
  'unirai',
  'unire',
  'unirei',
  'unisce',
  'unisci',
  'unisco',
  'unissi',
  'uniste',
  'unisti',
  'unita',
  'unite',
  'uniti',
  'unito',
  'univa',
  'univo',
  'uno',
  'unta',
  'unte',
  'unti',
  'unto',
  'uo',
  'uomini',
  'uomo',
  'up',
  'uq',
  'ur',
  'urbana',
  'urbane',
  'urbani',
  'urbano',
  'urla',
  'urlai',
  'urlano',
  'urlare',
  'urlata',
  'urlate',
  'urlati',
  'urlato',
  'urlava',
  'urlavi',
  'urlavo',
  'urli',
  'urlino',
  'urlo',
  'urna',
  'urne',
  'urta',
  'urtano',
  'urtare',
  'urtata',
  'urtate',
  'urtati',
  'urtato',
  'urtava',
  'urtavi',
  'urtavo',
  'urti',
  'urto',
  'us',
  'usa',
  'usai',
  'usando',
  'usano',
  'usanza',
  'usanze',
  'usare',
  'usasse',
  'usassi',
  'usaste',
  'usasti',
  'usata',
  'usate',
  'usati',
  'usato',
  'usava',
  'usavi',
  'usavo',
  'usci',
  'uscii',
  'uscio',
  'uscire',
  'uscita',
  'uscite',
  'usciti',
  'uscito',
  'usciva',
  'uscivo',
  'userai',
  'userei',
  'usi',
  'usiamo',
  'usiate',
  'uso',
  'usuale',
  'usuali',
  'usura',
  'usurai',
  'usure',
  'usurpi',
  'usurpo',
  'ut',
  'utente',
  'utenti',
  'utenza',
  'utile',
  'utili',
  'utopia',
  'utopie',
  'uu',
  'uuu',
  'uuuu',
  'uv',
  'uve',
  'uvw',
  'uw',
  'ux',
  'uy',
  'uz',
  'v',
  'vacche',
  'vada',
  'vadano',
  'vado',
  'vaga',
  'vagano',
  'vagare',
  'vagata',
  'vagate',
  'vagati',
  'vagato',
  'vagava',
  'vagavi',
  'vagavo',
  'vaghe',
  'vaghi',
  'vagire',
  'vagita',
  'vagite',
  'vagiti',
  'vagito',
  'vagiva',
  'vagivo',
  'vagli',
  'vaglia',
  'vaglio',
  'vagone',
  'vagoni',
  'vaioli',
  'vaiolo',
  'vale',
  'valere',
  'valeva',
  'valga',
  'valgo',
  'valica',
  'valida',
  'valide',
  'validi',
  'valido',
  'valle',
  'valli',
  'vallo',
  'valore',
  'valori',
  'valuta',
  'valute',
  'valuto',
  'vampa',
  'vana',
  'vane',
  'vanga',
  'vanghi',
  'vango',
  'van',
  'vani',
  'vanno',
  'vano',
  'vanta',
  'vantai',
  'vanti',
  'vanto',
  'vapore',
  'vapori',
  'vara',
  'varare',
  'varate',
  'varati',
  'varato',
  'varava',
  'varavi',
  'varavo',
  'varchi',
  'varco',
  'vari',
  'variai',
  'varie',
  'varino',
  'vario',
  'vasaio',
  'vasca',
  'vasche',
  'vaso',
  'vassoi',
  'vasta',
  'vaste',
  'vasti',
  'vasto',
  'vb',
  'vc',
  'vd',
  've',
  'vecchi',
  'veda',
  'vedano',
  'vede',
  'vedere',
  'vedete',
  'vedeva',
  'vedevi',
  'vedevo',
  'vedi',
  'vedili',
  'vedo',
  'vedono',
  'vedova',
  'vedove',
  'vedovi',
  'vedovo',
  'vedrai',
  'veduta',
  'vedute',
  'veduti',
  'veduto',
  'vegeta',
  'vegete',
  'vegeti',
  'vegeto',
  'vegli',
  'veglia',
  'veglio',
  'vela',
  'velai',
  'velano',
  'velare',
  'velata',
  'velate',
  'velati',
  'velava',
  'velavi',
  'velavo',
  'vele',
  'veli',
  'velina',
  'veline',
  'velino',
  'velo',
  'veloce',
  'veloci',
  'vena',
  'venale',
  'venali',
  'venda',
  'vende',
  'vendi',
  'venera',
  'venere',
  'veneri',
  'venero',
  'venga',
  'venire',
  'venite',
  'veniva',
  'venivi',
  'venivo',
  'venne',
  'venni',
  'venti',
  'vento',
  'ventre',
  'ventri',
  'venuta',
  'venute',
  'venuti',
  'venuto',
  'vera',
  'verbi',
  'verbo',
  'verde',
  'verdi',
  'vere',
  'verga',
  'verghe',
  'veri',
  'verme',
  'vermi',
  'verrei',
  'versa',
  'versai',
  'versi',
  'verso',
  'vespa',
  'vespe',
  'vespri',
  'vespro',
  'vessa',
  'vessai',
  'vessi',
  'vesso',
  'veste',
  'vesti',
  'vesto',
  'veti',
  'vetri',
  'vetta',
  'vette',
  'vf',
  'vg',
  'vh',
  'vi',
  'viaggi',
  'viale',
  'viali',
  'vibra',
  'vibrai',
  'vibri',
  'vicari',
  'vicina',
  'vicine',
  'vicini',
  'vicino',
  'vicoli',
  'vicolo',
  'vide',
  'video',
  'videro',
  'vidima',
  'vidimi',
  'vidimo',
  'vie',
  'viene',
  'vieni',
  'vieta',
  'vietai',
  'vieto',
  'vigere',
  'vigile',
  'vigili',
  'vigilo',
  'vigne',
  'vigore',
  'vigori',
  'vile',
  'villa',
  'ville',
  'vimini',
  'vinaio',
  'vinci',
  'vinco',
  'vini',
  'vino',
  'vinta',
  'vinte',
  'vinto',
  'viola',
  'violai',
  'viole',
  'violi',
  'violo',
  'vipera',
  'vipere',
  'vira',
  'virano',
  'virata',
  'virate',
  'virati',
  'virato',
  'virava',
  'viravi',
  'viravo',
  'virile',
  'virili',
  'visi',
  'visita',
  'visite',
  'visiti',
  'visito',
  'viso',
  'visone',
  'visoni',
  'vispa',
  'vispe',
  'vispi',
  'visse',
  'vissi',
  'vista',
  'vistai',
  'viste',
  'visti',
  'visto',
  'vita',
  'vitale',
  'vitali',
  'vite',
  'viti',
  'viva',
  'vivace',
  'vivaci',
  'vivaio',
  'vivano',
  'vive',
  'vivere',
  'vivete',
  'viveva',
  'vivevi',
  'vivevo',
  'vivi',
  'vivo',
  'vivono',
  'vizi',
  'vizia',
  'vizio',
  'vj',
  'vk',
  'vl',
  'vm',
  'vn',
  'vo',
  'vocale',
  'vocali',
  'voce',
  'voci',
  'voga',
  'vogai',
  'vogano',
  'vogata',
  'vogate',
  'vogati',
  'vogato',
  'vogava',
  'voghi',
  'voglia',
  'voglie',
  'voglio',
  'vogo',
  'voi',
  'volai',
  'volano',
  'volare',
  'volata',
  'volate',
  'volati',
  'volava',
  'volavi',
  'volavo',
  'volere',
  'volete',
  'voleva',
  'volevi',
  'volge',
  'volgo',
  'voli',
  'volino',
  'volli',
  'volo',
  'volpe',
  'volpi',
  'volse',
  'volta',
  'voltai',
  'volte',
  'volti',
  'volto',
  'volumi',
  'voluta',
  'volute',
  'voluti',
  'voluto',
  'vorace',
  'voraci',
  'vorrai',
  'vorrei',
  'vostro',
  'voti',
  'votiva',
  'votive',
  'votivi',
  'votivo',
  'voto',
  'vp',
  'vq',
  'vr',
  'vs',
  'vt',
  'vu',
  'vuoi',
  'vuole',
  'vuota',
  'vuotai',
  'vuoti',
  'vuoto',
  'vv',
  'vvv',
  'vvvv',
  'vw',
  'vwx',
  'vx',
  'vy',
  'vz',
  'w',
  'wb',
  'wc',
  'wd',
  'we',
  'wf',
  'wg',
  'wh',
  'wi',
  'wj',
  'wk',
  'wl',
  'wm',
  'wn',
  'wo',
  'wp',
  'wq',
  'wr',
  'ws',
  'wt',
  'wu',
  'wv',
  'ww',
  'www',
  'wwww',
  'wx',
  'wxy',
  'wy',
  'wz',
  'x',
  'xb',
  'xc',
  'xd',
  'xe',
  'xf',
  'xg',
  'xh',
  'xi',
  'xj',
  'xk',
  'xl',
  'xm',
  'xn',
  'xo',
  'xp',
  'xq',
  'xr',
  'xs',
  'xt',
  'xu',
  'xv',
  'xw',
  'xx',
  'xxx',
  'xxxx',
  'xy',
  'xyz',
  'xz',
  'y',
  'yb',
  'yc',
  'yd',
  'ye',
  'yf',
  'yg',
  'yh',
  'yi',
  'yj',
  'yk',
  'yl',
  'ym',
  'yn',
  'yo',
  'yp',
  'yq',
  'yr',
  'ys',
  'yt',
  'yu',
  'yv',
  'yw',
  'yx',
  'yy',
  'yyy',
  'yyyy',
  'yz',
  'z',
  'zaino',
  'zampa',
  'zampe',
  'zanna',
  'zappai',
  'zappi',
  'zappo',
  'zb',
  'zc',
  'zd',
  'ze',
  'zecca',
  'zelo',
  'zeppa',
  'zeppe',
  'zeppi',
  'zeppo',
  'zeri',
  'zf',
  'zg',
  'zh',
  'zi',
  'zia',
  'zie',
  'zii',
  'zio',
  'zitta',
  'zitti',
  'zitto',
  'zj',
  'zk',
  'zl',
  'zm',
  'zn',
  'zo',
  'zolfo',
  'zolla',
  'zona',
  'zone',
  'zoppa',
  'zoppe',
  'zoppi',
  'zoppo',
  'zotica',
  'zotici',
  'zotico',
  'zp',
  'zq',
  'zr',
  'zs',
  'zt',
  'zu',
  'zucca',
  'zucche',
  'zuffa',
  'zuffe',
  'zuppa',
  'zuppe',
  'zv',
  'zw',
  'zx',
  'zy',
  'zz',
  'zzz',
  'zzzz',
];
