export const latin = [
  'abantes',
  'abas',
  'abdens',
  'abdera',
  'abduci',
  'abducit',
  'abducta',
  'abducto',
  'abduxit',
  'abeam',
  'abeas',
  'aberant',
  'aberrat',
  'abesse',
  'abesset',
  'abeunt',
  'abeunte',
  'abfuit',
  'abibit',
  'abierat',
  'abiere',
  'abietes',
  'abigere',
  'abigi',
  'abigit',
  'abiisse',
  'abiit',
  'abire',
  'abirent',
  'abiret',
  'abit',
  'abite',
  'abito',
  'abjecit',
  'ablatas',
  'ablatus',
  'abluet',
  'ablut',
  'abnepos',
  'abrepti',
  'abrupta',
  'absint',
  'absolvi',
  'absque',
  'absurda',
  'abuti',
  'abydeno',
  'abydo',
  'abydum',
  'acanthi',
  'acantho',
  'acarnan',
  'accedit',
  'accensa',
  'accenso',
  'accepi',
  'accepit',
  'accepta',
  'accepti',
  'accidat',
  'accidit',
  'accipi',
  'accipio',
  'accipit',
  'accitis',
  'accitos',
  'accolis',
  'accura',
  'accusat',
  'acer',
  'acerb',
  'acerbam',
  'acerbis',
  'acerbum',
  'acervi',
  'acervum',
  'aces',
  'achaei',
  'achaeus',
  'achaia',
  'acie',
  'aciei',
  'acies',
  'acinace',
  'acquiri',
  'acre',
  'acrem',
  'acriora',
  'acris',
  'acriter',
  'actam',
  'acti',
  'actorum',
  'actos',
  'actuali',
  'acuito',
  'acuitur',
  'aculeo',
  'acutam',
  'acuti',
  'acutis',
  'acutum',
  'adacta',
  'adaptat',
  'adderet',
  'addi',
  'addita',
  'addo',
  'adducar',
  'adduci',
  'adducit',
  'adducta',
  'adducti',
  'adducto',
  'adduxit',
  'adegit',
  'ademit',
  'ademta',
  'ademtas',
  'adeo',
  'adeoque',
  'adeptus',
  'aderant',
  'aderit',
  'ades',
  'adesse',
  'adessem',
  'adest',
  'adeste',
  'adestis',
  'adeunt',
  'adeunti',
  'adfeci',
  'adfecit',
  'adfecta',
  'adfecti',
  'adferat',
  'adfero',
  'adferre',
  'adfici',
  'adficit',
  'adfirmo',
  'adfuere',
  'adfuero',
  'adfusa',
  'adgesta',
  'adgesto',
  'adhibeo',
  'adhuc',
  'adicran',
  'adierat',
  'adigis',
  'adigit',
  'adiisse',
  'adiisti',
  'adiit',
  'adimunt',
  'adipe',
  'adipem',
  'adire',
  'adiret',
  'aditu',
  'aditum',
  'adituri',
  'adjeci',
  'adjecit',
  'adjici',
  'adjutor',
  'adjutus',
  'adlata',
  'adlatum',
  'adlatus',
  'adluta',
  'admisi',
  'admissi',
  'admisso',
  'admitte',
  'admitto',
  'admixta',
  'admixti',
  'admodum',
  'admotis',
  'adnexas',
  'adolent',
  'adoleto',
  'adorat',
  'adortus',
  'adparet',
  'adpone',
  'adponit',
  'adpoti',
  'adprime',
  'adpulsa',
  'adraste',
  'adrasti',
  'adsensi',
  'adsequi',
  'adsidet',
  'adsidue',
  'adsiduo',
  'adstare',
  'adsueto',
  'adsuevi',
  'adsum',
  'adultae',
  'adulti',
  'adunco',
  'adustis',
  'adventu',
  'adversi',
  'adverso',
  'adverto',
  'adveso',
  'advocat',
  'aeace',
  'aeacem',
  'aeaces',
  'aeaci',
  'aeacis',
  'aeacum',
  'aede',
  'aedes',
  'aedibus',
  'aedium',
  'aegae',
  'aegaei',
  'aegaeo',
  'aegeas',
  'aeger',
  'aegeus',
  'aegidae',
  'aegidas',
  'aegilea',
  'aegilia',
  'aeginae',
  'aegium',
  'aeglos',
  'aegos',
  'aegpyto',
  'aegre',
  'aemulo',
  'aeneae',
  'aeneas',
  'aenei',
  'aeneis',
  'aeneo',
  'aenum',
  'aeoli',
  'aeoliae',
  'aeorum',
  'aequali',
  'aequare',
  'aequat',
  'aequata',
  'aeque',
  'aequius',
  'aequo',
  'aequum',
  'aere',
  'aeris',
  'aeropi',
  'aeropus',
  'aesanii',
  'aesopi',
  'aesopum',
  'aestate',
  'aestimo',
  'aestu',
  'aestus',
  'aetas',
  'aetate',
  'aetatem',
  'aetati',
  'aeterno',
  'aetheri',
  'aetion',
  'aetioni',
  'aetolia',
  'aevi',
  'aevum',
  'affatus',
  'affecta',
  'affecto',
  'afferri',
  'afficit',
  'afri',
  'africa',
  'africae',
  'africam',
  'africus',
  'afris',
  'agaei',
  'agam',
  'agamus',
  'agant',
  'agas',
  'agat',
  'agave',
  'agbali',
  'agebant',
  'agedum',
  'agendis',
  'agendum',
  'agens',
  'agentes',
  'ager',
  'agerent',
  'ageret',
  'agetus',
  'aggere',
  'aggerem',
  'aggeres',
  'agidis',
  'agilior',
  'agis',
  'agitans',
  'agitare',
  'agitata',
  'agite',
  'agitor',
  'agmina',
  'agmine',
  'agmini',
  'agminum',
  'agni',
  'agnina',
  'agninis',
  'agnito',
  'agnosci',
  'agnosco',
  'agnum',
  'agri',
  'agrium',
  'agronem',
  'agrorum',
  'agros',
  'agunt',
  'ahenum',
  'aiat',
  'aiebant',
  'ajacem',
  'alacri',
  'alae',
  'alalia',
  'alaliam',
  'alas',
  'alat',
  'alata',
  'alatae',
  'alati',
  'alatum',
  'alaz',
  'alazir',
  'albam',
  'albas',
  'albedo',
  'albi',
  'albido',
  'albis',
  'albo',
  'albos',
  'album',
  'alcaei',
  'alcaeo',
  'alcid',
  'alcidae',
  'alcmeon',
  'alea',
  'aleae',
  'alebat',
  'aleium',
  'alendum',
  'alens',
  'alente',
  'aleuae',
  'algere',
  'aliam',
  'aliaque',
  'aliarum',
  'alias',
  'aliave',
  'alibi',
  'alicubi',
  'alienas',
  'alienis',
  'alieno',
  'alienus',
  'alii',
  'aliique',
  'aliis',
  'aliisve',
  'alioque',
  'alioqui',
  'alios',
  'aliquae',
  'aliquam',
  'aliquas',
  'aliquem',
  'aliquid',
  'aliquis',
  'aliquo',
  'aliquot',
  'alis',
  'alit',
  'alitta',
  'aliudne',
  'aliunde',
  'alius',
  'allatae',
  'allatis',
  'allato',
  'allium',
  'alma',
  'almam',
  'alon',
  'alpenos',
  'alpis',
  'altam',
  'altaria',
  'alte',
  'alter',
  'altera',
  'alteram',
  'alteras',
  'alteri',
  'alteris',
  'altero',
  'alteros',
  'alticom',
  'altos',
  'altum',
  'altus',
  'alum',
  'alunt',
  'aluntur',
  'alveis',
  'alveos',
  'alveum',
  'alvi',
  'alvo',
  'alvum',
  'alvus',
  'amabat',
  'amans',
  'amant',
  'amara',
  'amarus',
  'amasi',
  'amasse',
  'amat',
  'amata',
  'amavit',
  'ambabus',
  'ambae',
  'ambigo',
  'ambili',
  'ambrosi',
  'ambusti',
  'ambusto',
  'amens',
  'amet',
  'amicam',
  'amicas',
  'amice',
  'amico',
  'amictus',
  'amiculi',
  'amiculo',
  'amicus',
  'aminiae',
  'amisero',
  'amisit',
  'amissis',
  'amisso',
  'amittat',
  'amittet',
  'ammonis',
  'amnem',
  'amnes',
  'amnis',
  'amoliri',
  'amor',
  'amore',
  'amorem',
  'amorges',
  'amoris',
  'amoto',
  'ampe',
  'ampelo',
  'ampelon',
  'amphor',
  'ampla',
  'amplae',
  'amplam',
  'amplas',
  'ampli',
  'amplior',
  'amplo',
  'amplum',
  'amplus',
  'amyntae',
  'amyrgii',
  'anates',
  'anaua',
  'anaurus',
  'anchis',
  'ancoram',
  'ancoris',
  'andreae',
  'andrii',
  'andrios',
  'andrum',
  'anetho',
  'angelos',
  'angiten',
  'angrus',
  'anguli',
  'angulo',
  'angulos',
  'angulum',
  'angusta',
  'angusto',
  'anim',
  'animal',
  'animali',
  'animam',
  'animati',
  'animum',
  'animus',
  'anne',
  'anni',
  'annis',
  'annonae',
  'annonis',
  'annorum',
  'annua',
  'annuis',
  'annulis',
  'annulos',
  'annulum',
  'annum',
  'annuo',
  'annus',
  'ansas',
  'ansis',
  'antea',
  'antes',
  'anthen',
  'antiqua',
  'antrum',
  'anum',
  'anysius',
  'aper',
  'aperi',
  'aperiam',
  'aperiat',
  'aperiri',
  'aperta',
  'apertam',
  'aperte',
  'apertis',
  'aperto',
  'apertum',
  'aphetae',
  'aphetas',
  'aphetis',
  'aphyti',
  'apidi',
  'apii',
  'apin',
  'apparet',
  'appetit',
  'appulit',
  'appulsu',
  'apri',
  'apriae',
  'apries',
  'apro',
  'aptans',
  'aptatam',
  'apter',
  'aptior',
  'aptos',
  'apud',
  'apudque',
  'apum',
  'apus',
  'aquae',
  'aquam',
  'aquari',
  'aquas',
  'aquatum',
  'aquavit',
  'aque',
  'aquil',
  'aquila',
  'aquilae',
  'aquis',
  'arabam',
  'arabem',
  'arabes',
  'arabia',
  'arabici',
  'arabio',
  'arabius',
  'arabum',
  'aradius',
  'arae',
  'aram',
  'araneas',
  'araneos',
  'arant',
  'arare',
  'arato',
  'arator',
  'aratrum',
  'aratum',
  'araxe',
  'araxem',
  'araxis',
  'arbor',
  'arborem',
  'arbores',
  'arbori',
  'arboris',
  'arcadas',
  'arcades',
  'arcadia',
  'arcadum',
  'arcana',
  'arcanis',
  'arcem',
  'archan',
  'archia',
  'archiae',
  'archias',
  'arci',
  'arctam',
  'arcte',
  'arcto',
  'arcu',
  'arcubus',
  'arcui',
  'arcum',
  'ardeant',
  'ardebat',
  'ardens',
  'ardente',
  'ardua',
  'ardyis',
  'ardys',
  'aream',
  'areas',
  'arena',
  'arenas',
  'arenosa',
  'arenoso',
  'argen',
  'argenti',
  'argento',
  'arges',
  'argis',
  'argivis',
  'argivos',
  'argivum',
  'argo',
  'argos',
  'arguit',
  'arida',
  'aridas',
  'aries',
  'arietem',
  'arietes',
  'arii',
  'arima',
  'arionis',
  'ariorum',
  'aris',
  'arisbam',
  'arist',
  'aristea',
  'arma',
  'armati',
  'armatis',
  'armatum',
  'armatus',
  'armavit',
  'armenii',
  'armiger',
  'armis',
  'armorum',
  'arpoxai',
  'arripe',
  'arrogo',
  'arsisse',
  'artacam',
  'artaei',
  'artanis',
  'arte',
  'artem',
  'artemve',
  'artes',
  'artibus',
  'artifex',
  'artubus',
  'artybii',
  'arulas',
  'arva',
  'arvis',
  'arvum',
  'aryande',
  'asbolum',
  'ascra',
  'asiae',
  'asiaque',
  'asias',
  'asine',
  'asini',
  'asino',
  'asinum',
  'asmach',
  'asopo',
  'asopus',
  'aspera',
  'asperae',
  'asperam',
  'aspero',
  'aspexi',
  'aspi',
  'asporta',
  'assa',
  'assata',
  'asseri',
  'assesi',
  'assesum',
  'assigno',
  'assyria',
  'assyrii',
  'astaci',
  'asteris',
  'astr',
  'astra',
  'astu',
  'astuto',
  'astyagi',
  'atarneo',
  'ater',
  'athei',
  'atheis',
  'athenas',
  'athenis',
  'athon',
  'athos',
  'athrys',
  'atlante',
  'atlas',
  'atossa',
  'atossae',
  'atque',
  'atqui',
  'atridae',
  'atriis',
  'atrita',
  'atrocem',
  'atropon',
  'atropos',
  'attamen',
  'attendo',
  'attenta',
  'attica',
  'atticam',
  'atticis',
  'attico',
  'atticos',
  'atticum',
  'attigi',
  'atuem',
  'atye',
  'atyis',
  'atyn',
  'atys',
  'auctor',
  'auctore',
  'auctus',
  'audaces',
  'audacia',
  'audax',
  'audeo',
  'audes',
  'audetis',
  'audi',
  'audiam',
  'audias',
  'audiens',
  'audiere',
  'audiero',
  'audio',
  'audire',
  'audirem',
  'audires',
  'audis',
  'audisse',
  'audisti',
  'audit',
  'audita',
  'audite',
  'auditis',
  'auditu',
  'auditum',
  'audivi',
  'auferas',
  'auferat',
  'auferes',
  'aufert',
  'aufugit',
  'augeant',
  'augebis',
  'augendi',
  'augens',
  'augent',
  'auget',
  'augila',
  'augilis',
  'auguria',
  'augurio',
  'aula',
  'aulae',
  'aulas',
  'aura',
  'auras',
  'aure',
  'aureae',
  'auream',
  'aurei',
  'aureis',
  'aureos',
  'aureum',
  'aureus',
  'auri',
  'auribus',
  'auriga',
  'auris',
  'auritum',
  'auro',
  'auror',
  'auroram',
  'ausi',
  'ausint',
  'austrum',
  'ausurum',
  'ausurus',
  'autem',
  'autho',
  'autono',
  'autonoi',
  'auxilia',
  'auxit',
  'avem',
  'aversum',
  'avertit',
  'aves',
  'avesque',
  'avide',
  'avidi',
  'avis',
  'avolare',
  'avum',
  'axem',
  'axillas',
  'axium',
  'azanes',
  'aziris',
  'babylon',
  'baccae',
  'bacchi',
  'baccho',
  'bacchus',
  'bacis',
  'bactra',
  'bactrii',
  'bactros',
  'baculis',
  'baculum',
  'badra',
  'badres',
  'bagaeus',
  'balnei',
  'barbam',
  'barbare',
  'barbari',
  'barbaro',
  'barbis',
  'barcae',
  'barce',
  'bare',
  'bares',
  'barin',
  'basibus',
  'basim',
  'basin',
  'basis',
  'battis',
  'batto',
  'battus',
  'beatis',
  'beatos',
  'beatum',
  'beatus',
  'becos',
  'beidem',
  'beli',
  'bella',
  'bellica',
  'bellici',
  'bellis',
  'bello',
  'bellu',
  'belluam',
  'belluis',
  'bellum',
  'below',
  'beneque',
  'benigna',
  'benigne',
  'benque',
  'bere',
  'bessi',
  'bestia',
  'bestiis',
  'beum',
  'bianti',
  'biantis',
  'bibatur',
  'bibe',
  'bibendi',
  'bibendo',
  'bibere',
  'bibito',
  'bibunt',
  'bidui',
  'biduum',
  'bile',
  'bilem',
  'bina',
  'binae',
  'binas',
  'binorum',
  'bitavi',
  'bithyni',
  'bitonem',
  'bitoni',
  'blandis',
  'bobus',
  'bogen',
  'bona',
  'bonam',
  'bonaque',
  'bonarum',
  'boni',
  'bonis',
  'bonitas',
  'bono',
  'bonos',
  'bonus',
  'bore',
  'borea',
  'boreali',
  'boreas',
  'boryes',
  'bospori',
  'bosporo',
  'bove',
  'bovem',
  'boves',
  'bovilla',
  'bovinae',
  'bovinis',
  'bovino',
  'bovinum',
  'bovis',
  'braccis',
  'brachio',
  'breve',
  'breves',
  'brevia',
  'brevior',
  'brevius',
  'briareo',
  'briges',
  'brongum',
  'brongus',
  'bruma',
  'brygi',
  'brygis',
  'bubares',
  'bubasti',
  'bubulae',
  'bubulci',
  'bubulco',
  'bubulo',
  'budinis',
  'budinus',
  'buerit',
  'buerunt',
  'bulidis',
  'bulis',
  'busae',
  'busirin',
  'buto',
  'byblina',
  'byblo',
  'byblos',
  'byssina',
  'cabiros',
  'cadens',
  'cadere',
  'cadet',
  'cadit',
  'cadmeas',
  'cadmei',
  'cadmeus',
  'cadmi',
  'cadmo',
  'cadmum',
  'cadmus',
  'cadum',
  'cadytin',
  'cadytis',
  'caeci',
  'caeco',
  'caecum',
  'caecus',
  'caede',
  'caedi',
  'caedunt',
  'caesa',
  'caesae',
  'caesi',
  'caesis',
  'caesus',
  'caico',
  'caicum',
  'calceos',
  'calcis',
  'calculo',
  'calida',
  'calidae',
  'calidam',
  'calido',
  'calidus',
  'caligae',
  'caligas',
  'callem',
  'callet',
  'calliam',
  'callias',
  'callide',
  'calore',
  'calorem',
  'calori',
  'caloris',
  'calvos',
  'camaris',
  'cambys',
  'cambyse',
  'cambysi',
  'cameli',
  'camelus',
  'camera',
  'cameram',
  'cameris',
  'camico',
  'camicum',
  'caminum',
  'camirus',
  'campo',
  'campos',
  'campum',
  'camuros',
  'cana',
  'canae',
  'canales',
  'canas',
  'candens',
  'candide',
  'cane',
  'canem',
  'canere',
  'canerem',
  'canes',
  'canibus',
  'canit',
  'cannabi',
  'canobum',
  'canora',
  'cantant',
  'cantor',
  'cantori',
  'cantu',
  'cantui',
  'canum',
  'capacem',
  'capaces',
  'cape',
  'capell',
  'capere',
  'caperet',
  'capesse',
  'capi',
  'capias',
  'capilli',
  'capit',
  'capite',
  'capitis',
  'capitum',
  'capra',
  'caprae',
  'caprina',
  'capris',
  'capsam',
  'capta',
  'captae',
  'captas',
  'captes',
  'captis',
  'captivi',
  'captivo',
  'capto',
  'captos',
  'captu',
  'captum',
  'capturi',
  'captus',
  'cara',
  'carcere',
  'cardiam',
  'careant',
  'careas',
  'careat',
  'careni',
  'carens',
  'carente',
  'carere',
  'careret',
  'cares',
  'caret',
  'cari',
  'caria',
  'cariam',
  'caribus',
  'carica',
  'caricis',
  'caricum',
  'carie',
  'carinam',
  'carinis',
  'carior',
  'carmina',
  'carmine',
  'carnea',
  'carnem',
  'carnes',
  'caro',
  'carpens',
  'carpis',
  'casa',
  'casas',
  'casibus',
  'casium',
  'casmena',
  'caspii',
  'caspium',
  'cast',
  'caste',
  'castos',
  'castra',
  'castris',
  'casum',
  'casuum',
  'catiari',
  'catino',
  'catulis',
  'catulo',
  'catulum',
  'catulus',
  'caucasa',
  'caucaso',
  'cauda',
  'caudae',
  'caudam',
  'caudas',
  'caudis',
  'cauniis',
  'cauno',
  'caunus',
  'causam',
  'causas',
  'cautus',
  'cava',
  'cave',
  'caveat',
  'cavebo',
  'cavens',
  'cavent',
  'cavere',
  'cavetis',
  'caveto',
  'cavis',
  'cavum',
  'ccii',
  'cciii',
  'ccvi',
  'ccviii',
  'ccxiv',
  'ccxix',
  'ccxvi',
  'ccxvii',
  'ccxviii',
  'ccxxii',
  'ccxxiii',
  'ccxxix',
  'ccxxx',
  'ccxxxi',
  'ccxxxii',
  'ccxxxv',
  'cecidit',
  'cecinit',
  'cecrope',
  'cedant',
  'cede',
  'cedent',
  'cedimus',
  'cedo',
  'cedrino',
  'cedunt',
  'celans',
  'celavit',
  'celerem',
  'celtis',
  'censent',
  'censet',
  'centum',
  'ceorum',
  'ceperit',
  'cepi',
  'cepisti',
  'cepit',
  'cepti',
  'cera',
  'cerae',
  'ceram',
  'cerebro',
  'cerere',
  'cererem',
  'ceres',
  'cernens',
  'certa',
  'certam',
  'certare',
  'certas',
  'certat',
  'certe',
  'certi',
  'certior',
  'certis',
  'certo',
  'certum',
  'cervo',
  'cervus',
  'cessare',
  'cessit',
  'cetera',
  'ceterae',
  'ceteris',
  'ceterum',
  'ceto',
  'cetus',
  'chalybe',
  'chao',
  'chaos',
  'charaxo',
  'chemmis',
  'cheopem',
  'chiae',
  'chiis',
  'chilaus',
  'chilon',
  'chim',
  'chiorum',
  'chios',
  'chium',
  'chore',
  'choreas',
  'chori',
  'chorus',
  'cibaria',
  'cibi',
  'cibis',
  'cibus',
  'cicones',
  'ciconum',
  'ciebant',
  'cientes',
  'ciere',
  'ciii',
  'cilices',
  'cilis',
  'cilix',
  'cilla',
  'cimon',
  'cimonem',
  'cimoni',
  'cimonis',
  'cincta',
  'cinctas',
  'cincti',
  'cinere',
  'cinerem',
  'cineres',
  'cingit',
  'cingula',
  'cinguli',
  'cingulo',
  'cingunt',
  'cinypem',
  'cinyps',
  'cippus',
  'circe',
  'circulo',
  'circum',
  'cissia',
  'cissiam',
  'cissii',
  'cissios',
  'cistam',
  'cistas',
  'citans',
  'citato',
  'cithar',
  'cithara',
  'citra',
  'cive',
  'civem',
  'cives',
  'civibus',
  'civilis',
  'civium',
  'cladem',
  'cladi',
  'cladis',
  'clamor',
  'clamore',
  'clara',
  'claram',
  'clare',
  'clarius',
  'claro',
  'claros',
  'clarum',
  'classes',
  'classi',
  'clauda',
  'claudam',
  'claudi',
  'clausum',
  'clava',
  'clavas',
  'claves',
  'clavis',
  'clavos',
  'cleade',
  'cleobi',
  'cleobin',
  'cleonae',
  'clii',
  'cliii',
  'clioque',
  'clivis',
  'clivo',
  'clivos',
  'clix',
  'clotho',
  'clypei',
  'clypeis',
  'clypeo',
  'clypeum',
  'clytii',
  'cnidii',
  'cnidus',
  'coactis',
  'coactum',
  'coactus',
  'cobon',
  'cocti',
  'coctili',
  'coctis',
  'coctus',
  'codro',
  'codrus',
  'coegit',
  'coelum',
  'coen',
  'coercet',
  'coes',
  'coeunt',
  'cogebat',
  'cogemur',
  'cogente',
  'cogeret',
  'cogita',
  'cogitas',
  'cogitem',
  'cogites',
  'cogito',
  'cognato',
  'cognita',
  'cogniti',
  'cognitu',
  'cognovi',
  'cogor',
  'cohibe',
  'cohibet',
  'cohorti',
  'colaeus',
  'colant',
  'colatur',
  'colchi',
  'colchis',
  'colchus',
  'colebat',
  'colere',
  'colit',
  'collari',
  'collata',
  'collato',
  'colle',
  'collegi',
  'collem',
  'colles',
  'colligi',
  'colloca',
  'coloni',
  'colonia',
  'colonis',
  'colonos',
  'colorem',
  'coloris',
  'colossi',
  'coluit',
  'colum',
  'columba',
  'columen',
  'colunt',
  'coma',
  'comam',
  'comati',
  'comburi',
  'comite',
  'commis',
  'commoda',
  'commode',
  'commodi',
  'commodo',
  'commoti',
  'commune',
  'communi',
  'componi',
  'conabor',
  'conando',
  'conanti',
  'conaris',
  'conatui',
  'conatum',
  'conatur',
  'concedo',
  'concepi',
  'concio',
  'condi',
  'coner',
  'confert',
  'confide',
  'confido',
  'confixi',
  'confusa',
  'confuse',
  'congere',
  'conjux',
  'conscii',
  'consita',
  'constat',
  'constet',
  'consule',
  'consuta',
  'consuto',
  'contect',
  'contine',
  'contr',
  'contra',
  'contuli',
  'contum',
  'convert',
  'conviva',
  'cooit',
  'coorto',
  'coortus',
  'coorum',
  'copia',
  'copiae',
  'copiam',
  'copias',
  'copiis',
  'copiose',
  'coprus',
  'coqui',
  'coquit',
  'coquos',
  'coquunt',
  'coquus',
  'coram',
  'cordi',
  'cordiis',
  'coressi',
  'coria',
  'corio',
  'cornix',
  'corno',
  'cornu',
  'cornua',
  'cornuti',
  'corobio',
  'corona',
  'coronam',
  'corpore',
  'corripe',
  'corroso',
  'corruit',
  'corum',
  'corusco',
  'corys',
  'costas',
  'cottus',
  'cotyis',
  'cotyla',
  'coum',
  'coxam',
  'coxit',
  'crania',
  'cranio',
  'crasso',
  'crassum',
  'crater',
  'crathin',
  'crathis',
  'crea',
  'creare',
  'creasse',
  'creati',
  'creatum',
  'creatur',
  'creatus',
  'creavit',
  'crebro',
  'credam',
  'credent',
  'credi',
  'credo',
  'creet',
  'cremare',
  'cremato',
  'cremavi',
  'cremni',
  'cremnos',
  'crepans',
  'crescet',
  'cret',
  'cretae',
  'cretam',
  'crevit',
  'crex',
  'cribro',
  'crii',
  'crimen',
  'crines',
  'crispos',
  'crista',
  'crium',
  'crotala',
  'crotone',
  'crud',
  'cruda',
  'crudas',
  'crudo',
  'crudos',
  'cruor',
  'crura',
  'cubabat',
  'cubamus',
  'cubanti',
  'cubat',
  'cubili',
  'cubiti',
  'cubitis',
  'cubitus',
  'cuculus',
  'cuidam',
  'cuinam',
  'cuipiam',
  'cuivis',
  'cujas',
  'cujus',
  'culices',
  'culpa',
  'culpae',
  'culpes',
  'culta',
  'cultam',
  'cultis',
  'cultos',
  'cultrum',
  'cultura',
  'cuma',
  'cumae',
  'cuncta',
  'cunctae',
  'cuncti',
  'cunctos',
  'cunda',
  'cunque',
  'cupiam',
  'cupiat',
  'cupide',
  'cupidi',
  'cupido',
  'cupidos',
  'cupidus',
  'cupimus',
  'cupio',
  'cupis',
  'cupit',
  'cupitis',
  'cura',
  'curae',
  'curanda',
  'curando',
  'curans',
  'curas',
  'curasse',
  'curat',
  'curata',
  'curatum',
  'curavit',
  'cures',
  'curiam',
  'curias',
  'curii',
  'curiose',
  'curo',
  'currit',
  'currui',
  'currunt',
  'currus',
  'cursim',
  'cursu',
  'cursum',
  'cursus',
  'curva',
  'curvata',
  'curve',
  'curvos',
  'curvum',
  'cusae',
  'cuspide',
  'cuspis',
  'custode',
  'custos',
  'cyaneas',
  'cyaneos',
  'cyaxare',
  'cyaxari',
  'cycnum',
  'cydrara',
  'cylon',
  'cymae',
  'cymaei',
  'cymaeum',
  'cymaeus',
  'cymen',
  'cymotho',
  'cyneae',
  'cyno',
  'cynurii',
  'cypero',
  'cypri',
  'cyprii',
  'cypriis',
  'cypro',
  'cyprum',
  'cyprus',
  'cypseli',
  'cypselo',
  'cyrenae',
  'cyrene',
  'cyrenen',
  'cyrni',
  'cyrnum',
  'cyros',
  'cyrum',
  'cyrus',
  'cythnii',
  'cythno',
  'cyzico',
  'cyzicum',
  'dabant',
  'dabimus',
  'dabisne',
  'dabit',
  'dabo',
  'dabunt',
  'dadicae',
  'dadicis',
  'damiae',
  'damnati',
  'damno',
  'damnosa',
  'damnum',
  'damus',
  'dana',
  'danae',
  'danaes',
  'danai',
  'danaum',
  'danaus',
  'danda',
  'dandi',
  'dandum',
  'danti',
  'daphnis',
  'daque',
  'dare',
  'darem',
  'daret',
  'dari',
  'dario',
  'daritae',
  'darium',
  'darius',
  'datae',
  'datam',
  'dataque',
  'datas',
  'dati',
  'datin',
  'daton',
  'datori',
  'datorum',
  'datrix',
  'datum',
  'daturi',
  'daturum',
  'daturus',
  'deabus',
  'dearum',
  'deaurat',
  'debemus',
  'debeo',
  'debere',
  'deberet',
  'debet',
  'debita',
  'debitum',
  'debuit',
  'deceat',
  'decelus',
  'decem',
  'decent',
  'decepit',
  'decepto',
  'decet',
  'decidit',
  'decies',
  'decimae',
  'decimam',
  'decimo',
  'decipi',
  'decori',
  'decorum',
  'decreta',
  'dederat',
  'dedere',
  'dedisse',
  'dedisti',
  'dedit',
  'dedita',
  'deditis',
  'deditos',
  'deditum',
  'deducit',
  'deducto',
  'deesset',
  'deest',
  'defecit',
  'deferet',
  'deferre',
  'deferri',
  'defixit',
  'defixus',
  'defluit',
  'defore',
  'defungi',
  'degenda',
  'dehinc',
  'dein',
  'deis',
  'dejecit',
  'dejecti',
  'dejici',
  'dejicit',
  'dejoce',
  'dejocem',
  'dejocis',
  'delapsa',
  'delatam',
  'delatas',
  'delati',
  'delatis',
  'delatos',
  'delatum',
  'delebo',
  'delecti',
  'delicta',
  'delii',
  'delium',
  'delphis',
  'delphos',
  'delta',
  'delum',
  'delusum',
  'demens',
  'demi',
  'demiror',
  'demonax',
  'demos',
  'demtis',
  'demus',
  'denique',
  'denorum',
  'dens',
  'densa',
  'densi',
  'denso',
  'densus',
  'dent',
  'dentale',
  'dentali',
  'dentem',
  'dentes',
  'dentium',
  'deorsum',
  'deorum',
  'deos',
  'deosque',
  'deponet',
  'deponi',
  'deris',
  'dersaei',
  'deserit',
  'deserti',
  'desinat',
  'desino',
  'desint',
  'desit',
  'desque',
  'desunt',
  'detecta',
  'detego',
  'detexi',
  'detexit',
  'detonsa',
  'detrahi',
  'detrito',
  'detulit',
  'deus',
  'devicit',
  'devito',
  'devorat',
  'devoret',
  'dextra',
  'dextre',
  'dextro',
  'dextros',
  'dextrum',
  'dianae',
  'dibus',
  'dicaeus',
  'dicam',
  'dicamne',
  'dicant',
  'dicaris',
  'dicat',
  'dicavi',
  'dicebat',
  'dicenda',
  'dicendi',
  'dicendo',
  'dicere',
  'dicerem',
  'diceres',
  'diceret',
  'dices',
  'dicetis',
  'dicetur',
  'dici',
  'dicimus',
  'dicit',
  'dicite',
  'dicitis',
  'dicito',
  'dico',
  'dictae',
  'dicti',
  'dictis',
  'dictu',
  'dictum',
  'dictyes',
  'didici',
  'didicit',
  'diducta',
  'diduxit',
  'didymis',
  'diei',
  'diem',
  'dierum',
  'dies',
  'diesque',
  'diest',
  'differt',
  'digesti',
  'digitis',
  'digito',
  'digna',
  'dignas',
  'dignis',
  'digno',
  'dignum',
  'dignus',
  'diis',
  'dilata',
  'dilect',
  'dimensa',
  'dimidio',
  'dimisso',
  'dimitte',
  'dira',
  'dirae',
  'diras',
  'directa',
  'directi',
  'directo',
  'diremit',
  'diremta',
  'direpta',
  'direpto',
  'dirigi',
  'dirigit',
  'dirigt',
  'diro',
  'diruere',
  'dirut',
  'diruta',
  'dirutae',
  'discas',
  'discat',
  'disce',
  'discede',
  'disputo',
  'dissere',
  'distans',
  'dium',
  'diurn',
  'diurna',
  'diurnum',
  'divam',
  'divelli',
  'diversa',
  'diversi',
  'diverso',
  'divides',
  'dividi',
  'dividit',
  'divin',
  'divina',
  'divinae',
  'divinas',
  'divini',
  'divinis',
  'divino',
  'divinum',
  'divisa',
  'divisae',
  'divisi',
  'divisum',
  'divitem',
  'divites',
  'diviti',
  'dixerat',
  'dixere',
  'dixerim',
  'dixeris',
  'dixerit',
  'dixi',
  'diximus',
  'dixique',
  'dixisse',
  'dixisti',
  'dixit',
  'doberes',
  'doceas',
  'doceat',
  'docebo',
  'docere',
  'doceri',
  'docet',
  'doctus',
  'dodonae',
  'dodonam',
  'dolebam',
  'dolere',
  'doli',
  'dolia',
  'dolii',
  'dolio',
  'dolium',
  'dolo',
  'dolonci',
  'dolopes',
  'doloque',
  'dolor',
  'dolore',
  'dolorem',
  'dolores',
  'doloris',
  'dolorum',
  'dolos',
  'dolosa',
  'dolosam',
  'doloso',
  'dolum',
  'domat',
  'domi',
  'domibus',
  'domina',
  'dominam',
  'domine',
  'domini',
  'dominis',
  'dominos',
  'dominum',
  'dominus',
  'domita',
  'domitis',
  'domui',
  'domuit',
  'domus',
  'dona',
  'donabo',
  'donare',
  'donario',
  'donasse',
  'donata',
  'donatio',
  'donato',
  'donatum',
  'donavit',
  'dono',
  'donum',
  'dores',
  'doricae',
  'doricam',
  'doricas',
  'dorico',
  'doricus',
  'doriei',
  'dorieo',
  'dorieum',
  'dorieus',
  'dormiam',
  'dormio',
  'dormire',
  'dormit',
  'doro',
  'dorsa',
  'dorsi',
  'dorso',
  'dorsum',
  'dotoque',
  'dotus',
  'draco',
  'drama',
  'dramate',
  'drymon',
  'dryopes',
  'dryopis',
  'dryopum',
  'duabus',
  'duarum',
  'dubia',
  'dubie',
  'dubiis',
  'ducamus',
  'ducant',
  'ducat',
  'ducatu',
  'ducatum',
  'ducem',
  'ducena',
  'ducenos',
  'ducenta',
  'ducente',
  'ducenti',
  'duceque',
  'duceret',
  'duces',
  'duci',
  'ducibus',
  'ducitis',
  'duco',
  'duct',
  'ducta',
  'ductae',
  'ductas',
  'ducti',
  'ductis',
  'ducto',
  'ductos',
  'ductu',
  'ductum',
  'ducturi',
  'ductus',
  'ducum',
  'dudum',
  'dulce',
  'dulci',
  'duobus',
  'duoden',
  'duorum',
  'duosque',
  'duplex',
  'duplum',
  'dura',
  'durat',
  'duratio',
  'duravit',
  'duret',
  'duretis',
  'duris',
  'duroum',
  'durum',
  'duxerat',
  'duxeris',
  'duxi',
  'duxisti',
  'dyme',
  'dyras',
  'dysorus',
  'eadem',
  'eaedem',
  'eamdem',
  'eandem',
  'eaque',
  'earum',
  'eatenus',
  'ebeni',
  'ebibunt',
  'echidna',
  'echo',
  'ecqua',
  'ecquem',
  'ecqui',
  'edant',
  'edebant',
  'edebat',
  'edens',
  'edere',
  'ederet',
  'edic',
  'edicere',
  'edici',
  'edicit',
  'edicta',
  'edidi',
  'edisset',
  'edita',
  'editis',
  'editos',
  'edituri',
  'edixit',
  'edocta',
  'edoctam',
  'edocti',
  'edoctis',
  'edoctus',
  'edoni',
  'edonis',
  'educant',
  'educati',
  'educere',
  'educit',
  'eductas',
  'educti',
  'eductos',
  'eductum',
  'educunt',
  'eduint',
  'edunt',
  'eduntur',
  'eetion',
  'effari',
  'effata',
  'effato',
  'effectu',
  'efferri',
  'efficta',
  'effingo',
  'efflans',
  'effluit',
  'effodit',
  'effossa',
  'effugio',
  'effugit',
  'effusae',
  'effusam',
  'effusos',
  'effusum',
  'egeamus',
  'egeat',
  'egenos',
  'egenum',
  'egerant',
  'egere',
  'egerim',
  'egerunt',
  'egesta',
  'eget',
  'egisse',
  'egregii',
  'egressi',
  'egresso',
  'eidem',
  'eionem',
  'eioni',
  'eionis',
  'eique',
  'eisque',
  'eius',
  'ejecit',
  'ejectae',
  'ejecti',
  'ejectos',
  'ejectum',
  'ejectus',
  'ejicere',
  'ejicit',
  'ejusdem',
  'elabi',
  'elapsi',
  'elapso',
  'elapsos',
  'elapsus',
  'elata',
  'elatas',
  'elateam',
  'elati',
  'elatum',
  'elatus',
  'elbo',
  'elei',
  'eleis',
  'eleorum',
  'eleum',
  'eleusi',
  'eligant',
  'eligere',
  'eligo',
  'elis',
  'elixa',
  'elixat',
  'elixum',
  'ellopii',
  'elocans',
  'elocant',
  'elocare',
  'elorum',
  'elusa',
  'eluunt',
  'emas',
  'emebant',
  'emere',
  'emersit',
  'eminere',
  'eminuit',
  'emisit',
  'emissa',
  'emissos',
  'emissus',
  'emittas',
  'emitte',
  'emittet',
  'emitti',
  'emitto',
  'emporia',
  'emporii',
  'emporio',
  'empta',
  'emptori',
  'emtioni',
  'enagees',
  'enarees',
  'enasci',
  'enatant',
  'enatare',
  'enatis',
  'enetos',
  'enipeus',
  'enitar',
  'enixa',
  'enixe',
  'ense',
  'ensem',
  'enses',
  'ensibus',
  'ente',
  'entis',
  'enyalii',
  'enyoque',
  'eoque',
  'eordi',
  'eorum',
  'eosdem',
  'epapho',
  'ephesii',
  'ephesum',
  'ephori',
  'ephoris',
  'ephoros',
  'epiro',
  'epium',
  'epota',
  'epulas',
  'epulis',
  'epulo',
  'epulum',
  'equabus',
  'equas',
  'equi',
  'equibus',
  'equina',
  'equino',
  'equinum',
  'equis',
  'equite',
  'equitem',
  'equitum',
  'equo',
  'equum',
  'eram',
  'eramus',
  'erasit',
  'erat',
  'erato',
  'eratque',
  'erebo',
  'erebum',
  'erectae',
  'erectas',
  'erecti',
  'erectis',
  'erectum',
  'erectus',
  'ereptus',
  'eretria',
  'erga',
  'ergo',
  'erigere',
  'erigi',
  'erigit',
  'erigunt',
  'erimus',
  'erineo',
  'eripuit',
  'eris',
  'eritis',
  'erochum',
  'erogata',
  'erramus',
  'errandi',
  'errasse',
  'error',
  'errore',
  'errorem',
  'errores',
  'errorum',
  'erumpam',
  'erumpit',
  'erupit',
  'erythea',
  'erythia',
  'eryxo',
  'esse',
  'essem',
  'essemus',
  'esseque',
  'essetis',
  'etesiae',
  'etesias',
  'etiam',
  'euboico',
  'euclidi',
  'euenie',
  'euenii',
  'euenio',
  'eumdem',
  'eumque',
  'eundem',
  'eundum',
  'eunice',
  'eunomus',
  'euntem',
  'eunuchi',
  'euphemi',
  'euripum',
  'europa',
  'europam',
  'eurybia',
  'euterpe',
  'euthyni',
  'euxini',
  'euxinus',
  'evadens',
  'evadere',
  'evades',
  'evadit',
  'evadunt',
  'evagore',
  'evanuit',
  'evasere',
  'evasero',
  'evecta',
  'evectas',
  'evecti',
  'evectos',
  'evelli',
  'eveniet',
  'evenire',
  'evenit',
  'evento',
  'eventu',
  'eversae',
  'eversam',
  'evertes',
  'evertis',
  'evidens',
  'evitans',
  'evitari',
  'evitato',
  'evocari',
  'evolvat',
  'evomuit',
  'exacta',
  'exacte',
  'exactis',
  'exactum',
  'examen',
  'examine',
  'excede',
  'excelso',
  'excepta',
  'excepti',
  'excidit',
  'excisa',
  'excisus',
  'excita',
  'excitas',
  'excitos',
  'excitum',
  'excitus',
  'exclusi',
  'excoque',
  'excussa',
  'exeat',
  'exemit',
  'exempla',
  'exemplo',
  'exerant',
  'exercet',
  'exeso',
  'exeundi',
  'exeundo',
  'exeunt',
  'exeunte',
  'exhedra',
  'exhibet',
  'exierit',
  'exigat',
  'exiguam',
  'exiguas',
  'exiguis',
  'exiguo',
  'exiguos',
  'exiit',
  'exilem',
  'eximiam',
  'eximio',
  'eximios',
  'eximium',
  'exiret',
  'existam',
  'existat',
  'existi',
  'existit',
  'existo',
  'exitio',
  'exitium',
  'exitu',
  'exitura',
  'exordio',
  'exorsum',
  'exortae',
  'exorti',
  'exortum',
  'exortus',
  'expavit',
  'expedit',
  'expers',
  'experti',
  'expiare',
  'expiari',
  'expiat',
  'expiavi',
  'expleto',
  'exponas',
  'exponit',
  'exposui',
  'expulsi',
  'exserat',
  'exserta',
  'exsilii',
  'exsilio',
  'exsolvi',
  'exstant',
  'exstat',
  'exsul',
  'exsulum',
  'exta',
  'extat',
  'extensa',
  'externa',
  'extrema',
  'extremi',
  'extremo',
  'exuere',
  'exueret',
  'exuit',
  'exundat',
  'exurens',
  'exuti',
  'exutus',
  'faba',
  'fabas',
  'faber',
  'fabulam',
  'fabulas',
  'face',
  'facere',
  'faceret',
  'faces',
  'facete',
  'faciam',
  'faciant',
  'facias',
  'faciat',
  'facibus',
  'faciei',
  'faciens',
  'facient',
  'facilia',
  'facilis',
  'facio',
  'facitis',
  'fact',
  'facta',
  'factae',
  'factas',
  'facti',
  'factio',
  'facto',
  'factos',
  'factun',
  'facturi',
  'faecum',
  'fago',
  'falcem',
  'fallant',
  'fallat',
  'fallens',
  'fallit',
  'falsae',
  'falsas',
  'falsis',
  'falso',
  'falsos',
  'falsum',
  'fama',
  'fame',
  'famem',
  'fames',
  'familia',
  'famuli',
  'famulis',
  'famulo',
  'famulus',
  'fando',
  'fanum',
  'farinae',
  'farinam',
  'fasciam',
  'fascias',
  'fasciis',
  'fatale',
  'fatales',
  'fatigor',
  'fatrem',
  'fatum',
  'fauces',
  'faucium',
  'fausta',
  'faustum',
  'faveant',
  'faveat',
  'favent',
  'favente',
  'favere',
  'favo',
  'feceret',
  'fecerim',
  'feceris',
  'fecerit',
  'fecero',
  'fecimus',
  'fecisse',
  'fecisti',
  'fecto',
  'felibus',
  'felici',
  'felis',
  'felium',
  'feminae',
  'feminas',
  'feminis',
  'femora',
  'femore',
  'femori',
  'fera',
  'feracia',
  'feram',
  'feramur',
  'feratur',
  'fere',
  'ferebam',
  'ferebat',
  'ferens',
  'feret',
  'feri',
  'ferias',
  'feriat',
  'feriate',
  'feriens',
  'ferimus',
  'ferinam',
  'feriunt',
  'fero',
  'ferocis',
  'ferox',
  'ferream',
  'ferreis',
  'ferrent',
  'ferret',
  'ferreus',
  'ferri',
  'ferro',
  'fert',
  'fertile',
  'fertis',
  'ferto',
  'fertque',
  'fertur',
  'ferunt',
  'fervere',
  'festi',
  'festina',
  'festis',
  'festos',
  'festus',
  'fiant',
  'fiat',
  'ficis',
  'ficos',
  'fictam',
  'fictile',
  'fictum',
  'ficum',
  'fidelia',
  'fidem',
  'fideque',
  'fidere',
  'fides',
  'fierent',
  'fieri',
  'fierine',
  'figere',
  'figulo',
  'figulus',
  'figura',
  'figurae',
  'figuram',
  'figuris',
  'fila',
  'fili',
  'filia',
  'filiae',
  'filias',
  'filii',
  'filiis',
  'filiola',
  'filios',
  'filum',
  'fimbri',
  'finem',
  'fingam',
  'fingere',
  'fingi',
  'fingo',
  'finiit',
  'finio',
  'finire',
  'finiret',
  'finita',
  'finitam',
  'finiti',
  'finito',
  'finitus',
  'finxit',
  'firma',
  'firmae',
  'firmam',
  'firmat',
  'firmo',
  'firmum',
  'fiunt',
  'flagris',
  'flamina',
  'flamm',
  'flamma',
  'flammae',
  'flammam',
  'flammeo',
  'flando',
  'flante',
  'flantes',
  'flarent',
  'flatum',
  'flatus',
  'flere',
  'fleret',
  'fletu',
  'flexo',
  'flius',
  'flore',
  'florem',
  'florens',
  'florent',
  'florere',
  'floret',
  'florum',
  'fluat',
  'fluctum',
  'fluebat',
  'fluens',
  'fluenta',
  'fluere',
  'flueret',
  'flumina',
  'flumine',
  'flumini',
  'fluunt',
  'fluviis',
  'fluvio',
  'fluvios',
  'fluvium',
  'fluxit',
  'fluxium',
  'fluxus',
  'foco',
  'focorum',
  'focum',
  'fodi',
  'fodinae',
  'fodiunt',
  'foliis',
  'folles',
  'fons',
  'fontana',
  'fonte',
  'fontes',
  'fonti',
  'fontis',
  'foramen',
  'fore',
  'fores',
  'fori',
  'forique',
  'foris',
  'formari',
  'formet',
  'formiae',
  'formo',
  'formula',
  'fornace',
  'foro',
  'forsan',
  'forte',
  'fortes',
  'fortia',
  'fortior',
  'fortum',
  'fortuna',
  'forum',
  'fossa',
  'fossam',
  'fossis',
  'fove',
  'fovet',
  'fractae',
  'fracti',
  'fracto',
  'frater',
  'fratres',
  'fratrum',
  'fraudat',
  'fraudem',
  'fraus',
  'fremens',
  'fremitu',
  'frena',
  'frenis',
  'freno',
  'frenum',
  'frequen',
  'freta',
  'fretum',
  'frigidi',
  'frigido',
  'frigora',
  'frigore',
  'frigus',
  'frons',
  'frontes',
  'fronti',
  'fructus',
  'fruges',
  'frugis',
  'fruimur',
  'fruiti',
  'fruitur',
  'frusta',
  'frutice',
  'fucos',
  'fudisse',
  'fudit',
  'fueram',
  'fueri',
  'fuerim',
  'fuerint',
  'fueris',
  'fuerunt',
  'fugae',
  'fugam',
  'fugere',
  'fugiat',
  'fugio',
  'fugito',
  'fuimus',
  'fuisse',
  'fuissem',
  'fuisset',
  'fuisti',
  'fuistis',
  'fulcrum',
  'fulgido',
  'fulgor',
  'fulgur',
  'fulgure',
  'fulmen',
  'fulmina',
  'fulmine',
  'fumanti',
  'fumeto',
  'funalem',
  'functa',
  'functo',
  'functos',
  'functus',
  'fundere',
  'fundis',
  'fundo',
  'fundunt',
  'funesta',
  'fungus',
  'funibus',
  'funis',
  'funium',
  'funus',
  'furatus',
  'furem',
  'furens',
  'fureret',
  'furibus',
  'furis',
  'furno',
  'furnos',
  'furnum',
  'furore',
  'furoris',
  'furta',
  'furti',
  'furto',
  'furunt',
  'fusa',
  'fusco',
  'fuse',
  'fusi',
  'fusis',
  'fuste',
  'fustem',
  'futura',
  'futuram',
  'futuras',
  'futuros',
  'futurum',
  'gades',
  'gadira',
  'galatea',
  'galea',
  'galeam',
  'galeas',
  'gallico',
  'gamoros',
  'gauanes',
  'gaudens',
  'gaudere',
  'gaudes',
  'gaudet',
  'gaulo',
  'gavisa',
  'gavisi',
  'gela',
  'gelae',
  'gelam',
  'gelon',
  'gelone',
  'gelonem',
  'geloni',
  'gelonis',
  'gelono',
  'gelonum',
  'gelonus',
  'gelu',
  'gemitu',
  'gemitum',
  'genas',
  'gener',
  'genere',
  'generi',
  'generis',
  'genero',
  'generum',
  'genibus',
  'geniti',
  'genitos',
  'genitus',
  'gentem',
  'genti',
  'gentis',
  'genua',
  'genuina',
  'genuini',
  'genuit',
  'gerat',
  'geratis',
  'geratur',
  'gerendi',
  'gerens',
  'gerere',
  'gereret',
  'gergi',
  'gergis',
  'gerite',
  'gerrhum',
  'gerrhus',
  'gerunt',
  'gessit',
  'gestae',
  'gestam',
  'gesto',
  'gesturi',
  'getae',
  'getarum',
  'getas',
  'getis',
  'gida',
  'gide',
  'giden',
  'gignend',
  'gigonus',
  'gillum',
  'gillus',
  'glabrae',
  'glacie',
  'glacies',
  'gladiis',
  'gladio',
  'glande',
  'glandem',
  'glandes',
  'glauce',
  'glauci',
  'globum',
  'gloria',
  'gloriam',
  'glorior',
  'glorios',
  'glutine',
  'gnaram',
  'gnari',
  'gnaris',
  'gnaro',
  'gnaros',
  'gnarus',
  'gnatus',
  'gnuri',
  'gobrya',
  'gobryas',
  'gonnon',
  'gordiae',
  'gorgum',
  'gorgus',
  'gradu',
  'graduum',
  'graecas',
  'graece',
  'graeci',
  'graecia',
  'graecis',
  'graecos',
  'graecum',
  'graecus',
  'graiis',
  'gramen',
  'grana',
  'grandem',
  'grandi',
  'granis',
  'grata',
  'gratae',
  'gratas',
  'grate',
  'grati',
  'gratias',
  'grato',
  'gratum',
  'gratus',
  'grave',
  'gravem',
  'graves',
  'gravi',
  'gravia',
  'gravida',
  'gravis',
  'gregem',
  'grinus',
  'grossi',
  'grues',
  'gruis',
  'grumis',
  'grumos',
  'gruum',
  'grypas',
  'grypes',
  'grypum',
  'gula',
  'gummi',
  'gustu',
  'gutt',
  'guttas',
  'guttos',
  'gutture',
  'gyasque',
  'gygadas',
  'gygae',
  'gygaea',
  'gygaeam',
  'gygaeum',
  'gyge',
  'gygen',
  'gyges',
  'gygis',
  'gymnica',
  'gyndae',
  'gynden',
  'gyrum',
  'habeare',
  'habeas',
  'habeat',
  'habebam',
  'habebat',
  'habebo',
  'habemus',
  'haben',
  'habenda',
  'habendo',
  'habenti',
  'haberem',
  'haberet',
  'habet',
  'habetis',
  'habetur',
  'habitat',
  'habiti',
  'habitis',
  'habitu',
  'habitum',
  'habitus',
  'habuit',
  'hacque',
  'haec',
  'haemi',
  'haeque',
  'halitus',
  'haly',
  'halys',
  'hamo',
  'hanc',
  'harpago',
  'harpen',
  'harum',
  'hast',
  'hasta',
  'hastas',
  'hastile',
  'haurit',
  'hausit',
  'haustam',
  'heben',
  'hebrum',
  'hecat',
  'hecaten',
  'helenae',
  'helenam',
  'helice',
  'helotam',
  'helotas',
  'helotis',
  'heoris',
  'hepar',
  'heraeo',
  'heraeum',
  'heram',
  'herb',
  'herba',
  'herbae',
  'herbas',
  'herbida',
  'herbis',
  'herbosa',
  'herceum',
  'hercule',
  'hermon',
  'hermus',
  'hero',
  'heroas',
  'heroem',
  'heroes',
  'heroi',
  'herois',
  'heros',
  'heroum',
  'herum',
  'herus',
  'hiante',
  'hiberna',
  'hiberno',
  'hicce',
  'hicque',
  'hiemem',
  'hiemes',
  'hiemis',
  'hilaris',
  'himera',
  'himerae',
  'himeram',
  'hippia',
  'hippiam',
  'hique',
  'hirci',
  'hircum',
  'hisce',
  'hisque',
  'hnnc',
  'hocque',
  'homeri',
  'homerum',
  'homicid',
  'homine',
  'homines',
  'hominis',
  'hominum',
  'homo',
  'honesto',
  'honorat',
  'honore',
  'honorem',
  'honores',
  'honoris',
  'honos',
  'hoplete',
  'horas',
  'horco',
  'hordei',
  'hordeo',
  'horrent',
  'horrido',
  'hortari',
  'hortata',
  'hortati',
  'hortis',
  'hortor',
  'horum',
  'hospite',
  'hosque',
  'hostem',
  'hostias',
  'hostile',
  'hostili',
  'hostis',
  'hostium',
  'huic',
  'humana',
  'humanae',
  'humanam',
  'humani',
  'humanis',
  'humano',
  'humeris',
  'humero',
  'humeros',
  'humerum',
  'humi',
  'humide',
  'humidum',
  'humilis',
  'humore',
  'humum',
  'hunc',
  'huncce',
  'huncque',
  'hyampeo',
  'hydarne',
  'hydram',
  'hyela',
  'hyeme',
  'hyemem',
  'hylaea',
  'hylaeam',
  'hylli',
  'hyllum',
  'hyllus',
  'hymeas',
  'hymen',
  'hymetto',
  'hypanis',
  'hysias',
  'iacchum',
  'iacchus',
  'iadmon',
  'iadmoni',
  'ialysus',
  'iambis',
  'iapyges',
  'iasone',
  'iasoni',
  'iberiam',
  'ibes',
  'ibin',
  'ibis',
  'ibium',
  'icarium',
  'ichnae',
  'icta',
  'ictum',
  'ictus',
  'ideae',
  'ideas',
  'ideis',
  'idem',
  'idemque',
  'idipsum',
  'idolum',
  'idonea',
  'idoneam',
  'idoneo',
  'idoneum',
  'idoneus',
  'idque',
  'ienysum',
  'ignaros',
  'ignave',
  'ignavi',
  'ignavia',
  'ignavo',
  'ignavum',
  'ignavus',
  'igne',
  'ignem',
  'ignifer',
  'ignis',
  'igno',
  'ignorat',
  'ignorem',
  'ignosci',
  'ignosco',
  'ignota',
  'ignotae',
  'ignotas',
  'ignovi',
  'iidem',
  'iisdem',
  'ilia',
  'iliacae',
  'iliacam',
  'iliadem',
  'ilignam',
  'ilio',
  'ilium',
  'illa',
  'illae',
  'illam',
  'illaque',
  'illas',
  'illata',
  'illatae',
  'illatam',
  'illati',
  'illato',
  'ille',
  'illic',
  'illico',
  'illimis',
  'illis',
  'illium',
  'illius',
  'illoque',
  'illorum',
  'illoti',
  'illotum',
  'illotus',
  'illud',
  'illus',
  'illuvie',
  'illuxit',
  'imagine',
  'imago',
  'imber',
  'imbre',
  'imbrem',
  'imbres',
  'imbro',
  'imitati',
  'immanis',
  'immens',
  'immensa',
  'immense',
  'immensi',
  'imminet',
  'immisit',
  'immite',
  'immitte',
  'immo',
  'immotum',
  'immunem',
  'immunes',
  'immunia',
  'immunis',
  'impacta',
  'impares',
  'impegit',
  'impelli',
  'impense',
  'impera',
  'imperas',
  'imperat',
  'impete',
  'impetu',
  'impiam',
  'impii',
  'impios',
  'impium',
  'impius',
  'implere',
  'impleta',
  'impone',
  'imponit',
  'impoti',
  'impubem',
  'impulas',
  'inachi',
  'inanes',
  'inania',
  'inari',
  'inaros',
  'inarus',
  'inaures',
  'incedet',
  'incendi',
  'incensi',
  'incenso',
  'incepit',
  'incepto',
  'incerti',
  'incidat',
  'incidet',
  'incidi',
  'incidit',
  'incipe',
  'incipio',
  'incipit',
  'incisus',
  'inclyta',
  'incola',
  'incolae',
  'incolas',
  'incolit',
  'incudem',
  'inculta',
  'incumbo',
  'incutit',
  'inde',
  'indere',
  'indi',
  'india',
  'indiam',
  'indicem',
  'indicta',
  'indidem',
  'indiget',
  'indigna',
  'indigne',
  'indigno',
  'indigus',
  'indis',
  'inditos',
  'inditum',
  'indole',
  'indolem',
  'indos',
  'inducar',
  'induces',
  'induci',
  'inducti',
  'indue',
  'induere',
  'induit',
  'induta',
  'induti',
  'induto',
  'indutum',
  'induunt',
  'induxit',
  'ineat',
  'inedia',
  'ineptum',
  'inerat',
  'inertem',
  'inerunt',
  'inesse',
  'inest',
  'ineunt',
  'ineunte',
  'infamis',
  'infans',
  'infecta',
  'infensi',
  'inferam',
  'inferat',
  'inferi',
  'inferis',
  'inferri',
  'infige',
  'infimus',
  'infirma',
  'infirmi',
  'infixum',
  'inflexa',
  'infligi',
  'influat',
  'influit',
  'infudit',
  'infulis',
  'infunde',
  'infusa',
  'infusis',
  'infusum',
  'inge',
  'ingenii',
  'ingente',
  'ingenti',
  'ingesto',
  'ingrata',
  'ingrati',
  'ingrato',
  'ingredi',
  'inhians',
  'inhibe',
  'inibo',
  'iniens',
  'inierat',
  'iniere',
  'iniisse',
  'iniit',
  'inimica',
  'inimici',
  'iniqua',
  'inique',
  'iniquis',
  'iniquo',
  'iniquum',
  'initi',
  'initiis',
  'initis',
  'initium',
  'inito',
  'initum',
  'initus',
  'inivit',
  'injecit',
  'injungo',
  'injuri',
  'injuste',
  'injusti',
  'innata',
  'innatae',
  'innatas',
  'innixa',
  'innixus',
  'innoxii',
  'inopes',
  'inopi',
  'inopia',
  'inquit',
  'insana',
  'insanis',
  'insciis',
  'inscios',
  'insequi',
  'insigne',
  'insint',
  'insons',
  'inspexi',
  'instans',
  'instar',
  'instare',
  'instat',
  'insualm',
  'insul',
  'insula',
  'insulae',
  'insulas',
  'insulis',
  'insumta',
  'insuper',
  'insutum',
  'intacta',
  'intendi',
  'intendo',
  'interim',
  'intexe',
  'intima',
  'intimas',
  'intime',
  'intimo',
  'intimum',
  'intra',
  'intrant',
  'intrare',
  'intrat',
  'intravi',
  'intres',
  'intro',
  'intueri',
  'intulit',
  'inundat',
  'inundet',
  'inungi',
  'inutile',
  'inutili',
  'invadet',
  'invadit',
  'invasit',
  'invecta',
  'invecti',
  'invenio',
  'inventi',
  'invento',
  'inverso',
  'invict',
  'invicti',
  'invidet',
  'invidia',
  'invidus',
  'inviso',
  'invisus',
  'invitis',
  'invito',
  'invitum',
  'invitus',
  'inyco',
  'iolae',
  'iolaum',
  'iolcon',
  'iolcum',
  'iolcus',
  'ionas',
  'iones',
  'ionia',
  'ioniae',
  'ioniam',
  'ionibus',
  'ionicam',
  'ionico',
  'ionicum',
  'ionicus',
  'ionio',
  'ionis',
  'ionum',
  'iphicli',
  'ipsa',
  'ipsae',
  'ipsamet',
  'ipsaque',
  'ipse',
  'ipsene',
  'ipseque',
  'ipsique',
  'ipsis',
  'ipsius',
  'ipsum',
  'ipsumne',
  'iram',
  'iraque',
  'irasa',
  'irati',
  'iratum',
  'iratus',
  'irenas',
  'irenen',
  'iret',
  'irim',
  'iris',
  'irruens',
  'irruunt',
  'isagora',
  'isidi',
  'isidis',
  'ismenio',
  'isque',
  'isset',
  'ista',
  'istam',
  'istarum',
  'iste',
  'isthmi',
  'isthmio',
  'isthmo',
  'isthmus',
  'isti',
  'istic',
  'istis',
  'istram',
  'istro',
  'istrum',
  'istud',
  'istudo',
  'italia',
  'italiae',
  'italiam',
  'itanum',
  'item',
  'iter',
  'iterata',
  'iterato',
  'iterim',
  'iterum',
  'ithomam',
  'itinere',
  'itur',
  'itura',
  'iturae',
  'iverant',
  'ivere',
  'iverunt',
  'ivit',
  'iyrcae',
  'jacebat',
  'jaceo',
  'jacere',
  'jactant',
  'jactare',
  'jactis',
  'jacto',
  'jacula',
  'jaculis',
  'jaculo',
  'jaculum',
  'janua',
  'januae',
  'januam',
  'japeti',
  'japetus',
  'jasio',
  'jasone',
  'jecit',
  'jejunia',
  'joci',
  'jocum',
  'jolcum',
  'jovem',
  'jovis',
  'jubar',
  'jubare',
  'jubeat',
  'jubebat',
  'jubens',
  'juberet',
  'jubet',
  'jubis',
  'jucundo',
  'judcies',
  'judex',
  'judicas',
  'judicem',
  'judices',
  'judicio',
  'judico',
  'juga',
  'jugera',
  'jugeris',
  'jugo',
  'jugum',
  'jumento',
  'juncis',
  'junctae',
  'junctam',
  'juncto',
  'junctum',
  'junctus',
  'jungat',
  'jungere',
  'jungunt',
  'junior',
  'juno',
  'junonem',
  'junoni',
  'jupiter',
  'jurabit',
  'jurandi',
  'jurando',
  'jurare',
  'juravit',
  'jure',
  'juribus',
  'juris',
  'juro',
  'jusque',
  'jussa',
  'jussero',
  'jussi',
  'jussit',
  'jusso',
  'jussos',
  'jussum',
  'jussus',
  'justae',
  'justam',
  'justiti',
  'justius',
  'justos',
  'justus',
  'juvabit',
  'juvenem',
  'juveni',
  'juvenum',
  'juverat',
  'juvisse',
  'juvisti',
  'juxta',
  'kiki',
  'krios',
  'labda',
  'labia',
  'labor',
  'laborat',
  'labore',
  'laborem',
  'labores',
  'laboris',
  'laborum',
  'lacmone',
  'lacon',
  'laconem',
  'lacrima',
  'lacte',
  'lacti',
  'lactis',
  'lactuca',
  'lacunae',
  'lacunam',
  'lacunar',
  'lacus',
  'ladanum',
  'lade',
  'laden',
  'ladice',
  'ladicem',
  'ladicen',
  'laedam',
  'laedit',
  'laenas',
  'laeso',
  'laeta',
  'laetae',
  'laetati',
  'laetos',
  'laeva',
  'laevi',
  'laevo',
  'laevum',
  'lamenta',
  'lamin',
  'lampon',
  'lanceae',
  'lanceas',
  'lanceis',
  'lanea',
  'laneos',
  'lapid',
  'lapide',
  'lapidea',
  'lapideo',
  'lapides',
  'lapidi',
  'lapis',
  'lapitha',
  'laquem',
  'laqueo',
  'laqueos',
  'larem',
  'larga',
  'largum',
  'laribus',
  'laso',
  'lasonii',
  'lata',
  'latam',
  'lataque',
  'late',
  'lateat',
  'latens',
  'lateque',
  'lateris',
  'laterum',
  'latis',
  'lato',
  'laton',
  'latona',
  'latonae',
  'latos',
  'latuit',
  'laturi',
  'laturos',
  'laturum',
  'laturus',
  'laudem',
  'laudo',
  'laum',
  'lauri',
  'lauro',
  'lauta',
  'lauto',
  'lavando',
  'lavant',
  'lavare',
  'lavaret',
  'lavari',
  'lavator',
  'laxavit',
  'leaena',
  'learcho',
  'lebaeam',
  'lebedus',
  'lebes',
  'lebete',
  'lectas',
  'lectis',
  'lecto',
  'lectos',
  'lectulo',
  'legant',
  'legato',
  'legatos',
  'legebat',
  'legem',
  'legenda',
  'legens',
  'legi',
  'legibus',
  'legis',
  'lemni',
  'lemniae',
  'lemnii',
  'lemno',
  'lemnum',
  'leni',
  'lenibus',
  'lentem',
  'lentius',
  'leone',
  'leonem',
  'leones',
  'leonina',
  'leonis',
  'leontis',
  'leonum',
  'leporem',
  'lepra',
  'lepreum',
  'lepus',
  'lero',
  'lesbi',
  'lesbie',
  'lesbii',
  'lesbiis',
  'lesbios',
  'lesbum',
  'lestem',
  'lestes',
  'letale',
  'leuce',
  'levamen',
  'levandi',
  'levatam',
  'levatu',
  'levia',
  'levis',
  'leviter',
  'libato',
  'libavit',
  'libello',
  'libens',
  'liber',
  'libera',
  'liberae',
  'liberam',
  'liberat',
  'liberi',
  'liberis',
  'liberum',
  'libet',
  'libras',
  'libro',
  'libros',
  'libum',
  'libya',
  'libyam',
  'libyas',
  'libybus',
  'libyci',
  'libyen',
  'liceat',
  'licere',
  'licet',
  'lichas',
  'licitum',
  'licuit',
  'lida',
  'ligato',
  'ligit',
  'ligna',
  'ligneam',
  'ligneas',
  'ligneis',
  'ligneos',
  'ligneus',
  'ligni',
  'lignis',
  'ligno',
  'lignum',
  'ligonem',
  'ligures',
  'ligurum',
  'ligyes',
  'ligyum',
  'liii',
  'liique',
  'liis',
  'lilia',
  'limen',
  'limes',
  'limite',
  'limites',
  'limo',
  'limosa',
  'limpida',
  'lindi',
  'lindiis',
  'lindum',
  'lindus',
  'line',
  'lineas',
  'linei',
  'lineis',
  'lines',
  'lineum',
  'lingu',
  'lingua',
  'linguis',
  'lingunt',
  'lino',
  'linteas',
  'lintei',
  'linum',
  'lipaxus',
  'lipoxai',
  'liquida',
  'liquore',
  'lisae',
  'lissus',
  'litanti',
  'litavit',
  'litem',
  'literam',
  'literas',
  'litibus',
  'litis',
  'litoris',
  'lium',
  'livor',
  'locales',
  'locanda',
  'locasse',
  'locata',
  'locatae',
  'locatis',
  'locatos',
  'locavit',
  'locemur',
  'loci',
  'locoque',
  'locorum',
  'loculos',
  'loculum',
  'locum',
  'locus',
  'locuta',
  'locutam',
  'locuti',
  'locutum',
  'longam',
  'longas',
  'longe',
  'longior',
  'longo',
  'longos',
  'longum',
  'loquar',
  'loquela',
  'loquens',
  'loqui',
  'loquor',
  'lori',
  'lorica',
  'loricae',
  'loricas',
  'loris',
  'lorum',
  'lotas',
  'lotio',
  'loto',
  'lotus',
  'loxias',
  'luat',
  'lubrica',
  'lucem',
  'lucet',
  'lucid',
  'lucidus',
  'lucinae',
  'lucinam',
  'luco',
  'lucri',
  'lucro',
  'lucrum',
  'luctu',
  'luctum',
  'lucus',
  'ludendo',
  'ludens',
  'ludere',
  'ludi',
  'ludorum',
  'luere',
  'lues',
  'lugebat',
  'lugens',
  'lugent',
  'luit',
  'luituri',
  'lumbos',
  'lumini',
  'lunam',
  'lunata',
  'lupi',
  'lupis',
  'lupos',
  'lusisse',
  'lusus',
  'lusuum',
  'luti',
  'luto',
  'luxasse',
  'luxus',
  'lycaei',
  'lycia',
  'lyciam',
  'lycidae',
  'lyciden',
  'lycides',
  'lycii',
  'lyciis',
  'lycius',
  'lyco',
  'lyctum',
  'lycurge',
  'lycurgi',
  'lydi',
  'lydia',
  'lydiae',
  'lydiam',
  'lydici',
  'lydio',
  'lydis',
  'lydius',
  'lydorum',
  'lydos',
  'lydum',
  'lynceum',
  'lyra',
  'lyrico',
  'macae',
  'macedo',
  'maceria',
  'machina',
  'macis',
  'macra',
  'mactant',
  'mactari',
  'mactato',
  'madens',
  'madytum',
  'maeones',
  'maeotas',
  'maeotin',
  'magi',
  'magis',
  'magn',
  'magnae',
  'magnam',
  'magnas',
  'magneti',
  'magni',
  'magnis',
  'magno',
  'magnos',
  'magnum',
  'mago',
  'magos',
  'majo',
  'majora',
  'majore',
  'majorem',
  'majori',
  'majoris',
  'majorum',
  'majus',
  'malae',
  'malam',
  'male',
  'maleque',
  'males',
  'mali',
  'malis',
  'malle',
  'malleum',
  'malo',
  'malorum',
  'malum',
  'malus',
  'mammas',
  'manabat',
  'manavit',
  'manca',
  'mancas',
  'mandana',
  'mandane',
  'mandans',
  'mandata',
  'mandati',
  'mane',
  'manebo',
  'manendi',
  'manens',
  'manent',
  'manerem',
  'maneret',
  'maneros',
  'manet',
  'manete',
  'manetis',
  'manibus',
  'manis',
  'manui',
  'manus',
  'manuum',
  'mardi',
  'mardus',
  'mare',
  'mareae',
  'marem',
  'mari',
  'maria',
  'maribus',
  'marina',
  'marini',
  'marique',
  'maris',
  'maritos',
  'maritum',
  'marivag',
  'marmore',
  'mars',
  'marsyam',
  'marte',
  'martem',
  'martia',
  'martium',
  'mascula',
  'massam',
  'massas',
  'mater',
  'materia',
  'materna',
  'materno',
  'matres',
  'matri',
  'matris',
  'matura',
  'maturas',
  'mature',
  'maturet',
  'maturo',
  'maturus',
  'mausoli',
  'mavors',
  'maxill',
  'maxilla',
  'maxima',
  'maximae',
  'maximam',
  'maximas',
  'maximis',
  'maximos',
  'maximum',
  'mazam',
  'mazares',
  'mazari',
  'meam',
  'mearum',
  'meas',
  'meda',
  'medeae',
  'medeum',
  'media',
  'mediae',
  'medias',
  'medicis',
  'medico',
  'medicus',
  'medio',
  'medis',
  'medius',
  'medo',
  'medum',
  'medus',
  'meipsum',
  'meis',
  'meisque',
  'meito',
  'melane',
  'melanem',
  'melias',
  'melib',
  'meliora',
  'melissa',
  'melita',
  'melius',
  'melle',
  'membris',
  'membrum',
  'memento',
  'meminit',
  'memnona',
  'memor',
  'memorem',
  'memores',
  'memoro',
  'memphi',
  'memphin',
  'memphis',
  'menaris',
  'menda',
  'mendaci',
  'mendax',
  'mendes',
  'mendici',
  'mendico',
  'mene',
  'menelai',
  'menelao',
  'menii',
  'menippe',
  'mens',
  'mensam',
  'mensas',
  'menses',
  'mente',
  'mentem',
  'mentes',
  'menti',
  'mentio',
  'mentis',
  'mentum',
  'meos',
  'mercatu',
  'mercium',
  'mereri',
  'meretur',
  'merita',
  'meritam',
  'meriti',
  'merito',
  'meritus',
  'mero',
  'meroe',
  'meruit',
  'merum',
  'messem',
  'messis',
  'metalla',
  'metere',
  'metes',
  'metidem',
  'metito',
  'metitur',
  'metu',
  'metuens',
  'metunt',
  'metuque',
  'metus',
  'meum',
  'meus',
  'meusque',
  'micytho',
  'midas',
  'migrare',
  'mihique',
  'miis',
  'milesie',
  'milesii',
  'mileto',
  'milii',
  'militae',
  'militem',
  'milites',
  'militia',
  'militum',
  'milium',
  'mille',
  'millium',
  'milonis',
  'milvi',
  'milyas',
  'minacem',
  'minae',
  'minarum',
  'minas',
  'minata',
  'minati',
  'miner',
  'minerv',
  'mingunt',
  'minima',
  'minimae',
  'minime',
  'minimo',
  'minimus',
  'minis',
  'minois',
  'minor',
  'minora',
  'minore',
  'minoris',
  'minorum',
  'minos',
  'minui',
  'minuit',
  'minuta',
  'minutim',
  'minutis',
  'minyae',
  'minyis',
  'mira',
  'mirabar',
  'miram',
  'miramur',
  'miranda',
  'mirando',
  'mirans',
  'mirari',
  'mirati',
  'miratum',
  'miratur',
  'miratus',
  'miretur',
  'miscent',
  'miscet',
  'miser',
  'misera',
  'misere',
  'miseria',
  'miseris',
  'miserit',
  'misero',
  'miserum',
  'misi',
  'missa',
  'missae',
  'missam',
  'missum',
  'mista',
  'mistus',
  'miti',
  'mitis',
  'mitran',
  'mittens',
  'mitti',
  'mittit',
  'mitto',
  'mittunt',
  'mium',
  'mixti',
  'mixtim',
  'mixto',
  'mixtos',
  'mixtum',
  'mixtus',
  'mlius',
  'modica',
  'modos',
  'modum',
  'mola',
  'molares',
  'mole',
  'molest',
  'molesta',
  'molesti',
  'molesto',
  'moliens',
  'moliri',
  'moliti',
  'molitum',
  'molitus',
  'mollem',
  'molles',
  'molli',
  'mollita',
  'momenti',
  'momento',
  'momum',
  'moneam',
  'monem',
  'monens',
  'monerem',
  'moneri',
  'monetae',
  'monetam',
  'monilia',
  'monita',
  'moniti',
  'monitis',
  'monito',
  'monitum',
  'monitus',
  'mons',
  'monstra',
  'montana',
  'monte',
  'montem',
  'montes',
  'montium',
  'morae',
  'moralis',
  'moras',
  'moratum',
  'moratur',
  'morbo',
  'morbos',
  'morbum',
  'morbus',
  'mordent',
  'more',
  'mores',
  'moribus',
  'moris',
  'mors',
  'morte',
  'mortem',
  'mortis',
  'mortua',
  'mortuae',
  'mortuis',
  'mortuo',
  'mortuos',
  'mortuum',
  'mortuus',
  'moschi',
  'moschis',
  'mosyn',
  'moti',
  'motibus',
  'motum',
  'motura',
  'moturus',
  'moveas',
  'moveat',
  'movebat',
  'movenda',
  'movendi',
  'moveres',
  'moveret',
  'moveri',
  'movet',
  'movisse',
  'movit',
  'moxque',
  'mucus',
  'mulae',
  'mulctam',
  'mulget',
  'mulier',
  'muliere',
  'mulis',
  'mulorum',
  'multa',
  'multam',
  'multas',
  'multati',
  'multi',
  'multis',
  'multo',
  'multos',
  'multus',
  'mundi',
  'mundis',
  'mundo',
  'mundum',
  'mundus',
  'munera',
  'munere',
  'muneri',
  'muneris',
  'muni',
  'munire',
  'muniret',
  'munita',
  'muniunt',
  'munus',
  'murario',
  'murem',
  'muris',
  'murium',
  'murum',
  'murus',
  'musici',
  'mutandi',
  'mutari',
  'mutas',
  'mutasse',
  'mutatam',
  'mutatis',
  'mutato',
  'mutatum',
  'mutatur',
  'mutavi',
  'mutavit',
  'mutetur',
  'mutilum',
  'mutua',
  'mutuae',
  'mutuam',
  'mutuari',
  'mutuas',
  'mycalae',
  'mycale',
  'mycis',
  'myconum',
  'mycorum',
  'mylasa',
  'myndia',
  'myndiam',
  'myrica',
  'myrmex',
  'myrrha',
  'myrsum',
  'myrtis',
  'myrto',
  'myrtus',
  'mysi',
  'mysia',
  'mysio',
  'myuntem',
  'myus',
  'myusii',
  'nactis',
  'nactus',
  'naculum',
  'naeque',
  'nandi',
  'nandum',
  'nantem',
  'nares',
  'naribus',
  'narrans',
  'narrare',
  'narrat',
  'narratu',
  'nasamon',
  'nascens',
  'nasci',
  'naso',
  'nassa',
  'nata',
  'natali',
  'natam',
  'natans',
  'natant',
  'natare',
  'nates',
  'natho',
  'natibus',
  'natio',
  'natis',
  'nato',
  'natos',
  'natu',
  'natum',
  'natur',
  'natura',
  'naturam',
  'nautae',
  'nautas',
  'navabat',
  'navale',
  'navalem',
  'navales',
  'navalia',
  'navalis',
  'navavit',
  'nave',
  'navem',
  'navi',
  'navibus',
  'navigas',
  'navigii',
  'navigio',
  'navium',
  'naxi',
  'naxii',
  'naxiis',
  'naxios',
  'naxius',
  'naxo',
  'naxus',
  'nebula',
  'necare',
  'necasse',
  'necavit',
  'necdum',
  'neci',
  'neco',
  'necos',
  'nedum',
  'nefanda',
  'nefarie',
  'nefarii',
  'negabat',
  'negando',
  'neganti',
  'negare',
  'negaret',
  'negari',
  'negas',
  'negat',
  'negavi',
  'negavit',
  'negem',
  'negotia',
  'negotii',
  'negotio',
  'neleum',
  'nelidae',
  'nemine',
  'nemoque',
  'nemora',
  'nentem',
  'neoclis',
  'nepos',
  'nepote',
  'nepotem',
  'nepoti',
  'neptin',
  'neptis',
  'neptuno',
  'nequam',
  'neque',
  'nequeo',
  'nequit',
  'nereo',
  'nereum',
  'nescii',
  'nescire',
  'nescis',
  'nesoque',
  'nestum',
  'nestus',
  'neum',
  'neumque',
  'neuri',
  'neuris',
  'neurus',
  'neuter',
  'neutram',
  'neutri',
  'neutrum',
  'neve',
  'nexque',
  'nexuit',
  'nexum',
  'nice',
  'nicen',
  'nicibus',
  'niciis',
  'nicios',
  'nicolai',
  'nidos',
  'nihi',
  'nihil',
  'nihili',
  'nihilum',
  'nilo',
  'nilum',
  'nilus',
  'nimia',
  'nimiis',
  'nimis',
  'nimium',
  'nini',
  'niniam',
  'ninus',
  'nipsaei',
  'nisaeam',
  'nisaei',
  'nisaeis',
  'nisaeum',
  'nisaeus',
  'nisi',
  'nissa',
  'nissas',
  'nitetin',
  'nitida',
  'nitidae',
  'nitro',
  'nituit',
  'nive',
  'nivem',
  'nivosi',
  'nobilem',
  'nobiles',
  'nobili',
  'nobis',
  'nobs',
  'noctem',
  'noctes',
  'noctium',
  'noctuae',
  'nodi',
  'nodis',
  'nodosas',
  'nodum',
  'noes',
  'nolent',
  'nolente',
  'nolenti',
  'noles',
  'nolim',
  'nolint',
  'nolite',
  'nolle',
  'nollent',
  'nolo',
  'noluit',
  'nomem',
  'nomen',
  'nominat',
  'nominis',
  'nomos',
  'none',
  'nonne',
  'nonus',
  'norim',
  'norum',
  'norunt',
  'nosque',
  'nosse',
  'nossem',
  'nossent',
  'noster',
  'nosti',
  'nostis',
  'nostra',
  'nostrae',
  'nostras',
  'nostri',
  'nostris',
  'nostro',
  'nostros',
  'notae',
  'notas',
  'notat',
  'notatu',
  'notatus',
  'notavit',
  'noti',
  'notione',
  'notior',
  'notitia',
  'notium',
  'notria',
  'nova',
  'novalem',
  'novali',
  'novarum',
  'noveram',
  'noverat',
  'noverim',
  'novies',
  'novimus',
  'novisse',
  'novit',
  'novo',
  'novos',
  'novum',
  'novus',
  'noxa',
  'noxae',
  'noxam',
  'noxia',
  'noxii',
  'noxium',
  'noxque',
  'nubant',
  'nubat',
  'nube',
  'nubem',
  'nubes',
  'nubibus',
  'nubilis',
  'nubilo',
  'nubit',
  'nucleum',
  'nuda',
  'nudam',
  'nudant',
  'nudas',
  'nudatam',
  'nudati',
  'nudatum',
  'nudatus',
  'nudavit',
  'nude',
  'nudi',
  'nudis',
  'nudium',
  'nudum',
  'nugari',
  'nugas',
  'nullae',
  'nullam',
  'nulli',
  'nullibi',
  'nullius',
  'nullum',
  'nullus',
  'numeres',
  'numeri',
  'numeris',
  'numero',
  'numeros',
  'numerus',
  'numinis',
  'nummos',
  'numquam',
  'numquid',
  'nuncia',
  'nunciat',
  'nuncio',
  'nuncios',
  'nuncium',
  'nuncius',
  'nunquam',
  'nunquid',
  'nuper',
  'nupsit',
  'nuptae',
  'nupti',
  'nuptias',
  'nusquam',
  'nussas',
  'nutante',
  'nutrici',
  'nutrire',
  'nutrita',
  'nutrito',
  'nutu',
  'nutum',
  'nuuc',
  'nympha',
  'nympham',
  'nyra',
  'nysam',
  'oarizi',
  'oarum',
  'oarus',
  'oasi',
  'obazo',
  'obducto',
  'obeundo',
  'obeunt',
  'obierat',
  'obiere',
  'obieris',
  'obiisse',
  'obiit',
  'obit',
  'obiter',
  'obitum',
  'objecta',
  'objecti',
  'objecto',
  'oblata',
  'oblatas',
  'oblatum',
  'obliget',
  'obliqua',
  'obliti',
  'oblitos',
  'oblonga',
  'oblongo',
  'obnoxio',
  'oborta',
  'obortam',
  'obruit',
  'obruti',
  'obscura',
  'obscure',
  'obscuri',
  'obscuro',
  'obsecro',
  'obsedit',
  'obsessi',
  'obsesso',
  'obsides',
  'obsidet',
  'obsidum',
  'obsitum',
  'obsitus',
  'obstat',
  'obstet',
  'obtecta',
  'obtinet',
  'obtulit',
  'obtutum',
  'obversa',
  'obvia',
  'obvii',
  'obvium',
  'obvius',
  'occasio',
  'occasu',
  'occasum',
  'occidam',
  'occidat',
  'occide',
  'occidi',
  'occidit',
  'occisi',
  'occisos',
  'occisum',
  'occisus',
  'occlusa',
  'occulta',
  'occulte',
  'occupat',
  'occupet',
  'oceani',
  'oceanis',
  'oceano',
  'oceanum',
  'oceanus',
  'ocreas',
  'ocreis',
  'octavo',
  'octo',
  'oculi',
  'oculos',
  'oculus',
  'ocyro',
  'ocyus',
  'oderat',
  'oderit',
  'oderunt',
  'odii',
  'odio',
  'odiosa',
  'odium',
  'odor',
  'odore',
  'odorem',
  'odoris',
  'odorum',
  'odrysas',
  'offensi',
  'officia',
  'officii',
  'officum',
  'oior',
  'olea',
  'oleae',
  'oleam',
  'olei',
  'olen',
  'olente',
  'oleo',
  'oleum',
  'oliatum',
  'olide',
  'oliva',
  'olivae',
  'ollulis',
  'olmii',
  'olyci',
  'olympii',
  'olympo',
  'olympum',
  'olympus',
  'omen',
  'ominari',
  'omine',
  'ominis',
  'omissa',
  'omisso',
  'omitto',
  'omnes',
  'omnibus',
  'omnino',
  'omnique',
  'omnium',
  'onem',
  'onera',
  'onere',
  'onerent',
  'onesilo',
  'onetae',
  'onum',
  'onust',
  'onusta',
  'onustus',
  'opaca',
  'opacis',
  'operae',
  'operam',
  'operi',
  'operis',
  'operose',
  'operuit',
  'operum',
  'opin',
  'opinio',
  'opinor',
  'opis',
  'oportet',
  'oppida',
  'oppidi',
  'oppidum',
  'oppone',
  'opprimi',
  'optans',
  'optanti',
  'optarem',
  'optavit',
  'optimae',
  'optimas',
  'optimi',
  'optimis',
  'optimum',
  'optimus',
  'opum',
  'opuntii',
  'opus',
  'oracula',
  'oraculi',
  'orae',
  'oramus',
  'orans',
  'orare',
  'orarent',
  'orarum',
  'orarunt',
  'oras',
  'oravit',
  'orbam',
  'orbem',
  'orbes',
  'orbis',
  'orci',
  'orcum',
  'ordinem',
  'ordines',
  'ordini',
  'ordinis',
  'ordo',
  'oreque',
  'organa',
  'organis',
  'organo',
  'orgyia',
  'orgyiae',
  'orgyias',
  'orgyiis',
  'oriatur',
  'oribus',
  'oricus',
  'oriente',
  'origine',
  'orion',
  'oriri',
  'oritur',
  'oriundi',
  'oriundo',
  'ornant',
  'ornata',
  'ornati',
  'ornatu',
  'ornavit',
  'oropum',
  'orotal',
  'orsus',
  'orta',
  'orthion',
  'orthrum',
  'orti',
  'orto',
  'ortus',
  'orus',
  'oryes',
  'oryges',
  'osiris',
  'osse',
  'osseas',
  'ossibus',
  'ostia',
  'ostiis',
  'ostio',
  'ostium',
  'otane',
  'otanem',
  'otanen',
  'otanes',
  'othry',
  'othrys',
  'otia',
  'otiae',
  'oticum',
  'otii',
  'otiis',
  'otiosum',
  'otiosus',
  'otium',
  'otorum',
  'otos',
  'ovem',
  'oves',
  'ovillis',
  'ovinam',
  'ovium',
  'ovorum',
  'ozolas',
  'pabulo',
  'pabulum',
  'pacatae',
  'pacem',
  'pactae',
  'pacto',
  'pactum',
  'pactus',
  'pactya',
  'pactyam',
  'pactyas',
  'pactyes',
  'paeo',
  'paeones',
  'paeonum',
  'paeso',
  'paesum',
  'pagas',
  'pagasas',
  'pagi',
  'pagis',
  'pagos',
  'palam',
  'paleis',
  'palis',
  'pallas',
  'pallene',
  'pallio',
  'pallium',
  'palmae',
  'palmari',
  'palmas',
  'palmeo',
  'palo',
  'palos',
  'palpa',
  'palpans',
  'palude',
  'paludem',
  'paludes',
  'palus',
  'pane',
  'panem',
  'pangaei',
  'pani',
  'panibus',
  'panicum',
  'panis',
  'panopia',
  'papae',
  'papaeus',
  'papho',
  'pappo',
  'papremi',
  'papyri',
  'papyrum',
  'parabat',
  'parans',
  'parant',
  'parare',
  'pararem',
  'pararet',
  'paras',
  'parasse',
  'parat',
  'parata',
  'paratas',
  'paratos',
  'paravit',
  'parc',
  'parcam',
  'parce',
  'parcit',
  'parere',
  'pareret',
  'pares',
  'paret',
  'pari',
  'paria',
  'pariam',
  'pariant',
  'pariat',
  'pariet',
  'pariete',
  'parii',
  'pariis',
  'parios',
  'paris',
  'parium',
  'pariunt',
  'parius',
  'pars',
  'parte',
  'parthi',
  'parthis',
  'parti',
  'partim',
  'partiti',
  'partui',
  'partum',
  'paruere',
  'paruit',
  'parva',
  'parvae',
  'parvam',
  'parvas',
  'parvi',
  'parvos',
  'parvuli',
  'parvum',
  'parvus',
  'pasce',
  'pascens',
  'pascua',
  'pascunt',
  'pasitho',
  'passa',
  'passae',
  'passi',
  'passis',
  'passos',
  'pasti',
  'pastore',
  'pataeci',
  'patens',
  'patent',
  'patente',
  'patenti',
  'patere',
  'pateret',
  'pateris',
  'paterna',
  'paterno',
  'pati',
  'patior',
  'patrans',
  'patrata',
  'patrem',
  'patres',
  'patria',
  'patriam',
  'patrias',
  'patriis',
  'patrio',
  'patrios',
  'patris',
  'patrium',
  'patrui',
  'patruos',
  'patumon',
  'paucas',
  'pauci',
  'paucis',
  'pauco',
  'paucos',
  'paullo',
  'paulo',
  'paulto',
  'pauper',
  'paxillo',
  'peccant',
  'peccata',
  'peccato',
  'pecora',
  'pectora',
  'pectus',
  'pecudes',
  'pecudis',
  'pecudum',
  'pecus',
  'pedasum',
  'pedatis',
  'pede',
  'pedem',
  'pedes',
  'pedites',
  'peditum',
  'pegasus',
  'pejor',
  'pejus',
  'pelagus',
  'pelias',
  'pella',
  'pellem',
  'pellene',
  'pellere',
  'pellex',
  'pellis',
  'pelops',
  'pelusii',
  'pendeat',
  'pendent',
  'pendeo',
  'pendere',
  'penei',
  'peneo',
  'peneus',
  'penulas',
  'penulis',
  'penuria',
  'pera',
  'peracta',
  'peracto',
  'peragam',
  'peragas',
  'peragi',
  'perapte',
  'perat',
  'percipi',
  'perdat',
  'perdere',
  'perdes',
  'perdet',
  'perdiu',
  'pereant',
  'pereas',
  'peregit',
  'peregre',
  'pereunt',
  'perfert',
  'perfici',
  'perflat',
  'perflet',
  'perfusa',
  'pergant',
  'perge',
  'pergens',
  'pergere',
  'pergunt',
  'periit',
  'perinde',
  'perire',
  'periret',
  'peritia',
  'peritos',
  'peritum',
  'peritus',
  'perlata',
  'perlong',
  'permeat',
  'pernox',
  'perraro',
  'persei',
  'perseis',
  'persen',
  'perseo',
  'perses',
  'persica',
  'persici',
  'persico',
  'persona',
  'perunt',
  'perveni',
  'pervium',
  'pessima',
  'pessimo',
  'peste',
  'pestis',
  'petant',
  'petas',
  'petebat',
  'petens',
  'petenti',
  'peteret',
  'petiere',
  'petiit',
  'petimus',
  'petitis',
  'petr',
  'petram',
  'petris',
  'petrosa',
  'petunt',
  'phagres',
  'phaleri',
  'phallum',
  'phasi',
  'phegei',
  'pheneum',
  'pheron',
  'phialae',
  'phialis',
  'philae',
  'phla',
  'phlegra',
  'phocide',
  'phorcyn',
  'phormus',
  'phrixas',
  'phrixo',
  'phrygas',
  'phrygem',
  'phrygia',
  'phrygis',
  'phrygum',
  'phryx',
  'physica',
  'pice',
  'picem',
  'pici',
  'picis',
  'pictis',
  'pictum',
  'pierica',
  'pierum',
  'pigebit',
  'pignus',
  'pigres',
  'pilei',
  'pileos',
  'pili',
  'pilis',
  'pilorus',
  'pilos',
  'pilum',
  'pingui',
  'pinguis',
  'pingunt',
  'pinsunt',
  'pinus',
  'pirenen',
  'piromi',
  'piromis',
  'pisam',
  'pisse',
  'pissent',
  'pitane',
  'placant',
  'placato',
  'placens',
  'placent',
  'placere',
  'placet',
  'placiam',
  'placid',
  'placide',
  'placuit',
  'plaga',
  'plagam',
  'plantis',
  'planum',
  'planxit',
  'plebe',
  'plebem',
  'plebi',
  'plena',
  'plenae',
  'plenam',
  'pleni',
  'pleno',
  'plenos',
  'plenus',
  'pluat',
  'pluebat',
  'pluerat',
  'pluere',
  'pluit',
  'plumas',
  'plumbo',
  'plumo',
  'plurima',
  'plurimo',
  'plurium',
  'pluto',
  'plutum',
  'pluvi',
  'pluvia',
  'pluvii',
  'pocula',
  'poculis',
  'poculum',
  'poesin',
  'poeta',
  'poetae',
  'polit',
  'politum',
  'politus',
  'polum',
  'polybi',
  'poma',
  'pompam',
  'pompas',
  'ponam',
  'ponamus',
  'ponat',
  'pondere',
  'pondus',
  'pone',
  'ponenda',
  'ponens',
  'ponere',
  'poneres',
  'poni',
  'ponit',
  'ponito',
  'ponitur',
  'pono',
  'pons',
  'ponte',
  'pontem',
  'ponti',
  'pontium',
  'pontum',
  'ponunt',
  'populi',
  'populis',
  'populo',
  'populum',
  'porata',
  'porci',
  'porcos',
  'porino',
  'porrigi',
  'porta',
  'portae',
  'portare',
  'portas',
  'portatu',
  'portis',
  'portu',
  'poscere',
  'positae',
  'positio',
  'positis',
  'positos',
  'positum',
  'positus',
  'posse',
  'possem',
  'possent',
  'possim',
  'possint',
  'possis',
  'possum',
  'possunt',
  'post',
  'posteri',
  'postero',
  'posthac',
  'postica',
  'postico',
  'postulo',
  'posuere',
  'potant',
  'potasse',
  'potat',
  'potatu',
  'pote',
  'potens',
  'potente',
  'poteram',
  'poteras',
  'poterat',
  'poteris',
  'poterit',
  'potest',
  'potior',
  'potiri',
  'potiti',
  'potito',
  'potitus',
  'potu',
  'potuere',
  'potui',
  'potuis',
  'potum',
  'potuque',
  'prae',
  'praebet',
  'praeco',
  'praedam',
  'praede',
  'praeeat',
  'praeest',
  'praelii',
  'praelio',
  'praeses',
  'praesii',
  'praesit',
  'praesta',
  'praesto',
  'praetor',
  'prandit',
  'prato',
  'pratum',
  'prava',
  'pravis',
  'pravos',
  'pravus',
  'precata',
  'precor',
  'premas',
  'pressi',
  'pressum',
  'pretii',
  'pretio',
  'priami',
  'pridem',
  'primam',
  'primas',
  'primo',
  'primos',
  'primus',
  'prior',
  'priora',
  'priorem',
  'priores',
  'prioris',
  'prisca',
  'priscam',
  'priscis',
  'prisco',
  'prius',
  'privari',
  'privata',
  'privati',
  'proavum',
  'proba',
  'probans',
  'probant',
  'probari',
  'probata',
  'probati',
  'probato',
  'probavi',
  'probe',
  'probra',
  'probro',
  'probrum',
  'proclem',
  'procles',
  'proclis',
  'procos',
  'procul',
  'prodest',
  'prodiit',
  'prodit',
  'produci',
  'profers',
  'profert',
  'profugi',
  'prognat',
  'proh',
  'proinde',
  'prolata',
  'proles',
  'prolis',
  'promat',
  'prome',
  'promens',
  'promere',
  'promis',
  'prompte',
  'prompti',
  'prona',
  'pronaea',
  'pronus',
  'propago',
  'prope',
  'propere',
  'propius',
  'propono',
  'proprie',
  'proprio',
  'proris',
  'prosit',
  'prosper',
  'proteus',
  'prout',
  'proxim',
  'proxime',
  'prudens',
  'psammi',
  'psylli',
  'ptae',
  'ptoum',
  'publica',
  'publice',
  'publici',
  'publico',
  'pudeat',
  'pudenda',
  'pudet',
  'pudicam',
  'pudor',
  'pudore',
  'pudorem',
  'puer',
  'pueruli',
  'puerulo',
  'pugilum',
  'pugnam',
  'pugnans',
  'pugnare',
  'pugnent',
  'pugnis',
  'pulcher',
  'pulchra',
  'pulchre',
  'pulcra',
  'pulcram',
  'pulcris',
  'pullis',
  'pulsae',
  'pulsare',
  'pulses',
  'pulsi',
  'pulsos',
  'pulsum',
  'pulsus',
  'punctam',
  'punctis',
  'punctum',
  'punico',
  'punire',
  'puniri',
  'puppes',
  'puppim',
  'puppis',
  'pura',
  'puram',
  'pure',
  'purgant',
  'purpura',
  'pusilla',
  'pusilli',
  'putabam',
  'putabas',
  'putabat',
  'putabo',
  'putans',
  'putare',
  'putarem',
  'putaret',
  'putarim',
  'putat',
  'putatur',
  'puteis',
  'putem',
  'puteo',
  'puteos',
  'putrido',
  'pylaeum',
  'pylas',
  'pyra',
  'pyram',
  'pyrene',
  'pyreton',
  'pyrgum',
  'pytheam',
  'pytheas',
  'pythia',
  'pythiae',
  'pythiam',
  'pythica',
  'pythii',
  'pythios',
  'pythium',
  'pytho',
  'quadam',
  'quae',
  'quaeri',
  'quaero',
  'quaeso',
  'quaestu',
  'quaevis',
  'quales',
  'quali',
  'qualia',
  'qualos',
  'quamdam',
  'quamdiu',
  'quamque',
  'quamve',
  'quanam',
  'quandam',
  'quandiu',
  'quando',
  'quantam',
  'quantas',
  'quanto',
  'quaque',
  'quare',
  'quarit',
  'quarta',
  'quartae',
  'quartam',
  'quarti',
  'quarto',
  'quartum',
  'quarum',
  'quasdam',
  'quasi',
  'quato',
  'quatuor',
  'quavis',
  'queas',
  'queat',
  'quem',
  'quemdam',
  'quemque',
  'quendam',
  'queo',
  'quercu',
  'quercus',
  'queri',
  'quia',
  'quicum',
  'quidam',
  'quiddam',
  'quidnam',
  'quidni',
  'quies',
  'quiete',
  'quietem',
  'quietum',
  'quietus',
  'quievit',
  'quin',
  'quinis',
  'quinta',
  'quintam',
  'quinti',
  'quinto',
  'quintos',
  'quintus',
  'quis',
  'quisnam',
  'quoad',
  'quodve',
  'quomodo',
  'quonam',
  'quondam',
  'quoniam',
  'quoquam',
  'quoquo',
  'quorsum',
  'quorum',
  'quos',
  'quosque',
  'quotnam',
  'quovis',
  'quum',
  'quumque',
  'rabido',
  'radians',
  'radice',
  'radicem',
  'radices',
  'radii',
  'radiis',
  'radix',
  'ramenta',
  'ramis',
  'ramo',
  'ramos',
  'ramum',
  'rana',
  'rapere',
  'rapidus',
  'rapiend',
  'rapiens',
  'rapinam',
  'rapinis',
  'raptam',
  'raptas',
  'rapti',
  'raptim',
  'raptu',
  'raptum',
  'raptus',
  'raras',
  'rarem',
  'rari',
  'rariam',
  'rarius',
  'raro',
  'raros',
  'rasisse',
  'rata',
  'ratam',
  'ratas',
  'ratio',
  'ratione',
  'rationi',
  'ratis',
  'ratum',
  'reale',
  'reali',
  'realis',
  'reas',
  'reatas',
  'rebus',
  'recens',
  'recepi',
  'recepit',
  'recepto',
  'receptu',
  'recessu',
  'recipe',
  'recipio',
  'recipit',
  'recta',
  'rectam',
  'recte',
  'recto',
  'rectos',
  'rectus',
  'recubat',
  'recusas',
  'redacta',
  'redacti',
  'reddant',
  'reddas',
  'reddent',
  'reddere',
  'reddi',
  'reddit',
  'reddito',
  'reddo',
  'reddunt',
  'redeas',
  'redeat',
  'redi',
  'redibat',
  'rediens',
  'rediere',
  'redigam',
  'redigat',
  'redigi',
  'redii',
  'rediit',
  'redimam',
  'redire',
  'rediret',
  'redit',
  'reditu',
  'reducat',
  'reduces',
  'reduci',
  'reducit',
  'redux',
  'reduxit',
  'referat',
  'referet',
  'refero',
  'referre',
  'referri',
  'regem',
  'regia',
  'regiae',
  'regibus',
  'reginae',
  'regio',
  'regione',
  'regis',
  'regitur',
  'regius',
  'regna',
  'regnant',
  'regnare',
  'regnat',
  'regno',
  'regnum',
  'regulae',
  'regulis',
  'reipsa',
  'relabor',
  'relata',
  'relatae',
  'relatam',
  'relati',
  'relato',
  'relicto',
  'religio',
  'reliqua',
  'reliqui',
  'remanet',
  'remi',
  'remiges',
  'remigum',
  'remis',
  'remisit',
  'remissa',
  'remitte',
  'remotam',
  'remoti',
  'remotus',
  'removit',
  'remque',
  'repelli',
  'repente',
  'reperi',
  'reperio',
  'repetam',
  'repetet',
  'repetii',
  'replent',
  'repleri',
  'repleti',
  'repleto',
  'repone',
  'reponit',
  'repono',
  'repulsa',
  'reputa',
  'reputat',
  'reque',
  'reseca',
  'resonat',
  'resque',
  'resumam',
  'retenta',
  'retia',
  'retinet',
  'retuli',
  'reum',
  'reus',
  'revera',
  'reversi',
  'reverti',
  'revoces',
  'revolvo',
  'revulso',
  'rhea',
  'rheam',
  'rhegii',
  'rheneam',
  'rhodo',
  'rhodope',
  'rhodum',
  'rhodus',
  'rhombum',
  'rhypes',
  'ridem',
  'ridens',
  'rideo',
  'rideri',
  'ridet',
  'ridis',
  'rigatur',
  'rigida',
  'rigidat',
  'rigidis',
  'ripas',
  'risu',
  'risui',
  'risum',
  'ritas',
  'rite',
  'ritibus',
  'ritu',
  'rius',
  'rivi',
  'rivos',
  'rivulus',
  'rivus',
  'rixae',
  'rixam',
  'robore',
  'robusta',
  'robusti',
  'robusto',
  'rodit',
  'rogans',
  'rogare',
  'rogarem',
  'rogaret',
  'rogasse',
  'rogatu',
  'rogatus',
  'rori',
  'rosa',
  'rostris',
  'rostro',
  'rostrum',
  'rotae',
  'rotalem',
  'rotarum',
  'rotunda',
  'ruber',
  'rubia',
  'rubro',
  'rubrum',
  'rudis',
  'ruere',
  'rugitus',
  'ruinae',
  'rulei',
  'ruleis',
  'rumnam',
  'rumor',
  'rumorem',
  'rumpens',
  'rupit',
  'rupta',
  'rupto',
  'ruri',
  'rursum',
  'rursus',
  'rutilo',
  'sabacon',
  'sabuli',
  'sabulum',
  'sabyllo',
  'sacae',
  'saccum',
  'sacer',
  'sacis',
  'sacra',
  'sacrae',
  'sacrata',
  'sacri',
  'sacris',
  'sacrum',
  'saeculi',
  'saepe',
  'saeviit',
  'saevus',
  'sagares',
  'sagarin',
  'sagis',
  'saiten',
  'saites',
  'salamis',
  'salis',
  'salsus',
  'saltus',
  'salus',
  'salutem',
  'saluti',
  'salva',
  'salvete',
  'salvis',
  'salvo',
  'salvos',
  'salvum',
  'salvus',
  'sami',
  'samia',
  'samiis',
  'samios',
  'samo',
  'samum',
  'samus',
  'sana',
  'sanae',
  'sanam',
  'sanare',
  'sanavit',
  'sancte',
  'sancti',
  'sanctum',
  'sanctus',
  'sane',
  'sanen',
  'sanguis',
  'sani',
  'sanum',
  'saoque',
  'sapaei',
  'sapio',
  'sapit',
  'sapor',
  'sapore',
  'sapores',
  'saporis',
  'sappho',
  'sardium',
  'sarie',
  'sarta',
  'sata',
  'satiata',
  'satiati',
  'satis',
  'satius',
  'sativae',
  'satrae',
  'satras',
  'satris',
  'satum',
  'saturni',
  'saturno',
  'saucium',
  'saulio',
  'saulius',
  'saxa',
  'saxeos',
  'saxeus',
  'saxis',
  'saxo',
  'scabiem',
  'scatuit',
  'scelus',
  'sceptra',
  'sciam',
  'sciant',
  'sciat',
  'sciatho',
  'scidrum',
  'sciebam',
  'sciendi',
  'sciens',
  'scimus',
  'scio',
  'scioli',
  'sciona',
  'scire',
  'scirem',
  'sciret',
  'sciri',
  'scis',
  'scissam',
  'scissis',
  'sciton',
  'scitote',
  'scituri',
  'sciunt',
  'scius',
  'scolon',
  'scribae',
  'scribam',
  'scribi',
  'scripta',
  'scripto',
  'scrobem',
  'scuta',
  'scuti',
  'scutis',
  'scylax',
  'scyles',
  'scyrius',
  'scythae',
  'scytham',
  'scythes',
  'scythia',
  'seca',
  'secans',
  'secari',
  'secato',
  'secedo',
  'secius',
  'secreta',
  'secreti',
  'sectis',
  'sectos',
  'secunda',
  'secundi',
  'secundo',
  'securi',
  'securus',
  'secus',
  'secuta',
  'secuti',
  'secutum',
  'secutus',
  'sedebat',
  'sedens',
  'sedent',
  'sedenti',
  'sedere',
  'sedetis',
  'sedili',
  'sedimus',
  'sedium',
  'sedulam',
  'sedulo',
  'segetes',
  'segnius',
  'seipsum',
  'selecta',
  'selegit',
  'seligo',
  'sellae',
  'sellam',
  'semelen',
  'semen',
  'semidei',
  'semideo',
  'semina',
  'semine',
  'semita',
  'semitam',
  'senatus',
  'senect',
  'senem',
  'seni',
  'senii',
  'senis',
  'sensi',
  'sensim',
  'sensu',
  'sensus',
  'sensuum',
  'senti',
  'sentiat',
  'sentio',
  'sentit',
  'seorsim',
  'seorsum',
  'septies',
  'septima',
  'septimo',
  'septum',
  'sepulta',
  'sepum',
  'sequens',
  'sequi',
  'sequor',
  'sera',
  'serere',
  'serie',
  'seriem',
  'seriis',
  'serio',
  'serior',
  'seritur',
  'sermo',
  'sermone',
  'sero',
  'serpens',
  'serta',
  'serunt',
  'serus',
  'serva',
  'servabo',
  'servam',
  'servare',
  'servari',
  'servata',
  'servili',
  'servire',
  'servis',
  'servo',
  'servum',
  'servus',
  'sesamo',
  'sese',
  'seseque',
  'sesti',
  'sesto',
  'sestum',
  'sestus',
  'setis',
  'sevare',
  'severe',
  'severum',
  'sextam',
  'sexto',
  'sextus',
  'sexum',
  'siccata',
  'sicco',
  'siccum',
  'siccus',
  'sicilia',
  'siculi',
  'sicuti',
  'sidera',
  'sidere',
  'sidon',
  'sidonem',
  'sidonio',
  'sigei',
  'sigilla',
  'sigillo',
  'signa',
  'signi',
  'signis',
  'signum',
  'sile',
  'sileni',
  'silenus',
  'silva',
  'silvis',
  'simiis',
  'simile',
  'similem',
  'simili',
  'similia',
  'similum',
  'simo',
  'simos',
  'simplex',
  'simu',
  'simulac',
  'simus',
  'sinam',
  'sinat',
  'sindica',
  'sindone',
  'sindos',
  'sinebat',
  'sinere',
  'singula',
  'singuli',
  'sinis',
  'sinit',
  'sinite',
  'sinito',
  'sinope',
  'sinopes',
  'sint',
  'sinus',
  'siphnii',
  'siphno',
  'siphnum',
  'siri',
  'sirin',
  'sirites',
  'sirius',
  'sita',
  'sitae',
  'sitalce',
  'sitam',
  'sitas',
  'siti',
  'sitiat',
  'sitim',
  'sitire',
  'sitorum',
  'sitos',
  'situ',
  'situla',
  'siuph',
  'sive',
  'smerdi',
  'smerdin',
  'smyrna',
  'smyrnae',
  'sobole',
  'sobolem',
  'soboles',
  'soccis',
  'socero',
  'socerum',
  'socia',
  'sociae',
  'sociam',
  'sociis',
  'socium',
  'sodalem',
  'sogdis',
  'sola',
  'solae',
  'solam',
  'solaque',
  'sole',
  'soleat',
  'solem',
  'solenne',
  'solent',
  'solere',
  'soli',
  'solidi',
  'soliis',
  'solique',
  'solis',
  'solita',
  'soliti',
  'solitis',
  'solitos',
  'solitum',
  'solois',
  'solonis',
  'solorum',
  'solos',
  'solum',
  'soluta',
  'solutis',
  'soluto',
  'solutus',
  'solvant',
  'solve',
  'solvi',
  'solvit',
  'solvite',
  'solvunt',
  'solymi',
  'somnio',
  'somnis',
  'somnium',
  'somno',
  'somnum',
  'somnus',
  'sonabat',
  'sonaret',
  'soni',
  'sonides',
  'sonitu',
  'sonitum',
  'sono',
  'sonum',
  'sophani',
  'sopitus',
  'sopor',
  'sopore',
  'sorore',
  'sorores',
  'sororis',
  'sors',
  'sortem',
  'sortita',
  'spaca',
  'spaco',
  'spargit',
  'sparsa',
  'sparsim',
  'spartae',
  'spartam',
  'sparto',
  'specie',
  'speciei',
  'spectat',
  'spectri',
  'specu',
  'specula',
  'specus',
  'spic',
  'spicis',
  'spicula',
  'spina',
  'spinas',
  'spioque',
  'spirans',
  'spissa',
  'splenem',
  'spoliis',
  'sponte',
  'sporta',
  'spreta',
  'spretam',
  'spretis',
  'spuma',
  'squamis',
  'stabat',
  'stabile',
  'stabula',
  'stabulo',
  'stadio',
  'stadium',
  'stagnis',
  'stagno',
  'stanno',
  'stans',
  'stant',
  'stantem',
  'stantes',
  'stanti',
  'stare',
  'starent',
  'staret',
  'stas',
  'stat',
  'state',
  'statio',
  'statu',
  'statuae',
  'statuam',
  'statui',
  'statum',
  'status',
  'stella',
  'stercus',
  'stet',
  'stetere',
  'stetis',
  'stetit',
  'stipem',
  'stipite',
  'stirpe',
  'stirpis',
  'stirps',
  'stiv',
  'stolida',
  'stolide',
  'storae',
  'strage',
  'stragem',
  'strages',
  'strata',
  'stratio',
  'stratis',
  'strato',
  'stratum',
  'strenue',
  'strepis',
  'stricto',
  'struere',
  'struit',
  'stryme',
  'strymen',
  'studens',
  'studere',
  'studiis',
  'studium',
  'stulte',
  'stulti',
  'stultum',
  'stultus',
  'stum',
  'stupam',
  'stupor',
  'stus',
  'styx',
  'suada',
  'suade',
  'suadens',
  'suadeo',
  'suadet',
  'suam',
  'suamque',
  'suaque',
  'suarum',
  'suas',
  'suasit',
  'suavem',
  'suavia',
  'subacta',
  'subacti',
  'subacto',
  'subactu',
  'subeat',
  'subegit',
  'subesse',
  'subiit',
  'subinde',
  'subire',
  'subit',
  'sublata',
  'sublato',
  'sublime',
  'sublimi',
  'subtus',
  'subula',
  'subulci',
  'succida',
  'sudore',
  'sudorem',
  'sufflat',
  'suibus',
  'suis',
  'suisque',
  'suisti',
  'sulco',
  'sulcos',
  'sulcum',
  'sumam',
  'sumatis',
  'sumendi',
  'sumens',
  'sumere',
  'sumi',
  'summam',
  'summe',
  'summi',
  'summo',
  'summum',
  'sumne',
  'sumo',
  'sumpsit',
  'sumptae',
  'sumptis',
  'sumpto',
  'sumptum',
  'sumptus',
  'sumque',
  'sumsit',
  'sumta',
  'sumtis',
  'sumto',
  'sumtu',
  'sumtum',
  'sumturi',
  'sumunt',
  'sumus',
  'sunium',
  'sunta',
  'sunto',
  'suntque',
  'suoque',
  'suorum',
  'suos',
  'suosque',
  'superat',
  'superbi',
  'superes',
  'superf',
  'superne',
  'supplex',
  'suppono',
  'supra',
  'surda',
  'surgens',
  'surgunt',
  'susa',
  'suscipe',
  'suscipi',
  'susis',
  'susque',
  'sutn',
  'sutura',
  'suturam',
  'suumne',
  'suumque',
  'syagri',
  'syagrus',
  'sybari',
  'sybarin',
  'sybaris',
  'syleum',
  'symen',
  'syriae',
  'syriam',
  'syriis',
  'syrios',
  'syris',
  'syrorum',
  'syros',
  'syrtin',
  'syrtis',
  'tabalum',
  'tabam',
  'tabella',
  'taberna',
  'tabiti',
  'tabula',
  'taceo',
  'tacere',
  'tacite',
  'tactio',
  'tacto',
  'tactu',
  'taeorum',
  'talarem',
  'tale',
  'talem',
  'talenta',
  'taleque',
  'tales',
  'tali',
  'talia',
  'talique',
  'talis',
  'talium',
  'tamdiu',
  'tamen',
  'tametsi',
  'tamquam',
  'tamque',
  'tamynas',
  'tanai',
  'tanain',
  'tanais',
  'tandem',
  'tangam',
  'tangi',
  'tango',
  'tangunt',
  'tanites',
  'tanta',
  'tantam',
  'tantas',
  'tanti',
  'tantis',
  'tanto',
  'tantum',
  'tantus',
  'tarento',
  'tartara',
  'tartari',
  'tatem',
  'tatio',
  'tatis',
  'taurica',
  'tauro',
  'tauros',
  'taurus',
  'taxacis',
  'teari',
  'teaspis',
  'tect',
  'tecta',
  'tecto',
  'tectum',
  'tecum',
  'tegeae',
  'tegens',
  'tegmine',
  'teii',
  'teium',
  'telam',
  'teline',
  'telines',
  'telis',
  'tellias',
  'tello',
  'tellum',
  'tellure',
  'telo',
  'telum',
  'tely',
  'temeni',
  'temere',
  'temnus',
  'temoni',
  'temonis',
  'tempe',
  'templa',
  'templum',
  'tempore',
  'tempus',
  'tendat',
  'tendere',
  'tendit',
  'tendo',
  'tendunt',
  'tene',
  'teneat',
  'tenebat',
  'tenedo',
  'tenedum',
  'tenelli',
  'tenemus',
  'tenens',
  'tenent',
  'tenenti',
  'teneo',
  'tenera',
  'tenere',
  'teneres',
  'teneret',
  'teneri',
  'teneris',
  'tenerum',
  'tenetur',
  'teniis',
  'tensas',
  'tentabo',
  'tentare',
  'tentate',
  'tentato',
  'tenue',
  'tenuem',
  'tenuere',
  'tenuia',
  'tenuis',
  'tenus',
  'teos',
  'tepida',
  'terage',
  'tereat',
  'terei',
  'tergis',
  'tergo',
  'tergum',
  'tergus',
  'terilli',
  'terna',
  'terque',
  'terr',
  'terrae',
  'terram',
  'terras',
  'terreat',
  'terris',
  'territi',
  'terror',
  'terrore',
  'tertiam',
  'tertii',
  'tertio',
  'tertium',
  'tertius',
  'terum',
  'testari',
  'testem',
  'testes',
  'testis',
  'tethys',
  'tetigi',
  'teucri',
  'teum',
  'texebat',
  'texisse',
  'texta',
  'texunt',
  'thalamo',
  'thasi',
  'thasiis',
  'thasios',
  'thasum',
  'thaumas',
  'thebais',
  'thebani',
  'thebe',
  'thebis',
  'themin',
  'theram',
  'theras',
  'there',
  'therma',
  'thermam',
  'therque',
  'thesei',
  'thestin',
  'thetidi',
  'thia',
  'thiopum',
  'thoes',
  'thonis',
  'thracas',
  'thracio',
  'thracis',
  'thracum',
  'thrax',
  'throno',
  'thura',
  'thure',
  'thus',
  'thyream',
  'thyreas',
  'tiara',
  'tiaram',
  'tibia',
  'tibiae',
  'tibiam',
  'tibine',
  'tibique',
  'tibisis',
  'tignis',
  'tigris',
  'timeas',
  'timenda',
  'timens',
  'timeo',
  'timere',
  'timet',
  'timidae',
  'timo',
  'timon',
  'timonax',
  'timoris',
  'timui',
  'tinctae',
  'tinctas',
  'tisiae',
  'titacus',
  'titanas',
  'titanes',
  'tithono',
  'titiam',
  'titormi',
  'titulum',
  'tium',
  'tius',
  'tmolo',
  'tmolum',
  'toga',
  'tollens',
  'tollere',
  'tollit',
  'tollunt',
  'tomyri',
  'tomyrin',
  'tomyris',
  'tonat',
  'tondere',
  'tonitru',
  'tonsa',
  'tonsis',
  'tonsus',
  'torno',
  'toronam',
  'torque',
  'torquem',
  'torret',
  'torrido',
  'torum',
  'torve',
  'tostum',
  'tota',
  'totae',
  'totam',
  'totaque',
  'totarum',
  'toti',
  'totidem',
  'totis',
  'totius',
  'toto',
  'totos',
  'totque',
  'totum',
  'totus',
  'trabe',
  'tract',
  'tractes',
  'tractu',
  'tractum',
  'tradam',
  'trade',
  'tradens',
  'tradere',
  'tradita',
  'tradite',
  'tradito',
  'trado',
  'tradunt',
  'trahant',
  'trahens',
  'trahere',
  'trahit',
  'trahito',
  'trajeci',
  'trajici',
  'tramam',
  'transeo',
  'transit',
  'trausis',
  'tremant',
  'tremuit',
  'trepidi',
  'tribu',
  'tribuam',
  'tribuat',
  'tribue',
  'tribui',
  'tribum',
  'tribuo',
  'tributo',
  'triceni',
  'tricies',
  'tridui',
  'triduo',
  'triopii',
  'tripus',
  'tristem',
  'tristes',
  'tristi',
  'tristis',
  'trita',
  'tritam',
  'triteas',
  'tritici',
  'tritico',
  'tritis',
  'trito',
  'tritone',
  'tritoni',
  'trium',
  'troade',
  'troia',
  'trojam',
  'trojana',
  'truces',
  'trunca',
  'trutina',
  'tuae',
  'tuarum',
  'tuas',
  'tubos',
  'tueamur',
  'tuentur',
  'tueri',
  'tueris',
  'tuique',
  'tuis',
  'tuleram',
  'tulerat',
  'tulisse',
  'tulit',
  'tumor',
  'tumorem',
  'tumulis',
  'tumulo',
  'tumulos',
  'tumulum',
  'tunc',
  'tunicis',
  'tuorum',
  'tuos',
  'tuosque',
  'turba',
  'turbae',
  'turbant',
  'turbido',
  'turbine',
  'turgida',
  'turma',
  'turpem',
  'turpis',
  'turri',
  'turrim',
  'turris',
  'turrita',
  'turrium',
  'tussit',
  'tuta',
  'tutamen',
  'tutanda',
  'tutas',
  'tutelae',
  'tuto',
  'tutoque',
  'tutor',
  'tutos',
  'tutum',
  'tutus',
  'tydeum',
  'tymnae',
  'tymnis',
  'tympano',
  'tyndari',
  'typis',
  'tyranni',
  'tyranno',
  'tyri',
  'tyria',
  'tyrii',
  'tyrio',
  'tyritae',
  'tyro',
  'tyrum',
  'tyrus',
  'ubera',
  'uberem',
  'ubinam',
  'uerant',
  'ulcera',
  'ulixi',
  'ulixis',
  'ullae',
  'ullam',
  'ullas',
  'ullis',
  'ullius',
  'ullo',
  'ullos',
  'ullus',
  'ulmo',
  'ulnarum',
  'ulnis',
  'ultima',
  'ultimi',
  'ultimo',
  'ultimum',
  'ultio',
  'ultione',
  'ultra',
  'ultro',
  'ulturus',
  'ululant',
  'umbra',
  'umbriam',
  'umbris',
  'umquam',
  'umque',
  'unam',
  'uncta',
  'unda',
  'undanti',
  'unde',
  'undenam',
  'ungues',
  'ungulam',
  'ungulis',
  'unicam',
  'unicum',
  'unione',
  'unita',
  'unitam',
  'unitas',
  'univesa',
  'unus',
  'urania',
  'uraniae',
  'uraniam',
  'urbe',
  'urbem',
  'urbi',
  'urbibus',
  'urbis',
  'urbium',
  'urendo',
  'urgeat',
  'urgebat',
  'urgente',
  'urgere',
  'urgeret',
  'urges',
  'urget',
  'urina',
  'urinae',
  'urinam',
  'urnarum',
  'ursos',
  'usitata',
  'usitate',
  'usos',
  'usuri',
  'usuros',
  'usurum',
  'utantur',
  'utar',
  'utatur',
  'utemur',
  'utendi',
  'utendo',
  'utens',
  'uter',
  'uteris',
  'utero',
  'uterque',
  'utetur',
  'utilem',
  'utiles',
  'utilia',
  'utilius',
  'utinam',
  'utique',
  'utor',
  'utpote',
  'utqiue',
  'utra',
  'utram',
  'utraque',
  'utres',
  'utrique',
  'utris',
  'utrium',
  'utrum',
  'utuntur',
  'uvis',
  'uxor',
  'uxore',
  'uxorem',
  'uxoris',
  'uxorum',
  'vacabo',
  'vacans',
  'vacant',
  'vacante',
  'vacarem',
  'vacc',
  'vaccas',
  'vacua',
  'vacui',
  'vacuum',
  'vadit',
  'vadosum',
  'vafer',
  'vagari',
  'vagina',
  'vaginae',
  'vagitus',
  'valeat',
  'valebat',
  'valebo',
  'valemus',
  'valent',
  'valere',
  'valet',
  'valida',
  'valide',
  'valido',
  'validum',
  'validus',
  'vallem',
  'valles',
  'vallis',
  'vallo',
  'valuere',
  'vana',
  'vanam',
  'vano',
  'vanum',
  'vapor',
  'vapore',
  'vaporem',
  'variata',
  'variis',
  'vasa',
  'vascula',
  'vase',
  'vasta',
  'vastat',
  'vastum',
  'vatem',
  'vates',
  'vatibus',
  'vatis',
  'vatum',
  'veat',
  'vebitur',
  'vectas',
  'vecti',
  'vectos',
  'vectura',
  'vectus',
  'vehens',
  'vehit',
  'vehitur',
  'velim',
  'velint',
  'velis',
  'velit',
  'velitis',
  'velle',
  'vellem',
  'vellent',
  'vellere',
  'veloci',
  'velorum',
  'velox',
  'veluti',
  'venae',
  'venale',
  'venando',
  'venas',
  'venati',
  'venatio',
  'venatur',
  'vendere',
  'vendi',
  'vendita',
  'vendunt',
  'venenum',
  'venerat',
  'veneri',
  'veneris',
  'venerit',
  'veni',
  'veniant',
  'veniret',
  'venit',
  'vento',
  'ventos',
  'ventoso',
  'ventre',
  'ventrem',
  'ventres',
  'ventri',
  'ventris',
  'ventus',
  'venum',
  'venusta',
  'veracam',
  'veracem',
  'veracia',
  'veram',
  'verarum',
  'veras',
  'verax',
  'verba',
  'verbera',
  'verbis',
  'verbo',
  'verbum',
  'verene',
  'vereor',
  'vereri',
  'veretro',
  'vergit',
  'veris',
  'verita',
  'veritam',
  'veritas',
  'veriti',
  'veritos',
  'veritus',
  'verna',
  'verno',
  'vero',
  'verrem',
  'versa',
  'versata',
  'versi',
  'verso',
  'versos',
  'versum',
  'versus',
  'vertens',
  'vertere',
  'vertex',
  'verti',
  'vertit',
  'vertito',
  'veru',
  'verum',
  'verutum',
  'vesania',
  'vesanum',
  'vescens',
  'vesci',
  'vesta',
  'veste',
  'vestem',
  'vester',
  'vestes',
  'vesti',
  'vestiri',
  'vestium',
  'vestr',
  'vestra',
  'vestrae',
  'vestram',
  'vestras',
  'vestri',
  'vestris',
  'vestro',
  'vestrum',
  'vetabat',
  'vetera',
  'veterem',
  'veteres',
  'vetet',
  'vetitam',
  'vetitum',
  'vetulae',
  'vetus',
  'vexabat',
  'vexari',
  'vexato',
  'vexet',
  'viae',
  'viam',
  'viamque',
  'viarum',
  'vias',
  'viatico',
  'vibrans',
  'vice',
  'vicem',
  'vicen',
  'vicenis',
  'vicerat',
  'vicere',
  'viceris',
  'vices',
  'vicibus',
  'vicies',
  'vicinae',
  'vicinam',
  'vicinas',
  'vicinia',
  'vicinis',
  'vicinos',
  'vicinum',
  'vicinus',
  'vicisti',
  'vicit',
  'vicorum',
  'vicos',
  'victi',
  'victima',
  'victis',
  'victor',
  'victu',
  'vicum',
  'vicus',
  'vide',
  'videam',
  'videar',
  'videas',
  'videbar',
  'videbis',
  'videbo',
  'videbor',
  'videmur',
  'videns',
  'viderat',
  'videre',
  'viderem',
  'videres',
  'videret',
  'videri',
  'viderim',
  'videris',
  'viderit',
  'videro',
  'vides',
  'videte',
  'videtis',
  'vidi',
  'vidisse',
  'vidist',
  'vidit',
  'viduam',
  'viget',
  'vigilem',
  'vigilia',
  'vigorem',
  'viii',
  'viis',
  'viliori',
  'villi',
  'vimque',
  'vincere',
  'vincire',
  'vincis',
  'vinctis',
  'vincto',
  'vinctos',
  'vinctum',
  'vinculo',
  'vincunt',
  'vini',
  'vino',
  'vinum',
  'viperae',
  'viperam',
  'viperis',
  'vires',
  'virgini',
  'virgis',
  'virgo',
  'viri',
  'viribus',
  'viridi',
  'virilem',
  'virilis',
  'virium',
  'viro',
  'viroque',
  'virtute',
  'virtuti',
  'virum',
  'visa',
  'visae',
  'viscera',
  'viscum',
  'visio',
  'visione',
  'visitat',
  'visne',
  'visus',
  'vitabit',
  'vitae',
  'vitam',
  'vitare',
  'vitari',
  'vitate',
  'vitei',
  'vitem',
  'viteum',
  'vitibus',
  'vitio',
  'vitis',
  'vitium',
  'vitro',
  'viunt',
  'vivae',
  'vivam',
  'vivamus',
  'vivas',
  'vivere',
  'vivi',
  'vividae',
  'vivit',
  'vivo',
  'vivos',
  'vivunt',
  'vixdum',
  'vixisse',
  'vixque',
  'vobis',
  'vocabat',
  'vocant',
  'vocare',
  'vocaret',
  'vocari',
  'vocasse',
  'vocatae',
  'vocati',
  'vocato',
  'vocatum',
  'vocatus',
  'vocavit',
  'vocem',
  'vocis',
  'voco',
  'volabat',
  'volare',
  'volebam',
  'volebat',
  'volen',
  'volendi',
  'volens',
  'voles',
  'volesne',
  'voluero',
  'volui',
  'volun',
  'volunt',
  'volupe',
  'volvam',
  'volve',
  'volvens',
  'vomere',
  'vorum',
  'votis',
  'voto',
  'votum',
  'vulcani',
  'vulgari',
  'vulgata',
  'vulgo',
  'vulgus',
  'vulnera',
  'vulnere',
  'vulpium',
  'vult',
  'vultis',
  'vultque',
  'vultus',
  'vusque',
  'xanthii',
  'xantho',
  'zancla',
  'zaueces',
  'zeiran',
  'zelum',
  'zena',
  'zene',
  'zenia',
  'zeniam',
  'zeniis',
  'zona',
  'zonis',
  'zopyri',
  'zopyro',
  'zopyrus',
];
