import React from 'react';
import Popup from 'reactjs-popup';
import PropTypes from 'prop-types';
import 'reactjs-popup/dist/index.css';
import { Button } from './Button';
import { useState } from "react";

import { toast } from './toast2';
import { ToastContainer2 } from './toast2';

const contentStyle = { background: '#072333', border: "0px solid white", borderRadius: "15px" };
const overlayStyle = { background: 'rgba(0,0,0,0.5)' };
const arrowStyle = { color: '#C1E8FF' }; // style for an svg element

export const PopupModal = (props) => { 
    const { pass } = props;
    const className = `bg-dark text-white text-center p-8 rounded-xl`;
    const initialMessage = `Hi , \n\nPlease see your new credentials below. \n\nUsername: \nPassword: ${pass}\n\nThanks,\n`
    const [message, setMessage] = useState('')
    const [email, setEmail] = useState('someone@domain.com')
    const [subject, setSubject] = useState('Credentials')

    function Log () {
        console.log(message)
    }

    function sendDesktop () {
        if (document.getElementById('emailField').value < 1) {
            toast('Email Address cannot be empty!', { bgColor: 'bg-purple' });
            return
        }

        var link = `mailto:${email}`
        + "?subject=" + encodeURIComponent(`SECURE: ${subject}`)
        + "&body=" + encodeURIComponent(message)
        
        window.location.href = link;
    }

    function handleMessageChange () {
        setMessage(document.getElementById('messagebox').value)
    }

    function handleEmailChange () {
        setEmail(document.getElementById('emailField').value)
    }

    function handleSubjectChange () {
        setSubject(document.getElementById('subjectField').value)
    }

    function setSubjectFocus () {
        var secureSpan = document.getElementById('secureSpan')
        secureSpan.style.outline = "none !important"
        secureSpan.style.borderColor = "#719ECE"
        secureSpan.style.boxShadow = "0 0 10px #719ECE"
        // secureSpan.style.zIndex = "4"
    }

    function resetSubjectFocus () {
        var secureSpan = document.getElementById('secureSpan')
        secureSpan.style.outline = ""
        secureSpan.style.borderColor = ""
        secureSpan.style.boxShadow = ""
        secureSpan.style.zIndex = ""
    }

    const ref = React.createRef();

    return (
        <Popup 
            onOpen={handleMessageChange}
            trigger={<Button ref={ref} title="Send Secure Email" />} modal
            {...{ contentStyle, overlayStyle, arrowStyle }}
        >
            <ToastContainer2 />
          <div className={className}>
            <span style={{fontSize: "24pt"}}>Send a secure email</span>
            <br/>
            <br/>
            <div style={{display: "flex", paddingBottom: "10px"}}>
                <input id="emailField" placeholder={"Email Address..."} style={{
                        height:"35px", 
                        width: "100%", 
                        backgroundColor: "#C1E8FF", 
                        color: "#072333", 
                        padding: "10px", 
                        borderRadius: "10px",
                        fontSize: "12pt"
                    }} onChange={handleEmailChange} required>    
                </input>
            </div>
            <div style={{display: "flex"}}>
                {/* <span>SECURE:</span> */}
                <span id="secureSpan" style={{
                        height:"35px", 
                        width: "15%", 
                        backgroundColor: "#C1E8FF", 
                        color: "#072333", 
                        paddingTop: "6.5px", 
                        paddingRight: "0px",
                        borderRadius: "10px 0px 0px 10px",
                        fontSize: "12pt"
                    }}>
                        SECURE:
                </span>
                <input id="subjectField" defaultValue={"New Credentials"} style={{
                        height:"35px", 
                        width: "85%", 
                        backgroundColor: "#C1E8FF", 
                        color: "#072333", 
                        padding: "10px", 
                        paddingLeft: "4px",
                        borderRadius: "0px 10px 10px 0px",
                        fontSize: "12pt"
                    }} onChange={handleSubjectChange} onFocus={setSubjectFocus} onBlur={resetSubjectFocus}>
                </input>
            </div>
            <br/>
            <div style={{textAlign: "left", paddingBottom: "7px"}}>
                <span style={{fontSize: "11px", color: "#7496C4"}}><i>Please fill in the missing info below</i></span>
            </div>
            <div style={{display: "flex"}}>
                <textarea spellCheck="false" id="messagebox" style={{
                        height:"250px", 
                        width: "100%", 
                        backgroundColor: "#C1E8FF", 
                        color: "#072333", 
                        padding: "10px", 
                        borderRadius: "10px"
                    }} defaultValue={initialMessage} onChange={handleMessageChange}
                >
                </textarea>
            </div>
            <br/>
            <br/>
            <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                <div style={{paddingRight: "5px"}}>
                    <Button title="Send from here" />
                </div> 
                <div style={{paddingLeft: "5px"}}>
                    <Button title="Desktop mail app" onClick={sendDesktop} />
                </div>
            </div>
          </div>
        </Popup>
    );
};

PopupModal.defaultProps = {
    password: 'password',
  };

PopupModal.propTypes = {
    password: PropTypes.string,
};