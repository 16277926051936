import PropTypes from 'prop-types';
import React from 'react';

export const Button = React.forwardRef((props, ref) => {
  const { title, bgColor, bgHoverColor, onClick, textColor, textHoverColor, textActiveColor } = props;
  const colorClasses = `${bgColor} hover:${bgHoverColor} ${textColor} hover:${textHoverColor} active:${textActiveColor}`;
  const className = `${colorClasses} text-xl focus:outline-none font-semibold p-8 rounded-xl capitalize truncate shadow-lg`;

  return (
    <button ref={ref} className={className} onClick={onClick}>
      {title}
    </button>
  );
});

Button.defaultProps = {
  title: 'Button',
  bgColor: 'bg-purple',
  bgHoverColor: 'bg-white',
  textColor: 'text-dark',
  textHoverColor: 'text-dark',
  textActiveColor: 'text-dark',
};

Button.propTypes = {
  title: PropTypes.string,
  bgColor: PropTypes.string,
  bgHoverColor: PropTypes.string,
  onClick: PropTypes.func,
};
