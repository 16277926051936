export const norwegian = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
  '60',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '69',
  '70',
  '71',
  '72',
  '73',
  '74',
  '75',
  '76',
  '77',
  '78',
  '79',
  '80',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '88',
  '89',
  '90',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '97',
  '98',
  '99',
  '100',
  '101',
  '111',
  '123',
  '200',
  '222',
  '234',
  '300',
  '333',
  '345',
  '400',
  '444',
  '456',
  '500',
  '555',
  '567',
  '600',
  '666',
  '678',
  '700',
  '777',
  '789',
  '800',
  '888',
  '900',
  '999',
  '1000',
  '1111',
  '1234',
  '1492',
  '1500',
  '1600',
  '1700',
  '1800',
  '1900',
  '1910',
  '1920',
  '1925',
  '1930',
  '1935',
  '1940',
  '1945',
  '1950',
  '1955',
  '1960',
  '1965',
  '1970',
  '1975',
  '1980',
  '1985',
  '1990',
  '1991',
  '1992',
  '1993',
  '1994',
  '1995',
  '1996',
  '1997',
  '1998',
  '1999',
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2015',
  '2020',
  '2030',
  '2035',
  '2040',
  '2045',
  '2050',
  '2222',
  '2345',
  '2468',
  '3000',
  '3333',
  '3456',
  '4000',
  '4321',
  '4444',
  '4567',
  '5000',
  '5555',
  '5678',
  '6000',
  '6666',
  '6789',
  '7000',
  '7777',
  '8000',
  '8888',
  '9000',
  '9876',
  '9999',
  '-',
  '--',
  '---',
  '!',
  '!!',
  '!!!',
  '#',
  '##',
  '###',
  '$',
  '$$',
  '$$$',
  '%',
  '%%',
  '%%%',
  '&',
  '(',
  '()',
  ')',
  '*',
  '**',
  '***',
  ':',
  ':(',
  ':-(',
  ':)',
  ':-)',
  '""',
  '?',
  '??',
  '???',
  '@',
  '+',
  '++',
  '+++',
  '=',
  '==',
  '===',
  'a',
  'aa',
  'aaa',
  'aaaa',
  'ab',
  'abbed',
  'abbor',
  'abc',
  'aber',
  'abort',
  'ac',
  'ad',
  'adel',
  'adle',
  'adler',
  'ae',
  'af',
  'afga',
  'aften',
  'ag',
  'agder',
  'age',
  'agen',
  'agent',
  'agere',
  'agert',
  'agg',
  'agget',
  'agn',
  'agrar',
  'agurk',
  'ah',
  'aha',
  'ai',
  'aj',
  'ak',
  'ake',
  'aker',
  'akk',
  'akkar',
  'akryl',
  'aks',
  'akse',
  'aksel',
  'aksen',
  'akser',
  'akt',
  'akte',
  'akten',
  'akter',
  'aktet',
  'aktiv',
  'aktor',
  'al',
  'alarm',
  'albue',
  'album',
  'alder',
  'aldri',
  'ale',
  'alene',
  'aler',
  'alfa',
  'alge',
  'algen',
  'alger',
  'algin',
  'alias',
  'alibi',
  'alke',
  'alker',
  'all',
  'allah',
  'alle',
  'aller',
  'alles',
  'allmu',
  'alm',
  'alna',
  'alner',
  'alnor',
  'alpin',
  'alt',
  'altan',
  'altar',
  'alte',
  'alter',
  'altet',
  'alun',
  'alv',
  'alven',
  'alver',
  'alvor',
  'am',
  'ambar',
  'ambod',
  'ambra',
  'ame',
  'amen',
  'amfi',
  'amma',
  'amme',
  'ammen',
  'ammet',
  'amok',
  'amor',
  'ampel',
  'amper',
  'ampre',
  'an',
  'anal',
  'anbud',
  'and',
  'anda',
  'anden',
  'andra',
  'andre',
  'anemi',
  'anen',
  'aner',
  'anes',
  'ange',
  'angel',
  'angen',
  'anger',
  'anget',
  'angi',
  'angir',
  'angis',
  'angle',
  'angre',
  'angst',
  'aning',
  'anis',
  'anita',
  'ank',
  'anka',
  'anke',
  'ankel',
  'anken',
  'anket',
  'ankra',
  'ankre',
  'anla',
  'anna',
  'annal',
  'anne',
  'annen',
  'annet',
  'anno',
  'annua',
  'anode',
  'anrop',
  'anse',
  'anser',
  'anses',
  'ansig',
  'anslo',
  'anta',
  'antar',
  'antas',
  'anti',
  'anton',
  'anus',
  'ao',
  'aorta',
  'ap',
  'apa',
  'apal',
  'apart',
  'apati',
  'ape',
  'apen',
  'aper',
  'apet',
  'april',
  'apt',
  'apte',
  'aq',
  'aqua',
  'ar',
  'arak',
  'arden',
  'are',
  'areal',
  'arena',
  'aret',
  'arge',
  'argon',
  'argot',
  'argt',
  'argus',
  'arie',
  'arien',
  'arier',
  'arisk',
  'ark',
  'arm',
  'arme',
  'armen',
  'armer',
  'armet',
  'armod',
  'arna',
  'arne',
  'aroma',
  'arr',
  'arret',
  'arrig',
  'arsen',
  'art',
  'arta',
  'arte',
  'arten',
  'arter',
  'artet',
  'artig',
  'arv',
  'arve',
  'arven',
  'arvet',
  'as',
  'asal',
  'ascii',
  'asdic',
  'ase',
  'asen',
  'aset',
  'asete',
  'asia',
  'asiat',
  'ask',
  'aske',
  'asken',
  'asker',
  'asket',
  'aslak',
  'asp',
  'aspik',
  'astma',
  'asur',
  'aswan',
  'asyl',
  'at',
  'atal',
  'atalt',
  'aten',
  'athen',
  'atlas',
  'atlet',
  'atm',
  'atoll',
  'atom',
  'att',
  'atter',
  'attom',
  'attre',
  'au',
  'aud',
  'audio',
  'aue',
  'auf',
  'aug',
  'auke',
  'auken',
  'auker',
  'aukra',
  'aukte',
  'aula',
  'aule',
  'auler',
  'aur',
  'aura',
  'aure',
  'aurer',
  'auret',
  'aurum',
  'ause',
  'auser',
  'aust',
  'auto',
  'av',
  'aval',
  'avart',
  'avbud',
  'avdal',
  'avdra',
  'ave',
  'avec',
  'aven',
  'aveny',
  'avga',
  'avgi',
  'avgud',
  'avind',
  'avis',
  'avisa',
  'avkok',
  'avl',
  'avla',
  'avlat',
  'avle',
  'avlen',
  'avler',
  'avlet',
  'avlyd',
  'avsa',
  'avse',
  'avsi',
  'avsky',
  'avslo',
  'avta',
  'avtak',
  'avtar',
  'avtok',
  'avvei',
  'avvik',
  'aw',
  'ax',
  'ay',
  'az',
  'aztek',
  'b',
  'baby',
  'bach',
  'back',
  'backs',
  'bad',
  'bada',
  'bade',
  'bader',
  'badet',
  'bag',
  'bager',
  'bai',
  'bajas',
  'bak',
  'bake',
  'baken',
  'baker',
  'bakk',
  'bakka',
  'bakke',
  'bakom',
  'bakre',
  'baks',
  'baksa',
  'bakse',
  'bakst',
  'bakt',
  'bal',
  'bale',
  'balet',
  'balg',
  'bali',
  'balje',
  'ball',
  'balla',
  'balle',
  'bambi',
  'bamse',
  'banal',
  'banan',
  'band',
  'bande',
  'bandt',
  'bandy',
  'bane',
  'banen',
  'baner',
  'banet',
  'bang',
  'bange',
  'banjo',
  'bank',
  'banke',
  'bann',
  'banne',
  'bantu',
  'bar',
  'bardu',
  'bare',
  'baren',
  'barer',
  'bark',
  'barm',
  'barn',
  'barna',
  'barns',
  'baron',
  'barre',
  'barsk',
  'bart',
  'bas',
  'basal',
  'basar',
  'base',
  'baser',
  'baska',
  'baske',
  'bass',
  'bast',
  'baste',
  'bata',
  'baten',
  'batet',
  'batna',
  'batne',
  'baule',
  'baun',
  'baus',
  'baut',
  'bauta',
  'bavl',
  'bavle',
  'bb',
  'bbb',
  'bbbb',
  'bbc',
  'bc',
  'bcd',
  'bd',
  'be',
  'beat',
  'bebor',
  'bebos',
  'bed',
  'bedet',
  'bedre',
  'bedt',
  'befri',
  'begav',
  'begge',
  'begi',
  'begir',
  'beglo',
  'begra',
  'begre',
  'behag',
  'behov',
  'beige',
  'beile',
  'bein',
  'beina',
  'beine',
  'beins',
  'beise',
  'beist',
  'beit',
  'beita',
  'bejae',
  'beke',
  'beket',
  'bekk',
  'bekle',
  'bekre',
  'bel',
  'belen',
  'belg',
  'belja',
  'belje',
  'belma',
  'belme',
  'belt',
  'belte',
  'ben',
  'bende',
  'benk',
  'benka',
  'benke',
  'bens',
  'bent',
  'ber',
  'beret',
  'berg',
  'berga',
  'berge',
  'bergs',
  'berme',
  'bero',
  'beror',
  'bert',
  'berte',
  'bese',
  'besk',
  'beske',
  'beskt',
  'beslo',
  'besna',
  'besne',
  'best',
  'besyv',
  'bet',
  'bete',
  'beteg',
  'beten',
  'betla',
  'betle',
  'betok',
  'bety',
  'betyr',
  'bevar',
  'beve',
  'bevet',
  'bevis',
  'bevra',
  'bevre',
  'bf',
  'bg',
  'bh',
  'bi',
  'bia',
  'bidde',
  'bidig',
  'bidra',
  'bidro',
  'bie',
  'biet',
  'bifag',
  'biff',
  'bikk',
  'bikka',
  'bikke',
  'bil',
  'bilag',
  'bile',
  'bilen',
  'biler',
  'bill',
  'bille',
  'bilt',
  'bilte',
  'bilyd',
  'bind',
  'binde',
  'binge',
  'bingo',
  'binne',
  'bisak',
  'bisk',
  'biskt',
  'bisle',
  'bisp',
  'bit',
  'bite',
  'biten',
  'biter',
  'bitre',
  'bitt',
  'bitte',
  'bivra',
  'bivre',
  'bj',
  'bjart',
  'bjeff',
  'bjor',
  'bk',
  'bl',
  'bla',
  'blad',
  'blaff',
  'blakk',
  'blank',
  'blant',
  'blar',
  'blass',
  'blast',
  'blaut',
  'ble',
  'blei',
  'bleie',
  'bleig',
  'bleik',
  'bleiv',
  'blek',
  'bleke',
  'blekk',
  'blekt',
  'bles',
  'blese',
  'blest',
  'blev',
  'bli',
  'blid',
  'blide',
  'blidt',
  'blind',
  'blipp',
  'blir',
  'blitt',
  'blitz',
  'bloc',
  'blod',
  'blokk',
  'blom',
  'blond',
  'blot',
  'blote',
  'blott',
  'blues',
  'blund',
  'blunk',
  'bluss',
  'bly',
  'blyet',
  'blyg',
  'blygd',
  'blygt',
  'bm',
  'bn',
  'bo',
  'boa',
  'boaen',
  'bobla',
  'boble',
  'bodd',
  'bodde',
  'boden',
  'boder',
  'body',
  'boer',
  'bog',
  'boge',
  'bogen',
  'bogne',
  'bohem',
  'bok',
  'boka',
  'boken',
  'bokn',
  'boks',
  'bol',
  'boldt',
  'bolen',
  'bolig',
  'bolk',
  'bolle',
  'bolna',
  'bolne',
  'bolt',
  'bom',
  'bomba',
  'bombe',
  'bomme',
  'boms',
  'bonde',
  'bone',
  'bonet',
  'bonn',
  'bonns',
  'bonus',
  'boom',
  'bopel',
  'bor',
  'bora',
  'bord',
  'borda',
  'borde',
  'bords',
  'boren',
  'boret',
  'borg',
  'borga',
  'borre',
  'bort',
  'borte',
  'boso',
  'boss',
  'bot',
  'bota',
  'boten',
  'botne',
  'botns',
  'bowl',
  'bowle',
  'bp',
  'bq',
  'br',
  'bra',
  'bragd',
  'brak',
  'brake',
  'brakk',
  'brakt',
  'bram',
  'brann',
  'brant',
  'brase',
  'brask',
  'brast',
  'bratt',
  'braut',
  'bravo',
  'bre',
  'bred',
  'bredd',
  'bredt',
  'breen',
  'brei',
  'breid',
  'breie',
  'breit',
  'breke',
  'brem',
  'brems',
  'brer',
  'brest',
  'brett',
  'brev',
  'brigg',
  'brim',
  'brink',
  'bris',
  'brisk',
  'brist',
  'brite',
  'brodd',
  'broen',
  'brok',
  'broka',
  'brokk',
  'brom',
  'bror',
  'brott',
  'bru',
  'brua',
  'brud',
  'bruda',
  'brudd',
  'bruer',
  'bruk',
  'bruke',
  'brukt',
  'brun',
  'bruna',
  'brune',
  'brunt',
  'brur',
  'brura',
  'brus',
  'bruse',
  'brusk',
  'brust',
  'brutt',
  'bry',
  'brygg',
  'bryn',
  'bryne',
  'brynt',
  'bryr',
  'brysk',
  'bryst',
  'bryte',
  'bs',
  'bt',
  'bu',
  'bud',
  'budd',
  'budet',
  'budne',
  'budt',
  'buer',
  'buet',
  'bufe',
  'bugne',
  'buk',
  'buken',
  'bukk',
  'bukke',
  'bukse',
  'bukt',
  'bukta',
  'bukte',
  'bule',
  'bulka',
  'bulke',
  'bull',
  'bunke',
  'bunn',
  'bunne',
  'bunns',
  'buorm',
  'bur',
  'burde',
  'buret',
  'burma',
  'busen',
  'buset',
  'busk',
  'buske',
  'buss',
  'bussa',
  'busse',
  'bust',
  'busta',
  'butta',
  'butte',
  'bv',
  'bw',
  'bx',
  'by',
  'bydd',
  'bydde',
  'byde',
  'bydel',
  'bye',
  'byen',
  'byene',
  'byens',
  'byer',
  'byet',
  'byete',
  'bygda',
  'bygde',
  'bygds',
  'byge',
  'bygg',
  'bykse',
  'bylt',
  'byr',
  'byrd',
  'byrde',
  'byrg',
  'byrgt',
  'bysse',
  'byste',
  'byte',
  'bytes',
  'bytta',
  'bytte',
  'bytur',
  'bz',
  'c',
  'cabin',
  'campa',
  'campe',
  'cape',
  'carte',
  'case',
  'causa',
  'cb',
  'cc',
  'ccc',
  'cccc',
  'ccm',
  'cd',
  'cde',
  'ce',
  'cella',
  'celle',
  'cello',
  'cern',
  'cf',
  'cg',
  'ch',
  'chief',
  'chile',
  'chips',
  'chuck',
  'ci',
  'cia',
  'ciss',
  'city',
  'cj',
  'ck',
  'cl',
  'clou',
  'club',
  'cm',
  'cn',
  'co',
  'coat',
  'coca',
  'cola',
  'colt',
  'come',
  'corny',
  'cos',
  'cosi',
  'costa',
  'court',
  'cp',
  'cq',
  'cr',
  'crawl',
  'crazy',
  'credo',
  'crepe',
  'cs',
  'ct',
  'cu',
  'cuba',
  'cuban',
  'cup',
  'cv',
  'cw',
  'cx',
  'cy',
  'cz',
  'd',
  'dabbe',
  'dadle',
  'daffe',
  'dafne',
  'dagen',
  'dager',
  'dages',
  'dags',
  'dal',
  'daldu',
  'daler',
  'dall',
  'dalt',
  'dam',
  'dame',
  'damen',
  'damer',
  'damp',
  'dandy',
  'dann',
  'danna',
  'dansa',
  'danse',
  'dansk',
  'dask',
  'daske',
  'dass',
  'data',
  'dativ',
  'dato',
  'datt',
  'datum',
  'dau',
  'daud',
  'daude',
  'daue',
  'daut',
  'davit',
  'db',
  'dc',
  'dd',
  'ddd',
  'dddd',
  'ddt',
  'de',
  'dean',
  'debet',
  'decca',
  'decem',
  'def',
  'deg',
  'degga',
  'degge',
  'deig',
  'deild',
  'deis',
  'deise',
  'dekan',
  'dekar',
  'dekk',
  'dekke',
  'dekor',
  'del',
  'dele',
  'deler',
  'deles',
  'delet',
  'delta',
  'delte',
  'demma',
  'demme',
  'demon',
  'dempa',
  'dempe',
  'demre',
  'den',
  'denne',
  'depot',
  'der',
  'derav',
  'derby',
  'dere',
  'deres',
  'deri',
  'derom',
  'dert',
  'derte',
  'dess',
  'desto',
  'dets',
  'dett',
  'dette',
  'deus',
  'devel',
  'devon',
  'df',
  'dg',
  'dh',
  'di',
  'dia',
  'diar',
  'diare',
  'dias',
  'die',
  'dier',
  'diet',
  'diett',
  'digel',
  'diger',
  'digre',
  'dike',
  'dikta',
  'dill',
  'dilla',
  'dilta',
  'dilte',
  'dim',
  'dimma',
  'dimt',
  'diner',
  'dings',
  'dirk',
  'dirka',
  'dirra',
  'dis',
  'disen',
  'disig',
  'disk',
  'diske',
  'dissa',
  'disse',
  'dit',
  'ditt',
  'ditto',
  'diva',
  'divan',
  'dj',
  'djerv',
  'djup',
  'djupe',
  'dk',
  'dl',
  'dm',
  'dn',
  'do',
  'dobba',
  'dobbe',
  'dobla',
  'doble',
  'doen',
  'dog',
  'dogg',
  'dogge',
  'dogme',
  'dokk',
  'dokka',
  'dokke',
  'dolk',
  'dolka',
  'dolke',
  'dolp',
  'dolpa',
  'dom',
  'domen',
  'doms',
  'domus',
  'don',
  'donet',
  'donor',
  'dope',
  'doper',
  'dopet',
  'dor',
  'doran',
  'dore',
  'dorer',
  'doret',
  'dorg',
  'dorga',
  'dorge',
  'dorma',
  'dorme',
  'dorsk',
  'dory',
  'dosen',
  'dosis',
  'dott',
  'doven',
  'dovne',
  'dovre',
  'dp',
  'dq',
  'dr',
  'dra',
  'dradd',
  'draes',
  'drag',
  'drage',
  'drags',
  'drake',
  'drakk',
  'drakt',
  'dram',
  'drama',
  'drar',
  'dras',
  'dratt',
  'dregg',
  'drei',
  'dreie',
  'dreil',
  'dreis',
  'dreiv',
  'dreng',
  'drent',
  'drepe',
  'drept',
  'drett',
  'drift',
  'drikk',
  'driks',
  'drill',
  'drink',
  'drive',
  'drog',
  'droge',
  'drone',
  'drott',
  'drua',
  'druen',
  'dryg',
  'dryge',
  'drypp',
  'ds',
  'dt',
  'du',
  'dua',
  'dubba',
  'dubbe',
  'duble',
  'due',
  'duene',
  'duer',
  'duett',
  'dufte',
  'dugde',
  'duge',
  'duger',
  'dugg',
  'dugga',
  'dugge',
  'duk',
  'duka',
  'dukat',
  'duke',
  'duken',
  'duker',
  'duket',
  'dukka',
  'dukke',
  'duks',
  'dulgt',
  'dulla',
  'dulle',
  'dult',
  'dulta',
  'dulte',
  'dum',
  'dumme',
  'dummy',
  'dump',
  'dumpe',
  'dumt',
  'dun',
  'duna',
  'dunen',
  'dunet',
  'dunge',
  'dunk',
  'dunka',
  'dunke',
  'dunst',
  'duo',
  'dupp',
  'duppa',
  'duppe',
  'dur',
  'dure',
  'duren',
  'durer',
  'durte',
  'dus',
  'dusa',
  'duse',
  'duset',
  'dusin',
  'dusja',
  'dusje',
  'dusk',
  'dust',
  'dutte',
  'duva',
  'duvde',
  'duver',
  'duvet',
  'dv',
  'dvale',
  'dvele',
  'dverg',
  'dvs',
  'dw',
  'dx',
  'dy',
  'dybde',
  'dyd',
  'dydde',
  'dyden',
  'dydig',
  'dyet',
  'dygd',
  'dygda',
  'dykka',
  'dykke',
  'dylle',
  'dyna',
  'dynen',
  'dynga',
  'dynge',
  'dynke',
  'dynn',
  'dyp',
  'dype',
  'dypet',
  'dyppe',
  'dypt',
  'dyr',
  'dyra',
  'dyre',
  'dyret',
  'dyrka',
  'dyrke',
  'dyse',
  'dysen',
  'dyssa',
  'dysse',
  'dyst',
  'dytta',
  'dytte',
  'dz',
  'e',
  'eau',
  'eb',
  'ebbe',
  'ebben',
  'ebbet',
  'ec',
  'ed',
  'edb',
  'edda',
  'eddik',
  'edel',
  'eden',
  'edene',
  'eder',
  'edikt',
  'edle',
  'edru',
  'ee',
  'eec',
  'eee',
  'eeee',
  'ef',
  'efg',
  'efta',
  'eg',
  'egd',
  'egde',
  'egden',
  'egent',
  'eget',
  'egg',
  'egga',
  'egge',
  'eggen',
  'egget',
  'egla',
  'eglet',
  'egn',
  'egna',
  'egne',
  'egnen',
  'egner',
  'egnet',
  'ego',
  'egse',
  'egset',
  'egypt',
  'eh',
  'ei',
  'eide',
  'eidet',
  'eie',
  'eier',
  'eiere',
  'eies',
  'eiet',
  'eiga',
  'eige',
  'eigen',
  'eigna',
  'eigne',
  'eika',
  'eike',
  'eiken',
  'eiker',
  'eim',
  'eima',
  'eime',
  'eimer',
  'eimet',
  'eine',
  'einer',
  'eir',
  'eire',
  'eitel',
  'ej',
  'ek',
  'ekkel',
  'ekle',
  'ekorn',
  'ekra',
  'ekre',
  'eksem',
  'eksis',
  'eksos',
  'ekta',
  'ekte',
  'ektet',
  'el',
  'elda',
  'elden',
  'eldes',
  'eldet',
  'eldre',
  'eldst',
  'elegi',
  'elev',
  'elg',
  'elgen',
  'eling',
  'elite',
  'eller',
  'else',
  'elsk',
  'elska',
  'elske',
  'elta',
  'elte',
  'elter',
  'eltet',
  'elv',
  'elva',
  'elven',
  'elver',
  'em',
  'emmen',
  'emna',
  'emne',
  'emner',
  'emnet',
  'en',
  'enda',
  'enden',
  'ender',
  'endre',
  'endt',
  'endte',
  'ener',
  'eng',
  'engel',
  'engen',
  'enger',
  'enhet',
  'enig',
  'enige',
  'enkel',
  'enker',
  'enkle',
  'enkom',
  'enn',
  'enorm',
  'enrom',
  'ens',
  'ense',
  'enset',
  'ensom',
  'enste',
  'ental',
  'enten',
  'entes',
  'entra',
  'entre',
  'envis',
  'enzym',
  'eo',
  'ep',
  'episk',
  'eple',
  'epler',
  'eplet',
  'epoke',
  'eq',
  'er',
  'ergo',
  'ergra',
  'ergre',
  'erla',
  'erle',
  'erlen',
  'erler',
  'erme',
  'ermet',
  'ert',
  'erta',
  'erte',
  'erter',
  'ertet',
  'ertne',
  'erts',
  'es',
  'ese',
  'esel',
  'eser',
  'esing',
  'eska',
  'eske',
  'esken',
  'esket',
  'esla',
  'esle',
  'ess',
  'esse',
  'esset',
  'esso',
  'ester',
  'et',
  'etat',
  'eter',
  'etikk',
  'eting',
  'etiop',
  'etisk',
  'etla',
  'etle',
  'etlet',
  'etsa',
  'etse',
  'etset',
  'ett',
  'ettal',
  'etter',
  'etui',
  'etyde',
  'etyl',
  'eu',
  'ev',
  'eva',
  'eve',
  'evige',
  'evja',
  'evje',
  'evjen',
  'evna',
  'evne',
  'evnen',
  'evnet',
  'evt',
  'ew',
  'ex',
  'ey',
  'ez',
  'f',
  'fabel',
  'face',
  'facto',
  'fada',
  'fader',
  'fag',
  'faget',
  'fagre',
  'fair',
  'fait',
  'fakir',
  'fakka',
  'fakke',
  'faks',
  'fakta',
  'fal',
  'falby',
  'falda',
  'falde',
  'falk',
  'fall',
  'falle',
  'falma',
  'falme',
  'falne',
  'fals',
  'falsk',
  'falt',
  'famla',
  'famle',
  'famn',
  'famne',
  'fana',
  'fanen',
  'faner',
  'fang',
  'fans',
  'fant',
  'fanta',
  'fante',
  'far',
  'farao',
  'fare',
  'faren',
  'farer',
  'faret',
  'farga',
  'farge',
  'farin',
  'fars',
  'farse',
  'fart',
  'farta',
  'farte',
  'fase',
  'fasen',
  'faser',
  'faset',
  'fasit',
  'fasla',
  'fast',
  'fasta',
  'faste',
  'fate',
  'fatet',
  'fatla',
  'fatle',
  'fatna',
  'fatt',
  'fatta',
  'fatte',
  'fauna',
  'favne',
  'fb',
  'fc',
  'fd',
  'fe',
  'feber',
  'feen',
  'feet',
  'fegra',
  'fei',
  'feia',
  'feide',
  'feie',
  'feien',
  'feier',
  'feig',
  'feigd',
  'feil',
  'feila',
  'feile',
  'feira',
  'feire',
  'feit',
  'feita',
  'feite',
  'feitt',
  'fekta',
  'fekte',
  'fela',
  'felg',
  'fell',
  'felle',
  'felte',
  'felts',
  'fem',
  'femte',
  'femti',
  'fenad',
  'fenga',
  'fenge',
  'ferd',
  'ferda',
  'ferde',
  'ferga',
  'ferie',
  'ferja',
  'ferje',
  'ferm',
  'fersk',
  'fesla',
  'fest',
  'festa',
  'feste',
  'fet',
  'feta',
  'fete',
  'fetet',
  'fetla',
  'fett',
  'fevei',
  'ff',
  'fff',
  'ffff',
  'fg',
  'fgh',
  'fh',
  'fhv',
  'fi',
  'fiat',
  'fiber',
  'fiff',
  'fiffa',
  'fig',
  'figur',
  'fiji',
  'fik',
  'fiken',
  'fikk',
  'fiks',
  'fiksa',
  'fikse',
  'fil',
  'fila',
  'file',
  'filer',
  'filet',
  'filla',
  'fille',
  'film',
  'filma',
  'filme',
  'films',
  'filt',
  'fin',
  'fink',
  'finn',
  'finse',
  'finsk',
  'finta',
  'finte',
  'fiol',
  'fira',
  'fire',
  'firer',
  'firma',
  'first',
  'fis',
  'fisk',
  'fiska',
  'fiske',
  'fiss',
  'five',
  'fj',
  'fjasa',
  'fjase',
  'fjed',
  'fjel',
  'fjela',
  'fjelg',
  'fjell',
  'fjern',
  'fjes',
  'fjesk',
  'fjoll',
  'fjols',
  'fjor',
  'fjord',
  'fjott',
  'fk',
  'fl',
  'flabb',
  'flaga',
  'flage',
  'flagg',
  'flak',
  'flara',
  'flass',
  'flata',
  'flate',
  'flatt',
  'flau',
  'flaua',
  'flaug',
  'flaut',
  'flein',
  'fleip',
  'fleis',
  'flekk',
  'flekt',
  'fleng',
  'flens',
  'flere',
  'fles',
  'flesa',
  'flesk',
  'flest',
  'fli',
  'flik',
  'fline',
  'flink',
  'flint',
  'flipp',
  'flir',
  'flisa',
  'flise',
  'floa',
  'flod',
  'floen',
  'floka',
  'floke',
  'flokk',
  'flom',
  'flott',
  'flu',
  'flua',
  'flue',
  'fluen',
  'flukt',
  'flust',
  'fly',
  'flya',
  'flydd',
  'flyet',
  'flyge',
  'flyr',
  'flyte',
  'flytt',
  'fm',
  'fn',
  'fnatt',
  'fnl',
  'fnokk',
  'fnugg',
  'fnyst',
  'fo',
  'foaje',
  'fob',
  'foged',
  'fokk',
  'fokka',
  'fokus',
  'fola',
  'fold',
  'folda',
  'folde',
  'fole',
  'folen',
  'folet',
  'folie',
  'folio',
  'folk',
  'folka',
  'folke',
  'foll',
  'folla',
  'folle',
  'folna',
  'folne',
  'fomla',
  'fomle',
  'fond',
  'fonds',
  'fonn',
  'fonna',
  'for',
  'fora',
  'foran',
  'forat',
  'forbi',
  'forby',
  'force',
  'fordi',
  'fore',
  'foret',
  'fork',
  'form',
  'forme',
  'forn',
  'fornt',
  'forse',
  'forta',
  'forte',
  'forum',
  'forut',
  'fossa',
  'fosse',
  'fot',
  'fota',
  'fotet',
  'foto',
  'fots',
  'fp',
  'fq',
  'fr',
  'frabe',
  'frak',
  'frakk',
  'frakt',
  'fram',
  'franc',
  'frank',
  'frasa',
  'frase',
  'frata',
  'fraus',
  'fred',
  'freda',
  'frede',
  'frekk',
  'frekt',
  'frem',
  'fremt',
  'fres',
  'frese',
  'fri',
  'frie',
  'frier',
  'frise',
  'frisk',
  'frita',
  'fritt',
  'frk',
  'from',
  'fromt',
  'front',
  'frosk',
  'frost',
  'fru',
  'frue',
  'fruen',
  'frukt',
  'fryd',
  'fryda',
  'fryde',
  'frykt',
  'frys',
  'fryse',
  'fs',
  'ft',
  'fu',
  'fuge',
  'fugl',
  'fukta',
  'fukte',
  'ful',
  'fulgt',
  'full',
  'fullt',
  'fult',
  'funke',
  'funn',
  'funne',
  'fure',
  'furia',
  'furie',
  'furta',
  'furte',
  'furu',
  'furua',
  'fus',
  'fusa',
  'fuse',
  'fusel',
  'fuset',
  'fusk',
  'fuska',
  'fuske',
  'fust',
  'fut',
  'futen',
  'fv',
  'fw',
  'fx',
  'fy',
  'fyke',
  'fyker',
  'fylde',
  'fylka',
  'fylke',
  'fylla',
  'fylle',
  'fynd',
  'fyr',
  'fyre',
  'fyren',
  'fyret',
  'fyrig',
  'fyrst',
  'fyrte',
  'fysak',
  'fyse',
  'fyset',
  'fz',
  'g',
  'gadd',
  'gafla',
  'gafle',
  'gagn',
  'gagna',
  'gagne',
  'gal',
  'gale',
  'galei',
  'galge',
  'gall',
  'galla',
  'galle',
  'galt',
  'galte',
  'game',
  'gamet',
  'gamla',
  'gamle',
  'gamme',
  'gana',
  'ganda',
  'gande',
  'gane',
  'ganen',
  'gang',
  'gange',
  'gangs',
  'gap',
  'gapa',
  'gape',
  'gapen',
  'gapet',
  'gapte',
  'gard',
  'garde',
  'gards',
  'garn',
  'garp',
  'garva',
  'garve',
  'gas',
  'gasen',
  'gasje',
  'gasse',
  'gast',
  'gasta',
  'gata',
  'gate',
  'gaten',
  'gater',
  'gatt',
  'gauda',
  'gauk',
  'gaul',
  'gaula',
  'gaule',
  'gaupa',
  'gaupe',
  'gauv',
  'gav',
  'gava',
  'gaven',
  'gaver',
  'gavl',
  'gb',
  'gc',
  'gd',
  'ge',
  'gear',
  'gebyr',
  'geil',
  'geila',
  'geilo',
  'gein',
  'geina',
  'geipa',
  'geipe',
  'geit',
  'geita',
  'geite',
  'gele',
  'gemal',
  'gemen',
  'gemse',
  'gen',
  'gene',
  'genen',
  'genre',
  'genus',
  'gest',
  'gf',
  'gg',
  'ggg',
  'gggg',
  'gh',
  'ghana',
  'ghi',
  'gi',
  'gid',
  'gidde',
  'gies',
  'gift',
  'gifta',
  'gifte',
  'giga',
  'gikk',
  'gikt',
  'gikta',
  'gild',
  'gildt',
  'gille',
  'gipsa',
  'gipse',
  'gir',
  'gira',
  'gire',
  'giren',
  'giret',
  'girl',
  'giro',
  'gisle',
  'gisne',
  'gisp',
  'giss',
  'gissa',
  'gitar',
  'gitt',
  'giver',
  'gj',
  'gjel',
  'gjeld',
  'gjem',
  'gjemt',
  'gjeng',
  'gjerd',
  'gjesp',
  'gjess',
  'gjest',
  'gjete',
  'gjeti',
  'gjett',
  'gjev',
  'gjort',
  'gk',
  'gl',
  'gla',
  'glad',
  'glade',
  'glam',
  'glan',
  'glana',
  'glane',
  'glans',
  'glapp',
  'glas',
  'glass',
  'glatt',
  'gle',
  'gled',
  'gleda',
  'glede',
  'glefs',
  'glei',
  'glem',
  'glepp',
  'gli',
  'glide',
  'glimt',
  'glip',
  'glipp',
  'glir',
  'glire',
  'glis',
  'glise',
  'glo',
  'gloa',
  'glor',
  'glose',
  'glova',
  'glove',
  'glugg',
  'glunt',
  'glup',
  'glupt',
  'gm',
  'gn',
  'gnag',
  'gnagd',
  'gnage',
  'gnall',
  'gnatt',
  'gned',
  'gnei',
  'gneis',
  'gnelt',
  'gni',
  'gnidd',
  'gnier',
  'gnirk',
  'gnist',
  'gnu',
  'gnuen',
  'gnura',
  'gnure',
  'gny',
  'gnyet',
  'go',
  'goal',
  'god',
  'gode',
  'goden',
  'goder',
  'godet',
  'godt',
  'godta',
  'godte',
  'gol',
  'gold',
  'goldt',
  'golf',
  'golva',
  'gomla',
  'gomle',
  'goro',
  'goroa',
  'goter',
  'gott',
  'gouda',
  'gov',
  'gova',
  'gove',
  'govet',
  'gp',
  'gq',
  'gr',
  'gra',
  'grad',
  'gran',
  'grana',
  'grand',
  'grann',
  'grant',
  'gras',
  'graut',
  'grav',
  'gravd',
  'grave',
  'gre',
  'grei',
  'greia',
  'greid',
  'greie',
  'grein',
  'greip',
  'greit',
  'grell',
  'gren',
  'grena',
  'grend',
  'grene',
  'grep',
  'grepa',
  'gresk',
  'gress',
  'gret',
  'grev',
  'greve',
  'gribb',
  'grid',
  'grieg',
  'grill',
  'grim',
  'grima',
  'grime',
  'grin',
  'grine',
  'grint',
  'gripe',
  'gris',
  'grise',
  'grisk',
  'gro',
  'groa',
  'grodd',
  'groe',
  'grogg',
  'grom',
  'gromt',
  'grop',
  'gropa',
  'gror',
  'gros',
  'gross',
  'grov',
  'grove',
  'gru',
  'gruen',
  'gruk',
  'grum',
  'grums',
  'grunn',
  'grunt',
  'grus',
  'grut',
  'gryn',
  'gryte',
  'gs',
  'gt',
  'gu',
  'guano',
  'gubbe',
  'gud',
  'gude',
  'guden',
  'guds',
  'gufne',
  'gufsa',
  'gufse',
  'guide',
  'gul',
  'gular',
  'gule',
  'gulen',
  'gulf',
  'gull',
  'gulna',
  'gulp',
  'gulpa',
  'gult',
  'gulv',
  'gummi',
  'gunst',
  'gust',
  'gusta',
  'guste',
  'gutt',
  'gutta',
  'gutu',
  'gv',
  'gw',
  'gx',
  'gy',
  'gyger',
  'gygra',
  'gylle',
  'gylt',
  'gylta',
  'gylte',
  'gynga',
  'gynge',
  'gynt',
  'gyser',
  'gyste',
  'gyte',
  'gytja',
  'gytje',
  'gytte',
  'gyvd',
  'gyve',
  'gyvet',
  'gz',
  'h',
  'haag',
  'habil',
  'hadde',
  'hag',
  'hage',
  'hagen',
  'hagl',
  'hagla',
  'hagle',
  'hai',
  'haien',
  'haik',
  'haika',
  'haiti',
  'haka',
  'hake',
  'hakel',
  'haken',
  'hakk',
  'hakka',
  'hakke',
  'hal',
  'hala',
  'hale',
  'halen',
  'hall',
  'halla',
  'halle',
  'hallo',
  'halm',
  'hals',
  'halt',
  'halta',
  'halte',
  'halv',
  'halve',
  'halvt',
  'hambo',
  'hamla',
  'hamle',
  'hamna',
  'hamne',
  'hamp',
  'hamra',
  'hamre',
  'hams',
  'han',
  'hand',
  'hane',
  'hanen',
  'hang',
  'hank',
  'hanka',
  'hann',
  'hanoi',
  'hans',
  'har',
  'hard',
  'harde',
  'hardt',
  'hare',
  'harem',
  'hark',
  'harka',
  'harke',
  'harm',
  'harpa',
  'harr',
  'harsk',
  'harv',
  'harva',
  'harve',
  'has',
  'hase',
  'hasj',
  'hast',
  'hasta',
  'haste',
  'hata',
  'hate',
  'hater',
  'hatsk',
  'hatt',
  'hau',
  'haud',
  'hauge',
  'hauk',
  'hauka',
  'hauke',
  'haus',
  'haute',
  'hav',
  'havet',
  'havn',
  'havna',
  'havne',
  'havre',
  'havs',
  'hb',
  'hc',
  'hd',
  'he',
  'heade',
  'heder',
  'hedra',
  'hedre',
  'heft',
  'hefta',
  'hefte',
  'hegda',
  'hegde',
  'hegg',
  'hegn',
  'hegna',
  'hegne',
  'hei',
  'heia',
  'heie',
  'heiet',
  'heile',
  'heilo',
  'heim',
  'heime',
  'heis',
  'heise',
  'heist',
  'heit',
  'heitt',
  'heiv',
  'hekka',
  'hekla',
  'hekle',
  'heks',
  'heksa',
  'hekse',
  'hekt',
  'hekta',
  'hekto',
  'hel',
  'hela',
  'hele',
  'heler',
  'helet',
  'helg',
  'helga',
  'helge',
  'hell',
  'hella',
  'helle',
  'helse',
  'helst',
  'helte',
  'helvt',
  'hemma',
  'hemme',
  'hempa',
  'hempe',
  'hen',
  'hende',
  'heng',
  'henge',
  'hengi',
  'hengt',
  'henne',
  'herav',
  'herd',
  'herda',
  'herje',
  'herme',
  'hero',
  'herse',
  'hest',
  'het',
  'hete',
  'heten',
  'heter',
  'hetne',
  'hets',
  'hett',
  'hette',
  'hev',
  'hevd',
  'hevde',
  'heve',
  'hevet',
  'hevle',
  'hevn',
  'hf',
  'hg',
  'hh',
  'hhh',
  'hhhh',
  'hi',
  'hiet',
  'hige',
  'high',
  'hij',
  'hikke',
  'hikst',
  'hilde',
  'hill',
  'hilse',
  'hilst',
  'himle',
  'hind',
  'hindu',
  'hine',
  'hinke',
  'hinne',
  'hint',
  'hipne',
  'hipp',
  'hird',
  'hirda',
  'hire',
  'hisse',
  'hist',
  'hita',
  'hitre',
  'hitt',
  'hitte',
  'hiv',
  'hivd',
  'hive',
  'hiven',
  'hj',
  'hjalp',
  'hjalt',
  'hjel',
  'hjell',
  'hjelm',
  'hjelp',
  'hjem',
  'hjort',
  'hjul',
  'hk',
  'hl',
  'hm',
  'hn',
  'ho',
  'hobby',
  'hode',
  'hoder',
  'hodet',
  'hoff',
  'hoft',
  'hofta',
  'hofte',
  'hogd',
  'hogde',
  'hogg',
  'hogge',
  'hogst',
  'hoi',
  'hoie',
  'hokk',
  'hol',
  'hola',
  'hold',
  'holde',
  'holdt',
  'hole',
  'holet',
  'holk',
  'holt',
  'hong',
  'hook',
  'hope',
  'hoppe',
  'hor',
  'horde',
  'hore',
  'horg',
  'hork',
  'horka',
  'horn',
  'hors',
  'hos',
  'hose',
  'hoste',
  'hote',
  'hov',
  'hoved',
  'hoven',
  'hovet',
  'hovne',
  'hp',
  'hq',
  'hr',
  'hs',
  'ht',
  'hu',
  'hubro',
  'hud',
  'huff',
  'hufs',
  'hufse',
  'hug',
  'hugd',
  'hugde',
  'hugen',
  'hugg',
  'hugge',
  'hugs',
  'hugse',
  'hugst',
  'hugta',
  'hui',
  'huie',
  'huk',
  'huke',
  'hukre',
  'hul',
  'hula',
  'hule',
  'hulen',
  'hulk',
  'hull',
  'human',
  'humle',
  'humor',
  'humra',
  'humre',
  'humus',
  'hun',
  'hund',
  'huner',
  'hunn',
  'hurpe',
  'hurra',
  'hurv',
  'hus',
  'husa',
  'huse',
  'huser',
  'huset',
  'husk',
  'huske',
  'husky',
  'hute',
  'hutle',
  'hutre',
  'huve',
  'hv',
  'hva',
  'hval',
  'hvass',
  'hvelv',
  'hvem',
  'hven',
  'hver',
  'hvert',
  'hvese',
  'hvete',
  'hvil',
  'hvile',
  'hvin',
  'hvine',
  'hvint',
  'hvis',
  'hvite',
  'hvitt',
  'hvor',
  'hw',
  'hx',
  'hy',
  'hybel',
  'hydro',
  'hyene',
  'hyfse',
  'hygge',
  'hyl',
  'hylet',
  'hylla',
  'hylle',
  'hylse',
  'hymne',
  'hypp',
  'hyppe',
  'hyren',
  'hyss',
  'hytte',
  'hz',
  'i',
  'iata',
  'ib',
  'ibis',
  'ibm',
  'ibsen',
  'ic',
  'icing',
  'id',
  'idag',
  'ide',
  'ideal',
  'ideen',
  'ideer',
  'idet',
  'idig',
  'idiom',
  'idiot',
  'ie',
  'if',
  'ifall',
  'ifra',
  'ig',
  'igle',
  'iglo',
  'ih',
  'ihuga',
  'ii',
  'iii',
  'iiii',
  'ij',
  'ijk',
  'ik',
  'ikke',
  'ikon',
  'ikte',
  'il',
  'ilagt',
  'ild',
  'ilde',
  'ilden',
  'ilder',
  'ildet',
  'ile',
  'iling',
  'ilje',
  'ilk',
  'ilo',
  'ilsk',
  'ilske',
  'im',
  'immun',
  'imot',
  'in',
  'inder',
  'india',
  'indre',
  'infam',
  'info',
  'ing',
  'inga',
  'inge',
  'ingen',
  'Inger',
  'inkl',
  'inn',
  'inna',
  'innad',
  'innbo',
  'innbu',
  'innby',
  'inne',
  'innen',
  'innom',
  'innse',
  'innta',
  'input',
  'inst',
  'inter',
  'intet',
  'intim',
  'io',
  'ion',
  'ionen',
  'ionet',
  'ip',
  'iq',
  'ir',
  'ira',
  'irak',
  'iran',
  'ire',
  'iren',
  'irish',
  'ironi',
  'irr',
  'irra',
  'irre',
  'irret',
  'irsk',
  'irske',
  'is',
  'isbre',
  'ise',
  'isen',
  'isfri',
  'ising',
  'isne',
  'isop',
  'issen',
  'ister',
  'it',
  'itler',
  'iu',
  'iv',
  'ivare',
  'iver',
  'iverk',
  'ivre',
  'ivrer',
  'ivrig',
  'iw',
  'ix',
  'iy',
  'iz',
  'j',
  'jabb',
  'jabbe',
  'jaet',
  'jafs',
  'jafse',
  'jag',
  'jage',
  'jager',
  'jages',
  'jaget',
  'jak',
  'jakk',
  'jakke',
  'jakt',
  'jakta',
  'jakte',
  'jam',
  'jambe',
  'jamjf',
  'jamn',
  'jamne',
  'jamra',
  'jams',
  'jamt',
  'jamte',
  'jan',
  'japan',
  'jar',
  'jare',
  'jaren',
  'jark',
  'jarl',
  'jase',
  'jask',
  'jaske',
  'jass',
  'jatte',
  'jazz',
  'jb',
  'jc',
  'jd',
  'je',
  'jeans',
  'jeep',
  'jeg',
  'jekk',
  'jekke',
  'jekt',
  'jekta',
  'jenta',
  'jente',
  'jerpe',
  'jerv',
  'jest',
  'jesu',
  'jesus',
  'jet',
  'jeten',
  'jevn',
  'jevnt',
  'jf',
  'jg',
  'jh',
  'ji',
  'jibb',
  'jibbe',
  'jigg',
  'jj',
  'jjj',
  'jjjj',
  'jk',
  'jkl',
  'jl',
  'jm',
  'jn',
  'jo',
  'jobb',
  'jobbe',
  'jod',
  'joda',
  'jodle',
  'jogga',
  'joik',
  'jol',
  'jolle',
  'joner',
  'jord',
  'jorda',
  'jorde',
  'jorte',
  'jotun',
  'joule',
  'jour',
  'jp',
  'jq',
  'jr',
  'js',
  'jt',
  'ju',
  'juan',
  'jubel',
  'jugl',
  'juks',
  'jukse',
  'jul',
  'jula',
  'jule',
  'julen',
  'juli',
  'jumbo',
  'jumpa',
  'jumpe',
  'juni',
  'junta',
  'jur',
  'juret',
  'juris',
  'jury',
  'jus',
  'juss',
  'just',
  'jute',
  'jutul',
  'juv',
  'juvel',
  'juvet',
  'jv',
  'jw',
  'jx',
  'jy',
  'jyde',
  'jyden',
  'jz',
  'k',
  'kabal',
  'kabel',
  'kabin',
  'kafe',
  'kaffe',
  'kagg',
  'kagge',
  'kai',
  'kaia',
  'kaien',
  'kairo',
  'kaka',
  'kakao',
  'kake',
  'kaker',
  'kaki',
  'kakke',
  'kakle',
  'kakse',
  'kala',
  'kalas',
  'kalde',
  'kaldt',
  'kali',
  'kalif',
  'kalk',
  'kalke',
  'kall',
  'kalle',
  'kalt',
  'kalv',
  'kalve',
  'kam',
  'kamel',
  'kamin',
  'kamp',
  'kanal',
  'kanap',
  'kane',
  'kanen',
  'kanin',
  'kanne',
  'kano',
  'kanon',
  'kant',
  'kante',
  'kaos',
  'kapp',
  'kappe',
  'kar',
  'karat',
  'karde',
  'kare',
  'karen',
  'karer',
  'karet',
  'karm',
  'karpe',
  'karri',
  'karse',
  'karsk',
  'kart',
  'karve',
  'kase',
  'kassa',
  'kasse',
  'kasta',
  'kaste',
  'kasus',
  'katet',
  'katt',
  'kaut',
  'kav',
  'kave',
  'kavl',
  'kavle',
  'kb',
  'kc',
  'kd',
  'ke',
  'kefir',
  'keip',
  'keipe',
  'keit',
  'keiva',
  'keive',
  'kenya',
  'kf',
  'kfr',
  'kg',
  'kh',
  'khan',
  'ki',
  'kike',
  'kikk',
  'kikke',
  'kilde',
  'kile',
  'kilen',
  'kilne',
  'kilo',
  'kilt',
  'kim',
  'kime',
  'kimen',
  'kimet',
  'kimse',
  'kimt',
  'kimte',
  'kina',
  'king',
  'kinin',
  'kink',
  'kinn',
  'kinne',
  'kino',
  'kiosk',
  'kipen',
  'kipne',
  'kippe',
  'kipt',
  'kipte',
  'kirke',
  'kiste',
  'kitle',
  'kitte',
  'kiv',
  'kives',
  'kivet',
  'kj',
  'kjake',
  'kjapp',
  'kjapt',
  'kjav',
  'kjave',
  'kje',
  'kjed',
  'kjede',
  'kjee',
  'kjeet',
  'kjei',
  'kjekk',
  'kjekl',
  'kjeks',
  'kjekt',
  'kjel',
  'kjele',
  'kjem',
  'kjemi',
  'kjeng',
  'kjens',
  'kjent',
  'kjepp',
  'kjerr',
  'kjerv',
  'kjeve',
  'kjole',
  'kk',
  'kkk',
  'kkkk',
  'kl',
  'kladd',
  'klaff',
  'klaga',
  'klage',
  'klake',
  'klam',
  'klan',
  'klang',
  'klapp',
  'klaps',
  'klar',
  'klart',
  'klase',
  'klask',
  'klatt',
  'klauv',
  'klave',
  'kle',
  'kledd',
  'klede',
  'klegg',
  'kleie',
  'klein',
  'kleis',
  'kleiv',
  'klekk',
  'klem',
  'klepp',
  'kler',
  'klerk',
  'kleve',
  'kliet',
  'klikk',
  'klima',
  'klin',
  'kline',
  'klipp',
  'klipt',
  'klirr',
  'klisj',
  'klivd',
  'klive',
  'klm',
  'klo',
  'kloa',
  'klode',
  'klok',
  'kloke',
  'klokt',
  'klona',
  'klone',
  'klor',
  'klore',
  'klort',
  'klubb',
  'klukk',
  'klump',
  'klunk',
  'kluss',
  'klynk',
  'klype',
  'klypt',
  'klyse',
  'klyve',
  'km',
  'kn',
  'kna',
  'knabb',
  'knagg',
  'knaka',
  'knake',
  'knakk',
  'knall',
  'knapp',
  'knapt',
  'knark',
  'knarr',
  'knas',
  'knase',
  'knask',
  'knatt',
  'knaus',
  'kne',
  'kneik',
  'kneip',
  'knekk',
  'knele',
  'knep',
  'knept',
  'knert',
  'knes',
  'knett',
  'kniks',
  'knip',
  'knipe',
  'knirk',
  'knis',
  'knise',
  'knok',
  'knoke',
  'knoll',
  'knop',
  'knopp',
  'knot',
  'knote',
  'knue',
  'knuge',
  'knupp',
  'knuse',
  'knusk',
  'knust',
  'knute',
  'kny',
  'knyst',
  'knyte',
  'ko',
  'kobbe',
  'kobe',
  'kobla',
  'koble',
  'kode',
  'koden',
  'koder',
  'kogg',
  'kogge',
  'kogre',
  'koie',
  'kok',
  'koke',
  'koken',
  'koker',
  'kokes',
  'kokk',
  'kokke',
  'koks',
  'kokte',
  'kol',
  'kola',
  'kolbe',
  'kold',
  'koldt',
  'kole',
  'kolet',
  'kolje',
  'koll',
  'kolle',
  'kolli',
  'kolon',
  'kolv',
  'kom',
  'komet',
  'komit',
  'komma',
  'komme',
  'komse',
  'kon',
  'konas',
  'konen',
  'kones',
  'kong',
  'konge',
  'konj',
  'kont',
  'konti',
  'konus',
  'kope',
  'kopi',
  'kor',
  'kora',
  'koral',
  'korde',
  'korea',
  'koret',
  'korg',
  'korga',
  'kork',
  'korka',
  'korke',
  'korn',
  'korps',
  'kors',
  'kort',
  'korte',
  'kos',
  'kose',
  'koste',
  'kote',
  'kott',
  'kove',
  'koven',
  'kovne',
  'kp',
  'kq',
  'kr',
  'kraft',
  'krage',
  'krak',
  'krakk',
  'kram',
  'kran',
  'krana',
  'krane',
  'krapp',
  'krapt',
  'krase',
  'krasj',
  'krass',
  'krast',
  'kratt',
  'krave',
  'kre',
  'kreet',
  'kreft',
  'krek',
  'kreke',
  'krem',
  'kreml',
  'kremt',
  'krepp',
  'kreps',
  'krevd',
  'kreve',
  'kri',
  'krig',
  'krik',
  'krill',
  'krim',
  'krimi',
  'kring',
  'krins',
  'krise',
  'kritt',
  'kroa',
  'kroen',
  'krok',
  'kroke',
  'kroki',
  'krom',
  'krone',
  'kropp',
  'krot',
  'krull',
  'krum',
  'krus',
  'kruse',
  'krutt',
  'kry',
  'kryp',
  'krype',
  'krypt',
  'kryss',
  'ks',
  'kst',
  'kt',
  'ku',
  'kua',
  'kuban',
  'kubb',
  'kubbe',
  'kube',
  'kue',
  'kuene',
  'kuer',
  'kufte',
  'kujon',
  'kul',
  'kulda',
  'kulde',
  'kule',
  'kulen',
  'kuler',
  'kuli',
  'kull',
  'kulle',
  'kulp',
  'kulse',
  'kult',
  'kulte',
  'kum',
  'kumla',
  'kumle',
  'kun',
  'kunde',
  'kunne',
  'kunst',
  'kupe',
  'kur',
  'kuren',
  'kurer',
  'kurle',
  'kurre',
  'kurv',
  'kurva',
  'kurve',
  'kusk',
  'kusma',
  'kut',
  'kute',
  'kuten',
  'kutt',
  'kutta',
  'kutte',
  'kv',
  'kvad',
  'kvae',
  'kvaen',
  'kvakk',
  'kval',
  'kvalm',
  'kvalt',
  'kvam',
  'kvann',
  'kvant',
  'kvart',
  'kvase',
  'kvass',
  'kvast',
  'kve',
  'kvea',
  'kvede',
  'kvefs',
  'kveg',
  'kveil',
  'kvein',
  'kveis',
  'kveke',
  'kvekk',
  'kvekt',
  'kveld',
  'kvelt',
  'kvelv',
  'kven',
  'kverk',
  'kvern',
  'kverv',
  'kvese',
  'kvi',
  'kvier',
  'kvige',
  'kvikk',
  'kvikt',
  'kvil',
  'kvila',
  'kvile',
  'kvin',
  'kvine',
  'kvint',
  'kvise',
  'kvist',
  'kvit',
  'kvite',
  'kvitt',
  'kvm',
  'kvote',
  'kw',
  'kwh',
  'kx',
  'ky',
  'kyle',
  'kylet',
  'kylte',
  'kyr',
  'kyrne',
  'kyrre',
  'kysk',
  'kyske',
  'kyskt',
  'kyss',
  'kyssa',
  'kysse',
  'kyst',
  'kyt',
  'kyte',
  'kytet',
  'kytt',
  'kytte',
  'kz',
  'l',
  'labb',
  'laber',
  'labil',
  'lad',
  'ladd',
  'lader',
  'ladet',
  'lady',
  'laft',
  'lag',
  'laga',
  'lagd',
  'lage',
  'lager',
  'lages',
  'laget',
  'lagge',
  'lagom',
  'lagre',
  'lags',
  'lagt',
  'lakei',
  'laken',
  'lakk',
  'lakke',
  'lam',
  'lama',
  'lamma',
  'lamme',
  'lampa',
  'lampe',
  'lamt',
  'land',
  'landa',
  'lande',
  'lang',
  'lange',
  'langs',
  'langt',
  'lanka',
  'lanse',
  'laos',
  'laot',
  'lapis',
  'lapp',
  'lappe',
  'laps',
  'lar',
  'largo',
  'larm',
  'larme',
  'Lars',
  'las',
  'laser',
  'lass',
  'lasso',
  'last',
  'lasta',
  'laste',
  'lasur',
  'lat',
  'late',
  'later',
  'latin',
  'latt',
  'laud',
  'laug',
  'lauge',
  'laup',
  'laus',
  'laust',
  'lauv',
  'lav',
  'lave',
  'lavt',
  'lb',
  'lc',
  'ld',
  'le',
  'led',
  'ledd',
  'leden',
  'leder',
  'ledes',
  'ledig',
  'lee',
  'lefle',
  'lefse',
  'leg',
  'legat',
  'legd',
  'legda',
  'legde',
  'leger',
  'leget',
  'legg',
  'legge',
  'legio',
  'legt',
  'lei',
  'leia',
  'leid',
  'leide',
  'leie',
  'leien',
  'leier',
  'leies',
  'leiet',
  'leik',
  'leika',
  'leike',
  'leikt',
  'leir',
  'leire',
  'leist',
  'leite',
  'leiv',
  'lek',
  'leke',
  'leken',
  'leker',
  'lekk',
  'lekne',
  'lekre',
  'leksa',
  'lekse',
  'lekt',
  'lekta',
  'lekte',
  'lell',
  'lem',
  'lemen',
  'lempe',
  'lemus',
  'len',
  'lena',
  'lend',
  'lende',
  'lene',
  'lenet',
  'lengd',
  'lenge',
  'lengt',
  'lenin',
  'lenk',
  'lenke',
  'lense',
  'lepje',
  'leppa',
  'leppe',
  'lepra',
  'ler',
  'lerka',
  'lerke',
  'les',
  'lese',
  'leser',
  'leses',
  'leset',
  'lesja',
  'leske',
  'lespe',
  'less',
  'lesse',
  'lesst',
  'lest',
  'leste',
  'let',
  'lete',
  'leter',
  'letne',
  'leug',
  'lev',
  'levd',
  'levde',
  'lever',
  'levet',
  'levne',
  'levra',
  'lf',
  'lg',
  'lh',
  'li',
  'lia',
  'lid',
  'lidd',
  'lide',
  'lidt',
  'liene',
  'lier',
  'liga',
  'ligge',
  'light',
  'lign',
  'ligne',
  'lik',
  'like',
  'liken',
  'liker',
  'likke',
  'likn',
  'likne',
  'likt',
  'likte',
  'lilja',
  'lilje',
  'lilla',
  'lille',
  'lim',
  'lima',
  'lime',
  'limen',
  'limet',
  'limit',
  'limt',
  'limte',
  'lin',
  'lind',
  'linda',
  'line',
  'lines',
  'linet',
  'linja',
  'linje',
  'link',
  'linn',
  'linne',
  'linse',
  'lint',
  'lira',
  'lire',
  'liret',
  'lirka',
  'lirke',
  'lis',
  'lisa',
  'lisse',
  'list',
  'lista',
  'liste',
  'lit',
  'lita',
  'lite',
  'liv',
  'livat',
  'livd',
  'livda',
  'live',
  'livet',
  'livne',
  'livs',
  'lj',
  'ljome',
  'lk',
  'll',
  'lll',
  'llll',
  'lloyd',
  'lm',
  'lmn',
  'ln',
  'lo',
  'loa',
  'lobbe',
  'lobby',
  'lodd',
  'lodda',
  'lodde',
  'lodne',
  'loen',
  'loff',
  'loffe',
  'loft',
  'loge',
  'logga',
  'logn',
  'lognt',
  'logre',
  'lojal',
  'lokal',
  'lokk',
  'lokke',
  'lom',
  'lomme',
  'lomvi',
  'lon',
  'lona',
  'lonen',
  'loppa',
  'loppe',
  'lord',
  'lort',
  'los',
  'lose',
  'losen',
  'losje',
  'losji',
  'losse',
  'lot',
  'lott',
  'lotus',
  'lov',
  'lova',
  'love',
  'loven',
  'lover',
  'lovet',
  'lovt',
  'lovte',
  'lp',
  'lq',
  'lr',
  'ls',
  'lt',
  'ltd',
  'lu',
  'lubne',
  'ludra',
  'lue',
  'luer',
  'luffe',
  'luft',
  'lufta',
  'lugar',
  'lugg',
  'lugge',
  'lugom',
  'luka',
  'luke',
  'luken',
  'luker',
  'lukka',
  'lukt',
  'lukta',
  'lukte',
  'lulle',
  'lumre',
  'lumsk',
  'lun',
  'lund',
  'lunde',
  'lune',
  'lunga',
  'lunge',
  'lunka',
  'lunke',
  'lunne',
  'lunt',
  'lunte',
  'lupe',
  'lure',
  'luren',
  'lurer',
  'lurk',
  'lurt',
  'lurte',
  'lurv',
  'lurve',
  'lus',
  'lusa',
  'luse',
  'lusen',
  'luset',
  'luske',
  'lusne',
  'lut',
  'luta',
  'lute',
  'luten',
  'lutet',
  'lutre',
  'lutt',
  'luve',
  'lv',
  'lw',
  'lx',
  'ly',
  'lybsk',
  'lyce',
  'lyd',
  'lydd',
  'lydde',
  'lyde',
  'lyden',
  'lyder',
  'lydig',
  'lydt',
  'lyet',
  'lykka',
  'lykt',
  'lykta',
  'lymfe',
  'lyn',
  'lyne',
  'lynet',
  'lyng',
  'lynne',
  'lynol',
  'lyr',
  'lyre',
  'lyren',
  'lys',
  'lyse',
  'lyses',
  'lyset',
  'lysne',
  'lyst',
  'lysta',
  'lyste',
  'lyte',
  'lytt',
  'lyve',
  'lz',
  'm',
  'madam',
  'made',
  'mage',
  'magen',
  'magi',
  'magma',
  'mai',
  'maie',
  'maije',
  'mais',
  'major',
  'mak',
  'make',
  'maken',
  'makk',
  'makko',
  'makt',
  'makta',
  'makte',
  'mal',
  'male',
  'maler',
  'males',
  'malet',
  'mali',
  'malm',
  'malt',
  'malta',
  'malte',
  'mamma',
  'man',
  'mane',
  'manen',
  'maner',
  'manet',
  'mang',
  'mange',
  'mangt',
  'mani',
  'manke',
  'manko',
  'mann',
  'manna',
  'manne',
  'manns',
  'mante',
  'manus',
  'mao',
  'mappe',
  'mare',
  'marg',
  'mark',
  'marka',
  'marki',
  'mars',
  'marsj',
  'mas',
  'mase',
  'maset',
  'masi',
  'mask',
  'maske',
  'mast',
  'mat',
  'match',
  'mate',
  'maten',
  'matt',
  'matte',
  'maule',
  'maur',
  'mave',
  'maven',
  'mayen',
  'mb',
  'mc',
  'md',
  'me',
  'med',
  'medet',
  'medgi',
  'media',
  'meg',
  'meget',
  'megga',
  'megge',
  'megle',
  'mehe',
  'mei',
  'meie',
  'meien',
  'meier',
  'mein',
  'meine',
  'meins',
  'meint',
  'meis',
  'meisa',
  'meite',
  'mek',
  'mekla',
  'mekle',
  'mekre',
  'mel',
  'melbu',
  'melde',
  'meldt',
  'mele',
  'melen',
  'melet',
  'melk',
  'melka',
  'melke',
  'melte',
  'men',
  'mene',
  'mened',
  'mener',
  'menes',
  'menet',
  'menga',
  'mengd',
  'menge',
  'menig',
  'menn',
  'menns',
  'mens',
  'ment',
  'mente',
  'meny',
  'mer',
  'mere',
  'merg',
  'merk',
  'merke',
  'merr',
  'merra',
  'mers',
  'mesan',
  'mesen',
  'meske',
  'messe',
  'mest',
  'meter',
  'metie',
  'metra',
  'mett',
  'metta',
  'metyl',
  'mf',
  'mg',
  'mh',
  'mi',
  'miami',
  'midje',
  'midla',
  'midle',
  'midt',
  'midte',
  'miksa',
  'mikse',
  'mil',
  'mila',
  'milde',
  'mildt',
  'mile',
  'milen',
  'miles',
  'mill',
  'mils',
  'milt',
  'mime',
  'mimen',
  'mimre',
  'min',
  'mina',
  'minen',
  'miner',
  'mink',
  'minka',
  'minke',
  'minne',
  'mins',
  'mint',
  'minte',
  'minus',
  'miss',
  'misse',
  'miste',
  'mit',
  'mitt',
  'mitti',
  'mj',
  'mjuk',
  'mjuke',
  'mjukt',
  'mk',
  'ml',
  'mm',
  'mmm',
  'mmmm',
  'mn',
  'mnd',
  'mno',
  'mo',
  'mobb',
  'mobil',
  'mod',
  'modem',
  'moden',
  'modig',
  'modul',
  'modus',
  'moe',
  'moen',
  'mogop',
  'mol',
  'molar',
  'molbo',
  'mold',
  'molde',
  'molo',
  'molok',
  'molte',
  'momp',
  'moms',
  'mon',
  'monn',
  'monne',
  'moped',
  'mora',
  'mord',
  'more',
  'moren',
  'moret',
  'moria',
  'moro',
  'moroa',
  'mors',
  'morse',
  'mort',
  'mos',
  'mose',
  'moset',
  'mosk',
  'moske',
  'moss',
  'most',
  'mot',
  'mote',
  'moten',
  'motet',
  'motiv',
  'motor',
  'mots',
  'mott',
  'motto',
  'mp',
  'mq',
  'mr',
  'mrk',
  'mrs',
  'ms',
  'mt',
  'mu',
  'mudre',
  'muffe',
  'muge',
  'mugg',
  'mugga',
  'mugge',
  'mugne',
  'mukk',
  'mukka',
  'mukke',
  'muld',
  'mulda',
  'mule',
  'mulig',
  'mulle',
  'mulm',
  'multe',
  'mumle',
  'munch',
  'munn',
  'munne',
  'mur',
  'mure',
  'murer',
  'murre',
  'mus',
  'musa',
  'muse',
  'musen',
  'muset',
  'musse',
  'mute',
  'mutt',
  'mv',
  'mva',
  'mw',
  'mx',
  'my',
  'mye',
  'mygg',
  'mygla',
  'mygle',
  'mykna',
  'mykne',
  'mykt',
  'mykte',
  'mynde',
  'mynt',
  'mynte',
  'myr',
  'myra',
  'myrde',
  'myren',
  'myrra',
  'myrt',
  'myrte',
  'myse',
  'mysen',
  'myset',
  'myske',
  'myste',
  'myte',
  'myten',
  'mytet',
  'mz',
  'n',
  'nabo',
  'naf',
  'nafta',
  'nag',
  'nage',
  'naget',
  'nagle',
  'naive',
  'naivt',
  'naken',
  'nakke',
  'nakne',
  'nappa',
  'narr',
  'naska',
  'naske',
  'nato',
  'natos',
  'natt',
  'natta',
  'natur',
  'naue',
  'nauru',
  'naus',
  'naust',
  'naut',
  'nav',
  'navar',
  'navet',
  'navf',
  'navle',
  'navne',
  'nb',
  'nc',
  'nd',
  'ne',
  'nebb',
  'nedad',
  'nede',
  'nedi',
  'nedla',
  'nedre',
  'neger',
  'negl',
  'negre',
  'negro',
  'nei',
  'neide',
  'neise',
  'nek',
  'neket',
  'nem',
  'nemnd',
  'nemt',
  'nenne',
  'nente',
  'neon',
  'nepa',
  'nepal',
  'nepe',
  'neppe',
  'nerk',
  'nerts',
  'nerve',
  'nes',
  'nesa',
  'nese',
  'nesen',
  'neset',
  'nesle',
  'nest',
  'neste',
  'nett',
  'neve',
  'neven',
  'never',
  'neves',
  'nevne',
  'nevnt',
  'nevra',
  'nf',
  'ng',
  'nh',
  'nhh',
  'ni',
  'nibs',
  'nid',
  'nidet',
  'niesa',
  'niese',
  'nif',
  'nifs',
  'niger',
  'nikk',
  'nikka',
  'niks',
  'nipe',
  'nipen',
  'nipp',
  'nippe',
  'nise',
  'nisse',
  'nista',
  'niste',
  'nitid',
  'nito',
  'nitti',
  'nj',
  'nk',
  'nkp',
  'nkr',
  'nl',
  'nlh',
  'nm',
  'nn',
  'nnn',
  'nnnn',
  'no',
  'nobel',
  'noble',
  'noen',
  'noir',
  'nolo',
  'nomen',
  'non',
  'nonet',
  'nonne',
  'nop',
  'nor',
  'nora',
  'norad',
  'nord',
  'nore',
  'noret',
  'norge',
  'norne',
  'north',
  'notam',
  'note',
  'noten',
  'noter',
  'notis',
  'nova',
  'np',
  'nq',
  'nr',
  'nrk',
  'ns',
  'nsf',
  'nsu',
  'nt',
  'ntb',
  'nth',
  'nu',
  'nubb',
  'nudd',
  'nudel',
  'nuet',
  'nugg',
  'null',
  'nulte',
  'numre',
  'nupp',
  'nuppe',
  'nut',
  'nuten',
  'nv',
  'nw',
  'nx',
  'ny',
  'nye',
  'nyere',
  'nygle',
  'nyhet',
  'nying',
  'nykk',
  'nykke',
  'nylig',
  'nylon',
  'nymfe',
  'nynna',
  'nynne',
  'nype',
  'nyra',
  'nyre',
  'nyren',
  'nyret',
  'nyse',
  'nyss',
  'nyst',
  'nyste',
  'nyt',
  'nyte',
  'nyter',
  'nytt',
  'nytta',
  'nytte',
  'nyve',
  'nz',
  'o',
  'oase',
  'oasen',
  'ob',
  'obl',
  'oblat',
  'obo',
  'obos',
  'obs',
  'oc',
  'od',
  'odd',
  'odda',
  'odde',
  'odden',
  'odds',
  'ode',
  'odel',
  'oden',
  'odle',
  'odont',
  'oe',
  'oecd',
  'oecon',
  'of',
  'offer',
  'ofra',
  'ofre',
  'ofret',
  'og',
  'oh',
  'ohio',
  'ohm',
  'ohmsk',
  'oi',
  'oj',
  'ok',
  'oker',
  'okse',
  'okser',
  'oksid',
  'oksyd',
  'okt',
  'oktan',
  'oktav',
  'ol',
  'olav',
  'old',
  'older',
  'oldra',
  'olja',
  'olje',
  'oljen',
  'olle',
  'olm',
  'olmt',
  'om',
  'omatt',
  'ombot',
  'ombud',
  'omegn',
  'omen',
  'omgi',
  'omgir',
  'omhu',
  'omhug',
  'omina',
  'omlyd',
  'omme',
  'omn',
  'omner',
  'omsut',
  'omsyn',
  'omveg',
  'omvei',
  'on',
  'onani',
  'ond',
  'onde',
  'ondet',
  'ondt',
  'onkel',
  'onn',
  'onna',
  'oo',
  'ooo',
  'oooo',
  'op',
  'opal',
  'opel',
  'opera',
  'opium',
  'opp',
  'oppad',
  'oppby',
  'oppe',
  'oppgi',
  'oppi',
  'oppom',
  'oppta',
  'opq',
  'opsal',
  'opus',
  'oq',
  'or',
  'ora',
  'ord',
  'orden',
  'order',
  'ordet',
  'ordon',
  'ordre',
  'oren',
  'orgel',
  'orgie',
  'origo',
  'ork',
  'orka',
  'orkan',
  'orke',
  'orket',
  'orkid',
  'orlon',
  'orlov',
  'orm',
  'ormen',
  'ormer',
  'ornat',
  'orre',
  'orvet',
  'os',
  'osa',
  'osaka',
  'ose',
  'osen',
  'oset',
  'oske',
  'oslo',
  'oslos',
  'ospa',
  'ost',
  'oste',
  'osten',
  'osv',
  'ot',
  'oter',
  'otium',
  'otte',
  'ottes',
  'ou',
  'ov',
  'oval',
  'ovale',
  'over',
  'overs',
  'ovnen',
  'ovner',
  'ovns',
  'ow',
  'ox',
  'oy',
  'oz',
  'p',
  'pace',
  'padda',
  'padla',
  'padle',
  'paed',
  'paff',
  'paien',
  'pair',
  'pakk',
  'pakke',
  'pakt',
  'pakta',
  'pakte',
  'pal',
  'pale',
  'palen',
  'paler',
  'pall',
  'palle',
  'palme',
  'pamp',
  'panel',
  'pang',
  'panna',
  'pant',
  'papir',
  'papp',
  'pappa',
  'papua',
  'par',
  'para',
  'parat',
  'pare',
  'pari',
  'paria',
  'paris',
  'park',
  'parti',
  'party',
  'pasja',
  'pasje',
  'pason',
  'pass',
  'pasta',
  'pater',
  'patos',
  'patta',
  'patte',
  'pauke',
  'pause',
  'paven',
  'pb',
  'pc',
  'pd',
  'pe',
  'pedal',
  'pedro',
  'peila',
  'peile',
  'peip',
  'peise',
  'pek',
  'peker',
  'pekes',
  'peket',
  'pekin',
  'pekt',
  'pekte',
  'pel',
  'pelen',
  'pelle',
  'pels',
  'pelse',
  'pence',
  'pene',
  'penge',
  'penn',
  'penny',
  'pens',
  'pensa',
  'pense',
  'pent',
  'peon',
  'pep',
  'pepet',
  'pepp',
  'pepre',
  'pepsi',
  'per',
  'perle',
  'perm',
  'pers',
  'perse',
  'peru',
  'pese',
  'pest',
  'pesta',
  'petit',
  'pf',
  'pg',
  'pga',
  'ph',
  'pharm',
  'phil',
  'pi',
  'piano',
  'pigge',
  'pike',
  'piken',
  'piker',
  'pikes',
  'pikke',
  'pila',
  'pilar',
  'pilen',
  'pilk',
  'pilke',
  'pille',
  'pilot',
  'pils',
  'pimpe',
  'pine',
  'piner',
  'pinje',
  'pinne',
  'pinol',
  'pinse',
  'pinte',
  'pion',
  'pip',
  'pipa',
  'pipe',
  'pipen',
  'piper',
  'piple',
  'pirat',
  'piren',
  'pirk',
  'pirke',
  'pirra',
  'pirre',
  'piska',
  'piske',
  'pisse',
  'pist',
  'piste',
  'pj',
  'pjokk',
  'pk',
  'pl',
  'plaff',
  'plage',
  'plagg',
  'plan',
  'plank',
  'plask',
  'plass',
  'plast',
  'plata',
  'plate',
  'platt',
  'play',
  'pleia',
  'pleie',
  'plen',
  'plena',
  'plent',
  'plikt',
  'pling',
  'plire',
  'plitt',
  'plog',
  'plugg',
  'plukk',
  'plump',
  'pluss',
  'plysj',
  'pm',
  'pn',
  'po',
  'poda',
  'pode',
  'poden',
  'podet',
  'poem',
  'poeng',
  'poesi',
  'poet',
  'pokal',
  'poker',
  'pokka',
  'pol',
  'pole',
  'polen',
  'poler',
  'polet',
  'polio',
  'polit',
  'polka',
  'poll',
  'polo',
  'polsk',
  'pomp',
  'ponni',
  'pool',
  'pop',
  'popen',
  'pore',
  'porno',
  'pors',
  'port',
  'porto',
  'pose',
  'posen',
  'poser',
  'poste',
  'pote',
  'poten',
  'potet',
  'potte',
  'pp',
  'ppp',
  'pppp',
  'pq',
  'pqr',
  'pr',
  'praha',
  'praia',
  'praie',
  'prakt',
  'pral',
  'pram',
  'prat',
  'prata',
  'prate',
  'pre',
  'preet',
  'preg',
  'prege',
  'prek',
  'preka',
  'preke',
  'pren',
  'prent',
  'prep',
  'press',
  'prest',
  'prikk',
  'prim',
  'prima',
  'prime',
  'primo',
  'prins',
  'pris',
  'prisa',
  'prise',
  'prof',
  'proff',
  'propp',
  'props',
  'prosa',
  'prost',
  'prove',
  'prunk',
  'prut',
  'prute',
  'pryd',
  'pryda',
  'pryl',
  'prylt',
  'ps',
  'pst',
  'psyke',
  'pt',
  'ptro',
  'pu',
  'pub',
  'puben',
  'pudre',
  'puffa',
  'puffe',
  'pugg',
  'pugge',
  'pukka',
  'pukke',
  'pulk',
  'pull',
  'puls',
  'pult',
  'pumpe',
  'pumps',
  'pund',
  'pung',
  'punge',
  'punkt',
  'punsj',
  'pupp',
  'puppa',
  'puppe',
  'pur',
  'pure',
  'purka',
  'purke',
  'purra',
  'purre',
  'purt',
  'pus',
  'pusen',
  'push',
  'pushe',
  'pusle',
  'pusse',
  'pust',
  'puste',
  'puta',
  'pute',
  'puten',
  'puter',
  'putle',
  'putre',
  'putte',
  'pv',
  'pvc',
  'pw',
  'px',
  'py',
  'pygme',
  'pynte',
  'pyore',
  'pysen',
  'pysj',
  'pytt',
  'pz',
  'q',
  'qb',
  'qc',
  'qd',
  'qe',
  'qf',
  'qg',
  'qh',
  'qi',
  'qj',
  'qk',
  'ql',
  'qm',
  'qn',
  'qo',
  'qp',
  'qq',
  'qqq',
  'qqqq',
  'qr',
  'qrs',
  'qs',
  'qt',
  'qu',
  'queen',
  'qv',
  'qw',
  'qx',
  'qy',
  'qz',
  'r',
  'rabb',
  'rabbe',
  'rabla',
  'rable',
  'race',
  'racer',
  'racet',
  'rad',
  'rada',
  'radar',
  'radd',
  'radig',
  'radio',
  'radon',
  'raet',
  'raff',
  'raft',
  'rafta',
  'rage',
  'rager',
  'raget',
  'ragg',
  'ragu',
  'raid',
  'raide',
  'rajah',
  'raje',
  'rak',
  'rakk',
  'rakke',
  'rakle',
  'rakne',
  'rakst',
  'rakte',
  'ralle',
  'rally',
  'ram',
  'ramle',
  'ramm',
  'ramma',
  'ramme',
  'ramn',
  'rampe',
  'rams',
  'ramse',
  'ran',
  'rana',
  'rand',
  'rande',
  'rane',
  'rangs',
  'rank',
  'ranke',
  'ransj',
  'rap',
  'rape',
  'rapen',
  'rapp',
  'rappe',
  'raps',
  'rapse',
  'rapt',
  'rar',
  'rare',
  'rart',
  'ras',
  'rase',
  'raser',
  'raset',
  'rask',
  'raskt',
  'rasle',
  'rasp',
  'raten',
  'ratt',
  'rauk',
  'rauma',
  'raus',
  'rause',
  'raut',
  'raute',
  'rav',
  'rava',
  'rave',
  'raver',
  'ravet',
  'ravn',
  'rayon',
  'rb',
  'rc',
  'rd',
  're',
  'ready',
  'rebus',
  'red',
  'redd',
  'redde',
  'rede',
  'reden',
  'reder',
  'redet',
  'redig',
  'reel',
  'reell',
  'reelt',
  'ref',
  'refsa',
  'refse',
  'regel',
  'regi',
  'regn',
  'regne',
  'rei',
  'reie',
  'reika',
  'reim',
  'reima',
  'reine',
  'reint',
  'reip',
  'reir',
  'reis',
  'reisa',
  'reise',
  'reist',
  'reita',
  'reiv',
  'reiva',
  'reive',
  'rek',
  'reke',
  'rekel',
  'reket',
  'rekka',
  'rekke',
  'rekkt',
  'rekt',
  'rekyl',
  'rele',
  'remi',
  'remja',
  'remje',
  'remma',
  'remsa',
  'remse',
  'ren',
  'rene',
  'renn',
  'renne',
  'rens',
  'rense',
  'rent',
  'rente',
  'reol',
  'repos',
  'res',
  'reser',
  'resp',
  'rest',
  'rette',
  'rev',
  'reven',
  'revet',
  'revne',
  'revue',
  'revy',
  'rf',
  'rg',
  'rh',
  'ri',
  'ria',
  'ribbe',
  'rica',
  'rico',
  'ridd',
  'ride',
  'rifla',
  'rifle',
  'rift',
  'riga',
  'rigg',
  'rigga',
  'rigge',
  'right',
  'rik',
  'rike',
  'riket',
  'rikke',
  'rikt',
  'rille',
  'rim',
  'rime',
  'rimen',
  'rimer',
  'rimet',
  'ring',
  'ringe',
  'rinne',
  'rio',
  'rip',
  'ripa',
  'ripe',
  'riper',
  'ripet',
  'rippe',
  'rips',
  'rir',
  'rise',
  'risen',
  'risk',
  'riske',
  'risp',
  'rispe',
  'riss',
  'rissa',
  'risse',
  'rist',
  'rista',
  'riter',
  'ritt',
  'ritus',
  'riv',
  'rival',
  'rive',
  'river',
  'rivne',
  'rj',
  'rk',
  'rl',
  'rm',
  'rn',
  'ro',
  'roa',
  'robot',
  'rock',
  'rocke',
  'rode',
  'roden',
  'rodos',
  'roe',
  'roen',
  'rogn',
  'rogna',
  'rok',
  'roket',
  'rokk',
  'rokke',
  'rokoa',
  'rolig',
  'roll',
  'rolle',
  'roma',
  'roman',
  'rombe',
  'romeo',
  'romer',
  'romme',
  'rop',
  'rope',
  'roper',
  'ropet',
  'ropt',
  'ropte',
  'ror',
  'rorbu',
  'rorc',
  'rors',
  'ros',
  'rosa',
  'rose',
  'rosen',
  'roset',
  'rosin',
  'rosse',
  'roste',
  'rot',
  'rota',
  'rote',
  'roten',
  'rotor',
  'rotte',
  'rov',
  'rove',
  'rovet',
  'royal',
  'rp',
  'rq',
  'rr',
  'rrr',
  'rrrr',
  'rs',
  'rst',
  'rt',
  'ru',
  'rua',
  'rubb',
  'rubel',
  'rubin',
  'rue',
  'ruff',
  'rug',
  'rugde',
  'ruge',
  'rugen',
  'ruger',
  'rugge',
  'ruin',
  'ruke',
  'rull',
  'rulla',
  'rulle',
  'rumpe',
  'rund',
  'rundt',
  'rune',
  'runke',
  'runn',
  'runne',
  'rus',
  'ruse',
  'rusen',
  'rush',
  'rusk',
  'ruske',
  'rusle',
  'russ',
  'rust',
  'rusta',
  'ruste',
  'rut',
  'ruta',
  'rute',
  'ruten',
  'ruter',
  'rutet',
  'ruth',
  'rutle',
  'rutt',
  'rutte',
  'ruv',
  'ruve',
  'ruvet',
  'rv',
  'rw',
  'rx',
  'ry',
  'rydde',
  'rye',
  'ryet',
  'ryg',
  'rygg',
  'rygge',
  'ryke',
  'ryker',
  'rykk',
  'rykke',
  'rykte',
  'rype',
  'ryr',
  'rysj',
  'ryste',
  'ryte',
  'rytme',
  'rytt',
  'rz',
  's',
  'saab',
  'sabb',
  'sabbe',
  'sabel',
  'safe',
  'safen',
  'safir',
  'saft',
  'safta',
  'safte',
  'sag',
  'saga',
  'sagd',
  'sagde',
  'sage',
  'saget',
  'sagn',
  'sagt',
  'sak',
  'saka',
  'sake',
  'saker',
  'sakka',
  'sakke',
  'sakne',
  'sakse',
  'sakte',
  'sal',
  'salat',
  'saldi',
  'saldo',
  'salen',
  'salg',
  'salgs',
  'salme',
  'salta',
  'salte',
  'salto',
  'salva',
  'samba',
  'sambo',
  'same',
  'samle',
  'samma',
  'samme',
  'san',
  'sand',
  'sang',
  'sanke',
  'sann',
  'sans',
  'sanse',
  'sant',
  'santa',
  'sart',
  'sas',
  'sat',
  'satan',
  'sats',
  'satsa',
  'satse',
  'satt',
  'satte',
  'satyr',
  'sauen',
  'sauer',
  'saug',
  'saup',
  'sause',
  'saven',
  'savl',
  'savn',
  'savna',
  'savne',
  'sb',
  'sc',
  'scala',
  'score',
  'sd',
  'se',
  'sea',
  'sebra',
  'sed',
  'sedat',
  'seder',
  'seer',
  'seere',
  'sees',
  'sefyr',
  'seg',
  'seget',
  'segl',
  'segla',
  'segn',
  'segna',
  'segne',
  'sei',
  'seid',
  'seide',
  'seien',
  'seier',
  'seig',
  'seil',
  'seile',
  'seilt',
  'sein',
  'seint',
  'seire',
  'sek',
  'seks',
  'seksa',
  'sekt',
  'selbu',
  'selen',
  'selet',
  'selge',
  'selja',
  'selje',
  'selle',
  'selot',
  'selv',
  'selve',
  'semje',
  'sen',
  'senat',
  'send',
  'sende',
  'sene',
  'senen',
  'senet',
  'senga',
  'sengs',
  'senil',
  'senja',
  'senke',
  'senn',
  'sent',
  'sept',
  'ser',
  'seraf',
  'serai',
  'serie',
  'serk',
  'serum',
  'serva',
  'serve',
  'ses',
  'sesam',
  'sess',
  'sesse',
  'sete',
  'seter',
  'setra',
  'sett',
  'sev',
  'seva',
  'sevet',
  'sevja',
  'sevje',
  'sf',
  'sg',
  'sh',
  'shape',
  'sharp',
  'shell',
  'show',
  'shunt',
  'si',
  'sia',
  'siam',
  'sibir',
  'sid',
  'sida',
  'sidd',
  'sidda',
  'sidde',
  'side',
  'siden',
  'sider',
  'sidt',
  'sier',
  'sies',
  'sifre',
  'sig',
  'sigd',
  'sige',
  'signe',
  'sik',
  'sika',
  'siken',
  'sikh',
  'sikl',
  'sikle',
  'sikra',
  'sikre',
  'sikt',
  'sikta',
  'sikte',
  'sild',
  'silda',
  'silde',
  'sile',
  'silen',
  'siler',
  'silke',
  'silo',
  'silt',
  'silte',
  'simle',
  'sin',
  'sine',
  'sink',
  'sinka',
  'sinke',
  'sinn',
  'sinne',
  'sinns',
  'sint',
  'sinte',
  'sinus',
  'sir',
  'sira',
  'sirs',
  'sirup',
  'sisik',
  'siste',
  'sisu',
  'sitar',
  'sitat',
  'sitre',
  'sitt',
  'siv',
  'sive',
  'siver',
  'sivet',
  'sivil',
  'sj',
  'sjah',
  'sjakk',
  'sjakt',
  'sjal',
  'sjapp',
  'sjarm',
  'sjau',
  'sjaue',
  'sjef',
  'sjefs',
  'sjeik',
  'sjekk',
  'sjel',
  'sjela',
  'sjete',
  'sjikt',
  'sjog',
  'sjokk',
  'sju',
  'sjuk',
  'sjuke',
  'sjukt',
  'sjusk',
  'sjy',
  'sjyen',
  'sk',
  'ska',
  'skadd',
  'skade',
  'skaft',
  'skake',
  'skakk',
  'skakt',
  'skal',
  'skala',
  'skald',
  'skalk',
  'skall',
  'skalp',
  'skam',
  'skap',
  'skape',
  'skar',
  'skare',
  'skarn',
  'skarp',
  'skate',
  'skatt',
  'skaut',
  'skauv',
  'skave',
  'skavl',
  'skei',
  'skeia',
  'skeid',
  'skein',
  'skeis',
  'skeiv',
  'ski',
  'skia',
  'skie',
  'skien',
  'skift',
  'skikt',
  'skill',
  'skilt',
  'skimt',
  'skinn',
  'skint',
  'skip',
  'skipa',
  'skipe',
  'skips',
  'skir',
  'skitt',
  'skiva',
  'skive',
  'skje',
  'skjea',
  'skjer',
  'skjev',
  'skjor',
  'skled',
  'sklei',
  'skli',
  'sklie',
  'sklir',
  'sko',
  'skodd',
  'skoen',
  'skoft',
  'skog',
  'skogn',
  'skogs',
  'skokk',
  'skolt',
  'skor',
  'skort',
  'skott',
  'skove',
  'skovl',
  'skral',
  'skrap',
  'skre',
  'skred',
  'skrei',
  'skrek',
  'skrev',
  'skri',
  'skrie',
  'skrik',
  'skrin',
  'skrir',
  'skriv',
  'skrog',
  'skrov',
  'skru',
  'skrud',
  'skruv',
  'skryt',
  'skudd',
  'skue',
  'skuer',
  'skuet',
  'skuff',
  'skuld',
  'skuls',
  'skum',
  'skump',
  'skumt',
  'skunk',
  'skur',
  'skura',
  'skurd',
  'skure',
  'skurk',
  'skurv',
  'skut',
  'skuta',
  'skute',
  'skuve',
  'skval',
  'skvis',
  'sky',
  'skya',
  'skye',
  'skyen',
  'skyer',
  'skyet',
  'skygg',
  'skygt',
  'skyld',
  'skyll',
  'skylt',
  'skyme',
  'skyr',
  'skyss',
  'skyte',
  'skyts',
  'skyve',
  'sl',
  'slaet',
  'slafs',
  'slag',
  'slags',
  'slak',
  'slakk',
  'slakt',
  'slam',
  'slamp',
  'slams',
  'slang',
  'slank',
  'slant',
  'slapp',
  'slaps',
  'slapt',
  'slark',
  'slarv',
  'slask',
  'slatt',
  'slave',
  'sle',
  'slede',
  'sleik',
  'sleip',
  'sleit',
  'slekt',
  'slem',
  'slemt',
  'sleng',
  'slep',
  'slepe',
  'slepp',
  'slesk',
  'slet',
  'slett',
  'sleve',
  'slide',
  'slik',
  'slike',
  'slikk',
  'slikt',
  'slim',
  'slipe',
  'slipp',
  'slips',
  'slipt',
  'slir',
  'slira',
  'slire',
  'sliss',
  'slit',
  'slite',
  'slitt',
  'slo',
  'sloa',
  'slodd',
  'slok',
  'slokt',
  'sloss',
  'slott',
  'slow',
  'slu',
  'sludd',
  'slue',
  'slufs',
  'sluk',
  'sluke',
  'slum',
  'slump',
  'slunt',
  'slupp',
  'slure',
  'slurk',
  'slurv',
  'sluse',
  'slusk',
  'slut',
  'slute',
  'slyng',
  'sm',
  'smak',
  'smaka',
  'smake',
  'smakt',
  'smal',
  'smale',
  'small',
  'smalt',
  'smart',
  'smash',
  'smask',
  'smau',
  'smaug',
  'smeik',
  'smekk',
  'smell',
  'smelt',
  'smie',
  'smil',
  'smile',
  'smitt',
  'smog',
  'smokk',
  'sms',
  'smukk',
  'smul',
  'smule',
  'smult',
  'smurt',
  'smuss',
  'smyge',
  'sn',
  'snabb',
  'snack',
  'snak',
  'snakk',
  'snapp',
  'snaps',
  'snar',
  'snart',
  'snau',
  'snaue',
  'snaut',
  'snavl',
  'snei',
  'sneie',
  'sneik',
  'snek',
  'snerk',
  'snerp',
  'snert',
  'snev',
  'snik',
  'snike',
  'snile',
  'snill',
  'snilt',
  'snipe',
  'snipp',
  'snitt',
  'sno',
  'snoa',
  'snobb',
  'snoen',
  'snok',
  'snop',
  'snor',
  'snora',
  'snork',
  'snu',
  'snue',
  'snuen',
  'snupp',
  'snur',
  'snurt',
  'snuse',
  'snusk',
  'snut',
  'snute',
  'snytt',
  'so',
  'soar',
  'soare',
  'sobel',
  'sober',
  'sobre',
  'soda',
  'sodd',
  'sofa',
  'soge',
  'sogn',
  'sogna',
  'sogne',
  'sokn',
  'sokna',
  'sokne',
  'sol',
  'sola',
  'sold',
  'sole',
  'solem',
  'solgt',
  'solid',
  'soll',
  'solo',
  'som',
  'somle',
  'somme',
  'somt',
  'son',
  'sona',
  'sonar',
  'sonde',
  'sone',
  'sonen',
  'soner',
  'sonet',
  'sont',
  'sonte',
  'sop',
  'sope',
  'sopp',
  'sopt',
  'sopte',
  'sorg',
  'sorga',
  'soria',
  'sorp',
  'sort',
  'sorti',
  'sos',
  'sot',
  'sota',
  'sote',
  'soten',
  'sotet',
  'sotra',
  'sotta',
  'sous',
  'south',
  'sov',
  'sova',
  'sove',
  'sover',
  'sovet',
  'sovne',
  'soya',
  'sp',
  'spa',
  'spade',
  'spak',
  'spakt',
  'spant',
  'spar',
  'spara',
  'spare',
  'spark',
  'spas',
  'spase',
  'spat',
  'spe',
  'spedd',
  'spede',
  'spedt',
  'speed',
  'speen',
  'speke',
  'spekk',
  'spell',
  'spene',
  'spent',
  'sper',
  'spes',
  'spett',
  'spid',
  'spidd',
  'spik',
  'spika',
  'spikk',
  'spil',
  'spila',
  'spile',
  'spilt',
  'spinn',
  'spion',
  'spir',
  'spirt',
  'spis',
  'spise',
  'spiss',
  'spist',
  'spit',
  'spjut',
  'splid',
  'spole',
  'spon',
  'spond',
  'spong',
  'spor',
  'spore',
  'sport',
  'spott',
  'spove',
  'spre',
  'sprek',
  'sprer',
  'spres',
  'spri',
  'sprit',
  'spue',
  'spuen',
  'spuns',
  'spurt',
  'spurv',
  'spy',
  'spyd',
  'spydd',
  'spyle',
  'spyr',
  'spytt',
  'sq',
  'sr',
  'sri',
  'ss',
  'sss',
  'ssss',
  'st',
  'sta',
  'stab',
  'stad',
  'stafo',
  'stag',
  'staka',
  'stake',
  'stakk',
  'stakt',
  'stal',
  'stall',
  'stam',
  'stamn',
  'stamp',
  'stamt',
  'stand',
  'stang',
  'stank',
  'stans',
  'stapp',
  'star',
  'stare',
  'starr',
  'stars',
  'start',
  'stas',
  'stase',
  'stat',
  'state',
  'staup',
  'stav',
  'stava',
  'stave',
  'stavn',
  'ste',
  'stede',
  'stedt',
  'steet',
  'steg',
  'stege',
  'stegg',
  'steig',
  'steik',
  'steil',
  'stein',
  'stek',
  'steka',
  'steke',
  'stele',
  'stell',
  'stelt',
  'steng',
  'stenk',
  'stett',
  'stevn',
  'sti',
  'stie',
  'stien',
  'stier',
  'stiet',
  'stift',
  'stig',
  'stige',
  'stikk',
  'stil',
  'stilk',
  'still',
  'stilt',
  'stim',
  'stime',
  'sting',
  'stinn',
  'stint',
  'stiv',
  'stive',
  'stivt',
  'stjal',
  'stk',
  'sto',
  'stola',
  'stole',
  'stolt',
  'stopp',
  'store',
  'stork',
  'storm',
  'storr',
  'stort',
  'stova',
  'stove',
  'stram',
  'stred',
  'strek',
  'stri',
  'stria',
  'strid',
  'strie',
  'stril',
  'strir',
  'stry',
  'stryk',
  'stryn',
  'stu',
  'stua',
  'stubb',
  'stud',
  'stue',
  'stuen',
  'stuer',
  'stuet',
  'stukk',
  'stum',
  'stump',
  'stumt',
  'stund',
  'stup',
  'stupe',
  'stur',
  'sture',
  'stuss',
  'stut',
  'stuv',
  'stuve',
  'stygg',
  'styr',
  'styre',
  'styrt',
  'styv',
  'su',
  'sua',
  'subb',
  'subbe',
  'succe',
  'sudan',
  'suez',
  'sug',
  'sugde',
  'suge',
  'suger',
  'suget',
  'sugg',
  'sugge',
  'suite',
  'sukat',
  'sukk',
  'sukke',
  'sukl',
  'sukle',
  'sukre',
  'sul',
  'sule',
  'sulet',
  'sulke',
  'sull',
  'sulle',
  'sult',
  'sulte',
  'sum',
  'summa',
  'summe',
  'sump',
  'sund',
  'sunde',
  'sunn',
  'sunne',
  'sunt',
  'suomi',
  'supe',
  'supen',
  'super',
  'supre',
  'supt',
  'supte',
  'sur',
  'surle',
  'surne',
  'surr',
  'surre',
  'surt',
  'sus',
  'suse',
  'susen',
  'suser',
  'suset',
  'susle',
  'sut',
  'suta',
  'sutre',
  'sutt',
  'sutte',
  'suvl',
  'sv',
  'sva',
  'svada',
  'svaet',
  'svait',
  'svak',
  'svake',
  'svakt',
  'sval',
  'svala',
  'svale',
  'svalt',
  'svamp',
  'svane',
  'svans',
  'svant',
  'svar',
  'svars',
  'svart',
  'sve',
  'svei',
  'sveik',
  'svein',
  'sveit',
  'sveiv',
  'svek',
  'svekk',
  'svelg',
  'svelt',
  'svenn',
  'svepa',
  'svepe',
  'sverd',
  'sverm',
  'svett',
  'sveve',
  'svidd',
  'svie',
  'svien',
  'svik',
  'svike',
  'svikt',
  'svime',
  'svin',
  'sving',
  'svinn',
  'svint',
  'svip',
  'svir',
  'sviv',
  'svivd',
  'svor',
  'svull',
  'sw',
  'swing',
  'sx',
  'sy',
  'syd',
  'sydd',
  'syde',
  'syden',
  'syder',
  'syke',
  'syken',
  'sykne',
  'sykt',
  'syl',
  'sylta',
  'sylte',
  'symre',
  'syn',
  'synd',
  'synda',
  'synde',
  'syne',
  'synes',
  'synet',
  'synge',
  'synke',
  'syns',
  'synsk',
  'syr',
  'syre',
  'syria',
  'syrin',
  'sysle',
  'syte',
  'sytt',
  'sytte',
  'sytti',
  'sz',
  't',
  'tabbe',
  'tabu',
  'tafs',
  'taft',
  'tagal',
  'tagd',
  'tagde',
  'tagg',
  'tagl',
  'tagne',
  'taket',
  'takk',
  'takke',
  'takle',
  'takst',
  'takt',
  'takta',
  'tal',
  'tala',
  'taler',
  'tales',
  'talet',
  'talg',
  'talja',
  'talje',
  'tall',
  'talls',
  'talt',
  'talte',
  'tam',
  'tame',
  'tamen',
  'tamme',
  'tamp',
  'tampa',
  'tamt',
  'tan',
  'tana',
  'tang',
  'tanga',
  'tango',
  'tank',
  'tanke',
  'tanks',
  'tann',
  'tanna',
  'tanne',
  'tant',
  'tanta',
  'tante',
  'tap',
  'tape',
  'tapen',
  'taper',
  'tapet',
  'tapir',
  'tapp',
  'tappe',
  'tapt',
  'tapte',
  'tar',
  'tare',
  'tart',
  'tarv',
  'taska',
  'tasla',
  'tassa',
  'tasse',
  'tast',
  'tasta',
  'tat',
  'tatar',
  'tatle',
  'tatt',
  'tau',
  'taue',
  'tauet',
  'taum',
  'tausa',
  'tause',
  'taust',
  'taut',
  'tavle',
  'tb',
  'tc',
  'tchad',
  'td',
  'te',
  'tea',
  'team',
  'tedde',
  'teddy',
  'teft',
  'tegl',
  'tegn',
  'tegna',
  'tegne',
  'tegns',
  'teig',
  'tein',
  'teine',
  'teint',
  'teip',
  'teipa',
  'teipe',
  'teist',
  'tekke',
  'tekn',
  'tekst',
  'tekt',
  'tekte',
  'tel',
  'tele',
  'telen',
  'telle',
  'telne',
  'telt',
  'telte',
  'tema',
  'temme',
  'tempi',
  'tempo',
  'ten',
  'tendt',
  'tenk',
  'tenke',
  'tenkt',
  'tenne',
  'tenor',
  'tent',
  'teppe',
  'ter',
  'terge',
  'term',
  'terme',
  'terne',
  'ters',
  'terta',
  'terte',
  'tese',
  'tesen',
  'teser',
  'tess',
  'testa',
  'teste',
  'tet',
  'teten',
  'tetna',
  'tetne',
  'tett',
  'tette',
  'tev',
  'teve',
  'teven',
  'tevla',
  'tevle',
  'tf',
  'tg',
  'th',
  'the',
  'theol',
  'ti',
  'tiara',
  'tibet',
  'tid',
  'tidd',
  'tidde',
  'tide',
  'tidel',
  'tiden',
  'tider',
  'tidl',
  'tids',
  'tidt',
  'tie',
  'tien',
  'tiene',
  'tier',
  'tiere',
  'tiest',
  'tigd',
  'tigde',
  'tiger',
  'tigge',
  'tika',
  'tikka',
  'tikke',
  'tilbe',
  'tilby',
  'tilde',
  'tilgi',
  'tilje',
  'tilla',
  'tilsa',
  'tilta',
  'time',
  'timen',
  'times',
  'tina',
  'tinda',
  'tinde',
  'tiner',
  'tinet',
  'tinga',
  'tings',
  'tinn',
  'tiple',
  'tipp',
  'tippe',
  'tirra',
  'tirre',
  'tiske',
  'tispa',
  'tispe',
  'tisse',
  'tit',
  'titan',
  'titen',
  'titt',
  'titta',
  'titte',
  'tiur',
  'tj',
  'tja',
  'tjafs',
  'tjau',
  'tjeld',
  'tjen',
  'tjene',
  'tjent',
  'tjern',
  'tjon',
  'tjona',
  'tjont',
  'tjor',
  'tjora',
  'tjore',
  'tjort',
  'tjue',
  'tjug',
  'tjuge',
  'tjukk',
  'tjuv',
  'tk',
  'tl',
  'tlf',
  'tm',
  'tn',
  'to',
  'toa',
  'toast',
  'toe',
  'toen',
  'toer',
  'toet',
  'tofte',
  'tog',
  'toga',
  'toger',
  'toget',
  'togo',
  'tok',
  'tokke',
  'tokn',
  'tokna',
  'tokt',
  'tokta',
  'tokyo',
  'tolka',
  'toll',
  'tolla',
  'tolle',
  'tom',
  'tomat',
  'tomme',
  'tomt',
  'tomta',
  'tona',
  'tonal',
  'tone',
  'tonen',
  'tonet',
  'tonga',
  'topas',
  'toppa',
  'toppe',
  'topps',
  'tora',
  'tore',
  'torg',
  'torgs',
  'torn',
  'torp',
  'torsk',
  'tort',
  'torv',
  'torva',
  'torve',
  'tosk',
  'total',
  'totem',
  'toten',
  'tott',
  'touch',
  'tout',
  'tove',
  'tovet',
  'tp',
  'tq',
  'tr',
  'trace',
  'trade',
  'traff',
  'trakk',
  'trakt',
  'trale',
  'trall',
  'tram',
  'tramp',
  'tran',
  'trana',
  'trane',
  'trang',
  'trapp',
  'trase',
  'trask',
  'trass',
  'trast',
  'trau',
  'traut',
  'trav',
  'trava',
  'travd',
  'trave',
  'tre',
  'treen',
  'treet',
  'treff',
  'trega',
  'trege',
  'tregt',
  'treiv',
  'trell',
  'tren',
  'trena',
  'trend',
  'trene',
  'trent',
  'trer',
  'tres',
  'tresk',
  'trett',
  'trick',
  'trikk',
  'triks',
  'trim',
  'trinn',
  'trio',
  'tripp',
  'trisa',
  'trise',
  'trist',
  'tritt',
  'triv',
  'trivd',
  'trive',
  'troa',
  'trodd',
  'troen',
  'trofe',
  'troja',
  'troke',
  'troll',
  'trona',
  'trone',
  'tropp',
  'tror',
  'tross',
  'trott',
  'tru',
  'trua',
  'truck',
  'truer',
  'trues',
  'truet',
  'truga',
  'truge',
  'trumf',
  'trump',
  'trupp',
  'trur',
  'trusa',
  'trust',
  'trut',
  'trutt',
  'trygg',
  'trykk',
  'trykt',
  'trylt',
  'tryte',
  'trytt',
  'ts',
  'tsar',
  'tt',
  'ttt',
  'tttt',
  'tu',
  'tua',
  'tube',
  'tue',
  'tufs',
  'tufsa',
  'tufse',
  'tuft',
  'tufte',
  'tugga',
  'tukla',
  'tukle',
  'tukt',
  'tukta',
  'tukte',
  'tull',
  'tulla',
  'tulle',
  'tumla',
  'tumle',
  'tumor',
  'tun',
  'tune',
  'tuner',
  'tunes',
  'tunet',
  'tung',
  'tunga',
  'tunge',
  'tungt',
  'tunis',
  'tupp',
  'tuppa',
  'tuppe',
  'tur',
  'tura',
  'turde',
  'ture',
  'turen',
  'turne',
  'turt',
  'turta',
  'turu',
  'tusen',
  'tusj',
  'tusle',
  'tussa',
  'tusse',
  'tuta',
  'tute',
  'tuten',
  'tuter',
  'tutet',
  'tutla',
  'tutle',
  'tutt',
  'tuv',
  'tv',
  'tvang',
  'tvare',
  'tverr',
  'tvers',
  'tvert',
  'tvi',
  'tvil',
  'tvile',
  'tvill',
  'tvilt',
  'tvore',
  'tw',
  'tweed',
  'twist',
  'tx',
  'ty',
  'tydd',
  'tyde',
  'tyder',
  'tydet',
  'tyfon',
  'tyfus',
  'tygde',
  'tygge',
  'tykk',
  'tykke',
  'tykne',
  'tykt',
  'tykte',
  'tylft',
  'tylla',
  'tylle',
  'tyne',
  'tyner',
  'tynga',
  'tyngd',
  'tynne',
  'type',
  'typer',
  'tyr',
  'tyren',
  'tyri',
  'tyrk',
  'tysk',
  'tyske',
  'tyss',
  'tyst',
  'tyte',
  'tytt',
  'tytte',
  'tyv',
  'tyven',
  'tyver',
  'tz',
  'u',
  'uante',
  'ub',
  'ubedt',
  'ublue',
  'ubudd',
  'ubygd',
  'uc',
  'ud',
  'udelt',
  'udyr',
  'udyra',
  'ue',
  'uefne',
  'uegna',
  'uekte',
  'uer',
  'ueren',
  'uf',
  'uferd',
  'uff',
  'ufin',
  'ufine',
  'ufint',
  'ufoen',
  'ufred',
  'ufs',
  'ufsa',
  'ufsen',
  'ug',
  'ugagn',
  'uggen',
  'ugift',
  'ugla',
  'uglad',
  'ugle',
  'uglen',
  'ugler',
  'ugne',
  'ugras',
  'uh',
  'uhell',
  'uhyre',
  'ui',
  'uj',
  'ujamn',
  'ujamt',
  'ujevn',
  'uk',
  'ukalt',
  'ukas',
  'uken',
  'ukene',
  'uker',
  'ukers',
  'uklar',
  'uklok',
  'ul',
  'ula',
  'ulag',
  'ulage',
  'ule',
  'ulene',
  'ulet',
  'ulik',
  'ulike',
  'ulikt',
  'ulken',
  'ull',
  'ulla',
  'ullen',
  'ulma',
  'ulme',
  'ulmer',
  'ulmet',
  'ulne',
  'ult',
  'ulte',
  'ulv',
  'ulven',
  'ulver',
  'ulvik',
  'um',
  'umak',
  'umaka',
  'umake',
  'un',
  'undas',
  'under',
  'undra',
  'undre',
  'ung',
  'ungar',
  'unge',
  'ungen',
  'unger',
  'unges',
  'unik',
  'unike',
  'unikt',
  'union',
  'univ',
  'unna',
  'unne',
  'unnse',
  'unnsi',
  'unnta',
  'unote',
  'unse',
  'unsen',
  'unt',
  'unte',
  'uo',
  'up',
  'uq',
  'ur',
  'uran',
  'urban',
  'uredd',
  'urene',
  'urer',
  'uret',
  'urett',
  'urin',
  'urna',
  'urne',
  'uro',
  'uroa',
  'urodd',
  'uroe',
  'uroer',
  'uroes',
  'uroet',
  'urt',
  'urten',
  'urter',
  'urtid',
  'urven',
  'urvne',
  'us',
  'usa',
  'usagt',
  'usle',
  'usmak',
  'ussel',
  'usunt',
  'ut',
  'uta',
  'utad',
  'utakk',
  'utall',
  'utast',
  'utav',
  'utbe',
  'utby',
  'ute',
  'uten',
  'utett',
  'utfor',
  'utfra',
  'utga',
  'utgav',
  'utgi',
  'utgir',
  'utgis',
  'uthus',
  'uti',
  'utide',
  'uting',
  'utkik',
  'utkom',
  'utleg',
  'utlyd',
  'utmed',
  'utom',
  'utopi',
  'utrop',
  'utror',
  'utse',
  'utsig',
  'utsyn',
  'uttak',
  'uttur',
  'utukt',
  'utur',
  'utveg',
  'utvei',
  'uu',
  'uuu',
  'uuuu',
  'uv',
  'uvand',
  'uvane',
  'uvant',
  'uvel',
  'uvenn',
  'uvett',
  'uviss',
  'uvw',
  'uw',
  'ux',
  'uy',
  'uz',
  'v',
  'vable',
  'vad',
  'vadde',
  'vade',
  'vaden',
  'vafle',
  'vage',
  'vagl',
  'vagla',
  'vagle',
  'vagt',
  'vaide',
  'vaie',
  'vaier',
  'vaiet',
  'vak',
  'vaka',
  'vake',
  'vaken',
  'vaket',
  'vakla',
  'vakle',
  'vakna',
  'vakne',
  'vakre',
  'vakt',
  'vakte',
  'val',
  'valen',
  'valg',
  'valk',
  'valp',
  'valsa',
  'valse',
  'valte',
  'vand',
  'vandt',
  'vane',
  'vanen',
  'vaner',
  'vang',
  'vange',
  'vanka',
  'vanke',
  'vann',
  'vanne',
  'vanns',
  'var',
  'vara',
  'vare',
  'varen',
  'varer',
  'varet',
  'varg',
  'varig',
  'varm',
  'varma',
  'varmt',
  'varp',
  'varte',
  'vas',
  'vase',
  'vasen',
  'vaset',
  'vask',
  'vaska',
  'vass',
  'vasse',
  'vater',
  'vatn',
  'vatna',
  'vatne',
  'vatra',
  'vatre',
  'vatt',
  'vb',
  'vc',
  'vd',
  've',
  'ved',
  'vedde',
  'vedr',
  'vedta',
  'veg',
  'vegd',
  'vege',
  'vegen',
  'veger',
  'veggs',
  'vegne',
  'vegra',
  'vegre',
  'vegs',
  'vei',
  'veid',
  'veidd',
  'veide',
  'veien',
  'veier',
  'veiet',
  'veik',
  'veike',
  'veikt',
  'veis',
  'veit',
  'veita',
  'veiv',
  'veive',
  'veka',
  'veke',
  'veken',
  'vekk',
  'vekke',
  'vekne',
  'vekst',
  'vekta',
  'vel',
  'velde',
  'velg',
  'velge',
  'vell',
  'velle',
  'velt',
  'velte',
  'velur',
  'vemme',
  'vemod',
  'ven',
  'vende',
  'vendt',
  'vene',
  'vener',
  'veng',
  'venn',
  'venne',
  'venns',
  'vent',
  'venta',
  'vente',
  'veps',
  'verb',
  'verbo',
  'verd',
  'verda',
  'verdi',
  'verdt',
  'verge',
  'verja',
  'verk',
  'verke',
  'vern',
  'verne',
  'verpa',
  'verpe',
  'verre',
  'vers',
  'vert',
  'verts',
  'verv',
  'verva',
  'verve',
  'vesen',
  'vesir',
  'veska',
  'vesle',
  'vest',
  'vesta',
  'vet',
  'vete',
  'veten',
  'veto',
  'vett',
  'veum',
  'vev',
  'vevd',
  'vevde',
  'veve',
  'veven',
  'vever',
  'vevet',
  'vevre',
  'vf',
  'vg',
  'vh',
  'vi',
  'via',
  'vid',
  'vidd',
  'vidde',
  'vide',
  'vidje',
  'vidt',
  'vie',
  'vier',
  'viet',
  'vifta',
  'vifte',
  'vigd',
  'vigde',
  'vigge',
  'vigla',
  'vik',
  'vika',
  'vikar',
  'vike',
  'viken',
  'viker',
  'vikke',
  'vikla',
  'vil',
  'vill',
  'villa',
  'ville',
  'vilt',
  'vime',
  'vimre',
  'vims',
  'vimsa',
  'vimse',
  'vin',
  'vind',
  'vinde',
  'vindt',
  'vinen',
  'viner',
  'ving',
  'vinge',
  'vinje',
  'vink',
  'vinke',
  'vinn',
  'vinyl',
  'vipa',
  'vipe',
  'vipp',
  'vippa',
  'vippe',
  'vips',
  'virak',
  'viril',
  'virka',
  'virke',
  'virra',
  'virus',
  'vise',
  'viser',
  'viset',
  'visir',
  'viske',
  'visle',
  'visne',
  'visp',
  'vispe',
  'visst',
  'viste',
  'visum',
  'vit',
  'vita',
  'vital',
  'vite',
  'vites',
  'vitne',
  'vits',
  'vitse',
  'viv',
  'vivas',
  'viven',
  'vivet',
  'vj',
  'vk',
  'vl',
  'vm',
  'vn',
  'vo',
  'vodka',
  'voffe',
  'vog',
  'vogga',
  'vogge',
  'vogna',
  'vokal',
  'voks',
  'vokta',
  'vokte',
  'volde',
  'voldt',
  'volen',
  'voll',
  'volte',
  'volum',
  'volve',
  'volvo',
  'vom',
  'vomma',
  'von',
  'vona',
  'vond',
  'vondt',
  'vone',
  'voner',
  'vont',
  'vonte',
  'vorde',
  'vorr',
  'vorta',
  'vota',
  'vott',
  'votum',
  'vov',
  'vovet',
  'vp',
  'vq',
  'vr',
  'vragt',
  'vraka',
  'vrake',
  'vrakt',
  'vrang',
  'vred',
  'vrede',
  'vrei',
  'vri',
  'vridd',
  'vrir',
  'vrist',
  'vs',
  'vsa',
  'vt',
  'vu',
  'vugga',
  'vugge',
  'vulke',
  'vunne',
  'vv',
  'vvs',
  'vvv',
  'vvvv',
  'vw',
  'vwx',
  'vx',
  'vy',
  'vyer',
  'vz',
  'w',
  'wagon',
  'wales',
  'walk',
  'water',
  'watt',
  'wb',
  'wc',
  'wd',
  'we',
  'west',
  'wf',
  'wg',
  'wh',
  'whig',
  'whist',
  'wi',
  'wien',
  'winge',
  'wire',
  'wirer',
  'wj',
  'wk',
  'wl',
  'wm',
  'wn',
  'wo',
  'world',
  'wp',
  'wq',
  'wr',
  'ws',
  'wt',
  'wu',
  'wv',
  'ww',
  'www',
  'wwww',
  'wx',
  'wxy',
  'wy',
  'wz',
  'x',
  'xb',
  'xc',
  'xd',
  'xe',
  'xf',
  'xg',
  'xh',
  'xi',
  'xj',
  'xk',
  'xl',
  'xm',
  'xn',
  'xo',
  'xp',
  'xq',
  'xr',
  'xs',
  'xt',
  'xu',
  'xv',
  'xw',
  'xx',
  'xxx',
  'xxxx',
  'xy',
  'xyz',
  'xz',
  'y',
  'yacht',
  'yb',
  'yc',
  'yd',
  'ydmyk',
  'ye',
  'year',
  'yf',
  'yg',
  'yh',
  'yi',
  'yj',
  'yk',
  'yl',
  'yle',
  'yler',
  'ylte',
  'ym',
  'ymse',
  'ymt',
  'ymte',
  'ymten',
  'ymter',
  'ymtet',
  'yn',
  'ynda',
  'ynde',
  'ynden',
  'ynder',
  'yndet',
  'yndig',
  'ynges',
  'yngre',
  'yngst',
  'ynk',
  'ynke',
  'ynken',
  'ynker',
  'ynkes',
  'ynket',
  'yo',
  'yoga',
  'york',
  'yp',
  'yppa',
  'yppe',
  'ypper',
  'yppes',
  'yppet',
  'yppig',
  'yq',
  'yr',
  'yre',
  'yret',
  'yrka',
  'yrke',
  'yrker',
  'yrket',
  'yrt',
  'yrte',
  'yrvak',
  'ys',
  'ysta',
  'yste',
  'yster',
  'ystet',
  'yt',
  'yta',
  'yte',
  'yter',
  'ytes',
  'ytet',
  'yting',
  'ytre',
  'ytret',
  'ytst',
  'ytt',
  'ytte',
  'yu',
  'yv',
  'yve',
  'yven',
  'yvne',
  'yw',
  'yx',
  'yy',
  'yyy',
  'yyyy',
  'yz',
  'z',
  'zaire',
  'zb',
  'zc',
  'zd',
  'ze',
  'zero',
  'zeus',
  'zevs',
  'zf',
  'zg',
  'zh',
  'zi',
  'zj',
  'zk',
  'zl',
  'zm',
  'zn',
  'zo',
  'zp',
  'zq',
  'zr',
  'zs',
  'zt',
  'zu',
  'zv',
  'zw',
  'zx',
  'zy',
  'zz',
  'zzz',
  'zzzz',
];
