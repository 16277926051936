export const czech = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
  '60',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '69',
  '70',
  '71',
  '72',
  '73',
  '74',
  '75',
  '76',
  '77',
  '78',
  '79',
  '80',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '88',
  '89',
  '90',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '97',
  '98',
  '99',
  '100',
  '101',
  '111',
  '123',
  '200',
  '222',
  '234',
  '300',
  '333',
  '345',
  '400',
  '444',
  '456',
  '500',
  '555',
  '567',
  '600',
  '666',
  '678',
  '700',
  '777',
  '789',
  '800',
  '888',
  '900',
  '999',
  '1000',
  '1111',
  '1234',
  '1492',
  '1500',
  '1600',
  '1700',
  '1800',
  '1900',
  '1910',
  '1920',
  '1925',
  '1930',
  '1935',
  '1940',
  '1945',
  '1950',
  '1955',
  '1960',
  '1965',
  '1970',
  '1975',
  '1980',
  '1985',
  '1990',
  '1991',
  '1992',
  '1993',
  '1994',
  '1995',
  '1996',
  '1997',
  '1998',
  '1999',
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2015',
  '2020',
  '2030',
  '2035',
  '2040',
  '2045',
  '2050',
  '2222',
  '2345',
  '2468',
  '3000',
  '3333',
  '3456',
  '4000',
  '4321',
  '4444',
  '4567',
  '5000',
  '5555',
  '5678',
  '6000',
  '6666',
  '6789',
  '7000',
  '7777',
  '8000',
  '8888',
  '9000',
  '9876',
  '9999',
  '-',
  '--',
  '---',
  ';',
  ':',
  ':-(',
  ':-)',
  ':(',
  ':)',
  '!',
  '!!',
  '!!!',
  '?',
  '??',
  '???',
  '"',
  '(',
  '()',
  ')',
  '@',
  '*',
  '**',
  '***',
  '&',
  '#',
  '##',
  '###',
  '%',
  '%%',
  '%%%',
  '+',
  '++',
  '+++',
  '=',
  '==',
  '===',
  '$',
  '$$',
  '$$$',
  'a',
  'aa',
  'aaa',
  'aaaa',
  'ab',
  'abc',
  'aby',
  'ac',
  'ač',
  'áčko',
  'ačkoli',
  'ad',
  'ada',
  'adam',
  'adéla',
  'adept',
  'adolf',
  'adopce',
  'adresa',
  'adsl',
  'advent',
  'ae',
  'aero',
  'af',
  'aféra',
  'afrika',
  'ag',
  'agáta',
  'agenda',
  'agent',
  'ah',
  'aha',
  'ahoj',
  'ach',
  'ai',
  'aj',
  'ak',
  'akce',
  'akcent',
  'akcie',
  'akční',
  'akorát',
  'akord',
  'akt',
  'aktér',
  'aktiv',
  'akutní',
  'al',
  'alan',
  'alarm',
  'albert',
  'album',
  'ale',
  'alej',
  'alena',
  'aleš',
  'alex',
  'alfa',
  'alias',
  'alibi',
  'alice',
  'alois',
  'alpa',
  'alpský',
  'alpy',
  'alt',
  'altán',
  'am',
  'amatér',
  'ambice',
  'amen',
  'amulet',
  'an',
  'ananas',
  'anděl',
  'andrea',
  'andrej',
  'andy',
  'aneb',
  'anebo',
  'aneta',
  'anežka',
  'anglie',
  'ani',
  'anička',
  'anita',
  'aniž',
  'anketa',
  'anna',
  'ano',
  'anonym',
  'anténa',
  'antika',
  'anton',
  'ao',
  'ap',
  'aparát',
  'apel',
  'aplaus',
  'apod',
  'aq',
  'ar',
  'arbitr',
  'areál',
  'aréna',
  'arch',
  'archa',
  'archiv',
  'árie',
  'arkáda',
  'armáda',
  'arnošt',
  'aróma',
  'artur',
  'artuš',
  'as',
  'asfalt',
  'asi',
  'asie',
  'aspekt',
  'aspoň',
  'astma',
  'aš',
  'at',
  'ať',
  'atak',
  'atd',
  'atény',
  'atlas',
  'atlet',
  'atom',
  'atp',
  'atrium',
  'au',
  'audi',
  'audio',
  'audit',
  'aukce',
  'aukční',
  'aura',
  'aut',
  'auťák',
  'auto',
  'autor',
  'av',
  'averze',
  'avšak',
  'aw',
  'ax',
  'axiom',
  'ay',
  'az',
  'azyl',
  'až',
  'b',
  'baba',
  'bába',
  'babí',
  'babka',
  'bádání',
  'bageta',
  'bagr',
  'bahno',
  'bach',
  'bacha',
  'bajaja',
  'báje',
  'bájný',
  'bál',
  'balada',
  'balení',
  'balený',
  'balet',
  'balík',
  'balit',
  'balkán',
  'balkón',
  'balón',
  'balvan',
  'bambus',
  'banán',
  'banda',
  'baník',
  'banka',
  'baňka',
  'bankéř',
  'báňský',
  'bar',
  'bára',
  'barák',
  'baráž',
  'barbar',
  'bard',
  'barel',
  'barman',
  'baroko',
  'baron',
  'barový',
  'bárta',
  'barva',
  'barvit',
  'bas',
  'basa',
  'báseň',
  'basket',
  'básník',
  'bašta',
  'bát',
  'baťa',
  'batoh',
  'batole',
  'bavič',
  'bavit',
  'bavlna',
  'bazar',
  'báze',
  'bazén',
  'bázeň',
  'bažant',
  'bažina',
  'bb',
  'bbb',
  'bbbb',
  'bbc',
  'bc',
  'bcd',
  'bd',
  'bdělý',
  'bdít',
  'be',
  'béčko',
  'bečva',
  'běda',
  'bedna',
  'bedra',
  'běh',
  'běhání',
  'běhat',
  'během',
  'bělá',
  'bělavý',
  'běloch',
  'ben',
  'benzín',
  'beran',
  'berka',
  'berle',
  'bert',
  'berta',
  'beseda',
  'bestie',
  'beta',
  'beton',
  'bez',
  'beztak',
  'bezva',
  'běžet',
  'běžící',
  'běžka',
  'běžkař',
  'běžně',
  'běžný',
  'béžový',
  'bf',
  'bg',
  'bh',
  'bi',
  'bible',
  'bicí',
  'bicykl',
  'bič',
  'bída',
  'bídný',
  'biftek',
  'bíle',
  'bílek',
  'bílina',
  'bilión',
  'bílý',
  'bio',
  'biolog',
  'biotop',
  'biskup',
  'bistro',
  'bit',
  'bít',
  'bití',
  'bitka',
  'bitva',
  'bj',
  'bk',
  'bl',
  'blábol',
  'bláha',
  'blaho',
  'blána',
  'blanka',
  'bláto',
  'blaze',
  'blázen',
  'blbě',
  'blbec',
  'blbost',
  'blbý',
  'bledě',
  'bledý',
  'blecha',
  'blesk',
  'blikat',
  'blízko',
  'blízký',
  'blížit',
  'blok',
  'blond',
  'blud',
  'bludný',
  'blues',
  'blůza',
  'bm',
  'bmw',
  'bn',
  'bo',
  'bob',
  'bobek',
  'bobr',
  'bobule',
  'boční',
  'bod',
  'bodat',
  'bodový',
  'boháč',
  'bohatě',
  'bohatý',
  'bohdan',
  'bohyně',
  'boj',
  'bojový',
  'bok',
  'boka',
  'bolavý',
  'bolek',
  'bolest',
  'bolet',
  'bomba',
  'bon',
  'bonbón',
  'bond',
  'bonus',
  'bor',
  'borec',
  'borový',
  'bořit',
  'bosna',
  'bosý',
  'bota',
  'bouda',
  'boule',
  'bourat',
  'bouře',
  'bouřit',
  'bouřka',
  'box',
  'boxer',
  'božena',
  'boží',
  'božský',
  'bp',
  'bq',
  'br',
  'brada',
  'bradka',
  'brácha',
  'brána',
  'bránit',
  'branka',
  'branný',
  'branže',
  'bráška',
  'brašna',
  'brát',
  'bratr',
  'brázda',
  'brdy',
  'brečet',
  'brit',
  'brňan',
  'brnění',
  'brno',
  'brod',
  'brodit',
  'brok',
  'brom',
  'bróm',
  'bronz',
  'brouk',
  'bručet',
  'bruno',
  'brusle',
  'brutus',
  'brva',
  'brýle',
  'brzda',
  'brzdit',
  'brzký',
  'brzy',
  'břeh',
  'břevno',
  'březen',
  'břicho',
  'břímě',
  'bříško',
  'břišní',
  'břitva',
  'bříza',
  'bs',
  'bt',
  'bu',
  'buben',
  'bublat',
  'buď',
  'budík',
  'budit',
  'budiž',
  'budka',
  'budova',
  'buďto',
  'budvar',
  'bufet',
  'bůh',
  'bůhví',
  'buchta',
  'bujarý',
  'bujný',
  'buk',
  'bukový',
  'buldok',
  'bulvár',
  'bum',
  'bunda',
  'buňka',
  'bunkr',
  'burza',
  'bušení',
  'bušit',
  'butik',
  'bv',
  'bw',
  'bx',
  'by',
  'býčí',
  'bydlet',
  'býk',
  'bylina',
  'bysta',
  'bystrý',
  'byt',
  'byť',
  'být',
  'bytí',
  'bytost',
  'bytový',
  'bývalý',
  'bývat',
  'bz',
  'bzučet',
  'c',
  'cár',
  'carský',
  'cb',
  'cc',
  'cca',
  'ccc',
  'cccc',
  'cd',
  'cde',
  'ce',
  'cecil',
  'cedule',
  'cech',
  'cela',
  'celek',
  'celer',
  'celkem',
  'celní',
  'celník',
  'celý',
  'cement',
  'cena',
  'ceněný',
  'ceník',
  'cenit',
  'cenný',
  'cenově',
  'cenový',
  'cesta',
  'céva',
  'cévní',
  'cf',
  'cg',
  'ci',
  'cibule',
  'cihla',
  'cikán',
  'cíl',
  'cíleně',
  'cílený',
  'cílový',
  'cín',
  'cinkat',
  'cíp',
  'církev',
  'cirkus',
  'císař',
  'cit',
  'citace',
  'citát',
  'cítění',
  'cítit',
  'citově',
  'citový',
  'citron',
  'city',
  'civět',
  'civil',
  'cívka',
  'cizí',
  'cizina',
  'cj',
  'ck',
  'cl',
  'clo',
  'clona',
  'cm',
  'cn',
  'co',
  'coby',
  'cokoli',
  'cop',
  'copak',
  'cosi',
  'couvat',
  'což',
  'cože',
  'cožpak',
  'cp',
  'cpát',
  'cq',
  'cr',
  'cs',
  'csc',
  'ct',
  'ctít',
  'ctitel',
  'ctnost',
  'cu',
  'cukat',
  'cukr',
  'cv',
  'cvičit',
  'cvičný',
  'cvik',
  'cvok',
  'cvrček',
  'cw',
  'cx',
  'cy',
  'cyklus',
  'cyril',
  'cz',
  'čaj',
  'čajový',
  'čáp',
  'čapek',
  'čapka',
  'čára',
  'čárka',
  'čárový',
  'čáry',
  'čas',
  'časně',
  'časný',
  'časově',
  'časový',
  'část',
  'částka',
  'často',
  'častý',
  'čau',
  'čd',
  'čech',
  'čechy',
  'čekání',
  'čekat',
  'čeleď',
  'čelist',
  'čelit',
  'čelní',
  'čelný',
  'čelo',
  'čenich',
  'čep',
  'čepec',
  'čepel',
  'čepice',
  'čepro',
  'čerň',
  'černá',
  'černě',
  'černý',
  'čerpat',
  'čert',
  'čertův',
  'červ',
  'červen',
  'červeň',
  'česat',
  'česko',
  'česky',
  'český',
  'česle',
  'česnek',
  'čest',
  'čestně',
  'čestný',
  'češka',
  'četa',
  'četba',
  'četník',
  'četný',
  'čez',
  'či',
  'čí',
  'čidlo',
  'číhat',
  'čich',
  'čile',
  'čili',
  'čilý',
  'čin',
  'čína',
  'číňan',
  'činit',
  'činný',
  'činžák',
  'čip',
  'čipový',
  'čirý',
  'číslo',
  'číst',
  'čistě',
  'čistit',
  'čistka',
  'čistý',
  'číše',
  'čišet',
  'číšník',
  'čítač',
  'čítat',
  'čl',
  'článek',
  'člen',
  'členit',
  'členka',
  'člověk',
  'člun',
  'čnb',
  'čnít',
  'čočka',
  'čr',
  'čro',
  'čs',
  'čsa',
  'čsn',
  'čt',
  'čtečka',
  'čtenář',
  'čtení',
  'čtený',
  'čtk',
  'čtvrt',
  'čtvrť',
  'čtvrtý',
  'čtyři',
  'čtyřka',
  'čumák',
  'čumět',
  'čvut',
  'd',
  'ďábel',
  'dabing',
  'ďáblův',
  'dagmar',
  'dakar',
  'dál',
  'dále',
  'dalece',
  'daleko',
  'daleký',
  'dálka',
  'dálný',
  'další',
  'dáma',
  'dámský',
  'damu',
  'dan',
  'daň',
  'dán',
  'dana',
  'daněk',
  'daniel',
  'daný',
  'dar',
  'dárce',
  'dárek',
  'dařit',
  'dáseň',
  'dáša',
  'dát',
  'data',
  'datový',
  'datum',
  'dav',
  'dávat',
  'david',
  'dávka',
  'dávno',
  'dávný',
  'db',
  'dbát',
  'dc',
  'dcera',
  'dcerka',
  'dd',
  'ddd',
  'dddd',
  'de',
  'debata',
  'debut',
  'děcko',
  'děčín',
  'děd',
  'děda',
  'dědek',
  'dědic',
  'dědit',
  'def',
  'defekt',
  'dech',
  'děj',
  'dějiny',
  'dějový',
  'deka',
  'dekáda',
  'děkan',
  'dekret',
  'dělat',
  'dělba',
  'dělení',
  'dělený',
  'delfín',
  'dělicí',
  'delikt',
  'dělit',
  'délka',
  'dělník',
  'dělo',
  'dělový',
  'delta',
  'demise',
  'démon',
  'den',
  'dění',
  'deník',
  'denis',
  'denisa',
  'denně',
  'denní',
  'depeše',
  'depo',
  'děravý',
  'derby',
  'děs',
  'desátý',
  'deset',
  'děsit',
  'děsivě',
  'děsivý',
  'deska',
  'desky',
  'děsně',
  'děsný',
  'déšť',
  'detail',
  'dětský',
  'devátý',
  'děvče',
  'devět',
  'dezert',
  'df',
  'dg',
  'dh',
  'di',
  'dialog',
  'diana',
  'diář',
  'dieta',
  'dietní',
  'dík',
  'diktát',
  'díky',
  'díl',
  'dílčí',
  'dílek',
  'dilema',
  'dílko',
  'dílna',
  'dílo',
  'dioda',
  'diplom',
  'dipól',
  'díra',
  'dírka',
  'disk',
  'disko',
  'dít',
  'dita',
  'dítě',
  'dítko',
  'div',
  'divák',
  'dívat',
  'dívčí',
  'dívčin',
  'diviš',
  'divit',
  'divize',
  'dívka',
  'divný',
  'divoce',
  'divoch',
  'divoký',
  'divý',
  'dj',
  'dk',
  'dl',
  'dlaň',
  'dlažba',
  'dle',
  'dlít',
  'dlouhá',
  'dlouho',
  'dlouhý',
  'dlouze',
  'dluh',
  'dlužný',
  'dm',
  'dn',
  'dna',
  'dnes',
  'dneska',
  'dnešek',
  'dnešní',
  'dno',
  'do',
  'doba',
  'dobový',
  'dobrá',
  'dobrák',
  'dobrat',
  'dobro',
  'dobrý',
  'dobře',
  'dobýt',
  'dobytí',
  'doc',
  'docela',
  'docent',
  'dočíst',
  'dodaný',
  'dodat',
  'dodnes',
  'dogma',
  'dohad',
  'dohled',
  'dohnal',
  'dohnat',
  'dohoda',
  'dohrát',
  'dojatý',
  'dojem',
  'dojet',
  'dojetí',
  'dojíst',
  'dojít',
  'dok',
  'doklad',
  'dokola',
  'doktor',
  'dokud',
  'dolar',
  'dole',
  'doleva',
  'dolít',
  'dolní',
  'dolů',
  'dom',
  'dóm',
  'doma',
  'domácí',
  'domek',
  'doména',
  'domov',
  'domů',
  'don',
  'donést',
  'dopad',
  'doping',
  'dopis',
  'dopít',
  'dopřát',
  'dopsat',
  'dora',
  'dorost',
  'dort',
  'dorůst',
  'dosah',
  'dospět',
  'dost',
  'dostál',
  'dostat',
  'dostát',
  'dostih',
  'dosud',
  'dotace',
  'dotaz',
  'doteď',
  'dotek',
  'dotyk',
  'doufat',
  'doupě',
  'doušek',
  'dovézt',
  'dovoz',
  'dozadu',
  'doznat',
  'dozor',
  'dozrát',
  'dožít',
  'dp',
  'dph',
  'dq',
  'dr',
  'dračí',
  'dráha',
  'draho',
  'drahý',
  'drak',
  'drama',
  'dráp',
  'drát',
  'drátek',
  'dravec',
  'dravý',
  'draze',
  'dražba',
  'drážka',
  'drážní',
  'drb',
  'drbat',
  'drcený',
  'drda',
  'dres',
  'drn',
  'drobné',
  'drobný',
  'drožka',
  'drsně',
  'drsný',
  'drť',
  'drtit',
  'drtivý',
  'drůbež',
  'druh',
  'druhý',
  'družka',
  'drze',
  'drzost',
  'drzý',
  'držák',
  'držení',
  'držený',
  'držet',
  'držící',
  'dřeň',
  'dřepět',
  'dřevo',
  'dřez',
  'dřímat',
  'dřina',
  'dřít',
  'dříví',
  'ds',
  'dt',
  'du',
  'dub',
  'duben',
  'dubový',
  'duda',
  'duel',
  'duha',
  'duhový',
  'duch',
  'důchod',
  'důkaz',
  'dukla',
  'důl',
  'důlek',
  'důlní',
  'dům',
  'dumat',
  'duna',
  'dunaj',
  'dunění',
  'dunět',
  'duo',
  'dupat',
  'dur',
  'důraz',
  'dusík',
  'dusit',
  'dusno',
  'dusný',
  'duša',
  'dušan',
  'duše',
  'dušek',
  'dušený',
  'dutina',
  'dutý',
  'důvěra',
  'důvod',
  'dv',
  'dva',
  'dvacet',
  'dvd',
  'dveře',
  'dveřní',
  'dvířka',
  'dvojče',
  'dvojí',
  'dvojka',
  'dvorec',
  'dvorek',
  'dvorní',
  'dvořan',
  'dvůr',
  'dw',
  'dx',
  'dy',
  'dýchat',
  'dyje',
  'dýka',
  'dým',
  'dýmka',
  'dynamo',
  'dýně',
  'dz',
  'džbán',
  'džin',
  'džíny',
  'džíp',
  'džus',
  'e',
  'e-mail',
  'eb',
  'eben',
  'ec',
  'ed',
  'eda',
  'eden',
  'edice',
  'ediční',
  'editor',
  'edna',
  'eduard',
  'ee',
  'eee',
  'eeee',
  'ef',
  'efekt',
  'efg',
  'eg',
  'ego',
  'egon',
  'egypt',
  'eh',
  'ehm',
  'echo',
  'ei',
  'ej',
  'ek',
  'ekolog',
  'ekonom',
  'el',
  'elán',
  'elf',
  'eli',
  'eliška',
  'elita',
  'elitní',
  'ella',
  'em',
  'ema',
  'email',
  'emil',
  'emise',
  'emma',
  'emoce',
  'emoční',
  'en',
  'entita',
  'enzym',
  'eo',
  'ep',
  'epocha',
  'epos',
  'eq',
  'er',
  'éra',
  'erb',
  'erik',
  'erika',
  'eroze',
  'erupce',
  'es',
  'esej',
  'esence',
  'esesák',
  'eso',
  'ester',
  'et',
  'eta',
  'etan',
  'etanol',
  'etapa',
  'éter',
  'etický',
  'etika',
  'eu',
  'eur',
  'euro',
  'ev',
  'eva',
  'evka',
  'evropa',
  'evžen',
  'ew',
  'ex',
  'exces',
  'exil',
  'expert',
  'export',
  'extra',
  'extrém',
  'ey',
  'ez',
  'f',
  'fabia',
  'facka',
  'fádní',
  'fajn',
  'fakt',
  'faktor',
  'fáma',
  'famu',
  'fanda',
  'fandit',
  'fantom',
  'fara',
  'farář',
  'farma',
  'farmář',
  'farní',
  'farník',
  'fasáda',
  'faul',
  'fauna',
  'faust',
  'fax',
  'fáze',
  'fazole',
  'fázový',
  'fb',
  'fc',
  'fd',
  'fe',
  'felix',
  'fén',
  'fena',
  'fér',
  'ferda',
  'férový',
  'ff',
  'fff',
  'ffff',
  'fg',
  'fgh',
  'fh',
  'fi',
  'fia',
  'fiala',
  'fialka',
  'fiasko',
  'fiat',
  'fidel',
  'fifa',
  'fígl',
  'figura',
  'fík',
  'fikce',
  'fikční',
  'filip',
  'film',
  'filmař',
  'filtr',
  'finále',
  'finiš',
  'finsko',
  'finský',
  'finta',
  'firma',
  'fišer',
  'fit',
  'fix',
  'fixace',
  'fixní',
  'fj',
  'fjord',
  'fk',
  'fl',
  'flaška',
  'flek',
  'flétna',
  'flora',
  'flóra',
  'fm',
  'fn',
  'fo',
  'focení',
  'fólie',
  'folk',
  'fond',
  'fór',
  'ford',
  'forma',
  'forman',
  'formát',
  'fort',
  'fórum',
  'fosfor',
  'foťák',
  'fotbal',
  'fotit',
  'fotka',
  'foto',
  'foton',
  'foukat',
  'fox',
  'fp',
  'fq',
  'fr',
  'frajer',
  'frak',
  'frakce',
  'franta',
  'fraška',
  'fráze',
  'freska',
  'fréza',
  'fronta',
  'fs',
  'ft',
  'fu',
  'fuj',
  'fuk',
  'funět',
  'funkce',
  'fůra',
  'furt',
  'fúze',
  'fv',
  'fw',
  'fx',
  'fy',
  'fyzik',
  'fyzika',
  'fz',
  'g',
  'garant',
  'garáž',
  'garda',
  'gauč',
  'gb',
  'gc',
  'gd',
  'ge',
  'gejzír',
  'gel',
  'génius',
  'genom',
  'genový',
  'geolog',
  'gesto',
  'gf',
  'gg',
  'ggg',
  'gggg',
  'gh',
  'ghi',
  'gi',
  'gigant',
  'gj',
  'gk',
  'gl',
  'glóbus',
  'glosa',
  'gm',
  'gn',
  'go',
  'gól',
  'golf',
  'gólman',
  'gorila',
  'gotika',
  'gp',
  'gps',
  'gq',
  'gr',
  'graf',
  'grafik',
  'grál',
  'gram',
  'granát',
  'grant',
  'gregor',
  'gril',
  'groš',
  'grupa',
  'gs',
  'gt',
  'gu',
  'guláš',
  'guma',
  'gumový',
  'guru',
  'gustav',
  'gusto',
  'gv',
  'gw',
  'gx',
  'gy',
  'gz',
  'h',
  'hábit',
  'háček',
  'had',
  'hádat',
  'hadí',
  'hadice',
  'hádka',
  'hadr',
  'hadry',
  'hadřík',
  'háj',
  'hájit',
  'hajný',
  'hák',
  'hala',
  'halda',
  'haléř',
  'halit',
  'haló',
  'halový',
  'hamlet',
  'hana',
  'hanák',
  'hanba',
  'hangár',
  'hanka',
  'hanuš',
  'harfa',
  'hasič',
  'hasit',
  'hašek',
  'háv',
  'havaj',
  'havana',
  'havel',
  'havran',
  'hazard',
  'házená',
  'házení',
  'házet',
  'hb',
  'hbitě',
  'hbitý',
  'hc',
  'hd',
  'hdp',
  'he',
  'hebký',
  'hej',
  'hejno',
  'hektar',
  'hele',
  'helena',
  'helma',
  'hemžit',
  'herec',
  'herna',
  'herně',
  'herní',
  'hezky',
  'hezký',
  'hf',
  'hg',
  'hh',
  'hhh',
  'hhhh',
  'hi',
  'hij',
  'hip',
  'hit',
  'hj',
  'hk',
  'hl',
  'hlad',
  'hladce',
  'hladit',
  'hladký',
  'hlady',
  'hlas',
  'hlásat',
  'hlásek',
  'hlásit',
  'hláška',
  'hlava',
  'hlaveň',
  'hlavně',
  'hlavní',
  'hle',
  'hledač',
  'hledat',
  'hledět',
  'hlídač',
  'hlídat',
  'hlídka',
  'hlína',
  'hliník',
  'hlíza',
  'hloub',
  'hlt',
  'hltat',
  'hlučně',
  'hluchý',
  'hluk',
  'hlupák',
  'hm',
  'hmat',
  'hmmm',
  'hmota',
  'hmotný',
  'hmyz',
  'hn',
  'hnací',
  'hnát',
  'hned',
  'hnědý',
  'hněv',
  'hněvat',
  'hnít',
  'hnízdo',
  'hnout',
  'hnutí',
  'ho',
  'hobit',
  'hod',
  'hodina',
  'hodiny',
  'hodit',
  'hodlat',
  'hodně',
  'hodný',
  'hody',
  'hoch',
  'hojit',
  'hojně',
  'hojný',
  'hokej',
  'hold',
  'holení',
  'holič',
  'holík',
  'holit',
  'holka',
  'holt',
  'holub',
  'holý',
  'homo',
  'hon',
  'honba',
  'honem',
  'honit',
  'honza',
  'honzík',
  'hop',
  'hora',
  'horal',
  'horda',
  'horko',
  'horký',
  'hormon',
  'horní',
  'horník',
  'horor',
  'horský',
  'horst',
  'hořce',
  'hořčík',
  'hořet',
  'hořící',
  'hořký',
  'host',
  'hostit',
  'hostýn',
  'hotel',
  'hotový',
  'houba',
  'houf',
  'houpat',
  'house',
  'houska',
  'housle',
  'houští',
  'hovězí',
  'hovor',
  'hp',
  'hq',
  'hr',
  'hra',
  'hrabat',
  'hrabě',
  'hrací',
  'hráč',
  'hračka',
  'hráčka',
  'hrad',
  'hradba',
  'hrádek',
  'hradit',
  'hradní',
  'hrách',
  'hrana',
  'hraní',
  'hranol',
  'hraný',
  'hrášek',
  'hrát',
  'hrátky',
  'hrávat',
  'hravě',
  'hravý',
  'hráz',
  'hrdě',
  'hrdina',
  'hrdlo',
  'hrdost',
  'hrdý',
  'hrnec',
  'hrnek',
  'hrnout',
  'hrob',
  'hrobka',
  'hroch',
  'hrom',
  'hrot',
  'hrouda',
  'hrozba',
  'hrozen',
  'hrozit',
  'hrozně',
  'hrozný',
  'hrst',
  'hrstka',
  'hrubě',
  'hrubý',
  'hruď',
  'hrudní',
  'hruška',
  'hrůza',
  'hrůzný',
  'hřát',
  'hřbet',
  'hřeb',
  'hřebec',
  'hřeben',
  'hřebík',
  'hřib',
  'hříbě',
  'hříčka',
  'hřídel',
  'hřích',
  'hříšný',
  'hřiště',
  'hříva',
  'hs',
  'ht',
  'hu',
  'huba',
  'hubený',
  'hubert',
  'hučení',
  'hučet',
  'hudba',
  'hugo',
  'hukot',
  'hůl',
  'hůlka',
  'humor',
  'huňatý',
  'hurá',
  'hus',
  'husa',
  'husí',
  'husita',
  'hustě',
  'hustý',
  'husův',
  'huť',
  'hutní',
  'hutný',
  'hv',
  'hvězda',
  'hvizd',
  'hvozd',
  'hw',
  'hx',
  'hy',
  'hýbat',
  'hybrid',
  'hýčkat',
  'hýl',
  'hymna',
  'hynek',
  'hynout',
  'hýřit',
  'hýždě',
  'hz',
  'ch',
  'cha',
  'chabě',
  'chabý',
  'chaos',
  'chápat',
  'charta',
  'chata',
  'chatka',
  'chatrč',
  'che',
  'cheb',
  'chemie',
  'chemik',
  'chlad',
  'chlap',
  'chléb',
  'chlév',
  'chlór',
  'chlum',
  'chlup',
  'chmel',
  'chod',
  'chodba',
  'chodec',
  'chodit',
  'chopit',
  'chór',
  'chorál',
  'chorý',
  'choť',
  'chov',
  'chovat',
  'chrám',
  'chrlit',
  'chrup',
  'chřest',
  'chtěně',
  'chtěný',
  'chtíč',
  'chtít',
  'chudák',
  'chudý',
  'chuť',
  'chutný',
  'chůva',
  'chůze',
  'chvála',
  'chvat',
  'chvění',
  'chvět',
  'chvíle',
  'chyba',
  'chybět',
  'chybit',
  'chybný',
  'chýlit',
  'chýše',
  'chytat',
  'chytit',
  'chytrý',
  'chytře',
  'i',
  'ib',
  'ibm',
  'ic',
  'id',
  'ideál',
  'ideový',
  'idiot',
  'idol',
  'idyla',
  'ie',
  'if',
  'ig',
  'ignác',
  'igor',
  'ih',
  'ihned',
  'ii',
  'iii',
  'iiii',
  'ij',
  'ijk',
  'ik',
  'ikona',
  'il',
  'ilona',
  'im',
  'impuls',
  'in',
  'ind',
  'index',
  'indián',
  'indie',
  'info',
  'ing',
  'inkaso',
  'intel',
  'inu',
  'invaze',
  'io',
  'iont',
  'ip',
  'iq',
  'ir',
  'irena',
  'ironie',
  'irsko',
  'irský',
  'is',
  'iso',
  'it',
  'ital',
  'iu',
  'iv',
  'iva',
  'ivan',
  'ivana',
  'iveta',
  'ivo',
  'iw',
  'ix',
  'iy',
  'iz',
  'j',
  'já',
  'jablko',
  'jabloň',
  'jacek',
  'jádro',
  'jágr',
  'jaguár',
  'jahoda',
  'jachta',
  'jak',
  'jako',
  'jakoby',
  'jakost',
  'jakož',
  'jakpak',
  'jaksi',
  'jakub',
  'jaký',
  'jakýsi',
  'jalta',
  'jáma',
  'jamka',
  'jan',
  'ján',
  'jana',
  'janov',
  'jára',
  'jarda',
  'jarní',
  'jaro',
  'jas',
  'jásat',
  'jasně',
  'jasno',
  'jasný',
  'jásot',
  'játra',
  'javor',
  'jazyk',
  'jb',
  'jc',
  'jd',
  'jdoucí',
  'je',
  'jé',
  'ječet',
  'ječmen',
  'jed',
  'jeden',
  'jedině',
  'jediný',
  'jedle',
  'jedlý',
  'jednak',
  'jednat',
  'jednou',
  'jehla',
  'jeho',
  'její',
  'jejich',
  'jekot',
  'jelen',
  'jelení',
  'jemně',
  'jemný',
  'jen',
  'jeník',
  'jenom',
  'jenž',
  'jenže',
  'jeřáb',
  'jesle',
  'jestli',
  'ještě',
  'ještěd',
  'ještěr',
  'jet',
  'jev',
  'jevit',
  'jez',
  'jezdec',
  'jezdit',
  'jezero',
  'ježek',
  'jf',
  'jg',
  'jh',
  'ji',
  'jícen',
  'jídlo',
  'jih',
  'jíl',
  'jilm',
  'jim',
  'jinak',
  'jinam',
  'jinde',
  'jindra',
  'jindy',
  'jiný',
  'jirka',
  'jiří',
  'jiřík',
  'jiřina',
  'jiskra',
  'jíst',
  'jistě',
  'jistý',
  'jít',
  'jitka',
  'jitro',
  'jízda',
  'jízdné',
  'jízdní',
  'jizera',
  'jizva',
  'již',
  'jižně',
  'jižní',
  'jj',
  'jjj',
  'jjjj',
  'jk',
  'jkl',
  'jl',
  'jm',
  'jmění',
  'jméno',
  'jn',
  'jo',
  'jó',
  'jód',
  'jóga',
  'jogurt',
  'jonáš',
  'josef',
  'jošt',
  'jozef',
  'jp',
  'jq',
  'jr',
  'js',
  'jt',
  'ju',
  'judo',
  'judr',
  'julie',
  'jv',
  'jw',
  'jx',
  'jy',
  'jz',
  'k',
  'kabát',
  'kabel',
  'kabina',
  'kácení',
  'kácet',
  'kacíř',
  'káča',
  'káď',
  'kadaň',
  'kadeř',
  'kadet',
  'kadlec',
  'kádr',
  'kafe',
  'kafka',
  'kachna',
  'kája',
  'kajuta',
  'kakao',
  'kaktus',
  'kal',
  'kalibr',
  'kalich',
  'kalný',
  'kaluž',
  'kam',
  'kámen',
  'kamení',
  'kamera',
  'kamil',
  'kamila',
  'kamión',
  'kamna',
  'kámoš',
  'kampa',
  'kampaň',
  'kamsi',
  'kanál',
  'kánoe',
  'kanón',
  'kaňon',
  'kantor',
  'kap',
  'kapat',
  'kapela',
  'kapka',
  'kapku',
  'kaplan',
  'kaple',
  'kapota',
  'kapr',
  'kapsa',
  'kapsář',
  'kapuce',
  'kára',
  'kárat',
  'karel',
  'karla',
  'karlův',
  'karma',
  'kárný',
  'karta',
  'kartáč',
  'kartel',
  'karton',
  'kasa',
  'kasal',
  'kasino',
  'kasta',
  'kaše',
  'kašel',
  'kašlat',
  'kašna',
  'kašpar',
  'kaštan',
  'kat',
  'kát',
  'káťa',
  'katka',
  'kauce',
  'kauza',
  'káva',
  'kavkaz',
  'kávový',
  'kaz',
  'kázání',
  'kázat',
  'kázeň',
  'kazeta',
  'kazit',
  'každý',
  'kb',
  'kbelík',
  'kc',
  'kč',
  'kd',
  'kde',
  'kdekdo',
  'kdepak',
  'kdesi',
  'kdežto',
  'kdo',
  'kdopak',
  'kdosi',
  'kdoví',
  'kdy',
  'kdyby',
  'kdysi',
  'když',
  'ke',
  'kec',
  'kecat',
  'kečup',
  'kel',
  'kelt',
  'kemp',
  'keňa',
  'keř',
  'keřík',
  'kéž',
  'kf',
  'kg',
  'kh',
  'ki',
  'kilo',
  'kino',
  'kj',
  'kk',
  'kkk',
  'kkkk',
  'kl',
  'klacek',
  'klad',
  'kláda',
  'kladně',
  'kladný',
  'klam',
  'klamat',
  'klamný',
  'klan',
  'klanět',
  'klání',
  'klapat',
  'klapka',
  'klára',
  'klas',
  'klasik',
  'klást',
  'klaun',
  'klavír',
  'klec',
  'klečet',
  'klenba',
  'klenot',
  'klep',
  'klepat',
  'klesat',
  'kleště',
  'klíč',
  'klíček',
  'klička',
  'klid',
  'klidně',
  'klidný',
  'klient',
  'klika',
  'klima',
  'klín',
  'klip',
  'klisna',
  'klišé',
  'klíště',
  'klít',
  'klm',
  'klokan',
  'klon',
  'klonit',
  'klopa',
  'kloub',
  'klub',
  'klubko',
  'kluk',
  'klus',
  'klusat',
  'kluzký',
  'km',
  'km/h',
  'kmen',
  'kmín',
  'kmínek',
  'kmitat',
  'kmotr',
  'kn',
  'kněz',
  'kněžna',
  'kniha',
  'knír',
  'knírek',
  'kníže',
  'knížka',
  'knižní',
  'ko',
  'kobka',
  'kobyla',
  'kocour',
  'kočár',
  'kočí',
  'kočičí',
  'kočka',
  'kód',
  'kodaň',
  'kodex',
  'kofein',
  'kohout',
  'kochat',
  'kóje',
  'kojit',
  'kokpit',
  'koks',
  'koktat',
  'koláč',
  'kolaps',
  'kolář',
  'koláž',
  'koleda',
  'kolega',
  'kolej',
  'kolem',
  'koleno',
  'kolik',
  'kolík',
  'kolín',
  'kolize',
  'kolmo',
  'kolmý',
  'kolo',
  'kolona',
  'kolos',
  'komár',
  'kombi',
  'kometa',
  'komik',
  'komiks',
  'komín',
  'komise',
  'komora',
  'kompas',
  'kompot',
  'koňak',
  'konání',
  'konaný',
  'konat',
  'koncil',
  'končit',
  'konec',
  'konev',
  'koník',
  'konopí',
  'koňský',
  'konto',
  'konvoj',
  'konzul',
  'konzum',
  'kop',
  'kopaná',
  'kopat',
  'kopec',
  'kopí',
  'kopie',
  'kopule',
  'kopyto',
  'koráb',
  'korál',
  'korba',
  'kord',
  'koroze',
  'koruna',
  'korýš',
  'koryto',
  'kořen',
  'koření',
  'kořist',
  'kos',
  'kosa',
  'kosmos',
  'kost',
  'kostel',
  'kostka',
  'kostní',
  'kostra',
  'kostým',
  'koš',
  'košík',
  'košile',
  'košťál',
  'koště',
  'kotě',
  'kotec',
  'kotel',
  'kotlík',
  'kotník',
  'kotouč',
  'kotva',
  'kotvit',
  'kouč',
  'koukal',
  'koukat',
  'koukej',
  'koule',
  'koupat',
  'koupě',
  'koupel',
  'koupit',
  'kouř',
  'kouřit',
  'kousat',
  'kousek',
  'kout',
  'koutek',
  'kouzlo',
  'kov',
  'kováč',
  'kování',
  'kovář',
  'kovboj',
  'kovový',
  'koza',
  'kozák',
  'kozel',
  'kozí',
  'kozlík',
  'kožený',
  'kožich',
  'kožní',
  'kp',
  'kq',
  'kr',
  'kra',
  'krab',
  'kráčet',
  'krádež',
  'krach',
  'kraj',
  'krajan',
  'krájet',
  'krajíc',
  'krajka',
  'krajně',
  'krajní',
  'král',
  'králík',
  'králův',
  'krám',
  'krámek',
  'kras',
  'krása',
  'kráska',
  'krásně',
  'krásný',
  'krást',
  'krát',
  'krátce',
  'kráter',
  'krátit',
  'krátký',
  'kráva',
  'krb',
  'krbový',
  'krčit',
  'krčma',
  'krční',
  'kreace',
  'kredit',
  'krédo',
  'krejčí',
  'krém',
  'kresba',
  'kréta',
  'krev',
  'krevní',
  'kritik',
  'krize',
  'krk',
  'krmení',
  'krmit',
  'krmivo',
  'krocan',
  'kroj',
  'krok',
  'krokev',
  'kromě',
  'krotit',
  'kroupa',
  'krov',
  'krtek',
  'kruci',
  'krůček',
  'kruh',
  'krunýř',
  'krůpěj',
  'krušný',
  'krutě',
  'krutý',
  'krvavý',
  'krycí',
  'krypta',
  'krysa',
  'kryt',
  'krýt',
  'krytí',
  'krytý',
  'křeč',
  'křeček',
  'křehký',
  'křen',
  'křeslo',
  'křest',
  'křičet',
  'křída',
  'křídlo',
  'křik',
  'křivka',
  'křivý',
  'kříž',
  'křížek',
  'křížit',
  'křoví',
  'křtít',
  'ks',
  'ksicht',
  'kšeft',
  'kštice',
  'kt',
  'kterak',
  'který',
  'ku',
  'kuba',
  'kubík',
  'kudy',
  'kufr',
  'kufřík',
  'kuchař',
  'kukla',
  'kůl',
  'kulatý',
  'kulhat',
  'kulič',
  'kulisa',
  'kulka',
  'kůlna',
  'kuloár',
  'kulový',
  'kult',
  'kumpán',
  'kůň',
  'kuna',
  'kupa',
  'kupé',
  'kupec',
  'kupit',
  'kupka',
  'kupní',
  'kupole',
  'kupón',
  'kúra',
  'kůra',
  'kuráž',
  'kurs',
  'kurýr',
  'kuřák',
  'kuře',
  'kuřecí',
  'kus',
  'kusý',
  'kutný',
  'kůže',
  'kužel',
  'kv',
  'kvádr',
  'kvark',
  'kvést',
  'květ',
  'květa',
  'květák',
  'květen',
  'květní',
  'kvílet',
  'kvítek',
  'kvóta',
  'kvůli',
  'kw',
  'kx',
  'ky',
  'kýbl',
  'kýč',
  'kyčel',
  'kyj',
  'kýl',
  'kynout',
  'kypr',
  'kysaný',
  'kyselý',
  'kyslík',
  'kýta',
  'kytara',
  'kytice',
  'kytka',
  'kývat',
  'kýžený',
  'kz',
  'l',
  'labe',
  'labský',
  'labuť',
  'laciný',
  'lačný',
  'lada',
  'láďa',
  'ladění',
  'laděný',
  'ladit',
  'ladný',
  'lágr',
  'laguna',
  'láhev',
  'laický',
  'laik',
  'lajna',
  'lak',
  'lákat',
  'lákavý',
  'lalok',
  'lama',
  'láma',
  'lámat',
  'lampa',
  'laň',
  'lán',
  'lanka',
  'lano',
  'lanový',
  'lapat',
  'lapit',
  'larva',
  'laser',
  'láska',
  'laskat',
  'laso',
  'lať',
  'lata',
  'latina',
  'laťka',
  'látka',
  'laura',
  'láva',
  'lavice',
  'lavina',
  'lávka',
  'lávový',
  'lázeň',
  'lázně',
  'lb',
  'lc',
  'lcd',
  'ld',
  'le',
  'lebka',
  'leccos',
  'leckdo',
  'leckdy',
  'leč',
  'léčba',
  'léčení',
  'léčit',
  'léčivo',
  'léčivý',
  'léčka',
  'led',
  'leda',
  'ledaže',
  'leden',
  'lední',
  'ledově',
  'ledový',
  'legie',
  'lehce',
  'lehko',
  'lehký',
  'lék',
  'lékař',
  'lekat',
  'lekce',
  'lektor',
  'lem',
  'len',
  'lena',
  'lenka',
  'lenost',
  'leo',
  'leona',
  'leoš',
  'lepení',
  'lepicí',
  'lepit',
  'les',
  'lesík',
  'lesk',
  'lesklý',
  'lesní',
  'lesník',
  'lest',
  'lešení',
  'let',
  'leták',
  'létání',
  'létat',
  'letec',
  'letět',
  'letící',
  'letitý',
  'letka',
  'letmo',
  'letmý',
  'letná',
  'letní',
  'léto',
  'letos',
  'letoun',
  'letový',
  'lev',
  'levice',
  'levně',
  'levný',
  'levý',
  'lezení',
  'lézt',
  'lež',
  'ležák',
  'ležet',
  'ležící',
  'lf',
  'lg',
  'lh',
  'lhář',
  'lhát',
  'lhůta',
  'li',
  'liána',
  'líbání',
  'líbat',
  'libeň',
  'líbit',
  'líbivý',
  'libo',
  'libor',
  'libost',
  'libra',
  'libuše',
  'libý',
  'líc',
  'líce',
  'lícní',
  'líčení',
  'líčit',
  'lid',
  'lída',
  'lidice',
  'lidoop',
  'lidově',
  'lidový',
  'lídr',
  'lidsky',
  'lidský',
  'liga',
  'ligový',
  'líh',
  'lichý',
  'liják',
  'likér',
  'lilie',
  'límec',
  'limit',
  'linda',
  'line',
  'líně',
  'linec',
  'linie',
  'link',
  'linka',
  'linout',
  'linux',
  'líný',
  'lípa',
  'lipno',
  'lipový',
  'lis',
  'list',
  'lístek',
  'listí',
  'lišit',
  'liška',
  'lišta',
  'lít',
  'lítat',
  'litera',
  'líto',
  'lítost',
  'litr',
  'líza',
  'lízat',
  'lj',
  'lk',
  'll',
  'lll',
  'llll',
  'lm',
  'lmn',
  'ln',
  'lněný',
  'lo',
  'loď',
  'loďka',
  'lodní',
  'lodyha',
  'logika',
  'logo',
  'lojza',
  'lokaj',
  'lokál',
  'loket',
  'lom',
  'lomený',
  'loňsko',
  'loňský',
  'lopata',
  'los',
  'losos',
  'lotr',
  'loučit',
  'loučka',
  'loudat',
  'louh',
  'louka',
  'loupež',
  'loutka',
  'louže',
  'lov',
  'lovec',
  'lovit',
  'lože',
  'lóže',
  'lp',
  'lpět',
  'lq',
  'lr',
  'ls',
  'lt',
  'lu',
  'lub',
  'luboš',
  'lucie',
  'lucka',
  'luční',
  'luděk',
  'ludvík',
  'luh',
  'luk',
  'luka',
  'lukáš',
  'lulu',
  'lumír',
  'lump',
  'luna',
  'lůno',
  'lup',
  'lupa',
  'lupič',
  'lupina',
  'lusk',
  'lustr',
  'luxus',
  'lůžko',
  'lužní',
  'lv',
  'lví',
  'lw',
  'lx',
  'ly',
  'lysá',
  'lysý',
  'lýtko',
  'lyžař',
  'lyže',
  'lz',
  'lze',
  'lžíce',
  'lžička',
  'lživý',
  'm',
  'macek',
  'mačkat',
  'madam',
  'maďar',
  'madlo',
  'mafián',
  'mafie',
  'mág',
  'magda',
  'magie',
  'magnát',
  'magnet',
  'mach',
  'mácha',
  'mail',
  'máj',
  'maják',
  'mající',
  'major',
  'májový',
  'mák',
  'makak',
  'makat',
  'makléř',
  'makro',
  'malba',
  'málek',
  'málem',
  'malér',
  'malina',
  'malíř',
  'málo',
  'malta',
  'malý',
  'máma',
  'mamka',
  'mamut',
  'mamutí',
  'mandát',
  'mandle',
  'manévr',
  'mango',
  'mání',
  'manka',
  'manko',
  'mantra',
  'manuál',
  'manžel',
  'mapa',
  'mapka',
  'mapový',
  'mara',
  'marcel',
  'marek',
  'margot',
  'marián',
  'marie',
  'mariin',
  'marka',
  'market',
  'markýz',
  'marně',
  'marný',
  'mars',
  'maršál',
  'marta',
  'martin',
  'marty',
  'marx',
  'marže',
  'máří',
  'mařit',
  'masa',
  'masakr',
  'masáž',
  'masér',
  'masitý',
  'masiv',
  'maska',
  'máslo',
  'masný',
  'maso',
  'masově',
  'masový',
  'mast',
  'mást',
  'mastný',
  'máša',
  'mašek',
  'mašina',
  'máta',
  'matčin',
  'matěj',
  'matek',
  'matice',
  'matka',
  'matně',
  'matný',
  'matouš',
  'matyáš',
  'mávat',
  'mayský',
  'mazaný',
  'mazat',
  'mazlit',
  'mb',
  'mc',
  'md',
  'mdloba',
  'mdlý',
  'me',
  'meč',
  'med',
  'měď',
  'měděný',
  'medik',
  'médium',
  'medový',
  'medúza',
  'medvěd',
  'mega',
  'megan',
  'mech',
  'měchýř',
  'mejdan',
  'měkce',
  'mekka',
  'měkký',
  'mela',
  'mělký',
  'meloun',
  'měna',
  'méně',
  'měnící',
  'měnit',
  'měnový',
  'menu',
  'měrný',
  'měření',
  'měřený',
  'měřicí',
  'měřící',
  'měřič',
  'měřit',
  'mesiáš',
  'měsíc',
  'město',
  'měšťan',
  'meta',
  'metan',
  'metoda',
  'metr',
  'metro',
  'mez',
  'mezera',
  'mezi',
  'mezní',
  'mezník',
  'mf',
  'mg',
  'mgr',
  'mh',
  'mhd',
  'mhz',
  'mi',
  'míč',
  'míček',
  'míhat',
  'michal',
  'míchat',
  'míjet',
  'mikina',
  'mikrob',
  'milá',
  'milada',
  'milan',
  'milán',
  'mile',
  'míle',
  'milena',
  'milice',
  'milión',
  'milník',
  'milost',
  'miloš',
  'milý',
  'mim',
  'mimino',
  'mimo',
  'mimoto',
  'min',
  'míň',
  'mina',
  'mince',
  'mínění',
  'míněný',
  'mini',
  'mínit',
  'minout',
  'minule',
  'minulý',
  'minus',
  'minuta',
  'mír',
  'míra',
  'mirek',
  'mirka',
  'mírně',
  'mírnit',
  'mírný',
  'mírový',
  'mířící',
  'mířit',
  'mísa',
  'mise',
  'misie',
  'mísit',
  'miska',
  'miss',
  'místek',
  'místně',
  'místní',
  'místo',
  'mistr',
  'místy',
  'míša',
  'mít',
  'mívat',
  'mix',
  'míza',
  'mizera',
  'mizet',
  'mizivý',
  'mj',
  'mk',
  'ml',
  'mládě',
  'mládež',
  'mládí',
  'mladík',
  'mladý',
  'mlátit',
  'mlčení',
  'mlčet',
  'mlčky',
  'mléčný',
  'mléko',
  'mletý',
  'mlha',
  'mlhavý',
  'mlít',
  'mluva',
  'mluvčí',
  'mluvit',
  'mlýn',
  'mlynář',
  'mlžný',
  'mm',
  'mmm',
  'mmmm',
  'mn',
  'mnich',
  'mno',
  'mnohdy',
  'mnohem',
  'mnoho',
  'mnohý',
  'mnout',
  'množit',
  'mo',
  'mobil',
  'moc',
  'moci',
  'mocně',
  'mocný',
  'močál',
  'mód',
  'móda',
  'model',
  'modla',
  'modlit',
  'módní',
  'modrý',
  'modř',
  'modře',
  'modul',
  'mohyla',
  'mok',
  'mokro',
  'mokrý',
  'mokřad',
  'mol',
  'molo',
  'moment',
  'monika',
  'montáž',
  'mor',
  'morava',
  'morče',
  'morový',
  'moře',
  'mořský',
  'most',
  'mostní',
  'motat',
  'motel',
  'motiv',
  'motol',
  'motor',
  'motto',
  'motýl',
  'moudrý',
  'moudře',
  'moucha',
  'mouka',
  'movitý',
  'mozek',
  'možná',
  'možný',
  'mp',
  'mq',
  'mr',
  'mráček',
  'mračit',
  'mračno',
  'mrak',
  'mramor',
  'mrav',
  'mravní',
  'mravný',
  'mráz',
  'mrazák',
  'mrazit',
  'mrkat',
  'mrkev',
  'mrňavý',
  'mrštit',
  'mrtvý',
  'mrzák',
  'mrzet',
  'mrzutě',
  'mrzutý',
  'mříž',
  'mřížka',
  'ms',
  'msta',
  'mstít',
  'mš',
  'mše',
  'mt',
  'mu',
  'mučit',
  'mudr',
  'mudrc',
  'mucha',
  'můj',
  'muka',
  'mukl',
  'mula',
  'mumie',
  'mumlat',
  'munice',
  'můra',
  'muset',
  'musil',
  'můstek',
  'muška',
  'mušle',
  'mutace',
  'mutant',
  'múza',
  'muzeum',
  'muzika',
  'muž',
  'mužík',
  'mužný',
  'mužský',
  'mužův',
  'mv',
  'mw',
  'mx',
  'my',
  'myčka',
  'mýdlo',
  'mýlit',
  'mylně',
  'mylný',
  'mys',
  'mysl',
  'myslet',
  'mystik',
  'myš',
  'myší',
  'myška',
  'mýt',
  'mytí',
  'mýtina',
  'mýtné',
  'mýto',
  'mýtus',
  'mz',
  'mzda',
  'mzdový',
  'mžik',
  'n',
  'nabít',
  'nabitý',
  'náboj',
  'nábor',
  'nabrat',
  'nabýt',
  'nabytí',
  'nabytý',
  'nacpat',
  'nácvik',
  'nač',
  'načase',
  'načež',
  'náčiní',
  'náčrt',
  'nad',
  'naďa',
  'nadace',
  'nadále',
  'nadání',
  'nadaný',
  'nadát',
  'nádech',
  'naděje',
  'nádoba',
  'nádobí',
  'ňadra',
  'ňadro',
  'nádrž',
  'nadto',
  'nafta',
  'nahlas',
  'náhle',
  'náhled',
  'náhlý',
  'nahnat',
  'náhoda',
  'náhon',
  'nahoru',
  'nahoře',
  'nahota',
  'nahrát',
  'nahý',
  'náchod',
  'naivně',
  'naivní',
  'najatý',
  'nájem',
  'najet',
  'najevo',
  'nájezd',
  'najíst',
  'najít',
  'nákaza',
  'náklad',
  'nákres',
  'nákup',
  'nálada',
  'nálet',
  'nálev',
  'nalevo',
  'nález',
  'nalézt',
  'nalít',
  'nálož',
  'nám',
  'námaha',
  'námět',
  'nanést',
  'nános',
  'naoko',
  'naopak',
  'nápad',
  'napětí',
  'nápis',
  'napít',
  'náplň',
  'naplno',
  'nápoj',
  'nápor',
  'např',
  'napřed',
  'napříč',
  'napsat',
  'napůl',
  'naráz',
  'náraz',
  'narcis',
  'národ',
  'nárok',
  'nároží',
  'naruby',
  'náruč',
  'náručí',
  'narůst',
  'nárůst',
  'nářadí',
  'nářečí',
  'nářek',
  'násada',
  'nasát',
  'násep',
  'náskok',
  'nastat',
  'nástup',
  'náš',
  'nať',
  'nátěr',
  'nátlak',
  'nato',
  'natož',
  'natřít',
  'naučit',
  'naučný',
  'nauka',
  'nával',
  'navěky',
  'náves',
  'navést',
  'navíc',
  'návod',
  'návrat',
  'návrh',
  'navrch',
  'návrší',
  'návyk',
  'navždy',
  'nazdar',
  'název',
  'náznak',
  'názor',
  'nazpět',
  'nazvat',
  'naživu',
  'nb',
  'nc',
  'nd',
  'ne',
  'né',
  'nebe',
  'nebesa',
  'nebo',
  'nebohý',
  'neboli',
  'neboť',
  'něco',
  'nečas',
  'něčí',
  'neděle',
  'neduh',
  'negace',
  'něha',
  'nehet',
  'nehoda',
  'nechat',
  'nechť',
  'nechuť',
  'nej',
  'nějak',
  'nějaký',
  'nejen',
  'nekalý',
  'někam',
  'někde',
  'někdo',
  'někdy',
  'neklid',
  'němec',
  'němka',
  'nemo',
  'němý',
  'nepál',
  'neptun',
  'nero',
  'nerost',
  'neruda',
  'nerv',
  'neřest',
  'nesnáz',
  'nést',
  'nesvůj',
  'net',
  'neteř',
  'netvor',
  'neuron',
  'nevěra',
  'nevina',
  'nevíra',
  'nevole',
  'nezdar',
  'nezval',
  'než',
  'nežli',
  'něžně',
  'něžný',
  'nf',
  'ng',
  'nh',
  'ni',
  'nic',
  'nicota',
  'ničema',
  'ničení',
  'ničit',
  'ničivý',
  'nijak',
  'nikam',
  'nikde',
  'nikdo',
  'nikdy',
  'nikola',
  'nikoli',
  'nil',
  'nina',
  'nit',
  'nitka',
  'nitro',
  'niva',
  'nízko',
  'nízký',
  'nížina',
  'nj',
  'nk',
  'nl',
  'nm',
  'nn',
  'nnn',
  'nnnn',
  'no',
  'nóbl',
  'noc',
  'nocleh',
  'noční',
  'noha',
  'nok',
  'nop',
  'nor',
  'nora',
  'norma',
  'normál',
  'norman',
  'nořit',
  'nos',
  'nosič',
  'nosit',
  'nosní',
  'nosník',
  'nosný',
  'nošení',
  'nota',
  'notář',
  'notes',
  'notně',
  'notný',
  'nouze',
  'nova',
  'nová',
  'novák',
  'nově',
  'novela',
  'novina',
  'noviny',
  'novota',
  'nový',
  'nozdra',
  'nožka',
  'np',
  'nq',
  'nr',
  'ns',
  'nt',
  'nu',
  'nuceně',
  'nucený',
  'nuda',
  'nudit',
  'nudle',
  'nudný',
  'nula',
  'nulový',
  'nutit',
  'nutně',
  'nutný',
  'nůž',
  'nuže',
  'nůžky',
  'nv',
  'nw',
  'nx',
  'ny',
  'nýbrž',
  'nyní',
  'nýt',
  'nz',
  'o',
  'oáza',
  'ob',
  'oba',
  'obal',
  'obalit',
  'obálka',
  'obava',
  'obávat',
  'občan',
  'občas',
  'obdiv',
  'obdoba',
  'období',
  'obec',
  'obecně',
  'obecní',
  'obecný',
  'oběd',
  'oběh',
  'obejít',
  'oběsit',
  'oběť',
  'obézní',
  'oběžný',
  'obchod',
  'obchz',
  'obíhat',
  'obilí',
  'obilný',
  'objekt',
  'objem',
  'objet',
  'objetí',
  'objev',
  'objezd',
  'obklad',
  'oblak',
  'oblast',
  'obléci',
  'oblek',
  'obliba',
  'obloha',
  'oblouk',
  'obluda',
  'oblý',
  'obměna',
  'obnos',
  'obnova',
  'obočí',
  'obojek',
  'obojí',
  'obor',
  'obora',
  'obořit',
  'obout',
  'obr',
  'obrana',
  'obrat',
  'obraz',
  'obrnit',
  'obrys',
  'obřad',
  'obří',
  'obsah',
  'obstát',
  'obtíž',
  'obušek',
  'obuv',
  'obvaz',
  'obvod',
  'obyčej',
  'obydlí',
  'obytný',
  'obývák',
  'obývat',
  'obzor',
  'obživa',
  'oc',
  'ocas',
  'ocásek',
  'ocasní',
  'oceán',
  'ocel',
  'ocenit',
  'ocet',
  'ocitat',
  'oč',
  'očista',
  'očitý',
  'očko',
  'oční',
  'od',
  'óda',
  'odběr',
  'odboj',
  'odbor',
  'odbory',
  'odbyt',
  'odbýt',
  'oddaný',
  'oddat',
  'oddech',
  'oddíl',
  'odejít',
  'oděný',
  'oděv',
  'oděvní',
  'odezva',
  'odhad',
  'odchod',
  'odít',
  'odiv',
  'odjet',
  'odjezd',
  'odkaz',
  'odkdy',
  'odklad',
  'odklon',
  'odkrýt',
  'odkud',
  'odkup',
  'odkvět',
  'odlesk',
  'odlet',
  'odliv',
  'odměna',
  'odmlka',
  'odnést',
  'odnětí',
  'odnož',
  'odolat',
  'odolný',
  'odpad',
  'odpis',
  'odpor',
  'odra',
  'odraz',
  'odrůda',
  'odřený',
  'odstín',
  'odstup',
  'odsud',
  'odsun',
  'odtok',
  'odtud',
  'odvaha',
  'odvar',
  'odvěký',
  'odvést',
  'odveta',
  'odvézt',
  'odvod',
  'odvoz',
  'odznak',
  'oe',
  'of',
  'ofsajd',
  'og',
  'oh',
  'ohánět',
  'ohavný',
  'ohebný',
  'oheň',
  'ohlas',
  'ohled',
  'ohnivý',
  'ohnout',
  'ohnutý',
  'oholit',
  'ohon',
  'ohrada',
  'ohřát',
  'ohře',
  'ohřev',
  'ohyb',
  'ohýbat',
  'och',
  'ochota',
  'ochoz',
  'oi',
  'oj',
  'ojetý',
  'ok',
  'okap',
  'okénko',
  'okenní',
  'oklika',
  'okno',
  'oko',
  'okolí',
  'okolní',
  'okolo',
  'okraj',
  'okrást',
  'okres',
  'okrsek',
  'okruh',
  'okurka',
  'okusit',
  'okýnko',
  'ol',
  'olda',
  'olej',
  'olga',
  'oliva',
  'oliver',
  'olovo',
  'olše',
  'oltář',
  'om',
  'omáčka',
  'omamný',
  'omdlít',
  'omezit',
  'omítka',
  'omluva',
  'omotat',
  'omrzet',
  'omšelý',
  'omyl',
  'omýt',
  'omývat',
  'on',
  'onak',
  'ondra',
  'ondřej',
  'onen',
  'oni',
  'oo',
  'ooo',
  'oooo',
  'op',
  'opáčit',
  'opačně',
  'opačný',
  'opak',
  'opar',
  'opasek',
  'opat',
  'opava',
  'opéci',
  'opera',
  'opěra',
  'opěrka',
  'operní',
  'opěrný',
  'opět',
  'opice',
  'opičí',
  'opička',
  'opilec',
  'opilý',
  'opírat',
  'opis',
  'opít',
  'opium',
  'opodál',
  'opojný',
  'opona',
  'opora',
  'opq',
  'oprava',
  'oproti',
  'opřený',
  'opřít',
  'opsat',
  'optat',
  'optika',
  'oq',
  'or',
  'orel',
  'orgán',
  'orgie',
  'orient',
  'orlí',
  'orlice',
  'orloj',
  'orlová',
  'ořech',
  'oříšek',
  'os',
  'osa',
  'osada',
  'osadit',
  'osamět',
  'osel',
  'oskar',
  'oslava',
  'oslnit',
  'oslo',
  'osm',
  'osma',
  'osmý',
  'osn',
  'osnova',
  'osoba',
  'osobně',
  'osobní',
  'osolit',
  'ospale',
  'ospalý',
  'osten',
  'ostění',
  'ostroh',
  'ostrov',
  'ostrý',
  'ostře',
  'ostří',
  'ostuda',
  'ostych',
  'osud',
  'osudný',
  'osušit',
  'osvěta',
  'ošidit',
  'oštěp',
  'ot',
  'ota',
  'otáčet',
  'otáčka',
  'otakar',
  'otálet',
  'otázat',
  'otázka',
  'otcův',
  'otčím',
  'otec',
  'oteklý',
  'otěž',
  'otírat',
  'otisk',
  'oto',
  'otočit',
  'otočka',
  'otočný',
  'otok',
  'otrava',
  'otrlý',
  'otrok',
  'otřást',
  'otřes',
  'otřít',
  'otto',
  'otvor',
  'ou',
  'ouško',
  'ov',
  'ovace',
  'ovál',
  'oválný',
  'ovce',
  'ovčák',
  'ovčí',
  'ovečka',
  'ověřit',
  'oves',
  'ovesný',
  'ovoce',
  'ovocný',
  'ovšem',
  'ow',
  'ox',
  'oxid',
  'oy',
  'oz',
  'ozářit',
  'ozdoba',
  'ozón',
  'ozvat',
  'ozvěna',
  'ozývat',
  'oženit',
  'ožít',
  'ožívat',
  'oživit',
  'p',
  'packa',
  'pád',
  'padací',
  'padák',
  'padat',
  'pádit',
  'padlý',
  'pádný',
  'pahýl',
  'pach',
  'páchat',
  'pachuť',
  'pak',
  'paka',
  'páka',
  'pakt',
  'palác',
  'palach',
  'palba',
  'palec',
  'pálení',
  'pálený',
  'paleta',
  'palice',
  'pálit',
  'palivo',
  'pálka',
  'palma',
  'paluba',
  'paměť',
  'paměti',
  'pan',
  'pán',
  'panák',
  'pánbůh',
  'pane',
  'páně',
  'panel',
  'pánev',
  'paní',
  'panika',
  'panna',
  'panoš',
  'panský',
  'pánský',
  'pant',
  'panter',
  'papež',
  'papír',
  'pár',
  'pára',
  'paráda',
  'parčík',
  'pardon',
  'párek',
  'parfém',
  'park',
  'parket',
  'párky',
  'parní',
  'parník',
  'parný',
  'paroh',
  'part',
  'parta',
  'partaj',
  'parťák',
  'partie',
  'paruka',
  'pařát',
  'pařez',
  'paříž',
  'pas',
  'pás',
  'pasáž',
  'pásek',
  'paseka',
  'páska',
  'pásmo',
  'past',
  'pást',
  'pasta',
  'pastor',
  'pastva',
  'pastýř',
  'pat',
  'pata',
  'pátek',
  'patent',
  'páter',
  'páteř',
  'patka',
  'pátrat',
  'patrik',
  'patrně',
  'patrný',
  'patro',
  'patron',
  'patřit',
  'pátý',
  'paušál',
  'pauza',
  'páv',
  'pavel',
  'pavla',
  'pavlík',
  'pavouk',
  'pažba',
  'paže',
  'pb',
  'pc',
  'pčr',
  'pd',
  'pe',
  'pec',
  'péci',
  'pecka',
  'péče',
  'pečení',
  'pečený',
  'pečeť',
  'pečivo',
  'pedál',
  'pedro',
  'pejsek',
  'pekáč',
  'pekař',
  'peklo',
  'pěkně',
  'pěkný',
  'pěna',
  'peníz',
  'peníze',
  'pěnový',
  'penta',
  'penze',
  'pepa',
  'pepek',
  'pepík',
  'pepř',
  'perla',
  'perník',
  'pero',
  'perský',
  'peru',
  'peruť',
  'peřej',
  'peří',
  'peřina',
  'pes',
  'pěst',
  'pestrý',
  'pěšák',
  'pešek',
  'pěší',
  'pěšina',
  'pěšky',
  'pět',
  'petice',
  'pětice',
  'pětina',
  'pětka',
  'petr',
  'petra',
  'petrov',
  'petřín',
  'pěvec',
  'pevně',
  'pevný',
  'pf',
  'pg',
  'ph',
  'phdr',
  'pi',
  'piano',
  'píď',
  'pietní',
  'piha',
  'píchat',
  'piknik',
  'pila',
  'pilař',
  'píle',
  'pilina',
  'pilíř',
  'pilně',
  'pilný',
  'pilot',
  'pionýr',
  'pirát',
  'pírko',
  'písař',
  'písek',
  'píseň',
  'pískat',
  'písmo',
  'píst',
  'pistol',
  'pít',
  'pití',
  'pitný',
  'pitomý',
  'pitva',
  'pivní',
  'pivo',
  'pixel',
  'pizza',
  'pj',
  'pk',
  'pl',
  'plácat',
  'place',
  'placka',
  'pláč',
  'plachý',
  'plakat',
  'plakát',
  'plamen',
  'plán',
  'pláň',
  'planá',
  'plánek',
  'planý',
  'plast',
  'plaše',
  'plašil',
  'plášť',
  'plat',
  'plát',
  'platan',
  'platba',
  'plátce',
  'plátek',
  'platit',
  'plátno',
  'platný',
  'platon',
  'plavat',
  'plavba',
  'plavec',
  'plavit',
  'plavky',
  'plavý',
  'plaz',
  'plazit',
  'plazma',
  'pláž',
  'plech',
  'plena',
  'plenka',
  'plénum',
  'ples',
  'plést',
  'pleš',
  'pleť',
  'plevel',
  'plíce',
  'plicní',
  'plíseň',
  'plivat',
  'plížit',
  'plk',
  'plně',
  'plnění',
  'plněný',
  'plnit',
  'plno',
  'plnost',
  'plný',
  'plod',
  'plodit',
  'plodný',
  'plocha',
  'plochý',
  'plošný',
  'plot',
  'plotna',
  'plout',
  'pluh',
  'pluk',
  'plus',
  'plyn',
  'plynný',
  'plž',
  'pm',
  'pn',
  'po',
  'pobít',
  'poblíž',
  'pobyt',
  'pobýt',
  'pocit',
  'pocta',
  'poctít',
  'počasí',
  'počest',
  'počet',
  'početí',
  'počin',
  'počít',
  'počkat',
  'počty',
  'pod',
  'podání',
  'podaný',
  'podat',
  'podél',
  'podíl',
  'podít',
  'pódium',
  'podiv',
  'podle',
  'podlít',
  'podlý',
  'podnět',
  'podnik',
  'podnos',
  'podoba',
  'podpis',
  'podraz',
  'podvod',
  'podzim',
  'poezie',
  'pohan',
  'pohár',
  'pohled',
  'pohoda',
  'pohon',
  'pohoří',
  'pohrát',
  'pohřeb',
  'pohyb',
  'pochod',
  'pointa',
  'pojatý',
  'pojem',
  'pojetí',
  'pojit',
  'pokání',
  'poklad',
  'pokles',
  'poklid',
  'poklop',
  'pokoj',
  'pokora',
  'pokraj',
  'pokrm',
  'pokrok',
  'pokrýt',
  'pokřik',
  'pokud',
  'pokus',
  'pokuta',
  'pokyn',
  'pól',
  'polák',
  'polda',
  'pole',
  'poleno',
  'poleva',
  'police',
  'polít',
  'polní',
  'pólo',
  'poloha',
  'pomale',
  'pomalu',
  'pomalý',
  'poměr',
  'poměry',
  'pomezí',
  'pomník',
  'pomoc',
  'pomoci',
  'pomocí',
  'pomsta',
  'ponětí',
  'poník',
  'ponor',
  'ponurý',
  'pop',
  'popáté',
  'popel',
  'popis',
  'popový',
  'poprat',
  'poprsí',
  'popruh',
  'poprvé',
  'popř',
  'popřát',
  'popřít',
  'popsat',
  'popud',
  'pór',
  'porada',
  'porce',
  'pórek',
  'porod',
  'porost',
  'porota',
  'port',
  'porta',
  'portál',
  'porvat',
  'poryv',
  'pořad',
  'pořád',
  'pořadí',
  'poříčí',
  'posed',
  'posel',
  'posetý',
  'posila',
  'poslat',
  'post',
  'postát',
  'postel',
  'postih',
  'postoj',
  'postup',
  'posun',
  'posuv',
  'pošta',
  'pošťák',
  'pot',
  'potah',
  'potají',
  'potaz',
  'poté',
  'potěr',
  'potit',
  'potíž',
  'potkan',
  'potkat',
  'potmě',
  'potok',
  'potom',
  'potopa',
  'potřít',
  'potud',
  'poučit',
  'poučka',
  'poučný',
  'pouhý',
  'poukaz',
  'poupě',
  'poušť',
  'pouť',
  'poutat',
  'poutní',
  'pouto',
  'pouze',
  'použít',
  'povaha',
  'povel',
  'pověra',
  'povést',
  'pověst',
  'povlak',
  'povodí',
  'povoz',
  'povrch',
  'povyk',
  'póza',
  'pozadí',
  'pozadu',
  'pozbýt',
  'pozdě',
  'pozdně',
  'pozdní',
  'pozice',
  'pozn',
  'poznat',
  'pozor',
  'pozřít',
  'pozvat',
  'požár',
  'požití',
  'pp',
  'ppp',
  'pppp',
  'pq',
  'pqr',
  'pr',
  'práce',
  'prací',
  'pracně',
  'pracný',
  'pračka',
  'praděd',
  'prádlo',
  'práh',
  'praha',
  'prach',
  'prachy',
  'prales',
  'pramen',
  'praní',
  'prapor',
  'prase',
  'prášek',
  'prašný',
  'prát',
  'práv',
  'pravda',
  'právě',
  'pravěk',
  'právem',
  'pravit',
  'právně',
  'právní',
  'právo',
  'pravý',
  'praxe',
  'pražák',
  'pražan',
  'prcek',
  'prémie',
  'prchat',
  'prima',
  'primář',
  'primát',
  'princ',
  'prkno',
  'pro',
  'proces',
  'proč',
  'prodat',
  'prodej',
  'prof',
  'profík',
  'profil',
  'prohra',
  'projet',
  'projev',
  'projít',
  'prokop',
  'prolog',
  'propad',
  'prorok',
  'prosba',
  'prosek',
  'prosit',
  'prostě',
  'prostý',
  'proti',
  'proto',
  'proton',
  'proud',
  'provaz',
  'provoz',
  'próza',
  'prožít',
  'prs',
  'prsa',
  'prskat',
  'prsní',
  'prst',
  'prsten',
  'pršet',
  'průběh',
  'prudce',
  'prudký',
  'pruh',
  'průkaz',
  'průliv',
  'průlom',
  'průměr',
  'průnik',
  'průřez',
  'prut',
  'průtah',
  'průtok',
  'průvan',
  'průvod',
  'pružně',
  'pružný',
  'prve',
  'prvek',
  'prvně',
  'první',
  'prvý',
  'prý',
  'pryč',
  'pryž',
  'přání',
  'přát',
  'pře',
  'přebor',
  'přece',
  'před',
  'předák',
  'předat',
  'předek',
  'předem',
  'předně',
  'přední',
  'přehoz',
  'přejet',
  'přejít',
  'přelet',
  'přelít',
  'přelom',
  'přelud',
  'přenos',
  'přepad',
  'přepis',
  'přes',
  'přesah',
  'přesně',
  'přesný',
  'přesto',
  'přesun',
  'převis',
  'převod',
  'převoz',
  'přezka',
  'přežít',
  'při',
  'příběh',
  'příboj',
  'příbor',
  'přibyl',
  'přibýt',
  'příčit',
  'příčka',
  'příčný',
  'příď',
  'přidat',
  'příděl',
  'příjem',
  'přijet',
  'přijít',
  'příkaz',
  'příkop',
  'příkrý',
  'příkře',
  'přilba',
  'přílet',
  'příliš',
  'přilít',
  'příliv',
  'příměs',
  'přimět',
  'přímka',
  'přímo',
  'přímý',
  'přínos',
  'případ',
  'připít',
  'přísně',
  'přísný',
  'příst',
  'přísun',
  'příště',
  'příští',
  'přít',
  'přítel',
  'přítmí',
  'přítok',
  'přitom',
  'příval',
  'přívěs',
  'přívod',
  'přívoz',
  'přízeň',
  'ps',
  'psací',
  'psaní',
  'psaný',
  'psát',
  'psí',
  'psík',
  'pstruh',
  'pt',
  'ptáček',
  'ptačí',
  'pták',
  'ptát',
  'pu',
  'puč',
  'pud',
  'půda',
  'půdní',
  'pudr',
  'puch',
  'půjčit',
  'půjčka',
  'puk',
  'půl',
  'půle',
  'půlka',
  'půlnoc',
  'půlrok',
  'puls',
  'pult',
  'puma',
  'pumpa',
  'punc',
  'puntík',
  'pupek',
  'pupen',
  'pusa',
  'půst',
  'pustit',
  'pustý',
  'puška',
  'půvab',
  'původ',
  'pv',
  'pw',
  'px',
  'py',
  'pýcha',
  'pyl',
  'pysk',
  'pyšně',
  'pyšnit',
  'pyšný',
  'pytel',
  'pytlák',
  'pytlík',
  'pyžamo',
  'pz',
  'q',
  'qb',
  'qc',
  'qd',
  'qe',
  'qf',
  'qg',
  'qh',
  'qi',
  'qj',
  'qk',
  'ql',
  'qm',
  'qn',
  'qo',
  'qp',
  'qq',
  'qqq',
  'qqqq',
  'qr',
  'qrs',
  'qs',
  'qt',
  'qu',
  'qv',
  'qw',
  'qx',
  'qy',
  'qz',
  'r',
  'rabín',
  'racek',
  'ráčit',
  'rád',
  'rada',
  'radar',
  'rádce',
  'radek',
  'radim',
  'rádio',
  'radit',
  'radka',
  'radní',
  'rádoby',
  'radost',
  'raf',
  'rafat',
  'rachot',
  'ráj',
  'rajče',
  'rajský',
  'rak',
  'raketa',
  'rakev',
  'rákosí',
  'ram',
  'rám',
  'rámec',
  'rameno',
  'rampa',
  'rámus',
  'rána',
  'ranč',
  'rande',
  'raně',
  'ranec',
  'raněný',
  'ranit',
  'ranní',
  'ráno',
  'raný',
  'rarita',
  'rasa',
  'rasový',
  'ráz',
  'rázem',
  'razie',
  'razit',
  'rázně',
  'rázný',
  'ráže',
  'rb',
  'rc',
  'rčení',
  'rd',
  're',
  'reakce',
  'reálie',
  'reálně',
  'reálný',
  'rebel',
  'recept',
  'recese',
  'reflex',
  'refrén',
  'regál',
  'regina',
  'region',
  'rej',
  'rekord',
  'rektor',
  'relace',
  'reliéf',
  'remíza',
  'renata',
  'rené',
  'renomé',
  'renta',
  'resort',
  'resp',
  'ret',
  'réva',
  'revír',
  'revize',
  'revue',
  'rex',
  'rez',
  'rezavý',
  'rezort',
  'režie',
  'režim',
  'režný',
  'rf',
  'rg',
  'rh',
  'ri',
  'ring',
  'rio',
  'risk',
  'ríša',
  'rita',
  'rituál',
  'rival',
  'riziko',
  'rj',
  'rk',
  'rl',
  'rm',
  'rn',
  'ro',
  'róba',
  'robert',
  'robin',
  'robot',
  'ročně',
  'roční',
  'ročník',
  'rod',
  'rodák',
  'rodící',
  'rodič',
  'rodilý',
  'rodin',
  'rodina',
  'rodit',
  'rodný',
  'rodový',
  'roh',
  'rohlík',
  'rohový',
  'rohož',
  'roj',
  'rok',
  'rokle',
  'role',
  'roleta',
  'rolník',
  'rom',
  'roman',
  'román',
  'romeo',
  'romský',
  'romy',
  'ropa',
  'ropný',
  'rosa',
  'rose',
  'rošt',
  'rota',
  'rotace',
  'rotor',
  'roucho',
  'roura',
  'rouška',
  'rovina',
  'rovnat',
  'rovně',
  'rovněž',
  'rovník',
  'rovnou',
  'rovný',
  'rozběh',
  'rozbít',
  'rozbor',
  'rozdat',
  'rozdíl',
  'rozený',
  'rozjet',
  'rozkaz',
  'rozkol',
  'rozkoš',
  'rozlít',
  'rozmar',
  'rozměr',
  'rozpad',
  'rozpis',
  'rozpor',
  'rozsah',
  'roztát',
  'roztok',
  'rozum',
  'rozvod',
  'rozvoj',
  'rozvrh',
  'rožnov',
  'rp',
  'rq',
  'rr',
  'rrr',
  'rrrr',
  'rs',
  'rst',
  'rt',
  'rtěnka',
  'rtuť',
  'ru',
  'rub',
  'rubín',
  'ručení',
  'ručit',
  'ručka',
  'ručně',
  'ruční',
  'ručník',
  'ruda',
  'rudě',
  'rudný',
  'rudolf',
  'rudý',
  'ruch',
  'ruina',
  'ruka',
  'rukáv',
  'ruleta',
  'rum',
  'rumun',
  'rus',
  'rusko',
  'růst',
  'rušení',
  'rušit',
  'rušivý',
  'rušno',
  'rušný',
  'rutina',
  'různě',
  'různit',
  'různý',
  'ruzyně',
  'růž',
  'růže',
  'růžek',
  'růžena',
  'růžice',
  'růžový',
  'rv',
  'rváč',
  'rvačka',
  'rvát',
  'rw',
  'rx',
  'ry',
  'ryba',
  'rybář',
  'rybí',
  'rybíz',
  'rybka',
  'rybník',
  'rýč',
  'rýha',
  'rychle',
  'rychlý',
  'rým',
  'rýma',
  'rys',
  'rytina',
  'rytíř',
  'rytmus',
  'ryze',
  'ryzí',
  'rýže',
  'rýžový',
  'rz',
  'řád',
  'řada',
  'řádek',
  'řádění',
  'řadit',
  'řádit',
  'řádka',
  'řádně',
  'řádný',
  'řádově',
  'řadový',
  'řádový',
  'řasa',
  'řazení',
  'řecko',
  'řecký',
  'řeč',
  'řečený',
  'řečník',
  'řehoř',
  'řek',
  'řeka',
  'řemen',
  'řepa',
  'řepka',
  'řešení',
  'řešený',
  'řešit',
  'řetěz',
  'řev',
  'řez',
  'řezání',
  'řezaný',
  'řezat',
  'řezbář',
  'řezník',
  'řezný',
  'říci',
  'říčka',
  'říční',
  'řídce',
  'řídicí',
  'řídící',
  'řidič',
  'řídit',
  'řídký',
  'říjen',
  'říkat',
  'řím',
  'říman',
  'římsa',
  'římský',
  'řinout',
  'říp',
  'říše',
  'říšský',
  'řítit',
  'řízek',
  'řízení',
  'řízený',
  'řvát',
  's',
  'sabina',
  'sáček',
  'sad',
  'sada',
  'sádlo',
  'sádra',
  'safari',
  'sága',
  'sahara',
  'sahat',
  'sako',
  'sakra',
  'sál',
  'salám',
  'salát',
  'sálat',
  'salón',
  'salto',
  'salva',
  'sám',
  'samčí',
  'samec',
  'samet',
  'samice',
  'samota',
  'samý',
  'saň',
  'sanace',
  'sandál',
  'sandra',
  'saně',
  'sankce',
  'sára',
  'sasko',
  'saský',
  'saša',
  'sát',
  'satira',
  'saturn',
  'sauna',
  'savana',
  'savec',
  'savka',
  'sázava',
  'sazba',
  'saze',
  'sázení',
  'sázet',
  'sazka',
  'sázka',
  'sb',
  'sbalit',
  'sběr',
  'sběrač',
  'sběrný',
  'sbíhat',
  'sbírat',
  'sbírka',
  'sbohem',
  'sbor',
  'sc',
  'scedit',
  'scéna',
  'scénář',
  'scénka',
  'sci-fi',
  'sčítat',
  'sd',
  'sdělit',
  'sdílet',
  'se',
  'sebrat',
  'secese',
  'sečíst',
  'sed',
  'sedací',
  'sedan',
  'sedat',
  'sedět',
  'sedící',
  'sedlák',
  'sedlo',
  'sedm',
  'sedmý',
  'sehnat',
  'sehrát',
  'sejf',
  'sejít',
  'sekaný',
  'sekat',
  'sekce',
  'sekera',
  'sekta',
  'sektor',
  'sekyra',
  'selhat',
  'selský',
  'sem',
  'semeno',
  'semlít',
  'sen',
  'senát',
  'senior',
  'seno',
  'senzor',
  'sepsat',
  'seriál',
  'série',
  'sérum',
  'server',
  'servis',
  'seskok',
  'seslat',
  'sestra',
  'sestup',
  'sesuv',
  'sešit',
  'set',
  'setina',
  'setkat',
  'setmít',
  'sever',
  'sevřít',
  'sex',
  'sexy',
  'sezení',
  'seznam',
  'sezona',
  'sezóna',
  'sežrat',
  'sf',
  'sféra',
  'sg',
  'sh',
  'shánět',
  'shluk',
  'shnilý',
  'shoda',
  'shodit',
  'shodně',
  'shodný',
  'shon',
  'shora',
  'shořet',
  'schéma',
  'schod',
  'schůze',
  'si',
  'sibiř',
  'sic',
  'sice',
  'sídlit',
  'sídlo',
  'sigma',
  'signál',
  'síla',
  'sílící',
  'sílit',
  'silně',
  'silný',
  'silový',
  'simon',
  'simona',
  'síň',
  'sinice',
  'sir',
  'síra',
  'siréna',
  'sirius',
  'sirka',
  'sirup',
  'sít',
  'síť',
  'sítí',
  'síto',
  'síťový',
  'sj',
  'sjet',
  'sjezd',
  'sk',
  'skácet',
  'skákat',
  'skála',
  'skalka',
  'skalní',
  'skaut',
  'skeč',
  'skelet',
  'skelný',
  'skener',
  'skepse',
  'ski',
  'skica',
  'sklad',
  'sklep',
  'sklo',
  'sklon',
  'skluz',
  'skočit',
  'skok',
  'skokan',
  'skolit',
  'skóre',
  'skoro',
  'skot',
  'skript',
  'skrýš',
  'skryt',
  'skrýt',
  'skrytý',
  'skrz',
  'skřek',
  'skřet',
  'skříň',
  'skutek',
  'skútr',
  'skvěle',
  'skvělý',
  'skvost',
  'skvrna',
  'skýtat',
  'sl',
  'slabě',
  'slabý',
  'sladce',
  'sladit',
  'sladký',
  'slalom',
  'sláma',
  'slaný',
  'slast',
  'sláva',
  'slavia',
  'slavík',
  'slavit',
  'slavný',
  'slavoj',
  'slečna',
  'sled',
  'slepě',
  'slepý',
  'slet',
  'sleva',
  'slevit',
  'slézt',
  'slib',
  'slíbit',
  'slibně',
  'slibný',
  'sličný',
  'slina',
  'slít',
  'slizký',
  'slogan',
  'sloh',
  'sloka',
  'slon',
  'sloní',
  'slot',
  'sloup',
  'slovák',
  'slovan',
  'slovně',
  'slovní',
  'slovo',
  'složit',
  'složka',
  'sluha',
  'sluch',
  'slunce',
  'slunný',
  'slupka',
  'slušet',
  'slušně',
  'slušný',
  'slůvko',
  'služba',
  'služka',
  'slyšet',
  'slza',
  'sm',
  'smát',
  'smazat',
  'smažit',
  'smečka',
  'směle',
  'smělý',
  'směna',
  'směnka',
  'směr',
  'směs',
  'smést',
  'směšně',
  'směšný',
  'smět',
  'smetí',
  'smích',
  'smíchy',
  'smír',
  'smířit',
  'smog',
  'smolný',
  'smrad',
  'smrdět',
  'smrk',
  'smršť',
  'smrt',
  'sms',
  'smůla',
  'smutek',
  'smutně',
  'smutno',
  'smutný',
  'smyčec',
  'smyčka',
  'smyk',
  'smysl',
  'smýt',
  'sn',
  'snad',
  'snadno',
  'snadný',
  'snaha',
  'snacha',
  'snášet',
  'sňatek',
  'snažit',
  'snědý',
  'sněm',
  'snění',
  'snést',
  'sněžit',
  'sněžka',
  'sněžný',
  'snídat',
  'sníh',
  'snílek',
  'snímač',
  'snímat',
  'snímek',
  'sníst',
  'snít',
  'snížit',
  'snový',
  'snubní',
  'so',
  'sob',
  'sobota',
  'soc',
  'soda',
  'sodný',
  'sofie',
  'socha',
  'sochař',
  'sójový',
  'sok',
  'sokl',
  'sokol',
  'solný',
  'sólo',
  'sólový',
  'soňa',
  'sonáta',
  'sonda',
  'sopka',
  'sos',
  'soška',
  'sotva',
  'souboj',
  'soubor',
  'soucit',
  'součet',
  'součin',
  'soud',
  'soudce',
  'soudek',
  'soudit',
  'soudně',
  'soudní',
  'soudný',
  'souhra',
  'souhrn',
  'soukat',
  'soulad',
  'soupeř',
  'soupis',
  'soused',
  'sousto',
  'souš',
  'soutěž',
  'soutok',
  'sova',
  'sp',
  'spacák',
  'spací',
  'spád',
  'spadat',
  'spadlý',
  'spálit',
  'spam',
  'spánek',
  'spaní',
  'spár',
  'spára',
  'sparta',
  'spása',
  'spasit',
  'spát',
  'spávat',
  'spěch',
  'spěšně',
  'spět',
  'spící',
  'spílat',
  'spin',
  'spínač',
  'spis',
  'spíš',
  'spíše',
  'spíž',
  'spjatý',
  'splést',
  'spleť',
  'splnit',
  'spodek',
  'spodní',
  'spoj',
  'spojit',
  'spojka',
  'spol',
  'spolek',
  'spolu',
  'spona',
  'spor',
  'sporák',
  'sporný',
  'sport',
  'spořit',
  'spot',
  'spoušť',
  'správa',
  'sprej',
  'sprcha',
  'sprint',
  'sprška',
  'sq',
  'sr',
  'sranda',
  'sraz',
  'sráz',
  'srazit',
  'srážet',
  'srážka',
  'srb',
  'srdce',
  'srna',
  'srnčí',
  'srnec',
  'srp',
  'srpen',
  'srst',
  'sršet',
  'srub',
  'ss',
  'sss',
  'ssss',
  'st',
  'stáčet',
  'stačit',
  'stádo',
  'stah',
  'stáj',
  'stále',
  'stálý',
  'stan',
  'standa',
  'stánek',
  'stání',
  'stará',
  'starat',
  'start',
  'starý',
  'stařec',
  'stáří',
  'stařík',
  'stať',
  'statek',
  'státní',
  'statný',
  'status',
  'statut',
  'stav',
  'stávat',
  'stavba',
  'stavět',
  'stavit',
  'stávka',
  'stáž',
  'stéblo',
  'steh',
  'stehno',
  'stejně',
  'stejný',
  'stékat',
  'stěna',
  'sténat',
  'stereo',
  'stesk',
  'stezka',
  'stěží',
  'stíhat',
  'stín',
  'stinný',
  'stírat',
  'stisk',
  'sto',
  'stočit',
  'stoh',
  'stojan',
  'stoka',
  'stolec',
  'stolek',
  'stolní',
  'stonat',
  'stonek',
  'stop',
  'stopa',
  'stopař',
  'stopka',
  'stovka',
  'stožár',
  'str',
  'strach',
  'straka',
  'stráň',
  'strana',
  'strast',
  'strava',
  'stráž',
  'strčit',
  'stres',
  'strkat',
  'strmě',
  'strmý',
  'strohý',
  'stroj',
  'strom',
  'strop',
  'stroze',
  'strpět',
  'struna',
  'strýc',
  'strž',
  'střed',
  'středa',
  'střeh',
  'střela',
  'střep',
  'střet',
  'střevo',
  'střih',
  'stu',
  'stud',
  'studie',
  'studio',
  'studna',
  'studně',
  'stuha',
  'stůl',
  'stulík',
  'stupeň',
  'stužka',
  'stvol',
  'stý',
  'styčný',
  'stydět',
  'styk',
  'stýkat',
  'styl',
  'su',
  'sud',
  'sudí',
  'sucho',
  'suchý',
  'suk',
  'sukně',
  'sůl',
  'sultán',
  'suma',
  'sumec',
  'sundat',
  'sunout',
  'sup',
  'super',
  'surově',
  'surový',
  'suše',
  'sušení',
  'sušený',
  'sušit',
  'suť',
  'sutina',
  'sv',
  'svádět',
  'svah',
  'sval',
  'svalit',
  'svár',
  'svatá',
  'sváťa',
  'svatba',
  'svátek',
  'svatý',
  'svaz',
  'svázat',
  'svazek',
  'svědek',
  'svěrák',
  'svěřit',
  'svěsit',
  'svést',
  'svět',
  'světec',
  'světlá',
  'světle',
  'světlo',
  'světlý',
  'svetr',
  'svézt',
  'svěží',
  'svíce',
  'svícen',
  'svíčka',
  'svíjet',
  'svině',
  'svírat',
  'svislý',
  'svit',
  'svítat',
  'svitek',
  'svítit',
  'svižně',
  'svižný',
  'svod',
  'svolat',
  'svolit',
  'svorka',
  'svorně',
  'svoz',
  'svůdný',
  'svůj',
  'sw',
  'sx',
  'sy',
  'syčet',
  'sýkora',
  'sylva',
  'sylvie',
  'symbol',
  'syn',
  'synek',
  'synův',
  'sypat',
  'sýpka',
  'sýr',
  'sýrie',
  'syrový',
  'sýrový',
  'systém',
  'sytě',
  'sytý',
  'sz',
  'šach',
  'šachta',
  'šál',
  'šála',
  'šálek',
  'šalvěj',
  'šaman',
  'šampon',
  'šance',
  'šárka',
  'šarm',
  'šašek',
  'šat',
  'šátek',
  'šatna',
  'šatník',
  'šaty',
  'šavle',
  'šeď',
  'šedivý',
  'šedý',
  'šéf',
  'šéfka',
  'šek',
  'šelma',
  'šepot',
  'šeptat',
  'šerif',
  'šerm',
  'šermíř',
  'šero',
  'šest',
  'šestka',
  'šestý',
  'šetrně',
  'šetrný',
  'šetřit',
  'šev',
  'šicí',
  'šifra',
  'šíje',
  'šik',
  'šikana',
  'šikmo',
  'šikmý',
  'šíleně',
  'šílený',
  'šílet',
  'šimon',
  'šíp',
  'šipka',
  'široce',
  'široko',
  'široký',
  'širý',
  'šíře',
  'šíření',
  'šířící',
  'šířit',
  'šířka',
  'šiška',
  'šít',
  'šití',
  'šitý',
  'škála',
  'škoda',
  'škodit',
  'škola',
  'školák',
  'školit',
  'školka',
  'školné',
  'školní',
  'škrob',
  'škrt',
  'škrtat',
  'škrtit',
  'škubat',
  'škůdce',
  'škvíra',
  'šlágr',
  'šlacha',
  'šlapat',
  'šlehat',
  'šlépěj',
  'šmouha',
  'šnek',
  'šňůra',
  'šňůrka',
  'šofér',
  'šok',
  'šortky',
  'špaček',
  'špalek',
  'špatně',
  'špatný',
  'špek',
  'špenát',
  'šperk',
  'špetka',
  'špice',
  'špičák',
  'špička',
  'špína',
  'špion',
  'špión',
  'špitál',
  'šplhat',
  'šrám',
  'šrot',
  'šroub',
  'štáb',
  'štábní',
  'štace',
  'šťáva',
  'štědrý',
  'štefan',
  'štěkat',
  'štěkot',
  'štěně',
  'štěpán',
  'štěrk',
  'štěstí',
  'štětec',
  'štíhlý',
  'štika',
  'štípat',
  'štír',
  'štít',
  'štítek',
  'štola',
  'štvát',
  'šum',
  'šumava',
  'šumění',
  'šumět',
  'šunka',
  'šup',
  'šupina',
  'šuplík',
  'šuškat',
  'šváb',
  'švagr',
  'švec',
  'švéd',
  'švéda',
  'švejk',
  't',
  'tabák',
  'tábor',
  'tabu',
  'tabule',
  'tác',
  'tady',
  'tah',
  'tahač',
  'tahák',
  'tahat',
  'táhlý',
  'tahoun',
  'taj',
  'tajit',
  'tajně',
  'tajný',
  'tak',
  'také',
  'takhle',
  'takový',
  'takřka',
  'takt',
  'taktak',
  'taktéž',
  'taktně',
  'takto',
  'taky',
  'takže',
  'talent',
  'talíř',
  'tam',
  'támhle',
  'tamní',
  'tamten',
  'tamtéž',
  'táňa',
  'tančit',
  'tandem',
  'tanec',
  'tango',
  'tání',
  'tank',
  'tanker',
  'tao',
  'tápat',
  'tapeta',
  'tarif',
  'taška',
  'tát',
  'tata',
  'táta',
  'taťka',
  'tatra',
  'tatran',
  'tatry',
  'tátův',
  'taxi',
  'taxík',
  'tázat',
  'tázavě',
  'tažení',
  'tažený',
  'tažný',
  'tb',
  'tc',
  'td',
  'te',
  'téci',
  'teč',
  'tečka',
  'ted',
  'teď',
  'teda',
  'teďka',
  'tedy',
  'tehdy',
  'těkat',
  'těkavý',
  'tekutý',
  'tel',
  'telč',
  'tele',
  'telecí',
  'těleso',
  'tělní',
  'tělo',
  'téma',
  'temeno',
  'téměř',
  'temně',
  'temno',
  'temný',
  'tempo',
  'ten',
  'tendr',
  'tenhle',
  'tenis',
  'tenký',
  'tenor',
  'tento',
  'tentýž',
  'teolog',
  'teorie',
  'tep',
  'tepat',
  'teplo',
  'teplý',
  'tepna',
  'teprve',
  'terasa',
  'terč',
  'terén',
  'tereza',
  'terka',
  'termín',
  'tesák',
  'tesař',
  'těsně',
  'těsný',
  'test',
  'těsto',
  'těšit',
  'teta',
  'text',
  'textař',
  'textil',
  'teze',
  'též',
  'těžba',
  'těžce',
  'těžit',
  'těžko',
  'těžký',
  'tf',
  'tg',
  'th',
  'tchán',
  'tchyně',
  'ti',
  'tibet',
  'tíha',
  'ticho',
  'tichý',
  'tip',
  'tis',
  'tíseň',
  'tisíc',
  'tisící',
  'tisk',
  'tiskař',
  'tísnit',
  'tiše',
  'titul',
  'tíže',
  'tížit',
  'tíživý',
  'tj',
  'tk',
  'tkáň',
  'tkvít',
  'tl',
  'tlačit',
  'tlak',
  'tlama',
  'tlapa',
  'tlapka',
  'tlouci',
  'tlumič',
  'tlumit',
  'tlupa',
  'tlustý',
  'tm',
  'tma',
  'tmavě',
  'tmavý',
  'tmel',
  'tn',
  'to',
  'tobiáš',
  'točit',
  'točitý',
  'točivý',
  'točna',
  'tok',
  'tolik',
  'toliko',
  'tom',
  'tomáš',
  'tomík',
  'tón',
  'tonda',
  'toník',
  'topení',
  'topit',
  'topný',
  'topol',
  'torzo',
  'toť',
  'totiž',
  'touha',
  'toulat',
  'toulka',
  'toužit',
  'tož',
  'tp',
  'tq',
  'tr',
  'trable',
  'trakt',
  'trám',
  'trapas',
  'trápit',
  'trapně',
  'trapný',
  'trasa',
  'trať',
  'trauma',
  'tráva',
  'trávit',
  'trčet',
  'trefa',
  'trefit',
  'tréma',
  'trend',
  'trenér',
  'treska',
  'trest',
  'tretra',
  'trezor',
  'trh',
  'trhák',
  'trhat',
  'tričko',
  'trik',
  'triko',
  'trio',
  'triumf',
  'trn',
  'trní',
  'trnitý',
  'trnka',
  'trofej',
  'trocha',
  'trochu',
  'trója',
  'trojan',
  'trojí',
  'trojka',
  'tropit',
  'tropy',
  'troska',
  'trosky',
  'troška',
  'trošku',
  'trouba',
  'trpce',
  'trpět',
  'trpký',
  'trs',
  'trubka',
  'truhla',
  'trumf',
  'trůn',
  'trůnit',
  'trup',
  'trvale',
  'trvalý',
  'trvání',
  'trvat',
  'trysk',
  'tryska',
  'tržba',
  'tržní',
  'třást',
  'třeba',
  'třebas',
  'tření',
  'třesk',
  'třešně',
  'třetí',
  'tři',
  'třicet',
  'třída',
  'třídit',
  'třídní',
  'tříska',
  'tříšť',
  'třít',
  'třpyt',
  'ts',
  'tt',
  'ttt',
  'tttt',
  'tu',
  'tuba',
  'tucet',
  'tučňák',
  'tučný',
  'tudíž',
  'tudy',
  'tuhle',
  'tuhý',
  'tuk',
  'ťukat',
  'tukový',
  'tulák',
  'tuleň',
  'tůma',
  'tůň',
  'tuna',
  'tuňák',
  'tůně',
  'tunel',
  'tupě',
  'tupý',
  'túra',
  'turban',
  'turnaj',
  'turné',
  'tušení',
  'tušící',
  'tušit',
  'tuv',
  'tuze',
  'tužba',
  'tužka',
  'tv',
  'tvar',
  'tvaroh',
  'tvář',
  'tvářit',
  'tvor',
  'tvorba',
  'tvořit',
  'tvrdě',
  'tvrdit',
  'tvrdý',
  'tvrz',
  'tvůj',
  'tvůrce',
  'tvůrčí',
  'tw',
  'tx',
  'ty',
  'tyč',
  'tyčit',
  'tyčka',
  'týden',
  'týdně',
  'tygr',
  'tygří',
  'tykat',
  'týkat',
  'tyl',
  'týl',
  'tým',
  'tymián',
  'týmový',
  'týn',
  'typ',
  'týpek',
  'typový',
  'tyran',
  'tyršův',
  'týž',
  'tz',
  'tzn',
  'tzv',
  'u',
  'ub',
  'ubíhat',
  'ubírat',
  'úbočí',
  'ubohý',
  'úbor',
  'ubožák',
  'ubrat',
  'ubrus',
  'ubýt',
  'úbytek',
  'ubývat',
  'uc',
  'ucítit',
  'ucpaný',
  'ucpat',
  'úcta',
  'uctít',
  'uctivě',
  'uctivý',
  'účast',
  'učebna',
  'učební',
  'účel',
  'účelně',
  'účelný',
  'učeň',
  'učenec',
  'učení',
  'učený',
  'účes',
  'učesat',
  'účet',
  'účetní',
  'účinek',
  'učinit',
  'účinně',
  'účinný',
  'učit',
  'učitel',
  'učivo',
  'ud',
  'úd',
  'údaj',
  'údajně',
  'údajný',
  'udání',
  'udat',
  'udát',
  'udávat',
  'úděl',
  'udělat',
  'udělit',
  'úder',
  'úderný',
  'udeřit',
  'úděsný',
  'udílet',
  'údiv',
  'udivit',
  'údobí',
  'údolí',
  'údolní',
  'údržba',
  'udržet',
  'udusit',
  'ue',
  'uf',
  'ug',
  'uh',
  'uhájit',
  'uhánět',
  'uhasit',
  'uhel',
  'úhel',
  'uhelný',
  'uhlí',
  'uhlík',
  'úhlový',
  'uhnout',
  'uhodit',
  'úhona',
  'úhoř',
  'úhrada',
  'uhrát',
  'úhrn',
  'uhry',
  'uhýbat',
  'ucho',
  'ui',
  'uj',
  'ujet',
  'ujetý',
  'újezd',
  'ujímat',
  'ujít',
  'újma',
  'ujmout',
  'uk',
  'úkaz',
  'ukázat',
  'ukázka',
  'úklid',
  'úklona',
  'úkol',
  'úkon',
  'úkor',
  'ukrást',
  'ukrýt',
  'úkryt',
  'ukrytý',
  'ul',
  'úl',
  'úlek',
  'uletět',
  'úleva',
  'ulevit',
  'ulice',
  'ulička',
  'ulita',
  'úloha',
  'úlomek',
  'úlovek',
  'ulovit',
  'uložit',
  'úložný',
  'ulpět',
  'um',
  'uměle',
  'umělec',
  'umělý',
  'umění',
  'úměrně',
  'úměrný',
  'umět',
  'umlčet',
  'úmluva',
  'úmorný',
  'úmrtí',
  'umřít',
  'úmysl',
  'umýt',
  'umývat',
  'un',
  'unášet',
  'únava',
  'unavit',
  'únavně',
  'únavný',
  'unést',
  'unie',
  'unijní',
  'únik',
  'unikat',
  'unikát',
  'únor',
  'únos',
  'únosce',
  'únosný',
  'uo',
  'up',
  'upadat',
  'úpadek',
  'upálit',
  'úpatí',
  'upéci',
  'upíjet',
  'upínat',
  'upír',
  'upírat',
  'upít',
  'upjatý',
  'úplata',
  'uplést',
  'úplně',
  'úplněk',
  'úplný',
  'upnout',
  'úporně',
  'úporný',
  'úprava',
  'úprk',
  'upřeně',
  'upřený',
  'upřít',
  'upsat',
  'uq',
  'ur',
  'uran',
  'úraz',
  'urazit',
  'urážet',
  'urážka',
  'určení',
  'určený',
  'určit',
  'určitě',
  'určitý',
  'urna',
  'úroda',
  'úrodný',
  'úrok',
  'úroveň',
  'urvat',
  'úryvek',
  'úřad',
  'úředně',
  'úřední',
  'us',
  'ús',
  'usadit',
  'usb',
  'usedat',
  'úsek',
  'uschlý',
  'úsilí',
  'usínat',
  'úskalí',
  'usmát',
  'úsměv',
  'usnést',
  'usnout',
  'uspat',
  'úspěch',
  'uspět',
  'úspora',
  'ústa',
  'ustat',
  'ustát',
  'ústav',
  'ústava',
  'ústí',
  'ústit',
  'ustlat',
  'ústně',
  'ústní',
  'ústup',
  'úsudek',
  'úsvit',
  'ušít',
  'ušitý',
  'ušní',
  'ut',
  'utajit',
  'utápět',
  'utéci',
  'útěcha',
  'útěk',
  'utěrka',
  'úterní',
  'úterý',
  'útes',
  'utěšit',
  'utíkat',
  'utírat',
  'utišit',
  'utkání',
  'utkat',
  'utkvět',
  'útlak',
  'útlum',
  'útlý',
  'utnout',
  'útočit',
  'útočný',
  'útok',
  'utopie',
  'utopit',
  'útrapa',
  'útrata',
  'útroby',
  'utrpět',
  'útržek',
  'utržit',
  'utřít',
  'útulek',
  'útulný',
  'útvar',
  'uu',
  'uuu',
  'uuuu',
  'uv',
  'uvádět',
  'úvaha',
  'uvalit',
  'uvařit',
  'uvázat',
  'úvazek',
  'uvážit',
  'úvěr',
  'uvěřit',
  'uvést',
  'uvidět',
  'uvítat',
  'uvnitř',
  'úvod',
  'úvodní',
  'uvw',
  'uw',
  'ux',
  'uy',
  'uz',
  'uzávěr',
  'úzce',
  'uzda',
  'uzel',
  'území',
  'územně',
  'územní',
  'uzený',
  'úzkost',
  'úzký',
  'uznale',
  'uznání',
  'uznaný',
  'uznat',
  'už',
  'úžas',
  'užasle',
  'užaslý',
  'úžasně',
  'úžasný',
  'úžina',
  'užít',
  'užitek',
  'užití',
  'užitný',
  'užitý',
  'užívat',
  'uživit',
  'v',
  'vábit',
  'václav',
  'váček',
  'vada',
  'vadit',
  'vadný',
  'vágní',
  'vagón',
  'váha',
  'váhání',
  'váhat',
  'váhavě',
  'váhavý',
  'vak',
  'vakuum',
  'val',
  'valčík',
  'válčit',
  'válec',
  'válek',
  'válet',
  'valit',
  'válka',
  'valný',
  'vana',
  'váňa',
  'vandal',
  'vaněk',
  'vánek',
  'vánice',
  'vánoce',
  'vanout',
  'vápník',
  'vápno',
  'var',
  'várka',
  'vary',
  'vaření',
  'vařený',
  'vařící',
  'vařič',
  'vařit',
  'váš',
  'vašek',
  'vášeň',
  'vaško',
  'vát',
  'vata',
  'vavřín',
  'vaz',
  'váza',
  'vázaný',
  'vázat',
  'vazba',
  'vážení',
  'vážený',
  'vážící',
  'vážit',
  'vážka',
  'vážně',
  'vážný',
  'vb',
  'vc',
  'vcelku',
  'včas',
  'včasný',
  'včela',
  'včelař',
  'včelí',
  'včera',
  'včetně',
  'vd',
  'vdaný',
  'vdát',
  'vdávat',
  'vděčit',
  'vděčně',
  'vděčný',
  'vděk',
  'vdova',
  've',
  'věc',
  'věcně',
  'věcný',
  'večer',
  'večeře',
  'věčně',
  'věčný',
  'věda',
  'vědec',
  'vedení',
  'vědění',
  'vedený',
  'vědět',
  'vedle',
  'vědní',
  'vědomě',
  'vědomí',
  'vědomý',
  'vedro',
  'vědro',
  'věhlas',
  'vejce',
  'vějíř',
  'vejít',
  'věk',
  'věkový',
  'vektor',
  'velení',
  'velet',
  'velice',
  'veliký',
  'velký',
  'velmi',
  'velmoc',
  'ven',
  'věnec',
  'venkov',
  'venku',
  'věno',
  'ventil',
  'venuše',
  'vepř',
  'vepsat',
  'věra',
  'věrně',
  'věrný',
  'versus',
  'verš',
  'věru',
  'verva',
  'verze',
  'věřící',
  'věřit',
  'ves',
  'veselá',
  'vesele',
  'veselí',
  'veselo',
  'veselý',
  'veslo',
  'vesměs',
  'vesmír',
  'vést',
  'vesta',
  'veš',
  'věšák',
  'věšet',
  'věštba',
  'věštit',
  'věta',
  'větev',
  'větný',
  'veto',
  'větrák',
  'větrný',
  'větřík',
  'vetřít',
  'vévoda',
  'vězeň',
  'vězení',
  'vězet',
  'věznit',
  'vézt',
  'věž',
  'vf',
  'vg',
  'vh',
  'vhled',
  'vhod',
  'vhodit',
  'vhodně',
  'vhodný',
  'vchod',
  'vi',
  'víc',
  'více',
  'víčko',
  'vid',
  'viď',
  'vida',
  'vídat',
  'vidění',
  'viděný',
  'video',
  'vidět',
  'vidina',
  'viďte',
  'vichr',
  'vikář',
  'víkend',
  'víko',
  'viktor',
  'vila',
  'víla',
  'vilém',
  'vilík',
  'vilka',
  'vina',
  'vinař',
  'vinice',
  'viník',
  'vinit',
  'vinný',
  'víno',
  'vinout',
  'vir',
  'vír',
  'víra',
  'virový',
  'viróza',
  'virus',
  'vířit',
  'viset',
  'visící',
  'víska',
  'vít',
  'vítaný',
  'vítat',
  'vítek',
  'vítěz',
  'vítkov',
  'vítr',
  'viz',
  'vizáž',
  'vize',
  'vízový',
  'vízum',
  'vj',
  'vjem',
  'vjet',
  'vjezd',
  'vk',
  'vklad',
  'vkus',
  'vkusný',
  'vl',
  'vláček',
  'vláčet',
  'vláda',
  'vláďa',
  'vladař',
  'vládce',
  'vládní',
  'vláha',
  'vlahý',
  'vlach',
  'vlajka',
  'vlak',
  'vlákno',
  'vlas',
  'vlasák',
  'vlásek',
  'vlast',
  'vlasta',
  'vlát',
  'vlažný',
  'vlček',
  'vlčí',
  'vléci',
  'vlečka',
  'vlek',
  'vleklý',
  'vletět',
  'vlévat',
  'vlevo',
  'vlézt',
  'vleže',
  'vlhko',
  'vlhký',
  'vlídně',
  'vlídný',
  'vlít',
  'vliv',
  'vlivný',
  'vlk',
  'vlna',
  'vlnění',
  'vlněný',
  'vlnit',
  'vlnitý',
  'vlnka',
  'vlnový',
  'vločka',
  'vloni',
  'vložit',
  'vltava',
  'vm',
  'vmísit',
  'vn',
  'vnášet',
  'vně',
  'vnější',
  'vnést',
  'vnikat',
  'vnímat',
  'vnouče',
  'vnučka',
  'vnuk',
  'vnutit',
  'vo',
  'voda',
  'vodák',
  'vodič',
  'vodík',
  'vodit',
  'vodivý',
  'vodka',
  'vodní',
  'voják',
  'vojín',
  'vojna',
  'vojsko',
  'vojta',
  'vokoun',
  'volání',
  'volant',
  'volat',
  'volba',
  'vole',
  'volený',
  'volič',
  'volit',
  'volně',
  'volno',
  'volný',
  'voňavý',
  'vonět',
  'vonící',
  'vonný',
  'vor',
  'vosa',
  'vosk',
  'vous',
  'vousy',
  'vozík',
  'vozit',
  'vozka',
  'vozový',
  'vp',
  'vpád',
  'vplout',
  'vpravo',
  'vpřed',
  'vpředu',
  'vq',
  'vr',
  'vrabec',
  'vracet',
  'vrah',
  'vrak',
  'vrána',
  'vráska',
  'vrata',
  'vrátit',
  'vrátka',
  'vratký',
  'vratný',
  'vrátný',
  'vrazit',
  'vrba',
  'vrčet',
  'vrh',
  'vrhat',
  'vrch',
  'vrchní',
  'vrchol',
  'vroucí',
  'vrstva',
  'vršek',
  'vršit',
  'vrt',
  'vrták',
  'vrtání',
  'vrtat',
  'vrtět',
  'vrtule',
  'vrub',
  'vrut',
  'vrýt',
  'vřava',
  'vřed',
  'vřele',
  'vřelý',
  'vřes',
  'vřít',
  'vs',
  'vsadit',
  'vsedě',
  'vstát',
  'vstoje',
  'vstříc',
  'vstup',
  'vš',
  'však',
  'vše',
  'všecek',
  'všední',
  'všímat',
  'všude',
  'všudy',
  'vt',
  'vtip',
  'vtípek',
  'vtipně',
  'vtipný',
  'vtom',
  'vu',
  'vůbec',
  'vůči',
  'vůdce',
  'vůdčí',
  'vůl',
  'vůle',
  'vulkán',
  'vůně',
  'vůz',
  'vv',
  'vvv',
  'vvvv',
  'vw',
  'vwx',
  'vx',
  'vy',
  'výbava',
  'výběh',
  'výběr',
  'vybít',
  'výboj',
  'výbor',
  'vybrat',
  'výbuch',
  'výcvik',
  'výčep',
  'výčet',
  'vyčíst',
  'vyčkat',
  'výdaj',
  'vydání',
  'vydaný',
  'vydat',
  'výdech',
  'výdej',
  'vydra',
  'výdrž',
  'výfuk',
  'výheň',
  'výhled',
  'vyhnat',
  'výhoda',
  'výhon',
  'výhra',
  'vyhrát',
  'východ',
  'vyjet',
  'výjev',
  'výjezd',
  'vyjít',
  'vyjma',
  'výkaz',
  'výklad',
  'výkon',
  'výkop',
  'výkres',
  'výkřik',
  'výkup',
  'výkyv',
  'výlet',
  'výlev',
  'vylézt',
  'vylít',
  'výloha',
  'výlohy',
  'výlov',
  'výluka',
  'výměna',
  'výměra',
  'vymoci',
  'výmysl',
  'vyndat',
  'vynést',
  'výnos',
  'výpad',
  'výpar',
  'vypětí',
  'výpis',
  'vypít',
  'výplň',
  'výplod',
  'vyprat',
  'vypsat',
  'výraz',
  'výroba',
  'výročí',
  'výrok',
  'vyrůst',
  'vyrvat',
  'vyrýt',
  'vyrytý',
  'výsada',
  'vysát',
  'výskyt',
  'vyslat',
  'vysmát',
  'vysoce',
  'vysoká',
  'vysoko',
  'vysoký',
  'výsost',
  'vyspat',
  'vystát',
  'výstup',
  'výše',
  'výšina',
  'výška',
  'výt',
  'výtah',
  'vytí',
  'výtisk',
  'výtka',
  'vytřít',
  'výtvor',
  'vyučit',
  'výuka',
  'využít',
  'vývar',
  'vyvést',
  'vyvézt',
  'vývod',
  'vývoj',
  'vývoz',
  'výzkum',
  'význam',
  'vyznat',
  'vyznít',
  'vyzrát',
  'výztuž',
  'výzva',
  'vyzvat',
  'vyžití',
  'výživa',
  'vz',
  'vzácně',
  'vzácný',
  'vzad',
  'vzadu',
  'vzdát',
  'vzdech',
  'vzdor',
  'vzduch',
  'vzejít',
  'vzhled',
  'vzhůru',
  'vzít',
  'vzkaz',
  'vzlyk',
  'vznést',
  'vznik',
  'vzor',
  'vzorec',
  'vzorek',
  'vzorně',
  'vzorný',
  'vzpěra',
  'vzplát',
  'vzruch',
  'vzrůst',
  'vztah',
  'vztek',
  'vzteky',
  'vzývat',
  'vždy',
  'vždyť',
  'vžít',
  'w',
  'wb',
  'wc',
  'wd',
  'we',
  'web',
  'webový',
  'werich',
  'wf',
  'wg',
  'wh',
  'wi',
  'wi-fi',
  'wilma',
  'wj',
  'wk',
  'wl',
  'wm',
  'wn',
  'wo',
  'wp',
  'wq',
  'wr',
  'ws',
  'wt',
  'wu',
  'wv',
  'ww',
  'www',
  'wwww',
  'wx',
  'wxy',
  'wy',
  'wz',
  'x',
  'xb',
  'xc',
  'xd',
  'xe',
  'xf',
  'xg',
  'xh',
  'xi',
  'xj',
  'xk',
  'xl',
  'xm',
  'xn',
  'xo',
  'xp',
  'xq',
  'xr',
  'xs',
  'xt',
  'xu',
  'xv',
  'xw',
  'xx',
  'xxx',
  'xxxx',
  'xy',
  'xyz',
  'xz',
  'y',
  'yb',
  'yc',
  'yd',
  'ye',
  'yf',
  'yg',
  'yh',
  'yi',
  'yj',
  'yk',
  'yl',
  'ym',
  'yn',
  'yo',
  'yp',
  'yq',
  'yr',
  'ys',
  'yt',
  'yu',
  'yv',
  'yveta',
  'yw',
  'yx',
  'yy',
  'yyy',
  'yyyy',
  'yz',
  'z',
  'zábava',
  'záběr',
  'zabít',
  'zabitý',
  'zabrat',
  'zácpa',
  'zač',
  'začít',
  'záď',
  'záda',
  'zadák',
  'zadání',
  'zadaný',
  'zadat',
  'zadek',
  'zadní',
  'záhada',
  'zahnat',
  'záhon',
  'zahrát',
  'zahřát',
  'záhuba',
  'záhy',
  'záhyb',
  'záchod',
  'zajatý',
  'zaječí',
  'zájem',
  'zajet',
  'zajetí',
  'zájezd',
  'zajíc',
  'zajít',
  'zákal',
  'zákaz',
  'základ',
  'zaklít',
  'zákon',
  'zákop',
  'zákrok',
  'zakrýt',
  'zalézt',
  'zalhat',
  'záliba',
  'zalít',
  'zalitý',
  'záliv',
  'záloha',
  'zámek',
  'záměna',
  'záměr',
  'zamést',
  'zámoří',
  'zanést',
  'zánět',
  'zánik',
  'západ',
  'zápach',
  'zápal',
  'zápas',
  'zápis',
  'zápor',
  'zaprvé',
  'zapřít',
  'zapsat',
  'záruka',
  'zarýt',
  'zarytý',
  'zář',
  'záře',
  'záření',
  'zářez',
  'září',
  'zářící',
  'zářič',
  'zářit',
  'zářivě',
  'zářivý',
  'zařvat',
  'zas',
  'zásada',
  'zásah',
  'zase',
  'zasít',
  'zaslat',
  'zasmát',
  'zasnít',
  'zásoba',
  'zaspat',
  'zastat',
  'zástup',
  'zašlý',
  'zášť',
  'zátah',
  'zaťatý',
  'zátěž',
  'zatím',
  'zátiší',
  'zátka',
  'zato',
  'zátoka',
  'zaúpět',
  'závada',
  'závan',
  'závaží',
  'závěj',
  'závěr',
  'závěs',
  'zavést',
  'závěť',
  'zavézt',
  'závist',
  'závit',
  'závod',
  'závoj',
  'závora',
  'závrať',
  'zavřít',
  'zázemí',
  'záznam',
  'zaznít',
  'zázrak',
  'zázvor',
  'zažít',
  'zažitý',
  'zaživa',
  'zb',
  'zbavit',
  'zběžně',
  'zbít',
  'zbořit',
  'zboží',
  'zbožný',
  'zbraň',
  'zbroj',
  'zbrusu',
  'zbylý',
  'zbyněk',
  'zbýt',
  'zbytek',
  'zbývat',
  'zc',
  'zcela',
  'zčásti',
  'zd',
  'zda',
  'zdali',
  'zdálky',
  'zdání',
  'zdar',
  'zdarma',
  'zdárný',
  'zdařit',
  'zdát',
  'zdatný',
  'zde',
  'zdědit',
  'zdejší',
  'zdena',
  'zdeněk',
  'zdenka',
  'zděný',
  'zděsit',
  'zdivo',
  'zdobit',
  'zdola',
  'zdolat',
  'zdravě',
  'zdraví',
  'zdravý',
  'zdroj',
  'zdržet',
  'ze',
  'zebra',
  'zeď',
  'zedník',
  'zeleň',
  'zelená',
  'zelený',
  'zelí',
  'země',
  'zemina',
  'zemní',
  'zemřít',
  'zemský',
  'zenit',
  'zeptat',
  'zet',
  'zeť',
  'zeus',
  'zevní',
  'zezadu',
  'zf',
  'zg',
  'zh',
  'zhatit',
  'zhola',
  'zhruba',
  'zi',
  'zídka',
  'zima',
  'zimní',
  'zinek',
  'zip',
  'zírat',
  'zisk',
  'získat',
  'zištný',
  'zítra',
  'zítřek',
  'zj',
  'zjev',
  'zjevit',
  'zjevně',
  'zjevný',
  'zk',
  'zkáza',
  'zkazit',
  'zkraje',
  'zkrat',
  'zkusit',
  'zl',
  'zlákat',
  'zlato',
  'zlatý',
  'zle',
  'zlehka',
  'zleva',
  'zlíbit',
  'zlo',
  'zloba',
  'zlobit',
  'zločin',
  'zloděj',
  'zlom',
  'zlomek',
  'zlomit',
  'zlost',
  'zlý',
  'zm',
  'zmar',
  'zmařit',
  'zmást',
  'zmatek',
  'změna',
  'změnit',
  'změřit',
  'změť',
  'zmije',
  'zmínit',
  'zmínka',
  'zmítat',
  'zmizet',
  'zmoci',
  'zmrzlý',
  'zmýlit',
  'zn',
  'značit',
  'značka',
  'značně',
  'značný',
  'znak',
  'znalec',
  'znalý',
  'známka',
  'známý',
  'znát',
  'znělka',
  'znění',
  'zničit',
  'znít',
  'znova',
  'znovu',
  'zo',
  'zobák',
  'zóna',
  'zoo',
  'zoolog',
  'zorný',
  'zoubek',
  'zoufat',
  'zout',
  'zp',
  'zpátky',
  'zpěnit',
  'zpět',
  'zpětně',
  'zpětný',
  'zpěv',
  'zpěvák',
  'zpívat',
  'zpola',
  'zpověď',
  'zpoza',
  'zprava',
  'zpráva',
  'zprvu',
  'zpříma',
  'způsob',
  'zq',
  'zr',
  'zračit',
  'zrada',
  'zrádce',
  'zradit',
  'zrádný',
  'zrak',
  'zraky',
  'zralý',
  'zrána',
  'zrání',
  'zranit',
  'zrát',
  'zrní',
  'zrnko',
  'zrno',
  'zrod',
  'zrodit',
  'zrovna',
  'zručný',
  'zrůda',
  'zrušit',
  'zrzavý',
  'zřejmě',
  'zřejmý',
  'zřetel',
  'zřídit',
  'zřídka',
  'zříkat',
  'zřít',
  'zřítit',
  'zs',
  'zš',
  'zt',
  'zticha',
  'ztišit',
  'ztížit',
  'ztráta',
  'ztuhlý',
  'zu',
  'zub',
  'zubař',
  'zubatý',
  'zubní',
  'zubr',
  'zubří',
  'zuřit',
  'zuřivě',
  'zuřivý',
  'zůstat',
  'zuzana',
  'zuzka',
  'zúžení',
  'zúžený',
  'zúžit',
  'zv',
  'zvaný',
  'zvát',
  'zvážit',
  'zvedat',
  'zvenčí',
  'zvenku',
  'zvěř',
  'zvěst',
  'zvíře',
  'zvlášť',
  'zvolat',
  'zvolit',
  'zvolna',
  'zvon',
  'zvonek',
  'zvonit',
  'zvrat',
  'zvučný',
  'zvuk',
  'zvyk',
  'zvykat',
  'zvyklý',
  'zvýšit',
  'zw',
  'zx',
  'zy',
  'zz',
  'zzz',
  'zzzz',
  'žába',
  'žabí',
  'žádaný',
  'žádat',
  'žádný',
  'žádost',
  'žák',
  'žákyně',
  'žal',
  'žalář',
  'žalm',
  'žaloba',
  'žánr',
  'žár',
  'žárlit',
  'žďár',
  'že',
  'žebrák',
  'žebrat',
  'žebro',
  'žebřík',
  'žehnat',
  'žel',
  'železo',
  'želva',
  'žena',
  'ženatý',
  'ženich',
  'ženin',
  'ženit',
  'ženská',
  'ženský',
  'žert',
  'žertík',
  'žezlo',
  'žhavý',
  'žhnout',
  'žid',
  'židle',
  'žijící',
  'žíla',
  'žilka',
  'žirafa',
  'žít',
  'žití',
  'žitný',
  'žito',
  'živě',
  'živel',
  'živina',
  'živit',
  'živný',
  'živo',
  'život',
  'živý',
  'žízeň',
  'žižka',
  'žlab',
  'žláza',
  'žluč',
  'žlutý',
  'žně',
  'žnout',
  'žokej',
  'žrádlo',
  'žralok',
  'žula',
  'žulový',
  'župan',
  'žvanit',
  'žvást',
  'žvýkat',
];
