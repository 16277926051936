export const german = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
  '60',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '69',
  '70',
  '71',
  '72',
  '73',
  '74',
  '75',
  '76',
  '77',
  '78',
  '79',
  '80',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '88',
  '89',
  '90',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '97',
  '98',
  '99',
  '100',
  '101',
  '111',
  '123',
  '200',
  '222',
  '234',
  '300',
  '333',
  '345',
  '400',
  '444',
  '456',
  '500',
  '555',
  '567',
  '600',
  '666',
  '678',
  '700',
  '777',
  '789',
  '800',
  '888',
  '900',
  '999',
  '1000',
  '1111',
  '1234',
  '1492',
  '1500',
  '1600',
  '1700',
  '1800',
  '1900',
  '1910',
  '1920',
  '1925',
  '1930',
  '1935',
  '1940',
  '1945',
  '1950',
  '1955',
  '1960',
  '1965',
  '1970',
  '1975',
  '1980',
  '1985',
  '1990',
  '1991',
  '1992',
  '1993',
  '1994',
  '1995',
  '1996',
  '1997',
  '1998',
  '1999',
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2015',
  '2020',
  '2030',
  '2035',
  '2040',
  '2045',
  '2050',
  '2222',
  '2345',
  '2468',
  '3000',
  '3333',
  '3456',
  '4000',
  '4321',
  '4444',
  '4567',
  '5000',
  '5555',
  '5678',
  '6000',
  '6666',
  '6789',
  '7000',
  '7777',
  '8000',
  '8888',
  '9000',
  '9876',
  '9999',
  '-',
  '--',
  '---',
  '!',
  '!!',
  '!!!',
  '"',
  '#',
  '##',
  '###',
  '$',
  '$$',
  '$$$',
  '%',
  '%%',
  '%%%',
  '&',
  '(',
  '()',
  ')',
  '*',
  '**',
  '***',
  ':',
  ':(',
  ':-(',
  ':)',
  ':-)',
  ';',
  '?',
  '??',
  '???',
  '@',
  '+',
  '++',
  '+++',
  '=',
  '==',
  '===',
  'a',
  'aa',
  'aaa',
  'aaaa',
  'aachen',
  'aal',
  'aao',
  'aas',
  'aasig',
  'ab',
  'abart',
  'abb',
  'abbau',
  'abbe',
  'abbild',
  'abc',
  'abdomen',
  'abend',
  'aber',
  'abf',
  'abfall',
  'abflug',
  'abfuhr',
  'abgabe',
  'abgang',
  'abgas',
  'abgott',
  'abguss',
  'abhang',
  'abitur',
  'abk',
  'abkehr',
  'abladen',
  'ablage',
  'ablass',
  'ablauf',
  'ablaut',
  'abluft',
  'abnorm',
  'abo',
  'abraum',
  'abrede',
  'abrieb',
  'abriss',
  'abruf',
  'abrupt',
  'abs',
  'absage',
  'absatz',
  'abschn',
  'absenz',
  'absurd',
  'abt',
  'abtei',
  'abteil',
  'abtun',
  'abu',
  'abuja',
  'abweg',
  'abwehr',
  'abwurf',
  'abzgl',
  'abzug',
  'ac',
  'accra',
  'aceton',
  'ach',
  'achat',
  'achse',
  'achsel',
  'acht',
  'achte',
  'achten',
  'achtet',
  'acker',
  'acryl',
  'ad',
  'adac',
  'adagio',
  'adana',
  'addier',
  'ade',
  'adel',
  'adeln',
  'adelnd',
  'adelt',
  'adelte',
  'adenom',
  'ader',
  'adis',
  'adisch',
  'adj',
  'adler',
  'adlig',
  'adlige',
  'adm',
  'adn',
  'adr',
  'adrett',
  'adria',
  'adv',
  'advent',
  'adverb',
  'ae',
  'aegide',
  'aehre',
  'aelter',
  'aeon',
  'aerger',
  'aermel',
  'aerob',
  'aesche',
  'aether',
  'aethyl',
  'aetna',
  'aetzen',
  'af',
  'affe',
  'affig',
  'affin',
  'afrika',
  'after',
  'ag',
  'agar',
  'agent',
  'agiert',
  'agio',
  'agonie',
  'agra',
  'agrar',
  'ah',
  'aha',
  'ahle',
  'ahmt',
  'ahmte',
  'ahn',
  'ahndet',
  'ahndete',
  'ahnen',
  'ahnend',
  'ahnt',
  'ahnte',
  'ahnung',
  'ahorn',
  'ai',
  'aids',
  'airbus',
  'aj',
  'ak',
  'aka',
  'akazie',
  'akelei',
  'akkord',
  'akne',
  'akt',
  'akte',
  'akten',
  'akteur',
  'aktie',
  'aktien',
  'aktion',
  'aktiv',
  'aktiva',
  'aktive',
  'aktor',
  'akut',
  'akutes',
  'akzent',
  'akzept',
  'al',
  'alanin',
  'alarm',
  'alaska',
  'alaun',
  'albern',
  'albert',
  'albino',
  'album',
  'alge',
  'alias',
  'alibi',
  'alkali',
  'all',
  'alle',
  'allee',
  'allein',
  'allel',
  'allem',
  'allen',
  'alles',
  'allg',
  'alltag',
  'allzu',
  'alm',
  'alma',
  'aloe',
  'alofi',
  'alp',
  'alpen',
  'alpin',
  'alraun',
  'als',
  'alse',
  'also',
  'alt',
  'altai',
  'altar',
  'altbau',
  'alte',
  'alter',
  'altern',
  'altert',
  'altes',
  'altoel',
  'am',
  'ama',
  'amateur',
  'amber',
  'amboss',
  'ambra',
  'ameise',
  'ami',
  'amman',
  'amme',
  'amnion',
  'amoebe',
  'amok',
  'amoll',
  'amor',
  'amorph',
  'ampel',
  'amsel',
  'amt',
  'amtl',
  'amur',
  'an',
  'anabol',
  'anal',
  'analog',
  'ananas',
  'anatom',
  'anbau',
  'anbei',
  'andere',
  'anders',
  'andora',
  'anemone',
  'anfall',
  'anfang',
  'anflug',
  'anfuhr',
  'angabe',
  'angara',
  'angel',
  'angeln',
  'anger',
  'angina',
  'angler',
  'angola',
  'angst',
  'anguss',
  'anh',
  'anhand',
  'anhang',
  'anis',
  'ank',
  'ankara',
  'ankauf',
  'anker',
  'ankert',
  'ankunft',
  'anl',
  'anlage',
  'anlass',
  'anlauf',
  'anlaut',
  'anm',
  'anmut',
  'annona',
  'anode',
  'anomal',
  'anomie',
  'anonym',
  'anorak',
  'anrede',
  'anreiz',
  'anruf',
  'ans',
  'ansage',
  'ansatz',
  'ansaug',
  'anschl',
  'anshan',
  'anteil',
  'anti',
  'antik',
  'antrag',
  'antw',
  'anurie',
  'anwalt',
  'anzahl',
  'anzug',
  'ao',
  'aorta',
  'ap',
  'apart',
  'apfel',
  'aphel',
  'apia',
  'apnoe',
  'apo',
  'app',
  'appell',
  'april',
  'aq',
  'aquana',
  'ar',
  'araber',
  'arbeit',
  'arbok',
  'archiv',
  'ard',
  'arena',
  'arglos',
  'argon',
  'arh',
  'arie',
  'arier',
  'arkade',
  'arktis',
  'arm',
  'armada',
  'arme',
  'armee',
  'armer',
  'armut',
  'aroma',
  'arrest',
  'arsch',
  'arsen',
  'art',
  'artig',
  'artist',
  'aruba',
  'arznei',
  'arzt',
  'as',
  'asbest',
  'asche',
  'aschig',
  'asiat',
  'asien',
  'askese',
  'asket',
  'asmara',
  'aspekt',
  'aspik',
  'ass',
  'ast',
  'astana',
  'astat',
  'aster',
  'asthma',
  'astral',
  'asyl',
  'at',
  'ataxie',
  'atem',
  'athlet',
  'atlas',
  'atm',
  'atmen',
  'atmend',
  'atmet',
  'atmete',
  'atmung',
  'atoll',
  'atom',
  'atomar',
  'atonie',
  'atopie',
  'atrial',
  'atrium',
  'attest',
  'au',
  'auch',
  'aue',
  'auf',
  'aufbau',
  'aufl',
  'aufruf',
  'aufs',
  'aufzug',
  'auge',
  'augen',
  'august',
  'aula',
  'aura',
  'aural',
  'aus',
  'ausbau',
  'aushub',
  'ausruf',
  'aussen',
  'ausser',
  'auster',
  'austin',
  'ausweg',
  'auszug',
  'autark',
  'auto',
  'autogen',
  'autor',
  'autsch',
  'av',
  'avarua',
  'avis',
  'aw',
  'ax',
  'axial',
  'axiom',
  'axt',
  'ay',
  'az',
  'azalee',
  'azetat',
  'azeton',
  'azimut',
  'azoren',
  'azteke',
  'b',
  'baby',
  'bach',
  'back',
  'backe',
  'backen',
  'bad',
  'baden',
  'badet',
  'badete',
  'baehen',
  'baeht',
  'baehte',
  'baer',
  'baerin',
  'baff',
  'bafoeg',
  'bagel',
  'bagger',
  'bahn',
  'bahre',
  'baku',
  'bald',
  'baldig',
  'balg',
  'balkan',
  'balken',
  'balkon',
  'ball',
  'ballade',
  'ballen',
  'ballig',
  'ballon',
  'ballt',
  'balsa',
  'balsam',
  'bamako',
  'bambus',
  'banal',
  'banane',
  'band',
  'bandar',
  'bande',
  'bandit',
  'bange',
  'bangen',
  'bangt',
  'bangte',
  'bangui',
  'banjo',
  'banjul',
  'bank',
  'banner',
  'baotou',
  'bar',
  'barbar',
  'barde',
  'barium',
  'bark',
  'barock',
  'baron',
  'barren',
  'barsch',
  'bart',
  'baryon',
  'basal',
  'basalt',
  'basar',
  'basic',
  'basis',
  'baske',
  'bass',
  'bast',
  'bastei',
  'bat',
  'baten',
  'batik',
  'batist',
  'bau',
  'bauamt',
  'bauart',
  'bauch',
  'baud',
  'baude',
  'bauen',
  'bauend',
  'bauer',
  'baum',
  'bausch',
  'baut',
  'baute',
  'bauxit',
  'bauzaun',
  'bayer',
  'bayern',
  'bayrut',
  'bb',
  'bbb',
  'bbbb',
  'bc',
  'bcd',
  'bd',
  'bde',
  'be',
  'beamte',
  'bebaut',
  'beben',
  'bebend',
  'bebt',
  'bebte',
  'becher',
  'becken',
  'bedarf',
  'bedenk',
  'beehrt',
  'beeil',
  'beeilt',
  'beere',
  'beet',
  'befahl',
  'befehl',
  'befiel',
  'befugt',
  'befuhr',
  'befund',
  'begab',
  'begabt',
  'begann',
  'begebe',
  'begeht',
  'begibt',
  'beging',
  'beginn',
  'begoss',
  'begrub',
  'beguss',
  'behalt',
  'behaut',
  'behebt',
  'behext',
  'bei',
  'beide',
  'beige',
  'beil',
  'beim',
  'bein',
  'beinah',
  'beinig',
  'beirat',
  'beirrt',
  'beirut',
  'beisst',
  'beiwort',
  'bejaht',
  'bekam',
  'belag',
  'belaubt',
  'belebt',
  'beleg',
  'belegt',
  'belem',
  'belieh',
  'belize',
  'bellen',
  'beller',
  'bellt',
  'bellte',
  'belo',
  'belud',
  'bemass',
  'benahm',
  'benennt',
  'bengel',
  'benin',
  'benzin',
  'benzol',
  'bequem',
  'beraet',
  'bereit',
  'berg',
  'bergab',
  'bergan',
  'bergen',
  'berief',
  'berlin',
  'berme',
  'bern',
  'beruf',
  'beruft',
  'beruht',
  'beryll',
  'bes',
  'besagt',
  'besann',
  'besass',
  'besatz',
  'besen',
  'besitz',
  'besser',
  'beste',
  'bester',
  'bestnr',
  'besuch',
  'beten',
  'betend',
  'betet',
  'betete',
  'beton',
  'betont',
  'betr',
  'betraf',
  'betrag',
  'betrat',
  'betrog',
  'betrug',
  'bett',
  'betten',
  'bettet',
  'beugen',
  'beugt',
  'beugte',
  'beule',
  'beulen',
  'beute',
  'beutel',
  'beutet',
  'bev',
  'bevor',
  'bewarb',
  'bewegt',
  'beweis',
  'bez',
  'bezirk',
  'bezog',
  'bezug',
  'bf',
  'bg',
  'bgb',
  'bgh',
  'bh',
  'bhf',
  'bhopal',
  'bhutan',
  'bi',
  'bias',
  'bibel',
  'biber',
  'bibor',
  'bieder',
  'biegen',
  'biegt',
  'biene',
  'bier',
  'biest',
  'bieten',
  'bieter',
  'bietet',
  'bigott',
  'bikini',
  'bilanz',
  'bilbao',
  'bild',
  'bilden',
  'bilder',
  'bildet',
  'billig',
  'bimsen',
  'bin',
  'binaer',
  'binde',
  'binden',
  'binder',
  'bindet',
  'bingo',
  'binom',
  'binse',
  'biogas',
  'bionik',
  'biopsie',
  'bios',
  'bip',
  'birgt',
  'birke',
  'birne',
  'bis',
  'bisam',
  'bisher',
  'bismut',
  'biss',
  'bissau',
  'bissen',
  'bissig',
  'bistum',
  'bit',
  'bitmap',
  'bitte',
  'bitten',
  'bitter',
  'bittet',
  'biwak',
  'bizarr',
  'bizeps',
  'bj',
  'bk',
  'bl',
  'blaeht',
  'blaest',
  'blaeue',
  'blank',
  'blase',
  'blasen',
  'blasig',
  'blass',
  'blatt',
  'blau',
  'blauer',
  'blaues',
  'blazer',
  'blech',
  'blei',
  'bleibt',
  'bleich',
  'blende',
  'blick',
  'blind',
  'blinde',
  'blitz',
  'blitza',
  'block',
  'bloed',
  'blond',
  'blonde',
  'blosse',
  'bluete',
  'bluff',
  'blume',
  'blumen',
  'bluse',
  'blut',
  'bluten',
  'bluter',
  'blutig',
  'bluts',
  'blz',
  'bm',
  'bmw',
  'bn',
  'bnd',
  'bo',
  'boa',
  'bob',
  'bochum',
  'bock',
  'bocken',
  'bodden',
  'boden',
  'boehme',
  'boeig',
  'boerse',
  'boese',
  'bogen',
  'bogota',
  'bohle',
  'bohne',
  'bohnen',
  'bohren',
  'bohrer',
  'boje',
  'bolero',
  'bolzen',
  'bombe',
  'bomber',
  'bonbon',
  'bongo',
  'bonn',
  'bonus',
  'boom',
  'boot',
  'bor',
  'borax',
  'bord',
  'bordell',
  'borste',
  'boskop',
  'boss',
  'boston',
  'bote',
  'bowle',
  'box',
  'boxen',
  'boxer',
  'bozen',
  'bp',
  'bq',
  'br',
  'brand',
  'braten',
  'brauch',
  'braue',
  'braun',
  'braut',
  'bravo',
  'brd',
  'brei',
  'breiig',
  'breite',
  'bremen',
  'bremse',
  'brett',
  'brezel',
  'brie',
  'brief',
  'brigg',
  'brille',
  'brise',
  'brit',
  'brite',
  'brokat',
  'brom',
  'bronze',
  'brot',
  'brt',
  'bruch',
  'bruder',
  'bruehe',
  'bruesk',
  'brunei',
  'brunst',
  'brust',
  'brut',
  'brutal',
  'brutei',
  'brutto',
  'bs',
  'bse',
  'bsp',
  'bt',
  'bu',
  'bub',
  'bube',
  'buccal',
  'buch',
  'buche',
  'buchen',
  'buchse',
  'bucht',
  'buckel',
  'bude',
  'buegel',
  'buehne',
  'buenos',
  'buerge',
  'buero',
  'bueste',
  'buett',
  'buette',
  'bug',
  'buhlen',
  'bukett',
  'bulle',
  'bulloes',
  'bump',
  'bums',
  'bund',
  'bunker',
  'bunny',
  'bunt',
  'bursa',
  'bus',
  'busch',
  'busen',
  'buss',
  'busse',
  'butler',
  'butter',
  'butzen',
  'bv',
  'bw',
  'bx',
  'by',
  'bypass',
  'byte',
  'bz',
  'bzgl',
  'bzw',
  'c',
  'cabrio',
  'cache',
  'cad',
  'cafe',
  'cai',
  'cali',
  'cam',
  'cand',
  'carrel',
  'cayenne',
  'cb',
  'cc',
  'ccc',
  'cccc',
  'ccitt',
  'cd',
  'cde',
  'cdrom',
  'cdu',
  'ce',
  'cebu',
  'cerium',
  'cf',
  'cg',
  'ch',
  'chance',
  'chaos',
  'charge',
  'chef',
  'chemie',
  'chile',
  'chili',
  'china',
  'chinin',
  'chip',
  'chips',
  'chlor',
  'chor',
  'choral',
  'chr',
  'christ',
  'chrom',
  'chuzpe',
  'chylus',
  'ci',
  'cim',
  'citrin',
  'ciudad',
  'cj',
  'ck',
  'cl',
  'claim',
  'clever',
  'clique',
  'clogs',
  'cm',
  'cmÂ³',
  'cmos',
  'cn',
  'cnc',
  'co',
  'cobalt',
  'cochin',
  'code',
  'codec',
  'comic',
  'cook',
  'cookie',
  'costa',
  'cote',
  'coup',
  'coupon',
  'cousin',
  'cp',
  'cq',
  'cr',
  'crash',
  'cs',
  'csu',
  'ct',
  'cu',
  'curium',
  'cursor',
  'cv',
  'cvjm',
  'cw',
  'cx',
  'cy',
  'cz',
  'd',
  'dabei',
  'dach',
  'dachs',
  'dachte',
  'dackel',
  'dada',
  'daemmt',
  'daemon',
  'daene',
  'dafuer',
  'dag',
  'daheim',
  'daher',
  'dahlie',
  'dakar',
  'dalian',
  'dallas',
  'damals',
  'damast',
  'dame',
  'damen',
  'damit',
  'damm',
  'dampf',
  'dampft',
  'danach',
  'dandy',
  'dank',
  'danke',
  'danken',
  'dankt',
  'dann',
  'dar',
  'daran',
  'darauf',
  'daraus',
  'darf',
  'darin',
  'darm',
  'darum',
  'das',
  'dasein',
  'dass',
  'datei',
  'daten',
  'dativ',
  'dato',
  'datong',
  'dattel',
  'datum',
  'dauer',
  'dauern',
  'dauert',
  'daumen',
  'daune',
  'davao',
  'davon',
  'davor',
  'dazu',
  'db',
  'dc',
  'dd',
  'ddd',
  'dddd',
  'ddr',
  'de',
  'debuet',
  'deck',
  'decke',
  'deckel',
  'decken',
  'deckt',
  'def',
  'defekt',
  'defizit',
  'degen',
  'dehnen',
  'dehnt',
  'dehnte',
  'deich',
  'dein',
  'deine',
  'deist',
  'dekade',
  'dekan',
  'dekor',
  'dekret',
  'delfin',
  'delikt',
  'delle',
  'dem',
  'demut',
  'den',
  'denise',
  'denk',
  'denken',
  'denker',
  'denkt',
  'denn',
  'dental',
  'denver',
  'deo',
  'depot',
  'depp',
  'der',
  'derart',
  'derb',
  'derber',
  'deren',
  'des',
  'despot',
  'dessen',
  'dessert',
  'desto',
  'detail',
  'deuten',
  'deutet',
  'devon',
  'devot',
  'dezent',
  'df',
  'dfb',
  'dg',
  'dgb',
  'dgl',
  'dgr',
  'dh',
  'dhaka',
  'di',
  'dia',
  'diadem',
  'diaet',
  'diag',
  'diakon',
  'dialog',
  'dich',
  'dicht',
  'dichte',
  'dick',
  'dicke',
  'dicker',
  'dickes',
  'die',
  'dieb',
  'diele',
  'dienen',
  'diener',
  'dienst',
  'dient',
  'diente',
  'dies',
  'diese',
  'diesel',
  'dieser',
  'dieses',
  'diffus',
  'diktat',
  'dilemma',
  'dill',
  'dimmer',
  'din',
  'ding',
  'dingen',
  'dinghy',
  'dinkel',
  'diode',
  'dioxyd',
  'dip',
  'dipl',
  'diplom',
  'dipol',
  'dir',
  'direkt',
  'dirndl',
  'dirne',
  'diskus',
  'distal',
  'distel',
  'dito',
  'diva',
  'divers',
  'diwan',
  'dj',
  'djh',
  'dk',
  'dkp',
  'dl',
  'dm',
  'dn',
  'do',
  'doch',
  'docht',
  'dock',
  'docke',
  'doebel',
  'doesen',
  'doest',
  'doeste',
  'dogge',
  'doha',
  'dohle',
  'doktor',
  'dolch',
  'dolde',
  'dollar',
  'dom',
  'domino',
  'don',
  'donau',
  'donner',
  'doof',
  'dopen',
  'doping',
  'doppel',
  'dorf',
  'dorn',
  'dornig',
  'dorsal',
  'dorsch',
  'dort',
  'dose',
  'dosis',
  'dotter',
  'douala',
  'double',
  'doz',
  'dozent',
  'dozer',
  'dp',
  'dpa',
  'dq',
  'dr',
  'drache',
  'draht',
  'drall',
  'dralle',
  'drama',
  'drang',
  'dreck',
  'dreckt',
  'drehen',
  'dreher',
  'drehst',
  'dreht',
  'drehte',
  'drei',
  'dreist',
  'drell',
  'drg',
  'drift',
  'drill',
  'drillt',
  'drin',
  'dringt',
  'dritte',
  'droben',
  'droge',
  'drohen',
  'dronte',
  'drops',
  'drosch',
  'druck',
  'druckt',
  'druese',
  'druse',
  'ds',
  'dt',
  'dtzd',
  'du',
  'dublin',
  'ducken',
  'duckt',
  'duckte',
  'duebel',
  'duell',
  'duene',
  'duengt',
  'duenn',
  'duenne',
  'duerr',
  'duerre',
  'duese',
  'duester',
  'duett',
  'duft',
  'duften',
  'duftet',
  'duftig',
  'dulden',
  'duldet',
  'dumas',
  'dumdum',
  'dumm',
  'dumme',
  'dummer',
  'dummes',
  'dumpf',
  'dung',
  'dunkel',
  'dunst',
  'duo',
  'duplex',
  'dur',
  'durban',
  'durch',
  'durst',
  'dusche',
  'dussel',
  'dutt',
  'dv',
  'dw',
  'dx',
  'dy',
  'dynamo',
  'dysurie',
  'dz',
  'e',
  'eb',
  'ebbe',
  'ebd',
  'eben',
  'ebene',
  'ebenso',
  'eber',
  'ebnen',
  'ebonit',
  'ec',
  'echo',
  'echt',
  'echter',
  'echtes',
  'ecke',
  'eckig',
  'eckige',
  'eckt',
  'ecma',
  'ed',
  'edamer',
  'edel',
  'editor',
  'edv',
  'edwin',
  'ee',
  'eee',
  'eeee',
  'ef',
  'efeu',
  'effekt',
  'efg',
  'efre',
  'efta',
  'eg',
  'egge',
  'eggend',
  'egoist',
  'eh',
  'ehe',
  'ehedem',
  'ehem',
  'eher',
  'ehern',
  'ehrbar',
  'ehre',
  'ehren',
  'ehrend',
  'ehrlos',
  'ehrt',
  'ehrte',
  'ehrung',
  'ei',
  'eibe',
  'eiche',
  'eichel',
  'eichen',
  'eid',
  'eidam',
  'eides',
  'eidg',
  'eier',
  'eifer',
  'eifern',
  'eifert',
  'eifrig',
  'eigelb',
  'eigen',
  'eigene',
  'eignet',
  'eigtl',
  'eile',
  'eilen',
  'eilend',
  'eilig',
  'eilzug',
  'eimer',
  'ein',
  'einbau',
  'einbit',
  'eine',
  'einehe',
  'einem',
  'einen',
  'einer',
  'eines',
  'einfach',
  'einheit',
  'einher',
  'einige',
  'einmal',
  'einoede',
  'einrad',
  'eins',
  'einsam',
  'einser',
  'einst',
  'einweg',
  'einzeln',
  'einzig',
  'einzug',
  'eis',
  'eisen',
  'eisern',
  'eisig',
  'eistee',
  'eitel',
  'eiter',
  'eitern',
  'eitert',
  'eitrig',
  'ej',
  'ek',
  'ekd',
  'ekel',
  'ekelnd',
  'ekelt',
  'ekelte',
  'ekg',
  'eklig',
  'ekzem',
  'el',
  'elan',
  'elbe',
  'elch',
  'elend',
  'elf',
  'elfe',
  'elfte',
  'elite',
  'elle',
  'elsass',
  'elster',
  'eltern',
  'em',
  'email',
  'emb',
  'emblem',
  'embryo',
  'emesis',
  'emirat',
  'empor',
  'empore',
  'empyem',
  'emsig',
  'en',
  'end',
  'ende',
  'enden',
  'endend',
  'endet',
  'endlos',
  'endung',
  'eng',
  'engel',
  'enger',
  'enges',
  'engl',
  'engt',
  'engte',
  'enkel',
  'enorm',
  'ente',
  'enthob',
  'entity',
  'entkam',
  'entspr',
  'entzog',
  'entzug',
  'enzian',
  'enzym',
  'eo',
  'eozaen',
  'ep',
  'epilog',
  'episch',
  'epoche',
  'eponym',
  'epos',
  'eq',
  'er',
  'erb',
  'erbat',
  'erbaut',
  'erbe',
  'erbend',
  'erbin',
  'erbium',
  'erbot',
  'erbse',
  'erbt',
  'erde',
  'erden',
  'erdgas',
  'erdig',
  'erdoel',
  'ereilt',
  'ererbt',
  'erfand',
  'erfolg',
  'erfuhr',
  'erfurt',
  'ergab',
  'ergeben',
  'ergibt',
  'erguss',
  'erhalt',
  'erhebt',
  'erhob',
  'erholen',
  'erholt',
  'erker',
  'erlass',
  'erle',
  'erlebt',
  'erlegt',
  'erloes',
  'erneut',
  'ernst',
  'ernte',
  'ernten',
  'erntet',
  'erogen',
  'erotik',
  'errang',
  'erregt',
  'ersann',
  'ersatz',
  'erst',
  'erste',
  'erster',
  'erstes',
  'erw',
  'erwarb',
  'erwerb',
  'erz',
  'es',
  'esa',
  'esche',
  'esel',
  'espe',
  'ess',
  'essay',
  'essbar',
  'essen',
  'essenz',
  'esser',
  'essig',
  'este',
  'eszb',
  'et',
  'etage',
  'etappe',
  'etat',
  'ethanol',
  'ethik',
  'etuede',
  'etui',
  'etwa',
  'etwaig',
  'etwas',
  'etws',
  'eu',
  'euer',
  'eugh',
  'eule',
  'eunuch',
  'euro',
  'europa',
  'euter',
  'ev',
  'evoli',
  'evtl',
  'ew',
  'ewenke',
  'ewi',
  'ewig',
  'ewige',
  'ews',
  'ewwu',
  'ex',
  'exakt',
  'examen',
  'exil',
  'exkl',
  'exkurs',
  'expl',
  'export',
  'extern',
  'extra',
  'extrem',
  'exzess',
  'ey',
  'ez',
  'ezb',
  'f',
  'fabel',
  'fabrik',
  'fach',
  'fackel',
  'fad',
  'fade',
  'faden',
  'faehig',
  'faehre',
  'faehrt',
  'faekal',
  'faelle',
  'faellt',
  'faengt',
  'faerbt',
  'faerse',
  'fagott',
  'fahl',
  'fahne',
  'fahren',
  'fahrer',
  'fahrig',
  'fahrt',
  'faible',
  'fair',
  'fakir',
  'fakt',
  'fakten',
  'faktor',
  'falb',
  'falbel',
  'falke',
  'fall',
  'falle',
  'fallen',
  'falls',
  'falte',
  'falten',
  'falter',
  'faltet',
  'faltig',
  'falz',
  'falzen',
  'falzt',
  'fam',
  'famos',
  'fan',
  'fanal',
  'fand',
  'fang',
  'fangen',
  'fao',
  'farad',
  'farbe',
  'farbig',
  'farm',
  'farmer',
  'farn',
  'fasan',
  'fascia',
  'fase',
  'faseln',
  'faser',
  'fasert',
  'fass',
  'fasse',
  'fassen',
  'fasst',
  'fasste',
  'fast',
  'fasten',
  'fastet',
  'fatal',
  'faul',
  'faulen',
  'fauler',
  'faulig',
  'faulte',
  'faun',
  'fauna',
  'faust',
  'fax',
  'faxe',
  'fazit',
  'fb',
  'fbi',
  'fc',
  'fd',
  'fddi',
  'fdgb',
  'fdp',
  'fe',
  'feder',
  'fee',
  'fegen',
  'fegt',
  'fegten',
  'fehde',
  'fehl',
  'fehlen',
  'fehler',
  'fehlt',
  'fehlte',
  'feier',
  'feiern',
  'feiert',
  'feige',
  'feile',
  'feilen',
  'fein',
  'feind',
  'feine',
  'feiner',
  'feines',
  'feld',
  'felge',
  'fell',
  'fels',
  'felsig',
  'fender',
  'ferien',
  'ferkel',
  'fern',
  'ferne',
  'ferner',
  'ferrat',
  'ferrit',
  'ferse',
  'fertig',
  'fesch',
  'fessel',
  'fest',
  'feste',
  'fester',
  'festes',
  'festtag',
  'fete',
  'feten',
  'fett',
  'fette',
  'fetten',
  'fettig',
  'fetzen',
  'feucht',
  'feudal',
  'feuer',
  'feuern',
  'feurig',
  'ff',
  'fff',
  'ffff',
  'fg',
  'fgh',
  'fh',
  'fi',
  'fiasko',
  'fibel',
  'fichte',
  'ficht',
  'ficus',
  'fidel',
  'fieber',
  'fiedel',
  'fiedelt',
  'fiel',
  'fies',
  'fig',
  'figur',
  'fiktiv',
  'file',
  'filet',
  'film',
  'filme',
  'filmen',
  'filmte',
  'filter',
  'filz',
  'filzen',
  'filzig',
  'fimmel',
  'finale',
  'finanz',
  'finden',
  'finder',
  'findet',
  'findig',
  'fing',
  'finger',
  'finite',
  'fink',
  'finne',
  'finte',
  'firma',
  'firmen',
  'fisch',
  'fische',
  'fischt',
  'fiskus',
  'fistel',
  'fitis',
  'fixen',
  'fixend',
  'fixer',
  'fixt',
  'fixte',
  'fixum',
  'fj',
  'fjord',
  'fk',
  'fl',
  'flach',
  'flache',
  'flachs',
  'flag',
  'flagge',
  'flak',
  'flakon',
  'flame',
  'flamme',
  'flanke',
  'flansch',
  'flau',
  'flaum',
  'flaute',
  'fleck',
  'flegel',
  'flehen',
  'fleht',
  'flehte',
  'fleiss',
  'flickt',
  'flieder',
  'fliege',
  'fliegt',
  'flink',
  'flinte',
  'flirt',
  'flocke',
  'floete',
  'floez',
  'floh',
  'flora',
  'floss',
  'flosse',
  'flott',
  'flotte',
  'fluch',
  'flucht',
  'fluege',
  'fluenz',
  'flug',
  'flugs',
  'fluke',
  'flunke',
  'fluor',
  'flur',
  'flusen',
  'fluss',
  'flut',
  'fm',
  'fn',
  'fo',
  'fock',
  'foehn',
  'foehre',
  'foetal',
  'foetus',
  'fohlen',
  'fokal',
  'fokus',
  'folg',
  'folge',
  'folgen',
  'folger',
  'folgt',
  'folgte',
  'folie',
  'folter',
  'fond',
  'fondue',
  'foppen',
  'foppt',
  'foppte',
  'form',
  'formal',
  'format',
  'formel',
  'formen',
  'former',
  'formt',
  'formte',
  'forsch',
  'fort',
  'fortan',
  'forts',
  'fortsf',
  'forum',
  'fossil',
  'fotogen',
  'fp',
  'fq',
  'fr',
  'fracht',
  'frack',
  'fraese',
  'fraest',
  'frag',
  'frage',
  'fragen',
  'frager',
  'fragt',
  'fragte',
  'franko',
  'franse',
  'frass',
  'fratze',
  'frau',
  'frauen',
  'freak',
  'frech',
  'frei',
  'freie',
  'freier',
  'freies',
  'freite',
  'fremd',
  'fremde',
  'fresko',
  'freu',
  'freude',
  'freund',
  'freut',
  'freute',
  'frevel',
  'friede',
  'friert',
  'fries',
  'frisch',
  'frisst',
  'frist',
  'frisur',
  'fritte',
  'frivol',
  'frl',
  'froh',
  'frohe',
  'fromm',
  'fron',
  'front',
  'fror',
  'froren',
  'frosch',
  'frost',
  'frucht',
  'frueh',
  'fruehe',
  'frz',
  'fs',
  'ft',
  'fu',
  'fuchs',
  'fuder',
  'fuegt',
  'fuegte',
  'fuehlt',
  'fuehrt',
  'fuehrte',
  'fuelle',
  'fuellt',
  'fuenf',
  'fuer',
  'fuers',
  'fuerst',
  'fuge',
  'fugen',
  'fuhr',
  'fuhre',
  'fund',
  'fundus',
  'funke',
  'funkt',
  'furche',
  'furcht',
  'furie',
  'furier',
  'furt',
  'furz',
  'furzen',
  'fushun',
  'fusion',
  'fuss',
  'fussel',
  'futsch',
  'futter',
  'fuzhou',
  'fuzzy',
  'fv',
  'fw',
  'fx',
  'fy',
  'fz',
  'g',
  'gab',
  'gabel',
  'gabeln',
  'gabelt',
  'gabun',
  'gaehnt',
  'gaele',
  'gaemse',
  'gaeren',
  'gaffen',
  'gaffer',
  'gafft',
  'gaffte',
  'gag',
  'gage',
  'gala',
  'galant',
  'galgen',
  'galle',
  'gallen',
  'gallig',
  'galmei',
  'galopp',
  'galt',
  'galten',
  'gambe',
  'gambia',
  'gambit',
  'gamma',
  'gams',
  'gang',
  'ganove',
  'gans',
  'ganz',
  'ganze',
  'gap',
  'gar',
  'garage',
  'garant',
  'garbe',
  'garde',
  'garn',
  'garten',
  'gas',
  'gasse',
  'gast',
  'gatt',
  'gatte',
  'gatter',
  'gattin',
  'gaudi',
  'gaul',
  'gaumen',
  'gauner',
  'gaze',
  'gb',
  'gc',
  'gd',
  'ge',
  'geb',
  'gebaut',
  'geben',
  'geber',
  'gebet',
  'gebiet',
  'gebiss',
  'gebot',
  'gebr',
  'geburt',
  'geck',
  'gecko',
  'gedieh',
  'gedoest',
  'geduld',
  'geeggt',
  'geehrt',
  'geeilt',
  'geerbt',
  'gefahr',
  'gefeit',
  'gefiel',
  'gegen',
  'gegend',
  'gegner',
  'gegr',
  'geh',
  'gehabt',
  'gehalt',
  'gehaut',
  'gehege',
  'geheim',
  'gehen',
  'gehend',
  'geheul',
  'gehirn',
  'gehoer',
  'geholt',
  'gehren',
  'geht',
  'gehuft',
  'gehupt',
  'gehweg',
  'geier',
  'geige',
  'geiger',
  'geil',
  'geisel',
  'geiser',
  'geiss',
  'geist',
  'geiz',
  'geizen',
  'geizig',
  'gejagt',
  'gek',
  'gekaut',
  'gelage',
  'gelang',
  'gelass',
  'gelb',
  'gelbe',
  'gelber',
  'gelbes',
  'geld',
  'gelder',
  'gelee',
  'gelege',
  'gelegt',
  'geleit',
  'gelenk',
  'gelobt',
  'gelost',
  'gelten',
  'gemalt',
  'gemein',
  'gemuet',
  'gemuht',
  'gen',
  'genagt',
  'genas',
  'genau',
  'genaue',
  'genese',
  'genial',
  'genick',
  'genie',
  'genius',
  'genoss',
  'genre',
  'genua',
  'genug',
  'genus',
  'genuss',
  'geoelt',
  'george',
  'gepard',
  'gerade',
  'geraet',
  'gerast',
  'geraum',
  'gerben',
  'gerber',
  'gerede',
  'gering',
  'gern',
  'gerne',
  'gerste',
  'gerte',
  'geruch',
  'geruhe',
  'geruht',
  'ges',
  'gesaet',
  'gesagt',
  'gesamt',
  'gesang',
  'gesch',
  'gesenk',
  'gesetz',
  'gest',
  'geste',
  'gesuch',
  'gesund',
  'getagt',
  'getaut',
  'geteilt',
  'getobt',
  'getto',
  'getue',
  'getunkt',
  'geuebt',
  'gew',
  'gewagt',
  'gewahr',
  'gewalt',
  'gewand',
  'gewann',
  'gewebe',
  'gewebt',
  'gewehr',
  'geweht',
  'geweih',
  'gewinn',
  'gewirr',
  'gewiss',
  'gewogt',
  'gez',
  'gf',
  'gg',
  'ggf',
  'ggg',
  'gggg',
  'gh',
  'ghana',
  'ghi',
  'gi',
  'gib',
  'gibbon',
  'gibt',
  'gicht',
  'giebel',
  'gier',
  'gieren',
  'gierig',
  'gierte',
  'giesst',
  'gift',
  'giftig',
  'gigant',
  'gigolo',
  'gilde',
  'gimpe',
  'gimpel',
  'gin',
  'ging',
  'gingen',
  'gipfel',
  'gips',
  'gischt',
  'gitter',
  'gj',
  'gk',
  'gl',
  'glanz',
  'glas',
  'glaser',
  'glasig',
  'glasur',
  'glatt',
  'glatte',
  'glatze',
  'glaube',
  'glauben',
  'glaubt',
  'gleich',
  'gleis',
  'gleit',
  'gleite',
  'gleiten',
  'glich',
  'glied',
  'glimmt',
  'glitt',
  'global',
  'globus',
  'glocke',
  'glomm',
  'glossar',
  'glosse',
  'glotze',
  'glotzt',
  'glucke',
  'glueck',
  'glueht',
  'glukose',
  'glurak',
  'glut',
  'glycin',
  'gm',
  'gmbh',
  'gn',
  'gnade',
  'gneis',
  'gnom',
  'gnu',
  'go',
  'goetze',
  'gokart',
  'gold',
  'golden',
  'golf',
  'gon',
  'gondel',
  'gong',
  'gospel',
  'goss',
  'gosse',
  'gotik',
  'gott',
  'gp',
  'gps',
  'gq',
  'gr',
  'grab',
  'graben',
  'grad',
  'graebt',
  'graf',
  'grafik',
  'gram',
  'gramm',
  'granat',
  'grand',
  'grande',
  'granit',
  'granne',
  'grapen',
  'graph',
  'gras',
  'grasen',
  'grasig',
  'grat',
  'gratis',
  'grau',
  'grauen',
  'grauer',
  'gravur',
  'grease',
  'green',
  'greift',
  'greis',
  'grell',
  'grelle',
  'grenze',
  'grenzt',
  'griebe',
  'griess',
  'griff',
  'grill',
  'grille',
  'grimm',
  'grizzly',
  'grinst',
  'grippe',
  'grips',
  'grob',
  'grober',
  'grobes',
  'groelt',
  'grog',
  'groll',
  'gros',
  'gross',
  'grosse',
  'grotte',
  'grub',
  'grube',
  'gruen',
  'gruene',
  'gruess',
  'grufti',
  'grund',
  'grunge',
  'grunzt',
  'gruppe',
  'gruss',
  'gs',
  'gsm',
  'gt',
  'gu',
  'guam',
  'gucken',
  'guete',
  'gueter',
  'guetig',
  'guinea',
  'gummi',
  'gunst',
  'gurke',
  'gurren',
  'gurt',
  'guru',
  'gut',
  'gute',
  'guten',
  'guter',
  'guyana',
  'gv',
  'gvp',
  'gw',
  'gx',
  'gy',
  'gz',
  'h',
  'haar',
  'haarig',
  'hab',
  'habe',
  'haben',
  'hacke',
  'hacken',
  'hacker',
  'hackt',
  'hackte',
  'haelt',
  'haende',
  'haengt',
  'haerte',
  'haeufig',
  'haeuft',
  'haeuser',
  'hafen',
  'hafer',
  'haft',
  'haften',
  'haftet',
  'hagel',
  'hageln',
  'hagelt',
  'hagen',
  'hager',
  'haggis',
  'hahn',
  'hai',
  'hain',
  'haiti',
  'haken',
  'hakend',
  'hakig',
  'hakt',
  'hakte',
  'halab',
  'halali',
  'halb',
  'halber',
  'halbgar',
  'halde',
  'half',
  'halle',
  'hallo',
  'hallt',
  'hallte',
  'halm',
  'halme',
  'hals',
  'halse',
  'halsen',
  'halsig',
  'halt',
  'halte',
  'halten',
  'halter',
  'halts',
  'hamlet',
  'hammel',
  'hammer',
  'hand',
  'handan',
  'handel',
  'hanf',
  'hanfen',
  'hang',
  'hanoi',
  'hans',
  'hanse',
  'hantel',
  'harare',
  'harbin',
  'harem',
  'harfe',
  'harke',
  'harken',
  'hart',
  'harte',
  'harz',
  'harzen',
  'harzig',
  'hascht',
  'hase',
  'haspe',
  'hass',
  'hassen',
  'hasser',
  'hasst',
  'hast',
  'haste',
  'hasten',
  'hastet',
  'hastig',
  'hat',
  'hau',
  'haube',
  'hauch',
  'hauen',
  'hauend',
  'hauer',
  'haufen',
  'haupt',
  'haus',
  'haut',
  'haute',
  'hawaii',
  'hb',
  'hbf',
  'hc',
  'hd',
  'he',
  'heard',
  'hebel',
  'hebeln',
  'heben',
  'hebend',
  'hebt',
  'hecht',
  'heck',
  'hecke',
  'heckt',
  'heer',
  'hefe',
  'hefei',
  'hefig',
  'heften',
  'heftet',
  'heftig',
  'heide',
  'heikel',
  'heil',
  'heilen',
  'heiler',
  'heilig',
  'heilige',
  'heilung',
  'heim',
  'heimat',
  'heirat',
  'heiser',
  'heiss',
  'heiter',
  'heizen',
  'heizer',
  'heizt',
  'heizte',
  'hektar',
  'hektik',
  'held',
  'heldin',
  'helfen',
  'helfer',
  'helfs',
  'helium',
  'hell',
  'hellt',
  'helm',
  'hemd',
  'hemmen',
  'hemmt',
  'hemmte',
  'hengst',
  'henkel',
  'henker',
  'henne',
  'herauf',
  'heraus',
  'herb',
  'herbst',
  'herd',
  'herde',
  'herden',
  'herein',
  'hering',
  'hernia',
  'herold',
  'herpes',
  'herr',
  'herrin',
  'hertz',
  'herum',
  'hervor',
  'herz',
  'herzas',
  'herzig',
  'herzog',
  'hesse',
  'hessen',
  'hetze',
  'hetzen',
  'hetzer',
  'hetzt',
  'hetzte',
  'heu',
  'heuer',
  'heul',
  'heulen',
  'heult',
  'heulte',
  'heute',
  'heutig',
  'hexe',
  'hf',
  'hg',
  'hh',
  'hhh',
  'hhhh',
  'hi',
  'hieb',
  'hielt',
  'hier',
  'hierzu',
  'hiesig',
  'hiess',
  'hieven',
  'hij',
  'hilfe',
  'hilfen',
  'hilflos',
  'hilfs',
  'himmel',
  'hin',
  'hinab',
  'hinauf',
  'hinaus',
  'hindert',
  'hing',
  'hinken',
  'hinkt',
  'hins',
  'hinten',
  'hinter',
  'hinz',
  'hippie',
  'hirsch',
  'hirse',
  'hirt',
  'hit',
  'hitze',
  'hitzig',
  'hiv',
  'hj',
  'hk',
  'hl',
  'hm',
  'hmilch',
  'hmmm',
  'hn',
  'ho',
  'hob',
  'hobby',
  'hobel',
  'hobeln',
  'hobelt',
  'hoch',
  'hocken',
  'hocker',
  'hockey',
  'hockt',
  'hockte',
  'hoden',
  'hoehe',
  'hoehere',
  'hoehle',
  'hoelle',
  'hoer',
  'hoerbar',
  'hoeren',
  'hoerer',
  'hoert',
  'hoerte',
  'hof',
  'hoffen',
  'hofft',
  'hoffte',
  'hohe',
  'hoheit',
  'hoher',
  'hohes',
  'hohl',
  'hohler',
  'hohn',
  'hol',
  'hold',
  'holen',
  'holend',
  'holm',
  'holst',
  'holt',
  'holte',
  'holz',
  'holzen',
  'holzig',
  'hong',
  'honig',
  'honoel',
  'hopfen',
  'hoppla',
  'horcht',
  'horde',
  'hormon',
  'horn',
  'hornig',
  'horst',
  'hort',
  'horten',
  'hortet',
  'hose',
  'hosen',
  'hotel',
  'hotkey',
  'hp',
  'hq',
  'hr',
  'hrsg',
  'hs',
  'ht',
  'hu',
  'huang',
  'hub',
  'huefte',
  'huegel',
  'huelle',
  'huellt',
  'huelse',
  'huepft',
  'huepfte',
  'huerde',
  'huete',
  'hueten',
  'hueter',
  'huetet',
  'huette',
  'huf',
  'huhn',
  'huker',
  'human',
  'humbug',
  'hummel',
  'hummer',
  'humor',
  'humus',
  'hund',
  'hunde',
  'hunger',
  'hunne',
  'hupe',
  'hupen',
  'hurrikan',
  'hurghada',
  'hurley',
  'hurone',
  'hurra',
  'hurtig',
  'husar',
  'husten',
  'hut',
  'hv',
  'hw',
  'hx',
  'hy',
  'hyaene',
  'hybrid',
  'hydrid',
  'hymne',
  'hz',
  'i',
  'iallg',
  'iao',
  'iata',
  'ib',
  'ibadan',
  'ibitak',
  'ic',
  'ice',
  'ich',
  'id',
  'idaho',
  'ideal',
  'idee',
  'ideell',
  'idiot',
  'idol',
  'idylle',
  'ie',
  'if',
  'ig',
  'igel',
  'iglu',
  'ih',
  'ihf',
  'ihk',
  'ihm',
  'ihn',
  'ihnen',
  'ihr',
  'ihre',
  'ihrer',
  'ii',
  'iii',
  'iiii',
  'ij',
  'ijk',
  'ik',
  'ikone',
  'il',
  'ileus',
  'illegal',
  'iltis',
  'im',
  'image',
  'imbiss',
  'imker',
  'immer',
  'immun',
  'impfen',
  'impft',
  'impfte',
  'import',
  'impuls',
  'in',
  'indem',
  'inder',
  'index',
  'indien',
  'indigo',
  'indium',
  'indore',
  'info',
  'infra',
  'ing',
  'inger',
  'ingwer',
  'inh',
  'inhalt',
  'inka',
  'inkl',
  'inlett',
  'innen',
  'inner',
  'innere',
  'innung',
  'ins',
  'insekt',
  'insel',
  'insult',
  'intakt',
  'intern',
  'intim',
  'invers',
  'io',
  'iod',
  'iok',
  'ion',
  'ionen',
  'iowa',
  'ip',
  'iq',
  'ir',
  'ira',
  'irak',
  'iraker',
  'iran',
  'iraner',
  'irbil',
  'irden',
  'ire',
  'irin',
  'irisch',
  'iritis',
  'irk',
  'irland',
  'ironie',
  'irre',
  'irreal',
  'irren',
  'irrend',
  'irrig',
  'irrt',
  'irrtum',
  'irrweg',
  'is',
  'isbn',
  'isebel',
  'island',
  'iso',
  'isomer',
  'isotop',
  'israel',
  'ist',
  'it',
  'iu',
  'iv',
  'iw',
  'iwf',
  'ix',
  'iy',
  'iz',
  'izmir',
  'j',
  'jacht',
  'jacke',
  'jade',
  'jaeger',
  'jaeh',
  'jaeten',
  'jagd',
  'jagen',
  'jagend',
  'jagt',
  'jagte',
  'jaguar',
  'jahr',
  'jaipur',
  'jammer',
  'januar',
  'japan',
  'jargon',
  'jasmin',
  'jauche',
  'jaulen',
  'jault',
  'jaulte',
  'jawohl',
  'jawort',
  'jazz',
  'jb',
  'jc',
  'jd',
  'je',
  'jeans',
  'jede',
  'jeden',
  'jeder',
  'jedes',
  'jemals',
  'jemand',
  'jemen',
  'jene',
  'jener',
  'jetlag',
  'jetset',
  'jetten',
  'jetzt',
  'jf',
  'jg',
  'jh',
  'jhrl',
  'jhv',
  'ji',
  'jiddah',
  'jigger',
  'jilin',
  'jinan',
  'jj',
  'jjj',
  'jjjj',
  'jk',
  'jkl',
  'jl',
  'jm',
  'jn',
  'jo',
  'job',
  'joch',
  'jod',
  'jodeln',
  'jodelt',
  'jodler',
  'joga',
  'joggen',
  'johlen',
  'johlt',
  'joint',
  'jolle',
  'jordan',
  'jota',
  'joule',
  'jp',
  'jq',
  'jr',
  'js',
  'jt',
  'ju',
  'jubel',
  'jubeln',
  'jubelt',
  'jucken',
  'jude',
  'juedin',
  'jugend',
  'juli',
  'julius',
  'jumbo',
  'jumper',
  'jung',
  'junge',
  'jungen',
  'junger',
  'junges',
  'jungs',
  'juni',
  'junker',
  'junkie',
  'junta',
  'jur',
  'jura',
  'jurist',
  'jury',
  'justiz',
  'jute',
  'juwel',
  'jv',
  'jw',
  'jx',
  'jy',
  'jz',
  'k',
  'kabel',
  'kabine',
  'kabul',
  'kachel',
  'kacke',
  'kadaver',
  'kadenz',
  'kader',
  'kadett',
  'kaefer',
  'kaefig',
  'kaelte',
  'kaese',
  'kaeut',
  'kaeute',
  'kaff',
  'kaffee',
  'kahl',
  'kahle',
  'kahler',
  'kahmig',
  'kahn',
  'kai',
  'kaiser',
  'kajak',
  'kakadu',
  'kakao',
  'kaktee',
  'kalb',
  'kalben',
  'kalbte',
  'kalif',
  'kalium',
  'kalk',
  'kalkig',
  'kalkte',
  'kalt',
  'kalte',
  'kalter',
  'kaltes',
  'kam',
  'kamel',
  'kamera',
  'kamin',
  'kamm',
  'kammer',
  'kampf',
  'kanada',
  'kanal',
  'kandis',
  'kann',
  'kanne',
  'kannst',
  'kanone',
  'kanpur',
  'kansas',
  'kante',
  'kanter',
  'kanton',
  'kantor',
  'kanu',
  'kanzel',
  'kaolin',
  'kap',
  'kapaun',
  'kaper',
  'kapern',
  'kapier',
  'kappa',
  'kappe',
  'kapsel',
  'kaputt',
  'karat',
  'karate',
  'karbid',
  'karbol',
  'karbon',
  'karde',
  'kardex',
  'karo',
  'karre',
  'karren',
  'karst',
  'karte',
  'kartei',
  'karton',
  'kasack',
  'kasan',
  'kasino',
  'kasse',
  'kassel',
  'kaste',
  'kasten',
  'katar',
  'kate',
  'kater',
  'kath',
  'kation',
  'katode',
  'kattun',
  'katze',
  'kaubar',
  'kauen',
  'kauend',
  'kauer',
  'kauern',
  'kauert',
  'kauf',
  'kaufen',
  'kauft',
  'kaufte',
  'kaum',
  'kausal',
  'kaut',
  'kaute',
  'kauten',
  'kauz',
  'kaviar',
  'kb',
  'kc',
  'kd',
  'ke',
  'kebab',
  'keck',
  'kegel',
  'kegeln',
  'kegler',
  'kehle',
  'kehlig',
  'kehrer',
  'kehrt',
  'kehrte',
  'keifen',
  'keift',
  'keil',
  'keilt',
  'keilte',
  'keim',
  'keimen',
  'keimt',
  'kein',
  'keine',
  'keinen',
  'keiner',
  'keks',
  'kelch',
  'kelle',
  'keller',
  'keloid',
  'kelte',
  'kenia',
  'kennen',
  'kenner',
  'kenyer',
  'kerbe',
  'kerbel',
  'kerben',
  'kerbt',
  'kerbte',
  'kerker',
  'kerl',
  'kern',
  'kernal',
  'kernig',
  'kerze',
  'kessel',
  'keton',
  'kette',
  'kettet',
  'ketzer',
  'keucht',
  'keule',
  'keusch',
  'kf',
  'kfm',
  'kfz',
  'kg',
  'kh',
  'khulna',
  'ki',
  'kiefer',
  'kiel',
  'kieme',
  'kiepe',
  'kies',
  'kiesel',
  'kigali',
  'kilo',
  'kimm',
  'kimme',
  'kind',
  'kinder',
  'kinn',
  'kino',
  'kippe',
  'kippen',
  'kipper',
  'kippt',
  'kippte',
  'kirche',
  'kirmes',
  'kissen',
  'kiste',
  'kitsch',
  'kitt',
  'kittel',
  'kitten',
  'kittet',
  'kitz',
  'kitzel',
  'kj',
  'kk',
  'klappern',
  'kkkk',
  'kl',
  'kladde',
  'klaert',
  'klage',
  'klagen',
  'klagt',
  'klagte',
  'klamm',
  'klampe',
  'klan',
  'klang',
  'klappe',
  'klappt',
  'klaps',
  'klar',
  'klasse',
  'klaue',
  'klauen',
  'klause',
  'klaut',
  'klaute',
  'kleben',
  'kleber',
  'klebt',
  'klebte',
  'klecks',
  'klee',
  'kleid',
  'kleie',
  'klein',
  'kleine',
  'kleiner',
  'klemme',
  'klemmt',
  'klette',
  'klick',
  'klient',
  'kliff',
  'klima',
  'klinge',
  'klingt',
  'klinik',
  'klinke',
  'klinkt',
  'klippe',
  'klirrt',
  'klm',
  'klo',
  'kloake',
  'kloent',
  'klon',
  'klonen',
  'klotz',
  'klub',
  'kluft',
  'klug',
  'km',
  'kn',
  'knabe',
  'knackt',
  'knaeul',
  'knall',
  'knallt',
  'knapp',
  'knappe',
  'knarre',
  'knast',
  'knebel',
  'knecht',
  'kneift',
  'kneipe',
  'knete',
  'kneten',
  'knick',
  'knicks',
  'knie',
  'knien',
  'kniend',
  'kniet',
  'kniete',
  'kniff',
  'knilch',
  'knirps',
  'knogga',
  'knolle',
  'knopf',
  'knospe',
  'knoten',
  'knotet',
  'knotig',
  'knowhow',
  'knurrt',
  'knut',
  'knute',
  'ko',
  'kobalt',
  'kobold',
  'koch',
  'kochen',
  'kocher',
  'kocht',
  'kochte',
  'koda',
  'kodein',
  'kodex',
  'koeder',
  'koeln',
  'koenig',
  'koepft',
  'koernt',
  'koeter',
  'koffer',
  'kognak',
  'kohl',
  'kohle',
  'kohorte',
  'koitus',
  'koje',
  'kojote',
  'kokain',
  'kokett',
  'kokon',
  'koks',
  'kokuna',
  'kolben',
  'kolik',
  'kolleg',
  'kollege',
  'koller',
  'koloss',
  'komet',
  'komi',
  'komm',
  'komma',
  'kommen',
  'kommst',
  'kommt',
  'kondom',
  'kondor',
  'konkav',
  'konnte',
  'konsul',
  'konsum',
  'konter',
  'konto',
  'kontor',
  'kontra',
  'konus',
  'konvex',
  'kopal',
  'kopf',
  'kopie',
  'koppel',
  'koran',
  'korb',
  'kord',
  'kordel',
  'kordon',
  'korea',
  'korfu',
  'kork',
  'korken',
  'korn',
  'koror',
  'korse',
  'korund',
  'kosen',
  'kosmos',
  'kosovo',
  'kost',
  'kosten',
  'kostet',
  'kostuem',
  'kot',
  'kotig',
  'kotzen',
  'kp',
  'kpev',
  'kq',
  'kr',
  'krabbe',
  'krach',
  'kracht',
  'kraehe',
  'kraft',
  'kragen',
  'krake',
  'kralle',
  'krampf',
  'kran',
  'krank',
  'kranke',
  'kranz',
  'krass',
  'krater',
  'kratze',
  'kratzt',
  'krault',
  'kraus',
  'krause',
  'kraut',
  'krebs',
  'kredit',
  'kreide',
  'kreis',
  'kreist',
  'kreml',
  'krempe',
  'krepp',
  'kresse',
  'kreta',
  'kreter',
  'kreuz',
  'kreuzt',
  'krieg',
  'krill',
  'krim',
  'krimi',
  'kripo',
  'krippe',
  'krise',
  'kritik',
  'kroate',
  'kroch',
  'kroete',
  'krokus',
  'krone',
  'kropf',
  'kruemmt',
  'krug',
  'krumm',
  'krumme',
  'krung',
  'krupp',
  'kruste',
  'krypta',
  'ks',
  'kt',
  'kto',
  'ku',
  'kuala',
  'kuba',
  'kubist',
  'kuchen',
  'kuebel',
  'kueche',
  'kuehl',
  'kuehle',
  'kuehlt',
  'kuehn',
  'kueken',
  'kuer',
  'kueren',
  'kuerze',
  'kuerzt',
  'kuesst',
  'kueste',
  'kufe',
  'kugel',
  'kuguar',
  'kuh',
  'kulanz',
  'kuli',
  'kult',
  'kultur',
  'kummer',
  'kumpan',
  'kumpel',
  'kunde',
  'kunden',
  'kundig',
  'kunst',
  'kupfer',
  'kuppel',
  'kur',
  'kurare',
  'kurbel',
  'kurie',
  'kurier',
  'kuries',
  'kuriose',
  'kurort',
  'kurs',
  'kursiv',
  'kurve',
  'kurz',
  'kurze',
  'kurzen',
  'kurzer',
  'kurzum',
  'kuss',
  'kutte',
  'kuweit',
  'kv',
  'kw',
  'kx',
  'ky',
  'kyyiv',
  'kz',
  'l',
  'lab',
  'labend',
  'labern',
  'labial',
  'labil',
  'labor',
  'lache',
  'lachen',
  'lacher',
  'lachs',
  'lacht',
  'lachte',
  'lack',
  'lade',
  'laden',
  'ladend',
  'lader',
  'ladung',
  'laedt',
  'laehmt',
  'laenge',
  'laengs',
  'laerm',
  'laermt',
  'laesst',
  'laeuft',
  'lag',
  'lage',
  'lager',
  'lagern',
  'lagert',
  'lagos',
  'lagune',
  'lahm',
  'lahore',
  'laib',
  'laich',
  'laie',
  'lakai',
  'lake',
  'laken',
  'lamm',
  'lammen',
  'lampe',
  'land',
  'landen',
  'lang',
  'lange',
  'lanze',
  'laos',
  'laote',
  'lappen',
  'laptop',
  'larve',
  'larven',
  'las',
  'lasch',
  'lasche',
  'laser',
  'lass',
  'lassen',
  'lasso',
  'lasst',
  'last',
  'lasten',
  'laster',
  'lasur',
  'latein',
  'latent',
  'latte',
  'latz',
  'lau',
  'laub',
  'laube',
  'lauern',
  'lauert',
  'lauf',
  'laufen',
  'lauge',
  'laugen',
  'laugt',
  'laugte',
  'laune',
  'launig',
  'laurie',
  'laus',
  'lausen',
  'lausig',
  'lauste',
  'laut',
  'laute',
  'lauter',
  'lautes',
  'lava',
  'lawine',
  'layout',
  'lb',
  'lc',
  'ld',
  'le',
  'leb',
  'lebe',
  'leben',
  'lebend',
  'leber',
  'leblos',
  'lebst',
  'lebt',
  'lebte',
  'leck',
  'lecken',
  'lecker',
  'leckt',
  'led',
  'leder',
  'ledern',
  'ledig',
  'lee',
  'leeds',
  'leefze',
  'leer',
  'leere',
  'leeren',
  'leeres',
  'leert',
  'legal',
  'legat',
  'legen',
  'leger',
  'legion',
  'legt',
  'legte',
  'leguan',
  'lehen',
  'lehm',
  'lehmig',
  'lehne',
  'lehnen',
  'lehnt',
  'lehnte',
  'lehr',
  'lehre',
  'lehren',
  'lehrer',
  'lehrt',
  'lehrte',
  'leib',
  'leiche',
  'leicht',
  'leid',
  'leiden',
  'leider',
  'leidet',
  'leier',
  'leihe',
  'leihen',
  'leiht',
  'leim',
  'leimen',
  'leimt',
  'leine',
  'leinen',
  'leise',
  'leiser',
  'leiste',
  'leit',
  'leiten',
  'leiter',
  'leitet',
  'lektor',
  'lemma',
  'lena',
  'lende',
  'lenken',
  'lenker',
  'lenkt',
  'lenkte',
  'lenz',
  'lenzen',
  'leon',
  'lepra',
  'lerche',
  'lernen',
  'lernend',
  'lernst',
  'lernt',
  'lernte',
  'lesart',
  'lesbar',
  'lesbe',
  'lese',
  'lesen',
  'lesend',
  'leser',
  'lesung',
  'lette',
  'letzte',
  'leucin',
  'leute',
  'level',
  'lewis',
  'lf',
  'lfd',
  'lfg',
  'lg',
  'lh',
  'li',
  'libero',
  'libido',
  'libyen',
  'libyer',
  'licht',
  'lid',
  'lieb',
  'liebe',
  'lieben',
  'lieber',
  'liebes',
  'liebt',
  'lied',
  'lief',
  'liege',
  'liegen',
  'liegt',
  'lieh',
  'lies',
  'liess',
  'liest',
  'lift',
  'liga',
  'likoer',
  'lila',
  'lilie',
  'lille',
  'lima',
  'limbo',
  'limes',
  'linde',
  'lineal',
  'linear',
  'linie',
  'linke',
  'links',
  'linse',
  'linux',
  'lipid',
  'lippe',
  'liquor',
  'lisboa',
  'list',
  'liste',
  'listet',
  'listig',
  'litauer',
  'liter',
  'litze',
  'livedo',
  'lizenz',
  'lj',
  'lk',
  'lkw',
  'll',
  'llkw',
  'lll',
  'llll',
  'lm',
  'lmaa',
  'lmn',
  'ln',
  'lo',
  'lob',
  'loben',
  'lobend',
  'lobt',
  'lobte',
  'loch',
  'lochen',
  'lochia',
  'locke',
  'locken',
  'locker',
  'lockere',
  'lockt',
  'lockte',
  'loden',
  'lodz',
  'loesen',
  'loeser',
  'loess',
  'loest',
  'loeste',
  'loeten',
  'loetend',
  'loetet',
  'loewe',
  'log',
  'logge',
  'loggia',
  'logik',
  'logo',
  'lohn',
  'lohnen',
  'lohnt',
  'loipe',
  'lokal',
  'lokale',
  'lokum',
  'lome',
  'london',
  'lore',
  'los',
  'losbar',
  'lose',
  'loser',
  'losung',
  'lot',
  'loten',
  'lotion',
  'lotse',
  'loyal',
  'lp',
  'lq',
  'lr',
  'ls',
  'lt',
  'ltd',
  'ltg',
  'lu',
  'luanda',
  'luchs',
  'lud',
  'luecke',
  'lueg',
  'luege',
  'luegen',
  'luffa',
  'luft',
  'luftig',
  'luftweg',
  'luftzug',
  'lug',
  'luke',
  'lullt',
  'lullte',
  'lumbal',
  'lumen',
  'lunch',
  'lunge',
  'lungen',
  'lunker',
  'lupe',
  'lupine',
  'lurch',
  'lusaka',
  'lust',
  'lustig',
  'lustlos',
  'luth',
  'luv',
  'luven',
  'lux',
  'luxus',
  'lv',
  'lw',
  'lx',
  'ly',
  'lymphe',
  'lyon',
  'lyra',
  'lyrik',
  'lysin',
  'lyzeum',
  'lz',
  'm',
  'maat',
  'macau',
  'mach',
  'machen',
  'macher',
  'macho',
  'machs',
  'machst',
  'macht',
  'machte',
  'macke',
  'mad',
  'made',
  'madig',
  'madrid',
  'maedel',
  'maehen',
  'maeher',
  'maehne',
  'maeht',
  'maehte',
  'maekle',
  'maennl',
  'maerz',
  'mafia',
  'mag',
  'magd',
  'magen',
  'mager',
  'magere',
  'magern',
  'magert',
  'magie',
  'magnat',
  'magnet',
  'mahl',
  'mahlen',
  'mahlt',
  'mahlte',
  'mahnen',
  'mahnt',
  'mahnte',
  'mai',
  'maid',
  'maine',
  'mainz',
  'mais',
  'major',
  'majuro',
  'makel',
  'makler',
  'makro',
  'makula',
  'mal',
  'malabo',
  'malaie',
  'malawi',
  'male',
  'malen',
  'maler',
  'mali',
  'malier',
  'malt',
  'malta',
  'malve',
  'malz',
  'malzig',
  'mamey',
  'mammon',
  'mammut',
  'mampf',
  'man',
  'manama',
  'manaus',
  'manche',
  'mandat',
  'mandel',
  'manege',
  'mangan',
  'mangel',
  'mango',
  'manie',
  'manila',
  'manko',
  'mann',
  'mantel',
  'manual',
  'mappe',
  'maputo',
  'marder',
  'mariae',
  'marij',
  'marine',
  'mark',
  'marke',
  'marken',
  'markig',
  'markt',
  'marmor',
  'marone',
  'mars',
  'marsch',
  'marter',
  'masche',
  'maser',
  'masern',
  'maseru',
  'maske',
  'mass',
  'masse',
  'massig',
  'massiv',
  'masst',
  'mast',
  'matrix',
  'matsch',
  'matt',
  'matte',
  'mauer',
  'mauern',
  'mauert',
  'mauken',
  'maul',
  'maurer',
  'maus',
  'mauser',
  'mauzi',
  'max',
  'mazare',
  'mb',
  'mbh',
  'mc',
  'md',
  'mdb',
  'mdl',
  'me',
  'medan',
  'median',
  'medley',
  'meer',
  'meere',
  'meerut',
  'mega',
  'mehl',
  'mehlig',
  'mehr',
  'meiden',
  'meidet',
  'meile',
  'meiler',
  'mein',
  'meine',
  'meinen',
  'meiner',
  'meint',
  'meinte',
  'meise',
  'meist',
  'mekka',
  'mekong',
  'melden',
  'meldet',
  'meldung',
  'melken',
  'melkt',
  'melone',
  'menge',
  'mengt',
  'mensa',
  'mensch',
  'mensur',
  'mental',
  'mentor',
  'menue',
  'mergel',
  'merk',
  'merken',
  'merkte',
  'merkur',
  'merlin',
  'merzt',
  'mess',
  'messbar',
  'messe',
  'messen',
  'messer',
  'met',
  'metall',
  'meteor',
  'meter',
  'methan',
  'methyl',
  'metier',
  'metro',
  'metrum',
  'meute',
  'mewtu',
  'mexiko',
  'mez',
  'mf',
  'mfg',
  'mg',
  'mh',
  'mi',
  'miami',
  'miauen',
  'miaut',
  'miaute',
  'mich',
  'micro',
  'mieden',
  'mieder',
  'mief',
  'miene',
  'mies',
  'miete',
  'mieten',
  'mieter',
  'mietet',
  'mietze',
  'mikron',
  'milano',
  'milbe',
  'milch',
  'mild',
  'milde',
  'milieu',
  'miliz',
  'mill',
  'milz',
  'mimend',
  'mimik',
  'mimisch',
  'mimose',
  'mimt',
  'mimte',
  'min',
  'minder',
  'mine',
  'minor',
  'minsk',
  'minus',
  'minute',
  'minze',
  'mio',
  'mir',
  'misch',
  'mischt',
  'misst',
  'mist',
  'mistel',
  'mit',
  'mitose',
  'mittag',
  'mitte',
  'mittel',
  'mitten',
  'mittig',
  'mitw',
  'mixer',
  'mixt',
  'mixte',
  'mj',
  'mk',
  'ml',
  'mm',
  'mmm',
  'mmmm',
  'mn',
  'mno',
  'mo',
  'mobil',
  'mobile',
  'mod',
  'modal',
  'mode',
  'modell',
  'modem',
  'moder',
  'modern',
  'modrig',
  'modul',
  'modus',
  'moebel',
  'moebl',
  'moegen',
  'moehre',
  'moel',
  'moench',
  'moderne',
  'moewe',
  'mofa',
  'mogeln',
  'mogelt',
  'mogul',
  'mol',
  'moldau',
  'mole',
  'molke',
  'moll',
  'molle',
  'mollig',
  'mombi',
  'moment',
  'monaco',
  'monat',
  'mond',
  'monom',
  'monsun',
  'montag',
  'moor',
  'moos',
  'moosig',
  'mop',
  'moped',
  'mops',
  'moral',
  'morast',
  'morbid',
  'mord',
  'morden',
  'morgen',
  'moroni',
  'mosaik',
  'moskva',
  'moslem',
  'most',
  'motel',
  'motiv',
  'motor',
  'motte',
  'motto',
  'mousse',
  'mp',
  'mq',
  'mr',
  'mrd',
  'ms',
  'mt',
  'mtl',
  'mu',
  'muecke',
  'muede',
  'muehe',
  'muehle',
  'muell',
  'mueller',
  'muendig',
  'muenze',
  'muerbe',
  'muetze',
  'muff',
  'muffe',
  'muffig',
  'muhen',
  'muhend',
  'mulch',
  'mulde',
  'muldex',
  'mull',
  'mullah',
  'multan',
  'mumbai',
  'mumie',
  'mund',
  'munden',
  'mungo',
  'munter',
  'murmel',
  'murren',
  'mus',
  'muscat',
  'musisch',
  'muse',
  'museum',
  'musik',
  'musiker',
  'muskat',
  'muskel',
  'muss',
  'muster',
  'mut',
  'mutig',
  'mutlos',
  'mutter',
  'mutti',
  'mv',
  'mw',
  'mwst',
  'mx',
  'my',
  'myelom',
  'mykose',
  'myon',
  'myopie',
  'myosin',
  'myrrhe',
  'myrthe',
  'mystik',
  'mythos',
  'mz',
  'n',
  'nabe',
  'nabel',
  'nach',
  'nachm',
  'nacht',
  'nachts',
  'nacken',
  'nackig',
  'nackt',
  'nackte',
  'nadel',
  'naehe',
  'naehen',
  'naeher',
  'naeht',
  'naehte',
  'naeml',
  'naesse',
  'nagel',
  'nageln',
  'nagend',
  'nagoya',
  'nagpur',
  'nagt',
  'nah',
  'nahe',
  'nahen',
  'nahend',
  'nahezu',
  'nahm',
  'nahost',
  'naht',
  'naiv',
  'name',
  'namen',
  'namens',
  'nannte',
  'nanu',
  'naomi',
  'napalm',
  'napf',
  'napoli',
  'narbe',
  'narbig',
  'narr',
  'nasa',
  'nasal',
  'nase',
  'nashik',
  'nass',
  'nassau',
  'nation',
  'nato',
  'natter',
  'natur',
  'nauru',
  'nazi',
  'nb',
  'nc',
  'nchf',
  'nchr',
  'nd',
  'ne',
  'nebel',
  'neben',
  'neblig',
  'necken',
  'neckt',
  'neckte',
  'neffe',
  'neg',
  'negativ',
  'negieren',
  'nehmen',
  'neid',
  'neider',
  'neigen',
  'neigt',
  'neigte',
  'nein',
  'nektar',
  'nelke',
  'nennen',
  'nenner',
  'nennt',
  'neodym',
  'neogen',
  'neon',
  'nepal',
  'neppt',
  'neptun',
  'nerv',
  'nerven',
  'nervig',
  'nervt',
  'nervte',
  'nerz',
  'nessel',
  'nest',
  'nett',
  'netto',
  'netz',
  'neu',
  'neubau',
  'neue',
  'neuer',
  'neues',
  'neun',
  'neunte',
  'neural',
  'neuron',
  'nevada',
  'new',
  'nf',
  'ng',
  'ngo',
  'nh',
  'ni',
  'niamey',
  'nicht',
  'nichte',
  'nichts',
  'nickel',
  'nicken',
  'nie',
  'nieder',
  'niere',
  'nieren',
  'niesen',
  'niest',
  'niet',
  'niete',
  'nieten',
  'niger',
  'nigeria',
  'nil',
  'nimm',
  'nimmt',
  'ningbo',
  'nippel',
  'nippen',
  'nippt',
  'nische',
  'nisten',
  'nistet',
  'nitrit',
  'niue',
  'nix',
  'nixe',
  'niznij',
  'nizza',
  'nj',
  'nk',
  'nl',
  'nm',
  'nmal',
  'nn',
  'nnn',
  'nnnn',
  'no',
  'nobel',
  'noch',
  'nocke',
  'nodoes',
  'noetig',
  'nomade',
  'nonius',
  'nop',
  'noppe',
  'norden',
  'norm',
  'normal',
  'north',
  'not',
  'notar',
  'note',
  'noten',
  'notiz',
  'notruf',
  'nougat',
  'noumea',
  'np',
  'npd',
  'nq',
  'nr',
  'ns',
  'nt',
  'nu',
  'nudel',
  'nudist',
  'nuetze',
  'nuetzt',
  'nukleon',
  'null',
  'nullen',
  'nummer',
  'nun',
  'nur',
  'nuss',
  'nussig',
  'nut',
  'nutria',
  'nutte',
  'nutzen',
  'nutzer',
  'nuuk',
  'nv',
  'nw',
  'nx',
  'ny',
  'nylon',
  'nz',
  'o',
  'oas',
  'oase',
  'ob',
  'obb',
  'obdach',
  'oben',
  'obenab',
  'ober',
  'obere',
  'oberer',
  'oberst',
  'obige',
  'objekt',
  'oblag',
  'oblate',
  'oboe',
  'obst',
  'obwohl',
  'oc',
  'ochse',
  'ocker',
  'od',
  'ode',
  'oder',
  'odessa',
  'odor',
  'oe',
  'oecd',
  'oede',
  'oedem',
  'oeffne',
  'oelbad',
  'oelen',
  'oelend',
  'oeler',
  'oelig',
  'oelt',
  'oelte',
  'oese',
  'of',
  'ofen',
  'off',
  'offen',
  'offene',
  'offiz',
  'oft',
  'og',
  'oh',
  'ohchr',
  'ohg',
  'ohio',
  'ohm',
  'ohne',
  'ohr',
  'ohren',
  'oi',
  'oj',
  'ok',
  'oktal',
  'oktan',
  'oktant',
  'oktave',
  'okular',
  'ol',
  'oliv',
  'olive',
  'om',
  'oma',
  'oman',
  'omen',
  'omsk',
  'on',
  'onkel',
  'onyx',
  'oo',
  'ooo',
  'oooo',
  'op',
  'opa',
  'opal',
  'opec',
  'oper',
  'opfer',
  'opfern',
  'opfert',
  'opiat',
  'opium',
  'opq',
  'oprof',
  'optik',
  'option',
  'opus',
  'oq',
  'or',
  'orakel',
  'oral',
  'orange',
  'orden',
  'ordnen',
  'ordner',
  'ordnet',
  'oregon',
  'organ',
  'orgel',
  'orgie',
  'orient',
  'orig',
  'oring',
  'orion',
  'orkan',
  'ort',
  'orten',
  'ortet',
  'orth',
  'ortung',
  'os',
  'osaka',
  'oslo',
  'osmium',
  'osmose',
  'ossi',
  'ost',
  'osten',
  'ostern',
  'ostsee',
  'ot',
  'otitis',
  'ottawa',
  'otter',
  'ou',
  'ov',
  'oval',
  'ovipar',
  'ovum',
  'ow',
  'ox',
  'oxid',
  'oy',
  'oz',
  'ozean',
  'ozelot',
  'ozon',
  'p',
  'paar',
  'paaren',
  'paarig',
  'paart',
  'paarte',
  'pacht',
  'pack',
  'packen',
  'packer',
  'packt',
  'packte',
  'paddel',
  'padr',
  'paella',
  'paffen',
  'paffer',
  'pafft',
  'paffte',
  'pago',
  'pagode',
  'paket',
  'pakt',
  'palast',
  'palau',
  'palme',
  'pampa',
  'pampe',
  'panama',
  'panda',
  'panik',
  'panne',
  'pansen',
  'panter',
  'panzer',
  'pap',
  'papa',
  'papaya',
  'papel',
  'papier',
  'pappe',
  'pappel',
  'papst',
  'papua',
  'parade',
  'parese',
  'pari',
  'paris',
  'park',
  'parka',
  'parken',
  'parkt',
  'parole',
  'partei',
  'partie',
  'party',
  'pascal',
  'pass',
  'passen',
  'passiv',
  'passt',
  'passte',
  'paste',
  'pastor',
  'pasty',
  'patch',
  'pate',
  'patent',
  'pater',
  'patin',
  'patna',
  'patt',
  'patzer',
  'patzig',
  'patzte',
  'pauke',
  'pauken',
  'pause',
  'pausen',
  'pavian',
  'pb',
  'pc',
  'pd',
  'pe',
  'pecan',
  'pech',
  'pedant',
  'peer',
  'pegel',
  'pein',
  'pelvin',
  'pelz',
  'pendel',
  'peng',
  'penne',
  'penny',
  'pensum',
  'pepsin',
  'peptid',
  'per',
  'perle',
  'perm',
  'pers',
  'perser',
  'person',
  'perth',
  'peru',
  'pessar',
  'pest',
  'peter',
  'petit',
  'petrus',
  'petzen',
  'petzer',
  'petzte',
  'pf',
  'pfad',
  'pfahl',
  'pfand',
  'pfanne',
  'pfau',
  'pfd',
  'pfeife',
  'pfeil',
  'pferd',
  'pfiff',
  'pflege',
  'pflegt',
  'pflug',
  'pfote',
  'pfriem',
  'pfropf',
  'pfuhl',
  'pfui',
  'pfund',
  'pfusch',
  'pg',
  'ph',
  'phase',
  'phiole',
  'phlox',
  'phnum',
  'phobie',
  'phon',
  'phonem',
  'phrase',
  'phwert',
  'physik',
  'pi',
  'pickel',
  'picken',
  'piepen',
  'piepst',
  'pik',
  'pikant',
  'pilger',
  'pille',
  'pilot',
  'pilz',
  'piment',
  'pimms',
  'pin',
  'pinie',
  'pinkel',
  'pinne',
  'pinsel',
  'pipi',
  'piranha',
  'pirat',
  'pirol',
  'pirsch',
  'pissoir',
  'piste',
  'pizza',
  'pj',
  'pk',
  'pkt',
  'pkw',
  'pl',
  'plaene',
  'plage',
  'plagen',
  'plagiat',
  'plagt',
  'plagte',
  'plakat',
  'plan',
  'plane',
  'planen',
  'planet',
  'planke',
  'plante',
  'planum',
  'plasma',
  'platin',
  'platt',
  'platte',
  'platz',
  'platzt',
  'pleite',
  'plenum',
  'plombe',
  'plugin',
  'plump',
  'plumps',
  'plus',
  'pluto',
  'ply',
  'plz',
  'pm',
  'pn',
  'po',
  'pocke',
  'pocken',
  'podest',
  'podium',
  'poebel',
  'poesie',
  'poet',
  'pogrom',
  'pokal',
  'pol',
  'pole',
  'polen',
  'police',
  'polka',
  'pollen',
  'poller',
  'polo',
  'polung',
  'polyp',
  'pomade',
  'pomp',
  'poncho',
  'ponton',
  'pony',
  'pop',
  'popel',
  'popo',
  'pore',
  'porno',
  'poroes',
  'porree',
  'port',
  'portal',
  'porto',
  'pose',
  'posen',
  'posse',
  'possen',
  'post',
  'posten',
  'poster',
  'postum',
  'potent',
  'potenz',
  'pp',
  'ppa',
  'ppp',
  'pppp',
  'pq',
  'pqr',
  'pr',
  'pracht',
  'praegt',
  'praha',
  'prahlt',
  'praia',
  'prall',
  'prallt',
  'praxis',
  'preis',
  'preise',
  'preist',
  'presse',
  'presst',
  'prima',
  'prime',
  'primel',
  'prinz',
  'prise',
  'prisma',
  'privat',
  'pro',
  'proa',
  'probe',
  'proben',
  'probt',
  'prof',
  'profan',
  'profi',
  'profil',
  'profit',
  'prolet',
  'prolin',
  'prolog',
  'prompt',
  'propan',
  'prosa',
  'prosit',
  'proton',
  'protzt',
  'pruede',
  'prueft',
  'prunk',
  'ps',
  'psalm',
  'pseudo',
  'pst',
  'psyche',
  'pt',
  'ptose',
  'pu',
  'pudel',
  'puder',
  'pudern',
  'puebla',
  'pueblo',
  'pueree',
  'puerto',
  'pumuckl',
  'puffen',
  'puffer',
  'pulk',
  'puls',
  'pult',
  'pulver',
  'pulvert',
  'puma',
  'pumpe',
  'pumpen',
  'pumps',
  'pumpt',
  'pumpte',
  'pune',
  'punkt',
  'punkte',
  'punt',
  'puppe',
  'purist',
  'purpur',
  'pusan',
  'pustel',
  'puter',
  'putsch',
  'putten',
  'putz',
  'putze',
  'putzen',
  'putzt',
  'putzte',
  'puzzle',
  'pv',
  'pw',
  'px',
  'py',
  'pyogen',
  'pyrit',
  'python',
  'pz',
  'q',
  'qb',
  'qc',
  'qd',
  'qe',
  'qf',
  'qg',
  'qh',
  'qi',
  'qj',
  'qk',
  'qkm',
  'ql',
  'qm',
  'qn',
  'qo',
  'qp',
  'qq',
  'qqq',
  'qqqq',
  'qr',
  'qrs',
  'qs',
  'qt',
  'qu',
  'quader',
  'quaeket',
  'quaekt',
  'quaelt',
  'quaken',
  'quakt',
  'quakte',
  'qual',
  'qualle',
  'qualm',
  'quark',
  'quarte',
  'quarz',
  'quast',
  'quecke',
  'quelle',
  'quer',
  'querab',
  'queren',
  'quiche',
  'quiekt',
  'quillt',
  'quinte',
  'quirl',
  'quito',
  'quitt',
  'quitte',
  'quiz',
  'quoll',
  'quorum',
  'quote',
  'qv',
  'qw',
  'qx',
  'qy',
  'qz',
  'r',
  'rabat',
  'rabatt',
  'rabatz',
  'rabe',
  'rabiat',
  'rache',
  'rachen',
  'racker',
  'rad',
  'radar',
  'radau',
  'radelt',
  'radial',
  'radio',
  'radium',
  'radius',
  'radler',
  'radon',
  'radweg',
  'raecht',
  'raet',
  'raeude',
  'raeumt',
  'rage',
  'ragen',
  'ragout',
  'ragt',
  'rah',
  'rahm',
  'rahmen',
  'raichu',
  'rakel',
  'rakete',
  'rallye',
  'ramme',
  'rammen',
  'rammler',
  'rampe',
  'ranch',
  'rand',
  'rang',
  'range',
  'rank',
  'ranke',
  'rannte',
  'ranzen',
  'ranzig',
  'rapier',
  'rappe',
  'raps',
  'rar',
  'rasant',
  'rasch',
  'rasen',
  'rasend',
  'raser',
  'raserei',
  'raspel',
  'rasse',
  'rassel',
  'rassig',
  'rast',
  'raste',
  'rasten',
  'raster',
  'rasur',
  'rat',
  'rate',
  'raten',
  'ratend',
  'ratete',
  'ration',
  'ratlos',
  'ratsam',
  'rattan',
  'ratte',
  'rau',
  'raub',
  'rauben',
  'raubt',
  'raubte',
  'rauch',
  'raucht',
  'raufe',
  'raufen',
  'rauft',
  'rauhe',
  'rauher',
  'raum',
  'raupe',
  'raupy',
  'rausch',
  'raut',
  'raute',
  'rayon',
  'razzia',
  'rb',
  'rc',
  'rd',
  're',
  'reale',
  'reales',
  'rebe',
  'rebell',
  'rechen',
  'recht',
  'rechte',
  'rechts',
  'recife',
  'reck',
  'red',
  'rede',
  'reden',
  'redend',
  'redet',
  'redete',
  'redner',
  'reede',
  'reeder',
  'reell',
  'reelle',
  'reffen',
  'reflex',
  'reform',
  'reg',
  'regal',
  'regbez',
  'rege',
  'regel',
  'regeln',
  'regelt',
  'regen',
  'regent',
  'reggae',
  'regie',
  'regime',
  'region',
  'regler',
  'regnen',
  'regnet',
  'regt',
  'regte',
  'regula',
  'regung',
  'reh',
  'reiben',
  'reibt',
  'reich',
  'reicht',
  'reif',
  'reife',
  'reifen',
  'reift',
  'reihe',
  'reiher',
  'reiht',
  'reihte',
  'reihum',
  'reim',
  'reimt',
  'rein',
  'reine',
  'reinen',
  'reiner',
  'reis',
  'reise',
  'reisen',
  'reisst',
  'reist',
  'reiste',
  'reiten',
  'reiter',
  'reitet',
  'reiz',
  'reizen',
  'reizt',
  'reizte',
  'rekeln',
  'rekelt',
  'rekord',
  'rekrut',
  'rektal',
  'rektor',
  'rel',
  'relais',
  'relief',
  'relikt',
  'reling',
  'remis',
  'ren',
  'renin',
  'renn',
  'rennen',
  'renner',
  'rennt',
  'rente',
  'rep',
  'replik',
  'repro',
  'reptil',
  'resp',
  'rest',
  'reste',
  'retour',
  'rettan',
  'retten',
  'retter',
  'rettet',
  'reue',
  'reuig',
  'reuse',
  'revier',
  'revue',
  'rezept',
  'rf',
  'rg',
  'rh',
  'rhein',
  'rheuma',
  'rhode',
  'ri',
  'rias',
  'rieb',
  'riecht',
  'rief',
  'riefe',
  'riege',
  'riegel',
  'riemen',
  'ries',
  'riese',
  'riesen',
  'riesig',
  'riff',
  'riffig',
  'riga',
  'rille',
  'rind',
  'rinde',
  'rinden',
  'rinder',
  'ring',
  'ringen',
  'ringt',
  'rinne',
  'rinnen',
  'rio',
  'rippe',
  'risiko',
  'rispe',
  'riss',
  'risse',
  'rissig',
  'rist',
  'ritt',
  'ritter',
  'ritual',
  'ritus',
  'ritz',
  'ritze',
  'ritzel',
  'ritzen',
  'ritzt',
  'ritzte',
  'rivale',
  'rj',
  'rk',
  'rl',
  'rm',
  'rn',
  'ro',
  'robbe',
  'robe',
  'robust',
  'rochen',
  'rocker',
  'rocky',
  'rodeln',
  'rodelt',
  'roden',
  'rodend',
  'rodeo',
  'rodet',
  'rodete',
  'rodung',
  'roehre',
  'roem',
  'roemer',
  'roete',
  'roeten',
  'roeter',
  'roetet',
  'roggen',
  'roh',
  'rohbau',
  'rohe',
  'rohoel',
  'rohr',
  'rohre',
  'rolle',
  'rollen',
  'roller',
  'rollo',
  'rollt',
  'rollte',
  'rom',
  'roma',
  'roman',
  'romeo',
  'rondo',
  'rosa',
  'rose',
  'roseau',
  'rosig',
  'rosine',
  'ross',
  'rost',
  'rosten',
  'rostet',
  'rostig',
  'rot',
  'rote',
  'roter',
  'rotes',
  'rotte',
  'rottet',
  'rotz',
  'rotzig',
  'rouge',
  'route',
  'router',
  'rowdy',
  'rp',
  'rq',
  'rr',
  'rrr',
  'rrrr',
  'rs',
  'rst',
  'rt',
  'ru',
  'ruanda',
  'rubel',
  'rubin',
  'rubrik',
  'ruck',
  'rucken',
  'rudel',
  'ruder',
  'rudern',
  'rudert',
  'ruebe',
  'rueck',
  'rueckt',
  'ruede',
  'ruegen',
  'ruegt',
  'ruegte',
  'ruehmt',
  'ruehrt',
  'ruest',
  'ruesten',
  'ruf',
  'rufen',
  'ruft',
  'ruhe',
  'ruhen',
  'ruhend',
  'ruhig',
  'ruhige',
  'ruhm',
  'ruhr',
  'ruht',
  'ruhte',
  'ruin',
  'ruine',
  'rum',
  'rumba',
  'rummel',
  'rumpf',
  'rund',
  'runde',
  'runden',
  'rune',
  'runzel',
  'rupfen',
  'rupft',
  'rupie',
  'ruptur',
  'russ',
  'russe',
  'russig',
  'rute',
  'rutsch',
  'rv',
  'rw',
  'rx',
  'ry',
  'rz',
  's',
  'saal',
  'sabbat',
  'sacha',
  'sache',
  'sachen',
  'sachse',
  'sachte',
  'sack',
  'sacken',
  'sackt',
  'sackte',
  'sadist',
  'saebel',
  'saeen',
  'saeend',
  'saege',
  'saegen',
  'saeger',
  'saegt',
  'saemig',
  'saet',
  'saete',
  'saeugt',
  'saeule',
  'saeumt',
  'saeure',
  'safari',
  'safcon',
  'saflor',
  'safran',
  'saft',
  'saftig',
  'saga',
  'sage',
  'sagen',
  'sagend',
  'sago',
  'sagt',
  'sagte',
  'sah',
  'sahnig',
  'sahnt',
  'saint',
  'saite',
  'sakrale',
  'salami',
  'salat',
  'salbe',
  'salbei',
  'salbst',
  'salbt',
  'salbte',
  'saldo',
  'saline',
  'salm',
  'salon',
  'salopp',
  'salt',
  'salto',
  'salut',
  'salve',
  'salz',
  'salzen',
  'salzig',
  'salzt',
  'samara',
  'sambia',
  'same',
  'samen',
  'samoa',
  'sampan',
  'samt',
  'samtig',
  'san',
  'sana',
  'sand',
  'sandan',
  'sandig',
  'sandte',
  'sanft',
  'sang',
  'sank',
  'sanken',
  'sankt',
  'santa',
  'santo',
  'santos',
  'sao',
  'saphir',
  'sarg',
  'sari',
  'sarkom',
  'sass',
  'satan',
  'satin',
  'satire',
  'satt',
  'sattel',
  'saturn',
  'satyr',
  'satz',
  'satzbau',
  'sau',
  'sauber',
  'saudi',
  'sauer',
  'saufen',
  'sauft',
  'saufte',
  'saugen',
  'saugt',
  'saugte',
  'saum',
  'sauna',
  'sausen',
  'saust',
  'sauste',
  'sb',
  'sbb',
  'sc',
  'scampi',
  'schabe',
  'schach',
  'schade',
  'schaem',
  'schaf',
  'schaft',
  'schah',
  'schal',
  'schale',
  'schall',
  'schalt',
  'scham',
  'schar',
  'scharf',
  'schart',
  'schatz',
  'schau',
  'schaum',
  'schaut',
  'scheck',
  'schein',
  'scheit',
  'schell',
  'schelm',
  'schema',
  'scher',
  'schere',
  'scherz',
  'scheu',
  'scheut',
  'schick',
  'schieb',
  'schiebt',
  'schief',
  'schien',
  'schiff',
  'schild',
  'schilf',
  'schirm',
  'schlaf',
  'schlag',
  'schlau',
  'schluf',
  'schlug',
  'schmal',
  'schmerz',
  'schmus',
  'schnee',
  'schnur',
  'schob',
  'schock',
  'schoen',
  'schon',
  'schopf',
  'schor',
  'schorf',
  'schoss',
  'schot',
  'schott',
  'schrei',
  'schrie',
  'schrot',
  'schub',
  'schuber',
  'schuft',
  'schuh',
  'schuld',
  'schule',
  'schult',
  'schund',
  'schur',
  'schurz',
  'schuss',
  'schutt',
  'schutz',
  'schwan',
  'schwer',
  'schwor',
  'schwul',
  'schwur',
  'sd',
  'se',
  'seance',
  'sechs',
  'sechse',
  'sed',
  'see',
  'seebad',
  'seele',
  'seenot',
  'seeweg',
  'segel',
  'segeln',
  'segelnd',
  'segelt',
  'segen',
  'segler',
  'segnen',
  'segnet',
  'sehen',
  'seher',
  'sehne',
  'sehnig',
  'sehnt',
  'sehnte',
  'sehr',
  'sei',
  'seicht',
  'seide',
  'seiden',
  'seidig',
  'seien',
  'seiend',
  'seife',
  'seifig',
  'seift',
  'seil',
  'seiler',
  'sein',
  'seine',
  'seinen',
  'seiner',
  'seis',
  'seit',
  'seitan',
  'seite',
  'seiten',
  'seitig',
  'sekr',
  'sekret',
  'sekt',
  'sekte',
  'sektor',
  'selbe',
  'selber',
  'selbst',
  'selen',
  'selten',
  'semit',
  'senat',
  'sendai',
  'senden',
  'sender',
  'senf',
  'sengen',
  'senior',
  'senke',
  'senker',
  'senne',
  'senner',
  'sense',
  'sepsis',
  'septum',
  'seraph',
  'serbe',
  'serge',
  'serie',
  'serife',
  'serin',
  'sermon',
  'serum',
  'servo',
  'sesam',
  'sessel',
  'set',
  'setz',
  'setze',
  'setzei',
  'setzen',
  'setzer',
  'seuche',
  'sexist',
  'sexte',
  'sf',
  'sg',
  'sh',
  'shalom',
  'shanty',
  'sherpa',
  'shimmy',
  'shiraz',
  'show',
  'shunt',
  'si',
  'sich',
  'sichel',
  'sicher',
  'sicht',
  'sie',
  'sieb',
  'sieben',
  'siebt',
  'siebte',
  'sieche',
  'siecht',
  'sieden',
  'siedet',
  'sieg',
  'siegel',
  'siegen',
  'sieger',
  'siegt',
  'sieh',
  'siehe',
  'siehst',
  'sieht',
  'sierra',
  'signal',
  'signum',
  'silbe',
  'silben',
  'silber',
  'silur',
  'sims',
  'sind',
  'singen',
  'sinken',
  'sinkt',
  'sinn',
  'sinnen',
  'sinnes',
  'sinnig',
  'sinter',
  'sinus',
  'sioux',
  'siphon',
  'sippe',
  'sirene',
  'sirup',
  'sisal',
  'sitar',
  'sitte',
  'sitz',
  'sitzen',
  'sitzt',
  'sj',
  'sk',
  'skala',
  'skalar',
  'skalp',
  'skat',
  'skeet',
  'sketch',
  'ski',
  'skiff',
  'skizze',
  'sklave',
  'sklera',
  'skopje',
  'skotom',
  'sl',
  'slalom',
  'slam',
  'slang',
  'slawe',
  'slick',
  'slip',
  'slum',
  'slums',
  'sm',
  'smog',
  'sn',
  'snapin',
  'snob',
  'so',
  'so?',
  'sobald',
  'socke',
  'sockel',
  'soda',
  'soeben',
  'sofa',
  'sofern',
  'sofia',
  'sofort',
  'sog',
  'sogar',
  'sohle',
  'sohn',
  'soiree',
  'soja',
  'solch',
  'solche',
  'sold',
  'soldat',
  'solide',
  'solist',
  'soll',
  'sollen',
  'sollst',
  'sollte',
  'solo',
  'sommer',
  'sonate',
  'sonde',
  'sonett',
  'sonne',
  'sonnen',
  'sonnig',
  'sonor',
  'sonst',
  'sooft',
  'sopran',
  'sorbet',
  'sorge',
  'sorgen',
  'sorgt',
  'sorgte',
  'sorte',
  'sosse',
  'soul',
  'south',
  'soviel',
  'sowas',
  'soweit',
  'sowie',
  'sowjet',
  'sowohl',
  'sozial',
  'sp',
  'spaet',
  'spalt',
  'spalte',
  'spam',
  'spange',
  'spanne',
  'spant',
  'spar',
  'sparen',
  'sparer',
  'spass',
  'spat',
  'spatel',
  'spaten',
  'spatz',
  'spd',
  'specht',
  'speck',
  'speer',
  'speien',
  'speise',
  'spelz',
  'spende',
  'sperma',
  'sperre',
  'sphinx',
  'spiegel',
  'spiel',
  'spiess',
  'spike',
  'spinal',
  'spinat',
  'spinne',
  'spion',
  'spital',
  'spitz',
  'spitze',
  'spleen',
  'splice',
  'splint',
  'splitt',
  'spore',
  'sport',
  'spott',
  'spr',
  'spray',
  'spreu',
  'sprich',
  'sprit',
  'sproed',
  'spross',
  'spruch',
  'sprung',
  'spuck',
  'spucke',
  'spuele',
  'spuk',
  'spuken',
  'spule',
  'spund',
  'spur',
  'sputum',
  'sq',
  'sr',
  'sri',
  'ssid',
  'sss',
  'ssss',
  'st',
  'staat',
  'stab',
  'stabil',
  'stadt',
  'stahl',
  'staken',
  'stall',
  'stamm',
  'stand',
  'stange',
  'stanze',
  'stapel',
  'star',
  'stark',
  'starr',
  'start',
  'stase',
  'statik',
  'statur',
  'status',
  'statut',
  'stau',
  'staub',
  'stauen',
  'stauer',
  'staupe',
  'std',
  'steak',
  'steck',
  'steg',
  'stehen',
  'steher',
  'steht',
  'steif',
  'steig',
  'steile',
  'stein',
  'stele',
  'stell',
  'stelle',
  'stellt',
  'stellte',
  'stelze',
  'stent',
  'steppe',
  'steppen',
  'steppt',
  'steril',
  'stern',
  'stet',
  'steter',
  'stetig',
  'stets',
  'steuer',
  'stgb',
  'stich',
  'stieg',
  'stiel',
  'stier',
  'stiess',
  'stift',
  'stifter',
  'stigma',
  'stil',
  'still',
  'stille',
  'stillt',
  'stimme',
  'stimmt',
  'stinkt',
  'stippt',
  'stirbt',
  'stirn',
  'stock',
  'stockt',
  'stoer',
  'stoert',
  'stoff',
  'stola',
  'stolz',
  'stopft',
  'stopp',
  'storch',
  'stoss',
  'stout',
  'str',
  'straf',
  'strafe',
  'straff',
  'straft',
  'strahl',
  'stramm',
  'strand',
  'strang',
  'straps',
  'strebe',
  'strebt',
  'streik',
  'streit',
  'streng',
  'stress',
  'streut',
  'strich',
  'strick',
  'strikt',
  'stritt',
  'stroh',
  'strom',
  'stromer',
  'struma',
  'stu',
  'stuck',
  'stud',
  'student',
  'studie',
  'stueck',
  'stufe',
  'stuft',
  'stufte',
  'stuhl',
  'stumm',
  'stumpf',
  'stunde',
  'stupor',
  'stupst',
  'stur',
  'sturm',
  'sturz',
  'stute',
  'stutzen',
  'stutzt',
  'stvo',
  'styren',
  'su',
  'sublim',
  'subtil',
  'suche',
  'suchen',
  'sucher',
  'sucht',
  'suchte',
  'sudan',
  'sudeln',
  'sueden',
  'suehne',
  'suelze',
  'suende',
  'suess',
  'suesse',
  'suesst',
  'suhle',
  'suhlen',
  'suite',
  'sulfat',
  'sulfid',
  'sulfit',
  'sultan',
  'summe',
  'summen',
  'summer',
  'summt',
  'summte',
  'sumo',
  'sumpf',
  'sund',
  'sunnit',
  'super',
  'superb',
  'suppe',
  'surat',
  'surren',
  'surrt',
  'suva',
  'sv',
  'sw',
  'sx',
  'sy',
  'sydney',
  'symbol',
  'synode',
  'syntax',
  'syrer',
  'syrien',
  'system',
  'systole',
  'sz',
  'szene',
  't',
  'tabak',
  'tabor',
  'tabriz',
  'tabu',
  'tabula',
  'tadel',
  'tadeln',
  'tadelt',
  'tadler',
  'taefeln',
  'taegu',
  'taejon',
  'taeter',
  'taetig',
  'tafel',
  'taft',
  'tag',
  'tagaus',
  'tage',
  'tagen',
  'tagend',
  'tags',
  'tagt',
  'tagung',
  'taifun',
  'taille',
  'tainan',
  'taipei',
  'taiwan',
  'takel',
  'takeln',
  'takler',
  'takt',
  'taktik',
  'tal',
  'talent',
  'talg',
  'talgig',
  'talk',
  'talkum',
  'talon',
  'tampa',
  'tampon',
  'tamtam',
  'tan',
  'tand',
  'tandem',
  'tang',
  'tango',
  'tank',
  'tanken',
  'tanker',
  'tankt',
  'tankte',
  'tanne',
  'tannin',
  'tantal',
  'tante',
  'tanz',
  'tanzen',
  'tanzt',
  'tanzte',
  'tapete',
  'tapfer',
  'tapsen',
  'tapst',
  'tara',
  'tarawa',
  'tardiv',
  'tarif',
  'tarnen',
  'tarnte',
  'tarock',
  'tartan',
  'tasche',
  'tasse',
  'tast',
  'taste',
  'tasten',
  'taster',
  'tastet',
  'tat',
  'tatar',
  'taten',
  'tatort',
  'tau',
  'taub',
  'taube',
  'tauben',
  'taubsi',
  'taucht',
  'tauen',
  'taufe',
  'taufen',
  'tauft',
  'taufte',
  'taugen',
  'taumel',
  'tausch',
  'taut',
  'taute',
  'taxi',
  'tb',
  'tbruch',
  'tc',
  'td',
  'te',
  'teak',
  'tee',
  'teeei',
  'teer',
  'teeren',
  'teflon',
  'tehran',
  'teich',
  'teig',
  'teigig',
  'teil',
  'teilen',
  'teiler',
  'teils',
  'teilt',
  'teilte',
  'tel',
  'teller',
  'tellur',
  'tempeh',
  'tempel',
  'tempo',
  'tender',
  'tenne',
  'tennis',
  'tenor',
  'tensid',
  'term',
  'termin',
  'terror',
  'terz',
  'test',
  'testen',
  'tester',
  'testet',
  'teuer',
  'teufel',
  'texas',
  'text',
  'texter',
  'textil',
  'tf',
  'tg',
  'tgl',
  'th',
  'thai',
  'thanh',
  'theist',
  'theke',
  'thema',
  'themse',
  'therme',
  'these',
  'thiamin',
  'thread',
  'thron',
  'thront',
  'thymus',
  'ti',
  'tiara',
  'tibet',
  'tic',
  'tick',
  'ticken',
  'tickt',
  'tief',
  'tiefe',
  'tiefer',
  'tiegel',
  'tier',
  'tiger',
  'tigris',
  'tilde',
  'tilgen',
  'tilgt',
  'tilgte',
  'timon',
  'tinte',
  'tipp',
  'tippen',
  'tippt',
  'tippte',
  'tirade',
  'tirana',
  'tirol',
  'tisch',
  'titan',
  'titel',
  'titer',
  'titanic',
  'titus',
  'tj',
  'tk',
  'tl',
  'tm',
  'tn',
  'to',
  'toben',
  'tobend',
  'tobt',
  'tobte',
  'tod',
  'toddy',
  'toenen',
  'toerin',
  'toeten',
  'toetet',
  'tofu',
  'togo',
  'tokelau',
  'tokyo',
  'toll',
  'tolle',
  'tollen',
  'tollt',
  'toluca',
  'tomate',
  'tombak',
  'ton',
  'tonarm',
  'tonart',
  'tonbad',
  'tonga',
  'tonika',
  'tonlos',
  'tonne',
  'tonsur',
  'tonus',
  'topas',
  'topf',
  'tophus',
  'topp',
  'tor',
  'torf',
  'torino',
  'torlos',
  'torte',
  'torweg',
  'tory',
  'tot',
  'total',
  'totale',
  'tote',
  'totem',
  'totes',
  'toupet',
  'tour',
  'toxin',
  'tp',
  'tq',
  'tr',
  'trab',
  'traber',
  'tracht',
  'traege',
  'traegt',
  'traene',
  'traenen',
  'traf',
  'trage',
  'tragen',
  'tragik',
  'trakt',
  'trampt',
  'trance',
  'trank',
  'trapez',
  'trat',
  'tratte',
  'trau',
  'traube',
  'trauen',
  'trauer',
  'traufe',
  'traum',
  'trauma',
  'traut',
  'traute',
  'treck',
  'treib',
  'treibt',
  'tremor',
  'trend',
  'trennt',
  'treppe',
  'tresor',
  'treten',
  'treu',
  'treue',
  'treuer',
  'trias',
  'trick',
  'trieb',
  'trieft',
  'triel',
  'trifft',
  'triftig',
  'trikot',
  'trimm',
  'trinkt',
  'trist',
  'tritt',
  'trog',
  'troja',
  'trollt',
  'tropf',
  'trost',
  'trott',
  'trotz',
  'trotzt',
  'truebe',
  'truebt',
  'trug',
  'trumpf',
  'truppe',
  'ts',
  'tschad',
  'tshirt',
  'tt',
  'ttt',
  'tttt',
  'tu',
  'tuba',
  'tube',
  'tuch',
  'tucuxi',
  'tue',
  'tuell',
  'tuelle',
  'tuer',
  'tuerke',
  'tuev',
  'tugend',
  'tulpe',
  'tumor',
  'tun',
  'tundra',
  'tuner',
  'tunika',
  'tunis',
  'tunkt',
  'tunkte',
  'tunnel',
  'tunken',
  'tupel',
  'turban',
  'turbo',
  'turf',
  'turks',
  'turm',
  'turnen',
  'turnt',
  'turnte',
  'turtok',
  'tusche',
  'tut',
  'tuten',
  'tuv',
  'tuva',
  'tuvalu',
  'tv',
  'tw',
  'tx',
  'ty',
  'type',
  'typhus',
  'tz',
  'u',
  'uawg',
  'ub',
  'ubahn',
  'uboot',
  'uc',
  'ud',
  'udssr',
  'ue',
  'uebel',
  'ueben',
  'uebend',
  'ueber',
  'uebers',
  'ueble',
  'uebrig',
  'uebte',
  'uebung',
  'uefa',
  'ueppig',
  'uf',
  'ufa',
  'ufer',
  'uff',
  'ufo',
  'ufz',
  'ug',
  'uganda',
  'uh',
  'uhf',
  'uhr',
  'uhu',
  'ui',
  'uie',
  'uj',
  'uk',
  'ukw',
  'ul',
  'ulcus',
  'ulk',
  'ulkig',
  'ulkus',
  'ulme',
  'ulsan',
  'ulster',
  'ultimo',
  'ultra',
  'um',
  'umarmt',
  'umbau',
  'umber',
  'umfang',
  'umfeld',
  'umflog',
  'umgab',
  'umgeht',
  'umgibt',
  'umging',
  'umhang',
  'umin',
  'umkehr',
  'umlage',
  'umlauf',
  'umlaut',
  'umluft',
  'umriss',
  'ums',
  'umsatz',
  'umweg',
  'umwelt',
  'umzu',
  'umzug',
  'un',
  'unart',
  'uncc',
  'unccd',
  'uncdf',
  'uncrd',
  'unctad',
  'und',
  'undank',
  'undcp',
  'undp',
  'uneben',
  'unecht',
  'unedel',
  'unehre',
  'unep',
  'unesco',
  'unevoc',
  'unfair',
  'unfall',
  'unfccc',
  'unfein',
  'unfpa',
  'unfug',
  'ungar',
  'ungarn',
  'ungern',
  'ungute',
  'unhchr',
  'unhcr',
  'unheil',
  'uni',
  'unicef',
  'unicri',
  'unidir',
  'union',
  'unklar',
  'unklug',
  'unlieb',
  'unmut',
  'unops',
  'unreif',
  'unrein',
  'unrisd',
  'unruh',
  'unruhe',
  'unrwa',
  'uns',
  'unser',
  'unsere',
  'unsinn',
  'unstet',
  'untat',
  'unten',
  'unter',
  'untere',
  'unterm',
  'untier',
  'untreu',
  'unu',
  'unv',
  'unwahr',
  'unweit',
  'unwohl',
  'unzahl',
  'unzart',
  'unze',
  'unzeit',
  'uo',
  'up',
  'upu',
  'uq',
  'ur',
  'uralt',
  'uran',
  'uranus',
  'urban',
  'urform',
  'urgenz',
  'urin',
  'urinal',
  'urlaub',
  'urlaut',
  'urne',
  'urteil',
  'urumqi',
  'urwald',
  'urzeit',
  'us',
  'usa',
  'usw',
  'ut',
  'utah',
  'utopia',
  'utopie',
  'uu',
  'uuu',
  'uuuu',
  'uv',
  'uvw',
  'uw',
  'ux',
  'uy',
  'uz',
  'v',
  'vaduz',
  'vaeter',
  'vagal',
  'vage',
  'vakuum',
  'valenz',
  'valin',
  'valuta',
  'vamp',
  'vampir',
  'vasall',
  'vase',
  'vater',
  'vati',
  'vb',
  'vc',
  'vchr',
  'vd',
  've',
  'veb',
  'vektor',
  'velar',
  'velin',
  'vene',
  'venoes',
  'ventil',
  'venus',
  'verb',
  'verbal',
  'verbat',
  'verbot',
  'verein',
  'verf',
  'vergab',
  'verh',
  'verl',
  'verlag',
  'verlor',
  'verrat',
  'verruf',
  'vers',
  'versatz',
  'versen',
  'vertan',
  'verw',
  'verzug',
  'vespern',
  'vf',
  'vform',
  'vg',
  'vgl',
  'vh',
  'vhf',
  'vhs',
  'vi',
  'video',
  'vieh',
  'viel',
  'viele',
  'vielen',
  'vieles',
  'vier',
  'vierte',
  'vikar',
  'villa',
  'vinyl',
  'vip',
  'viper',
  'virus',
  'visage',
  'vision',
  'visum',
  'vj',
  'vk',
  'vl',
  'vlies',
  'vm',
  'vn',
  'vo',
  'voegel',
  'vogel',
  'vogt',
  'voile',
  'volant',
  'volk',
  'voll',
  'voller',
  'volt',
  'vom',
  'von',
  'voodoo',
  'vor',
  'vorab',
  'voran',
  'voraus',
  'vorbau',
  'vordem',
  'vorfall',
  'vorher',
  'vorhin',
  'vorhof',
  'vorhut',
  'vorige',
  'vorlaut',
  'vorm',
  'vormals',
  'vorn',
  'vorne',
  'vorort',
  'vorrat',
  'vors',
  'vorweg',
  'vorzug',
  'vp',
  'vq',
  'vr',
  'vrumpf',
  'vs',
  'vt',
  'vu',
  'vulkan',
  'vulva',
  'vuz',
  'vv',
  'vvv',
  'vvvv',
  'vw',
  'vwx',
  'vx',
  'vy',
  'vz',
  'w',
  'waage',
  'wabe',
  'wach',
  'wache',
  'wachen',
  'wachs',
  'wachst',
  'wacht',
  'wachte',
  'wacker',
  'wade',
  'waegen',
  'waeger',
  'waehle',
  'waehlt',
  'waehnt',
  'waelzt',
  'waende',
  'waere',
  'waeren',
  'waerme',
  'waermt',
  'waffe',
  'waffel',
  'waffen',
  'wagen',
  'waggon',
  'wagnis',
  'wahl',
  'wahn',
  'wal',
  'wald',
  'waldig',
  'wales',
  'walker',
  'wall',
  'wallis',
  'waltz',
  'walze',
  'walzen',
  'walzer',
  'walzte',
  'wamme',
  'wand',
  'wandel',
  'wandte',
  'wange',
  'wanken',
  'wankt',
  'wann',
  'wanze',
  'wapiti',
  'wappen',
  'war',
  'warb',
  'ware',
  'waren',
  'warf',
  'warm',
  'warme',
  'warnen',
  'warnt',
  'warnte',
  'warpt',
  'warte',
  'warten',
  'wartet',
  'warum',
  'warze',
  'warzig',
  'was',
  'wasser',
  'waten',
  'watt',
  'watte',
  'wb',
  'wbl',
  'wc',
  'wd',
  'wdhlg',
  'we',
  'weben',
  'webend',
  'weber',
  'webt',
  'webte',
  'wecken',
  'wecker',
  'wedel',
  'wedeln',
  'weder',
  'weg',
  'wegen',
  'weh',
  'wehe',
  'wehen',
  'wehend',
  'wehmut',
  'wehr',
  'wehrt',
  'wehrte',
  'weht',
  'wehte',
  'weich',
  'weiche',
  'weicht',
  'weide',
  'weiden',
  'weidend',
  'weidet',
  'weih',
  'weihe',
  'weiht',
  'weihte',
  'weil',
  'weile',
  'wein',
  'weinen',
  'weint',
  'weinte',
  'weise',
  'weisen',
  'weiss',
  'weisse',
  'weist',
  'weit',
  'weite',
  'weiter',
  'weizen',
  'welch',
  'welche',
  'welk',
  'welken',
  'welle',
  'wellen',
  'wellig',
  'wellt',
  'welpe',
  'welt',
  'wem',
  'wen',
  'wende',
  'wenden',
  'wendet',
  'wendig',
  'wenig',
  'wenn',
  'wer',
  'werben',
  'werber',
  'werbt',
  'werde',
  'werden',
  'werfen',
  'werfer',
  'werft',
  'werg',
  'werk',
  'wermut',
  'wert',
  'wertet',
  'wertig',
  'wesen',
  'wesir',
  'wespe',
  'wessen',
  'wessi',
  'west',
  'weste',
  'westen',
  'wette',
  'wetten',
  'wetter',
  'wettet',
  'wetzen',
  'wetzt',
  'wetzte',
  'wez',
  'wf',
  'wg',
  'wh',
  'whg',
  'whisky',
  'whist',
  'who',
  'wi',
  'wich',
  'wicht',
  'wicke',
  'widder',
  'wider',
  'widert',
  'widmen',
  'widmet',
  'widrig',
  'wie',
  'wie?',
  'wied',
  'wieder',
  'wiege',
  'wiegen',
  'wiegt',
  'wiegte',
  'wien',
  'wiener',
  'wies',
  'wiese',
  'wiesel',
  'wigwam',
  'wild',
  'wilde',
  'wildes',
  'wille',
  'willig',
  'willst',
  'wimmern',
  'wimper',
  'wind',
  'winde',
  'windei',
  'windel',
  'winden',
  'windet',
  'windig',
  'wink',
  'winkel',
  'winken',
  'winker',
  'winkt',
  'winkte',
  'winsch',
  'winter',
  'winzig',
  'wipfel',
  'wippen',
  'wippt',
  'wippte',
  'wir',
  'wirbel',
  'wirbt',
  'wird',
  'wirft',
  'wirken',
  'wirkt',
  'wirkte',
  'wirr',
  'wirt',
  'wirtin',
  'wisch',
  'wischt',
  'wisent',
  'wissen',
  'witwe',
  'witwer',
  'witz',
  'witzig',
  'wj',
  'wk',
  'wl',
  'wm',
  'wn',
  'wo',
  'wobei',
  'woche',
  'wodka',
  'woelbt',
  'wofuer',
  'wog',
  'woge',
  'wogen',
  'wogend',
  'wogt',
  'wogte',
  'woher',
  'wohin',
  'wohl',
  'wohn',
  'wohnen',
  'wok',
  'wolf',
  'wolga',
  'wolke',
  'wolkig',
  'wolle',
  'wollen',
  'wollig',
  'wollte',
  'womit',
  'wonach',
  'wonne',
  'woran',
  'worauf',
  'woraus',
  'worden',
  'worin',
  'world',
  'wort',
  'worte',
  'worum',
  'wovon',
  'wovor',
  'wozu',
  'wp',
  'wq',
  'wr',
  'wrack',
  'wringt',
  'ws',
  'wsa',
  'wt',
  'wto',
  'wu',
  'wucher',
  'wuchs',
  'wucht',
  'wuehler',
  'wuerde',
  'wuerger',
  'wuergt',
  'wuerze',
  'wuerzen',
  'wuest',
  'wueste',
  'wuetet',
  'wuhan',
  'wulst',
  'wund',
  'wunde',
  'wunder',
  'wunsch',
  'wurde',
  'wurden',
  'wurf',
  'wurm',
  'wurmig',
  'wurst',
  'wurzel',
  'wusch',
  'wusste',
  'wut',
  'wuxi',
  'wv',
  'ww',
  'www',
  'wwww',
  'wx',
  'wxy',
  'wy',
  'wz',
  'x',
  'xb',
  'xbeine',
  'xc',
  'xd',
  'xe',
  'xenon',
  'xf',
  'xfache',
  'xg',
  'xh',
  'xi',
  'xian',
  'xj',
  'xk',
  'xl',
  'xm',
  'xmal',
  'xn',
  'xo',
  'xp',
  'xq',
  'xr',
  'xs',
  'xt',
  'xu',
  'xuzhou',
  'xv',
  'xw',
  'xx',
  'xxx',
  'xxxx',
  'xy',
  'xyz',
  'xz',
  'y',
  'yang',
  'yangon',
  'yard',
  'yawl',
  'yb',
  'yc',
  'yd',
  'ye',
  'yen',
  'yeti',
  'yf',
  'yg',
  'yh',
  'yi',
  'yj',
  'yk',
  'yl',
  'ym',
  'yn',
  'yo',
  'yoga',
  'yp',
  'yq',
  'yr',
  'ys',
  'yt',
  'yu',
  'yucca',
  'yuppie',
  'yv',
  'yw',
  'yx',
  'yy',
  'yyy',
  'yyyy',
  'yz',
  'z',
  'zacke',
  'zacken',
  'zackig',
  'zaeh',
  'zaeher',
  'zaehl',
  'zaehlt',
  'zaehmt',
  'zaehne',
  'zaeunt',
  'zagreb',
  'zahl',
  'zahlen',
  'zahlt',
  'zahlte',
  'zahm',
  'zahn',
  'zahnen',
  'zahnt',
  'zahnte',
  'zander',
  'zange',
  'zank',
  'zanken',
  'zankt',
  'zankte',
  'zapfen',
  'zappen',
  'zar',
  'zarge',
  'zarin',
  'zart',
  'zaster',
  'zauber',
  'zaum',
  'zaun',
  'zausen',
  'zaust',
  'zauste',
  'zb',
  'zc',
  'zd',
  'zdf',
  'ze',
  'zebra',
  'zebu',
  'zechen',
  'zecher',
  'zecht',
  'zechte',
  'zecke',
  'zeder',
  'zehe',
  'zehn',
  'zehner',
  'zehnte',
  'zehren',
  'zehrt',
  'zeigen',
  'zeiger',
  'zeigt',
  'zeihen',
  'zeile',
  'zeilen',
  'zeit',
  'zeitgen',
  'zelle',
  'zelot',
  'zelt',
  'zelten',
  'zelter',
  'zement',
  'zenit',
  'zensur',
  'zephir',
  'zepter',
  'zerren',
  'zerrt',
  'zerrte',
  'zettel',
  'zeug',
  'zeuge',
  'zeugen',
  'zeugt',
  'zeugte',
  'zf',
  'zg',
  'zh',
  'zhd',
  'zi',
  'zibo',
  'zich',
  'zickzack',
  'zilk',
  'ziege',
  'ziegel',
  'zieh',
  'ziehe',
  'ziehen',
  'zieht',
  'ziel',
  'zielen',
  'zielt',
  'ziemen',
  'ziemt',
  'zier',
  'zierde',
  'ziert',
  'ziffer',
  'zikade',
  'zimmer',
  'zimt',
  'zinder',
  'zink',
  'zinke',
  'zinken',
  'zinn',
  'zinnen',
  'zinnie',
  'zins',
  'zirkon',
  'zirkus',
  'zirpen',
  'zischt',
  'zitat',
  'zither',
  'zitrat',
  'zitrus',
  'zitze',
  'zivil',
  'zj',
  'zk',
  'zl',
  'zm',
  'zn',
  'zo',
  'zobel',
  'zoelle',
  'zofe',
  'zog',
  'zogen',
  'zoll',
  'zombie',
  'zonal',
  'zone',
  'zonen',
  'zoomt',
  'zoomte',
  'zopf',
  'zorn',
  'zornig',
  'zotte',
  'zottig',
  'zp',
  'zq',
  'zr',
  'zs',
  'zt',
  'ztg',
  'ztschr',
  'zu',
  'zucht',
  'zucken',
  'zucker',
  'zuckt',
  'zuckte',
  'zudem',
  'zueckt',
  'zuege',
  'zuegel',
  'zuernt',
  'zuerst',
  'zufall',
  'zug',
  'zugabe',
  'zugang',
  'zugig',
  'zulage',
  'zulauf',
  'zuluft',
  'zum',
  'zumal',
  'zuname',
  'zunder',
  'zunft',
  'zunge',
  'zupfen',
  'zupft',
  'zupfte',
  'zur',
  'zurren',
  'zurrt',
  'zuruf',
  'zus',
  'zusage',
  'zusatz',
  'zutat',
  'zuvor',
  'zuzug',
  'zv',
  'zw',
  'zwang',
  'zwangs',
  'zwar',
  'zweck',
  'zwecke',
  'zwecks',
  'zwei',
  'zweier',
  'zweig',
  'zweite',
  'zwerg',
  'zwingt',
  'zwirn',
  'zwirnt',
  'zwist',
  'zwoelf',
  'zx',
  'zy',
  'zyanat',
  'zyanid',
  'zygote',
  'zyklon',
  'zyklop',
  'zyklus',
  'zypern',
  'zyste',
  'zz',
  'zzgl',
  'zzz',
  'zzzz',
];
