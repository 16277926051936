import './styles/global.css';

import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

import { Layout } from './components/Layout';
import { CollectEntropy } from './components/CollectEntropy';
import { Password } from './components/Password';
import { Options } from './components/Options';
import { Entropy } from './components/Entropy';
import { Scenario } from './components/Scenario';
import { Actions } from './components/Actions';
import { toast, ToastContainer } from './components/toast';
import { Portal } from './components/Portal';
import { Pwd } from './pwd';
import logo from '../img/blue_smorin_cloud5.png';
import { PopupModal } from './components/Popup'

const App = () => {
  const [entropyCollected, setEntropyCollected] = useState(false);
  const [data, setData] = useState({});
  const [options, setOptions] = useState({
    useNumbers: true,
    useSymbols: false,
    useSpaces: false,
    useWordList: 'original',
    useCase: 'capitalize',
    useWords: 5,
  });
  const pwd = useMemo(() => new Pwd(), []);

  useEffect(() => {
    if (Object.keys(data).includes('password') && !entropyCollected) {
      setEntropyCollected(true);
      document.body.style.overflow = 'initial';
    } else if (Object.keys(data).length === 0 && entropyCollected) {
      toast('Password cleared');
    }
  }, [entropyCollected, data]);

  const handleSetOptions = option => {
    setOptions({ ...options, [option]: options[option] ? false : true });
  };

  const handleSetWordList = option => {
    setOptions({ ...options, ['useWordList']: option });
  };

  const handleSetCase = option => {
    setOptions({ ...options, ['useCase']: option });
  };

  const handleSetWords = action => {
    if (action === 'increase' && options.useWords < 14) {
      setOptions({ ...options, ['useWords']: options.useWords + 1 });
    }

    if (action === 'decrease' && options.useWords > 4) {
      setOptions({ ...options, ['useWords']: options.useWords - 1 });
    }
  };

  const handleGenerate = () => {
    const result = pwd.generate(options);
    setData(result);
  };

  const handleReset = () => {
    if (Object.keys(data).length > 0) {
      setData({});
    }
  }; 

  return (
    <>
      <ToastContainer />
      <div style={{paddingTop: "20px", color: "#7496C4"}}>
        <center>
          <img src={logo} style={{width: "25%", height: "25%"}}></img>
          <br/>
          <span style={{fontSize: "0.5pt"}}><a href="https://pv.smorin.app" target="_blank" style={{fontSize: "12px"}}><u>validate</u></a>
          </span>
          <span style={{fontSize: "12px"}}>&nbsp;password&nbsp;</span><span style={{fontSize: "0.5pt", fontSize: "12px"}}><span>&#124;</span> generate </span>
          <span>
          <a href="https://pdub.smorin.app" target="_blank" style={{fontSize: "12px"}}><u>password</u></a><span style={{fontSize: "12px"}}> | </span><a href="https://lookup.smorin.app" target="_blank" style={{fontSize: "12px"}}><u>domain tools</u></a></span>
        </center>
      </div>
      {!entropyCollected ? (
        <CollectEntropy options={options} setData={setData} fn={pwd} />
      ) : null}
      <Portal>
        <Options
          options={options}
          onSetOptions={handleSetOptions}
          onSetWords={handleSetWords}
          onSetCase={handleSetCase}
          onSetWordList={handleSetWordList}
        />
      </Portal>
      <Layout>
        <div className="grid gap-4">
          <Password value={data.password} />
          <Entropy value={data.entropy} />
          <Scenario values={data.guesses} />
          <Actions onGenerate={handleGenerate} onReset={handleReset} pass={data.password}/>
        </div>
      </Layout>
    </>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
