export const french = [
  '=',
  '20eme',
  '64eme',
  'abroge',
  'aime',
  'antre',
  'avales',
  'bande',
  '==',
  '21',
  '65',
  'abrupt',
  'aimer',
  'antres',
  'avalez',
  'bandes',
  '===',
  '21eme',
  '65eme',
  'abruti',
  'aimera',
  'anus',
  'avals',
  'bang',
  '-',
  '22',
  '66',
  'absent',
  'aimes',
  'ao',
  'avance',
  'bangs',
  '--',
  '222',
  '666',
  'abus',
  'aimez',
  'aorte',
  'avant',
  'banjo',
  '---',
  '2222',
  '6666',
  'abusa',
  'aine',
  'ap',
  'avants',
  'banni',
  ';',
  '22eme',
  '66eme',
  'abuse',
  'aines',
  'aphte',
  'avare',
  'bannit',
  ':',
  '23',
  '67',
  'ac',
  'ainsi',
  'aplani',
  'avarie',
  'banque',
  ':-(',
  '234',
  '678',
  'acajou',
  'air',
  'apparu',
  'avatar',
  'bans',
  ':-)',
  '2345',
  '6789',
  'accola',
  'aire',
  'appas',
  'avec',
  'bantou',
  ':(',
  '23eme',
  '67eme',
  'accord',
  'airer',
  'appel',
  'avenir',
  'baquet',
  ':)',
  '24',
  '68',
  'accroc',
  'aires',
  'appels',
  'avenu',
  'bar',
  '!',
  '2468',
  '68eme',
  'accru',
  'airs',
  'apport',
  'avenue',
  'barbe',
  '!!',
  '24eme',
  '69',
  'accule',
  'aise',
  'apposa',
  'avenus',
  'barbet',
  '!!!',
  '25',
  '69eme',
  'accusa',
  'aises',
  'appose',
  'averse',
  'barbu',
  '?',
  '25eme',
  '6eme',
  'ace',
  'ait',
  'appris',
  'aveu',
  'barda',
  '??',
  '26',
  '7',
  'acerbe',
  'aj',
  'appui',
  'aveuli',
  'bardas',
  '???',
  '26eme',
  '70',
  'aces',
  'ajonc',
  'appuie',
  'aveux',
  'barde',
  '"""',
  '27',
  '700',
  'achat',
  'ajout',
  'appuis',
  'avez',
  'bardes',
  '(',
  '27eme',
  '7000',
  'acheta',
  'ajouta',
  'apte',
  'avide',
  'barge',
  '()',
  '28',
  '70eme',
  'acheva',
  'ajuste',
  'aptes',
  'avides',
  'baril',
  ')',
  '28eme',
  '71',
  'acide',
  'ak',
  'apura',
  'aviez',
  'barman',
  '@',
  '29',
  '71eme',
  'acier',
  'al',
  'apure',
  'avili',
  'baron',
  '$',
  '29eme',
  '72',
  'acquis',
  'alarme',
  'apurer',
  'avilir',
  'barra',
  '$$',
  '2eme',
  '72eme',
  'acquit',
  'album',
  'apures',
  'avilis',
  'barre',
  '$$$',
  '3',
  '73',
  'acte',
  'alcool',
  'aq',
  'avilit',
  'barrez',
  '*',
  '30',
  '73eme',
  'actes',
  'alerta',
  'ar',
  'avion',
  'barrir',
  '**',
  '300',
  '74',
  'acteur',
  'alevin',
  'arabe',
  'avions',
  'bars',
  '***',
  '3000',
  '74eme',
  'actif',
  'alezan',
  'araser',
  'avis',
  'bas',
  '&',
  '30eme',
  '75',
  'ad',
  'alfa',
  'arbora',
  'avisa',
  'basa',
  '#',
  '31',
  '75eme',
  'adage',
  'alfas',
  'arbre',
  'avise',
  'basait',
  '##',
  '31eme',
  '76',
  'adapta',
  'algue',
  'arc',
  'avises',
  'basane',
  '###',
  '32',
  '76eme',
  'adapte',
  'algues',
  'arcade',
  'aviso',
  'base',
  '%',
  '32eme',
  '77',
  'adepte',
  'alias',
  'arche',
  'avisos',
  'basent',
  '%%',
  '33',
  '777',
  'adieu',
  'alibi',
  'archet',
  'aviva',
  'baser',
  '%%%',
  '333',
  '7777',
  'adjura',
  'aliter',
  'arcs',
  'avive',
  'bases',
  '+',
  '3333',
  '77eme',
  'adjure',
  'alla',
  'ardu',
  'avives',
  'basez',
  '++',
  '33eme',
  '78',
  'admet',
  'aller',
  'ardue',
  'avoir',
  'basque',
  '+++',
  '34',
  '789',
  'admira',
  'allez',
  'ardues',
  'avoirs',
  'basse',
  '0',
  '345',
  '78eme',
  'admis',
  'allia',
  'ardus',
  'avons',
  'bassin',
  '1',
  '3456',
  '79',
  'admise',
  'allie',
  'are',
  'avoua',
  'basson',
  '10',
  '34eme',
  '79eme',
  'admit',
  'allier',
  'ares',
  'avoue',
  'bat',
  '100',
  '35',
  '7eme',
  'adonne',
  'allies',
  'argile',
  'avouer',
  'bateau',
  '1000',
  '35eme',
  '8',
  'adopta',
  'alloue',
  'argot',
  'avouez',
  'bats',
  '100eme',
  '36',
  '80',
  'adopte',
  'allume',
  'argots',
  'avril',
  'batte',
  '101',
  '36eme',
  '800',
  'adora',
  'allure',
  'aria',
  'aw',
  'battez',
  '10eme',
  '37',
  '8000',
  'adore',
  'aloi',
  'arias',
  'ax',
  'battit',
  '11',
  '37eme',
  '80eme',
  'adorer',
  'alors',
  'aride',
  'axa',
  'battre',
  '111',
  '38',
  '81',
  'adores',
  'alpaga',
  'arides',
  'axais',
  'battu',
  '1111',
  '38eme',
  '81eme',
  'aduler',
  'alpage',
  'arma',
  'axait',
  'battus',
  '11eme',
  '39',
  '82',
  'adulte',
  'alpin',
  'armais',
  'axant',
  'baudet',
  '12',
  '39eme',
  '82eme',
  'advenu',
  'alpins',
  'armait',
  'axe',
  'bauge',
  '123',
  '3eme',
  '83',
  'ae',
  'alter',
  'armant',
  'axent',
  'baume',
  '1234',
  '4',
  '83eme',
  'aequo',
  'alto',
  'arme',
  'axer',
  'baux',
  '12eme',
  '40',
  '84',
  'af',
  'altos',
  'arment',
  'axera',
  'bava',
  '13',
  '400',
  '84eme',
  'affine',
  'alu',
  'armer',
  'axes',
  'bavait',
  '13eme',
  '4000',
  '85',
  'affixe',
  'aluner',
  'armes',
  'axez',
  'bave',
  '14',
  '40eme',
  '85eme',
  'afflua',
  'am',
  'armez',
  'axiez',
  'baver',
  '1492',
  '41',
  '86',
  'afflue',
  'amant',
  'arpent',
  'axiome',
  'baves',
  '14eme',
  '41eme',
  '86eme',
  'afflux',
  'amants',
  'arrime',
  'axons',
  'bavez',
  '15',
  '42',
  '87',
  'affola',
  'amarre',
  'arriva',
  'ay',
  'baviez',
  '1500',
  '42eme',
  '87eme',
  'affole',
  'amas',
  'arrive',
  'ayant',
  'bayer',
  '15eme',
  '43',
  '88',
  'affres',
  'amble',
  'arroge',
  'ayez',
  'bazar',
  '16',
  '4321',
  '888',
  'afin',
  'ambler',
  'arrosa',
  'ayons',
  'bb',
  '1600',
  '43eme',
  '8888',
  'ag',
  'ambre',
  'art',
  'az',
  'bbb',
  '16eme',
  '44',
  '88eme',
  'agace',
  'ambrer',
  'arts',
  'azote',
  'bbbb',
  '17',
  '444',
  '89',
  'agaces',
  'amen',
  'as',
  'azur',
  'bc',
  '1700',
  '4444',
  '89eme',
  'agacez',
  'amena',
  'asile',
  'azyme',
  'bcd',
  '17eme',
  '44eme',
  '8eme',
  'agate',
  'amenda',
  'aspect',
  'b',
  'bd',
  '18',
  '45',
  '9',
  'agates',
  'amener',
  'aspic',
  'ba',
  'be',
  '1800',
  '456',
  '90',
  'agence',
  'amer',
  'assagi',
  'baba',
  'beau',
  '18eme',
  '4567',
  '900',
  'agenda',
  'amers',
  'assez',
  'babas',
  'beaux',
  '19',
  '45eme',
  '9000',
  'agent',
  'ameuta',
  'assis',
  'babil',
  'bec',
  '1900',
  '46',
  '90eme',
  'agi',
  'ami',
  'assit',
  'bac',
  'becs',
  '1910',
  '46eme',
  '91',
  'agile',
  'amibe',
  'assoie',
  'bacon',
  'becter',
  '1920',
  '47',
  '91eme',
  'agio',
  'amical',
  'assume',
  'bacs',
  'beige',
  '1925',
  '47eme',
  '92',
  'agios',
  'amie',
  'astral',
  'badaud',
  'beiges',
  '1930',
  '48',
  '92eme',
  'agiote',
  'amies',
  'astre',
  'badge',
  'bel',
  '1935',
  '48eme',
  '93',
  'agir',
  'amis',
  'astres',
  'badges',
  'belge',
  '1940',
  '49',
  '93eme',
  'agira',
  'amont',
  'astuce',
  'badin',
  'belle',
  '1945',
  '49eme',
  '94',
  'agirai',
  'amour',
  'at',
  'badine',
  'belles',
  '1950',
  '4eme',
  '94eme',
  'agis',
  'amours',
  'atlas',
  'bafoua',
  'belon',
  '1955',
  '5',
  '95',
  'agisse',
  'amphi',
  'atoll',
  'bagne',
  'belons',
  '1960',
  '50',
  '95eme',
  'agit',
  'ample',
  'atome',
  'bagou',
  'benne',
  '1965',
  '500',
  '96',
  'agita',
  'amples',
  'atomes',
  'bague',
  'bennes',
  '1970',
  '5000',
  '96eme',
  'agite',
  'ampli',
  'atone',
  'baguer',
  'berce',
  '1975',
  '50eme',
  '97',
  'agiter',
  'amputa',
  'atones',
  'bahut',
  'bercer',
  '1980',
  '51',
  '97eme',
  'agonie',
  'amurer',
  'atours',
  'bahuts',
  'bercez',
  '1985',
  '51eme',
  '98',
  'agonir',
  'amusa',
  'atout',
  'bai',
  'berge',
  '1990',
  '52',
  '9876',
  'agrafe',
  'amuse',
  'atouts',
  'baie',
  'berger',
  '1991',
  '52eme',
  '98eme',
  'ah',
  'amuser',
  'attire',
  'baies',
  'berlue',
  '1992',
  '53',
  '99',
  'ahuri',
  'amusez',
  'au',
  'baigna',
  'berna',
  '1993',
  '53eme',
  '999',
  'ahurir',
  'an',
  'aube',
  'bail',
  'berne',
  '1994',
  '54',
  '9999',
  'ai',
  'anche',
  'aubier',
  'bain',
  'bernez',
  '1995',
  '54eme',
  '99eme',
  'aida',
  'anches',
  'aucun',
  'bains',
  'besoin',
  '1996',
  '55',
  '9eme',
  'aidais',
  'ancra',
  'audit',
  'bais',
  'bette',
  '1997',
  '555',
  'a',
  'aidant',
  'ancre',
  'audits',
  'baiser',
  'bettes',
  '1998',
  '5555',
  'aa',
  'aide',
  'ancrer',
  'auge',
  'baissa',
  'bf',
  '1999',
  '55eme',
  'aaa',
  'aider',
  'ancres',
  'auges',
  'baisse',
  'bg',
  '19eme',
  '56',
  'aaaa',
  'aides',
  'ancrez',
  'augura',
  'bal',
  'bh',
  '1er',
  '567',
  'ab',
  'aidez',
  'and',
  'aune',
  'balade',
  'bi',
  '2',
  '5678',
  'abat',
  'aidiez',
  'ange',
  'aunes',
  'balai',
  'biais',
  '20',
  '56eme',
  'abats',
  'aidons',
  'anges',
  'auquel',
  'balais',
  'biaisa',
  '200',
  '57',
  'abatte',
  'aie',
  'angine',
  'aura',
  'balaya',
  'bible',
  '2000',
  '57eme',
  'abc',
  'aient',
  'angle',
  'aurai',
  'balisa',
  'biceps',
  '2001',
  '58',
  'abject',
  'aies',
  'angles',
  'auras',
  'balise',
  'biche',
  '2002',
  '58eme',
  'aboie',
  'aigle',
  'angora',
  'aurez',
  'balle',
  'bicher',
  '2003',
  '59',
  'abois',
  'aigles',
  'anima',
  'auriez',
  'baller',
  'bidet',
  '2004',
  '59eme',
  'aboli',
  'aigre',
  'anime',
  'aussi',
  'balles',
  'bidon',
  '2005',
  '5eme',
  'abonda',
  'aigres',
  'animez',
  'autel',
  'bals',
  'bien',
  '2006',
  '6',
  'abonne',
  'aigri',
  'anis',
  'auteur',
  'balsa',
  'biens',
  '2007',
  '60',
  'abord',
  'aigrie',
  'aniser',
  'auto',
  'bambin',
  'biffa',
  '2008',
  '600',
  'aborda',
  'aigris',
  'anneau',
  'autos',
  'bambou',
  'biffe',
  '2009',
  '6000',
  'aborde',
  'aigrit',
  'annexa',
  'autre',
  'ban',
  'bigler',
  '2010',
  '60eme',
  'abords',
  'aigu',
  'annota',
  'auvent',
  'banal',
  'bijou',
  '2015',
  '61',
  'abouti',
  'aigus',
  'annote',
  'aux',
  'banals',
  'bilan',
  '2020',
  '61eme',
  'aboya',
  'ail',
  'anode',
  'av',
  'banane',
  'bilans',
  '2030',
  '62',
  'aboyer',
  'aile',
  'anodes',
  'avais',
  'banc',
  'bile',
  '2035',
  '62eme',
  'abri',
  'ailes',
  'ans',
  'avait',
  'bancal',
  'biler',
  '2040',
  '63',
  'abris',
  'aille',
  'anse',
  'aval',
  'banco',
  'bille',
  '2045',
  '63eme',
  'abrita',
  'aima',
  'anses',
  'avala',
  'bancos',
  'biller',
  '2050',
  '64',
  'abrite',
  'aimant',
  'antan',
  'avale',
  'bancs',
  'billes',
  'billet',
  'bouge',
  'bulles',
  'canule',
  'chas',
  'cloche',
  'corse',
  'croula',
  'billot',
  'bouges',
  'bure',
  'cap',
  'chat',
  'clone',
  'corses',
  'cru',
  'binage',
  'bougre',
  'burent',
  'cape',
  'chaton',
  'clora',
  'corset',
  'crue',
  'biner',
  'boule',
  'burin',
  'caper',
  'chats',
  'cloras',
  'corsez',
  'cruel',
  'bis',
  'boulon',
  'bus',
  'capes',
  'chaud',
  'clore',
  'cortex',
  'cruels',
  'bise',
  'boumer',
  'busard',
  'capot',
  'chauds',
  'clos',
  'coryza',
  'crues',
  'biseau',
  'bourg',
  'buse',
  'capots',
  'chaume',
  'close',
  'cosmos',
  'crus',
  'biser',
  'bourre',
  'buses',
  'caps',
  'chauve',
  'clou',
  'cosse',
  'crut',
  'bises',
  'bous',
  'buste',
  'capta',
  'chaux',
  'cloua',
  'cossu',
  'cs',
  'bison',
  'bouse',
  'but',
  'capte',
  'check',
  'cloue',
  'cossue',
  'ct',
  'bisons',
  'bout',
  'buta',
  'capter',
  'chef',
  'cloues',
  'cossus',
  'cu',
  'bistro',
  'bouter',
  'butait',
  'captes',
  'chefs',
  'clous',
  'cosy',
  'cuba',
  'bit',
  'bouts',
  'butane',
  'captez',
  'chelem',
  'clown',
  'cosys',
  'cubait',
  'bits',
  'bovin',
  'butant',
  'captif',
  'chemin',
  'club',
  'cota',
  'cubant',
  'bitume',
  'bovins',
  'bute',
  'caquer',
  'chenet',
  'clubs',
  'cotais',
  'cube',
  'bj',
  'box',
  'buter',
  'car',
  'chenil',
  'cm',
  'cote',
  'cuber',
  'bk',
  'boxa',
  'butera',
  'carafe',
  'cher',
  'cn',
  'coteau',
  'cubera',
  'bl',
  'boxe',
  'butes',
  'carat',
  'chers',
  'co',
  'cotent',
  'cubes',
  'blague',
  'boxer',
  'buteur',
  'carcan',
  'chevet',
  'cobol',
  'coter',
  'cuir',
  'blanc',
  'boxera',
  'butez',
  'carde',
  'chez',
  'cobra',
  'cotes',
  'cuira',
  'blancs',
  'boxers',
  'butiez',
  'cargo',
  'chic',
  'cocha',
  'cotez',
  'cuirai',
  'blaser',
  'boxes',
  'butin',
  'cargos',
  'chien',
  'coche',
  'cotir',
  'cuire',
  'blesse',
  'boxeur',
  'butins',
  'caria',
  'chiens',
  'cochez',
  'coton',
  'cuirs',
  'blet',
  'boxez',
  'butor',
  'carie',
  'chier',
  'cochon',
  'cotons',
  'cuis',
  'blets',
  'boxiez',
  'butors',
  'caries',
  'chiot',
  'coco',
  'cotte',
  'cuise',
  'bleu',
  'boxons',
  'buts',
  'carmin',
  'chiper',
  'cocon',
  'cou',
  'cuises',
  'bleue',
  'boy',
  'butte',
  'carnet',
  'chips',
  'cocons',
  'couac',
  'cuisez',
  'bleui',
  'boyau',
  'buvait',
  'carpe',
  'chique',
  'coda',
  'couche',
  'cuisse',
  'bleuie',
  'boys',
  'buvard',
  'carre',
  'chlore',
  'codage',
  'coucou',
  'cuit',
  'bleuir',
  'bp',
  'buveur',
  'carrer',
  'choc',
  'codais',
  'coud',
  'cuite',
  'bleus',
  'bq',
  'buvez',
  'carres',
  'chocs',
  'codait',
  'coude',
  'cuiter',
  'blinde',
  'br',
  'bv',
  'cars',
  'choeur',
  'codas',
  'couder',
  'cuits',
  'bloc',
  'brada',
  'bw',
  'carte',
  'choie',
  'code',
  'couds',
  'cuivre',
  'blocs',
  'brade',
  'bx',
  'carter',
  'choies',
  'codent',
  'coula',
  'cul',
  'blond',
  'brader',
  'by',
  'cartes',
  'choir',
  'coder',
  'coule',
  'culer',
  'bloque',
  'brades',
  'bz',
  'carton',
  'choisi',
  'codera',
  'coulez',
  'culot',
  'blouse',
  'bradez',
  'c',
  'cas',
  'choix',
  'codes',
  'coup',
  'culs',
  'blues',
  'braire',
  'ca',
  'casa',
  'chope',
  'codeur',
  'coupa',
  'culte',
  'bluff',
  'braise',
  'caban',
  'casait',
  'chopes',
  'codez',
  'coupe',
  'cultes',
  'bm',
  'brame',
  'cabas',
  'casant',
  'choqua',
  'codons',
  'coupez',
  'cumin',
  'bn',
  'bramer',
  'cabine',
  'case',
  'choque',
  'coeur',
  'coupla',
  'cumul',
  'bo',
  'branle',
  'cabra',
  'casent',
  'choral',
  'coeurs',
  'coups',
  'cumula',
  'boa',
  'braque',
  'cabre',
  'caser',
  'chose',
  'cogna',
  'cour',
  'cupide',
  'boas',
  'bras',
  'cabres',
  'casera',
  'choses',
  'cognac',
  'courbe',
  'cupule',
  'bob',
  'brassa',
  'cabrez',
  'cases',
  'chou',
  'cogne',
  'coure',
  'curage',
  'bobine',
  'brava',
  'cabri',
  'casez',
  'choux',
  'cogner',
  'coures',
  'cure',
  'bobs',
  'brave',
  'cacao',
  'casino',
  'choya',
  'cognez',
  'courre',
  'curer',
  'bocal',
  'braver',
  'cacha',
  'cassa',
  'choyer',
  'cohue',
  'cours',
  'cures',
  'bocaux',
  'braves',
  'cache',
  'casse',
  'chrome',
  'coi',
  'course',
  'curry',
  'bock',
  'bravez',
  'cacher',
  'casser',
  'chromo',
  'coiffe',
  'court',
  'cuti',
  'bocks',
  'bravo',
  'caches',
  'cassez',
  'chrono',
  'coin',
  'couru',
  'cuva',
  'boeuf',
  'bravos',
  'cachet',
  'cassis',
  'chut',
  'coince',
  'courue',
  'cuvait',
  'bogue',
  'break',
  'cachou',
  'caste',
  'chuta',
  'coing',
  'courus',
  'cuve',
  'boira',
  'breaks',
  'cactus',
  'catch',
  'chute',
  'coings',
  'cous',
  'cuver',
  'boirai',
  'brebis',
  'caddie',
  'catir',
  'chutes',
  'coins',
  'couse',
  'cuves',
  'boire',
  'bref',
  'cade',
  'caudal',
  'chutez',
  'cois',
  'couses',
  'cuvez',
  'boirez',
  'brefs',
  'cades',
  'causa',
  'ci',
  'coite',
  'cousez',
  'cuviez',
  'bois',
  'brevet',
  'cadet',
  'cause',
  'cible',
  'coites',
  'cousu',
  'cv',
  'boit',
  'bric',
  'cadi',
  'causer',
  'cibler',
  'coke',
  'cousus',
  'cw',
  'boita',
  'brick',
  'cadis',
  'causes',
  'cibles',
  'col',
  'coutil',
  'cx',
  'boite',
  'bricks',
  'cadra',
  'cavale',
  'cidre',
  'cola',
  'couva',
  'cy',
  'boitez',
  'brida',
  'cadre',
  'cave',
  'cidres',
  'colin',
  'couve',
  'cycle',
  'boive',
  'bride',
  'cadrer',
  'caveau',
  'ciel',
  'colis',
  'couvez',
  'cycles',
  'boives',
  'bridez',
  'cadrez',
  'caver',
  'cierge',
  'colite',
  'couvre',
  'cygne',
  'bol',
  'bridge',
  'caduc',
  'caves',
  'cieux',
  'colla',
  'cp',
  'cygnes',
  'bolet',
  'brie',
  'cafard',
  'caviar',
  'cil',
  'colle',
  'cpt',
  'cz',
  'bolide',
  'brilla',
  'cage',
  'cb',
  'ciller',
  'colles',
  'cq',
  'd',
  'bols',
  'brille',
  'cages',
  'cc',
  'cils',
  'collez',
  'cr',
  'da',
  'bombe',
  'brima',
  'cagibi',
  'ccc',
  'cime',
  'colon',
  'crabe',
  'dada',
  'bomber',
  'brime',
  'cahot',
  'cccc',
  'cimes',
  'colore',
  'crack',
  'dadas',
  'bombes',
  'brimer',
  'cahota',
  'cd',
  'cinq',
  'cols',
  'cracks',
  'dague',
  'bon',
  'brimes',
  'cahote',
  'cde',
  'cintra',
  'colt',
  'craie',
  'dagues',
  'bonbon',
  'brin',
  'cahots',
  'ce',
  'cintre',
  'colts',
  'craies',
  'daigne',
  'bond',
  'brins',
  'cajou',
  'ceci',
  'cira',
  'colza',
  'crains',
  'daim',
  'bonde',
  'brio',
  'cajous',
  'cedex',
  'cirais',
  'coma',
  'crampe',
  'daims',
  'bondes',
  'brique',
  'cajun',
  'ceint',
  'cirant',
  'comas',
  'cran',
  'dais',
  'bondi',
  'bris',
  'cake',
  'ceints',
  'cire',
  'combat',
  'crans',
  'dalla',
  'bondir',
  'brisa',
  'cakes',
  'cela',
  'cirent',
  'combla',
  'crasse',
  'dalle',
  'bondit',
  'brise',
  'cal',
  'celer',
  'cirer',
  'comme',
  'crawl',
  'dame',
  'bonds',
  'brises',
  'cala',
  'celle',
  'cirera',
  'commet',
  'crayon',
  'damer',
  'boni',
  'broc',
  'calage',
  'celui',
  'cires',
  'compta',
  'credo',
  'dames',
  'bonis',
  'brocs',
  'calais',
  'cens',
  'cireur',
  'compte',
  'creuse',
  'damna',
  'bonne',
  'broda',
  'calant',
  'cent',
  'cirez',
  'comte',
  'creux',
  'damne',
  'bonnes',
  'brode',
  'calcul',
  'centra',
  'cirrus',
  'comtes',
  'creva',
  'damnez',
  'bons',
  'broie',
  'cale',
  'cents',
  'cita',
  'con',
  'crevez',
  'dan',
  'bonus',
  'bronza',
  'calent',
  'cep',
  'cite',
  'conclu',
  'cri',
  'dandy',
  'bonze',
  'bronze',
  'caler',
  'ceps',
  'citer',
  'confia',
  'cria',
  'dandys',
  'bonzes',
  'brou',
  'cales',
  'cercla',
  'cites',
  'confus',
  'criais',
  'dans',
  'boom',
  'brouta',
  'calez',
  'cerf',
  'citez',
  'conne',
  'criait',
  'dansa',
  'boots',
  'broya',
  'calma',
  'cerfs',
  'citron',
  'connes',
  'criard',
  'danse',
  'bord',
  'bru',
  'calmar',
  'cerna',
  'civet',
  'connu',
  'crible',
  'danser',
  'borda',
  'bruine',
  'calme',
  'cerne',
  'civets',
  'connue',
  'cric',
  'dansez',
  'borde',
  'bruir',
  'calot',
  'cerner',
  'civil',
  'connut',
  'cricri',
  'darce',
  'border',
  'bruire',
  'cals',
  'cernez',
  'cj',
  'conque',
  'crics',
  'darces',
  'bordes',
  'bruit',
  'calva',
  'ces',
  'ck',
  'cons',
  'crie',
  'dard',
  'bordez',
  'bruits',
  'came',
  'cessa',
  'cl',
  'conte',
  'crier',
  'darder',
  'bords',
  'brume',
  'camer',
  'cesse',
  'claie',
  'contes',
  'criera',
  'dards',
  'borgne',
  'brumer',
  'cames',
  'cesser',
  'clair',
  'contre',
  'cries',
  'darne',
  'borna',
  'brun',
  'camion',
  'cet',
  'clairs',
  'convie',
  'crieur',
  'darnes',
  'borne',
  'brune',
  'camp',
  'cette',
  'clam',
  'convoi',
  'criez',
  'darse',
  'borner',
  'bruni',
  'campa',
  'ceux',
  'clama',
  'copia',
  'crime',
  'dartre',
  'bornez',
  'brunie',
  'campe',
  'cf',
  'clame',
  'copie',
  'crimes',
  'data',
  'bosse',
  'brunir',
  'camper',
  'cg',
  'clamer',
  'copier',
  'crin',
  'datait',
  'bosser',
  'brunit',
  'campes',
  'ch',
  'clames',
  'copies',
  'crins',
  'datant',
  'bosses',
  'bruns',
  'campez',
  'chacun',
  'clamez',
  'copine',
  'crions',
  'date',
  'bossez',
  'brus',
  'camps',
  'chah',
  'clams',
  'coq',
  'cris',
  'dater',
  'bossu',
  'brut',
  'camus',
  'chahs',
  'clan',
  'coqs',
  'crise',
  'dates',
  'bot',
  'brute',
  'canal',
  'chai',
  'clans',
  'coque',
  'crissa',
  'dateur',
  'bots',
  'bruts',
  'canard',
  'chair',
  'clapir',
  'coquet',
  'croc',
  'datez',
  'botte',
  'bs',
  'canari',
  'chaire',
  'claque',
  'cor',
  'crocs',
  'datif',
  'bouc',
  'bt',
  'cancer',
  'chais',
  'classe',
  'corail',
  'croie',
  'dation',
  'boucs',
  'bu',
  'candi',
  'champ',
  'claver',
  'corde',
  'croira',
  'datte',
  'bouda',
  'buccal',
  'candir',
  'chance',
  'clef',
  'corder',
  'crois',
  'dattes',
  'boude',
  'budget',
  'cane',
  'change',
  'clefs',
  'corne',
  'croisa',
  'daube',
  'bouder',
  'bue',
  'caner',
  'chant',
  'clerc',
  'corner',
  'croise',
  'dauber',
  'boudes',
  'bues',
  'canes',
  'chanta',
  'clercs',
  'cornet',
  'croit',
  'db',
  'boudez',
  'buffle',
  'canif',
  'chaos',
  'climat',
  'cornu',
  'croix',
  'dc',
  'boudin',
  'buggy',
  'canna',
  'chape',
  'clin',
  'cornus',
  'croqua',
  'dd',
  'boue',
  'buggys',
  'canne',
  'char',
  'clins',
  'coron',
  'croque',
  'ddd',
  'boues',
  'buis',
  'canon',
  'charma',
  'clip',
  'corps',
  'cross',
  'dddd',
  'bouffe',
  'bulbe',
  'canot',
  'chars',
  'clips',
  'cors',
  'crosse',
  'de',
  'bouffi',
  'bulle',
  'canots',
  'charte',
  'clique',
  'corsa',
  'crotte',
  'debout',
  'dedans',
  'donjon',
  'durent',
  'erra',
  'farces',
  'filera',
  'fonder',
  'fuir',
  'def',
  'donna',
  'durer',
  'erre',
  'farci',
  'files',
  'fondez',
  'fuira',
  'delta',
  'donne',
  'durera',
  'errer',
  'farcir',
  'filet',
  'fonds',
  'fuirai',
  'demain',
  'donnes',
  'dures',
  'errera',
  'fard',
  'filets',
  'fondu',
  'fuirez',
  'demi',
  'donnez',
  'durs',
  'erres',
  'farda',
  'filez',
  'fondue',
  'fuis',
  'demie',
  'dons',
  'dus',
  'errez',
  'farde',
  'filin',
  'fondus',
  'fuit',
  'demis',
  'dont',
  'dut',
  'erriez',
  'fardes',
  'fille',
  'font',
  'fuite',
  'denier',
  'dopa',
  'duvet',
  'ersatz',
  'fardez',
  'filles',
  'fonte',
  'fuites',
  'dense',
  'dopait',
  'duvets',
  'es',
  'fards',
  'film',
  'fontes',
  'full',
  'denses',
  'dopant',
  'dv',
  'escale',
  'farine',
  'filma',
  'fonts',
  'fulls',
  'dent',
  'dope',
  'dw',
  'esche',
  'fars',
  'filme',
  'foot',
  'fuma',
  'dents',
  'doper',
  'dx',
  'escher',
  'fart',
  'filmes',
  'for',
  'fumage',
  'derby',
  'dopes',
  'dy',
  'esches',
  'farts',
  'films',
  'fora',
  'fumait',
  'derbys',
  'dopez',
  'dynamo',
  'espion',
  'fasse',
  'filon',
  'forage',
  'fume',
  'derme',
  'dopons',
  'dz',
  'espoir',
  'fasses',
  'filons',
  'forain',
  'fumer',
  'des',
  'dora',
  'e',
  'essai',
  'faste',
  'filou',
  'forais',
  'fumes',
  'design',
  'dorade',
  'ea',
  'essaim',
  'fat',
  'fils',
  'forait',
  'fumet',
  'dessus',
  'dorait',
  'eau',
  'essaya',
  'fatal',
  'filtra',
  'force',
  'fumets',
  'destin',
  'dorant',
  'eaux',
  'esse',
  'fats',
  'filtre',
  'forces',
  'fumeur',
  'dette',
  'dore',
  'eb',
  'esses',
  'fauche',
  'fin',
  'fore',
  'fumez',
  'deuil',
  'dorent',
  'ec',
  'essor',
  'faudra',
  'final',
  'forent',
  'fumier',
  'deuils',
  'dorer',
  'ed',
  'essore',
  'faune',
  'finals',
  'forer',
  'fumoir',
  'deux',
  'doreur',
  'ee',
  'essuya',
  'faut',
  'finaud',
  'forera',
  'fur',
  'devais',
  'dorme',
  'eee',
  'est',
  'faute',
  'fine',
  'fores',
  'furet',
  'devait',
  'dormi',
  'eeee',
  'ester',
  'fautes',
  'fines',
  'foret',
  'furets',
  'devant',
  'dormir',
  'ef',
  'estima',
  'fauve',
  'fini',
  'forez',
  'fureur',
  'devenu',
  'dors',
  'efface',
  'estoc',
  'faux',
  'finie',
  'forge',
  'furia',
  'devez',
  'dort',
  'effara',
  'et',
  'fax',
  'finies',
  'forger',
  'furie',
  'devin',
  'dorure',
  'effare',
  'ethnie',
  'faxer',
  'finir',
  'forges',
  'furies',
  'devina',
  'dos',
  'effet',
  'eu',
  'fb',
  'finira',
  'forgez',
  'fusa',
  'devins',
  'dosa',
  'effets',
  'eue',
  'fc',
  'finis',
  'foriez',
  'fusain',
  'devint',
  'dosage',
  'effila',
  'eues',
  'fco',
  'finit',
  'forma',
  'fusait',
  'devis',
  'dosais',
  'effile',
  'euh',
  'fd',
  'fins',
  'forme',
  'fuse',
  'devons',
  'dosant',
  'effort',
  'eurent',
  'fe',
  'fiole',
  'formel',
  'fuseau',
  'devra',
  'dose',
  'effroi',
  'euro',
  'feins',
  'fioles',
  'former',
  'fuser',
  'devras',
  'dosent',
  'efg',
  'euros',
  'feint',
  'fions',
  'formes',
  'fusera',
  'devrez',
  'doser',
  'eg',
  'eus',
  'feinte',
  'firent',
  'formez',
  'fusil',
  'df',
  'doses',
  'ego',
  'eut',
  'feints',
  'firme',
  'formol',
  'fut',
  'dg',
  'dosez',
  'eh',
  'eux',
  'femme',
  'fisc',
  'fors',
  'futur',
  'dh',
  'dosons',
  'ei',
  'ev',
  'fend',
  'fiscal',
  'fort',
  'fuyais',
  'di',
  'dot',
  'eider',
  'ew',
  'fende',
  'fiscs',
  'forte',
  'fuyant',
  'diapo',
  'dota',
  'ej',
  'ex',
  'fendes',
  'fit',
  'forts',
  'fuyez',
  'dicta',
  'dote',
  'ek',
  'exact',
  'fendra',
  'fixa',
  'forum',
  'fuyiez',
  'dicte',
  'dotent',
  'el',
  'exacte',
  'fendre',
  'fixais',
  'forums',
  'fuyons',
  'dictez',
  'doter',
  'elfe',
  'exacts',
  'fends',
  'fixait',
  'fosse',
  'fv',
  'dicton',
  'dotes',
  'elfes',
  'exalte',
  'fendu',
  'fixant',
  'fosses',
  'fw',
  'diesel',
  'dotez',
  'elle',
  'exauce',
  'fente',
  'fixe',
  'fou',
  'fx',
  'dieu',
  'dotons',
  'elles',
  'excite',
  'fer',
  'fixer',
  'fouet',
  'fy',
  'dieux',
  'dots',
  'em',
  'exclu',
  'fera',
  'fixes',
  'fouine',
  'fz',
  'diffus',
  'douane',
  'embuer',
  'excusa',
  'ferai',
  'fixez',
  'fouir',
  'g',
  'digit',
  'douant',
  'emmena',
  'excuse',
  'ferais',
  'fj',
  'foula',
  'ga',
  'digits',
  'doubla',
  'empan',
  'exerce',
  'feras',
  'fjord',
  'foule',
  'gaffe',
  'digne',
  'double',
  'empara',
  'exhala',
  'ferez',
  'fjords',
  'foules',
  'gaffer',
  'digue',
  'douce',
  'empli',
  'exige',
  'feriez',
  'fk',
  'four',
  'gaffes',
  'dilua',
  'douces',
  'emplis',
  'exiger',
  'ferma',
  'fl',
  'fours',
  'gag',
  'dilue',
  'douche',
  'emplit',
  'exiges',
  'ferme',
  'flacon',
  'fous',
  'gage',
  'dinde',
  'douer',
  'empois',
  'exigu',
  'fermer',
  'flair',
  'foutez',
  'gagea',
  'dindon',
  'douta',
  'en',
  'exil',
  'fermez',
  'flairs',
  'foutu',
  'gager',
  'dinghy',
  'doute',
  'encan',
  'exila',
  'feront',
  'flambe',
  'foutus',
  'gages',
  'dingo',
  'doutez',
  'encas',
  'exile',
  'ferrer',
  'flamme',
  'fox',
  'gagez',
  'dingos',
  'douve',
  'encore',
  'exiles',
  'fers',
  'flan',
  'foyer',
  'gagna',
  'diode',
  'douves',
  'encra',
  'exils',
  'fesse',
  'flanc',
  'foyers',
  'gagne',
  'dira',
  'doux',
  'encre',
  'exista',
  'fesser',
  'flans',
  'fp',
  'gagner',
  'dirai',
  'douze',
  'encres',
  'exocet',
  'festif',
  'flapi',
  'fq',
  'gagnes',
  'dirait',
  'doyen',
  'encrez',
  'exode',
  'feu',
  'flash',
  'fr',
  'gagnez',
  'diras',
  'dp',
  'endos',
  'expert',
  'feue',
  'flatta',
  'frac',
  'gags',
  'dire',
  'dq',
  'enduis',
  'expia',
  'feues',
  'flatte',
  'fracas',
  'gai',
  'direct',
  'dr',
  'endure',
  'expie',
  'feutra',
  'flegme',
  'fracs',
  'gaie',
  'dires',
  'dragon',
  'enfer',
  'expier',
  'feux',
  'flemme',
  'frai',
  'gaies',
  'direz',
  'drain',
  'enfila',
  'expira',
  'fez',
  'fleur',
  'fraie',
  'gain',
  'dirige',
  'draine',
  'enfile',
  'expire',
  'ff',
  'fleura',
  'frais',
  'gaine',
  'dirons',
  'drame',
  'enfin',
  'exposa',
  'fff',
  'fleuri',
  'fraise',
  'gainer',
  'dis',
  'drap',
  'enfla',
  'expose',
  'ffff',
  'flippe',
  'franc',
  'gaines',
  'discal',
  'drapa',
  'enfle',
  'extra',
  'fg',
  'flirt',
  'fraser',
  'gains',
  'disco',
  'drape',
  'enfler',
  'exulte',
  'fgh',
  'flirts',
  'fraya',
  'gais',
  'dise',
  'drapes',
  'enflez',
  'ey',
  'fh',
  'flood',
  'fraye',
  'gala',
  'disert',
  'drapez',
  'enfoui',
  'ez',
  'fi',
  'flore',
  'frayer',
  'galant',
  'dises',
  'draps',
  'enfui',
  'f',
  'fia',
  'flores',
  'frayes',
  'galas',
  'disiez',
  'dressa',
  'enfuit',
  'fa',
  'fiacre',
  'flot',
  'frayez',
  'galbe',
  'dispos',
  'dresse',
  'enfuma',
  'fable',
  'fiais',
  'flots',
  'frein',
  'galber',
  'dit',
  'drille',
  'engin',
  'fabula',
  'fiait',
  'flou',
  'freina',
  'galbes',
  'dite',
  'drisse',
  'enivre',
  'face',
  'fiance',
  'floua',
  'freins',
  'gale',
  'dites',
  'driver',
  'enjeu',
  'faces',
  'fiant',
  'floue',
  'fret',
  'gales',
  'dito',
  'drogua',
  'enjeux',
  'facial',
  'fiasco',
  'flous',
  'frets',
  'galet',
  'dits',
  'droit',
  'enlisa',
  'facto',
  'fibre',
  'fluer',
  'friche',
  'galeux',
  'divan',
  'drop',
  'ennemi',
  'factor',
  'fibres',
  'fluet',
  'frigo',
  'galon',
  'divin',
  'drops',
  'ennui',
  'fade',
  'fibule',
  'fluide',
  'frigos',
  'galop',
  'divisa',
  'dru',
  'ennuie',
  'fades',
  'ficela',
  'fluor',
  'frime',
  'gamba',
  'divise',
  'drue',
  'ennuis',
  'fadeur',
  'ficha',
  'fluors',
  'frimer',
  'gambas',
  'dix',
  'drues',
  'ennuya',
  'fagot',
  'fiche',
  'flux',
  'fripa',
  'gamet',
  'dj',
  'drus',
  'enquit',
  'faible',
  'fichez',
  'fm',
  'fripe',
  'gamets',
  'dk',
  'ds',
  'enraya',
  'faibli',
  'fichu',
  'fn',
  'friper',
  'gamin',
  'dl',
  'dt',
  'enraye',
  'faim',
  'fictif',
  'fo',
  'frira',
  'gamine',
  'dm',
  'du',
  'enroba',
  'faire',
  'fie',
  'foc',
  'frirai',
  'gamins',
  'dn',
  'duc',
  'ensuit',
  'fais',
  'fief',
  'focal',
  'friras',
  'gamme',
  'do',
  'ducal',
  'entama',
  'fait',
  'fiefs',
  'focale',
  'frire',
  'gang',
  'docile',
  'ducale',
  'entame',
  'faite',
  'fiel',
  'focs',
  'frirez',
  'gangs',
  'dock',
  'ducs',
  'enter',
  'faites',
  'fient',
  'focus',
  'fris',
  'gangue',
  'docks',
  'due',
  'entier',
  'faits',
  'fier',
  'foehn',
  'frisa',
  'ganse',
  'docte',
  'duel',
  'entra',
  'fakir',
  'fiera',
  'foetus',
  'frise',
  'ganses',
  'doctes',
  'duels',
  'entre',
  'fallu',
  'fierai',
  'foi',
  'frisez',
  'gant',
  'dodu',
  'dues',
  'entrez',
  'falot',
  'fieras',
  'foie',
  'frit',
  'gants',
  'dodue',
  'dune',
  'envia',
  'falote',
  'fiers',
  'foies',
  'frite',
  'gara',
  'dodues',
  'dunes',
  'envie',
  'falots',
  'fies',
  'foin',
  'frits',
  'garant',
  'dodus',
  'duo',
  'envier',
  'fameux',
  'fiez',
  'foins',
  'froc',
  'garce',
  'dogme',
  'duos',
  'envies',
  'fan',
  'fifre',
  'foire',
  'froid',
  'garda',
  'dogmes',
  'dupa',
  'enviez',
  'fana',
  'fige',
  'fois',
  'front',
  'garde',
  'dogue',
  'dupais',
  'envoi',
  'fanal',
  'figea',
  'foison',
  'fronts',
  'gardes',
  'dogues',
  'dupait',
  'envois',
  'fane',
  'figer',
  'fol',
  'frugal',
  'gardez',
  'doigt',
  'dupant',
  'envol',
  'fanent',
  'figes',
  'folie',
  'fruit',
  'gare',
  'doigts',
  'dupe',
  'envola',
  'faner',
  'figez',
  'folies',
  'fs',
  'garer',
  'dois',
  'duper',
  'envols',
  'fanes',
  'figue',
  'folio',
  'ft',
  'garera',
  'doit',
  'dupera',
  'enzyme',
  'fange',
  'figura',
  'folios',
  'fu',
  'gares',
  'doive',
  'dupes',
  'eo',
  'fanion',
  'fiiez',
  'foliot',
  'fucus',
  'garez',
  'doives',
  'dupez',
  'ep',
  'fanon',
  'fiions',
  'folle',
  'fuel',
  'gariez',
  'dollar',
  'dupons',
  'eq',
  'fanons',
  'fil',
  'fonce',
  'fuels',
  'garni',
  'dolmen',
  'dur',
  'er',
  'fans',
  'fila',
  'foncer',
  'fugace',
  'garnie',
  'domina',
  'dura',
  'erg',
  'faon',
  'filait',
  'fonces',
  'fugue',
  'garnit',
  'domine',
  'durait',
  'ergot',
  'faons',
  'filant',
  'foncez',
  'fuguer',
  'gars',
  'dompta',
  'durci',
  'ergots',
  'far',
  'file',
  'fond',
  'fui',
  'gaule',
  'don',
  'durcit',
  'ergs',
  'faraud',
  'filent',
  'fonda',
  'fuie',
  'gava',
  'donc',
  'dure',
  'ermite',
  'farce',
  'filer',
  'fonde',
  'fuies',
  'gavais',
  'gavant',
  'gobes',
  'gy',
  'horde',
  'impur',
  'jaune',
  'jura',
  'lances',
  'gave',
  'gobez',
  'gypse',
  'horion',
  'impure',
  'jaunes',
  'jurais',
  'land',
  'gaver',
  'gobons',
  'gz',
  'hors',
  'impurs',
  'jauni',
  'jurait',
  'landau',
  'gaves',
  'goder',
  'h',
  'hostie',
  'imputa',
  'jaunis',
  'jurant',
  'lande',
  'gavez',
  'godet',
  'ha',
  'hotte',
  'impute',
  'java',
  'jure',
  'landes',
  'gavial',
  'goitre',
  'habile',
  'hotter',
  'in',
  'javas',
  'jurer',
  'lange',
  'gaviez',
  'golden',
  'habit',
  'houe',
  'inapte',
  'jazz',
  'jures',
  'langue',
  'gaz',
  'golf',
  'habita',
  'houer',
  'inca',
  'jb',
  'jurez',
  'laper',
  'gazage',
  'golfe',
  'habits',
  'houes',
  'incas',
  'jc',
  'juron',
  'lapin',
  'gaze',
  'golfs',
  'hacha',
  'houle',
  'incite',
  'jd',
  'jurons',
  'lapine',
  'gazer',
  'gomma',
  'hache',
  'hourra',
  'index',
  'je',
  'jury',
  'lapis',
  'gazes',
  'gomme',
  'hachez',
  'housse',
  'indexa',
  'jean',
  'jurys',
  'laps',
  'gazeux',
  'gommez',
  'hachis',
  'houx',
  'indexe',
  'jeans',
  'jus',
  'lapsus',
  'gazon',
  'gond',
  'hacker',
  'hp',
  'indu',
  'jerez',
  'jusque',
  'laque',
  'gazons',
  'gonds',
  'haie',
  'hq',
  'indue',
  'jerk',
  'juste',
  'lard',
  'gb',
  'gonfle',
  'haies',
  'hr',
  'induis',
  'jerker',
  'jute',
  'larder',
  'gc',
  'gong',
  'haine',
  'hs',
  'indus',
  'jet',
  'juter',
  'lardon',
  'gd',
  'gongs',
  'hais',
  'ht',
  'inepte',
  'jeta',
  'juteux',
  'large',
  'ge',
  'goret',
  'hait',
  'hu',
  'inerte',
  'jeter',
  'jv',
  'largua',
  'geai',
  'gorge',
  'haler',
  'hua',
  'infime',
  'jetez',
  'jw',
  'largue',
  'geais',
  'gorger',
  'haleta',
  'huais',
  'infini',
  'jetiez',
  'jx',
  'larme',
  'geigne',
  'gorges',
  'hall',
  'huait',
  'influa',
  'jeton',
  'jy',
  'larmes',
  'geins',
  'gosier',
  'halle',
  'huant',
  'influx',
  'jetons',
  'jz',
  'larron',
  'geint',
  'gosse',
  'halls',
  'huche',
  'infusa',
  'jets',
  'k',
  'larve',
  'gel',
  'gouge',
  'halo',
  'hucher',
  'infuse',
  'jette',
  'ka',
  'las',
  'gela',
  'gouges',
  'halos',
  'hue',
  'ingrat',
  'jeu',
  'kaki',
  'lascar',
  'gelais',
  'goulot',
  'halte',
  'huent',
  'inhuma',
  'jeudi',
  'kakis',
  'lascif',
  'gelait',
  'goulu',
  'haltes',
  'huer',
  'inique',
  'jeun',
  'kaolin',
  'laser',
  'gelant',
  'goulue',
  'hamac',
  'huera',
  'initie',
  'jeune',
  'kapok',
  'lassa',
  'geler',
  'gourd',
  'hampe',
  'huerez',
  'injure',
  'jeunes',
  'karma',
  'lasse',
  'gelez',
  'gourde',
  'hampes',
  'hues',
  'innove',
  'jeux',
  'kart',
  'lasses',
  'geliez',
  'gourds',
  'hanta',
  'huez',
  'inox',
  'jf',
  'karts',
  'lassez',
  'gelons',
  'gourou',
  'hante',
  'hui',
  'input',
  'jg',
  'kasher',
  'lasso',
  'gels',
  'gousse',
  'hantez',
  'huiez',
  'instar',
  'jh',
  'kayak',
  'lassos',
  'gemme',
  'gp',
  'happa',
  'huila',
  'insu',
  'ji',
  'kb',
  'latent',
  'gemmer',
  'gq',
  'happe',
  'huile',
  'inter',
  'jj',
  'kc',
  'latex',
  'gendre',
  'gr',
  'haras',
  'huilez',
  'intima',
  'jjj',
  'kd',
  'latin',
  'genet',
  'gracia',
  'harder',
  'huis',
  'invita',
  'jjjj',
  'ke',
  'latine',
  'genets',
  'grade',
  'hardi',
  'huit',
  'invite',
  'jk',
  'kf',
  'latins',
  'genou',
  'gradin',
  'harem',
  'huma',
  'io',
  'jkl',
  'kg',
  'latte',
  'genre',
  'grain',
  'harems',
  'humait',
  'iode',
  'jl',
  'kh',
  'lattes',
  'genres',
  'grains',
  'hargne',
  'humant',
  'ioder',
  'jm',
  'ki',
  'lava',
  'gens',
  'grand',
  'haro',
  'hume',
  'iodes',
  'jn',
  'kif',
  'lavage',
  'gentes',
  'grandi',
  'haros',
  'hument',
  'ion',
  'jo',
  'kilo',
  'lavait',
  'gentil',
  'grappe',
  'harpe',
  'humer',
  'ions',
  'job',
  'kilos',
  'lave',
  'gerbe',
  'gras',
  'harpes',
  'humes',
  'iota',
  'jobard',
  'kilt',
  'laver',
  'gerber',
  'grasse',
  'harpie',
  'humeur',
  'iouler',
  'jobs',
  'kilts',
  'laves',
  'gerbes',
  'gratin',
  'hase',
  'humez',
  'ip',
  'jockey',
  'kimono',
  'lavez',
  'gerce',
  'gratis',
  'hases',
  'humons',
  'iq',
  'joie',
  'kir',
  'lavis',
  'germa',
  'gratta',
  'haussa',
  'humour',
  'ir',
  'joies',
  'kit',
  'lavoir',
  'germe',
  'grava',
  'hausse',
  'humus',
  'ira',
  'joins',
  'kits',
  'layer',
  'geste',
  'grave',
  'haut',
  'hune',
  'irai',
  'joint',
  'kiwi',
  'lazzi',
  'gf',
  'graver',
  'haute',
  'hunes',
  'irais',
  'jointe',
  'kiwis',
  'lb',
  'gg',
  'graves',
  'hautes',
  'huons',
  'irait',
  'joker',
  'kj',
  'lc',
  'ggg',
  'gravi',
  'hauts',
  'huotte',
  'iras',
  'jokers',
  'kk',
  'ld',
  'gggg',
  'gravie',
  'havane',
  'huppe',
  'irez',
  'joli',
  'kkk',
  'le',
  'gh',
  'gravis',
  'haver',
  'hurla',
  'iriez',
  'jolie',
  'kkkk',
  'leader',
  'ghi',
  'gravit',
  'havre',
  'hurle',
  'irions',
  'jolis',
  'kl',
  'ledit',
  'gi',
  'grec',
  'havres',
  'hurrah',
  'iris',
  'jonc',
  'klm',
  'legs',
  'gibbon',
  'grecs',
  'hayon',
  'hutte',
  'ironie',
  'joncs',
  'km',
  'lent',
  'gibet',
  'greffe',
  'hayons',
  'huttes',
  'irons',
  'jongla',
  'kn',
  'lente',
  'gibets',
  'grenat',
  'hb',
  'hv',
  'iront',
  'jongle',
  'ko',
  'lents',
  'gicla',
  'grener',
  'hc',
  'hw',
  'irrita',
  'jonque',
  'kola',
  'les',
  'gicle',
  'grenu',
  'hd',
  'hx',
  'irrite',
  'joua',
  'kolas',
  'lest',
  'gifla',
  'greva',
  'he',
  'hy',
  'is',
  'jouait',
  'kp',
  'lesta',
  'gifle',
  'grevez',
  'heaume',
  'hydre',
  'isard',
  'joual',
  'kq',
  'leste',
  'gifler',
  'grief',
  'hein',
  'hydres',
  'isba',
  'jouant',
  'kr',
  'lestes',
  'giflez',
  'griefs',
  'hennir',
  'hymen',
  'isbas',
  'joue',
  'krach',
  'lests',
  'gigot',
  'griffe',
  'herbe',
  'hymens',
  'islam',
  'jouer',
  'kraft',
  'let',
  'gigue',
  'griffu',
  'herber',
  'hymne',
  'isola',
  'jouera',
  'krak',
  'lettre',
  'gilet',
  'gril',
  'herbes',
  'hz',
  'isole',
  'joues',
  'kraks',
  'leur',
  'gilets',
  'grill',
  'hernie',
  'i',
  'isoler',
  'jouet',
  'ks',
  'leurs',
  'gin',
  'grils',
  'herse',
  'ia',
  'issu',
  'joueur',
  'kt',
  'leva',
  'gins',
  'grimer',
  'hertz',
  'iambe',
  'issue',
  'jouez',
  'ku',
  'levage',
  'girl',
  'grimpa',
  'heur',
  'ib',
  'issus',
  'joug',
  'kv',
  'levain',
  'girls',
  'grimpe',
  'heure',
  'ibis',
  'it',
  'jougs',
  'kvas',
  'levais',
  'giron',
  'griot',
  'heures',
  'ic',
  'item',
  'joui',
  'kw',
  'levait',
  'gisait',
  'grippe',
  'heurt',
  'ici',
  'iu',
  'jouiez',
  'kwas',
  'levant',
  'gisant',
  'gris',
  'heurta',
  'ictus',
  'iv',
  'jouir',
  'kx',
  'lever',
  'gitan',
  'grisa',
  'heurts',
  'id',
  'ivraie',
  'jouira',
  'ky',
  'levers',
  'gitane',
  'grisbi',
  'hf',
  'idem',
  'ivre',
  'jouis',
  'kyste',
  'levez',
  'gitans',
  'grise',
  'hg',
  'ides',
  'ivres',
  'jouit',
  'kystes',
  'levons',
  'givra',
  'grises',
  'hh',
  'idiome',
  'iw',
  'joule',
  'kz',
  'levure',
  'givre',
  'grive',
  'hhh',
  'idiot',
  'ix',
  'joules',
  'l',
  'lf',
  'givrer',
  'grives',
  'hhhh',
  'idoine',
  'iy',
  'jour',
  'la',
  'lg',
  'gj',
  'grog',
  'hi',
  'idole',
  'iz',
  'jours',
  'label',
  'lh',
  'gk',
  'grogna',
  'hiatus',
  'idoles',
  'j',
  'joute',
  'labial',
  'li',
  'gl',
  'grogs',
  'hibou',
  'idylle',
  'ja',
  'joutes',
  'lac',
  'lia',
  'glabre',
  'groin',
  'hic',
  'ie',
  'jabler',
  'jouxte',
  'lace',
  'liais',
  'glace',
  'gronda',
  'hideux',
  'if',
  'jabot',
  'joyau',
  'lacer',
  'liait',
  'glacer',
  'groom',
  'hie',
  'ifs',
  'jabots',
  'joyaux',
  'laces',
  'liane',
  'glaces',
  'gros',
  'hier',
  'ig',
  'jade',
  'joyeux',
  'lacet',
  'lianes',
  'glana',
  'grosse',
  'hies',
  'igloo',
  'jades',
  'jp',
  'lacets',
  'liant',
  'gland',
  'grotte',
  'hij',
  'igloos',
  'jadis',
  'jq',
  'lacez',
  'liants',
  'glane',
  'groupa',
  'hilare',
  'igname',
  'jaguar',
  'jr',
  'laciez',
  'libre',
  'glaner',
  'grouse',
  'hindi',
  'ignare',
  'jais',
  'js',
  'lacis',
  'libres',
  'glanes',
  'gruau',
  'hippie',
  'ignore',
  'jalon',
  'jt',
  'lacs',
  'lice',
  'glas',
  'gruaux',
  'hippy',
  'iguane',
  'jalons',
  'ju',
  'lad',
  'licher',
  'glatir',
  'grue',
  'hissa',
  'ih',
  'jambe',
  'jubila',
  'ladre',
  'licite',
  'globe',
  'grues',
  'hisse',
  'ii',
  'jambes',
  'jubile',
  'ladres',
  'licol',
  'globes',
  'gruger',
  'hisser',
  'iii',
  'jambon',
  'judas',
  'lads',
  'licols',
  'gloire',
  'grume',
  'hiver',
  'iiii',
  'jante',
  'judo',
  'lagon',
  'licou',
  'glose',
  'gs',
  'hj',
  'ij',
  'jantes',
  'judoka',
  'lagons',
  'licous',
  'gloser',
  'gt',
  'hk',
  'ijk',
  'jardin',
  'juge',
  'lagune',
  'lie',
  'gloses',
  'gu',
  'hl',
  'ik',
  'jarre',
  'jugea',
  'laid',
  'lied',
  'glu',
  'guano',
  'hm',
  'il',
  'jarret',
  'juger',
  'laide',
  'lieds',
  'gluant',
  'guerre',
  'hn',
  'ilote',
  'jars',
  'juges',
  'laids',
  'lien',
  'glus',
  'guet',
  'ho',
  'ils',
  'jas',
  'jugez',
  'laie',
  'liens',
  'gm',
  'guette',
  'hobby',
  'im',
  'jasa',
  'jugiez',
  'laies',
  'lient',
  'gn',
  'gueuse',
  'hocha',
  'image',
  'jasait',
  'juif',
  'laine',
  'lier',
  'gnome',
  'gui',
  'hoche',
  'images',
  'jasant',
  'juifs',
  'lainer',
  'liera',
  'gnon',
  'guida',
  'hocher',
  'imbibe',
  'jase',
  'juin',
  'laisse',
  'lierai',
  'gnons',
  'guide',
  'hochet',
  'imbu',
  'jasent',
  'juive',
  'lait',
  'lies',
  'gnou',
  'guider',
  'hockey',
  'imbue',
  'jaser',
  'juives',
  'laits',
  'lieu',
  'gnous',
  'guidez',
  'homard',
  'imbus',
  'jasera',
  'jumeau',
  'lama',
  'lieue',
  'go',
  'guigne',
  'home',
  'imita',
  'jasper',
  'jumela',
  'lamas',
  'lieuse',
  'goal',
  'guimpe',
  'homme',
  'imite',
  'jatte',
  'junior',
  'lame',
  'lieux',
  'goals',
  'guis',
  'honni',
  'imitez',
  'jattes',
  'junte',
  'lamer',
  'liez',
  'goba',
  'guise',
  'honnie',
  'imper',
  'jauge',
  'juntes',
  'lames',
  'lift',
  'gobait',
  'gv',
  'honnis',
  'impie',
  'jauger',
  'jupe',
  'lamine',
  'lifter',
  'gobe',
  'gw',
  'honora',
  'impies',
  'jauges',
  'jupes',
  'lampe',
  'lifts',
  'gober',
  'gx',
  'honte',
  'impoli',
  'jaugez',
  'jupon',
  'lance',
  'ligne',
  'ligner',
  'loua',
  'manche',
  'mess',
  'mixe',
  'muera',
  'nd',
  'noya',
  'ligote',
  'louage',
  'manda',
  'messe',
  'mixent',
  'muerez',
  'ne',
  'noyau',
  'ligua',
  'loue',
  'mandat',
  'messes',
  'mixer',
  'mues',
  'nectar',
  'noyaux',
  'ligue',
  'louer',
  'mande',
  'messie',
  'mixes',
  'muet',
  'nef',
  'noyer',
  'liguer',
  'loues',
  'mander',
  'met',
  'mixez',
  'muets',
  'nefs',
  'noyez',
  'liguez',
  'loueur',
  'mandez',
  'mets',
  'mixte',
  'muez',
  'negro',
  'noyiez',
  'liiez',
  'louez',
  'manga',
  'mette',
  'mixtes',
  'mufle',
  'neige',
  'noyons',
  'liions',
  'louiez',
  'mange',
  'mettes',
  'mj',
  'mugi',
  'neiger',
  'np',
  'lilas',
  'loup',
  'manger',
  'meubla',
  'mk',
  'mugir',
  'nenni',
  'nq',
  'lima',
  'loupe',
  'mania',
  'meugle',
  'ml',
  'mugis',
  'nerf',
  'nr',
  'limais',
  'louper',
  'manie',
  'meule',
  'mm',
  'mugit',
  'nerfs',
  'ns',
  'limait',
  'loups',
  'maniez',
  'meure',
  'mmm',
  'muiez',
  'nervi',
  'nt',
  'lime',
  'lourd',
  'manioc',
  'meurs',
  'mmmm',
  'mule',
  'nervis',
  'nu',
  'limer',
  'lourds',
  'manne',
  'meurt',
  'mn',
  'mules',
  'net',
  'nuage',
  'limera',
  'loutre',
  'manqua',
  'meus',
  'mno',
  'mulet',
  'nets',
  'nubile',
  'limes',
  'louve',
  'mante',
  'meut',
  'mo',
  'mulot',
  'nette',
  'nue',
  'limez',
  'louves',
  'mantes',
  'meute',
  'moche',
  'muni',
  'nettes',
  'nues',
  'limier',
  'lover',
  'manuel',
  'meutes',
  'modal',
  'munie',
  'neuf',
  'nui',
  'limite',
  'loyal',
  'maquer',
  'meuve',
  'modale',
  'munir',
  'neufs',
  'nuira',
  'limoge',
  'loyale',
  'maquis',
  'mf',
  'mode',
  'munira',
  'neutre',
  'nuiras',
  'limon',
  'loyaux',
  'marbre',
  'mg',
  'modela',
  'munis',
  'neuve',
  'nuire',
  'limons',
  'loyer',
  'marc',
  'mh',
  'modem',
  'munit',
  'neveu',
  'nuirez',
  'lin',
  'loyers',
  'marche',
  'mi',
  'modes',
  'muons',
  'nez',
  'nuis',
  'linge',
  'lp',
  'mardi',
  'miasme',
  'modula',
  'mur',
  'nf',
  'nuise',
  'lino',
  'lq',
  'mare',
  'mica',
  'modus',
  'mura',
  'ng',
  'nuit',
  'linos',
  'lr',
  'mares',
  'micas',
  'moelle',
  'murais',
  'nh',
  'nuits',
  'lion',
  'ls',
  'marge',
  'miche',
  'moi',
  'mural',
  'ni',
  'nul',
  'lionne',
  'lt',
  'marges',
  'miches',
  'moine',
  'murale',
  'nia',
  'nulle',
  'lions',
  'lu',
  'mari',
  'micmac',
  'moines',
  'murals',
  'niable',
  'nulles',
  'lipide',
  'lubie',
  'maria',
  'micro',
  'moins',
  'mure',
  'niais',
  'nuls',
  'lippe',
  'lucide',
  'marie',
  'micron',
  'moire',
  'murent',
  'niait',
  'nuque',
  'lippu',
  'lucre',
  'maries',
  'micros',
  'mois',
  'murer',
  'niant',
  'nurse',
  'lippue',
  'lue',
  'mariez',
  'midi',
  'moisi',
  'mures',
  'nicha',
  'nus',
  'lira',
  'lues',
  'marin',
  'midis',
  'moisie',
  'muret',
  'niche',
  'nv',
  'lirai',
  'lueur',
  'marina',
  'mie',
  'moisir',
  'murez',
  'nicher',
  'nw',
  'liras',
  'luge',
  'marine',
  'miel',
  'moite',
  'muriez',
  'niches',
  'nx',
  'lire',
  'luger',
  'maris',
  'miels',
  'moites',
  'murons',
  'nid',
  'ny',
  'lires',
  'luges',
  'mark',
  'mien',
  'moitir',
  'murs',
  'nids',
  'nylon',
  'lirez',
  'lui',
  'marks',
  'mienne',
  'moka',
  'mus',
  'nie',
  'nz',
  'liriez',
  'luira',
  'marne',
  'miens',
  'mokas',
  'musc',
  'nient',
  'o',
  'lis',
  'luire',
  'marner',
  'mieux',
  'mol',
  'muscat',
  'nier',
  'oa',
  'lisait',
  'luise',
  'marque',
  'mignon',
  'molle',
  'muscle',
  'niera',
  'oasis',
  'lisant',
  'luit',
  'marra',
  'migre',
  'mollet',
  'muscs',
  'nierai',
  'ob',
  'lise',
  'lump',
  'marre',
  'migrer',
  'molli',
  'muse',
  'nies',
  'obier',
  'lisent',
  'lumps',
  'marres',
  'migrez',
  'moment',
  'museau',
  'niez',
  'objet',
  'lises',
  'lunch',
  'marri',
  'mijota',
  'momie',
  'muser',
  'nigaud',
  'objets',
  'lisez',
  'lundi',
  'marrie',
  'mil',
  'momies',
  'muses',
  'niiez',
  'oblige',
  'lissa',
  'lune',
  'marris',
  'milan',
  'mon',
  'musser',
  'nimber',
  'obole',
  'lisse',
  'lunes',
  'mars',
  'mile',
  'monde',
  'muta',
  'ninja',
  'obture',
  'lisses',
  'lupin',
  'marte',
  'miles',
  'monder',
  'mutant',
  'ninjas',
  'obtus',
  'lista',
  'lupins',
  'martel',
  'milite',
  'mondes',
  'mute',
  'nions',
  'obus',
  'liste',
  'lurent',
  'martes',
  'mille',
  'mont',
  'muter',
  'nippe',
  'obvier',
  'listez',
  'luron',
  'martre',
  'milles',
  'monta',
  'mutera',
  'nipper',
  'oc',
  'lit',
  'lurons',
  'martyr',
  'millet',
  'monte',
  'mutes',
  'nique',
  'occupe',
  'liter',
  'lus',
  'mas',
  'mima',
  'montra',
  'mutez',
  'niveau',
  'ocre',
  'litre',
  'lustra',
  'masqua',
  'mimait',
  'monts',
  'mutiez',
  'nivela',
  'ocrer',
  'litres',
  'lustre',
  'massa',
  'mimant',
  'moqua',
  'mutile',
  'nj',
  'ocres',
  'lits',
  'lut',
  'masse',
  'mime',
  'moque',
  'mutin',
  'nk',
  'octal',
  'livra',
  'luter',
  'masser',
  'mimer',
  'moquez',
  'mutina',
  'nl',
  'octane',
  'livre',
  'luth',
  'massue',
  'mimes',
  'moral',
  'mv',
  'nm',
  'octave',
  'livrer',
  'luths',
  'mastic',
  'mimez',
  'mord',
  'mw',
  'nn',
  'octet',
  'lj',
  'lutin',
  'mastoc',
  'min',
  'morde',
  'mx',
  'nnn',
  'octroi',
  'lk',
  'lutta',
  'masure',
  'mina',
  'mordre',
  'my',
  'nnnn',
  'od',
  'll',
  'lutte',
  'mat',
  'minais',
  'mords',
  'myope',
  'no',
  'ode',
  'lll',
  'lutter',
  'match',
  'mince',
  'mordu',
  'myrrhe',
  'noble',
  'odes',
  'llll',
  'lux',
  'matchs',
  'minci',
  'mordue',
  'mythe',
  'nobles',
  'odeur',
  'lm',
  'luxa',
  'mate',
  'mincie',
  'morgue',
  'mz',
  'noce',
  'odieux',
  'lmn',
  'luxant',
  'mater',
  'mincis',
  'morne',
  'n',
  'noces',
  'oe',
  'ln',
  'luxe',
  'mates',
  'mine',
  'mornes',
  'na',
  'nocif',
  'oeil',
  'lo',
  'luxent',
  'math',
  'minent',
  'mors',
  'nabab',
  'nocifs',
  'oeuf',
  'lob',
  'luxer',
  'maths',
  'miner',
  'morse',
  'nababs',
  'nodule',
  'oeufs',
  'lobby',
  'luxes',
  'matin',
  'mines',
  'morses',
  'nabot',
  'noeud',
  'oeuvre',
  'lobe',
  'luxez',
  'matir',
  'minet',
  'mort',
  'nabote',
  'noie',
  'of',
  'lober',
  'luxiez',
  'mats',
  'mineur',
  'morte',
  'nacre',
  'noiera',
  'offert',
  'lobes',
  'luxure',
  'mature',
  'minez',
  'mortel',
  'nacres',
  'noies',
  'office',
  'lobs',
  'lv',
  'mauve',
  'minime',
  'morts',
  'nage',
  'noir',
  'offre',
  'local',
  'lw',
  'mauves',
  'minois',
  'morue',
  'nagea',
  'noire',
  'offres',
  'locale',
  'lx',
  'mauvis',
  'minons',
  'morve',
  'nager',
  'noires',
  'offrez',
  'locher',
  'ly',
  'maux',
  'minora',
  'mot',
  'nages',
  'noirs',
  'og',
  'loden',
  'lynx',
  'maxima',
  'minore',
  'motel',
  'nagez',
  'noise',
  'ogive',
  'loess',
  'lyre',
  'maya',
  'minuit',
  'motif',
  'nain',
  'noises',
  'ogives',
  'lofer',
  'lyres',
  'mayas',
  'minus',
  'motiva',
  'naine',
  'noix',
  'ogre',
  'loge',
  'lys',
  'mazer',
  'mioche',
  'moto',
  'nains',
  'nom',
  'ogres',
  'logea',
  'lyse',
  'mazout',
  'mira',
  'motos',
  'nais',
  'nomade',
  'oh',
  'logent',
  'lyses',
  'mb',
  'mirage',
  'mots',
  'naja',
  'nomma',
  'ohm',
  'loger',
  'lz',
  'mc',
  'mirait',
  'motte',
  'najas',
  'nomme',
  'ohms',
  'loges',
  'm',
  'md',
  'mirant',
  'mou',
  'nanan',
  'nommer',
  'oi',
  'logeur',
  'ma',
  'me',
  'mire',
  'moucha',
  'nanti',
  'noms',
  'oie',
  'logez',
  'mach',
  'melon',
  'mirent',
  'mouche',
  'nantir',
  'non',
  'oies',
  'logiez',
  'madone',
  'men',
  'mirer',
  'moud',
  'nantis',
  'nonce',
  'oignon',
  'logis',
  'mafia',
  'mena',
  'mirera',
  'moudra',
  'nantit',
  'nonne',
  'oisif',
  'logo',
  'mage',
  'menace',
  'mires',
  'moudre',
  'napalm',
  'nop',
  'oisifs',
  'logos',
  'mages',
  'menait',
  'mirez',
  'mouds',
  'nappe',
  'nord',
  'oison',
  'loi',
  'magie',
  'menant',
  'miroir',
  'moue',
  'naquit',
  'normal',
  'oisons',
  'loin',
  'magma',
  'mendia',
  'mis',
  'moues',
  'narra',
  'norme',
  'oj',
  'loir',
  'magner',
  'mendie',
  'misa',
  'moula',
  'narre',
  'normes',
  'ok',
  'loirs',
  'magot',
  'mener',
  'misait',
  'moule',
  'narres',
  'nos',
  'okapi',
  'lois',
  'mai',
  'meneur',
  'misant',
  'moulin',
  'narrez',
  'nota',
  'ol',
  'long',
  'maille',
  'menez',
  'mise',
  'moulu',
  'naseau',
  'notais',
  'olive',
  'longe',
  'main',
  'menons',
  'miser',
  'mourez',
  'nasse',
  'notait',
  'olives',
  'longer',
  'mains',
  'mens',
  'misera',
  'mouron',
  'natal',
  'note',
  'om',
  'longes',
  'maint',
  'ment',
  'mises',
  'mourut',
  'natale',
  'notent',
  'ombre',
  'longs',
  'mainte',
  'mente',
  'misez',
  'mous',
  'natif',
  'noter',
  'omet',
  'lopin',
  'maints',
  'mentez',
  'misiez',
  'moussa',
  'nation',
  'notera',
  'omets',
  'lopins',
  'maire',
  'menti',
  'misons',
  'mousse',
  'natte',
  'notes',
  'omette',
  'loque',
  'maires',
  'menton',
  'miss',
  'mouvez',
  'natter',
  'notez',
  'omis',
  'lors',
  'mairie',
  'mentor',
  'missel',
  'moyen',
  'nattes',
  'notice',
  'omise',
  'lot',
  'mais',
  'menu',
  'misses',
  'moyeu',
  'naval',
  'notons',
  'omit',
  'lote',
  'major',
  'menue',
  'mit',
  'moyeux',
  'navals',
  'notre',
  'on',
  'lotes',
  'mal',
  'menues',
  'mita',
  'mp',
  'navet',
  'notule',
  'onagre',
  'loti',
  'malaxa',
  'menuet',
  'mite',
  'mq',
  'navets',
  'noua',
  'once',
  'lotie',
  'malice',
  'menus',
  'mitent',
  'mr',
  'navire',
  'noue',
  'onces',
  'loties',
  'malin',
  'mer',
  'miter',
  'ms',
  'navra',
  'nouer',
  'oncle',
  'lotir',
  'malins',
  'merci',
  'mitera',
  'mt',
  'navre',
  'nouera',
  'oncles',
  'lotira',
  'malle',
  'mercis',
  'mites',
  'mu',
  'navrer',
  'noues',
  'onde',
  'lotis',
  'malles',
  'merder',
  'mitre',
  'mua',
  'navrez',
  'noueux',
  'ondes',
  'lotit',
  'malt',
  'merise',
  'mitron',
  'muais',
  'nazi',
  'nouez',
  'ondula',
  'loto',
  'malter',
  'merlan',
  'mixa',
  'muait',
  'nazie',
  'nouiez',
  'ongle',
  'lots',
  'malus',
  'merle',
  'mixage',
  'muant',
  'nazies',
  'nouons',
  'ont',
  'lotte',
  'maman',
  'merlu',
  'mixais',
  'mue',
  'nazis',
  'nourri',
  'onyx',
  'lottes',
  'mamans',
  'mers',
  'mixait',
  'muent',
  'nb',
  'nous',
  'onze',
  'lotus',
  'man',
  'mes',
  'mixant',
  'muer',
  'nc',
  'nover',
  'oo',
  'ooo',
  'ozones',
  'pave',
  'piger',
  'po',
  'priera',
  'qu',
  'raquer',
  'oooo',
  'p',
  'pavent',
  'piges',
  'poche',
  'pries',
  'quai',
  'rare',
  'op',
  'pa',
  'paver',
  'pigne',
  'pocher',
  'priez',
  'quais',
  'rares',
  'opale',
  'pacha',
  'paves',
  'pignon',
  'poches',
  'prima',
  'quand',
  'ras',
  'opales',
  'pack',
  'paveur',
  'pila',
  'podium',
  'primat',
  'quant',
  'rasa',
  'opaque',
  'packs',
  'pavez',
  'pilait',
  'pogrom',
  'prime',
  'quart',
  'rasade',
  'opina',
  'pacte',
  'pavois',
  'pile',
  'poids',
  'primer',
  'quarts',
  'rasage',
  'opine',
  'page',
  'pavons',
  'piler',
  'poil',
  'prions',
  'quartz',
  'rasais',
  'opiner',
  'pages',
  'pavot',
  'piles',
  'poils',
  'pris',
  'quasi',
  'rasant',
  'opines',
  'pagina',
  'paya',
  'pileux',
  'poilu',
  'prisa',
  'quatre',
  'rase',
  'opium',
  'pagine',
  'paye',
  'pilez',
  'poilue',
  'prise',
  'que',
  'rasent',
  'opq',
  'pagne',
  'payent',
  'piliez',
  'poilus',
  'prises',
  'quel',
  'raser',
  'opta',
  'pagode',
  'payer',
  'pilla',
  'poing',
  'prison',
  'quels',
  'rases',
  'opte',
  'paie',
  'payera',
  'pille',
  'poings',
  'prit',
  'queue',
  'rasez',
  'optent',
  'paient',
  'payes',
  'pillez',
  'point',
  'priva',
  'queux',
  'rasoir',
  'opter',
  'paies',
  'payeur',
  'pilon',
  'pointa',
  'prive',
  'qui',
  'rasons',
  'optera',
  'paille',
  'payez',
  'pilou',
  'points',
  'priver',
  'quidam',
  'rassir',
  'optes',
  'pain',
  'payiez',
  'pilous',
  'pointu',
  'prix',
  'quille',
  'rassit',
  'optez',
  'pains',
  'pays',
  'pilule',
  'poire',
  'probe',
  'quinte',
  'rat',
  'optima',
  'pair',
  'payse',
  'pin',
  'pois',
  'proche',
  'quitus',
  'rata',
  'option',
  'paire',
  'pb',
  'pince',
  'poisse',
  'profit',
  'quo',
  'ratage',
  'opus',
  'paires',
  'pc',
  'pins',
  'poix',
  'proie',
  'quoi',
  'ratais',
  'oq',
  'pairs',
  'pd',
  'pinte',
  'poker',
  'proies',
  'quota',
  'ratant',
  'or',
  'paix',
  'pe',
  'piolet',
  'pokers',
  'projet',
  'quotas',
  'rate',
  'orage',
  'pal',
  'peau',
  'pion',
  'poli',
  'promet',
  'qv',
  'rater',
  'oral',
  'palace',
  'peaux',
  'pions',
  'polie',
  'promis',
  'qw',
  'ratera',
  'orale',
  'palan',
  'peina',
  'pipe',
  'polio',
  'promit',
  'qx',
  'rates',
  'oraux',
  'pale',
  'peine',
  'pipeau',
  'polios',
  'promu',
  'qy',
  'ratez',
  'orc',
  'pales',
  'peiner',
  'piper',
  'polir',
  'promue',
  'qz',
  'ratio',
  'orcs',
  'palet',
  'peines',
  'pipes',
  'polis',
  'promus',
  'r',
  'ration',
  'ordre',
  'palier',
  'peinez',
  'piqua',
  'polit',
  'promut',
  'ra',
  'raton',
  'ordure',
  'palme',
  'peins',
  'pique',
  'polka',
  'pronom',
  'rabane',
  'ratons',
  'ores',
  'palmes',
  'peint',
  'piquer',
  'pollen',
  'propre',
  'rabat',
  'rats',
  'orge',
  'palot',
  'peints',
  'piques',
  'polo',
  'prose',
  'rabbin',
  'ravage',
  'orges',
  'palpa',
  'pela',
  'pire',
  'polos',
  'proue',
  'rabiot',
  'ravale',
  'orgie',
  'palpe',
  'pelage',
  'pires',
  'pomme',
  'proues',
  'rabot',
  'rave',
  'orgies',
  'palpes',
  'peler',
  'pis',
  'pommer',
  'prude',
  'rabote',
  'raves',
  'orgue',
  'palpez',
  'pelez',
  'pisser',
  'pompa',
  'prune',
  'race',
  'ravi',
  'orme',
  'pals',
  'peliez',
  'pista',
  'pompe',
  'ps',
  'races',
  'ravie',
  'ormeau',
  'pampa',
  'pelle',
  'piste',
  'pompez',
  'pt',
  'racine',
  'ravies',
  'ormes',
  'pampas',
  'pelles',
  'pister',
  'pompon',
  'pu',
  'racla',
  'ravin',
  'orna',
  'pan',
  'pelons',
  'pistes',
  'ponce',
  'pua',
  'racle',
  'ravina',
  'ornais',
  'panda',
  'pelote',
  'pistez',
  'poncez',
  'puait',
  'raclez',
  'ravir',
  'ornant',
  'panel',
  'pencha',
  'pistil',
  'pond',
  'puant',
  'racola',
  'ravira',
  'orne',
  'panels',
  'penche',
  'piteux',
  'ponde',
  'pub',
  'racole',
  'ravis',
  'ornent',
  'paner',
  'pend',
  'piton',
  'pondes',
  'pubis',
  'radar',
  'ravisa',
  'orner',
  'panne',
  'pende',
  'pitons',
  'pondre',
  'public',
  'rade',
  'ravit',
  'ornes',
  'pannes',
  'pendes',
  'pitre',
  'ponds',
  'publie',
  'radeau',
  'ravoir',
  'ornez',
  'pans',
  'pendra',
  'pivot',
  'pondu',
  'pubs',
  'rader',
  'raya',
  'orniez',
  'pansa',
  'pends',
  'pivote',
  'pondue',
  'puce',
  'rades',
  'rayait',
  'ornons',
  'panse',
  'pendu',
  'pivots',
  'poney',
  'puceau',
  'radia',
  'raye',
  'oronge',
  'panser',
  'pendue',
  'pixel',
  'pont',
  'puces',
  'radie',
  'rayer',
  'orque',
  'pansez',
  'pensa',
  'pixels',
  'ponte',
  'pue',
  'radio',
  'rayes',
  'ors',
  'pantin',
  'pense',
  'pizza',
  'ponter',
  'puent',
  'radios',
  'rayez',
  'orteil',
  'paon',
  'penses',
  'pizzas',
  'ponts',
  'puer',
  'radis',
  'rayon',
  'ortie',
  'paonne',
  'pensez',
  'pj',
  'pool',
  'puera',
  'radium',
  'rayons',
  'orvet',
  'paons',
  'pensum',
  'pk',
  'pools',
  'puis',
  'radius',
  'raz',
  'os',
  'papa',
  'pente',
  'pl',
  'pope',
  'puisa',
  'radota',
  'razzia',
  'osa',
  'papal',
  'perce',
  'place',
  'popes',
  'puise',
  'radoub',
  'rb',
  'osais',
  'papale',
  'perd',
  'places',
  'popote',
  'puiser',
  'rafale',
  'rc',
  'osait',
  'papas',
  'perde',
  'placet',
  'porc',
  'puises',
  'raffut',
  'rd',
  'osant',
  'papaux',
  'perdre',
  'placez',
  'porcs',
  'puisse',
  'rafla',
  're',
  'oscar',
  'pape',
  'perds',
  'plage',
  'pore',
  'puits',
  'rafle',
  'rebat',
  'oscars',
  'papes',
  'perdu',
  'plagia',
  'pores',
  'pull',
  'rafler',
  'rebord',
  'ose',
  'papier',
  'perdue',
  'plagie',
  'porno',
  'pulls',
  'rafles',
  'rebut',
  'osent',
  'paquet',
  'perla',
  'plaid',
  'pornos',
  'pulpe',
  'raflez',
  'rebuta',
  'oser',
  'par',
  'perle',
  'plaids',
  'port',
  'puma',
  'rage',
  'recel',
  'osera',
  'para',
  'permet',
  'plaie',
  'porta',
  'pumas',
  'ragea',
  'recoin',
  'oses',
  'parada',
  'permis',
  'plaies',
  'porte',
  'punch',
  'ragent',
  'record',
  'osez',
  'parade',
  'permit',
  'plaine',
  'porto',
  'puni',
  'rager',
  'recoud',
  'osier',
  'parafe',
  'perron',
  'plains',
  'ports',
  'punie',
  'ragera',
  'recru',
  'osiers',
  'paras',
  'pers',
  'plais',
  'posa',
  'punir',
  'rages',
  'recta',
  'osiez',
  'parc',
  'perse',
  'plan',
  'pose',
  'punira',
  'ragez',
  'recto',
  'osions',
  'parce',
  'perses',
  'plana',
  'poser',
  'punis',
  'ragot',
  'rectum',
  'osmose',
  'parcs',
  'perte',
  'plane',
  'poses',
  'punit',
  'ragots',
  'recuit',
  'osons',
  'pare',
  'pesa',
  'planes',
  'posez',
  'pur',
  'raguer',
  'recul',
  'ot',
  'pareil',
  'pesage',
  'planez',
  'posons',
  'pure',
  'raid',
  'redira',
  'otage',
  'parer',
  'pesais',
  'plans',
  'posta',
  'purent',
  'raide',
  'redire',
  'otages',
  'pares',
  'peser',
  'plant',
  'postal',
  'pures',
  'raidi',
  'redis',
  'otarie',
  'parez',
  'peseta',
  'plante',
  'poste',
  'purge',
  'raids',
  'redit',
  'otite',
  'pari',
  'pesez',
  'plaqua',
  'postez',
  'purgea',
  'raie',
  'redits',
  'otites',
  'paria',
  'pesons',
  'plasma',
  'pot',
  'purger',
  'raies',
  'redoux',
  'ou',
  'parias',
  'pesta',
  'plat',
  'potage',
  'purin',
  'rail',
  'refera',
  'ouate',
  'parie',
  'peste',
  'plate',
  'pote',
  'purs',
  'railla',
  'refit',
  'ouater',
  'paris',
  'pestes',
  'plates',
  'potes',
  'pus',
  'raille',
  'reflux',
  'oubli',
  'parla',
  'pet',
  'plats',
  'potin',
  'put',
  'rails',
  'refuge',
  'oublis',
  'parle',
  'petit',
  'plein',
  'potion',
  'putain',
  'rainer',
  'refus',
  'oued',
  'parles',
  'peton',
  'pleins',
  'pots',
  'putois',
  'raire',
  'refuse',
  'oueds',
  'parmi',
  'petons',
  'pleur',
  'pou',
  'puzzle',
  'rama',
  'reg',
  'ouest',
  'paroi',
  'pets',
  'pleura',
  'pouce',
  'pv',
  'ramait',
  'regard',
  'oui',
  'pars',
  'peu',
  'pleure',
  'pouces',
  'pw',
  'rame',
  'regret',
  'ourdi',
  'part',
  'peupla',
  'pleut',
  'poudra',
  'px',
  'ramena',
  'regs',
  'ourdie',
  'parte',
  'peuple',
  'pli',
  'poudre',
  'py',
  'rament',
  'rein',
  'ourdis',
  'parti',
  'peur',
  'plia',
  'pouf',
  'pyjama',
  'ramer',
  'reine',
  'ourler',
  'partie',
  'peurs',
  'pliage',
  'poufs',
  'pyrex',
  'rames',
  'reines',
  'ours',
  'partit',
  'peut',
  'plie',
  'poule',
  'pz',
  'rameur',
  'reins',
  'ourse',
  'parts',
  'peux',
  'plier',
  'poules',
  'q',
  'ramez',
  'rejet',
  'outil',
  'paru',
  'pf',
  'plies',
  'poulet',
  'qa',
  'rami',
  'rejeta',
  'outils',
  'parue',
  'pg',
  'pliez',
  'pouls',
  'qb',
  'ramis',
  'relaxa',
  'outra',
  'parure',
  'ph',
  'pliiez',
  'poumon',
  'qc',
  'ramone',
  'relent',
  'outre',
  'parus',
  'phare',
  'plions',
  'poupe',
  'qd',
  'ramons',
  'releva',
  'outrez',
  'parut',
  'phares',
  'plis',
  'poupes',
  'qe',
  'rampa',
  'relia',
  'ouvre',
  'pas',
  'phase',
  'pliure',
  'poupin',
  'qf',
  'rampe',
  'relie',
  'ouvrer',
  'pascal',
  'phases',
  'ploie',
  'poupon',
  'qg',
  'ramper',
  'relira',
  'ouvrez',
  'passa',
  'phobie',
  'plomb',
  'pour',
  'qh',
  'rampez',
  'relis',
  'ov',
  'passe',
  'phone',
  'plombe',
  'pourra',
  'qi',
  'rance',
  'relise',
  'ovaire',
  'passer',
  'phono',
  'plot',
  'pourri',
  'qj',
  'rances',
  'relit',
  'ovale',
  'passes',
  'phoque',
  'plots',
  'pourvu',
  'qk',
  'ranch',
  'relu',
  'ovales',
  'passez',
  'photo',
  'ploya',
  'pousse',
  'ql',
  'ranci',
  'relue',
  'ovine',
  'passif',
  'pi',
  'ployer',
  'poux',
  'qm',
  'rancie',
  'relui',
  'ovni',
  'patent',
  'piaffe',
  'ployez',
  'pp',
  'qn',
  'rancir',
  'reluit',
  'ovnis',
  'patin',
  'piano',
  'plu',
  'ppp',
  'qo',
  'rancis',
  'relus',
  'ovule',
  'patine',
  'pic',
  'pluie',
  'pppp',
  'qp',
  'rang',
  'relut',
  'ovuler',
  'patio',
  'pics',
  'plume',
  'pq',
  'qq',
  'range',
  'remet',
  'ow',
  'patte',
  'pie',
  'plumer',
  'pqr',
  'qqn',
  'rangez',
  'remis',
  'ox',
  'paume',
  'pied',
  'plumes',
  'pr',
  'qqq',
  'rangs',
  'remisa',
  'oxyda',
  'paumes',
  'pieds',
  'plus',
  'prend',
  'qqqq',
  'ranime',
  'remit',
  'oxyde',
  'pause',
  'pies',
  'plut',
  'presse',
  'qqun',
  'rapace',
  'remous',
  'oxyder',
  'pauses',
  'pieu',
  'pm',
  'pria',
  'qr',
  'raphia',
  'remua',
  'oy',
  'pava',
  'pieuse',
  'pn',
  'priait',
  'qrs',
  'rappel',
  'remue',
  'oz',
  'pavait',
  'pieux',
  'pneu',
  'prie',
  'qs',
  'rapt',
  'remuer',
  'ozone',
  'pavant',
  'pige',
  'pneus',
  'prier',
  'qt',
  'rapts',
  'renais',
  'renard',
  'riviez',
  'rue',
  'sapes',
  'sets',
  'soins',
  'statut',
  'sz',
  'rend',
  'rivons',
  'ruent',
  'sapeur',
  'setter',
  'soir',
  'steak',
  't',
  'rende',
  'rixe',
  'ruer',
  'sapin',
  'seuil',
  'soirs',
  'steaks',
  'ta',
  'rendes',
  'rixes',
  'ruera',
  'saquer',
  'seuils',
  'sois',
  'stem',
  'tabac',
  'rendez',
  'riz',
  'rueras',
  'sari',
  'seul',
  'soit',
  'stemm',
  'tabacs',
  'rendit',
  'rj',
  'ruerez',
  'saris',
  'seule',
  'soja',
  'stemms',
  'tabla',
  'rendre',
  'rk',
  'rues',
  'sarrau',
  'seules',
  'sojas',
  'stems',
  'table',
  'rends',
  'rl',
  'ruez',
  'sas',
  'seuls',
  'sol',
  'stick',
  'tabler',
  'rendu',
  'rm',
  'rugby',
  'satin',
  'sevra',
  'solda',
  'stock',
  'tabou',
  'renia',
  'rn',
  'rugbys',
  'satins',
  'sevrer',
  'solde',
  'stocka',
  'tac',
  'renie',
  'ro',
  'rugi',
  'satire',
  'sevrez',
  'soldez',
  'stocke',
  'tacha',
  'renies',
  'robe',
  'rugir',
  'satura',
  'sexe',
  'sole',
  'stop',
  'tache',
  'renne',
  'rober',
  'rugira',
  'sauce',
  'sexes',
  'solen',
  'stoppe',
  'tachez',
  'renom',
  'robes',
  'rugis',
  'sauces',
  'sexy',
  'soles',
  'stops',
  'tact',
  'renoms',
  'robot',
  'rugit',
  'sauf',
  'seyant',
  'soli',
  'store',
  'tacts',
  'renoua',
  'roc',
  'ruiez',
  'saufs',
  'sf',
  'solo',
  'stores',
  'taie',
  'rente',
  'roche',
  'ruiler',
  'sauge',
  'sg',
  'solos',
  'strate',
  'taies',
  'renter',
  'roches',
  'ruina',
  'sauges',
  'sh',
  'sols',
  'strie',
  'tailla',
  'rentes',
  'rock',
  'ruine',
  'saule',
  'shah',
  'sombra',
  'stu',
  'taille',
  'rentra',
  'rocker',
  'ruinez',
  'saules',
  'shahs',
  'sombre',
  'stuc',
  'tain',
  'rentre',
  'rocks',
  'ruions',
  'sauna',
  'shako',
  'somma',
  'stucs',
  'taira',
  'renvoi',
  'rococo',
  'rumina',
  'sauner',
  'shoot',
  'somme',
  'style',
  'tairas',
  'repaie',
  'rocs',
  'rumine',
  'saur',
  'shoots',
  'sommes',
  'styles',
  'taire',
  'repars',
  'roda',
  'ruons',
  'saura',
  'short',
  'sommet',
  'stylet',
  'tairez',
  'repas',
  'rodage',
  'rural',
  'saurs',
  'shorts',
  'sommez',
  'stylo',
  'tais',
  'repaya',
  'rodais',
  'rusa',
  'saut',
  'show',
  'son',
  'su',
  'taise',
  'repens',
  'rode',
  'ruse',
  'sauta',
  'shows',
  'sonar',
  'sua',
  'tait',
  'repent',
  'roder',
  'ruser',
  'saute',
  'si',
  'sonate',
  'suais',
  'talc',
  'repli',
  'rodes',
  'rusera',
  'sauts',
  'sic',
  'sonda',
  'suait',
  'talcs',
  'repos',
  'rodez',
  'ruses',
  'sauva',
  'sied',
  'sonde',
  'suant',
  'taler',
  'reposa',
  'rogna',
  'rusez',
  'sauve',
  'sien',
  'sondes',
  'suave',
  'talon',
  'repose',
  'rogne',
  'rush',
  'savait',
  'sienne',
  'songe',
  'suaves',
  'talons',
  'repris',
  'rogner',
  'rushs',
  'savant',
  'siens',
  'songea',
  'subi',
  'talus',
  'reprit',
  'rognez',
  'rusiez',
  'savez',
  'sieste',
  'songer',
  'subie',
  'tamis',
  'repu',
  'rognon',
  'russe',
  'saviez',
  'sieur',
  'songez',
  'subies',
  'tan',
  'repue',
  'rogue',
  'russes',
  'savon',
  'siffla',
  'sonna',
  'subir',
  'tandem',
  'repues',
  'roi',
  'rut',
  'savons',
  'sigle',
  'sonne',
  'subis',
  'tandis',
  'repus',
  'rois',
  'rutila',
  'saxo',
  'signa',
  'sonner',
  'subit',
  'tango',
  'ressac',
  'roll',
  'ruts',
  'saxon',
  'signal',
  'sonnet',
  'subits',
  'tangua',
  'resta',
  'romain',
  'rv',
  'saxos',
  'signe',
  'sono',
  'suc',
  'tangue',
  'reste',
  'roman',
  'rw',
  'sb',
  'signer',
  'sonos',
  'suce',
  'tanin',
  'rester',
  'rompe',
  'rx',
  'sbire',
  'signes',
  'sons',
  'sucent',
  'tank',
  'retard',
  'rompes',
  'ry',
  'sc',
  'signet',
  'sont',
  'sucer',
  'tanks',
  'retenu',
  'romps',
  'rythme',
  'scalp',
  'signez',
  'sorbe',
  'sucera',
  'tant',
  'revint',
  'rompt',
  'rz',
  'scanda',
  'silex',
  'sorbes',
  'suces',
  'tante',
  'revis',
  'rompu',
  's',
  'scande',
  'sillon',
  'sors',
  'sucez',
  'tantes',
  'revit',
  'rompue',
  'sa',
  'sceau',
  'silo',
  'sort',
  'sucra',
  'taon',
  'revois',
  'ronce',
  'sabir',
  'scella',
  'silos',
  'sorte',
  'sucre',
  'taons',
  'revoit',
  'ronces',
  'sabirs',
  'scia',
  'simoun',
  'sorti',
  'sucres',
  'tapa',
  'revu',
  'rond',
  'sable',
  'sciant',
  'singe',
  'sortie',
  'sucs',
  'tapais',
  'revue',
  'ronde',
  'sabot',
  'scie',
  'singer',
  'sortis',
  'sud',
  'tapait',
  'revus',
  'rondin',
  'sabote',
  'scier',
  'sinon',
  'sortit',
  'sue',
  'tapant',
  'rf',
  'ronds',
  'sabra',
  'sciera',
  'sinus',
  'sorts',
  'suent',
  'tape',
  'rg',
  'ronfla',
  'sabre',
  'scies',
  'siphon',
  'sosie',
  'suer',
  'taper',
  'rh',
  'ronge',
  'sabrer',
  'sciez',
  'sire',
  'sot',
  'suera',
  'tapes',
  'rhum',
  'rongea',
  'sabres',
  'sciiez',
  'sirop',
  'sots',
  'suerai',
  'tapeur',
  'rhume',
  'roque',
  'sabrez',
  'scion',
  'sis',
  'sotte',
  'sueras',
  'tapez',
  'rhums',
  'rosace',
  'sac',
  'scions',
  'sisal',
  'sottes',
  'sues',
  'tapi',
  'ri',
  'rosbif',
  'sache',
  'scoop',
  'sise',
  'sou',
  'sueur',
  'tapie',
  'riais',
  'rose',
  'saches',
  'score',
  'sises',
  'souci',
  'suez',
  'tapies',
  'riait',
  'roser',
  'sacre',
  'scorie',
  'site',
  'soucia',
  'suffi',
  'tapir',
  'riant',
  'roses',
  'sacrer',
  'scout',
  'sites',
  'soucie',
  'suffit',
  'tapira',
  'riants',
  'rosi',
  'sacres',
  'scouts',
  'situa',
  'soucis',
  'suie',
  'tapirs',
  'ribler',
  'rosie',
  'sacs',
  'scruta',
  'situe',
  'souda',
  'suiez',
  'tapis',
  'riche',
  'rosir',
  'saga',
  'sd',
  'situer',
  'soude',
  'suif',
  'tapit',
  'ricin',
  'rosis',
  'sagas',
  'se',
  'situes',
  'souder',
  'suint',
  'tapons',
  'rida',
  'rosit',
  'sage',
  'seau',
  'situez',
  'soudes',
  'suinta',
  'tapota',
  'ridant',
  'rosse',
  'sages',
  'seaux',
  'six',
  'souk',
  'suinte',
  'taquet',
  'ride',
  'rosses',
  'saille',
  'sec',
  'sj',
  'souks',
  'suis',
  'taquin',
  'rider',
  'rot',
  'sailli',
  'secs',
  'sk',
  'soulte',
  'suisse',
  'tard',
  'rides',
  'roter',
  'sain',
  'secte',
  'skate',
  'soumet',
  'suit',
  'tarda',
  'rie',
  'rotin',
  'saine',
  'seiche',
  'ski',
  'soumit',
  'suite',
  'tarde',
  'rien',
  'rotins',
  'saines',
  'sein',
  'skia',
  'soupa',
  'suive',
  'tardez',
  'riens',
  'rotor',
  'sains',
  'seing',
  'skiant',
  'soupe',
  'suives',
  'tardif',
  'rient',
  'rots',
  'saint',
  'seins',
  'skie',
  'souper',
  'suivi',
  'tare',
  'ries',
  'rotule',
  'sainte',
  'seize',
  'skient',
  'soupes',
  'suivie',
  'tarer',
  'rieur',
  'roue',
  'sais',
  'sel',
  'skier',
  'soupez',
  'suivis',
  'tares',
  'riez',
  'rouer',
  'saisi',
  'self',
  'skiera',
  'source',
  'suivit',
  'targua',
  'rifle',
  'roues',
  'saisie',
  'selfs',
  'skies',
  'sourd',
  'suivre',
  'tari',
  'rifles',
  'rouge',
  'saisis',
  'selle',
  'skieur',
  'sourde',
  'sujet',
  'tarie',
  'rigide',
  'rouget',
  'sait',
  'seller',
  'skiez',
  'sourds',
  'sujets',
  'taries',
  'riiez',
  'rougi',
  'sala',
  'selon',
  'skiff',
  'souri',
  'sulky',
  'tarif',
  'rima',
  'rougie',
  'salait',
  'sels',
  'skiffs',
  'souris',
  'sulkys',
  'tarifa',
  'rime',
  'rougir',
  'sale',
  'sema',
  'skions',
  'sous',
  'sultan',
  'tarife',
  'riment',
  'rouir',
  'saler',
  'semais',
  'skis',
  'soute',
  'suons',
  'tarir',
  'rimer',
  'roula',
  'sales',
  'semble',
  'sl',
  'soviet',
  'super',
  'taris',
  'rimes',
  'roule',
  'salez',
  'semer',
  'slave',
  'soya',
  'supin',
  'tarit',
  'rince',
  'rouler',
  'sali',
  'semez',
  'slip',
  'soyas',
  'sur',
  'tarot',
  'rinces',
  'roules',
  'salie',
  'semis',
  'slips',
  'soyeux',
  'sure',
  'tarots',
  'ring',
  'roulez',
  'salin',
  'semoir',
  'slogan',
  'soyez',
  'surent',
  'tarte',
  'rings',
  'roulis',
  'saline',
  'sens',
  'slow',
  'sp',
  'sures',
  'tartes',
  'rions',
  'round',
  'salir',
  'sent',
  'slows',
  'spasme',
  'surf',
  'tartir',
  'rioter',
  'rounds',
  'salis',
  'sente',
  'sm',
  'sphinx',
  'surfa',
  'tas',
  'riper',
  'rousse',
  'salit',
  'senti',
  'smash',
  'spire',
  'surfai',
  'tassa',
  'rira',
  'routa',
  'salive',
  'sentir',
  'sn',
  'spires',
  'surfas',
  'tasse',
  'rirai',
  'route',
  'salle',
  'sentis',
  'snack',
  'sport',
  'surfe',
  'tasser',
  'riras',
  'routes',
  'salmis',
  'seoir',
  'snob',
  'sports',
  'surfil',
  'tasses',
  'rire',
  'roux',
  'saloir',
  'sept',
  'snobs',
  'spot',
  'surfs',
  'tassez',
  'rirent',
  'royal',
  'salon',
  'sera',
  'so',
  'spots',
  'surgi',
  'taule',
  'rires',
  'royale',
  'salons',
  'serai',
  'sobre',
  'sq',
  'surgie',
  'taupe',
  'rirez',
  'royaux',
  'saloon',
  'seras',
  'sobres',
  'square',
  'surir',
  'taupes',
  'ririez',
  'rp',
  'salua',
  'serez',
  'soc',
  'squaw',
  'surjet',
  'taurin',
  'rirons',
  'rq',
  'salue',
  'serf',
  'social',
  'sr',
  'surs',
  'taux',
  'ris',
  'rr',
  'saluez',
  'serfs',
  'socle',
  'ss',
  'sursis',
  'taxa',
  'riser',
  'rrr',
  'salut',
  'serge',
  'socles',
  'sss',
  'survis',
  'taxant',
  'risqua',
  'rrrr',
  'salve',
  'serin',
  'socs',
  'ssss',
  'survit',
  'taxe',
  'risque',
  'rs',
  'samba',
  'serons',
  'soda',
  'st',
  'survol',
  'taxent',
  'rit',
  'rst',
  'samedi',
  'seront',
  'sodas',
  'stable',
  'sus',
  'taxer',
  'rite',
  'rt',
  'sana',
  'serpe',
  'sodium',
  'stade',
  'sut',
  'taxes',
  'rites',
  'ru',
  'sanas',
  'serra',
  'soeur',
  'stades',
  'sv',
  'taxez',
  'rituel',
  'rua',
  'sang',
  'serre',
  'soeurs',
  'staff',
  'sw',
  'taxi',
  'riva',
  'ruade',
  'sangs',
  'serrer',
  'sofa',
  'stage',
  'swap',
  'taxis',
  'rivage',
  'ruais',
  'sans',
  'sers',
  'sofas',
  'stages',
  'swaps',
  'tb',
  'rivais',
  'ruait',
  'santon',
  'sert',
  'soi',
  'stagna',
  'swing',
  'tc',
  'rival',
  'ruant',
  'saoul',
  'serti',
  'soie',
  'stagne',
  'swings',
  'td',
  'rivale',
  'ruban',
  'saoule',
  'sertie',
  'soient',
  'stand',
  'sx',
  'te',
  'rivant',
  'rubis',
  'sapa',
  'serval',
  'soies',
  'stands',
  'sy',
  'teck',
  'rive',
  'ruche',
  'sapait',
  'serve',
  'soif',
  'star',
  'sympa',
  'teins',
  'river',
  'rude',
  'sapant',
  'servi',
  'soifs',
  'stars',
  'sympas',
  'teint',
  'rives',
  'rudes',
  'sape',
  'servit',
  'soigna',
  'statif',
  'syndic',
  'tek',
  'rivet',
  'rudoie',
  'saper',
  'ses',
  'soigne',
  'statu',
  'synode',
  'teks',
  'rivez',
  'rudoya',
  'sapera',
  'set',
  'soin',
  'statua',
  'syrien',
  'tel',
  'telle',
  'tn',
  'tries',
  'une',
  'vase',
  'vies',
  'voleur',
  'xv',
  'tels',
  'to',
  'triez',
  'unes',
  'vases',
  'vieux',
  'volez',
  'xw',
  'tempe',
  'toast',
  'triiez',
  'uni',
  'vaseux',
  'vif',
  'vols',
  'xx',
  'tempes',
  'toc',
  'trille',
  'unie',
  'vassal',
  'vifs',
  'volt',
  'xxx',
  'tempo',
  'tocs',
  'trio',
  'unies',
  'vaste',
  'vigie',
  'volts',
  'xxxx',
  'tempos',
  'toge',
  'trios',
  'unifia',
  'vastes',
  'vigies',
  'volute',
  'xy',
  'temps',
  'toges',
  'tripe',
  'unifie',
  'vaut',
  'vigile',
  'vomi',
  'xyz',
  'tenais',
  'toi',
  'tris',
  'union',
  'vaux',
  'vigne',
  'vomie',
  'xz',
  'tenait',
  'toile',
  'triste',
  'unique',
  'vb',
  'vil',
  'vomies',
  'y',
  'tend',
  'toiles',
  'troc',
  'unir',
  'vc',
  'vile',
  'vomir',
  'ya',
  'tende',
  'toisa',
  'trocs',
  'unira',
  'vd',
  'viles',
  'vomis',
  'yacht',
  'tendez',
  'toise',
  'trois',
  'unirez',
  've',
  'villa',
  'vomit',
  'yack',
  'tends',
  'toiser',
  'troll',
  'unis',
  'veau',
  'villas',
  'vont',
  'yacks',
  'tendu',
  'toises',
  'trombe',
  'unit',
  'veaux',
  'ville',
  'vos',
  'yak',
  'tendue',
  'toisez',
  'tronc',
  'uns',
  'veine',
  'vils',
  'vota',
  'yaks',
  'teneur',
  'toison',
  'trop',
  'uo',
  'veiner',
  'vin',
  'votais',
  'yard',
  'tenez',
  'toit',
  'trope',
  'up',
  'velu',
  'viner',
  'votant',
  'yards',
  'teniez',
  'toits',
  'troqua',
  'uq',
  'velue',
  'vineux',
  'vote',
  'yb',
  'tenir',
  'tomba',
  'troque',
  'ur',
  'velus',
  'vingt',
  'votent',
  'yc',
  'tenon',
  'tombe',
  'trot',
  'urge',
  'vend',
  'vins',
  'voter',
  'yd',
  'tenons',
  'tombes',
  'trots',
  'urgea',
  'vende',
  'vint',
  'votera',
  'ye',
  'tenta',
  'tome',
  'trotta',
  'urger',
  'vendit',
  'vinyle',
  'votes',
  'yeux',
  'tente',
  'tomes',
  'trou',
  'urine',
  'vendra',
  'viol',
  'votez',
  'yf',
  'tenter',
  'tomme',
  'troua',
  'urne',
  'vendre',
  'viola',
  'votons',
  'yg',
  'tentes',
  'ton',
  'troue',
  'urnes',
  'vends',
  'viole',
  'votre',
  'yh',
  'tentez',
  'tonal',
  'troues',
  'us',
  'vendu',
  'violes',
  'voua',
  'yi',
  'tenu',
  'tonals',
  'trous',
  'usa',
  'veneur',
  'violet',
  'vouais',
  'yj',
  'tenue',
  'tond',
  'trouve',
  'usage',
  'venez',
  'violon',
  'vouait',
  'yk',
  'tenues',
  'tonde',
  'truand',
  'usager',
  'venge',
  'viols',
  'voue',
  'yl',
  'tenus',
  'tondre',
  'truc',
  'usais',
  'vengea',
  'vira',
  'vouent',
  'ym',
  'ter',
  'tonds',
  'trucs',
  'usait',
  'venger',
  'virago',
  'vouer',
  'yn',
  'terme',
  'tondu',
  'truffa',
  'usant',
  'venges',
  'virais',
  'voues',
  'yo',
  'termes',
  'tondue',
  'truie',
  'usante',
  'vengez',
  'viral',
  'vouez',
  'yoga',
  'terne',
  'tonna',
  'truies',
  'usants',
  'venin',
  'virant',
  'vouiez',
  'yp',
  'terni',
  'tonne',
  'truite',
  'use',
  'venir',
  'vire',
  'voulu',
  'yq',
  'ternis',
  'tonner',
  'trust',
  'usent',
  'venons',
  'virer',
  'voulue',
  'yr',
  'ternit',
  'tonnes',
  'truste',
  'user',
  'vent',
  'virera',
  'vous',
  'ys',
  'terra',
  'tons',
  'ts',
  'usera',
  'venta',
  'vires',
  'voyais',
  'yt',
  'terre',
  'tonte',
  'tsar',
  'userai',
  'vente',
  'virez',
  'voyeur',
  'yu',
  'terrer',
  'tonus',
  'tsars',
  'useras',
  'ventru',
  'viriez',
  'voyez',
  'yv',
  'terri',
  'top',
  'tt',
  'userez',
  'vents',
  'viril',
  'voyiez',
  'yw',
  'terril',
  'topaze',
  'ttt',
  'uses',
  'venu',
  'virons',
  'voyons',
  'yx',
  'tertre',
  'toper',
  'tttt',
  'usez',
  'venue',
  'virus',
  'voyou',
  'yy',
  'tes',
  'tops',
  'tu',
  'usiez',
  'venus',
  'vis',
  'vp',
  'yyy',
  'test',
  'toque',
  'tua',
  'usina',
  'ver',
  'visa',
  'vq',
  'yyyy',
  'testa',
  'toquer',
  'tuais',
  'usine',
  'verbe',
  'visage',
  'vr',
  'yz',
  'teste',
  'tord',
  'tuait',
  'usiner',
  'verbes',
  'visais',
  'vrac',
  'z',
  'testes',
  'torde',
  'tuant',
  'usons',
  'verdi',
  'visait',
  'vrai',
  'za',
  'testez',
  'tordre',
  'tuba',
  'usuel',
  'verdir',
  'visant',
  'vraie',
  'zb',
  'tests',
  'tords',
  'tubas',
  'usure',
  'verdit',
  'visas',
  'vrais',
  'zc',
  'texan',
  'tordu',
  'tube',
  'usurpa',
  'verge',
  'vise',
  'vrille',
  'zd',
  'texte',
  'tordus',
  'tuber',
  'ut',
  'verger',
  'viser',
  'vs',
  'ze',
  'textes',
  'torero',
  'tubes',
  'utile',
  'verni',
  'visera',
  'vt',
  'zen',
  'tf',
  'toril',
  'tue',
  'utiles',
  'vernie',
  'vises',
  'vu',
  'zeste',
  'tg',
  'torils',
  'tuent',
  'uu',
  'vernis',
  'visez',
  'vue',
  'zester',
  'th',
  'toron',
  'tuer',
  'uuu',
  'verra',
  'visiez',
  'vues',
  'zestes',
  'thon',
  'torons',
  'tuera',
  'uuuu',
  'verre',
  'vision',
  'vulve',
  'zf',
  'thons',
  'tors',
  'tueras',
  'uv',
  'verres',
  'visita',
  'vus',
  'zg',
  'thuya',
  'torse',
  'tuerez',
  'uvw',
  'verrez',
  'vison',
  'vv',
  'zh',
  'thuyas',
  'torses',
  'tuerie',
  'uw',
  'verrou',
  'visons',
  'vvv',
  'zi',
  'thym',
  'tort',
  'tues',
  'ux',
  'vers',
  'vissa',
  'vvvv',
  'zinc',
  'ti',
  'torts',
  'tueur',
  'uy',
  'versa',
  'visse',
  'vw',
  'zincs',
  'tiare',
  'torve',
  'tueurs',
  'uz',
  'verse',
  'visser',
  'vwx',
  'zipper',
  'tibia',
  'tosser',
  'tueuse',
  'v',
  'verser',
  'vissez',
  'vx',
  'zj',
  'tic',
  'total',
  'tuez',
  'va',
  'verses',
  'visuel',
  'vy',
  'zk',
  'tics',
  'totale',
  'tuf',
  'vacant',
  'versez',
  'vit',
  'vz',
  'zl',
  'tien',
  'totaux',
  'tufs',
  'vache',
  'verso',
  'vitae',
  'w',
  'zm',
  'tienne',
  'totem',
  'tuiez',
  'vagin',
  'versos',
  'vital',
  'wa',
  'zn',
  'tiens',
  'touer',
  'tuile',
  'vagins',
  'vert',
  'vite',
  'wagon',
  'zo',
  'tient',
  'toupet',
  'tuiles',
  'vagir',
  'verte',
  'vitre',
  'waters',
  'zona',
  'tierce',
  'toupie',
  'tulle',
  'vague',
  'vertes',
  'vitrer',
  'watt',
  'zonas',
  'tiers',
  'tour',
  'tuner',
  'vagues',
  'verts',
  'vitres',
  'watts',
  'zone',
  'tige',
  'tours',
  'tuners',
  'vaille',
  'vertu',
  'vivace',
  'wb',
  'zones',
  'tiges',
  'tous',
  'tuons',
  'vain',
  'verve',
  'vivat',
  'wc',
  'zoo',
  'tigre',
  'tout',
  'turban',
  'vainc',
  'vessie',
  'vive',
  'wd',
  'zoom',
  'tigres',
  'toute',
  'turc',
  'vaincs',
  'veste',
  'vivent',
  'we',
  'zooms',
  'tilde',
  'toutes',
  'turcs',
  'vaine',
  'veto',
  'vives',
  'wf',
  'zoos',
  'tildes',
  'toux',
  'turent',
  'vaines',
  'veuf',
  'vivez',
  'wg',
  'zp',
  'tiller',
  'toxine',
  'turf',
  'vains',
  'veufs',
  'vivier',
  'wh',
  'zq',
  'tilt',
  'tp',
  'turfs',
  'vais',
  'veule',
  'viviez',
  'wi',
  'zr',
  'tilts',
  'tq',
  'tus',
  'val',
  'veut',
  'vivote',
  'wj',
  'zs',
  'timbre',
  'tr',
  'tussor',
  'valais',
  'veuve',
  'vivra',
  'wk',
  'zt',
  'timide',
  'trac',
  'tut',
  'valant',
  'veux',
  'vivrai',
  'wl',
  'zu',
  'timon',
  'trace',
  'tuv',
  'valent',
  'vexa',
  'vivras',
  'wm',
  'zv',
  'tint',
  'tract',
  'tuyau',
  'valet',
  'vexais',
  'vivre',
  'wn',
  'zw',
  'tinta',
  'tracta',
  'tuyaux',
  'valeur',
  'vexant',
  'vj',
  'wo',
  'zx',
  'tinte',
  'tracte',
  'tv',
  'valez',
  'vexe',
  'vk',
  'wp',
  'zy',
  'tique',
  'trahi',
  'tw',
  'valgus',
  'vexer',
  'vl',
  'wq',
  'zz',
  'tiques',
  'trahie',
  'tweed',
  'vallon',
  'vexes',
  'vm',
  'wr',
  'zzz',
  'tir',
  'trahir',
  'tweeds',
  'valoir',
  'vexez',
  'vn',
  'ws',
  'zzzz',
  'tira',
  'traie',
  'tx',
  'valons',
  'vexiez',
  'vo',
  'wt',
  'tirade',
  'train',
  'ty',
  'vals',
  'vf',
  'vocal',
  'wu',
  'tirait',
  'trais',
  'type',
  'valsa',
  'vg',
  'vocale',
  'wv',
  'tirant',
  'trait',
  'typer',
  'valse',
  'vh',
  'vodka',
  'ww',
  'tire',
  'traita',
  'types',
  'valser',
  'vi',
  'vodkas',
  'www',
  'tirent',
  'traits',
  'typhon',
  'valsez',
  'via',
  'voeu',
  'wwww',
  'tirer',
  'tram',
  'typo',
  'valu',
  'viable',
  'voeux',
  'wx',
  'tirera',
  'trama',
  'typon',
  'value',
  'viager',
  'vogua',
  'wxy',
  'tires',
  'trame',
  'typons',
  'valus',
  'viande',
  'vogue',
  'wy',
  'tiret',
  'trames',
  'typos',
  'valut',
  'vibra',
  'voguer',
  'wz',
  'tirets',
  'trams',
  'tyran',
  'valve',
  'vibre',
  'vogues',
  'x',
  'tirez',
  'transi',
  'tyrans',
  'vamp',
  'vice',
  'voici',
  'xa',
  'tiroir',
  'trapu',
  'tz',
  'vamper',
  'vices',
  'voie',
  'xb',
  'tirs',
  'traqua',
  'u',
  'vamps',
  'vichy',
  'voies',
  'xc',
  'tisane',
  'trauma',
  'ua',
  'van',
  'vicia',
  'voila',
  'xd',
  'tison',
  'treize',
  'ub',
  'vanne',
  'vicie',
  'voile',
  'xe',
  'tisons',
  'trempe',
  'ubac',
  'vanner',
  'vida',
  'voir',
  'xf',
  'tissa',
  'tresse',
  'ubacs',
  'vans',
  'vidage',
  'voire',
  'xg',
  'tisse',
  'treuil',
  'uc',
  'vanta',
  'vidais',
  'voirie',
  'xh',
  'tisses',
  'tri',
  'ud',
  'vante',
  'vidait',
  'vois',
  'xi',
  'tissez',
  'tria',
  'ue',
  'vanter',
  'vidant',
  'voit',
  'xj',
  'tissu',
  'triade',
  'uf',
  'vantes',
  'vide',
  'voix',
  'xk',
  'titan',
  'triage',
  'ug',
  'vapeur',
  'vider',
  'vol',
  'xl',
  'titane',
  'triais',
  'uh',
  'vaqua',
  'videra',
  'vola',
  'xm',
  'titans',
  'tribu',
  'ui',
  'vaque',
  'vides',
  'volait',
  'xn',
  'titra',
  'tribun',
  'uj',
  'vaquer',
  'videz',
  'vole',
  'xo',
  'titre',
  'triche',
  'uk',
  'varan',
  'vie',
  'volent',
  'xp',
  'titrer',
  'tricot',
  'ukase',
  'varech',
  'vieil',
  'voler',
  'xq',
  'tj',
  'trie',
  'ukases',
  'varia',
  'vielle',
  'volera',
  'xr',
  'tk',
  'trient',
  'ul',
  'varie',
  'vienne',
  'voles',
  'xs',
  'tl',
  'trier',
  'um',
  'varus',
  'viens',
  'volet',
  'xt',
  'tm',
  'triera',
  'un',
  'vas',
  'vient',
  'volets',
  'xu',
];
