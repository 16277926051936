export const dutch = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
  '60',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '69',
  '70',
  '71',
  '72',
  '73',
  '74',
  '75',
  '76',
  '77',
  '78',
  '79',
  '80',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '88',
  '89',
  '90',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '97',
  '98',
  '99',
  '100',
  '101',
  '111',
  '123',
  '200',
  '222',
  '234',
  '300',
  '333',
  '345',
  '400',
  '444',
  '456',
  '500',
  '555',
  '567',
  '600',
  '666',
  '678',
  '700',
  '777',
  '789',
  '800',
  '888',
  '900',
  '999',
  '1000',
  '1111',
  '1234',
  '1492',
  '1500',
  '1600',
  '1700',
  '1800',
  '1900',
  '1910',
  '1920',
  '1925',
  '1930',
  '1935',
  '1940',
  '1945',
  '1950',
  '1955',
  '1960',
  '1965',
  '1970',
  '1975',
  '1980',
  '1985',
  '1990',
  '1991',
  '1992',
  '1993',
  '1994',
  '1995',
  '1996',
  '1997',
  '1998',
  '1999',
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2015',
  '2020',
  '2030',
  '2035',
  '2040',
  '2045',
  '2050',
  '2222',
  '2345',
  '2468',
  '3000',
  '3333',
  '3456',
  '4000',
  '4321',
  '4444',
  '4567',
  '5000',
  '5555',
  '5678',
  '6000',
  '6666',
  '6789',
  '7000',
  '7777',
  '8000',
  '8888',
  '9000',
  '9876',
  '9999',
  '-',
  '--',
  '---',
  '!',
  '!!',
  '!!!',
  '"',
  '#',
  '##',
  '###',
  '$',
  '$$',
  '$$$',
  '%',
  '%%',
  '%%%',
  '&',
  '(',
  '()',
  ')',
  '*',
  '**',
  '***',
  ':',
  ':(',
  ':-(',
  ':)',
  ':-)',
  ';',
  '?',
  '??',
  '???',
  '@',
  '+',
  '++',
  '+++',
  '=',
  '==',
  '===',
  'a',
  'aa',
  'aaa',
  'aaaa',
  'Aagje',
  'aagt',
  'aagten',
  'aai',
  'aaide',
  'aaien',
  'aaitje',
  'aak',
  'aal',
  'aalbes',
  'aalput',
  'aalt',
  'aaltje',
  'aam',
  'aambei',
  'aan',
  'aanbad',
  'aanbod',
  'aaneen',
  'aanhef',
  'aanleg',
  'aanpak',
  'aantal',
  'aanval',
  'aanwas',
  'aanzet',
  'aap',
  'aapje',
  'aapjes',
  'aapte',
  'aar',
  'aard',
  'aardas',
  'aardde',
  'aarde',
  'aarden',
  'aardig',
  'aards',
  'aardse',
  'aars',
  'aarzen',
  'aas',
  'aasde',
  'aasje',
  'aasjes',
  'aaszak',
  'aatje',
  'aatjes',
  'ab',
  'abaci',
  'abacus',
  'abc',
  'abc',
  'abces',
  'abdij',
  'abdis',
  'abeel',
  'abel',
  'abele',
  'abelen',
  'abject',
  'ablaut',
  'abri',
  'abrupt',
  'abscis',
  'absent',
  'absint',
  'absurd',
  'abt',
  'abten',
  'abuis',
  'ac',
  'acacia',
  'acajou',
  'accent',
  'accept',
  'acces',
  'accres',
  'accu',
  "accu's",
  'ace',
  'aceton',
  'ach',
  'acht',
  'achten',
  'achter',
  'achtte',
  'acid',
  'acne',
  'acquit',
  'acre',
  'acres',
  'acryl',
  'act',
  'acteur',
  'actie',
  'actief',
  'actiën',
  'acties',
  'activa',
  'acute',
  'acuter',
  'acuut',
  'ad',
  'adagia',
  'adagio',
  'Adam',
  'adat',
  'adder',
  'adders',
  'addict',
  'adel',
  'adelde',
  'adelen',
  'adem',
  'ademde',
  'ademen',
  'adept',
  'ader',
  'aderde',
  'aderen',
  'adieu',
  'adios',
  'adonis',
  'adres',
  'advent',
  'advies',
  'ae',
  'aequo',
  'aëroob',
  'af',
  'afasie',
  'afbouw',
  'afdak',
  'afdoen',
  'afdruk',
  'affect',
  'affix',
  'affuit',
  'afgaan',
  'afgang',
  'afgod',
  'afijn',
  'afkeer',
  'afkoop',
  'aflaat',
  'afloop',
  'afmars',
  'afname',
  'afpeil',
  'afreis',
  'afrit',
  'afroep',
  'afslag',
  'afstel',
  'aft',
  'aftrap',
  'aftrek',
  'afval',
  'afvoer',
  'afwas',
  'afweer',
  'afzet',
  'afzien',
  'afzijn',
  'ag',
  'agaat',
  'agamie',
  'agaten',
  'agave',
  'agaven',
  'agenda',
  'agens',
  'agent',
  'agente',
  'ageren',
  'agio',
  'agnost',
  'agogie',
  'agonie',
  'agoog',
  'agrafe',
  'ah',
  'aha',
  'ahoi',
  'ahorn',
  'ai',
  'aids',
  'aikido',
  'air',
  'airbag',
  'airbus',
  'airco',
  'airtje',
  'aïs',
  'aïssen',
  'aj',
  'ajour',
  'ajuin',
  'ajuus',
  'ak',
  'akant',
  'a-kant',
  'akela',
  'akelei',
  'akelig',
  'aker',
  'akker',
  'akkers',
  'akolei',
  'aks',
  'aksen',
  'akst',
  'aksten',
  'akte',
  'akten',
  'aktes',
  'al',
  'alaam',
  'alarm',
  'albast',
  'albe',
  'alben',
  'albino',
  'album',
  'albums',
  'aldaar',
  'aldoor',
  'aldra',
  'aldus',
  'aleer',
  'alert',
  'alerte',
  'alfa',
  'algauw',
  'alge',
  'algen',
  'alhier',
  'alias',
  'alibi',
  'alinea',
  'alk',
  'alkali',
  'alken',
  'alkoof',
  'all',
  'Allah',
  'allang',
  'alle',
  'allee',
  'alleen',
  'allen',
  'aller',
  'alles',
  'allo',
  'allooi',
  'allure',
  'almaar',
  'almede',
  'almee',
  'aloë',
  "aloë's",
  'alom',
  'aloud',
  'aloude',
  'alp',
  'alpaca',
  'alpen',
  'alpien',
  'alpine',
  'alpino',
  'alras',
  'alruin',
  'als',
  'alsdan',
  'alsem',
  'alsnog',
  'alsof',
  'alsook',
  'alt',
  'altaar',
  'alten',
  'alter',
  'althea',
  'altijd',
  'altist',
  'altoos',
  'aluin',
  'alvast',
  'alver',
  'alvers',
  'alwaar',
  'alweer',
  'alzo',
  'am',
  'amanda',
  'amant',
  'amaril',
  'amati',
  'amber',
  'ambigu',
  'ambo',
  'ambt',
  'ambten',
  'amen',
  'amfoor',
  'amfora',
  'amice',
  'amices',
  'amict',
  'amigo',
  'amoebe',
  'amok',
  'amorf',
  'amorfe',
  'ampel',
  'ampele',
  'amper',
  'ampère',
  'ampex',
  'ampul',
  'amulet',
  'amuse',
  'an',
  'anaal',
  'anale',
  'ananas',
  'ancien',
  'ander',
  'andere',
  'anders',
  'anemie',
  'angel',
  'angels',
  'angina',
  'angora',
  'angst',
  'anijs',
  'anima',
  'animo',
  'animus',
  'anion',
  'anjer',
  'anjers',
  'ank',
  'anker',
  'ankers',
  'anklet',
  'annex',
  'anno',
  'anode',
  'anoden',
  'anodes',
  'anomie',
  'anorak',
  'ante',
  'anti',
  'antiek',
  'anus',
  'ao',
  'aorta',
  'ap',
  'apache',
  'apart',
  'aparte',
  'aperçu',
  'aperij',
  'apert',
  'aperte',
  'apezat',
  'apin',
  'aplomb',
  'aporie',
  'appel',
  'appèl',
  'appels',
  'april',
  'aprils',
  'apsis',
  'aq',
  'ar',
  'ara',
  'arak',
  'arbeid',
  'arcade',
  'arde',
  'arduin',
  'are',
  'areaal',
  'aren',
  'arena',
  'arend',
  'argon',
  'argot',
  'argots',
  'argus',
  'aria',
  'ariër',
  'ariërs',
  'arisch',
  'ark',
  'arken',
  'arm',
  'armada',
  'arme',
  'armee',
  'armen',
  'armer',
  'armoe',
  'armpje',
  'armvol',
  'Arnhem',
  'aroma',
  'aromen',
  'aroom',
  'arren',
  'arrest',
  'arrivé',
  'ars',
  'arseen',
  'art',
  'arts',
  'artsen',
  'as',
  'asbak',
  'asbelt',
  'asbest',
  'asceet',
  'ascese',
  'Asdag',
  'asem',
  'asemen',
  'asfalt',
  'ashoop',
  'asiel',
  'askar',
  'asla',
  'aslade',
  'aspect',
  'aspic',
  'aspot',
  'asrest',
  'assem',
  'assen',
  'Assen',
  'ast',
  'asten',
  'aster',
  'asters',
  'astma',
  'aston',
  'asurn',
  'at',
  'ataxie',
  'atjar',
  'Atjees',
  'atlant',
  'atlas',
  'atleet',
  'atlete',
  'atol',
  'atomen',
  'atoom',
  'atrium',
  'attaca',
  'attent',
  'attest',
  'au',
  'aubade',
  'auctie',
  'auctor',
  'aucuba',
  'augurk',
  'aula',
  "aula's",
  'aura',
  "aura's",
  'aurora',
  'Aussie',
  'auteur',
  'autist',
  'auto',
  "auto's",
  'auxine',
  'av',
  'aval',
  'avance',
  'avant',
  'avatar',
  'ave',
  'avenue',
  'averij',
  'avis',
  'avond',
  'aw',
  'ax',
  'axel',
  'axiaal',
  'axiale',
  'axioma',
  'ay',
  'az',
  'azalea',
  'azen',
  'Aziaat',
  'azijn',
  'azimut',
  'azteek',
  'azuren',
  'azuur',
  'b',
  'baadde',
  'baadje',
  'baai',
  'baaien',
  'baak',
  'baal',
  'baalde',
  'baan',
  'baande',
  'baar',
  'baard',
  'baarde',
  'baars',
  'baas',
  'baasde',
  'baat',
  'baatte',
  'baba',
  'babbel',
  'babi',
  'baboe',
  'baboes',
  'baby',
  "baby's",
  'bacil',
  'back',
  'backen',
  'bacon',
  'bacove',
  'bad',
  'badcel',
  'baden',
  'bader',
  'baders',
  'badge',
  'badjas',
  'badpak',
  'badtas',
  'bagage',
  'bagger',
  'bagno',
  'bah',
  'bahco',
  'baisse',
  'bajes',
  'bak',
  'baken',
  'bakens',
  'baker',
  'bakers',
  'bakken',
  'bakker',
  'bakkes',
  'bakkie',
  'bakpan',
  'baksel',
  'bakte',
  'bakten',
  'bakvis',
  'bal',
  'balans',
  'balata',
  'balde',
  'balein',
  'balen',
  'balg',
  'balgen',
  'balie',
  'balies',
  'balije',
  'baljuw',
  'balk',
  'balken',
  'balkon',
  'balkte',
  'ballen',
  'ballet',
  'ballon',
  'balpen',
  'balsa',
  'balsem',
  'balts',
  'balzak',
  'bamba',
  'bamboe',
  'bami',
  'Bamis',
  'ban',
  'banaal',
  'banaan',
  'banaat',
  'banale',
  'band',
  'bande',
  'banden',
  'banen',
  'bang',
  'bange',
  'banger',
  'bangig',
  'banier',
  'banjer',
  'banjir',
  'banjo',
  'bank',
  'banken',
  'banket',
  'bankje',
  'bankte',
  'bannen',
  'bantoe',
  'bar',
  'barak',
  'bard',
  'barden',
  'barder',
  'bare',
  'bareel',
  'baren',
  'baret',
  'bariet',
  'baring',
  'barium',
  'bark',
  'barkas',
  'barken',
  'barman',
  'barok',
  'baron',
  'barons',
  'barre',
  'barrel',
  'bars',
  'barse',
  'barser',
  'barst',
  'barzoi',
  'bas',
  'basaal',
  'basale',
  'basalt',
  'base',
  'basen',
  'bases',
  'basis',
  'basket',
  'bassen',
  'basset',
  'bassin',
  'bast',
  'basta',
  'baste',
  'basten',
  'bat',
  'Bataaf',
  'bataat',
  'batch',
  'bate',
  'baten',
  'batig',
  'batik',
  'batiks',
  'batist',
  'baton',
  'batten',
  'baud',
  'bauwde',
  'bauwen',
  'bavet',
  'baxter',
  'bazaar',
  'bazen',
  'bazig',
  'bazige',
  'bazin',
  'bazuin',
  'bb',
  'bbb',
  'bbbb',
  'bc',
  'bcd',
  'bd',
  'be',
  'beaamd',
  'beaat',
  'beamen',
  'beat',
  'beauté ',
  'beauty',
  'beboet',
  'bebop',
  'bebost',
  'bed',
  'bedden',
  'bede',
  'bedekt',
  'beden',
  'bederf',
  'bedild',
  'beding',
  'bedolf',
  'bedong',
  'bedot',
  'bedrag',
  'bedrog',
  'beduid',
  'beefde',
  'beek',
  'beeld',
  'beemd',
  'been',
  'beende',
  'beer',
  'beerde',
  'beërfd',
  'beest',
  'beet',
  'beetje',
  'bef',
  'beffen',
  'begaan',
  'begaf',
  'begijn',
  'begild',
  'begin',
  'beging',
  'begon',
  'begoot',
  'begrip',
  'beha',
  'behang',
  "beha's",
  'beheer',
  'behept',
  'behing',
  'behoed',
  'behoef',
  'behoud',
  'behulp',
  'behuwd',
  'bei',
  'beidde',
  'beide',
  'beiden',
  'beider',
  'beien',
  'Beier',
  'Beiers',
  'beige',
  'beitel',
  'beits',
  'bejoeg',
  'bek',
  'bekaf',
  'bekakt',
  'bekapt',
  'bekeef',
  'bekeek',
  'beken',
  'bekend',
  'beker',
  'bekers',
  'bekijk',
  'bekist',
  'bekken',
  'beklad',
  'beklag',
  'beklom',
  'beknot',
  'bekort',
  'bekte',
  'bekwam',
  'bel',
  'beland',
  'belang',
  'belas',
  'belast',
  'belde',
  'beleed',
  'beleg',
  'belegd',
  'beleid',
  'belet',
  'Belg',
  'Belgen',
  'België',
  'beliep',
  'belle',
  'bellen',
  'bello',
  'beloog',
  'beloop',
  'belt',
  'belten',
  'beluik',
  'belust',
  'bemand',
  'bemest',
  'bemind',
  'bemost',
  'ben',
  'benam',
  'benard',
  'bende',
  'benden',
  'bendes',
  'bene',
  'benen',
  'bengel',
  'benig',
  'benige',
  'benijd',
  'bennen',
  'bent',
  'benul',
  'benut',
  'benzoë',
  'benzol',
  'beo',
  'beogen',
  'beoogd',
  'bepakt',
  'beraad',
  'berber',
  'berd',
  'berde',
  'berden',
  'bereed',
  'bereid',
  'bereik',
  'beren',
  'berg',
  'bergaf',
  'bergen',
  'Bergen',
  'bergop',
  'beried',
  'beriep',
  'beril',
  'berin',
  'berini',
  'berk',
  'berken',
  'berm',
  'bermen',
  'beroep',
  'beroet',
  'berouw',
  'berrie',
  'berst',
  'berust',
  'bes',
  'besef',
  'beseft',
  'besje',
  'besjes',
  'beslag',
  'besmet',
  'bespot',
  'bessen',
  'best',
  'bestal',
  'beste',
  'bestek',
  'bestel',
  'besten',
  'bèta',
  'betast',
  'bete',
  'betel',
  'beten',
  'beter',
  'beton',
  'betond',
  'betoog',
  'betoon',
  'betrad',
  'betrof',
  'betrok',
  'bette',
  'betten',
  'beu',
  'beug',
  'beugel',
  'beugen',
  'beuk',
  'beuken',
  'beuker',
  'beukte',
  'beul',
  'beulde',
  'beulen',
  'beun',
  'beunen',
  'beurde',
  'beuren',
  'beurs',
  'beurse',
  'beurt',
  'beval',
  'bevat',
  'bevel',
  'beven',
  'bever',
  'bevers',
  'beviel',
  'bevind',
  'beving',
  'bevist',
  'bevit',
  'bevoer',
  'bevond',
  'bewees',
  'beweid',
  'bewies',
  'bewijs',
  'bewind',
  'bewoog',
  'bewust',
  'bezaan',
  'bezag',
  'bezat',
  'bezem',
  'bezems',
  'bezet',
  'bezie',
  'bezien',
  'beziën',
  'bezig',
  'bezige',
  'bezit',
  'bezoek',
  'bezon',
  'bezong',
  'bezonk',
  'bezoop',
  'bf',
  'bg',
  'bh',
  'bi',
  'bias',
  'bibs',
  'bic',
  'biceps',
  'biddag',
  'bidden',
  'bidder',
  'bidet',
  'bidets',
  'bidon',
  'bidweg',
  'bieb',
  'biecht',
  'bieden',
  'bieder',
  'biel',
  'biels',
  'bier',
  'bieren',
  'bies',
  'biesde',
  'biest',
  'biet',
  'bieten',
  'biezen',
  'big',
  'bigde',
  'biggen',
  'bigot',
  'bij',
  'bijbal',
  'bijbel',
  'bijeen',
  'bijen',
  'bijl',
  'bijlen',
  'bijles',
  'bijna',
  'bijou',
  'bijous',
  'bijrol',
  'bijt',
  'bijten',
  'bijter',
  'bijtje',
  'bijtte',
  'bijvak',
  'bijval',
  'bijzin',
  'bijzit',
  'bijzon',
  'bik',
  'bikini',
  'bikkel',
  'bikken',
  'bikte',
  'bil',
  'bilde',
  'biljet',
  'billen',
  'bimbam',
  'binair',
  'binden',
  'binder',
  'bingo',
  'bink',
  'binken',
  'binnen',
  'binst',
  'bint',
  'binten',
  'bintje',
  'biobak',
  'biogas',
  'bios',
  'bips',
  'bis',
  'bisdom',
  'bismut',
  'bissen',
  'bisser',
  'bister',
  'bistro',
  'bit',
  'bits',
  'bitse',
  'bitser',
  'bitsig',
  'bitten',
  'bitter',
  'bivak',
  'bizar',
  'bizon',
  'bizons',
  'bj',
  'bk',
  'bl',
  'blaag',
  'blaam',
  'blaar',
  'blaas',
  'blad',
  'bladen',
  'blafte',
  'blagen',
  'blague',
  'blak',
  'blaken',
  'blaker',
  'blakke',
  'blanco',
  'blanda',
  'blank',
  'blanke',
  'blaren',
  'blasé',
  'blaten',
  'blauw',
  'blauwe',
  'blazen',
  'blazer',
  'bleef',
  'bleek',
  'blees',
  'blei',
  'bleien',
  'bleke',
  'bleken',
  'bleker',
  'blekte',
  'blende',
  'blèrde',
  'blèren',
  'bles',
  'bleten',
  'bleu',
  'blezen',
  'bliek',
  'bliep',
  'blies',
  'blij',
  'blijde',
  'blijer',
  'blijf',
  'blijk',
  'blik',
  'blikte',
  'blind',
  'blinde',
  'blink',
  'blits',
  'blo',
  'blode',
  'bloder',
  'bloed',
  'bloei',
  'bloem',
  'bloes',
  'blok',
  'blokte',
  'blom',
  'blond',
  'blonde',
  'bloot',
  'blos',
  'blote',
  'bloter',
  'blouse',
  'blow',
  'blowen',
  'blower',
  'blozen',
  'blues',
  'bluf',
  'blufte',
  'bluste',
  'blut',
  'bluts',
  'bm',
  'bn',
  'bo',
  'boa',
  'board',
  'bob',
  'bobbel',
  'bobben',
  'bobby',
  'bobde',
  'bobijn',
  'bobine',
  'bochel',
  'bocht',
  'bock',
  'bod',
  'bode',
  'bodega',
  'bodem',
  'bodems',
  'boden',
  'bodes',
  'body',
  "body's",
  'boe',
  'boedel',
  'boef',
  'boeg',
  'boegen',
  'boei',
  'boeide',
  'boeien',
  'boeier',
  'boek',
  'boeken',
  'boeket',
  'boekte',
  'boel',
  'boelen',
  'boem',
  'boeman',
  'boemel',
  'boende',
  'boenen',
  'boer',
  'boerde',
  'boeren',
  'boerin',
  'boers',
  'boerse',
  'boert',
  'boes',
  'boete',
  'boeten',
  'boetes',
  'boette',
  'boeven',
  'boezel',
  'boezem',
  'boezen',
  'bof',
  'boffen',
  'boffer',
  'bofte',
  'bogen',
  'bohème',
  'boiler',
  'bojaar',
  'bok',
  'bokaal',
  'bokken',
  'bokkig',
  'boksen',
  'bokser',
  'bokste',
  'bokte',
  'boktor',
  'bol',
  'bolde',
  'bolder',
  'boleet',
  'bolero',
  'bolide',
  'bolk',
  'bolken',
  'bolkop',
  'bolle',
  'bollen',
  'boller',
  'bollig',
  'bolus',
  'bom',
  'bombam',
  'bomde',
  'bomen',
  'bomer',
  'bomers',
  'bomgat',
  'bomijs',
  'bommel',
  'bommen',
  'bomvol',
  'bon',
  'bonbon',
  'bond',
  'bonden',
  'bondig',
  'bonen',
  'bongo',
  'boni',
  'bonje',
  'bonk',
  'bonken',
  'bonket',
  'bonkig',
  'bonkte',
  'bonnen',
  'bonnet',
  'bonobo',
  'bons',
  'bonsai',
  'bonsde',
  'bont',
  'bonte',
  'bonten',
  'bonter',
  'bontje',
  'bonus',
  'bonze',
  'bonzen',
  'boog',
  'boogde',
  'boom',
  'boomde',
  'boon',
  'boor',
  'booras',
  'boord',
  'boorde',
  'boort',
  'boos',
  'boot',
  'bootee',
  'bootte',
  'bopper',
  'boraat',
  'borat',
  'borax',
  'bord',
  'borden',
  'border',
  'bordes',
  'boren',
  'borg',
  'borgde',
  'borgen',
  'boring',
  'borium',
  'borrel',
  'borst',
  'bos',
  'bosbes',
  'bosgod',
  'bosje',
  'bosjes',
  'boskat',
  'boson',
  'bospad',
  'bossen',
  'boste',
  'boston',
  'bosuil',
  'bot',
  'boten',
  'boter',
  'botje',
  'botjes',
  'bots',
  'botsen',
  'botste',
  'botte',
  'bottel',
  'botten',
  'botter',
  'botweg',
  'bouclé',
  'boud',
  'boude',
  'bougie',
  'boules',
  'bout',
  'boute',
  'bouten',
  'bouw',
  'bouwde',
  'bouwen',
  'bouwer',
  'boven',
  'bowl',
  'bowlde',
  'bowlen',
  'bowler',
  'box',
  'boxer',
  'boy',
  'boycot',
  'boze',
  'bozer',
  'bozig',
  'bp',
  'bq',
  'br',
  'braaf',
  'braai',
  'braak',
  'braam',
  'bracht',
  'braden',
  'brak',
  'braken',
  'brakke',
  'bralde',
  'bram',
  'bramen',
  'brand',
  'brandy',
  'branie',
  'bras',
  'brasem',
  'braste',
  'brat',
  'brave',
  'braver',
  'bravo',
  'break',
  'brede',
  'breder',
  'breed',
  'breide',
  'breien',
  'brein',
  'breken',
  'breker',
  'brem',
  'brems',
  'bres',
  'bretel',
  'Breton',
  'breuk',
  'breve',
  'breven',
  'brevet',
  'bridge',
  'brie',
  'brief',
  'bries',
  'brij',
  'brijen',
  'brik',
  'briket',
  'bril',
  'brilde',
  'brille',
  'brink',
  'brio',
  'Brit',
  'Brits',
  'Britse',
  'broche',
  'broden',
  'broed',
  'broeds',
  'broei',
  'broek',
  'broer',
  'broes',
  'brogue',
  'brok',
  'broker',
  'brokte',
  'brom',
  'bromde',
  'bron',
  'brons',
  'bronst',
  'brood',
  'broom',
  'broos',
  'bros',
  'brosse',
  'broze',
  'brozen',
  'brozer',
  'brug',
  'Brugge',
  'brugje',
  'brui',
  'bruid',
  'bruin',
  'bruine',
  'bruis',
  'brulde',
  'brunch',
  'brut',
  'brute',
  'bruten',
  'bruter',
  'bruto',
  'bruusk',
  'bruut',
  'bs',
  'bt',
  'bu',
  'bubbel',
  'buddy',
  'budget',
  'budo',
  'buffel',
  'buffer',
  'buffet',
  'bug',
  'bugel',
  'bugels',
  'buggy',
  'bŸhne',
  'bui',
  'buide',
  'buidel',
  'buien',
  'buigen',
  'buiig',
  'buik',
  'buiken',
  'buikig',
  'buikje',
  'buil',
  'builde',
  'builen',
  'buis',
  'buisde',
  'buit',
  'buiten',
  'buitje',
  'buitte',
  'buizen',
  'bukken',
  'buks',
  'buksen',
  'bukte',
  'bul',
  'buldog',
  'bulk',
  'bulken',
  'bulkte',
  'bullen',
  'bult',
  'bulten',
  'bultig',
  'bultte',
  'bumper',
  'bun',
  'bundel',
  'bunder',
  'bunker',
  'bunnen',
  'bunny',
  'bups',
  'burcht',
  'bureau',
  'bureel',
  'buren',
  'buret',
  'burg',
  'burgen',
  'burger',
  'burijn',
  'burin',
  'burins',
  'bus',
  'bussel',
  'bussen',
  'buste',
  'busten',
  'bustes',
  'butaan',
  'butler',
  'butsen',
  'button',
  'buur',
  'buurde',
  'buurt',
  'buxus',
  'bv',
  'bw',
  'bx',
  'by',
  'bye',
  'bypass',
  'byte',
  'bytes',
  'bz',
  'c',
  'caban',
  'cabans',
  'cabine',
  'cacao',
  'cachet',
  'cachot',
  'cactus',
  'cadans',
  'caddie',
  'cadeau',
  'cadens',
  'cadet',
  'caesar',
  'café',
  'cafés',
  'cahier',
  'cake',
  'cakes',
  'calque',
  'camber',
  'camee',
  'camel',
  'camera',
  'camion',
  'camper',
  'campus',
  'canada',
  'canapé',
  'canard',
  'cancan',
  'canon',
  'canons',
  'canto',
  'cantor',
  'canule',
  'canvas',
  'cape',
  'capes',
  'capita',
  'carbid',
  'carbol',
  'carbon',
  'care',
  'cargo',
  'cariës',
  'carnet',
  'carré',
  'carrés',
  'carter',
  'casco',
  'case',
  'cash',
  'casino',
  'cassis',
  'cast',
  'casten',
  'castte',
  'casus',
  'catgut',
  'causa',
  'cautie',
  'cavia',
  'cb',
  'cc',
  'ccc',
  'cccc',
  'cd',
  'cde',
  'ce',
  'cedel',
  'cedels',
  'ceder',
  'ceders',
  'ceel',
  'cel',
  'celen',
  'cellen',
  'cello',
  'cement',
  'censor',
  'census',
  'cent',
  'centen',
  'center',
  'centra',
  'cerise',
  'cervix',
  'cesium',
  'cessie',
  'cesuur',
  'cetera',
  'cf',
  'cg',
  'ch',
  'chador',
  'chalet',
  'chaoot',
  'chaos',
  'charge',
  'charme',
  'charta',
  'cheeta',
  'chef',
  'chefs',
  'chemie',
  'cheque',
  'cherub',
  'chic',
  'chick',
  'chijl',
  'chili',
  'chintz',
  'chip',
  'chips',
  'chique',
  'chloor',
  'choco',
  'choke',
  'choken',
  'chorus',
  'chroma',
  'chromo',
  'chrono',
  'chroom',
  'ci',
  'cicero',
  'cider',
  'cijfer',
  'cijns',
  'cineac',
  'cinema',
  'cipier',
  'cipres',
  'circa',
  'circus',
  'cirkel',
  'cirrus',
  'cis',
  'cissen',
  'citaat',
  'citer',
  'citers',
  'city',
  'civet',
  'civiel',
  'cj',
  'ck',
  'cl',
  'claim',
  'clan',
  'claque',
  'claris',
  'claus',
  'claves',
  'claxon',
  'clean',
  'cleane',
  'clerus',
  'cliché',
  'click',
  'cliënt',
  'climax',
  'clinch',
  'clip',
  'clivia',
  'cloaca',
  'close',
  'closet',
  'clou',
  'clown',
  'club',
  'cm',
  'cn',
  'co',
  'coach',
  'coaten',
  'cobra',
  'coca',
  'coccus',
  'cocon',
  'cocons',
  'coda',
  'code',
  'codes',
  'codeur',
  'codex',
  'cognac',
  'cohort',
  'coïtus',
  'coke',
  'cokes',
  'col',
  'cola',
  "cola's",
  'colli',
  'collie',
  'collo',
  'colt',
  'column',
  'coma',
  'combi',
  'combo',
  'comité',
  'condor',
  'conga',
  'congé',
  'congés',
  'consul',
  'conté',
  'conti',
  'conto',
  'contra',
  'conus',
  'convex',
  'corgi',
  'corner',
  'cornet',
  'corona',
  'corps',
  'corpus',
  'corso',
  'Cortes',
  'cortex',
  'corvee',
  'coup',
  'coupe',
  'coupé',
  'coupes',
  'coupés',
  'coupon',
  'cour',
  'cover',
  'cowboy',
  'coyote',
  'cp',
  'cq',
  'cr',
  'crack',
  'crank',
  'crash',
  'crawl',
  'crayon',
  'crazy',
  'crèche',
  'credit',
  'credo',
  'crème',
  'crèmes',
  'creool',
  'crêpe',
  'cretin',
  'crew',
  'criant',
  'crime',
  'crises',
  'crisis',
  'cross',
  'cru',
  'cruise',
  'crux',
  'crypte',
  'cs',
  'ct',
  'cu',
  'Cubaan',
  'cue',
  'culten',
  'cultus',
  'cumul',
  'cup',
  'cupido',
  'curare',
  'curie',
  'cursor',
  'cursus',
  'curve',
  'curven',
  'custos',
  'cutter',
  'cv',
  'cw',
  'cx',
  'cy',
  'cyaan',
  'cycli',
  'cyclus',
  'cynici',
  'cypers',
  'cyste',
  'cysten',
  'cz',
  'd',
  'daad',
  'daagde',
  'daags',
  'daagse',
  'daalde',
  'daar',
  'daaraf',
  'daarin',
  'daarna',
  'daarom',
  'daarop',
  'daas',
  'daasde',
  'dabben',
  'dabde',
  'dacht',
  'dada',
  'dadel',
  'dadels',
  'daden',
  'dader',
  'daders',
  'dading',
  'dag',
  'dage',
  'dagen',
  'dager',
  'dagers',
  'dagge',
  'daggen',
  'daghit',
  'daging',
  'dagje',
  'dagjes',
  'daguil',
  'dahlia',
  'daim',
  'dak',
  'daken',
  'dakpan',
  'dal',
  'dalem',
  'dalems',
  'dalen',
  'daling',
  'daluur',
  'dam',
  'damar',
  'damast',
  'damde',
  'dame',
  'dames',
  'dammen',
  'dammer',
  'damp',
  'dampen',
  'dampig',
  'dampte',
  'dan',
  'dandy',
  'danig',
  'danige',
  'dank',
  'danken',
  'dankte',
  'dans',
  'dansen',
  'danser',
  'danste',
  'dapper',
  'dar',
  'darm',
  'darmen',
  'darren',
  'dartel',
  'darts',
  'das',
  'dassen',
  'dat',
  'data',
  'datief',
  'datje',
  'datjes',
  'dato',
  'datsja',
  'datum',
  'datums',
  'dauw',
  'dauwde',
  'dauwen',
  'daver',
  'davit',
  'davits',
  'dazen',
  'db',
  'dc',
  'dd',
  'D-day',
  'ddd',
  'dddd',
  'de',
  'deal',
  'dealen',
  'dealer',
  'debat',
  'debet',
  'debiel',
  'debiet',
  'debuut',
  'decaan',
  'decade',
  'decent',
  'deciel',
  'deck',
  'decor',
  'decors',
  'dédain',
  'deed',
  'deeg',
  'deejay',
  'deel',
  'deelde',
  'deels',
  'Deen',
  'Deens',
  'Deense',
  'deerde',
  'deerne',
  'def',
  'defect',
  'defilé',
  'deftig',
  'degel',
  'degels',
  'degen',
  'degene',
  'degens',
  'degout',
  'deinde',
  'deinen',
  'deïsme',
  'deïst',
  'dek',
  'dekbed',
  'deken',
  'dekens',
  'dekhut',
  'dekken',
  'dekker',
  'deksel',
  'dekte',
  'del',
  'dele',
  'delen',
  'deler',
  'delers',
  'delfde',
  'delfts',
  'delgde',
  'delgen',
  'delict',
  'deling',
  'dellen',
  'delta',
  'delven',
  'dement',
  'demi',
  'demo',
  'demon',
  'demons',
  "demo's",
  'dempen',
  'demper',
  'dempte',
  'Denen',
  'denier',
  'denim',
  'denken',
  'denker',
  'dennen',
  'Deo',
  'depot',
  'depots',
  'deppen',
  'depte',
  'der',
  'derby',
  'derde',
  'derden',
  'derdes',
  'deren',
  'derfde',
  'derny',
  'derrie',
  'dertig',
  'derven',
  'des',
  'desa',
  'desem',
  'desems',
  'design',
  'desk',
  'dessen',
  'dessin',
  'detail',
  'deuce',
  'deugd',
  'deugde',
  'deugen',
  'deuk',
  'deuken',
  'deukte',
  'deun',
  'deunde',
  'deunen',
  'deur',
  'deuren',
  'deuvik',
  'devies',
  'Devoon',
  'devoot',
  'devote',
  'dewijl',
  'deze',
  'dezen',
  'dezer',
  'df',
  'dg',
  'dh',
  'di',
  'dia',
  'diaken',
  'dicht',
  'dichte',
  'dictee',
  'dictie',
  'die',
  'dieet',
  'dief',
  'diefde',
  'dien',
  'diende',
  'dienen',
  'diens',
  'dienst',
  'diep',
  'diepe',
  'diepen',
  'dieper',
  'diepte',
  'dier',
  'dieren',
  'dies',
  'diesel',
  'diëten',
  'Diets',
  'Dietse',
  'dieven',
  'dij',
  'dijde',
  'dijen',
  'dijk',
  'dijken',
  'dijker',
  'dijkte',
  'dijn',
  'dik',
  'dikbil',
  'dikke',
  'dikken',
  'dikker',
  'dikkig',
  'dikkop',
  'dikoor',
  'dikte',
  'dikten',
  'diktes',
  'dikzak',
  'dildo',
  'dille',
  'dillen',
  'dimde',
  'dimmen',
  'dimmer',
  'dimorf',
  'dinar',
  'diner',
  'diners',
  'ding',
  'dingen',
  'dinges',
  'dingo',
  'diode',
  'diodes',
  'dip',
  'dippen',
  'dipte',
  'direct',
  'dirk',
  'dirken',
  'dirkte',
  'dis',
  'disco',
  'discus',
  'disk',
  'dissel',
  'dissen',
  'distel',
  'dit',
  'ditje',
  'ditjes',
  'dito',
  'diva',
  'divan',
  'divans',
  "diva's",
  'divers',
  'dizzy',
  'dj',
  'djati',
  'dk',
  'dl',
  'dm',
  'dn',
  'do',
  'dobber',
  'docent',
  'doch',
  'dociel',
  'doctor',
  'doddig',
  'dode',
  'doden',
  'doding',
  'dodo',
  'doedel',
  'doek',
  'doeken',
  'doekte',
  'doel',
  'doelde',
  'doelen',
  'doem',
  'doemde',
  'doemen',
  'doen',
  'doende',
  'doener',
  'doerak',
  'does',
  'doetje',
  'doezel',
  'doezen',
  'dof',
  'doffe',
  'doffen',
  'doffer',
  'doft',
  'dofte',
  'doften',
  'dog',
  'doge',
  'dogen',
  'doges',
  'doggen',
  'dogger',
  'dogma',
  'dok',
  'doka',
  'doken',
  'dokken',
  'dokte',
  'dokter',
  'dol',
  'dolby',
  'dolde',
  'dolen',
  'doler',
  'dolik',
  'doling',
  'dolk',
  'dolken',
  'dollar',
  'dolle',
  'dollen',
  'doller',
  'dolly',
  'dolman',
  'dolmen',
  'dolven',
  'dom',
  'domein',
  'domen',
  'domino',
  'domkop',
  'domme',
  'dommel',
  'dommer',
  'domoor',
  'dompel',
  'domper',
  'dompig',
  'domweg',
  'don',
  'doña',
  'donaat',
  'donder',
  'dong',
  'donjon',
  'donk',
  'donker',
  'donkey',
  'donna',
  'donor',
  'donors',
  'dons',
  'donsje',
  'donut',
  'donzen',
  'donzig',
  'dood',
  'doodde',
  'doodop',
  'doods',
  'doodse',
  'doof',
  'doofde',
  'dooi',
  'dooide',
  'dooie',
  'dooien',
  'dooier',
  'dook',
  'dool',
  'doolde',
  'doom',
  'doomde',
  'doop',
  'doopte',
  'door',
  'doorn',
  'doos',
  'dop',
  'dope',
  'dopen',
  'doper',
  'dopers',
  'dophei',
  'doping',
  'doppen',
  'dopper',
  'dopte',
  'dor',
  'dorade',
  'dorado',
  'dorde',
  'dorder',
  'doren',
  'dorens',
  'dorp',
  'dorpel',
  'dorpen',
  'dorper',
  'dorps',
  'dorpse',
  'dorre',
  'dorren',
  'dors',
  'dorsen',
  'dorst',
  'dorste',
  'dos',
  'doses',
  'dosis',
  'dossen',
  'doste',
  'dot',
  'dotaal',
  'dotten',
  'dotter',
  'douane',
  'doublé',
  'douche',
  'douw',
  'douwde',
  'douwen',
  'dove',
  'doven',
  'dover',
  'down',
  'dozen',
  'dozijn',
  'dp',
  'dq',
  'dr',
  'dra',
  'draad',
  'draai',
  'draak',
  'drab',
  'dracht',
  'draden',
  'draf',
  'dragee',
  'dragen',
  'drager',
  'dragon',
  'draken',
  'dralen',
  'drama',
  'dramde',
  'drang',
  'drank',
  'dras',
  'drasse',
  'draven',
  'draver',
  'dravik',
  'draw',
  'dreef',
  'dreg',
  'dregde',
  'drek',
  'dreun',
  'drevel',
  'dreven',
  'drie',
  'drieën',
  'dries',
  'driest',
  'drift',
  'dril',
  'drilde',
  'drive',
  'drives',
  'droef',
  'droeg',
  'droes',
  'droeve',
  'droge',
  'drogen',
  'droger',
  'drol',
  'drom',
  'dromde',
  'dromen',
  'dromer',
  'drong',
  'dronk',
  'droog',
  'droom',
  'drop',
  'dropje',
  'drops',
  'dropte',
  'drost',
  'droste',
  'drozen',
  'drug',
  'druïde',
  'druif',
  'druil',
  'druk',
  'drukke',
  'drukte',
  'drum',
  'drumde',
  'drup',
  'drupte',
  'dry',
  'dryade',
  'ds',
  'dt',
  'du',
  'duaal',
  'duale',
  'dualis',
  'dubbel',
  'dubben',
  'dubde',
  'dubio',
  'duel',
  'duels',
  'duet',
  'duf',
  'duffe',
  'duffel',
  'duffer',
  'duidde',
  'duiden',
  'duif',
  'duig',
  'duigen',
  'duik',
  'duiken',
  'duiker',
  'duim',
  'duimde',
  'duimen',
  'duin',
  'duinen',
  'duist',
  'duit',
  'duiten',
  'Duits',
  'Duitse',
  'duivel',
  'duiven',
  'duiver',
  'duivin',
  'dukaat',
  'duldde',
  'dulden',
  'dumdum',
  'dummy',
  'dump',
  'dumpen',
  'dumpte',
  'dun',
  'dunde',
  'dunk',
  'dunken',
  'dunne',
  'dunnen',
  'dunner',
  'dunsel',
  'dunte',
  'duo',
  'dupe',
  'dupes',
  'duplo',
  'dure',
  'duren',
  'durf',
  'durfal',
  'durfde',
  'duro',
  'durven',
  'dus',
  'duster',
  'dusver',
  'dut',
  'dutje',
  'duts',
  'dutsen',
  'dutte',
  'dutten',
  'duur',
  'duurde',
  'duurte',
  'duvel',
  'duvels',
  'duw',
  'duwbak',
  'duwde',
  'duwen',
  'duwtje',
  'dv',
  'dw',
  'dwaal',
  'dwaas',
  'dwalen',
  'dwang',
  'dwars',
  'dwarse',
  'dwaze',
  'dwazen',
  'dwazer',
  'dweil',
  'dwepen',
  'dweper',
  'dwerg',
  'dx',
  'dy',
  'dynamo',
  'dynast',
  'dyne',
  'dynes',
  'dz',
  'e',
  'eau',
  'eb',
  'ebbe',
  'ebben',
  'ebde',
  'ebdeur',
  'ec',
  'ecarté',
  'echec',
  'echel',
  'echo',
  'echode',
  'echoën',
  'echt',
  'echte',
  'echten',
  'echter',
  'echtte',
  'eclair',
  'eclips',
  'ecru',
  'eczeem',
  'eczema',
  'ed',
  'edel',
  'edele',
  'edeler',
  'Eden',
  'edict',
  'edik',
  'editie',
  'edoch',
  'ee',
  'eed',
  'eee',
  'eeee',
  'eega',
  'eegaas',
  "eega's",
  'eek',
  'eelt',
  'eeltig',
  'een',
  'eenarm',
  'eend',
  'eenden',
  'eender',
  'eenoog',
  'eens',
  'eentje',
  'eer',
  'eerde',
  'eerder',
  'eerst',
  'eerste',
  'eervol',
  'eest',
  'eesten',
  'eestte',
  'eeuw',
  'eeuwen',
  'eeuwig',
  'ef',
  'effect',
  'effen',
  'efg',
  'eg',
  'egaal',
  'egale',
  'egaler',
  'egard',
  'egde',
  'egel',
  'egge',
  'eggen',
  'ego',
  'egoïst',
  'eh',
  'ei',
  'eiber',
  'eibers',
  'eicel',
  'eieren',
  'eigen',
  'eik',
  'eikel',
  'eikels',
  'eiken',
  'eilaas',
  'eiland',
  'eind',
  'eindde',
  'einde',
  'einden',
  'einder',
  'eindig',
  'eindje',
  'eins',
  'einze',
  'einzen',
  'eirond',
  'eis',
  'eisen',
  'eiser',
  'eisers',
  'eiste',
  'eitje',
  'eitjes',
  'eivol',
  'eiwit',
  'ej',
  'ek',
  'eken',
  'ekster',
  'el',
  'elan',
  'eland',
  'elders',
  'elegie',
  'elf',
  'elfde',
  'elfden',
  'elfen',
  'elft',
  'elftal',
  'elften',
  'elisie',
  'elite',
  'elixer',
  'elk',
  'elkaar',
  'elke',
  'elkeen',
  'ellen',
  'ellens',
  'ellips',
  'elpee',
  'elpees',
  'elpen',
  'els',
  'elven',
  'elzen',
  'em',
  'email',
  'embryo',
  'emfase',
  'emir',
  'emmer',
  'emmers',
  'emoe',
  'emotie',
  'empire',
  'en',
  'end',
  'enden',
  'ener',
  'enfin',
  'eng',
  'enge',
  'engel',
  'Engels',
  'enger',
  'engerd',
  'engte',
  'engten',
  'engtes',
  'enig',
  'enige',
  'enigma',
  'enigst',
  'enk',
  'enkel',
  'enkele',
  'enkels',
  'enken',
  'enorm',
  'enorme',
  'ent',
  'enten',
  'enter',
  'enters',
  'enting',
  'entree',
  'entte',
  'enzym',
  'eo',
  'Eoceen',
  'eoliet',
  'ep',
  'epiek',
  'epifyt',
  'episch',
  'epoche',
  'epopee',
  'epoque',
  'epos',
  'eq',
  'equipe',
  'er',
  'era',
  'eraan',
  'eraf',
  'erbij',
  'erdoor',
  'ere',
  'erelid',
  'eren',
  'erf',
  'erfde',
  'erfoom',
  'erfwet',
  'erg',
  'erge',
  'ergens',
  'erger',
  'ergo',
  'ergon',
  'erheen',
  'erica',
  'erin',
  'erkend',
  'erker',
  'erkers',
  'ermede',
  'ermee',
  'erna',
  'ernaar',
  'ernst',
  'erom',
  'erop',
  'eropaf',
  'eropin',
  'eropna',
  'Eros',
  'erosie',
  'erover',
  'errata',
  'ersatz',
  'ertoe',
  'erts',
  'ertsen',
  'eruit',
  'ervan',
  'erve',
  'erven',
  'ervoer',
  'ervoor',
  'erwt',
  'erwten',
  'es',
  'escape',
  'escort',
  'escudo',
  'eskimo',
  'esp',
  'espada',
  'espen',
  'esprit',
  'essay',
  'essays',
  'essen',
  'ester',
  'estrik',
  'et',
  'etage',
  'etages',
  'etappe',
  'eten',
  'eter',
  'etheen',
  'ether',
  'ethica',
  'ethiek',
  'ethos',
  'ethyl',
  'etiket',
  'etmaal',
  'ets',
  'etsen',
  'etser',
  'etsers',
  'etste',
  'etter',
  'etude',
  'etudes',
  'etui',
  'eu',
  'eunuch',
  'eureka',
  'euvel',
  'euvele',
  'euvels',
  'euzie',
  'euzies',
  'ev',
  'eva',
  'evacué',
  'evasie',
  'even',
  'evenzo',
  'ever',
  'ew',
  'ex',
  'exact',
  'exacte',
  'examen',
  'exces',
  'excuus',
  'exil',
  'exit',
  'ex-man',
  'exodus',
  'exoot',
  'expert',
  'export',
  'exposé',
  'expres',
  'exquis',
  'extase',
  'extern',
  'extra',
  'ey',
  'ez',
  'ezel',
  'ezelin',
  'f',
  'faal',
  'faalde',
  'faam',
  'faas',
  'fabel',
  'fabels',
  'façade',
  'face',
  'facet',
  'facie',
  'facies',
  'facit',
  'factie',
  'facto',
  'factor',
  'fade',
  'fading',
  'fado',
  'faëton',
  'fagot',
  'fair',
  'faire',
  'fake',
  'faken',
  'fakir',
  'fakirs',
  'fakkel',
  'falanx',
  'falen',
  'falie',
  'falies',
  'fallus',
  'falset',
  'fameus',
  'fan',
  'fanaal',
  'fanaat',
  'farad',
  'farao',
  'farce',
  'farcen',
  'farces',
  'farde',
  'farden',
  'fardes',
  'farm',
  'farmer',
  'faro',
  'fase',
  'fasen',
  'fases',
  'fat',
  'fata',
  'fataal',
  'fatale',
  'fats',
  'fatsen',
  'fatten',
  'fatum',
  'fatwa',
  'faun',
  'fauna',
  'faunen',
  'faveur',
  'fax',
  'faxen',
  'faxte',
  'fazant',
  'fazen',
  'fb',
  'fc',
  'fd',
  'fe',
  'fecaal',
  'feces',
  'fee',
  'feeën',
  'feeks',
  'feest',
  'feetje',
  'feil',
  'feilde',
  'feilen',
  'feit',
  'feiten',
  'fel',
  'fellah',
  'felle',
  'feller',
  'fels',
  'felsen',
  'felste',
  'femel',
  'feniks',
  'fenol',
  'fep',
  'ferm',
  'ferme',
  'fermer',
  'ferry',
  'fes',
  'fessen',
  'feston',
  'feta',
  'fetisj',
  'feut',
  'fez',
  'fezzen',
  'ff',
  'fff',
  'ffff',
  'fg',
  'fgh',
  'fh',
  'fi',
  'fiasco',
  'fiat',
  'fiber',
  'fibril',
  'fibula',
  'fiche',
  'fiches',
  'fichu',
  'fictie',
  'ficus',
  'fideel',
  'fidele',
  'fiedel',
  'fielt',
  'fier',
  'fiere',
  'fiets',
  'figaro',
  'figuur',
  'fijfel',
  'fijn',
  'fijne',
  'fijnen',
  'fijner',
  'fijnte',
  'fijt',
  'fik',
  'fikken',
  'fikkie',
  'fiks',
  'fikse',
  'fiksen',
  'file',
  'files',
  'filet',
  'filets',
  'film',
  'filmde',
  'filmen',
  'filmer',
  'filter',
  'Fin',
  'finaal',
  'finale',
  'fine',
  'fineer',
  'finish',
  'Finnen',
  'Fins',
  'Finse',
  'fint',
  'finten',
  'fiolen',
  'fiool',
  'firma',
  'firn',
  'fis',
  'fiscus',
  'fissen',
  'fistel',
  'fit',
  'fitis',
  'fits',
  'fitte',
  'fitten',
  'fitter',
  'fixeer',
  'fixus',
  'fj',
  'fjord',
  'fk',
  'fl',
  'flacon',
  'flair',
  'flanel',
  'flank',
  'flap',
  'flapte',
  'flard',
  'flash',
  'flat',
  'flater',
  'flatus',
  'flauw',
  'flauwe',
  'fleer',
  'flegma',
  'flemen',
  'flens',
  'fleren',
  'fles',
  'flets',
  'fletse',
  'fleur',
  'flexie',
  'flik',
  'flikje',
  'flikte',
  'flink',
  'flinke',
  'flip',
  'flipte',
  'flirt',
  'flits',
  'floep',
  'floer',
  'floers',
  'floot',
  'flop',
  'floppy',
  'flor',
  'flora',
  'floret',
  'floste',
  'flox',
  'fluïde',
  'fluim',
  'fluit',
  'fluks',
  'fluor',
  'flus',
  'flut',
  'flux',
  'fm',
  'fn',
  'fo',
  'fobie',
  'focus',
  'foefje',
  'foei',
  'foelie',
  'foetus',
  'foezel',
  'föhn',
  'föhnde',
  'föhnen',
  'fok',
  'fokken',
  'fokker',
  'foksia',
  'fokte',
  'fokvee',
  'folder',
  'folie',
  'folies',
  'folio',
  'fond',
  'fonds',
  'fondue',
  'foneem',
  'font',
  'fooi',
  'fooien',
  'foor',
  'fop',
  'foppen',
  'fopte',
  'fora',
  'ford',
  'forel',
  'foren',
  'forens',
  'forint',
  'forma',
  'formol',
  'fors',
  'forse',
  'forser',
  'forsig',
  'fort',
  'forten',
  'forto',
  'forum',
  'forums',
  'fosfor',
  'foto',
  'foton',
  "foto's",
  'fout',
  'foute',
  'fouten',
  'fouter',
  'foutje',
  'fox',
  'foyer',
  'foyers',
  'fp',
  'fq',
  'fr',
  'fraai',
  'frak',
  'frame',
  'frames',
  'franc',
  'franco',
  'franje',
  'frank',
  'Frank',
  'franke',
  'Frans',
  'Franse',
  'frappe',
  'frase',
  'frasen',
  'frases',
  'frater',
  'frats',
  'fraude',
  'freak',
  'frees',
  'fregat',
  'frêle',
  'freon',
  'fresco',
  'fresia',
  'fret',
  'frette',
  'freule',
  'frezen',
  'Fries',
  'Friese',
  'friet',
  'frik',
  'fris',
  'frisco',
  'frisse',
  'friste',
  'frites',
  'frons',
  'front',
  'fruit',
  'fs',
  'ft',
  'ftisis',
  'fu',
  'fuga',
  "fuga's",
  'fuif',
  'fuifde',
  'fuik',
  'fuiken',
  'fuiven',
  'fulp',
  'fulpen',
  'fundi',
  'funest',
  'fungus',
  'funk',
  'funky',
  'furie',
  'furiën',
  'furies',
  'furore',
  'fusee',
  'fusie',
  'fusies',
  'fust',
  'fusten',
  'fut',
  'futen',
  'futiel',
  'fuut',
  'fv',
  'fw',
  'fx',
  'fy',
  'fylum',
  'fysica',
  'fysici',
  'fysiek',
  'fz',
  'g',
  'gaaf',
  'gaai',
  'gaaien',
  'gaal',
  'gaan',
  'gaande',
  'gaap',
  'gaapte',
  'gaar',
  'gaard',
  'gaarde',
  'gaarne',
  'gaas',
  'gaasje',
  'gaatje',
  'gabber',
  'gade',
  'gaden',
  'gadget',
  'gading',
  'gaf',
  'gaffe',
  'gaffel',
  'gag',
  'gaga',
  'gage',
  'gagel',
  'gagels',
  'gages',
  'gaine',
  'gajes',
  'gal',
  'gala',
  'galant',
  'galde',
  'galei',
  'galen',
  'galg',
  'galgen',
  'gallen',
  'gallig',
  'gallon',
  'galm',
  'galmde',
  'galmei',
  'galmen',
  'galmug',
  'galon',
  'galons',
  'galop',
  'galops',
  'gamba',
  'gambir',
  'game',
  'gameet',
  'gamel',
  'games',
  'gamma',
  'gammel',
  'gander',
  'gang',
  'gangen',
  'gannef',
  'gans',
  'ganse',
  'ganser',
  'ganzen',
  'gapen',
  'gaper',
  'gapers',
  'gaping',
  'gappen',
  'gapper',
  'gapte',
  'garage',
  'garant',
  'gard',
  'garde',
  'garden',
  'gardes',
  'gare',
  'gareel',
  'garen',
  'garens',
  'garf',
  'garfde',
  'garoe',
  'garve',
  'garven',
  'gas',
  'gasbek',
  'gasbel',
  'gaslek',
  'gasman',
  'gasnet',
  'gaspit',
  'gassen',
  'gast',
  'gaste',
  'gasten',
  'gat',
  'gaten',
  'gatten',
  'gaucho',
  'gauw',
  'gauwer',
  'gave',
  'gaven',
  'gaver',
  'gay',
  'gazel',
  'gazen',
  'gazet',
  'gazon',
  'gazons',
  'gb',
  'gc',
  'gd',
  'ge',
  'geaaid',
  'geaard',
  'geaasd',
  'geacht',
  'geard',
  'gearmd',
  'gebaad',
  'gebaar',
  'gebaat',
  'gebak',
  'gebakt',
  'gebald',
  'gebalk',
  'gebast',
  'gebed',
  'gebeft',
  'gebeid',
  'gebekt',
  'gebeld',
  'gebet',
  'gebied',
  'gebigd',
  'gebijt',
  'gebikt',
  'gebild',
  'gebint',
  'gebit',
  'geblaf',
  'geblèr',
  'gebluf',
  'gebobd',
  'gebod',
  'geboet',
  'geboft',
  'gebokt',
  'gebold',
  'gebomd',
  'gebood',
  'geboot',
  'gebost',
  'gebot',
  'gebouw',
  'gebral',
  'gebrek',
  'gebrom',
  'gebrul',
  'gebuid',
  'gebukt',
  'gebult',
  'gecast',
  'gedaan',
  'gedaas',
  'gedabd',
  'gedag',
  'gedamd',
  'gedane',
  'gedekt',
  'gedept',
  'gedijd',
  'gedikt',
  'gedimd',
  'geding',
  'gedoe',
  'gedokt',
  'gedold',
  'gedood',
  'gedopt',
  'gedord',
  'gedost',
  'gedrag',
  'gedubd',
  'geduid',
  'geduld',
  'gedund',
  'gedut',
  'geduwd',
  'gedwee',
  'geëbd',
  'geëcht',
  'geëerd',
  'geëest',
  'geef',
  'geëgd',
  'geëind',
  'geëist',
  'geel',
  'geelde',
  'geen',
  'geënt',
  'geep',
  'geer',
  'geerde',
  'geërfd',
  'geest',
  'geëtst',
  'geeuw',
  'gefaxt',
  'gefokt',
  'gefopt',
  'gefret',
  'gegaan',
  'gegald',
  'gegalm',
  'gegapt',
  'gegast',
  'gegeid',
  'gegekt',
  'gegil',
  'gegild',
  'gegist',
  'geglad',
  'gegoed',
  'gegokt',
  'gegomd',
  'gegons',
  'gegord',
  'gegrom',
  'gegrut',
  'gegund',
  'gegymd',
  'gehaat',
  'gehad',
  'gehakt',
  'gehand',
  'gehapt',
  'gehard',
  'geheel',
  'geheet',
  'geheid',
  'geheim',
  'geheld',
  'gehele',
  'gehemd',
  'gehijg',
  'gehikt',
  'gehipt',
  'gehoed',
  'gehokt',
  'gehold',
  'gehoon',
  'gehoor',
  'gehopt',
  'gehord',
  'gehore',
  'gehort',
  'gehost',
  'gehot',
  'gehuil',
  'gehukt',
  'gehuld',
  'gehumd',
  'gehupt',
  'gehuwd',
  'gei',
  'geïaad',
  'geide',
  'geien',
  'geijkt',
  'geijld',
  'geijsd',
  'geil',
  'geile',
  'geiler',
  'gein',
  'geïnd',
  'geinig',
  'geïnkt',
  'geiser',
  'geisha',
  'geit',
  'geiten',
  'gejank',
  'gejast',
  'gejat',
  'gejij',
  'gejijd',
  'gejoel',
  'gejokt',
  'gejold',
  'gejond',
  'gejou',
  'gejukt',
  'gejut',
  'gek',
  'gekaft',
  'gekakt',
  'gekald',
  'gekamd',
  'gekant',
  'gekapt',
  'gekard',
  'gekast',
  'gekat',
  'gekeft',
  'gekend',
  'gekift',
  'gekikt',
  'gekild',
  'gekimd',
  'gekipt',
  'gekird',
  'gekist',
  'gekit',
  'gekke',
  'gekken',
  'gekker',
  'gekkin',
  'gekko',
  'geklad',
  'geklap',
  'geklit',
  'geklop',
  'geknik',
  'geknot',
  'gekold',
  'gekoot',
  'gekopt',
  'gekord',
  'gekort',
  'gekost',
  'gekout',
  'gekte',
  'gekuch',
  'gekuip',
  'gekuld',
  'gekund',
  'gekust',
  'gel',
  'gelaat',
  'gelach',
  'gelag',
  'gelakt',
  'gelal',
  'gelald',
  'geland',
  'gelang',
  'gelapt',
  'gelast',
  'gelat',
  'geld',
  'gelden',
  'geldig',
  'geldla',
  'gele',
  'gelede',
  'gelee',
  'geleed',
  'geleek',
  'gelegd',
  'gelei',
  'geleid',
  'gelekt',
  'geleld',
  'gelen',
  'gelept',
  'geler',
  'gelet',
  'gelid',
  'gelift',
  'gelig',
  'gelige',
  'gelijk',
  'gelikt',
  'gelild',
  'gelipt',
  'gelobd',
  'geloei',
  'gelogd',
  'gelokt',
  'gelold',
  'gelood',
  'geloof',
  'geloop',
  'geloot',
  'gelord',
  'gelost',
  'gelubd',
  'gelui',
  'geluid',
  'geluk',
  'gelukt',
  'gelul',
  'geluld',
  'gelust',
  'geluwd',
  'gemaal',
  'gemaft',
  'gemak',
  'gemald',
  'gemand',
  'gemard',
  'gemast',
  'gemat',
  'gember',
  'gemeen',
  'gemekt',
  'gemeld',
  'gemend',
  'gemene',
  'gemept',
  'gemest',
  'gemier',
  'gemijt',
  'gemikt',
  'gemind',
  'gemis',
  'gemist',
  'gemixt',
  'gemoed',
  'gemok',
  'gemokt',
  'gemold',
  'gemond',
  'gemor',
  'gemord',
  'gemors',
  'gemot',
  'gemout',
  'gems',
  'gemuit',
  'gemunt',
  'gemzen',
  'gen',
  'genade',
  'gênant',
  'genard',
  'genas',
  'genat',
  'gene',
  'gêne',
  'genekt',
  'genen',
  'genept',
  'genera',
  'genese',
  'genet',
  'genie',
  'geniën',
  'geniep',
  'geniet',
  'genikt',
  'genipt',
  'genist',
  'genius',
  'genoeg',
  'genokt',
  'genood',
  'genoom',
  'genoot',
  'genopt',
  'genot',
  'genre',
  'genres',
  'Gent',
  'genten',
  'genus',
  'geoogd',
  'gepaft',
  'gepakt',
  'gepand',
  'gepapt',
  'gepast',
  'gepekt',
  'gepeld',
  'gepen',
  'gepend',
  'gepest',
  'gepiep',
  'gepikt',
  'gepind',
  'gepist',
  'gepit',
  'geplas',
  'geplat',
  'geplet',
  'gepoft',
  'gepokt',
  'gepoot',
  'gepord',
  'gepost',
  'gepot',
  'gepote',
  'gepuft',
  'gepunt',
  'gepust',
  'geput',
  'geraas',
  'geragd',
  'geramd',
  'gerand',
  'gerant',
  'gerat',
  'gered',
  'gerede',
  'gereed',
  'gerei',
  'gereid',
  'gerekt',
  'gereld',
  'geremd',
  'geren',
  'gerend',
  'gerent',
  'gerept',
  'gerest',
  'geribd',
  'gerief',
  'gerij',
  'gerijd',
  'gerild',
  'gering',
  'gerist',
  'gerit',
  'geroep',
  'gerokt',
  'gerold',
  'gerond',
  'geronk',
  'geroot',
  'gerost',
  'gerot',
  'gerst',
  'geruid',
  'geruim',
  'geruis',
  'geruit',
  'gerukt',
  'gerund',
  'gerust',
  'geruwd',
  'ges',
  'gesard',
  'gesast',
  'gesel',
  'gesels',
  'gesimd',
  'gesis',
  'gesist',
  'gesjot',
  'gesmet',
  'gesnap',
  'gesnor',
  'gesold',
  'gesopt',
  'gesp',
  'gespan',
  'gespat',
  'gespen',
  'gespet',
  'gespit',
  'gespot',
  'gespte',
  'gessen',
  'geste',
  'gestel',
  'gestes',
  'gestie',
  'gestut',
  'gesuft',
  'gesust',
  'getakt',
  'getal',
  'getale',
  'getalm',
  'getand',
  'getapt',
  'getart',
  'getast',
  'geteem',
  'geteld',
  'getemd',
  'getest',
  'geteut',
  'getier',
  'getij',
  'getik',
  'getikt',
  'getild',
  'getint',
  'getipt',
  'getob',
  'getobd',
  'getoet',
  'getold',
  'getond',
  'getopt',
  'getost',
  'getouw',
  'getto',
  'getuft',
  'getuid',
  'getuig',
  'getuit',
  'getukt',
  'getypt',
  'geuit',
  'geul',
  'geulen',
  'geur',
  'geurde',
  'geuren',
  'geurig',
  'geärmd',
  'geus',
  'geut',
  'geuten',
  'geuzen',
  'gevaar',
  'gevaat',
  'geval',
  'gevang',
  'gevast',
  'gevat',
  'gevel',
  'geveld',
  'gevels',
  'geven',
  'gevent',
  'gever',
  'gevers',
  'gevest',
  'gevet',
  'geviel',
  'gevild',
  'gevind',
  'gevist',
  'gevit',
  'gevlei',
  'gevlet',
  'gevlij',
  'gevlot',
  'gevoed',
  'gevoeg',
  'gevoel',
  'gevold',
  'gevolg',
  'gevost',
  'gevrij',
  'gevuld',
  'gewaad',
  'gewaar',
  'gewag',
  'gewald',
  'gewamd',
  'gewand',
  'gewant',
  'geward',
  'gewas',
  'gewast',
  'gewed',
  'geween',
  'geweer',
  'gewei',
  'geweid',
  'gewekt',
  'geweld',
  'gewelf',
  'gewend',
  'gewerd',
  'gewest',
  'gewet',
  'gewied',
  'gewijd',
  'gewikt',
  'gewild',
  'gewin',
  'gewipt',
  'gewis',
  'gewist',
  'gewit',
  'gewoed',
  'gewoel',
  'gewon',
  'gewond',
  'gewone',
  'gewoon',
  'gezaag',
  'gezag',
  'gezakt',
  'gezand',
  'gezang',
  'gezant',
  'gezapt',
  'gezegd',
  'gezeik',
  'gezel',
  'gezet',
  'gezeul',
  'gezeur',
  'gezien',
  'gezift',
  'gezin',
  'gezind',
  'gezoem',
  'gezoet',
  'gezond',
  'gezwam',
  'gezwel',
  'gf',
  'gg',
  'ggg',
  'gggg',
  'gh',
  'ghi',
  'gi',
  'gibbon',
  'gibus',
  'gids',
  'gidsen',
  'giebel',
  'giek',
  'gieken',
  'gier',
  'gierde',
  'gieren',
  'gierig',
  'gierst',
  'gieten',
  'gieter',
  'gif',
  'giffen',
  'gifgas',
  'gift',
  'giften',
  'giftig',
  'gig',
  'gigant',
  'giggen',
  'gigolo',
  'gigue',
  'gij',
  'gijl',
  'gijn',
  'gijnen',
  'gijpen',
  'gijpte',
  'gil',
  'gild',
  'gilde',
  'gilden',
  'gilet',
  'gilets',
  'gillen',
  'gin',
  'ginder',
  'ginds',
  'gindse',
  'ging',
  'ginkgo',
  'gips',
  'gipsen',
  'gipste',
  'gipsy',
  'giraal',
  'giraf',
  'girale',
  'girant',
  'giro',
  "giro's",
  'gis',
  'gispen',
  'gispte',
  'gissen',
  'gist',
  'giste',
  'gisten',
  'gistte',
  'git',
  'gitaar',
  'gitten',
  'gj',
  'gk',
  'gl',
  'glacé',
  'glacés',
  'glacis',
  'glad',
  'gladaf',
  'gladde',
  'glans',
  'glas',
  'glazen',
  'glazig',
  'glee',
  'gleed',
  'gleeën',
  'gleis',
  'gleuf',
  'glimp',
  'glip',
  'glipte',
  'glit',
  'globe',
  'globes',
  'gloed',
  'gloor',
  'gloren',
  'gloria',
  'glorie',
  'glosse',
  'glossy',
  'glui',
  'gluien',
  'gluren',
  'gluten',
  'gluton',
  'glycol',
  'gm',
  'gn',
  'gneis',
  'gnoe',
  'gnomen',
  'gnomon',
  'gnoom',
  'gnosis',
  'go',
  'goal',
  'goalie',
  'gocart',
  'God',
  'goden',
  'godin',
  'godlof',
  'goed',
  'goede',
  'goeden',
  'goedig',
  'goedje',
  'goeiig',
  'goeman',
  'goeroe',
  'gojim',
  'gojims',
  'gojs',
  'gok',
  'gokhal',
  'gokken',
  'gokker',
  'gokte',
  'golem',
  'golems',
  'golf',
  'golfde',
  'golfen',
  'golven',
  'gom',
  'gombal',
  'gomde',
  'gommen',
  'gompie',
  'gonade',
  'gondel',
  'gong',
  'gons',
  'gonsde',
  'gonzen',
  'goog',
  'gooi',
  'gooide',
  'gooien',
  'goor',
  'goot',
  'gordde',
  'gordel',
  'gorden',
  'gore',
  'gorgel',
  'gorig',
  'gorige',
  'gors',
  'gort',
  'gorten',
  'gorter',
  'gortig',
  'gorzen',
  'gospel',
  'gossip',
  'goten',
  'gotiek',
  'gotspe',
  'goud',
  'gouden',
  'gouw',
  'gouwe',
  'gouwen',
  'gozer',
  'gozers',
  'gp',
  'gq',
  'gr',
  'graad',
  'graaf',
  'graag',
  'graai',
  'graal',
  'graan',
  'graat',
  'gracht',
  'graden',
  'graeci',
  'graf',
  'grafie',
  'grage',
  'grager',
  'gram',
  'gramme',
  'grand',
  'grande',
  'grands',
  'granen',
  'grap',
  'gras',
  'graten',
  'gratie',
  'gratig',
  'gratis',
  'grauw',
  'grauwe',
  'gravel',
  'graven',
  'graver',
  'gravin',
  'grazen',
  'grazig',
  'grebbe',
  'green',
  'greep',
  'grein',
  'grenen',
  'grens',
  'grepen',
  'gres',
  'gretig',
  'gribus',
  'grief',
  'Griek',
  'Grieks',
  'griel',
  'griend',
  'griep',
  'gries',
  'griet',
  'grieve',
  'grif',
  'griffe',
  'grift',
  'grifte',
  'grijns',
  'grijp',
  'grijs',
  'grijze',
  'gril',
  'grilde',
  'grill',
  'grille',
  'grim',
  'grimas',
  'grimde',
  'grime',
  'grimes',
  'grind',
  'grip',
  'grise',
  'grises',
  'griste',
  'grit',
  'groef',
  'groei',
  'groen',
  'groene',
  'groep',
  'groet',
  'groeve',
  'grof',
  'grofte',
  'grog',
  'groggy',
  'grol',
  'grolde',
  'grom',
  'gromde',
  'grond',
  'gronde',
  'groot',
  'groots',
  'gros',
  'grosse',
  'grosso',
  'grot',
  'grote',
  'groten',
  'groter',
  'grove',
  'grover',
  'gruis',
  'grunge',
  'grut',
  'grutte',
  'grutto',
  'gruwde',
  'gruwel',
  'gruwen',
  'gs',
  'gt',
  'gu',
  'guano',
  'guit',
  'guiten',
  'guitig',
  'gul',
  'gulden',
  'gulle',
  'gullen',
  'guller',
  'gulp',
  'gulpen',
  'gulpte',
  'gulzig',
  'gum',
  'gumde',
  'gummen',
  'gummi',
  'gunde',
  'gunnen',
  'gunst',
  'gup',
  'guppy',
  'gure',
  'gust',
  'guts',
  'gutsen',
  'gutste',
  'guur',
  'gv',
  'gw',
  'gx',
  'gy',
  'gym',
  'gymde',
  'gymmen',
  'gymp',
  'gympie',
  'gz',
  'h',
  'haaf',
  'haagde',
  'haai',
  'haaide',
  'haaien',
  'haaiig',
  'haak',
  'haaks',
  'haakse',
  'haakte',
  'haal',
  'haalde',
  'haam',
  'haan',
  'haar',
  'haard',
  'haarde',
  'haas',
  'haast',
  'haat',
  'haatte',
  'habijt',
  'hachee',
  'hachje',
  'hacken',
  'hadith',
  'hadji',
  'hadzji',
  'haf',
  'haffen',
  'haft',
  'haften',
  'hagel',
  'hagen',
  'haiku',
  'hak',
  'haken',
  'hakken',
  'hakker',
  'hakmes',
  'haksel',
  'hakte',
  'hal',
  'halen',
  'half',
  'halfje',
  'hall',
  'hallen',
  'hallo',
  'halm',
  'halma',
  'halmen',
  'halo',
  'hals',
  'halsde',
  'halt',
  'halte',
  'halten',
  'halter',
  'haltes',
  'halve',
  'halzen',
  'ham',
  'hamei',
  'hamel',
  'hamels',
  'hamen',
  'hamer',
  'hamers',
  'hamlap',
  'hammen',
  'hand',
  'handde',
  'handel',
  'handen',
  'handig',
  'handje',
  'hanen',
  'hang',
  'hangar',
  'hangel',
  'hangen',
  'hanger',
  'hangop',
  'hanig',
  'hanige',
  'hannes',
  'hans',
  'hansop',
  'Hanze',
  'hanzen',
  'hap',
  'hapax',
  'hapje',
  'hapjes',
  'happen',
  'happig',
  'happy',
  'hapte',
  'har',
  'hard',
  'hardde',
  'harde',
  'harden',
  'harder',
  'hardop',
  'harem',
  'harems',
  'haren',
  'harent',
  'harig',
  'harige',
  'haring',
  'hark',
  'harken',
  'harkte',
  'harnas',
  'harp',
  'harpen',
  'harpij',
  'harpte',
  'harre',
  'harrel',
  'harren',
  'hars',
  'harsen',
  'harsig',
  'harst',
  'hart',
  'harte',
  'harten',
  'hartig',
  'hartje',
  'hasj',
  'haspel',
  'haten',
  'hater',
  'haters',
  'hausse',
  'haute',
  'hauw',
  'hauwen',
  'have',
  'haven',
  'havens',
  'haver',
  'havik',
  'havo',
  'hazard',
  'hazen',
  'hb',
  'hc',
  'hd',
  'he',
  'heat',
  'heavy',
  'heb',
  'hebben',
  'hebbes',
  'hecht',
  'hechte',
  'heden',
  'heef',
  'heeft',
  'heel',
  'heelal',
  'heelde',
  'heem',
  'heemst',
  'heen',
  'heep',
  'heer',
  'hees',
  'heet',
  'heette',
  'hef',
  'heffe',
  'heffen',
  'heft',
  'heften',
  'heftig',
  'heg',
  'hegge',
  'heggen',
  'hegje',
  'hei',
  'heibel',
  'heide',
  'heiden',
  'heien',
  'heiig',
  'heiige',
  'heikel',
  'heil',
  'heilig',
  'Hein',
  'heinde',
  'heisa',
  'heitje',
  'hek',
  'hekel',
  'hekels',
  'hekken',
  'heks',
  'heksen',
  'hekste',
  'hel',
  'hela',
  'helaas',
  'held',
  'helde',
  'helden',
  'helder',
  'heldin',
  'hele',
  'helen',
  'heler',
  'helers',
  'helft',
  'heli',
  'heling',
  'helium',
  'helix',
  'helle',
  'hellen',
  'heller',
  'helm',
  'helmen',
  'heloot',
  'helpen',
  'helper',
  'helpt',
  'hels',
  'helse',
  'hem',
  'he-man',
  'hemd',
  'hemde',
  'hemden',
  'hemel',
  'hemels',
  'hemen',
  'hemmen',
  'hen',
  'hendel',
  'henen',
  'heng',
  'hengel',
  'hengen',
  'hengst',
  'henna',
  'hennen',
  'hennep',
  'hens',
  'hepen',
  'her',
  'heraut',
  'herder',
  'heren',
  'herfst',
  'hergaf',
  'herig',
  'herik',
  'herlas',
  'hernam',
  'hernia',
  'heroën',
  'heros',
  'herpes',
  'herren',
  'herrie',
  'hert',
  'herten',
  'hertog',
  'hertz',
  'hervat',
  'herwon',
  'herzag',
  'hes',
  'hese',
  'heser',
  'hesp',
  'hespen',
  'hessen',
  'het',
  'hete',
  'heten',
  'heter',
  'hetero',
  'hetze',
  'hetzij',
  'heug',
  'heugde',
  'heugel',
  'heugen',
  'heul',
  'heulde',
  'heulen',
  'heup',
  'heupen',
  'heus',
  'heuse',
  'heuser',
  'heuvel',
  'hevea',
  'hevel',
  'hevels',
  'hevig',
  'hevige',
  'hf',
  'hg',
  'hh',
  'hhh',
  'hhhh',
  'hi',
  'hiaat',
  'hiaten',
  'hiel',
  'hield',
  'hielde',
  'hielen',
  'hielp',
  'hier',
  'hieraf',
  'hierin',
  'hierna',
  'hierom',
  'hierop',
  'hifi',
  'high',
  'hij',
  'hij',
  'hijgde',
  'hijgen',
  'hijger',
  'hijs',
  'hijsen',
  'hik',
  'hikken',
  'hikte',
  'hinde',
  'hinden',
  'hinder',
  'Hindoe',
  'hing',
  'hinken',
  'hinkte',
  'hint',
  'hinten',
  'hip',
  'hiphop',
  'hippe',
  'hippen',
  'hippie',
  'hipte',
  'hit',
  'hitsig',
  'hitte',
  'hitten',
  'hj',
  'hk',
  'hl',
  'hm',
  'hn',
  'ho',
  'hobbel',
  'hobby',
  'hobo',
  'hoc',
  'hockey',
  'hoe',
  'hoed',
  'hoedde',
  'hoede',
  'hoeden',
  'hoeder',
  'hoef',
  'hoefde',
  'hoek',
  'hoeken',
  'hoeker',
  'hoekig',
  'hoeks',
  'hoekse',
  'hoekte',
  'hoempa',
  'hoen',
  'hoep',
  'hoepel',
  'hoepen',
  'hoepla',
  'hoer',
  'hoera',
  'hoerde',
  'hoeren',
  'hoerig',
  'hoes',
  'hoest',
  'hoeve',
  'hoeven',
  'hoever',
  'hoewel',
  'hoezee',
  'hoezen',
  'hoezo',
  'hof',
  'hofje',
  'hofjes',
  'hofnar',
  'hoge',
  'hogen',
  'hoger',
  'hoging',
  'hoi',
  'hok',
  'hokken',
  'hokte',
  'hol',
  'hola',
  'holde',
  'hole',
  'holen',
  'holist',
  'holle',
  'hollen',
  'holler',
  'holte',
  'holten',
  'holtes',
  'hom',
  'home',
  'hommel',
  'hommen',
  'hommer',
  'homo',
  "homo's",
  'homp',
  'hompen',
  'hond',
  'honden',
  'honds',
  'hondse',
  'honen',
  'honend',
  'honger',
  'honig',
  'honing',
  'honk',
  'honken',
  'honkte',
  'hoofd',
  'hoofde',
  'hoofs',
  'hoofse',
  'hoog',
  'hoogde',
  'hoogst',
  'hoogte',
  'hooi',
  'hooide',
  'hooien',
  'hooier',
  'hooked',
  'hoon',
  'hoonde',
  'hoop',
  'hoopte',
  'hoor',
  'hoorde',
  'hoorn',
  'hoos',
  'hoosde',
  'hop',
  'hope',
  'hopen',
  'hopla',
  'hopman',
  'hoppe',
  'hoppen',
  'hopper',
  'hopsa',
  'hopte',
  'hor',
  'hordde',
  'horde',
  'horden',
  'hordes',
  'horeca',
  'horen',
  'horens',
  'horig',
  'horige',
  'hork',
  'horken',
  'hormon',
  'horren',
  'horror',
  'hors',
  'horsen',
  'horst',
  'hort',
  'horten',
  'hortte',
  'hortus',
  'horzel',
  'horzen',
  'hospes',
  'hospik',
  'hossen',
  'hoste',
  'hostel',
  'hostie',
  'hot',
  'hotdog',
  'hotel',
  'hotels',
  'hotsen',
  'hotste',
  'hotte',
  'hotten',
  'hou',
  'houden',
  'houder',
  'house',
  'housen',
  'hout',
  'houten',
  'houtig',
  'houtje',
  'houw',
  'houwen',
  'houwer',
  'hoven',
  'hoving',
  'hozen',
  'hp',
  'hq',
  'hr',
  'hs',
  'ht',
  'hu',
  'hui',
  'huid',
  'huiden',
  'huidig',
  'huif',
  'huifde',
  'huig',
  'huigen',
  'huik',
  'huiken',
  'huilde',
  'huilen',
  'huiler',
  'huis',
  'huisde',
  'huisje',
  'huiven',
  'huize',
  'huizen',
  'hukken',
  'hukte',
  'hul',
  'hulde',
  'hulk',
  'hulken',
  'hullen',
  'hulp',
  'hulpen',
  'huls',
  'hulsel',
  'hulst',
  'hulzen',
  'hum',
  'humaan',
  'humane',
  'humbug',
  'humde',
  'humeur',
  'hummel',
  'hummen',
  'humor',
  'humus',
  'hun',
  'hunne',
  'hunnen',
  'hup',
  'huppen',
  'hups',
  'hupse',
  'hupte',
  'huren',
  'hurken',
  'hurkte',
  'husky',
  'hut',
  'hutje',
  'hutten',
  'huur',
  'huurde',
  'huwde',
  'huwen',
  'huzaar',
  'hv',
  'hw',
  'hx',
  'hy',
  'hybris',
  'hydra',
  'hyena',
  'hymen',
  'hymens',
  'hymne',
  'hymnen',
  'hype',
  'hypo',
  'hysop',
  'hz',
  'i',
  'iade',
  'iaën',
  'ib',
  'ibidem',
  'ibis',
  'ic',
  'icing',
  'iconen',
  'icoon',
  'id',
  'ideaal',
  'ideale',
  'idee',
  'ideëel',
  'ideeën',
  'ideële',
  'idem',
  'idioom',
  'idioot',
  'idiote',
  'idolen',
  'idool',
  'idylle',
  'ie',
  'iebel',
  'ieder',
  'iedere',
  'iel',
  'iele',
  'iemand',
  'iemker',
  'iep',
  'iepen',
  'Ier',
  'Ieren',
  'Iers',
  'Ierse',
  'iet',
  'iets',
  'ietsje',
  'ietwat',
  'if',
  'ig',
  'iglo',
  'i-grec',
  'ih',
  'ii',
  'iii',
  'iiii',
  'ij',
  'ijdel',
  'ijdele',
  'ijk',
  'ijk',
  'ijken',
  'ijker',
  'ijkers',
  'ijking',
  'ijkte',
  'ijl',
  'ijlde',
  'ijle',
  'ijlen',
  'ijler',
  'ijs',
  'ijsco',
  'ijsde',
  'ijsje',
  'ijsjes',
  'ijskap',
  'ijszak',
  'ijszee',
  'ijver',
  'ijzel',
  'ijzen',
  'ijzer',
  'ijzers',
  'ijzig',
  'ijzige',
  'ik',
  'ikzelf',
  'il',
  'Ilias',
  'im',
  'image',
  'imago',
  'imam',
  'imker',
  'imkers',
  'immens',
  'immer',
  'immers',
  'immune',
  'immuun',
  'impact',
  'impala',
  'import',
  'impost',
  'impuls',
  'in',
  'inbaar',
  'inbare',
  'inca',
  'incest',
  'inch',
  'inches',
  'inde',
  'index',
  'indien',
  'Indiër',
  'indigo',
  'Indo',
  'indoen',
  "Indo's",
  'indruk',
  'indult',
  'ineen',
  'ineens',
  'inert',
  'inerte',
  'infaam',
  'infame',
  'infant',
  'info',
  'infuus',
  'ingaan',
  'ingang',
  'ingoed',
  'ingooi',
  'inham',
  'inhoud',
  'inkeep',
  'inkeer',
  'inkijk',
  'inkom',
  'inkoop',
  'inkoud',
  'inkt',
  'inkten',
  'inktte',
  'inlaag',
  'inlaat',
  'inlage',
  'inlas',
  'inlaut',
  'inleg',
  'inloop',
  'inmaak',
  'inname',
  'innen',
  'innig',
  'innige',
  'inning',
  'input',
  'inrit',
  'inruil',
  'insect',
  'inslag',
  'insult',
  'intact',
  'intake',
  'intens',
  'intern',
  'intiem',
  'intimi',
  'intree',
  'intrek',
  'intro',
  'intron',
  'inval',
  'invers',
  'invert',
  'invite',
  'invité',
  'invoer',
  'inworp',
  'inzage',
  'inzake',
  'inzet',
  'inzien',
  'io',
  'ion',
  'ionen',
  'ip',
  'iq',
  'ir',
  'iris',
  'ironie',
  'is',
  'islam',
  'isme',
  'ismen',
  'ismes',
  'issue',
  'issues',
  'it',
  'item',
  'iu',
  'iv',
  'ivoor',
  'ivoren',
  'Ivriet',
  'iw',
  'ix',
  'iy',
  'iz',
  'j',
  'jaagde',
  'jaap',
  'jaapte',
  'jaar',
  'jabot',
  'jabots',
  'jacht',
  'jack',
  'jacket',
  'jade',
  'jaeger',
  'jagen',
  'jager',
  'jagers',
  'jaguar',
  'Jahwe',
  'Jahweh',
  'jajem',
  'jak',
  'jakken',
  'jakkes',
  'jam',
  'jambe',
  'jamben',
  'jammen',
  'jammer',
  'jampot',
  'jan',
  'janhen',
  'janken',
  'jankte',
  'jannen',
  'jantje',
  'janus',
  'Jap',
  'Japans',
  'japen',
  'japon',
  'Jappen',
  'jaren',
  'jargon',
  'jarig',
  'jarige',
  'jas',
  'jaspis',
  'jassen',
  'jaste',
  'jaszak',
  'jat',
  'jatte',
  'jatten',
  'Javaan',
  'jawel',
  'jazz',
  'jazzy',
  'jb',
  'jc',
  'jd',
  'je',
  'jeans',
  'jeep',
  'jegens',
  'Jehova',
  'jein',
  'jekker',
  'jelui',
  'jengel',
  'jennen',
  'jersey',
  'jet',
  'jetlag',
  'jetset',
  'jetski',
  'jeu',
  'jeugd',
  'jeuïg',
  'jeuk',
  'jeuken',
  'jeukte',
  'jezelf',
  'jf',
  'jg',
  'jh',
  'ji',
  'jicht',
  'jij',
  'jijde',
  'jijen',
  'jingle',
  'jive',
  'jj',
  'jjj',
  'jjjj',
  'jk',
  'jkl',
  'jl',
  'jm',
  'jn',
  'jo',
  'Job',
  'jochie',
  'jockey',
  'joden',
  'jodide',
  'jodin',
  'jodium',
  'joeg',
  'joekel',
  'joelde',
  'joelen',
  'joep',
  'joetje',
  'jofel',
  'joggen',
  'jogger',
  'joh',
  'joint',
  'jojo',
  'jojoën',
  'jok',
  'joke',
  'joker',
  'jokers',
  'jokken',
  'jokte',
  'jol',
  'jolde',
  'jolen',
  'jolig',
  'jolige',
  'jolijt',
  'jollen',
  'Jonas',
  'jonde',
  'jonen',
  'jong',
  'jongde',
  'jonge',
  'jongen',
  'jonger',
  'jongs',
  'jonk',
  'jonken',
  'jonker',
  'jonnen',
  'jood',
  'joods',
  'joodse',
  'jool',
  'joolde',
  'joon',
  'joop',
  'Joost',
  'jopen',
  'jota',
  'jou',
  'joule',
  'jour',
  'jouw',
  'jouwde',
  'jouwen',
  'joyeus',
  'jozef',
  'jp',
  'jq',
  'jr',
  'js',
  'jt',
  'ju',
  'jubel',
  'judas',
  'judo',
  'judoën',
  'judoka',
  'juf',
  'juffen',
  'juffer',
  'juffie',
  'juist',
  'juiste',
  'jujube',
  'juk',
  'jukken',
  'jukte',
  'juli',
  'jullie',
  'jumbo',
  'jumper',
  'jungle',
  'juni',
  'junior',
  'junk',
  'junkie',
  'junta',
  'Jura',
  'jurist',
  'jurk',
  'jurken',
  'jury',
  "jury's",
  'jus',
  'juskom',
  'jut',
  'jute',
  'juten',
  'jutte',
  'jutten',
  'jutter',
  'juweel',
  'jv',
  'jw',
  'jx',
  'jy',
  'jz',
  'k',
  'kaag',
  'kaai',
  'kaaide',
  'kaaien',
  'kaak',
  'kaakje',
  'kaakte',
  'kaal',
  'kaalte',
  'kaam',
  'kaamde',
  'kaan',
  'kaap',
  'kaapte',
  'kaar',
  'kaard',
  'kaarde',
  'kaars',
  'kaart',
  'kaas',
  'kaasde',
  'kaats',
  'kabaai',
  'kabaal',
  'kabaja',
  'kabas',
  'kabel',
  'kabels',
  'kachel',
  'kade',
  'kadee',
  'kadees',
  'kaden',
  'kader',
  'kaders',
  'kadi',
  'kaduke',
  'kaduuk',
  'kaf',
  'kaffa',
  'kaffer',
  'kaft',
  'kaftan',
  'kaften',
  'kaftte',
  'kafzak',
  'kagen',
  'kaön',
  'kaöns',
  'kajak',
  'kajaks',
  'kajuit',
  'kak',
  'kakel',
  'kakels',
  'kaken',
  'kaki',
  'kakken',
  'kakte',
  'kalbas',
  'kalde',
  'kale',
  'kalen',
  'kaler',
  'kalf',
  'kalfde',
  'kalfje',
  'kali',
  'kalief',
  "kali's",
  'kalium',
  'kalk',
  'kalkei',
  'kalken',
  'kalkte',
  'kalle',
  'kallen',
  'kalm',
  'kalme',
  'kalmer',
  'kalmte',
  'kalot',
  'kalven',
  'kam',
  'kamde',
  'kameel',
  'kamen',
  'kamer',
  'kamers',
  'kamfer',
  'kamig',
  'kammen',
  'kamp',
  'kampen',
  'kamper',
  'kampte',
  'kamrad',
  'kan',
  'kanaal',
  'kanaat',
  'kandij',
  'kaneel',
  'kanen',
  'kanis',
  'kanjer',
  'kanji',
  'kanker',
  'kannen',
  'kano',
  'kanode',
  'kanoën',
  'kanoër',
  'kanoet',
  'kanon',
  'kans',
  'kansel',
  'kansen',
  'kant',
  'kanten',
  'kantig',
  'kantje',
  'kanton',
  'kantte',
  'kap',
  'kapel',
  'kapen',
  'kaper',
  'kapers',
  'kapje',
  'kapjes',
  'kapmes',
  'kapoen',
  'kapok',
  'kapot',
  'kappa',
  'kappen',
  'kapper',
  'kapsel',
  'kapte',
  'kar',
  'karaat',
  'karaf',
  'karate',
  'karde',
  'karen',
  'karet',
  'karig',
  'karige',
  'karkas',
  'karma',
  'karn',
  'karnde',
  'karnen',
  'karos',
  'karot',
  'karper',
  'karpet',
  'karren',
  'kartel',
  'karton',
  'karwei',
  'karwij',
  'kas',
  'kasba',
  'kashba',
  'kassa',
  'kassei',
  'kassen',
  'kast',
  'kaste',
  'kasten',
  'kastie',
  'kat',
  'kater',
  'katern',
  'katers',
  'kation',
  'katje',
  'katjes',
  'katoen',
  'katoog',
  'katrol',
  'katte',
  'katten',
  'kattig',
  'kattin',
  'katuil',
  'katvis',
  'kauri',
  'kauw',
  'kauwde',
  'kauwen',
  'kavel',
  'kavels',
  'kazak',
  'kazen',
  'kb',
  'kc',
  'kd',
  'ke',
  'kebab',
  'kebon',
  'kebons',
  'keek',
  'keel',
  'keelde',
  'keen',
  'keende',
  'keep',
  'keepen',
  'keeper',
  'keepte',
  'keer',
  'keerde',
  'kees',
  'keet',
  'keffen',
  'keffer',
  'kefir',
  'kefte',
  'keg',
  'kegel',
  'kegels',
  'kegge',
  'keggen',
  'kegje',
  'kei',
  'keien',
  'keikop',
  'keilde',
  'keilen',
  'keitje',
  'keizer',
  'kek',
  'keker',
  'kekers',
  'kelder',
  'kelen',
  'kelim',
  'kelk',
  'kelken',
  'kelner',
  'kelp',
  'kelt',
  'kelten',
  'kemel',
  'kemels',
  'kemp',
  'kempen',
  'kenau',
  'kende',
  'kenen',
  'kennel',
  'kennen',
  'kenner',
  'kennis',
  'kepen',
  'keper',
  'kepers',
  'kepie',
  'kepies',
  'kerel',
  'kerels',
  'keren',
  'kerf',
  'kerfde',
  'kering',
  'kerk',
  'kerken',
  'kerker',
  'kerks',
  'kerkse',
  'kerkte',
  'kermde',
  'kermen',
  'kermes',
  'Kermis',
  'kern',
  'kernen',
  'kerrie',
  'kers',
  'kersen',
  'kerst',
  'kervel',
  'kerven',
  'kesp',
  'kespen',
  'ketel',
  'ketels',
  'keten',
  'ketens',
  'ketjap',
  'ketje',
  'ketjes',
  'kets',
  'ketsen',
  'ketste',
  'ketter',
  'keu',
  'keuken',
  'Keuls',
  'Keulse',
  'keur',
  'keurde',
  'keuren',
  'keurig',
  'keurs',
  'keus',
  'keutel',
  'keuter',
  'keuvel',
  'keuze',
  'keuzen',
  'keuzes',
  'kevel',
  'kevels',
  'kever',
  'kevers',
  'kevie',
  'kevies',
  'kezen',
  'kf',
  'kg',
  'kh',
  'khmer',
  'ki',
  'kick',
  'kicken',
  'kief',
  'kiek',
  'kieken',
  'kiekje',
  'kiekte',
  'kiel',
  'kielde',
  'kielen',
  'kiem',
  'kiemde',
  'kiemen',
  'kien',
  'kiende',
  'kiene',
  'kienen',
  'kiep',
  'kiepen',
  'kier',
  'kierde',
  'kieren',
  'kies',
  'kiese',
  'kieuw',
  'kievit',
  'kiezel',
  'kiezen',
  'kiezer',
  'kift',
  'kiften',
  'kiftte',
  'kijf',
  'kijk',
  'kijken',
  'kijker',
  'kijven',
  'kik',
  'kikken',
  'kikker',
  'kikte',
  'kil',
  'kilde',
  'kille',
  'killen',
  'killer',
  'kilo',
  "kilo's",
  'kilt',
  'kilte',
  'kim',
  'kimde',
  'kimme',
  'kimmen',
  'kimono',
  'kin',
  'kina',
  'kinase',
  'kind',
  'kindje',
  'kinds',
  'kindse',
  'kinema',
  'kinine',
  'kink',
  'kinkel',
  'kinken',
  'kinnen',
  'kiosk',
  'kip',
  'kipbak',
  'kipkap',
  'kipkar',
  'kippen',
  'kippig',
  'kipte',
  'kirde',
  'kirren',
  'kirsch',
  'kissen',
  'kist',
  'kiste',
  'kisten',
  'kistje',
  'kistte',
  'kit',
  'kits',
  'kitsch',
  'kitsen',
  'kitste',
  'kitte',
  'kitten',
  'kittig',
  'kiwi',
  "kiwi's",
  'kj',
  'kk',
  'kkk',
  'kkkk',
  'kl',
  'klaar',
  'klaas',
  'klabak',
  'klacht',
  'klad',
  'kladde',
  'kladje',
  'klagen',
  'klager',
  'klak',
  'klakte',
  'klam',
  'klamme',
  'klamp',
  'klank',
  'klant',
  'klap',
  'klapte',
  'klare',
  'klaren',
  'klas',
  'klasse',
  'klauw',
  'klaver',
  'klazen',
  'kleden',
  'kledij',
  'kleed',
  'kleef',
  'klef',
  'kleffe',
  'klei',
  'kleide',
  'kleien',
  'kleiig',
  'klein',
  'kleine',
  'klem',
  'klemde',
  'klep',
  'klepel',
  'klepte',
  'kleren',
  'klerk',
  'klets',
  'kleun',
  'kleur',
  'kleven',
  'klever',
  'kliek',
  'klier',
  'klif',
  'klik',
  'klikte',
  'klim',
  'klimop',
  'kling',
  'klink',
  'klip',
  'klis',
  'kliste',
  'klit',
  'klitte',
  'klm',
  'klodde',
  'kloef',
  'kloek',
  'kloeke',
  'kloet',
  'klok',
  'klokje',
  'klokke',
  'klokte',
  'klom',
  'klomp',
  'klonen',
  'klonk',
  'klont',
  'kloof',
  'kloon',
  'kloot',
  'klop',
  'klopje',
  'klopte',
  'klos',
  'kloste',
  'kloten',
  'klove',
  'kloven',
  'klucht',
  'kluft',
  'kluif',
  'kluis',
  'kluit',
  'klunen',
  'kluns',
  'klus',
  'kluts',
  'kluwen',
  'klysma',
  'km',
  'kn',
  'knaak',
  'knaap',
  'knagen',
  'knak',
  'knaken',
  'knakte',
  'knal',
  'knalde',
  'knap',
  'knapen',
  'knappe',
  'knapte',
  'knar',
  'knauw',
  'knecht',
  'kneden',
  'kneep',
  'knekel',
  'knel',
  'knelde',
  'knepen',
  'kneu',
  'kneuen',
  'kneus',
  'knevel',
  'knie',
  'knieën',
  'knier',
  'knijp',
  'knik',
  'knikte',
  'knip',
  'knipte',
  'knoei',
  'knoest',
  'knoet',
  'knok',
  'knoken',
  'knokig',
  'knokte',
  'knol',
  'knook',
  'knoop',
  'knop',
  'knopen',
  'knopig',
  'knopte',
  'knor',
  'knorde',
  'knorf',
  'knot',
  'knotje',
  'knots',
  'knotte',
  'knudde',
  'knuist',
  'knul',
  'knus',
  'knusse',
  'ko',
  'koala',
  'kobalt',
  'kobbe',
  'kobben',
  'kobold',
  'kocht',
  'kodak',
  'kodaks',
  'kodde',
  'kodden',
  'koddig',
  'koe',
  'koedoe',
  'koeien',
  'koek',
  'koeken',
  'koekje',
  'koekte',
  'koel',
  'koelak',
  'koelde',
  'koele',
  'koelen',
  'koeler',
  'koelie',
  'koelte',
  'koen',
  'koene',
  'koepel',
  'koepok',
  'koer',
  'koerde',
  'koeren',
  'koers',
  'koest',
  'koet',
  'koeten',
  'koetje',
  'koets',
  'kof',
  'koffen',
  'koffer',
  'koffie',
  'kogel',
  'kogels',
  'kogen',
  'kogge',
  'koggen',
  'kohier',
  'koine',
  'kok',
  'koken',
  'kokend',
  'koker',
  'kokers',
  'koket',
  'kokkel',
  'kokker',
  'kokkie',
  'kokkin',
  'kokos',
  'kol',
  'kola',
  'kolbak',
  'kolde',
  'kolder',
  'kolen',
  'kolf',
  'kolfde',
  'kolfje',
  'koliek',
  'kolk',
  'kolken',
  'kolkte',
  'kollen',
  'kolom',
  'kolos',
  'kolven',
  'kom',
  'komaan',
  'komaf',
  'komeet',
  'komen',
  'komend',
  'komiek',
  'komijn',
  'komma',
  'kommen',
  'kommer',
  'kompas',
  'kompel',
  'komst',
  'komt',
  'kond',
  'konen',
  'kongsi',
  'konijn',
  'koning',
  'konkel',
  'kont',
  'konten',
  'kontje',
  'koof',
  'koog',
  'kooi',
  'kooide',
  'kooien',
  'kook',
  'kookte',
  'kool',
  'koon',
  'koop',
  'koopje',
  'koor',
  'koord',
  'koorde',
  'koorts',
  'koos',
  'koosde',
  'koot',
  'kootje',
  'kootte',
  'kop',
  'kopal',
  'kopbal',
  'kopeke',
  'kopen',
  'koper',
  'kopers',
  'kopie',
  'kopij',
  'kopje',
  'kopjes',
  'kopman',
  'koppel',
  'koppen',
  'koppig',
  'kopra',
  'kops',
  'kopse',
  'kopte',
  'kor',
  'koraal',
  'koran',
  'korde',
  'kordon',
  'koren',
  'korf',
  'korfde',
  'korist',
  'kornak',
  'kornet',
  'kornis',
  'korps',
  'korre',
  'korrel',
  'korren',
  'korset',
  'korst',
  'kort',
  'kortaf',
  'korte',
  'korten',
  'korter',
  'kortom',
  'kortte',
  'korund',
  'korven',
  'korvet',
  'kosjer',
  'kosmos',
  'kossem',
  'kost',
  'kosten',
  'koster',
  'kostte',
  'kot',
  'koten',
  'koter',
  'koters',
  'kots',
  'kotsen',
  'kotste',
  'kotten',
  'kotter',
  'kou',
  'koud',
  'koude',
  'kouder',
  'kous',
  'kousen',
  'kousje',
  'kout',
  'kouten',
  'kouter',
  'koutte',
  'kovel',
  'kovels',
  'koven',
  'kozak',
  'kozen',
  'kozijn',
  'kp',
  'kq',
  'kr',
  'kraag',
  'kraai',
  'kraak',
  'kraal',
  'kraam',
  'kraan',
  'krab',
  'krabbe',
  'krabde',
  'krach',
  'kracht',
  'kragen',
  'krak',
  'kraken',
  'kraker',
  'krakte',
  'kralen',
  'kram',
  'kramde',
  'kramen',
  'kramer',
  'kramp',
  'kranen',
  'kranig',
  'krank',
  'kranke',
  'krans',
  'krant',
  'krap',
  'krappe',
  'krapte',
  'kras',
  'krasse',
  'kraste',
  'krat',
  'krater',
  'kraton',
  'krats',
  'krauw',
  'kreeft',
  'kreeg',
  'kreek',
  'kreet',
  'kregel',
  'krek',
  'krekel',
  'kreken',
  'kreng',
  'krent',
  'krepte',
  'kresen',
  'kreten',
  'kreuk',
  'krevel',
  'kribbe',
  'kribde',
  'kribje',
  'kriek',
  'kriel',
  'krijg',
  'krijn',
  'krijs',
  'krijt',
  'krik',
  'krikte',
  'krill',
  'krimi',
  'krimp',
  'kring',
  'krip',
  'kris',
  'kriste',
  'krocht',
  'kroeg',
  'kroep',
  'kroes',
  'kroeze',
  'kroket',
  'krokus',
  'krol',
  'krols',
  'krolse',
  'krom',
  'kromde',
  'kromme',
  'kromp',
  'kromte',
  'kronen',
  'kroon',
  'kroop',
  'kroos',
  'kroost',
  'kroot',
  'krop',
  'kropte',
  'krot',
  'kroten',
  'krozen',
  'kruid',
  'kruide',
  'kruien',
  'kruier',
  'kruik',
  'kruim',
  'kruin',
  'kruis',
  'kruit',
  'kruk',
  'krukas',
  'krukte',
  'krul',
  'krulde',
  'ks',
  'kt',
  'ku',
  'kubiek',
  'kubist',
  'kubus',
  'kuch',
  'kuchen',
  'kuchte',
  'kudde',
  'kudden',
  'kuddes',
  'kuier',
  'kuif',
  'kuifde',
  'kuiken',
  'kuil',
  'kuilde',
  'kuilen',
  'kuiler',
  'kuip',
  'kuipen',
  'kuiper',
  'kuipte',
  'kuis',
  'kuise',
  'kuisen',
  'kuiser',
  'kuiste',
  'kuit',
  'kuiten',
  'kuiter',
  'kuiven',
  'kuizen',
  'kul',
  'kulas',
  'kulde',
  'kullen',
  'kummel',
  'kunde',
  'kundig',
  'kungfu',
  'kunne',
  'kunnen',
  'kunst',
  'kär',
  'kuras',
  'kuren',
  'kurk',
  'kurken',
  'kurkte',
  'kus',
  'kussen',
  'kust',
  'kuste',
  'kusten',
  'kut',
  'kutten',
  'kuub',
  'kuur',
  'kuurde',
  'kv',
  'kw',
  'kwaad',
  'kwaaie',
  'kwaal',
  'kwab',
  'kwade',
  'kwaden',
  'kwader',
  'kwak',
  'kwaken',
  'kwakte',
  'kwal',
  'kwalen',
  'kwam',
  'kwant',
  'kwanta',
  'kwark',
  'kwart',
  'kwarto',
  'kwarts',
  'kwast',
  'kwee',
  'kweeën',
  'kweek',
  'kween',
  'kwek',
  'kweken',
  'kweker',
  'kwekte',
  'kwel',
  'kwelde',
  'kwelen',
  'kwenen',
  'kwets',
  'kwezel',
  'kwibus',
  'kwiek',
  'kwieke',
  'kwijl',
  'kwijt',
  'kwik',
  'kwint',
  'kx',
  'ky',
  'kz',
  'l',
  'laadde',
  'laaf',
  'laafde',
  'laag',
  'laagje',
  'laagte',
  'laaide',
  'laaie',
  'laaien',
  'laakte',
  'laan',
  'laar',
  'laars',
  'laat',
  'laatje',
  'laatst',
  'lab',
  'label',
  'labels',
  'labeur',
  'labiel',
  'labo',
  'lach',
  'lachen',
  'lacher',
  'lachte',
  'lacune',
  'ladder',
  'lade',
  'laden',
  'lader',
  'laders',
  'lades',
  'lading',
  'lady',
  'laesie',
  'laf',
  'lafbek',
  'laffe',
  'laffer',
  'lag',
  'lage',
  'lagen',
  'lager',
  'lagune',
  'lak',
  'lakei',
  'laken',
  'lakens',
  'lakken',
  'lakooi',
  'laks',
  'lakse',
  'lakser',
  'lakte',
  'lala',
  'lalde',
  'lallen',
  'lam',
  'lama',
  "lama's",
  'lamŽ',
  'lamel',
  'lamfer',
  'lamme',
  'lammen',
  'lammer',
  'lammig',
  'lamoen',
  'lamp',
  'lampen',
  'lamzak',
  'lancet',
  'land',
  'landde',
  'lande',
  'landen',
  'landje',
  'lanen',
  'lang',
  'langde',
  'lange',
  'langen',
  'langer',
  'langs',
  'lans',
  'lansen',
  'Lap',
  'Lappen',
  'Laps',
  'lapsus',
  'lapte',
  'laptop',
  'laqué',
  'laren',
  'larf',
  'largo',
  'larie',
  'lariks',
  'larve',
  'larven',
  'larynx',
  'las',
  'laser',
  'lasers',
  'lassen',
  'lasser',
  'lasso',
  'last',
  'laste',
  'lasten',
  'laster',
  'lastig',
  'lat',
  'late',
  'latei',
  'laten',
  'latent',
  'later',
  'latere',
  'latex',
  'Latijn',
  'latte',
  'latten',
  'latuw',
  'lauden',
  'lauw',
  'lauwe',
  'lauwer',
  'lava',
  'lavabo',
  'lavas',
  'laven',
  'lavet',
  'laving',
  'lawaai',
  'lawine',
  'laxans',
  'lazer',
  'lazuur',
  'lb',
  'lc',
  'ld',
  'le',
  'league',
  'lease',
  'leasen',
  'leb',
  'lebaal',
  'lebbe',
  'lebben',
  'lebbig',
  'lector',
  'led',
  'lede',
  'leden',
  'leder',
  'ledig',
  'ledige',
  'leed',
  'leefde',
  'leeg',
  'leegde',
  'leegte',
  'leek',
  'leekte',
  'leem',
  'leeman',
  'leemde',
  'leemte',
  'leen',
  'leende',
  'leep',
  'leer',
  'leerde',
  'leest',
  'leeuw',
  'lef',
  'leg',
  'legaal',
  'legaat',
  'legale',
  'legato',
  'legde',
  'lege',
  'legen',
  'leger',
  'legers',
  'leges',
  'leggen',
  'legger',
  'leghen',
  'legio',
  'lego',
  'legsel',
  'lei',
  'leidde',
  'leiden',
  'leider',
  'leien',
  'leis',
  'leisel',
  'leitje',
  'leizen',
  'lek',
  'lekbak',
  'leken',
  'lekgat',
  'lekke',
  'lekken',
  'lekker',
  'lekte',
  'lel',
  'lelde',
  'lelie',
  'leliën',
  'lelies',
  'lelijk',
  'lellen',
  'lemen',
  'lemma',
  'lemmer',
  'lemmet',
  'lende',
  'lenden',
  'lenen',
  'lener',
  'leners',
  'leng',
  'lengde',
  'lengen',
  'lengte',
  'lenig',
  'lenige',
  'lening',
  'lens',
  'lensde',
  'lente',
  'lentes',
  'lento',
  'lenze',
  'lenzen',
  'lepe',
  'lepel',
  'lepels',
  'leper',
  'leperd',
  'leppen',
  'lepra',
  'lepte',
  'lepton',
  'leraar',
  'leren',
  'lering',
  'les',
  'lesbo',
  'lessen',
  'lest',
  'leste',
  'lesuur',
  'Let',
  'letaal',
  'letale',
  'letsel',
  'lette',
  'Letten',
  'letter',
  'leugen',
  'leuk',
  'leuke',
  'leuker',
  'leunde',
  'leunen',
  'leur',
  'leurde',
  'leuren',
  'leus',
  'leute',
  'leuter',
  'leutig',
  'leuze',
  'leuzen',
  'Levant',
  'leven',
  'levend',
  'levens',
  'lever',
  'levers',
  'leviet',
  'lexica',
  'lezen',
  'lezer',
  'lezers',
  'lezing',
  'lf',
  'lg',
  'lh',
  'li',
  'liaan',
  'liane',
  'lianen',
  'Lias',
  'libel',
  'libero',
  'libido',
  'libris',
  'licht',
  'lichte',
  'lictor',
  'lid',
  'lidje',
  'lidjes',
  'lied',
  'lieden',
  'liedje',
  'lief',
  'liefde',
  'liefje',
  'liefst',
  'liegen',
  'liep',
  'lier',
  'lieren',
  'liëren',
  'lies',
  'liet',
  'lieve',
  'lieven',
  'liever',
  'lievig',
  'liezen',
  'lift',
  'liften',
  'lifter',
  'liftte',
  'liga',
  'ligbad',
  'ligdag',
  'liggen',
  'ligger',
  'lij',
  'lijden',
  'lijder',
  'lijf',
  'lijfde',
  'lijfje',
  'lijk',
  'lijken',
  'lijkte',
  'lijkwa',
  'lijm',
  'lijmde',
  'lijmen',
  'lijmer',
  'lijmig',
  'lijn',
  'lijnde',
  'lijnen',
  'lijs',
  'lijst',
  'lijve',
  'lijven',
  'lijvig',
  'lijzen',
  'lijzig',
  'lijzij',
  'lik',
  'likeur',
  'likken',
  'likker',
  'likte',
  'lil',
  'lila',
  'lilde',
  'lillen',
  'liman',
  'limans',
  'limbo',
  'limbus',
  'limiet',
  'limit',
  'limoen',
  'linde',
  'linden',
  'linea',
  'linie',
  'linies',
  'link',
  'linke',
  'linken',
  'linker',
  'links',
  'linkse',
  'linnen',
  'lino',
  'lint',
  'linten',
  'lintje',
  'linze',
  'linzen',
  'lip',
  'lipje',
  'lipjes',
  'liplap',
  'lippen',
  'lipte',
  'lipvis',
  'lira',
  'lire',
  'lires',
  'lis',
  'lissen',
  'list',
  'listen',
  'listig',
  'liter',
  'liters',
  'litho',
  'liturg',
  'live',
  'living',
  'livrei',
  'lj',
  'lk',
  'll',
  'lll',
  'llll',
  'lm',
  'lmn',
  'ln',
  'lo',
  'lob',
  'lobben',
  'lobbes',
  'lobbig',
  'lobby',
  'loboor',
  'loc',
  'locale',
  'locker',
  'loco',
  'lodder',
  'loden',
  'lodens',
  'loding',
  'loeder',
  'loef',
  'loefde',
  'loeide',
  'loeien',
  'loens',
  'loense',
  'loep',
  'loepen',
  'loer',
  'loerde',
  'loeren',
  'loeres',
  'loeris',
  'loet',
  'loeten',
  'loeven',
  'lof',
  'log',
  'logde',
  'loge',
  'logé',
  'logee',
  'logees',
  'logen',
  'loges',
  'logés',
  'logge',
  'loggen',
  'logger',
  'loggia',
  'logica',
  'logies',
  'logo',
  "logo's",
  'loipe',
  'lok',
  'lokaal',
  'lokaas',
  'lokale',
  'loket',
  'lokken',
  'lokkig',
  'lokte',
  'lol',
  'lolde',
  'lolita',
  'lollen',
  'lollig',
  'lolly',
  'lom',
  'lombok',
  'lome',
  'lomer',
  'lomig',
  'lommen',
  'lommer',
  'lomp',
  'lompe',
  'lompen',
  'lonen',
  'long',
  'longen',
  'lonk',
  'lonken',
  'lonkte',
  'lont',
  'lonten',
  'lood',
  'loodde',
  'loodje',
  'loods',
  'loof',
  'loofde',
  'loog',
  'loogde',
  'looi',
  'looide',
  'looien',
  'looier',
  'look',
  'loom',
  'loon',
  'loonde',
  'loop',
  'loopje',
  'loops',
  'loopse',
  'loos',
  'loosde',
  'loot',
  'lootje',
  'lootte',
  'lopen',
  'lopend',
  'loper',
  'lopers',
  'lor',
  'lord',
  'lorde',
  'lork',
  'lorken',
  'lorre',
  'lorren',
  'lorres',
  'lorrie',
  'lorrig',
  'lorum',
  'los',
  'losbol',
  'losdag',
  'losjes',
  'loss',
  'löss',
  'losse',
  'lossen',
  'losser',
  'loste',
  'losweg',
  'lot',
  'loten',
  'loting',
  'lotion',
  'lotje',
  'lotto',
  'lotus',
  'louche',
  'lounge',
  'louter',
  'louw',
  'louwen',
  'loven',
  'lover',
  'loyaal',
  'loyale',
  'loze',
  'lozen',
  'lozer',
  'lozing',
  'lp',
  'lq',
  'lr',
  'ls',
  'lt',
  'lu',
  'lubben',
  'lubde',
  'lucht',
  'lucide',
  'ludiek',
  'lues',
  'lui',
  'luid',
  'luidde',
  'luide',
  'luiden',
  'luider',
  'luidop',
  'luien',
  'luier',
  'luiers',
  'luifel',
  'Luik',
  'luiken',
  'luilak',
  'luim',
  'luimde',
  'luimen',
  'luimig',
  'luis',
  'luisde',
  'luit',
  'luiten',
  'luizen',
  'luizig',
  'luk',
  'lukken',
  'lukte',
  'lul',
  'lulde',
  'lullen',
  'lullig',
  'lummel',
  'lunair',
  'lunch',
  'lunet',
  'luns',
  'lunsde',
  'lunzen',
  'lupine',
  'lupus',
  'luren',
  'lurken',
  'lurkte',
  'lurven',
  'lus',
  'lussen',
  'lust',
  'lusten',
  'luster',
  'lustig',
  'lustra',
  'lustre',
  'lustte',
  'lutje',
  'lutjes',
  'luttel',
  'luw',
  'luwde',
  'luwe',
  'luwen',
  'luwte',
  'lux',
  'luxe',
  'lv',
  'lw',
  'lx',
  'ly',
  'lycea',
  'lyceum',
  'lychee',
  'lycra',
  'lymfe',
  'lynx',
  'lynxen',
  'lyriek',
  'lysine',
  'lysol',
  'lz',
  'm',
  'maag',
  'maagd',
  'maaide',
  'maaien',
  'maaier',
  'maak',
  'maakte',
  'maal',
  'maalde',
  'maan',
  'maand',
  'maande',
  'maar',
  'maart',
  'maarte',
  'maarts',
  'maas',
  'maasde',
  'maat',
  'maatje',
  'mach',
  'macho',
  'macht',
  'macro',
  'madam',
  'madame',
  'madams',
  'made',
  'maden',
  'madera',
  'madras',
  'maf',
  'maffe',
  'maffen',
  'maffer',
  'maffia',
  'mafte',
  'magen',
  'mager',
  'magere',
  'maggi',
  'magie',
  'magiër',
  'magma',
  'magnum',
  'Mahdi',
  'mail',
  'mailen',
  'maïs',
  'maître',
  'majeur',
  'majoor',
  'major',
  'majors',
  'mak',
  'maken',
  'maker',
  'makers',
  'makke',
  'makker',
  'makron',
  'mal',
  'malaga',
  'malde',
  'Maleis',
  'malen',
  'maler',
  'malers',
  'malie',
  'maliën',
  'malies',
  'maling',
  'malle',
  'mallen',
  'maller',
  'mals',
  'malse',
  'malser',
  'malta',
  'malus',
  'maluwe',
  'malve',
  'malven',
  'mam',
  'mama',
  "mama's",
  'mamma',
  'mammen',
  'mammon',
  'mams',
  'man',
  'manche',
  'manco',
  'mand',
  'mande',
  'manden',
  'manege',
  'manen',
  'maner',
  'maners',
  'manga',
  'mangat',
  'mangel',
  'mango',
  'maniak',
  'manie',
  'manier',
  'manies',
  'maniok',
  'mank',
  'manke',
  'manken',
  'mankte',
  'manna',
  'mannen',
  'mannin',
  'manou',
  'mans',
  'mantel',
  'mantra',
  'manuur',
  'maoïst',
  'map',
  'mappen',
  'marde',
  'mare',
  'maren',
  'marge',
  'marges',
  'Maria',
  'marine',
  'mark',
  'marken',
  'marker',
  'markt',
  'marlde',
  'marlen',
  'marmer',
  'marmot',
  'marot',
  'marqué',
  'marren',
  'marron',
  'mars',
  'marsen',
  'marter',
  'maseur',
  'masker',
  'massa',
  'mast',
  'mastel',
  'masten',
  'mastte',
  'mat',
  'match',
  'mate',
  'maté',
  'maten',
  'mater',
  'maters',
  'matig',
  'matige',
  'matje',
  'matjes',
  'matrak',
  'matras',
  'matrix',
  'matse',
  'matsen',
  'matses',
  'matste',
  'matte',
  'matten',
  'matter',
  'mauser',
  'mauve',
  'mauwde',
  'mauwen',
  'maxi',
  'maxima',
  'maxime',
  'maya',
  'mazen',
  'mazzel',
  'mb',
  'mc',
  'md',
  'me',
  'mede',
  'media',
  'medici',
  'medina',
  'medio',
  'medium',
  'medley',
  'medoc',
  'medusa',
  'mee',
  'meel',
  'meelde',
  'meelij',
  'meende',
  'meent',
  'meer',
  "ME'er",
  'meerde',
  'meers',
  'mees',
  'meest',
  'meeste',
  'meet',
  'meeuw',
  'mf',
  'mg',
  'mh',
  'mi',
  'mj',
  'mk',
  'ml',
  'mm',
  'mmm',
  'mmmm',
  'mn',
  'mno',
  'mo',
  'mp',
  'mq',
  'mr',
  'ms',
  'mt',
  'mu',
  'mv',
  'mw',
  'mx',
  'my',
  'mz',
  'n',
  'nb',
  'nc',
  'nd',
  'ne',
  'nf',
  'ng',
  'nh',
  'ni',
  'nj',
  'nk',
  'nl',
  'nm',
  'nn',
  'nnn',
  'nnnn',
  'no',
  'nop',
  'np',
  'nq',
  'nr',
  'ns',
  'nt',
  'nu',
  'nv',
  'nw',
  'nx',
  'ny',
  'nz',
  'o',
  'ob',
  'oc',
  'od',
  'oe',
  'of',
  'og',
  'oh',
  'oi',
  'oj',
  'ok',
  'ol',
  'om',
  'on',
  'oo',
  'ooo',
  'oooo',
  'op',
  'opq',
  'oq',
  'or',
  'os',
  'ot',
  'ou',
  'ov',
  'ow',
  'ox',
  'oy',
  'oz',
  'p',
  'pb',
  'pc',
  'pd',
  'pe',
  'pf',
  'pg',
  'ph',
  'pi',
  'pj',
  'pk',
  'pl',
  'pm',
  'pn',
  'po',
  'pp',
  'ppp',
  'pppp',
  'pq',
  'pqr',
  'pr',
  'ps',
  'pt',
  'pu',
  'pv',
  'pw',
  'px',
  'py',
  'pz',
  'q',
  'qb',
  'qc',
  'qd',
  'qe',
  'qf',
  'qg',
  'qh',
  'qi',
  'qj',
  'qk',
  'ql',
  'qm',
  'qn',
  'qo',
  'qp',
  'qq',
  'qqq',
  'qqqq',
  'qr',
  'qrs',
  'qs',
  'qt',
  'qu',
  'qv',
  'qw',
  'qx',
  'qy',
  'qz',
  'r',
  'rb',
  'rc',
  'rd',
  're',
  'rf',
  'rg',
  'rh',
  'ri',
  'rj',
  'rk',
  'rl',
  'rm',
  'rn',
  'ro',
  'rp',
  'rq',
  'rr',
  'rrr',
  'rrrr',
  'rs',
  'rst',
  'rt',
  'ru',
  'rv',
  'rw',
  'rx',
  'ry',
  'rz',
  's',
  'sb',
  'sc',
  'sd',
  'se',
  'sf',
  'sg',
  'sh',
  'si',
  'sj',
  'sk',
  'sl',
  'sm',
  'sn',
  'so',
  'sp',
  'sq',
  'sr',
  'ss',
  'sss',
  'ssss',
  'st',
  'stu',
  'su',
  'sv',
  'sw',
  'sx',
  'sy',
  'sz',
  't',
  'tb',
  'tc',
  'td',
  'te',
  'tf',
  'tg',
  'th',
  'ti',
  'tj',
  'tk',
  'tl',
  'tm',
  'tn',
  'to',
  'tp',
  'tq',
  'tr',
  'ts',
  'tt',
  'ttt',
  'tttt',
  'tu',
  'tuv',
  'tv',
  'tw',
  'tx',
  'ty',
  'tz',
  'u',
  'ub',
  'uc',
  'ud',
  'ue',
  'uf',
  'ug',
  'uh',
  'ui',
  'uj',
  'uk',
  'ul',
  'um',
  'un',
  'uo',
  'up',
  'uq',
  'ur',
  'us',
  'ut',
  'uu',
  'uuu',
  'uuuu',
  'uv',
  'uvw',
  'uw',
  'ux',
  'uy',
  'uz',
  'v',
  'vb',
  'vc',
  'vd',
  've',
  'vf',
  'vg',
  'vh',
  'vi',
  'vj',
  'vk',
  'vl',
  'vm',
  'vn',
  'vo',
  'vp',
  'vq',
  'vr',
  'vs',
  'vt',
  'vu',
  'vv',
  'vvv',
  'vvvv',
  'vw',
  'vwx',
  'vx',
  'vy',
  'vz',
  'w',
  'wb',
  'wc',
  'wd',
  'we',
  'wf',
  'wg',
  'wh',
  'wi',
  'wj',
  'wk',
  'wl',
  'wm',
  'wn',
  'wo',
  'wp',
  'wq',
  'wr',
  'ws',
  'wt',
  'wu',
  'wv',
  'ww',
  'www',
  'wwww',
  'wx',
  'wxy',
];
