import PropTypes from 'prop-types';
import React from 'react';

import { Button } from './Button';
import { PopupModal } from './Popup';

export const Actions = props => {
  const { onGenerate, onReset, onSendEmail, pass } = props;

  return (
    <div className="grid sm:grid-cols-3 gap-4">
      <Button title="Generate" onClick={onGenerate} />
      <Button title="Reset" onClick={onReset} />
      <PopupModal pass={pass} />
      {/* <Button title="Send Secure Email" onClick={onSendEmail} /> */}
    </div>
  );
};

Actions.propTypes = {
  onGenerate: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSendEmail: PropTypes.func,
};