export const romaji = [
  '0',
  '1',
  '10',
  '100',
  '1000',
  '101',
  '11',
  '111',
  '1111',
  '12',
  '123',
  '1234',
  '13',
  '14',
  '15',
  '1500',
  '16',
  '1600',
  '17',
  '1700',
  '18',
  '1800',
  '19',
  '1900',
  '1910',
  '1920',
  '1925',
  '1930',
  '1935',
  '1940',
  '1945',
  '1950',
  '1955',
  '1960',
  '1965',
  '1970',
  '1975',
  '1980',
  '1985',
  '1990',
  '1991',
  '1992',
  '1993',
  '1994',
  '1995',
  '1996',
  '1997',
  '1998',
  '1999',
  '2',
  '20',
  '200',
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2015',
  '2020',
  '2030',
  '2035',
  '2040',
  '2045',
  '2050',
  '21',
  '22',
  '222',
  '2222',
  '23',
  '234',
  '2345',
  '24',
  '2468',
  '25',
  '26',
  '27',
  '28',
  '29',
  '3',
  '30',
  '300',
  '3000',
  '31',
  '32',
  '33',
  '333',
  '3333',
  '34',
  '345',
  '3456',
  '35',
  '36',
  '37',
  '38',
  '39',
  '4',
  '40',
  '400',
  '4000',
  '41',
  '42',
  '43',
  '4321',
  '44',
  '444',
  '4444',
  '45',
  '456',
  '4567',
  '46',
  '47',
  '48',
  '49',
  '5',
  '50',
  '500',
  '5000',
  '51',
  '52',
  '53',
  '54',
  '55',
  '555',
  '5555',
  '56',
  '567',
  '5678',
  '57',
  '58',
  '59',
  '6',
  '60',
  '600',
  '6000',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '666',
  '6666',
  '67',
  '678',
  '6789',
  '68',
  '69',
  '7',
  '70',
  '700',
  '7000',
  '71',
  '72',
  '73',
  '74',
  '75',
  '76',
  '77',
  '777',
  '7777',
  '78',
  '789',
  '79',
  '8',
  '80',
  '800',
  '8000',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '88',
  '888',
  '8888',
  '89',
  '9',
  '90',
  '900',
  '9000',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '97',
  '98',
  '9876',
  '99',
  '999',
  '9999',
  'a',
  'aa',
  'aaa',
  'aaaa',
  'aayuu',
  'ab',
  'abc',
  'abiru',
  'abuku',
  'abunai',
  'abura',
  'aburae',
  'ac',
  'acchi',
  'achi',
  'achira',
  'ad',
  'adana',
  'ae',
  'aen',
  'aete',
  'af',
  'ag',
  'agari',
  'agaru',
  'age',
  'ageku',
  'ageru',
  'ago',
  'agura',
  'ah',
  'aho',
  'ahou',
  'ai',
  'aida',
  'aidoku',
  'aigo',
  'aijaku',
  'aijin',
  'aijou',
  'aiken',
  'aikoku',
  'aikou',
  'aima',
  'aimai',
  'ainiku',
  'ainori',
  'aishou',
  'aishuu',
  'aiso',
  'aisou',
  'aisu',
  'aisuru',
  'aitai',
  'aite',
  'aitou',
  'aitsu',
  'aiyou',
  'aizu',
  'aj',
  'aji',
  'ajiwai',
  'ajiwau',
  'ak',
  'aka',
  'akai',
  'akairo',
  'akaji',
  'akari',
  'akarui',
  'akashi',
  'akasu',
  'ake',
  'akeru',
  'aki',
  'akichi',
  'akikan',
  'akinai',
  'akindo',
  'akiru',
  'akiudo',
  'akiya',
  'akka',
  'akkan',
  'akke',
  'akkou',
  'ako',
  'aku',
  'akubi',
  'akugi',
  'akui',
  'akuji',
  'akujo',
  'akuma',
  'akumu',
  'akunin',
  'akuru',
  'akusei',
  'akushu',
  'akuta',
  'akuto',
  'akuyou',
  'al',
  'am',
  'ama',
  'amado',
  'amae',
  'amaeru',
  'amagu',
  'amai',
  'amami',
  'amari',
  'amaru',
  'amasu',
  'ame',
  'ami',
  'amido',
  'amo',
  'amu',
  'an',
  'ana',
  'anata',
  'anaume',
  'anbai',
  'anchan',
  'anda',
  'andake',
  'ane',
  'anesan',
  'angai',
  'angou',
  'angya',
  'ani',
  'aniki',
  'anisan',
  'anji',
  'anjuu',
  'anka',
  'anken',
  'anki',
  'anko',
  'ankoku',
  'anma',
  'anmari',
  'anmin',
  'anmoku',
  'anna',
  'annai',
  'anne',
  'anni',
  'ano',
  'anone',
  'anosaa',
  'anou',
  'anoyo',
  'anpi',
  'anpo',
  'ansei',
  'anshin',
  'anshou',
  'antai',
  'antei',
  'anzen',
  'anzu',
  'ao',
  'aoba',
  'aogu',
  'aoi',
  'aoki',
  'aomuke',
  'aoru',
  'aouma',
  'aoume',
  'aoyagi',
  'aozora',
  'ap',
  'appaku',
  'aq',
  'ar',
  'ara',
  'arai',
  'araki',
  'arano',
  'arashi',
  'arasoi',
  'arasou',
  'arasu',
  'arata',
  'arate',
  'arau',
  'are',
  'areno',
  'areru',
  'ari',
  'ariyou',
  'aru',
  'aruha',
  'aruiha',
  'aruku',
  'as',
  'asa',
  'asagao',
  'asahan',
  'asahi',
  'asai',
  'asaru',
  'asatte',
  'asayuu',
  'ase',
  'aseri',
  'aseru',
  'ashi',
  'ashiba',
  'ashiko',
  'ashita',
  'asobi',
  'asobu',
  'asoko',
  'assari',
  'assen',
  'asshou',
  'asu',
  'asubu',
  'asuko',
  'at',
  'ataeru',
  'atai',
  'atama',
  'atari',
  'ataru',
  'atashi',
  'ate',
  'atena',
  'ateru',
  'ato',
  'atsugi',
  'atsui',
  'atsumi',
  'atsusa',
  'atte',
  'atteno',
  'attou',
  'au',
  'av',
  'aw',
  'awa',
  'aware',
  'awasu',
  'ax',
  'ay',
  'ayaui',
  'ayauku',
  'ayumi',
  'ayumu',
  'az',
  'aza',
  'azuki',
  'b',
  'baai',
  'baasan',
  'baba',
  'bachi',
  'bai',
  'baibai',
  'baikin',
  'baitai',
  'baiten',
  'baiu',
  'baiyou',
  'baizou',
  'baka',
  'bakari',
  'baken',
  'bakeru',
  'bakka',
  'bakkin',
  'baku',
  'bakufu',
  'bakuha',
  'bakuon',
  'bakuro',
  'bamen',
  'ban',
  'banchi',
  'bane',
  'bangou',
  'banji',
  'banjin',
  'bankon',
  'banme',
  'bannen',
  'bannin',
  'bannou',
  'banri',
  'bansou',
  'bante',
  'banzai',
  'banzei',
  'banzen',
  'bara',
  'barasu',
  'bareru',
  'bari',
  'basha',
  'basho',
  'bashu',
  'bassai',
  'basshi',
  'bassui',
  'bateru',
  'batsu',
  'batten',
  'bb',
  'bbb',
  'bbbb',
  'bc',
  'bcd',
  'bd',
  'be',
  'beihan',
  'beijin',
  'beika',
  'bekkan',
  'bekko',
  'bekkyo',
  'ben',
  'bengi',
  'bengo',
  'beni',
  'benjo',
  'benkai',
  'benkei',
  'benmei',
  'benri',
  'benron',
  'bensai',
  'bentou',
  'bessou',
  'betsu',
  'bf',
  'bg',
  'bh',
  'bi',
  'bidou',
  'bifuu',
  'bigaku',
  'bijin',
  'bijo',
  'bika',
  'bikou',
  'bimyou',
  'bin',
  'binbou',
  'binjou',
  'binkan',
  'binsen',
  'biryou',
  'bisai',
  'bishin',
  'bishou',
  'bisoku',
  'biwa',
  'biyou',
  'bj',
  'bk',
  'bl',
  'bm',
  'bn',
  'bo',
  'bochi',
  'bokasu',
  'boke',
  'bokeru',
  'bokin',
  'bokoku',
  'bokou',
  'boku',
  'bokuya',
  'bon',
  'bonchi',
  'bonjin',
  'bonnin',
  'bonsai',
  'bonyuu',
  'bosan',
  'bosei',
  'boseki',
  'boshi',
  'boshuu',
  'botai',
  'botan',
  'bottou',
  'bou',
  'boudai',
  'boudan',
  'boudou',
  'bouei',
  'boueki',
  'boufuu',
  'bougai',
  'bougo',
  'bougyo',
  'bouhan',
  'bouka',
  'boukan',
  'bouken',
  'boukou',
  'boukuu',
  'boukyo',
  'boumei',
  'bouon',
  'bousai',
  'bousan',
  'bousen',
  'boushi',
  'bousou',
  'bousui',
  'bouto',
  'boutou',
  'bouzu',
  'boya',
  'bp',
  'bq',
  'br',
  'bs',
  'bt',
  'bu',
  'buai',
  'bubun',
  'buchou',
  'budou',
  'bufu',
  'bugyou',
  'buhin',
  'bui',
  'buin',
  'buji',
  'bujin',
  'bujoku',
  'buka',
  'bukai',
  'buki',
  'bukimi',
  'bukka',
  'bukken',
  'bumon',
  'bun',
  'bunai',
  'bunan',
  'bunbo',
  'bundan',
  'bungei',
  'bungo',
  'bungu',
  'bunin',
  'bunjou',
  'bunka',
  'bunkai',
  'bunkei',
  'bunken',
  'bunki',
  'bunko',
  'bunkou',
  'bunmei',
  'bunmen',
  'bunmin',
  'bunpai',
  'bunpi',
  'bunpou',
  'bunpu',
  'bunri',
  'bunrui',
  'bunsan',
  'bunshi',
  'bunsho',
  'bunsuu',
  'buntai',
  'buntan',
  'bunya',
  'buonna',
  'buppan',
  'buppin',
  'buraku',
  'burei',
  'buri',
  'bushi',
  'busho',
  'bushou',
  'busou',
  'bussan',
  'busshi',
  'bussou',
  'busu',
  'busuu',
  'buta',
  'butai',
  'butou',
  'butsu',
  'buttai',
  'buyou',
  'buzoku',
  'bv',
  'bw',
  'bx',
  'by',
  'byakue',
  'byakui',
  'byou',
  'byoubu',
  'byouin',
  'byouki',
  'byouri',
  'bz',
  'c',
  'cb',
  'cc',
  'ccc',
  'cccc',
  'cd',
  'cde',
  'ce',
  'cf',
  'cg',
  'ch',
  'cha',
  'chadou',
  'chairo',
  'chaku',
  'chakui',
  'chan',
  'chanto',
  'chawan',
  'chaya',
  'chi',
  'chian',
  'chichi',
  'chie',
  'chien',
  'chigai',
  'chigau',
  'chigyo',
  'chihou',
  'chii',
  'chiiki',
  'chiji',
  'chijin',
  'chijo',
  'chijou',
  'chika',
  'chikai',
  'chikau',
  'chikei',
  'chiken',
  'chiku',
  'chimei',
  'chin',
  'chinka',
  'chinou',
  'chinpu',
  'chiri',
  'chiru',
  'chisai',
  'chisei',
  'chishi',
  'chisou',
  'chisso',
  'chisui',
  'chitai',
  'chiten',
  'chiyo',
  'chiyu',
  'chizu',
  'cho',
  'choito',
  'chokin',
  'chokka',
  'choku',
  'chomei',
  'chosha',
  'chosho',
  'chosui',
  'choto',
  'chotto',
  'chou',
  'choubo',
  'chouda',
  'choudo',
  'chouin',
  'chouja',
  'chouji',
  'choujo',
  'chouju',
  'chouka',
  'chouki',
  'choume',
  'chouri',
  'chousa',
  'chouwa',
  'chozou',
  'chuu',
  'chuubu',
  'chuui',
  'chuuka',
  'chuuki',
  'chuuko',
  'chuuou',
  'chuusa',
  'chuuto',
  'chuuwa',
  'chuuya',
  'ci',
  'cj',
  'ck',
  'cl',
  'cm',
  'cn',
  'co',
  'cp',
  'cq',
  'cr',
  'cs',
  'ct',
  'cu',
  'cv',
  'cw',
  'cx',
  'cy',
  'cz',
  'd',
  'daboku',
  'daga',
  'dageki',
  'daha',
  'dai',
  'daiben',
  'daibu',
  'daibun',
  'daichi',
  'daida',
  'daidai',
  'daidou',
  'daiei',
  'daigae',
  'daigi',
  'daihon',
  'daii',
  'daiji',
  'daijin',
  'daikan',
  'daikin',
  'daikon',
  'daikou',
  'daiku',
  'daimei',
  'dainin',
  'dainou',
  'dairi',
  'daisha',
  'daishi',
  'daitai',
  'daitan',
  'daiyou',
  'daizai',
  'daizu',
  'dajun',
  'daka',
  'dakai',
  'dakara',
  'dake',
  'dakedo',
  'dakkai',
  'dakkan',
  'dakke',
  'dakko',
  'daku',
  'dakyou',
  'dakyuu',
  'damaru',
  'damasu',
  'dame',
  'dan',
  'danbou',
  'danchi',
  'dandan',
  'dandou',
  'dangai',
  'dangan',
  'dangen',
  'dangou',
  'danji',
  'danjo',
  'danjou',
  'dankai',
  'danki',
  'danko',
  'dankou',
  'danmen',
  'danna',
  'dannen',
  'dano',
  'danpen',
  'dansei',
  'danshi',
  'dansou',
  'dansui',
  'dantai',
  'dantei',
  'dantou',
  'danwa',
  'danyuu',
  'danzen',
  'dappi',
  'darake',
  'daraku',
  'dare',
  'dareka',
  'dari',
  'daro',
  'darou',
  'darui',
  'daseki',
  'dasen',
  'dasha',
  'dashi',
  'dashin',
  'dassen',
  'dasshu',
  'dassou',
  'dassui',
  'dasu',
  'dasuu',
  'date',
  'daten',
  'dato',
  'datou',
  'datsui',
  'dattai',
  'datte',
  'dayori',
  'db',
  'dc',
  'dd',
  'ddd',
  'dddd',
  'de',
  'deai',
  'deashi',
  'deau',
  'deban',
  'dedoko',
  'def',
  'deha',
  'deiri',
  'dekai',
  'dekata',
  'dekiru',
  'dekkai',
  'demae',
  'demise',
  'demo',
  'demuku',
  'denchi',
  'dendou',
  'dengen',
  'dengon',
  'denka',
  'denki',
  'denkou',
  'denpa',
  'denpo',
  'denpou',
  'densen',
  'densha',
  'denshi',
  'densou',
  'dentou',
  'denwa',
  'deru',
  'desae',
  'deshi',
  'desho',
  'deshou',
  'desu',
  'desuga',
  'df',
  'dg',
  'dh',
  'di',
  'dj',
  'dk',
  'dl',
  'dm',
  'dn',
  'do',
  'doai',
  'doboku',
  'dobu',
  'docchi',
  'dochi',
  'dodai',
  'dohyou',
  'doitsu',
  'dojou',
  'dokeru',
  'doki',
  'dokka',
  'doko',
  'dokoka',
  'doku',
  'dokuji',
  'dokyou',
  'doma',
  'domo',
  'domoru',
  'don',
  'donaru',
  'donata',
  'dondon',
  'donka',
  'donkan',
  'donna',
  'dono',
  'donyou',
  'doori',
  'dore',
  'dorei',
  'doro',
  'doseki',
  'dosha',
  'dosoku',
  'dosuu',
  'dote',
  'dou',
  'douage',
  'doubou',
  'douchi',
  'doudou',
  'doufuu',
  'douga',
  'dougi',
  'dougu',
  'douhan',
  'douhou',
  'doui',
  'douin',
  'douji',
  'doujin',
  'doujou',
  'douka',
  'doukan',
  'douken',
  'douki',
  'doukou',
  'doukyo',
  'doumei',
  'doumo',
  'dounen',
  'dounin',
  'douon',
  'douran',
  'douri',
  'douro',
  'dourui',
  'dousa',
  'douse',
  'dousei',
  'dousen',
  'dousha',
  'doushi',
  'doushu',
  'dousou',
  'dousuu',
  'doutai',
  'doutei',
  'douten',
  'doutou',
  'douwa',
  'douya',
  'douyou',
  'douzen',
  'douzo',
  'douzou',
  'doyou',
  'dp',
  'dq',
  'dr',
  'ds',
  'dt',
  'du',
  'duki',
  'dutsu',
  'dv',
  'dw',
  'dx',
  'dy',
  'dz',
  'e',
  'eb',
  'ebaru',
  'ebesu',
  'ebi',
  'ebisu',
  'ec',
  'echigo',
  'ed',
  'eda',
  'edaha',
  'edo',
  'edokko',
  'ee',
  'eee',
  'eeee',
  'eeto',
  'ef',
  'efg',
  'eg',
  'egaku',
  'egao',
  'eh',
  'ehon',
  'ei',
  'eibun',
  'eichi',
  'eidan',
  'eien',
  'eiga',
  'eigo',
  'eigyou',
  'eiji',
  'eijuu',
  'eikan',
  'eiki',
  'eikou',
  'eikyou',
  'eikyuu',
  'eimei',
  'einen',
  'einou',
  'eiri',
  'eisei',
  'eitai',
  'eiwa',
  'eiyaku',
  'eiyo',
  'eiyou',
  'eiyuu',
  'eizoku',
  'eizou',
  'ej',
  'ek',
  'eki',
  'ekiben',
  'ekiden',
  'ekiin',
  'ekika',
  'ekimae',
  'ekitai',
  'ekkyou',
  'ekubo',
  'el',
  'em',
  'emaki',
  'emi',
  'emono',
  'en',
  'enban',
  'enbu',
  'enbun',
  'enchou',
  'endaka',
  'endan',
  'endate',
  'enden',
  'endou',
  'engan',
  'engawa',
  'engei',
  'engeki',
  'engi',
  'engo',
  'enji',
  'enjiru',
  'enjo',
  'enjou',
  'enka',
  'enkai',
  'enkaku',
  'enkei',
  'enki',
  'enman',
  'enmei',
  'enmoku',
  'ennai',
  'enogu',
  'enpou',
  'enpyou',
  'enryo',
  'ensei',
  'ensen',
  'enshou',
  'enshuu',
  'enso',
  'ensoku',
  'ensou',
  'ensui',
  'entai',
  'entaku',
  'enten',
  'entou',
  'enyasu',
  'enzan',
  'enzuru',
  'eo',
  'ep',
  'eq',
  'er',
  'erabu',
  'erai',
  'eri',
  'eru',
  'es',
  'esa',
  'eshaku',
  'et',
  'etto',
  'ettou',
  'eu',
  'ev',
  'ew',
  'ex',
  'ey',
  'ez',
  'f',
  'fb',
  'fc',
  'fd',
  'fe',
  'ff',
  'fff',
  'ffff',
  'fg',
  'fgh',
  'fh',
  'fi',
  'fj',
  'fk',
  'fl',
  'fm',
  'fn',
  'fo',
  'fp',
  'fq',
  'fr',
  'fs',
  'ft',
  'fu',
  'fuan',
  'fuben',
  'fubi',
  'fubo',
  'fubuki',
  'fuchi',
  'fuchin',
  'fuchou',
  'fuchuu',
  'fuda',
  'fudan',
  'fude',
  'fudou',
  'fue',
  'fueru',
  'fufuku',
  'fugen',
  'fugou',
  'fuhai',
  'fuhei',
  'fuhen',
  'fuhou',
  'fuhyou',
  'fui',
  'fuini',
  'fuji',
  'fujin',
  'fujo',
  'fujoki',
  'fujou',
  'fujun',
  'fuka',
  'fukahi',
  'fukai',
  'fukami',
  'fukasa',
  'fukasu',
  'fukei',
  'fuken',
  'fukeru',
  'fukin',
  'fukiso',
  'fukken',
  'fukki',
  'fukkou',
  'fukoku',
  'fukou',
  'fuku',
  'fukubu',
  'fukuin',
  'fukumi',
  'fukumu',
  'fukuri',
  'fukuro',
  'fukyou',
  'fukyuu',
  'fuman',
  'fumei',
  'fumi',
  'fumie',
  'fumin',
  'fumon',
  'fumoto',
  'fumou',
  'fumu',
  'fun',
  'funade',
  'funaka',
  'funare',
  'fune',
  'fungai',
  'funin',
  'funka',
  'funki',
  'funou',
  'funsha',
  'funsou',
  'funsui',
  'funtou',
  'fuon',
  'fureai',
  'fureau',
  'fureru',
  'furi',
  'furiko',
  'furin',
  'furo',
  'furoba',
  'furoku',
  'furoya',
  'furu',
  'furugi',
  'furui',
  'furusu',
  'furuu',
  'furyo',
  'furyou',
  'fusa',
  'fusagu',
  'fusai',
  'fusaku',
  'fuse',
  'fusegu',
  'fusei',
  'fuseki',
  'fusen',
  'fuseru',
  'fusha',
  'fushi',
  'fushin',
  'fushou',
  'fusoku',
  'fusuma',
  'futa',
  'futaba',
  'futae',
  'futago',
  'futai',
  'futami',
  'futan',
  'futari',
  'futei',
  'futo',
  'futoi',
  'futon',
  'futoru',
  'futou',
  'futsuu',
  'futtou',
  'fuu',
  'fuudo',
  'fuufu',
  'fuuja',
  'fuukei',
  'fuumi',
  'fuun',
  'fuurin',
  'fuusa',
  'fuusen',
  'fuusha',
  'fuushi',
  'fuusho',
  'fuutou',
  'fuwari',
  'fuyasu',
  'fuyo',
  'fuyou',
  'fuyoui',
  'fuyu',
  'fuyuba',
  'fuyuu',
  'fuzai',
  'fuzei',
  'fuzen',
  'fuzoku',
  'fv',
  'fw',
  'fx',
  'fy',
  'fz',
  'g',
  'gacchi',
  'gachi',
  'gai',
  'gaibu',
  'gaien',
  'gaijin',
  'gaiju',
  'gaika',
  'gaikan',
  'gaiken',
  'gaiki',
  'gaikou',
  'gaimen',
  'gaimu',
  'gainen',
  'gairai',
  'gairo',
  'gairon',
  'gaisan',
  'gaisha',
  'gaishi',
  'gaitou',
  'gaiya',
  'gaiyou',
  'gaiyuu',
  'gaka',
  'gake',
  'gakka',
  'gakkai',
  'gakki',
  'gakkou',
  'gakku',
  'gaku',
  'gakubu',
  'gakuen',
  'gakufu',
  'gakuhi',
  'gakui',
  'gakuin',
  'gakuto',
  'gakuya',
  'gaman',
  'gamen',
  'gan',
  'ganban',
  'ganbou',
  'gangan',
  'gangu',
  'ganjou',
  'ganka',
  'ganko',
  'ganmen',
  'ganmou',
  'gannen',
  'ganpon',
  'ganrai',
  'gansho',
  'ganso',
  'gansou',
  'gantan',
  'ganyuu',
  'gappei',
  'gappi',
  'gara',
  'garasu',
  'garou',
  'gashi',
  'gashou',
  'gashuu',
  'gassou',
  'gasu',
  'gata',
  'gatai',
  'gawa',
  'gawari',
  'gazou',
  'gb',
  'gc',
  'gd',
  'ge',
  'gehin',
  'gei',
  'geimei',
  'geinin',
  'geinou',
  'geisha',
  'gejun',
  'geka',
  'gekai',
  'geki',
  'gekido',
  'gekiga',
  'gekika',
  'gekka',
  'gekkan',
  'gekkei',
  'gekkou',
  'gemen',
  'gen',
  'genba',
  'genbun',
  'genchi',
  'gendai',
  'gendo',
  'gendou',
  'genga',
  'gengi',
  'gengo',
  'gengou',
  'geni',
  'genji',
  'genjin',
  'genjou',
  'genjuu',
  'genka',
  'genkai',
  'genkan',
  'genkei',
  'genki',
  'genkin',
  'genkou',
  'genmai',
  'genmen',
  'genni',
  'genpon',
  'genri',
  'genron',
  'gensan',
  'gensei',
  'gensen',
  'genshi',
  'gensho',
  'genshu',
  'genso',
  'gensou',
  'gensui',
  'gentai',
  'gentan',
  'gentei',
  'genten',
  'genya',
  'genyu',
  'genyuu',
  'genzai',
  'genzei',
  'genzou',
  'geppu',
  'geraku',
  'geri',
  'gesan',
  'gesha',
  'geshi',
  'gessan',
  'gessha',
  'gesui',
  'geta',
  'geya',
  'gezan',
  'gf',
  'gg',
  'ggg',
  'gggg',
  'gh',
  'ghi',
  'gi',
  'gian',
  'gibo',
  'gichou',
  'gida',
  'gidai',
  'gifu',
  'gigi',
  'gigoku',
  'gihi',
  'gihou',
  'giin',
  'giji',
  'gijou',
  'gijuku',
  'gikai',
  'gikou',
  'gimon',
  'gimu',
  'gin',
  'ginen',
  'ginga',
  'ginkou',
  'ginmi',
  'ginou',
  'ginza',
  'gion',
  'girei',
  'giri',
  'giron',
  'gisei',
  'giseki',
  'gishi',
  'gishin',
  'gishou',
  'gisou',
  'giwaku',
  'giyuu',
  'gizou',
  'gj',
  'gk',
  'gl',
  'gm',
  'gn',
  'go',
  'gobu',
  'goei',
  'gofuku',
  'gogaku',
  'gogan',
  'gogen',
  'gogo',
  'gohan',
  'goi',
  'goji',
  'gojo',
  'gojuu',
  'gokai',
  'gokaku',
  'gokan',
  'goken',
  'goki',
  'gokoku',
  'goku',
  'gokuhi',
  'gomen',
  'gomi',
  'gomu',
  'gongo',
  'gonin',
  'goraku',
  'gorin',
  'goro',
  'gosa',
  'gosan',
  'goshi',
  'gosho',
  'gosou',
  'gote',
  'goto',
  'gotoni',
  'gou',
  'gouban',
  'gouben',
  'goudou',
  'gougi',
  'gouhan',
  'gouhei',
  'gouhi',
  'gouhou',
  'goui',
  'gouin',
  'goujou',
  'gouka',
  'goukai',
  'goukei',
  'gouken',
  'goukin',
  'goumon',
  'gourei',
  'gouri',
  'gousei',
  'gousen',
  'gousha',
  'goutou',
  'gouu',
  'goza',
  'gozen',
  'gp',
  'gq',
  'gr',
  'gs',
  'gt',
  'gu',
  'guai',
  'guchi',
  'gun',
  'gunbai',
  'gunbi',
  'gunbu',
  'gundan',
  'gunji',
  'gunjin',
  'gunju',
  'gunkan',
  'gunki',
  'gunpei',
  'gunsei',
  'gunshu',
  'guntai',
  'guntou',
  'gunyou',
  'gunzou',
  'gurai',
  'gururi',
  'gushou',
  'gutai',
  'guusuu',
  'guuzen',
  'guuzou',
  'gv',
  'gw',
  'gx',
  'gy',
  'gyaku',
  'gyoba',
  'gyoen',
  'gyojou',
  'gyokou',
  'gyomin',
  'gyorui',
  'gyosen',
  'gyoson',
  'gyotto',
  'gyou',
  'gyougi',
  'gyouji',
  'gyouko',
  'gyoumu',
  'gyouza',
  'gyutto',
  'gyuu',
  'gyuuho',
  'gz',
  'h',
  'haaku',
  'haba',
  'habamu',
  'habuku',
  'hachi',
  'hachou',
  'hada',
  'hadae',
  'hadagi',
  'hadaka',
  'hade',
  'hadome',
  'hae',
  'haeru',
  'hagaki',
  'hagane',
  'hagasu',
  'hage',
  'hagemi',
  'hagemu',
  'hageru',
  'hagi',
  'hagire',
  'hagu',
  'haha',
  'hahei',
  'hahen',
  'hai',
  'haian',
  'haibi',
  'haibun',
  'haichi',
  'haiden',
  'haien',
  'haifu',
  'haigo',
  'haihon',
  'haiin',
  'haiiro',
  'haijin',
  'haijo',
  'haikan',
  'haikei',
  'haiken',
  'haiki',
  'haikin',
  'haiku',
  'haikyo',
  'hainin',
  'hairu',
  'hairyo',
  'haisen',
  'haisha',
  'haishi',
  'haiso',
  'haisou',
  'haisui',
  'haita',
  'haitai',
  'haitou',
  'haiyuu',
  'haizai',
  'haji',
  'hajiku',
  'hajime',
  'hajiru',
  'haka',
  'hakai',
  'hakaku',
  'hakama',
  'hakari',
  'hakaru',
  'hakase',
  'haken',
  'haki',
  'hakike',
  'hakka',
  'hakkan',
  'hakken',
  'hakki',
  'hakkou',
  'hako',
  'hakobi',
  'hakobu',
  'haku',
  'hakuai',
  'hakuba',
  'hakubo',
  'hakufu',
  'hakui',
  'hakyuu',
  'hama',
  'hamabe',
  'hamaru',
  'hame',
  'hameru',
  'hamon',
  'hamono',
  'han',
  'hana',
  'hanabi',
  'hanami',
  'hanasu',
  'hanawa',
  'hanbai',
  'hanbei',
  'hanbou',
  'hanbun',
  'handan',
  'handou',
  'hane',
  'haneru',
  'hanga',
  'hangen',
  'hanhan',
  'hanji',
  'hanjou',
  'hankan',
  'hankei',
  'hanken',
  'hanki',
  'hanko',
  'hankou',
  'hanmei',
  'hanmen',
  'hanne',
  'hannen',
  'hannin',
  'hannou',
  'hanpa',
  'hanran',
  'hanrei',
  'hanron',
  'hansei',
  'hansen',
  'hansha',
  'hanshu',
  'hansou',
  'hansuu',
  'hantai',
  'hantei',
  'hanten',
  'hantou',
  'hanyou',
  'hanzai',
  'haori',
  'happa',
  'happou',
  'hara',
  'harai',
  'haramu',
  'haran',
  'harau',
  'hare',
  'hareru',
  'hari',
  'haru',
  'haruka',
  'hasami',
  'hasamu',
  'hasan',
  'hasei',
  'hasha',
  'hashi',
  'hashu',
  'hason',
  'hassan',
  'hassei',
  'hassha',
  'hassou',
  'hasu',
  'hata',
  'hatake',
  'hataki',
  'hataku',
  'hatan',
  'hatasu',
  'hate',
  'hato',
  'hatsu',
  'hatten',
  'hau',
  'haya',
  'hayai',
  'hayaku',
  'hayane',
  'hayari',
  'hayaru',
  'hayasa',
  'hayase',
  'hayasu',
  'hazu',
  'hazumi',
  'hazumu',
  'hazure',
  'hazusu',
  'hb',
  'hc',
  'hd',
  'he',
  'hebi',
  'hedate',
  'hegasu',
  'hegu',
  'hei',
  'heian',
  'heibon',
  'heichi',
  'heieki',
  'heigai',
  'heigin',
  'heigou',
  'heihou',
  'heiin',
  'heiji',
  'heijou',
  'heika',
  'heikai',
  'heiki',
  'heikin',
  'heikou',
  'heimen',
  'heinen',
  'heion',
  'heisa',
  'heisei',
  'heisha',
  'heishi',
  'heitai',
  'heiten',
  'heiwa',
  'heiya',
  'heiyou',
  'heki',
  'hekiga',
  'hekomi',
  'hekomu',
  'hemi',
  'hen',
  'henbou',
  'hencho',
  'hendou',
  'henja',
  'henji',
  'henjou',
  'henjuu',
  'henka',
  'henkan',
  'henkei',
  'henken',
  'henkou',
  'hensai',
  'hensei',
  'hensen',
  'hensha',
  'hentou',
  'henyou',
  'henzou',
  'herasu',
  'heru',
  'heso',
  'heta',
  'heya',
  'hf',
  'hg',
  'hh',
  'hhh',
  'hhhh',
  'hi',
  'hiai',
  'hibaku',
  'hibana',
  'hibari',
  'hibi',
  'hibiki',
  'hibiku',
  'hibun',
  'hidai',
  'hidane',
  'hidari',
  'hidoi',
  'hidori',
  'hiduke',
  'hie',
  'hieru',
  'hifu',
  'hifuka',
  'higai',
  'higami',
  'higamu',
  'higan',
  'higata',
  'hige',
  'higeki',
  'higoro',
  'higure',
  'hihan',
  'hihei',
  'hihyou',
  'hiiki',
  'hij',
  'hiji',
  'hijou',
  'hijun',
  'hijuu',
  'hikae',
  'hikage',
  'hikaku',
  'hikan',
  'hikari',
  'hikaru',
  'hikazu',
  'hikeme',
  'hiki',
  'hikiai',
  'hikki',
  'hikoku',
  'hikou',
  'hiku',
  'hikui',
  'hikyou',
  'hikyuu',
  'hima',
  'himan',
  'hime',
  'himei',
  'himen',
  'himeru',
  'himo',
  'hin',
  'hina',
  'hinaka',
  'hinan',
  'hinata',
  'hindo',
  'hineru',
  'hiniku',
  'hinin',
  'hinkon',
  'hinmei',
  'hinode',
  'hinome',
  'hinpan',
  'hinpu',
  'hinsei',
  'hinshi',
  'hinshu',
  'hirabi',
  'hiraki',
  'hiraku',
  'hirei',
  'hiroba',
  'hiroi',
  'hirosa',
  'hirou',
  'hiru',
  'hiruge',
  'hiruko',
  'hiruma',
  'hirune',
  'hiryou',
  'hisai',
  'hisan',
  'hisha',
  'hisho',
  'hisoka',
  'hisomu',
  'hisou',
  'hissha',
  'hisshi',
  'hissu',
  'hitai',
  'hitaru',
  'hitasu',
  'hitei',
  'hito',
  'hitode',
  'hitoe',
  'hitohi',
  'hitome',
  'hitomi',
  'hitori',
  'hitoya',
  'hitoyo',
  'hitsuu',
  'hittou',
  'hiwa',
  'hiyake',
  'hiyaku',
  'hiyasu',
  'hiyoko',
  'hiyori',
  'hiyou',
  'hiyu',
  'hiza',
  'hizumu',
  'hj',
  'hk',
  'hl',
  'hm',
  'hn',
  'ho',
  'hoan',
  'hobo',
  'hobune',
  'hochou',
  'hodo',
  'hodoku',
  'hodou',
  'hoeru',
  'hogei',
  'hogo',
  'hohei',
  'hoho',
  'hoiku',
  'hoji',
  'hojo',
  'hojuu',
  'hoka',
  'hokaku',
  'hokan',
  'hokani',
  'hoken',
  'hokkai',
  'hokori',
  'hokoru',
  'hokou',
  'hokubu',
  'hokui',
  'hokuou',
  'hokuro',
  'hokyou',
  'hokyuu',
  'homeru',
  'homura',
  'hon',
  'honba',
  'honban',
  'honbu',
  'honbun',
  'hondo',
  'hondou',
  'hone',
  'hongan',
  'honja',
  'honkan',
  'honke',
  'honken',
  'honki',
  'honkon',
  'honkyo',
  'honmei',
  'honmon',
  'honne',
  'honnen',
  'honnin',
  'honno',
  'honnou',
  'honoka',
  'honoo',
  'honpou',
  'honrai',
  'honran',
  'honrui',
  'honsen',
  'honsha',
  'honshi',
  'honsho',
  'honsuu',
  'hontai',
  'honten',
  'honto',
  'hontou',
  'honya',
  'honzan',
  'hoo',
  'hoon',
  'hoppou',
  'hora',
  'horeru',
  'hori',
  'horie',
  'horu',
  'horyo',
  'horyuu',
  'hosa',
  'hosei',
  'hoshi',
  'hoshii',
  'hoshou',
  'hoshu',
  'hoshuu',
  'hosoi',
  'hosoku',
  'hosou',
  'hossa',
  'hosu',
  'hosuru',
  'hotaru',
  'hotoke',
  'hotori',
  'hottan',
  'hotto',
  'hou',
  'houan',
  'houbei',
  'houbi',
  'houbou',
  'houbun',
  'houchi',
  'houdai',
  'houdan',
  'houdou',
  'houei',
  'houfu',
  'houga',
  'hougai',
  'hougen',
  'houhou',
  'houi',
  'houjin',
  'houka',
  'houkai',
  'houki',
  'houko',
  'houkou',
  'houmen',
  'houmon',
  'houmu',
  'hounin',
  'houou',
  'hourei',
  'hourou',
  'houru',
  'housei',
  'housha',
  'houshi',
  'housou',
  'housui',
  'houtai',
  'houtei',
  'houwa',
  'houyou',
  'hoyou',
  'hoyuu',
  'hozen',
  'hozo',
  'hozon',
  'hp',
  'hq',
  'hr',
  'hs',
  'ht',
  'hu',
  'hv',
  'hw',
  'hx',
  'hy',
  'hyaku',
  'hyou',
  'hyouga',
  'hyougi',
  'hyougo',
  'hyouji',
  'hyouka',
  'hyouki',
  'hyouri',
  'hz',
  'i',
  'ian',
  'ib',
  'ibaru',
  'ibasho',
  'ibiki',
  'ibuki',
  'ibun',
  'ibutsu',
  'ic',
  'icchi',
  'icchou',
  'ichi',
  'ichiba',
  'ichibu',
  'ichida',
  'ichido',
  'ichien',
  'ichigi',
  'ichigo',
  'ichii',
  'ichiin',
  'ichiji',
  'ichijo',
  'ichini',
  'ichiou',
  'ichiya',
  'ichiza',
  'ichizu',
  'ichou',
  'id',
  'idai',
  'idaku',
  'iden',
  'ido',
  'idoko',
  'idou',
  'ie',
  'iede',
  'iegara',
  'iei',
  'iemoto',
  'if',
  'ifuku',
  'ig',
  'igai',
  'igaku',
  'igamu',
  'ige',
  'igen',
  'igi',
  'igo',
  'igon',
  'igyou',
  'ih',
  'ihan',
  'ihen',
  'ihin',
  'ihou',
  'ihyou',
  'ii',
  'iibun',
  'iidasu',
  'iie',
  'iii',
  'iiii',
  'iikata',
  'iikiru',
  'iin',
  'iinari',
  'iinkai',
  'iiwake',
  'iiya',
  'ij',
  'iji',
  'ijime',
  'ijin',
  'ijiru',
  'ijk',
  'ijou',
  'ijuu',
  'ik',
  'ika',
  'ikaga',
  'ikaku',
  'ikan',
  'ikani',
  'ikari',
  'ikaru',
  'ikasu',
  'ike',
  'iken',
  'ikenai',
  'iki',
  'ikichi',
  'ikigai',
  'ikiiki',
  'ikiji',
  'ikiki',
  'ikinai',
  'ikioi',
  'ikiru',
  'ikiume',
  'ikka',
  'ikkai',
  'ikkaku',
  'ikkan',
  'ikke',
  'ikken',
  'ikki',
  'ikkini',
  'ikko',
  'ikkoku',
  'ikkou',
  'ikkyo',
  'ikkyuu',
  'ikoi',
  'ikoku',
  'ikotsu',
  'ikou',
  'iku',
  'ikubun',
  'ikudo',
  'ikuei',
  'ikuji',
  'ikura',
  'ikusa',
  'ikusei',
  'ikutsu',
  'ikyoku',
  'il',
  'im',
  'ima',
  'imada',
  'imani',
  'imaya',
  'imei',
  'imi',
  'imiai',
  'imin',
  'imo',
  'imouto',
  'imyou',
  'in',
  'ina',
  'inada',
  'inai',
  'inaka',
  'inbou',
  'inchou',
  'ine',
  'inga',
  'inin',
  'inkan',
  'inki',
  'innai',
  'innen',
  'inochi',
  'inori',
  'inoru',
  'inryou',
  'inseki',
  'inshi',
  'inshou',
  'inshu',
  'intai',
  'inu',
  'inyou',
  'inzuu',
  'io',
  'iori',
  'iou',
  'ip',
  'ippa',
  'ippai',
  'ippaku',
  'ippan',
  'ippen',
  'ippi',
  'ippin',
  'ippo',
  'ippon',
  'ippou',
  'ippuu',
  'ippyou',
  'iq',
  'ir',
  'irae',
  'irai',
  'iraira',
  'ireba',
  'irei',
  'irekae',
  'ireru',
  'iri',
  'iro',
  'iroai',
  'iroha',
  'iroiro',
  'iroke',
  'iron',
  'ironna',
  'iru',
  'irui',
  'iryoku',
  'iryou',
  'is',
  'isaku',
  'isan',
  'isaru',
  'isei',
  'iseki',
  'isetan',
  'isha',
  'ishi',
  'ishiki',
  'ishin',
  'isho',
  'ishoku',
  'ishou',
  'ishuku',
  'iso',
  'isogi',
  'isogu',
  'isoide',
  'isoiso',
  'ison',
  'isou',
  'issai',
  'isse',
  'issei',
  'isseki',
  'issen',
  'isshin',
  'issho',
  'isshou',
  'isshu',
  'isshun',
  'isshuu',
  'isso',
  'issoku',
  'issou',
  'isu',
  'it',
  'ita',
  'itade',
  'itai',
  'itaku',
  'itami',
  'itamu',
  'itan',
  'itaru',
  'itasu',
  'itatte',
  'iten',
  'ito',
  'itoko',
  'itoma',
  'itsu',
  'itsuka',
  'itsumo',
  'ittai',
  'ittan',
  'itte',
  'ittei',
  'itten',
  'ittoki',
  'ittou',
  'ittsuu',
  'iu',
  'iv',
  'iw',
  'iwa',
  'iwaba',
  'iwai',
  'iwakan',
  'iwama',
  'iwao',
  'iwashi',
  'iwau',
  'ix',
  'iy',
  'iya',
  'iyada',
  'iyaiya',
  'iyake',
  'iyaki',
  'iyaku',
  'iyaoi',
  'iyoiyo',
  'iyoku',
  'iyou',
  'iz',
  'izanai',
  'izanau',
  'izen',
  'izoku',
  'izon',
  'izuchi',
  'izuko',
  'izuku',
  'izumi',
  'izure',
  'izushi',
  'j',
  'jaa',
  'jakkan',
  'jaku',
  'jama',
  'janome',
  'jareru',
  'jari',
  'jb',
  'jc',
  'jd',
  'je',
  'jf',
  'jg',
  'jh',
  'ji',
  'jiai',
  'jiba',
  'jiban',
  'jibika',
  'jibiki',
  'jibun',
  'jibyou',
  'jicchi',
  'jichi',
  'jichou',
  'jidai',
  'jidan',
  'jiden',
  'jidou',
  'jidume',
  'jiei',
  'jifu',
  'jiga',
  'jigen',
  'jigo',
  'jigoku',
  'jigyou',
  'jihaku',
  'jihan',
  'jihen',
  'jihi',
  'jihou',
  'jihyou',
  'jii',
  'jiin',
  'jiisan',
  'jiji',
  'jijo',
  'jijou',
  'jijuu',
  'jika',
  'jikai',
  'jikaku',
  'jikan',
  'jikani',
  'jikata',
  'jikei',
  'jiken',
  'jiki',
  'jikini',
  'jikka',
  'jikkan',
  'jikkei',
  'jikken',
  'jikkou',
  'jiko',
  'jikoku',
  'jikou',
  'jiku',
  'jikyuu',
  'jimae',
  'jimaku',
  'jiman',
  'jimei',
  'jimen',
  'jimi',
  'jimin',
  'jimon',
  'jimoto',
  'jimu',
  'jimuin',
  'jin',
  'jinan',
  'jinbun',
  'jinchi',
  'jindou',
  'jinguu',
  'jinin',
  'jinja',
  'jinji',
  'jinken',
  'jinkou',
  'jinmei',
  'jinmin',
  'jinmon',
  'jinrui',
  'jinsai',
  'jinsei',
  'jinsen',
  'jinshu',
  'jintai',
  'jintou',
  'jinyou',
  'jinzai',
  'jinzou',
  'jippi',
  'jirai',
  'jirei',
  'jiriki',
  'jiron',
  'jiryuu',
  'jisa',
  'jisaku',
  'jisan',
  'jisei',
  'jiseki',
  'jisha',
  'jishin',
  'jisho',
  'jishou',
  'jishu',
  'jishuu',
  'jisoku',
  'jissai',
  'jissei',
  'jissen',
  'jisshi',
  'jissou',
  'jissuu',
  'jisu',
  'jisui',
  'jisuu',
  'jita',
  'jitai',
  'jitaku',
  'jiten',
  'jitsu',
  'jittai',
  'jittei',
  'jitto',
  'jiyuu',
  'jizai',
  'jizen',
  'jizoku',
  'jizou',
  'jj',
  'jjj',
  'jjjj',
  'jk',
  'jkl',
  'jl',
  'jm',
  'jn',
  'jo',
  'joban',
  'joen',
  'jogai',
  'jogen',
  'jogon',
  'joji',
  'jojo',
  'jojou',
  'jokin',
  'jokou',
  'jokun',
  'jokyo',
  'jomei',
  'joou',
  'joryuu',
  'josei',
  'joshi',
  'joshin',
  'joshu',
  'josou',
  'jou',
  'jouba',
  'joubu',
  'joubun',
  'joucho',
  'joudan',
  'jouden',
  'joudo',
  'jouei',
  'jouen',
  'jougai',
  'jouge',
  'jougen',
  'jougi',
  'jouhin',
  'jouho',
  'jouhou',
  'joui',
  'jouin',
  'jouji',
  'joujou',
  'joujun',
  'jouka',
  'joukan',
  'joukei',
  'jouken',
  'jouki',
  'joukin',
  'joukou',
  'joukuu',
  'joumon',
  'joumu',
  'jounai',
  'jounin',
  'jourei',
  'jouren',
  'jousei',
  'jousha',
  'joushi',
  'jousho',
  'joushu',
  'jousou',
  'jousui',
  'joutai',
  'joute',
  'jouto',
  'joutou',
  'jouyou',
  'jouzou',
  'jouzu',
  'joyaku',
  'joyuu',
  'jp',
  'jq',
  'jr',
  'js',
  'jt',
  'ju',
  'juchuu',
  'judaku',
  'jueki',
  'jugyou',
  'juju',
  'jukei',
  'juken',
  'jukou',
  'juku',
  'jukugo',
  'jukyou',
  'jukyuu',
  'jumoku',
  'jumyou',
  'jun',
  'junan',
  'junban',
  'junbi',
  'jundo',
  'junji',
  'junjo',
  'junjou',
  'junkai',
  'junkan',
  'junkin',
  'junkou',
  'junnen',
  'junni',
  'junrei',
  'junro',
  'junsa',
  'junshi',
  'junshu',
  'junsui',
  'juntou',
  'juraku',
  'jurei',
  'juri',
  'juryou',
  'jusei',
  'jushi',
  'jushin',
  'jushou',
  'jutaku',
  'jutsu',
  'juu',
  'juubun',
  'juudai',
  'juudan',
  'juuden',
  'juudo',
  'juudou',
  'juugo',
  'juugun',
  'juuhou',
  'juui',
  'juuji',
  'juuka',
  'juuki',
  'juukou',
  'juukyo',
  'juuman',
  'juumen',
  'juumin',
  'juunan',
  'juuni',
  'juunin',
  'juuoku',
  'juuou',
  'juurai',
  'juusan',
  'juusei',
  'juusen',
  'juushi',
  'juusho',
  'juusou',
  'juutai',
  'juutan',
  'juuten',
  'juutou',
  'juuyon',
  'juuyou',
  'juuyu',
  'juwaki',
  'juyo',
  'juyou',
  'juzou',
  'jv',
  'jw',
  'jx',
  'jy',
  'jz',
  'k',
  'kaasan',
  'kaatsu',
  'kaban',
  'kabau',
  'kabe',
  'kaben',
  'kabi',
  'kabin',
  'kabu',
  'kabuka',
  'kabuki',
  'kabure',
  'kaburi',
  'kaburu',
  'kabuto',
  'kachi',
  'kachin',
  'kachou',
  'kachuu',
  'kadai',
  'kadan',
  'kaden',
  'kado',
  'kadou',
  'kae',
  'kaen',
  'kaeri',
  'kaeru',
  'kaesu',
  'kaette',
  'kafu',
  'kafun',
  'kagaku',
  'kagami',
  'kagan',
  'kage',
  'kageki',
  'kagen',
  'kageri',
  'kagi',
  'kagiri',
  'kagiru',
  'kago',
  'kagon',
  'kagou',
  'kagu',
  'kagura',
  'kagyou',
  'kahei',
  'kahi',
  'kahou',
  'kai',
  'kaiage',
  'kaibou',
  'kaidan',
  'kaidou',
  'kaien',
  'kaiga',
  'kaigai',
  'kaigan',
  'kaigi',
  'kaigo',
  'kaigou',
  'kaigun',
  'kaihei',
  'kaihen',
  'kaihi',
  'kaihin',
  'kaihou',
  'kaii',
  'kaiiki',
  'kaiin',
  'kaiire',
  'kaiji',
  'kaijo',
  'kaijou',
  'kaijuu',
  'kaika',
  'kaikai',
  'kaikan',
  'kaikei',
  'kaiken',
  'kaiki',
  'kaikin',
  'kaiko',
  'kaikou',
  'kaikyo',
  'kaimei',
  'kaimen',
  'kaimu',
  'kain',
  'kainan',
  'kainin',
  'kairo',
  'kairou',
  'kairu',
  'kaisai',
  'kaisan',
  'kaisei',
  'kaisen',
  'kaisha',
  'kaishi',
  'kaisho',
  'kaiso',
  'kaisou',
  'kaisui',
  'kaisuu',
  'kaitai',
  'kaite',
  'kaitei',
  'kaiten',
  'kaitou',
  'kaiun',
  'kaiwa',
  'kaiyou',
  'kaizai',
  'kaizan',
  'kaizen',
  'kaizou',
  'kaji',
  'kajin',
  'kajou',
  'kaju',
  'kajuu',
  'kakae',
  'kakaku',
  'kakan',
  'kakari',
  'kakaru',
  'kakasu',
  'kakato',
  'kake',
  'kakei',
  'kakera',
  'kakeru',
  'kaki',
  'kakiko',
  'kakine',
  'kakkai',
  'kakkan',
  'kakki',
  'kakko',
  'kakkou',
  'kako',
  'kakoi',
  'kakoku',
  'kakomi',
  'kakomu',
  'kakou',
  'kaku',
  'kakudo',
  'kakugi',
  'kakugo',
  'kakuho',
  'kakui',
  'kakuin',
  'kakuji',
  'kakuri',
  'kakusa',
  'kakusu',
  'kakuu',
  'kakyou',
  'kakyuu',
  'kama',
  'kamau',
  'kame',
  'kamei',
  'kamen',
  'kami',
  'kamiki',
  'kamo',
  'kamoku',
  'kamu',
  'kamuri',
  'kamuru',
  'kan',
  'kana',
  'kanaa',
  'kanagu',
  'kanai',
  'kaname',
  'kanari',
  'kanata',
  'kanau',
  'kanbai',
  'kanban',
  'kanbou',
  'kanbu',
  'kanbun',
  'kanchi',
  'kandai',
  'kando',
  'kandou',
  'kane',
  'kaneai',
  'kaneru',
  'kanete',
  'kangae',
  'kangai',
  'kangei',
  'kangen',
  'kango',
  'kani',
  'kanja',
  'kanji',
  'kanjin',
  'kanjou',
  'kanka',
  'kankai',
  'kankei',
  'kanki',
  'kankin',
  'kankou',
  'kanku',
  'kanmei',
  'kanmi',
  'kanmin',
  'kanmon',
  'kannai',
  'kannen',
  'kannon',
  'kannou',
  'kano',
  'kanojo',
  'kanou',
  'kanpa',
  'kanpai',
  'kanpan',
  'kanpou',
  'kanpu',
  'kanpuu',
  'kanrei',
  'kanren',
  'kanri',
  'kansa',
  'kansai',
  'kansan',
  'kansei',
  'kansen',
  'kansha',
  'kanshi',
  'kanso',
  'kansou',
  'kantai',
  'kantan',
  'kantei',
  'kanten',
  'kanton',
  'kantou',
  'kanwa',
  'kanyo',
  'kanyou',
  'kanyuu',
  'kanzai',
  'kanzan',
  'kanzei',
  'kanzen',
  'kanzou',
  'kao',
  'kaoiro',
  'kaoku',
  'kaori',
  'kaoru',
  'kaou',
  'kara',
  'karada',
  'karai',
  'karami',
  'karamu',
  'karasu',
  'karate',
  'kare',
  'kareha',
  'karei',
  'kareki',
  'karera',
  'kareru',
  'kari',
  'kariie',
  'karina',
  'karini',
  'kariru',
  'kariya',
  'karoi',
  'karou',
  'karu',
  'karui',
  'karyuu',
  'kasa',
  'kasai',
  'kasaku',
  'kasan',
  'kasegi',
  'kasegu',
  'kasei',
  'kaseki',
  'kasen',
  'kasha',
  'kashi',
  'kashin',
  'kasho',
  'kashou',
  'kashu',
  'kashuu',
  'kaso',
  'kasoku',
  'kasou',
  'kassei',
  'kassen',
  'kasshi',
  'kassou',
  'kassui',
  'kasu',
  'kasuka',
  'kasumi',
  'kasumu',
  'kasuru',
  'kata',
  'katagi',
  'katai',
  'kataku',
  'katame',
  'katami',
  'katan',
  'katana',
  'katari',
  'kataru',
  'katasa',
  'katate',
  'katei',
  'katen',
  'kato',
  'katoki',
  'katou',
  'katsu',
  'katte',
  'katto',
  'kattou',
  'kau',
  'kawa',
  'kawaii',
  'kawaki',
  'kawaku',
  'kawara',
  'kawari',
  'kawaru',
  'kawase',
  'kawasu',
  'kawazu',
  'kaya',
  'kayaku',
  'kayoi',
  'kayou',
  'kayu',
  'kayui',
  'kazai',
  'kazan',
  'kazari',
  'kazaru',
  'kaze',
  'kazei',
  'kazoku',
  'kazu',
  'kazura',
  'kb',
  'kc',
  'kd',
  'ke',
  'kebu',
  'keburi',
  'keburu',
  'kedo',
  'kedomo',
  'kega',
  'kegasu',
  'kegawa',
  'kehai',
  'kei',
  'keiba',
  'keibai',
  'keibi',
  'keibu',
  'keidai',
  'keido',
  'keiei',
  'keien',
  'keifu',
  'keigen',
  'keigo',
  'keigu',
  'keihan',
  'keihi',
  'keihin',
  'keihou',
  'keii',
  'keiji',
  'keijou',
  'keika',
  'keikai',
  'keikan',
  'keiken',
  'keiki',
  'keiko',
  'keikou',
  'keimou',
  'keiri',
  'keirin',
  'keiro',
  'keirou',
  'keisai',
  'keisan',
  'keisei',
  'keisha',
  'keishi',
  'keisou',
  'keitai',
  'keitei',
  'keito',
  'keitou',
  'keiyou',
  'keiyu',
  'keizai',
  'kejime',
  'kekka',
  'kekkan',
  'kekki',
  'kekkon',
  'kekkou',
  'kemono',
  'kemu',
  'kemui',
  'kemuri',
  'kemuru',
  'kemyou',
  'ken',
  'kenage',
  'kenasu',
  'kenbun',
  'kenchi',
  'kencho',
  'kendou',
  'kenen',
  'kengai',
  'kengen',
  'kengi',
  'kenji',
  'kenjin',
  'kenjou',
  'kenka',
  'kenkai',
  'kenkei',
  'kenki',
  'kenkin',
  'kenkou',
  'kenkyo',
  'kenmei',
  'kenmin',
  'kenmon',
  'kenmu',
  'kennai',
  'kennin',
  'kenpo',
  'kenpou',
  'kenri',
  'kensa',
  'kensei',
  'kenshi',
  'kenson',
  'kensuu',
  'kentei',
  'kentou',
  'kenzai',
  'kenzen',
  'kenzou',
  'keraku',
  'keredo',
  'keru',
  'kesa',
  'keshou',
  'kesou',
  'kessai',
  'kessan',
  'kessei',
  'kessen',
  'kessha',
  'kesson',
  'kesu',
  'keta',
  'ketoba',
  'ketsu',
  'ketsui',
  'kettei',
  'ketten',
  'kettou',
  'kewai',
  'kezuru',
  'kf',
  'kg',
  'kh',
  'ki',
  'kiai',
  'kiatsu',
  'kiba',
  'kibaku',
  'kiban',
  'kibin',
  'kibisu',
  'kibo',
  'kibou',
  'kibun',
  'kichi',
  'kichou',
  'kido',
  'kidoru',
  'kidou',
  'kiduku',
  'kiduna',
  'kiei',
  'kieru',
  'kifu',
  'kifuku',
  'kiga',
  'kigae',
  'kigai',
  'kigan',
  'kigane',
  'kigaru',
  'kigeki',
  'kigen',
  'kigi',
  'kigo',
  'kigou',
  'kigu',
  'kigyou',
  'kihai',
  'kihaku',
  'kihan',
  'kihon',
  'kii',
  'kiin',
  'kiiro',
  'kiiroi',
  'kiji',
  'kijiku',
  'kijin',
  'kijou',
  'kijun',
  'kika',
  'kikai',
  'kikaku',
  'kikan',
  'kikasu',
  'kiken',
  'kiki',
  'kikime',
  'kikin',
  'kikite',
  'kikoku',
  'kikon',
  'kikou',
  'kiku',
  'kikyou',
  'kikyuu',
  'kimae',
  'kimama',
  'kimari',
  'kimaru',
  'kime',
  'kimeru',
  'kimete',
  'kimi',
  'kimo',
  'kimono',
  'kimyou',
  'kin',
  'kinben',
  'kincho',
  'kindai',
  'kinen',
  'kingan',
  'kingyo',
  'kinjo',
  'kinka',
  'kinkai',
  'kinken',
  'kinki',
  'kinkin',
  'kinko',
  'kinkou',
  'kinku',
  'kinmu',
  'kinnen',
  'kinoha',
  'kinoko',
  'kinomi',
  'kinou',
  'kinpen',
  'kinpin',
  'kinri',
  'kinrin',
  'kinrou',
  'kinsei',
  'kinsen',
  'kinshi',
  'kinshu',
  'kinsu',
  'kintou',
  'kinu',
  'kinyou',
  'kinyu',
  'kinyuu',
  'kioi',
  'kioku',
  'kion',
  'kippu',
  'kirai',
  'kiraku',
  'kirasu',
  'kirau',
  'kire',
  'kirei',
  'kireji',
  'kireme',
  'kireru',
  'kiri',
  'kirin',
  'kiro',
  'kiroku',
  'kiru',
  'kisai',
  'kisei',
  'kiseki',
  'kisen',
  'kiseru',
  'kisha',
  'kishi',
  'kishin',
  'kishou',
  'kishu',
  'kishuu',
  'kiso',
  'kisoku',
  'kison',
  'kisou',
  'kissa',
  'kisu',
  'kisuu',
  'kita',
  'kitai',
  'kitaku',
  'kitaru',
  'kitei',
  'kiteki',
  'kiten',
  'kito',
  'kitoku',
  'kitou',
  'kitsu',
  'kitsui',
  'kitte',
  'kitto',
  'kiun',
  'kiwata',
  'kiyaku',
  'kiyo',
  'kiyoi',
  'kiyou',
  'kiza',
  'kizai',
  'kizami',
  'kizamu',
  'kizoku',
  'kizou',
  'kizu',
  'kizuku',
  'kizuna',
  'kj',
  'kk',
  'kkk',
  'kkkk',
  'kl',
  'klm',
  'km',
  'kn',
  'ko',
  'koame',
  'kobamu',
  'kobito',
  'kobosu',
  'kobu',
  'kocchi',
  'kochi',
  'kochou',
  'kodai',
  'kodoku',
  'kodomo',
  'koduka',
  'koe',
  'koeru',
  'kofun',
  'kogai',
  'kogane',
  'kogata',
  'kogeru',
  'kogoe',
  'kogu',
  'kohaba',
  'kohan',
  'kohon',
  'koi',
  'koitsu',
  'koji',
  'kojiin',
  'kojiki',
  'kojima',
  'kojin',
  'kokaku',
  'kokei',
  'kokka',
  'kokkai',
  'kokkei',
  'kokken',
  'kokki',
  'kokko',
  'kokkou',
  'koko',
  'kokoku',
  'kokon',
  'kokono',
  'kokoro',
  'koku',
  'kokudo',
  'kokue',
  'kokuei',
  'kokuen',
  'kokugi',
  'kokugo',
  'kokuhi',
  'kokui',
  'kokuji',
  'kokumu',
  'kokuou',
  'kokuso',
  'kokuze',
  'kokyou',
  'kokyuu',
  'koma',
  'komaka',
  'komaru',
  'kome',
  'komeru',
  'komoji',
  'komon',
  'komono',
  'komoru',
  'komu',
  'komugi',
  'kon',
  'kona',
  'konasu',
  'konban',
  'konbu',
  'kondan',
  'kondo',
  'kondou',
  'kongen',
  'kongo',
  'kongou',
  'konjou',
  'konka',
  'konkai',
  'konkan',
  'konki',
  'konkyo',
  'konmei',
  'konna',
  'konnan',
  'konnen',
  'kono',
  'konoe',
  'konoha',
  'konomi',
  'konomu',
  'konpon',
  'konran',
  'konro',
  'konsei',
  'konsen',
  'kontei',
  'konwa',
  'konya',
  'koori',
  'kooru',
  'koou',
  'korai',
  'kore',
  'korede',
  'korera',
  'koriru',
  'koro',
  'korobu',
  'koromo',
  'korosu',
  'koru',
  'kosaji',
  'kosame',
  'kosan',
  'kosei',
  'koseki',
  'koshi',
  'kosho',
  'koshou',
  'koshu',
  'koshuu',
  'koso',
  'kosshi',
  'kosu',
  'kosui',
  'kosuru',
  'kosuu',
  'kotae',
  'kotai',
  'kotei',
  'koten',
  'koto',
  'kotoba',
  'kotoni',
  'kotori',
  'kotta',
  'kou',
  'kouan',
  'kouba',
  'koubai',
  'kouban',
  'koube',
  'koubo',
  'koubou',
  'koubu',
  'koubun',
  'koucha',
  'kouchi',
  'koudai',
  'koudan',
  'koudo',
  'koudou',
  'kouei',
  'koueki',
  'kouen',
  'koufu',
  'koufun',
  'kougai',
  'kougei',
  'kougen',
  'kougi',
  'kougo',
  'kougou',
  'kouha',
  'kouhai',
  'kouhan',
  'kouhei',
  'kouhi',
  'kouho',
  'kouhou',
  'koui',
  'kouiki',
  'kouin',
  'kouiu',
  'kouji',
  'koujin',
  'koujo',
  'koujou',
  'kouka',
  'koukai',
  'koukan',
  'koukei',
  'kouken',
  'kouki',
  'koukin',
  'kouko',
  'koukon',
  'koukou',
  'kouku',
  'koukuu',
  'koukyo',
  'koumei',
  'koumin',
  'koumon',
  'koumu',
  'kounai',
  'kounin',
  'kounou',
  'kouon',
  'kourai',
  'kourei',
  'kouri',
  'kouro',
  'kouron',
  'kourou',
  'kouryo',
  'kousa',
  'kousai',
  'kousan',
  'kousei',
  'kousen',
  'kousha',
  'koushi',
  'kousho',
  'koushu',
  'kouso',
  'kousou',
  'kousui',
  'koutai',
  'koutei',
  'kouten',
  'koutou',
  'kouu',
  'kouun',
  'kouwa',
  'kouwan',
  'kouya',
  'kouyou',
  'kouyuu',
  'kouza',
  'kouzai',
  'kouzan',
  'kouzen',
  'kouzou',
  'kouzu',
  'kouzui',
  'kowai',
  'kowasu',
  'koya',
  'koyaku',
  'koyama',
  'koyomi',
  'koyou',
  'koyubi',
  'koyuu',
  'kozo',
  'kozou',
  'kp',
  'kq',
  'kr',
  'ks',
  'kt',
  'ku',
  'kubaru',
  'kubi',
  'kubisu',
  'kubomi',
  'kubun',
  'kuchi',
  'kuchou',
  'kuda',
  'kudaku',
  'kudari',
  'kudaru',
  'kudasu',
  'kudoi',
  'kudou',
  'kufu',
  'kufuu',
  'kugane',
  'kugen',
  'kugi',
  'kugiri',
  'kugiru',
  'kuguru',
  'kui',
  'kuiki',
  'kuji',
  'kujira',
  'kujo',
  'kujou',
  'kujuu',
  'kukaku',
  'kukan',
  'kuki',
  'kuku',
  'kukumu',
  'kukuru',
  'kukyou',
  'kuma',
  'kumi',
  'kumiai',
  'kumin',
  'kumo',
  'kumori',
  'kumoru',
  'kumu',
  'kun',
  'kunai',
  'kunan',
  'kuni',
  'kunji',
  'kunou',
  'kunren',
  'kunrin',
  'kunshi',
  'kunshu',
  'kura',
  'kurabe',
  'kurabu',
  'kurai',
  'kurasu',
  'kure',
  'kureru',
  'kuri',
  'kuro',
  'kurogo',
  'kuroi',
  'kuroji',
  'kuroki',
  'kuroko',
  'kurou',
  'kuru',
  'kuruma',
  'kurumu',
  'kuruu',
  'kuryo',
  'kusa',
  'kusai',
  'kusaki',
  'kusame',
  'kusari',
  'kusaru',
  'kuse',
  'kusen',
  'kuseni',
  'kushi',
  'kushin',
  'kushuu',
  'kusshi',
  'kusuri',
  'kutsu',
  'kutsuu',
  'kuu',
  'kuubo',
  'kuuchi',
  'kuudou',
  'kuugun',
  'kuuhou',
  'kuuiki',
  'kuukan',
  'kuuki',
  'kuukou',
  'kuukyo',
  'kuuro',
  'kuusha',
  'kuusou',
  'kuuten',
  'kuuyu',
  'kuuzen',
  'kuwa',
  'kuyou',
  'kuzu',
  'kuzure',
  'kuzusu',
  'kv',
  'kw',
  'kx',
  'ky',
  'kyaku',
  'kyatsu',
  'kyodai',
  'kyogi',
  'kyohi',
  'kyojin',
  'kyojuu',
  'kyoka',
  'kyokou',
  'kyoku',
  'kyokuu',
  'kyomu',
  'kyonen',
  'kyori',
  'kyotai',
  'kyoten',
  'kyotou',
  'kyou',
  'kyoubu',
  'kyouda',
  'kyoudo',
  'kyouei',
  'kyouen',
  'kyoufu',
  'kyougi',
  'kyouho',
  'kyoui',
  'kyouin',
  'kyouju',
  'kyouka',
  'kyouki',
  'kyouko',
  'kyoumi',
  'kyoumu',
  'kyouri',
  'kyouso',
  'kyouto',
  'kyouwa',
  'kyouyo',
  'kyouyu',
  'kyoyou',
  'kyuu',
  'kyuuen',
  'kyuufu',
  'kyuugi',
  'kyuuha',
  'kyuui',
  'kyuujo',
  'kyuuka',
  'kyuumu',
  'kyuuri',
  'kyuuyo',
  'kyuuyu',
  'kz',
  'l',
  'lb',
  'lc',
  'ld',
  'le',
  'lf',
  'lg',
  'lh',
  'li',
  'lj',
  'lk',
  'll',
  'lll',
  'llll',
  'lm',
  'lmn',
  'ln',
  'lo',
  'lp',
  'lq',
  'lr',
  'ls',
  'lt',
  'lu',
  'lv',
  'lw',
  'lx',
  'ly',
  'lz',
  'm',
  'maa',
  'maajan',
  'maamaa',
  'mabara',
  'mabuta',
  'macchi',
  'machi',
  'mada',
  'made',
  'madika',
  'mado',
  'madori',
  'mae',
  'maebi',
  'maemae',
  'maeoki',
  'maeuri',
  'mafu',
  'magao',
  'magari',
  'magaru',
  'mageru',
  'magiwa',
  'mago',
  'magure',
  'maguro',
  'mahi',
  'mahou',
  'mai',
  'maiasa',
  'maiban',
  'maido',
  'maigo',
  'maikai',
  'mainen',
  'mairu',
  'maisou',
  'maisuu',
  'maizou',
  'majime',
  'majiri',
  'majiru',
  'majo',
  'makasu',
  'make',
  'makeru',
  'maki',
  'makiba',
  'makka',
  'makki',
  'makkou',
  'makoto',
  'maku',
  'makura',
  'makuru',
  'mama',
  'mame',
  'mamie',
  'mamori',
  'mamoru',
  'man',
  'manabu',
  'manaka',
  'manako',
  'mane',
  'maneki',
  'maneku',
  'maneru',
  'manga',
  'maniau',
  'manjou',
  'mankai',
  'manki',
  'manma',
  'manman',
  'manmen',
  'mannen',
  'mannin',
  'mannou',
  'manpai',
  'manrui',
  'mansei',
  'manten',
  'manuke',
  'manzai',
  'mare',
  'marobu',
  'maroi',
  'maru',
  'marude',
  'marui',
  'marumi',
  'maruta',
  'masaka',
  'masani',
  'masao',
  'masaru',
  'mashi',
  'massao',
  'masshi',
  'masu',
  'masui',
  'mata',
  'matagu',
  'mataha',
  'matane',
  'matasu',
  'mato',
  'matome',
  'matsu',
  'mattan',
  'mattou',
  'mau',
  'maue',
  'mawari',
  'mawaru',
  'mawasu',
  'mayaku',
  'mayoi',
  'mayou',
  'mayu',
  'mayuge',
  'mazaru',
  'mazeru',
  'mazu',
  'mazui',
  'mb',
  'mc',
  'md',
  'me',
  'meate',
  'meberi',
  'medama',
  'medo',
  'megami',
  'megane',
  'megumi',
  'megumu',
  'meguru',
  'mei',
  'meian',
  'meibo',
  'meicho',
  'meidai',
  'meiga',
  'meigen',
  'meigi',
  'meiji',
  'meijin',
  'meikai',
  'meiki',
  'meimei',
  'meimon',
  'meirei',
  'meiro',
  'meirou',
  'meisai',
  'meisei',
  'meishi',
  'meisho',
  'meishu',
  'meiun',
  'meiyo',
  'meiyuu',
  'mejiro',
  'mekata',
  'meku',
  'mekuru',
  'memai',
  'meme',
  'memori',
  'men',
  'menbou',
  'mendan',
  'mendou',
  'menjo',
  'menkai',
  'menkyo',
  'menmen',
  'menuki',
  'menzei',
  'meoto',
  'mesaki',
  'meshi',
  'mesu',
  'metta',
  'meue',
  'meyasu',
  'mezasu',
  'mf',
  'mg',
  'mh',
  'mi',
  'miai',
  'mibae',
  'mibun',
  'miburi',
  'michi',
  'midare',
  'midasu',
  'midika',
  'midori',
  'mie',
  'mieru',
  'migaki',
  'migaku',
  'migara',
  'migaru',
  'migi',
  'migite',
  'migoto',
  'mihari',
  'miharu',
  'mihon',
  'miihaa',
  'miiru',
  'mijime',
  'mijin',
  'mijuku',
  'mikado',
  'mikai',
  'mikake',
  'mikaku',
  'mikan',
  'mikata',
  'miki',
  'mikiri',
  'mikka',
  'mikkou',
  'mikomi',
  'mikomu',
  'mikon',
  'mimai',
  'miman',
  'mimau',
  'mimei',
  'mimi',
  'mimoto',
  'mina',
  'minage',
  'minami',
  'minamo',
  'minari',
  'minasu',
  'minato',
  'mine',
  'mingei',
  'minji',
  'minju',
  'minka',
  'minkan',
  'minna',
  'minomo',
  'minori',
  'minoru',
  'minou',
  'minoue',
  'minpei',
  'minpou',
  'minsei',
  'minshu',
  'minwa',
  'minyou',
  'mioboe',
  'mirai',
  'miren',
  'miru',
  'miryou',
  'misaki',
  'misao',
  'mise',
  'miseba',
  'miseru',
  'miso',
  'misoka',
  'misui',
  'mitai',
  'mitame',
  'mitari',
  'mitasu',
  'mitei',
  'mitome',
  'mitsu',
  'mittsu',
  'miuchi',
  'miwake',
  'miwaku',
  'miyage',
  'miyako',
  'miyama',
  'miyori',
  'mizen',
  'mizo',
  'mizu',
  'mizube',
  'mizugi',
  'mizuke',
  'mj',
  'mk',
  'ml',
  'mm',
  'mmm',
  'mmmm',
  'mn',
  'mno',
  'mo',
  'mochi',
  'mochii',
  'modori',
  'modoru',
  'modosu',
  'moeru',
  'mogaku',
  'mogami',
  'mogi',
  'mogu',
  'mohan',
  'mohara',
  'mohaya',
  'mohou',
  'moji',
  'mojiru',
  'mokei',
  'mokka',
  'mokkou',
  'mokuji',
  'momen',
  'momeru',
  'momiji',
  'momo',
  'momu',
  'mon',
  'monbu',
  'mondai',
  'mondou',
  'monji',
  'monjo',
  'monka',
  'monko',
  'monku',
  'mono',
  'monoii',
  'monono',
  'monpi',
  'monyou',
  'monzen',
  'morasu',
  'morau',
  'moreru',
  'mori',
  'moroi',
  'morote',
  'moru',
  'mosaku',
  'moshi',
  'moshu',
  'mosu',
  'mote',
  'moteru',
  'moto',
  'motoki',
  'motsu',
  'motte',
  'motto',
  'mou',
  'mouda',
  'moufu',
  'mouhi',
  'moui',
  'moujin',
  'mouke',
  'moukou',
  'moura',
  'mousho',
  'mousu',
  'mouten',
  'moyasu',
  'moyori',
  'moyou',
  'mp',
  'mq',
  'mr',
  'ms',
  'mt',
  'mu',
  'mubou',
  'mucha',
  'muchi',
  'muchuu',
  'muda',
  'mudan',
  'mueki',
  'muen',
  'mufuu',
  'mugai',
  'mugen',
  'mugi',
  'mugon',
  'muhai',
  'muhou',
  'muika',
  'muimi',
  'mujaki',
  'muji',
  'mujin',
  'mujou',
  'mujun',
  'mukae',
  'mukai',
  'mukau',
  'muke',
  'mukei',
  'mukeru',
  'muki',
  'mukizu',
  'muko',
  'mukou',
  'muku',
  'mukuu',
  'mukyuu',
  'mumei',
  'mune',
  'munen',
  'munin',
  'munou',
  'mura',
  'mure',
  'mureru',
  'muri',
  'muron',
  'muryou',
  'musaku',
  'musen',
  'musha',
  'mushi',
  'mushin',
  'mushou',
  'muso',
  'musou',
  'musu',
  'musubi',
  'musubu',
  'musuko',
  'musume',
  'musuu',
  'mutsu',
  'mutto',
  'muttsu',
  'muu',
  'muyoka',
  'muyoku',
  'muyou',
  'muyuka',
  'muzai',
  'muzan',
  'mv',
  'mw',
  'mx',
  'my',
  'myaku',
  'myou',
  'myouji',
  'myouto',
  'mz',
  'n',
  'nabe',
  'nabiku',
  'nada',
  'nadare',
  'naderu',
  'nado',
  'nae',
  'naegi',
  'nafuda',
  'nagai',
  'nagame',
  'nagara',
  'nagare',
  'nagasa',
  'nagasu',
  'nagaya',
  'nage',
  'nageki',
  'nageku',
  'nageru',
  'nagisa',
  'nagomu',
  'nagori',
  'nagoro',
  'naguru',
  'nai',
  'naibu',
  'naigai',
  'naihou',
  'naiji',
  'naijou',
  'naiju',
  'naika',
  'naikai',
  'naiki',
  'naikou',
  'naimen',
  'naimu',
  'nainai',
  'nairan',
  'naisei',
  'naisen',
  'naishi',
  'naisho',
  'naisou',
  'naitei',
  'naiya',
  'naiyou',
  'naizou',
  'naka',
  'nakaba',
  'nakama',
  'nakami',
  'naki',
  'naku',
  'nakusu',
  'nama',
  'namae',
  'namari',
  'nameru',
  'nami',
  'namida',
  'namiki',
  'nan',
  'nana',
  'naname',
  'nanaso',
  'nanbei',
  'nanben',
  'nanbu',
  'nandai',
  'nande',
  'nando',
  'nani',
  'nanika',
  'nanimo',
  'nanira',
  'nanjuu',
  'nanka',
  'nankai',
  'nankan',
  'nankin',
  'nankou',
  'nanmin',
  'nanmon',
  'nanoka',
  'nanori',
  'nanoru',
  'nanpou',
  'nanra',
  'nansei',
  'nante',
  'nanten',
  'nanto',
  'nantou',
  'nanuka',
  'nanzen',
  'nao',
  'naoru',
  'naoshi',
  'naosu',
  'nara',
  'naraba',
  'narabi',
  'narabu',
  'naranu',
  'narasu',
  'narau',
  'nare',
  'nareru',
  'nari',
  'narini',
  'naru',
  'naruto',
  'nasake',
  'nasaru',
  'nashi',
  'nasu',
  'nasubi',
  'natsu',
  'nattou',
  'nawa',
  'nayami',
  'nayamu',
  'naze',
  'nazeka',
  'nazo',
  'nb',
  'nc',
  'nd',
  'nda',
  'nde',
  'ndesu',
  'ne',
  'neage',
  'nebari',
  'nebaru',
  'nebie',
  'nebiki',
  'nebou',
  'neburu',
  'nedai',
  'nedan',
  'nedaru',
  'nedoko',
  'nee',
  'neesan',
  'negai',
  'negao',
  'negau',
  'negi',
  'negiru',
  'negoto',
  'neiki',
  'neiro',
  'nejiru',
  'nekasu',
  'nekki',
  'nekko',
  'neko',
  'nekoze',
  'nemaki',
  'nemoto',
  'nemui',
  'nemuke',
  'nemuri',
  'nemuru',
  'nen',
  'nendai',
  'nendo',
  'nenga',
  'nengan',
  'nengou',
  'nenji',
  'nenjuu',
  'nenkai',
  'nenkan',
  'nenkin',
  'nennai',
  'nennen',
  'nenpai',
  'nenpi',
  'nenpou',
  'nenrai',
  'nenrei',
  'nenrin',
  'nensan',
  'nensei',
  'nenshi',
  'nensho',
  'nensuu',
  'nentou',
  'nenza',
  'nerai',
  'nerau',
  'neri',
  'neru',
  'nesage',
  'nessen',
  'neta',
  'netamu',
  'netsu',
  'netsui',
  'nettai',
  'nettou',
  'neuchi',
  'nezasu',
  'nezou',
  'nezu',
  'nezumi',
  'nf',
  'ng',
  'nh',
  'ni',
  'niai',
  'niau',
  'niban',
  'nibu',
  'nibui',
  'nibun',
  'nidai',
  'nido',
  'nidone',
  'nieru',
  'nifuda',
  'nigai',
  'nigami',
  'nigao',
  'nigaoe',
  'nigasu',
  'nigate',
  'nige',
  'nigeru',
  'nigiri',
  'nigiru',
  'nigoru',
  'nigosu',
  'nigou',
  'nigun',
  'niguu',
  'niin',
  'niisan',
  'niji',
  'nijimu',
  'nijuu',
  'nikai',
  'niki',
  'nikibi',
  'nikka',
  'nikkan',
  'nikkei',
  'nikki',
  'nikkou',
  'nikomu',
  'niku',
  'nikui',
  'nikumu',
  'nimai',
  'nimono',
  'nin',
  'ninaru',
  'ninau',
  'ninchi',
  'ningen',
  'ninin',
  'ninjin',
  'ninjou',
  'ninka',
  'ninki',
  'ninmae',
  'ninmei',
  'ninmu',
  'ninnin',
  'nino',
  'ninpu',
  'ninsou',
  'nintai',
  'nintei',
  'ninu',
  'ninzuu',
  'nioi',
  'niou',
  'nippou',
  'niramu',
  'niru',
  'nirui',
  'niryuu',
  'nise',
  'nisei',
  'niseru',
  'nishi',
  'nissan',
  'nisshi',
  'nissuu',
  'nite',
  'nittei',
  'niwa',
  'niwaka',
  'niwaki',
  'niwari',
  'nj',
  'nk',
  'nl',
  'nm',
  'nn',
  'nnn',
  'nnnn',
  'no',
  'nobasu',
  'nobe',
  'noberu',
  'nobi',
  'nobiru',
  'nobori',
  'noboru',
  'nochi',
  'noda',
  'node',
  'nodesu',
  'nodo',
  'nodoka',
  'nohara',
  'nokeru',
  'noki',
  'nokori',
  'nokoru',
  'nokosu',
  'noku',
  'nomi',
  'nomido',
  'nomito',
  'nomu',
  'nonaka',
  'nondo',
  'noni',
  'nonki',
  'nop',
  'noren',
  'nori',
  'noriba',
  'noriki',
  'noroi',
  'noroma',
  'norou',
  'noru',
  'noseru',
  'noshi',
  'nou',
  'nouchi',
  'noudo',
  'noudou',
  'nouen',
  'noufu',
  'nouha',
  'nouhou',
  'noujou',
  'nouka',
  'noukin',
  'noukou',
  'noumin',
  'nouri',
  'nourin',
  'nousan',
  'nousei',
  'noushi',
  'nouson',
  'noutan',
  'nouzei',
  'noyama',
  'nozoku',
  'nozomi',
  'nozomu',
  'nozue',
  'np',
  'nq',
  'nr',
  'ns',
  'nt',
  'nu',
  'nugu',
  'nuguu',
  'nuime',
  'nukasu',
  'nukeru',
  'nuki',
  'nuku',
  'nukui',
  'numa',
  'numeri',
  'nuno',
  'nunoji',
  'nurasu',
  'nureru',
  'nuru',
  'nurui',
  'nushi',
  'nusumi',
  'nusumu',
  'nuu',
  'nv',
  'nw',
  'nx',
  'ny',
  'nyou',
  'nyuuen',
  'nyuuin',
  'nyuuji',
  'nyuuka',
  'nz',
  'o',
  'ob',
  'oba',
  'obake',
  'obi',
  'obiru',
  'oboe',
  'oboeru',
  'oc',
  'ocha',
  'ochi',
  'ochiau',
  'ochiba',
  'ochido',
  'ochiru',
  'od',
  'odaku',
  'odeko',
  'oden',
  'odoodo',
  'odori',
  'odoru',
  'odoshi',
  'odosu',
  'oe',
  'oeru',
  'of',
  'og',
  'ogamu',
  'ogawa',
  'ogi',
  'oginau',
  'ogoru',
  'oh',
  'ohako',
  'ohayou',
  'oheso',
  'oi',
  'oidasu',
  'oikaze',
  'oikomi',
  'oikomu',
  'oikosu',
  'oime',
  'oinuku',
  'oiru',
  'oishii',
  'oite',
  'oiuchi',
  'oiwai',
  'oj',
  'oji',
  'ojigi',
  'ok',
  'oka',
  'okaeri',
  'okan',
  'okane',
  'okashi',
  'okasu',
  'okazu',
  'oke',
  'oki',
  'okiai',
  'okiba',
  'okina',
  'okiru',
  'okite',
  'okkake',
  'okkii',
  'okonai',
  'okonau',
  'okoru',
  'okosu',
  'oku',
  'okuchi',
  'okugai',
  'okujou',
  'okunai',
  'okure',
  'okuri',
  'okuru',
  'okusan',
  'ol',
  'om',
  'omae',
  'omai',
  'omairi',
  'omake',
  'omanma',
  'omee',
  'omei',
  'omo',
  'omocha',
  'omode',
  'omoi',
  'omoide',
  'omoki',
  'omomi',
  'omoni',
  'omoru',
  'omosa',
  'omoshi',
  'omotai',
  'omote',
  'omou',
  'omutsu',
  'on',
  'onago',
  'onaji',
  'onaka',
  'onara',
  'onbu',
  'onchuu',
  'ondan',
  'ondo',
  'ondoku',
  'one',
  'ongaku',
  'oni',
  'onjin',
  'onjou',
  'onkei',
  'onken',
  'onkou',
  'onkyou',
  'onna',
  'onnaji',
  'onoono',
  'onore',
  'onrei',
  'onryou',
  'onsei',
  'onsen',
  'onsha',
  'onshi',
  'onshou',
  'ontai',
  'onwa',
  'onyomi',
  'onzon',
  'oo',
  'ooame',
  'ooba',
  'oobune',
  'oodai',
  'oodana',
  'oode',
  'oodume',
  'oofune',
  'oogane',
  'oogata',
  'oogesa',
  'oogi',
  'oogoe',
  'oogoto',
  'oohaba',
  'ooi',
  'ooini',
  'ooji',
  'ookami',
  'ookata',
  'ookawa',
  'ookii',
  'ookina',
  'ookisa',
  'ookura',
  'oomaka',
  'oomizu',
  'oomoji',
  'oomono',
  'oomori',
  'oomugi',
  'oono',
  'ooo',
  'oooo',
  'oosaji',
  'oosuji',
  'oote',
  'oou',
  'oowata',
  'ooya',
  'ooyake',
  'ooyuki',
  'oozei',
  'oozeki',
  'oozora',
  'op',
  'oppai',
  'opq',
  'oq',
  'or',
  'ore',
  'orei',
  'oreru',
  'ori',
  'oriai',
  'oriori',
  'oriru',
  'oroka',
  'orooro',
  'oroshi',
  'orosu',
  'oru',
  'os',
  'osa',
  'osae',
  'osaeru',
  'osai',
  'osan',
  'osanai',
  'oseibo',
  'oseji',
  'osen',
  'oshare',
  'oshi',
  'oshie',
  'oshii',
  'oshimu',
  'oshoku',
  'osoi',
  'osore',
  'osou',
  'ossan',
  'osu',
  'osui',
  'ot',
  'otaku',
  'oteage',
  'oto',
  'otoko',
  'otome',
  'otona',
  'otoroe',
  'otoru',
  'otosu',
  'ototo',
  'ototoi',
  'otouto',
  'otsu',
  'otsuri',
  'otto',
  'ou',
  'oubei',
  'oubo',
  'oubou',
  'oubun',
  'ouchi',
  'ouchou',
  'ouda',
  'oudan',
  'ouen',
  'oufuku',
  'ougi',
  'ougon',
  'ougyou',
  'ouhi',
  'oui',
  'ouja',
  'ouji',
  'oujiru',
  'oujo',
  'oujou',
  'oukoku',
  'oukou',
  'oukyuu',
  'ounen',
  'ourai',
  'ouryou',
  'ousama',
  'ousen',
  'oushou',
  'oushuu',
  'outai',
  'oute',
  'outen',
  'outou',
  'ouyou',
  'ouza',
  'ouzoku',
  'ouzuru',
  'ov',
  'ow',
  'owari',
  'owaru',
  'ox',
  'oy',
  'oya',
  'oyabun',
  'oyako',
  'oyama',
  'oyatsu',
  'oyobi',
  'oyobu',
  'oyogi',
  'oyogu',
  'oyoso',
  'oyubi',
  'oz',
  'p',
  'patto',
  'pb',
  'pc',
  'pd',
  'pe',
  'pekin',
  'pf',
  'pg',
  'ph',
  'pi',
  'pinpin',
  'pj',
  'pk',
  'pl',
  'pm',
  'pn',
  'po',
  'pokan',
  'pp',
  'ppoi',
  'ppp',
  'pppp',
  'pq',
  'pqr',
  'pr',
  'ps',
  'pt',
  'pu',
  'punpun',
  'pv',
  'pw',
  'px',
  'py',
  'pz',
  'q',
  'qb',
  'qc',
  'qd',
  'qe',
  'qf',
  'qg',
  'qh',
  'qi',
  'qj',
  'qk',
  'ql',
  'qm',
  'qn',
  'qo',
  'qp',
  'qq',
  'qqq',
  'qqqq',
  'qr',
  'qrs',
  'qs',
  'qt',
  'qu',
  'qv',
  'qw',
  'qx',
  'qy',
  'qz',
  'r',
  'raamen',
  'rai',
  'raihai',
  'raihin',
  'raihou',
  'rainen',
  'raiten',
  'raiu',
  'rakka',
  'rakkan',
  'raku',
  'rakuen',
  'rakugo',
  'rakusa',
  'ran',
  'ranbai',
  'ranbou',
  'rankei',
  'ranshi',
  'ransou',
  'rantou',
  'ranyou',
  'rasen',
  'rashii',
  'rb',
  'rc',
  'rd',
  're',
  'rei',
  'reibou',
  'reibun',
  'reidai',
  'reien',
  'reigai',
  'reigi',
  'reihai',
  'reiji',
  'reijou',
  'reika',
  'reikai',
  'reikan',
  'reikin',
  'reinen',
  'reisai',
  'reisei',
  'reisen',
  'reisui',
  'reitan',
  'reitou',
  'reizou',
  'reki',
  'rekka',
  'rekkyo',
  'remon',
  'ren',
  'renda',
  'rendou',
  'renga',
  'rengou',
  'renjuu',
  'renkan',
  'renkei',
  'renkou',
  'renmei',
  'renpa',
  'renpai',
  'renpou',
  'rensa',
  'rensai',
  'rensen',
  'rensou',
  'rentai',
  'rentou',
  'renyou',
  'renza',
  'ressei',
  'ressha',
  'retsu',
  'rettou',
  'rf',
  'rg',
  'rh',
  'ri',
  'riage',
  'riben',
  'ricchi',
  'rieki',
  'rigai',
  'rigaku',
  'rihan',
  'riji',
  'rijun',
  'rika',
  'rikai',
  'rikei',
  'riken',
  'rikimu',
  'rikken',
  'rikon',
  'rikou',
  'riku',
  'rikuro',
  'rikyuu',
  'rimen',
  'rin',
  'rindou',
  'rinen',
  'ringo',
  'rinji',
  'rinjin',
  'rinjuu',
  'rinka',
  'rinkai',
  'rinri',
  'rinya',
  'rippa',
  'rippou',
  'rireki',
  'ririku',
  'riron',
  'risage',
  'risan',
  'risei',
  'rishi',
  'risoku',
  'risou',
  'riten',
  'ritou',
  'ritsu',
  'rittai',
  'riyaku',
  'riyou',
  'riyuu',
  'rj',
  'rk',
  'rl',
  'rm',
  'rn',
  'ro',
  'roji',
  'rojou',
  'roken',
  'roku',
  'rokuga',
  'rokuna',
  'rokuni',
  'rokuon',
  'romen',
  'ronbun',
  'rongai',
  'rongi',
  'ronkyo',
  'ronri',
  'ronsen',
  'ronsha',
  'ronsou',
  'ronten',
  'rosen',
  'roshin',
  'roten',
  'rou',
  'roudou',
  'roufu',
  'rougan',
  'rougo',
  'rouhi',
  'rouho',
  'rouhou',
  'roujin',
  'roujo',
  'rouka',
  'roumu',
  'rounen',
  'rounin',
  'rourei',
  'rouren',
  'rousai',
  'roushi',
  'rouso',
  'rousui',
  'rp',
  'rq',
  'rr',
  'rrr',
  'rrrr',
  'rs',
  'rst',
  'rt',
  'ru',
  'rui',
  'ruiji',
  'ruikei',
  'ruisui',
  'rusu',
  'rv',
  'rw',
  'rx',
  'ry',
  'ryaku',
  'ryodan',
  'ryohi',
  'ryokan',
  'ryoken',
  'ryokka',
  'ryokou',
  'ryoku',
  'ryou',
  'ryouba',
  'ryoudo',
  'ryouin',
  'ryouji',
  'ryouri',
  'ryoute',
  'ryuu',
  'ryuuha',
  'ryuuho',
  'ryuuou',
  'rz',
  's',
  'saa',
  'sabaku',
  'sabi',
  'sabiru',
  'sabou',
  'sabui',
  'sacchi',
  'sadaka',
  'sadou',
  'sae',
  'saeki',
  'saeru',
  'sagaku',
  'sagari',
  'sagaru',
  'sagasu',
  'sageru',
  'sagi',
  'saguru',
  'sagyou',
  'saha',
  'sahou',
  'sai',
  'saiai',
  'saiaku',
  'saibai',
  'saiban',
  'saibou',
  'saibu',
  'saibun',
  'saidai',
  'saidan',
  'saido',
  'saien',
  'saifu',
  'saigai',
  'saigen',
  'saigo',
  'saihan',
  'saihen',
  'saihou',
  'saijou',
  'saika',
  'saikai',
  'saiken',
  'saiki',
  'saikin',
  'saiko',
  'saikon',
  'saikou',
  'saiku',
  'saimu',
  'sainan',
  'sainen',
  'sainin',
  'sainou',
  'sairai',
  'sairui',
  'saisan',
  'saisei',
  'saisen',
  'saishi',
  'saisho',
  'saishu',
  'saita',
  'saitan',
  'saitei',
  'saiten',
  'saiwai',
  'saiyou',
  'saizen',
  'saji',
  'saka',
  'sakaba',
  'sakai',
  'sakan',
  'sakana',
  'sakari',
  'sakasa',
  'sakate',
  'sakaya',
  'sake',
  'sakebi',
  'sakebu',
  'sakeru',
  'saki',
  'sakini',
  'sakka',
  'sakki',
  'sakkin',
  'sakkon',
  'sakoku',
  'saku',
  'sakugo',
  'sakuin',
  'sakujo',
  'sakura',
  'sakuya',
  'sama',
  'samasu',
  'sameru',
  'samui',
  'samuke',
  'samusa',
  'san',
  'sana',
  'sanaka',
  'sanbi',
  'sanbon',
  'sanbou',
  'sanbu',
  'sancha',
  'sanchi',
  'sandai',
  'sando',
  'sandou',
  'sane',
  'sanji',
  'sanjo',
  'sanjou',
  'sanjuu',
  'sanka',
  'sankai',
  'sankan',
  'sankei',
  'sanken',
  'sankou',
  'sanmai',
  'sannan',
  'sannin',
  'sanpai',
  'sanpi',
  'sanpin',
  'sanpo',
  'sanpou',
  'sanpu',
  'sanran',
  'sanrin',
  'sanrui',
  'sansai',
  'sansei',
  'sansen',
  'sansha',
  'sanshu',
  'sanso',
  'sanson',
  'sansou',
  'sansuu',
  'santei',
  'sanya',
  'sanyo',
  'sanyou',
  'sanzan',
  'sanzen',
  'sao',
  'sappu',
  'sara',
  'sarani',
  'sarasu',
  'sarau',
  'saru',
  'sasa',
  'sasaru',
  'sasen',
  'saseru',
  'sashi',
  'sashie',
  'sashou',
  'sasoi',
  'sasoku',
  'sasou',
  'sasshi',
  'sasu',
  'sasuga',
  'sasuru',
  'sate',
  'satei',
  'satogo',
  'satoru',
  'satou',
  'satsu',
  'satsui',
  'satto',
  'sattou',
  'sau',
  'sawa',
  'sawagi',
  'sawagu',
  'sawan',
  'sawaru',
  'sayoku',
  'sayou',
  'sayuu',
  'sazo',
  'sb',
  'sc',
  'sd',
  'se',
  'sebiro',
  'sebone',
  'secchi',
  'sedai',
  'segamu',
  'segin',
  'segyou',
  'sei',
  'seian',
  'seibi',
  'seibo',
  'seibu',
  'seibun',
  'seichi',
  'seidai',
  'seido',
  'seidou',
  'seiei',
  'seien',
  'seifu',
  'seigan',
  'seigen',
  'seigi',
  'seigo',
  'seigou',
  'seigyo',
  'seiha',
  'seihen',
  'seihi',
  'seihin',
  'seiho',
  'seihou',
  'seii',
  'seiiki',
  'seiiku',
  'seiji',
  'seijin',
  'seijou',
  'seika',
  'seikai',
  'seikan',
  'seikei',
  'seiken',
  'seiki',
  'seikou',
  'seimai',
  'seimei',
  'seimon',
  'seimu',
  'seinan',
  'seinen',
  'seinou',
  'seiou',
  'seirei',
  'seiri',
  'seiron',
  'seisai',
  'seisan',
  'seisei',
  'seisen',
  'seishi',
  'seisho',
  'seishu',
  'seisou',
  'seisuu',
  'seitai',
  'seitan',
  'seitei',
  'seiten',
  'seito',
  'seiton',
  'seitou',
  'seiun',
  'seiyou',
  'seiza',
  'seizan',
  'seizei',
  'seizen',
  'seizon',
  'seizou',
  'seizu',
  'sekai',
  'sekasu',
  'seken',
  'seki',
  'sekimu',
  'sekiyu',
  'sekkai',
  'sekkei',
  'sekken',
  'sekki',
  'sekkin',
  'sekku',
  'sekou',
  'semai',
  'semaru',
  'seme',
  'semeru',
  'semete',
  'semi',
  'sen',
  'senaka',
  'senbai',
  'senbei',
  'senchi',
  'sendai',
  'senden',
  'sendo',
  'sendou',
  'sengen',
  'sengo',
  'sengyo',
  'senja',
  'senji',
  'senjin',
  'senjou',
  'senjuu',
  'senka',
  'senkan',
  'senkei',
  'senken',
  'senkou',
  'senku',
  'senkyo',
  'senmei',
  'senmen',
  'senmon',
  'senmu',
  'sennen',
  'sennin',
  'senpai',
  'senpan',
  'senpi',
  'senpou',
  'senpuu',
  'senrei',
  'senren',
  'senri',
  'senro',
  'sensai',
  'sensei',
  'sensen',
  'sensha',
  'senshi',
  'senshu',
  'sensou',
  'sensu',
  'sensui',
  'sentai',
  'sentan',
  'sente',
  'sentei',
  'sento',
  'sentou',
  'senyou',
  'senyuu',
  'senzai',
  'senzen',
  'senzo',
  'seou',
  'seppan',
  'seriai',
  'seriau',
  'seron',
  'seru',
  'seshuu',
  'sesou',
  'sessen',
  'sesshi',
  'sesshu',
  'sesuji',
  'setai',
  'seto',
  'setsu',
  'settai',
  'settei',
  'setten',
  'settou',
  'sewa',
  'sezuni',
  'sf',
  'sg',
  'sh',
  'sha',
  'shadou',
  'shagai',
  'shai',
  'shain',
  'shaji',
  'shaka',
  'shakai',
  'shaken',
  'shakka',
  'shako',
  'shakou',
  'shamei',
  'shamen',
  'shanai',
  'shaoku',
  'share',
  'sharei',
  'sharin',
  'shasai',
  'shasei',
  'shasen',
  'shashu',
  'shasou',
  'shatai',
  'shatei',
  'shatsu',
  'shazai',
  'shi',
  'shiage',
  'shiai',
  'shian',
  'shiba',
  'shibai',
  'shibi',
  'shibou',
  'shibu',
  'shibui',
  'shichi',
  'shidai',
  'shidan',
  'shidou',
  'shiei',
  'shien',
  'shigai',
  'shigan',
  'shigen',
  'shigi',
  'shigo',
  'shihai',
  'shihan',
  'shihei',
  'shihi',
  'shihon',
  'shihou',
  'shiika',
  'shiiku',
  'shiin',
  'shiire',
  'shiiru',
  'shiite',
  'shiito',
  'shiji',
  'shijin',
  'shijo',
  'shijou',
  'shijuu',
  'shika',
  'shikai',
  'shikan',
  'shikei',
  'shiken',
  'shiki',
  'shikii',
  'shikin',
  'shikke',
  'shikki',
  'shikou',
  'shiku',
  'shikyo',
  'shima',
  'shimai',
  'shimau',
  'shime',
  'shimei',
  'shimen',
  'shimi',
  'shimin',
  'shimo',
  'shimon',
  'shin',
  'shina',
  'shinai',
  'shinan',
  'shindo',
  'shingi',
  'shingo',
  'shingu',
  'shinin',
  'shinja',
  'shinju',
  'shinka',
  'shinki',
  'shinni',
  'shinno',
  'shinpa',
  'shinpi',
  'shinpo',
  'shinpu',
  'shinri',
  'shinro',
  'shinsa',
  'shinto',
  'shinu',
  'shinwa',
  'shinya',
  'shio',
  'shippo',
  'shirei',
  'shiren',
  'shiri',
  'shirio',
  'shiro',
  'shiroi',
  'shiru',
  'shisa',
  'shisan',
  'shisei',
  'shisen',
  'shisha',
  'shishi',
  'shisho',
  'shison',
  'shisou',
  'shisso',
  'shisuu',
  'shita',
  'shitai',
  'shitau',
  'shite',
  'shitei',
  'shiten',
  'shito',
  'shitsu',
  'shitto',
  'shiuto',
  'shiwa',
  'shiya',
  'shiyou',
  'shiyuu',
  'shizai',
  'shizen',
  'sho',
  'shobou',
  'shobun',
  'shochi',
  'shodai',
  'shodou',
  'shoen',
  'shoguu',
  'shohan',
  'shoho',
  'shoin',
  'shoji',
  'shojo',
  'shojun',
  'shoka',
  'shokai',
  'shokan',
  'shokei',
  'shoken',
  'shoki',
  'shokki',
  'shoku',
  'shokun',
  'shomei',
  'shomen',
  'shomin',
  'shonen',
  'shori',
  'shorou',
  'shorui',
  'shosai',
  'shosen',
  'shosho',
  'shotai',
  'shotei',
  'shoten',
  'shotou',
  'shou',
  'shoubi',
  'shoubu',
  'shoufu',
  'shouge',
  'shougi',
  'shougo',
  'shouhi',
  'shouin',
  'shouji',
  'shoujo',
  'shouka',
  'shouko',
  'shoumu',
  'shouni',
  'shouri',
  'shousa',
  'shouso',
  'shouu',
  'shouwa',
  'shouyo',
  'shouyu',
  'shoyou',
  'shoyuu',
  'shozai',
  'shozou',
  'shu',
  'shubi',
  'shubou',
  'shudai',
  'shudan',
  'shudou',
  'shuei',
  'shuen',
  'shufu',
  'shugan',
  'shugi',
  'shuhan',
  'shuhou',
  'shui',
  'shuin',
  'shujii',
  'shujin',
  'shuju',
  'shukan',
  'shukei',
  'shuken',
  'shuki',
  'shukka',
  'shukou',
  'shukun',
  'shumi',
  'shun',
  'shunin',
  'shunji',
  'shunki',
  'shunou',
  'shuppi',
  'shuri',
  'shurui',
  'shusa',
  'shusai',
  'shusei',
  'shusen',
  'shushi',
  'shusse',
  'shusui',
  'shutai',
  'shuten',
  'shuto',
  'shuu',
  'shuuen',
  'shuugi',
  'shuuha',
  'shuui',
  'shuuin',
  'shuujo',
  'shuuka',
  'shuuki',
  'shuuri',
  'shuuto',
  'shuwa',
  'shuwan',
  'shuyou',
  'shuyu',
  'shuzai',
  'shuzei',
  'shuzou',
  'si',
  'sj',
  'sk',
  'sl',
  'sm',
  'sn',
  'so',
  'soan',
  'soba',
  'sobaya',
  'sobo',
  'soboku',
  'sobu',
  'socchi',
  'sochi',
  'sodai',
  'sode',
  'soen',
  'soeru',
  'sofu',
  'sofubo',
  'sogai',
  'soitsu',
  'sojou',
  'sokai',
  'sokaku',
  'sokka',
  'sokkin',
  'sokkou',
  'soko',
  'sokode',
  'sokoku',
  'sokone',
  'sokou',
  'soku',
  'sokudo',
  'sokui',
  'sokuji',
  'sokuza',
  'somaru',
  'some',
  'someru',
  'somuku',
  'son',
  'sonae',
  'sonata',
  'sonbou',
  'songai',
  'songen',
  'sonkai',
  'sonkei',
  'sonmin',
  'sonna',
  'sono',
  'sonota',
  'sonoue',
  'sonzai',
  'sootto',
  'sora',
  'sorasu',
  'sore',
  'sorede',
  'soreja',
  'soren',
  'soreni',
  'sorera',
  'soreru',
  'sorou',
  'soru',
  'sosen',
  'soshi',
  'soshou',
  'sosogu',
  'sossen',
  'soto',
  'sotode',
  'sotomi',
  'sotsui',
  'sotsuu',
  'sotte',
  'sotto',
  'sou',
  'souan',
  'souba',
  'soubi',
  'souchi',
  'souda',
  'soudai',
  'soudan',
  'souden',
  'soudou',
  'soufu',
  'sougei',
  'sougen',
  'sougi',
  'sougo',
  'sougou',
  'souhou',
  'soui',
  'souji',
  'soujou',
  'soujuu',
  'souka',
  'soukai',
  'soukan',
  'souken',
  'souki',
  'soukin',
  'souko',
  'soukou',
  'soumu',
  'sounan',
  'sounen',
  'souon',
  'souou',
  'souran',
  'souri',
  'souron',
  'sourui',
  'souryo',
  'sousa',
  'sousai',
  'sousha',
  'soushi',
  'soushu',
  'sousou',
  'sousui',
  'sousuu',
  'soutai',
  'soutei',
  'souten',
  'soutou',
  'soutto',
  'souzai',
  'souzei',
  'souzen',
  'souzou',
  'sozai',
  'sozei',
  'sp',
  'sq',
  'sr',
  'ss',
  'sss',
  'ssss',
  'st',
  'stu',
  'su',
  'suberu',
  'subete',
  'sudeni',
  'sue',
  'suekko',
  'sueko',
  'sueni',
  'sueoki',
  'sueru',
  'sugao',
  'sugaru',
  'sugata',
  'sugi',
  'sugiru',
  'sugoi',
  'sugosu',
  'sugu',
  'suguru',
  'sui',
  'suibun',
  'suiden',
  'suidou',
  'suiei',
  'suigai',
  'suigen',
  'suigin',
  'suihei',
  'suihen',
  'suii',
  'suiiki',
  'suiji',
  'suijou',
  'suijun',
  'suika',
  'suikei',
  'suiki',
  'suikou',
  'suimen',
  'suimin',
  'suion',
  'suiri',
  'suiro',
  'suisan',
  'suisen',
  'suishi',
  'suiso',
  'suisou',
  'suitai',
  'suitei',
  'suitou',
  'suiyou',
  'suji',
  'sukete',
  'suki',
  'sukike',
  'sukima',
  'suku',
  'sukui',
  'sukuu',
  'suma',
  'sumai',
  'sumasu',
  'sumi',
  'sumomo',
  'sumou',
  'sumu',
  'suna',
  'sunao',
  'sundan',
  'sune',
  'sunpou',
  'sunzen',
  'suppai',
  'sura',
  'suri',
  'suru',
  'suruto',
  'sushi',
  'suso',
  'susume',
  'susumi',
  'susumu',
  'sutego',
  'suteki',
  'suteru',
  'sutto',
  'suu',
  'suuchi',
  'suuhai',
  'suuji',
  'suuko',
  'suuman',
  'suwari',
  'suwaru',
  'suyu',
  'suzu',
  'suzume',
  'suzumu',
  'sv',
  'sw',
  'sx',
  'sy',
  'sz',
  't',
  'taba',
  'tabako',
  'taberu',
  'tabi',
  'tabini',
  'tabou',
  'tabun',
  'tachi',
  'tada',
  'tadai',
  'tadasu',
  'tadoru',
  'taeru',
  'taezu',
  'tagai',
  'tagaku',
  'taha',
  'tahata',
  'tahou',
  'tai',
  'taian',
  'taibei',
  'taibou',
  'taichi',
  'taida',
  'taidan',
  'taido',
  'taidou',
  'taieki',
  'taifuu',
  'taiga',
  'taigai',
  'taigan',
  'taigi',
  'taiguu',
  'taiha',
  'taihai',
  'taihan',
  'taihen',
  'taihi',
  'taiho',
  'taihou',
  'taii',
  'taiiku',
  'taiin',
  'taiji',
  'taijin',
  'taijou',
  'taijuu',
  'taika',
  'taikai',
  'taikan',
  'taikei',
  'taiken',
  'taiki',
  'taikin',
  'taiko',
  'taikou',
  'taikyo',
  'taima',
  'taiman',
  'taimen',
  'tainai',
  'tainin',
  'tainou',
  'taion',
  'taiou',
  'taira',
  'tairon',
  'taisa',
  'taisei',
  'taisen',
  'taisha',
  'taishi',
  'taisho',
  'taisou',
  'taitei',
  'taitou',
  'taiwa',
  'taiwan',
  'taiyo',
  'taiyou',
  'taizai',
  'taizan',
  'taizei',
  'tajuu',
  'taka',
  'takai',
  'takaku',
  'takane',
  'takara',
  'takaru',
  'takasa',
  'takase',
  'take',
  'taki',
  'takigi',
  'tako',
  'takoku',
  'taku',
  'takuma',
  'takumi',
  'takusu',
  'tama',
  'tamago',
  'tamani',
  'tamaru',
  'tame',
  'tamen',
  'tameru',
  'tamesu',
  'tami',
  'tan',
  'tana',
  'tanan',
  'tanbi',
  'tanbo',
  'tancho',
  'tandai',
  'tanden',
  'tane',
  'tango',
  'tani',
  'taniai',
  'tanima',
  'tanin',
  'tanjou',
  'tanjun',
  'tanjuu',
  'tanka',
  'tanken',
  'tanki',
  'tankou',
  'tanmei',
  'tannen',
  'tanni',
  'tannin',
  'tannou',
  'tanomi',
  'tanomu',
  'tanpen',
  'tanpo',
  'tansa',
  'tansan',
  'tansei',
  'tansho',
  'tanso',
  'tansu',
  'tansui',
  'tansuu',
  'tantan',
  'tantei',
  'tantou',
  'taore',
  'taosu',
  'tara',
  'tarasu',
  'tare',
  'tareru',
  'tari',
  'tariru',
  'taru',
  'tarumu',
  'taryou',
  'tasai',
  'tasha',
  'tasho',
  'tashou',
  'tassei',
  'tassha',
  'tasu',
  'tasuke',
  'tasuu',
  'tataki',
  'tataku',
  'tatami',
  'tatamu',
  'tate',
  'tateru',
  'tatoe',
  'tatoi',
  'tatsu',
  'tatta',
  'tatte',
  'tattoi',
  'taue',
  'tawara',
  'tayori',
  'tayoru',
  'tayou',
  'tayumu',
  'tazu',
  'tb',
  'tc',
  'td',
  'te',
  'tearai',
  'teashi',
  'teate',
  'tebiki',
  'teburi',
  'techou',
  'tedate',
  'tegaki',
  'tegami',
  'tegara',
  'tegaru',
  'tegata',
  'tegiwa',
  'tegoro',
  'tehai',
  'tehon',
  'tei',
  'teian',
  'teibou',
  'teiden',
  'teido',
  'teien',
  'teigen',
  'teigi',
  'teihen',
  'teiin',
  'teiji',
  'teijuu',
  'teika',
  'teikei',
  'teiki',
  'teikou',
  'teimei',
  'teinei',
  'teinen',
  'teion',
  'teire',
  'teirei',
  'teiri',
  'teisai',
  'teisei',
  'teisen',
  'teisha',
  'teishi',
  'teishu',
  'teiso',
  'teisuu',
  'teitai',
  'tejina',
  'tejou',
  'tejun',
  'tekaki',
  'teki',
  'tekido',
  'tekigi',
  'tekii',
  'tekiji',
  'tekiou',
  'tekkai',
  'tekkin',
  'tekkou',
  'tekkyo',
  'tekubi',
  'tema',
  'temae',
  'temo',
  'temoto',
  'ten',
  'tenbai',
  'tenbou',
  'tenchi',
  'tenga',
  'tenji',
  'tenjin',
  'tenjou',
  'tenka',
  'tenkai',
  'tenkan',
  'tenkei',
  'tenken',
  'tenki',
  'tenkin',
  'tenkou',
  'tenkyo',
  'tenmon',
  'tennai',
  'tennen',
  'tennou',
  'tenpo',
  'tenpu',
  'tenri',
  'tensa',
  'tensai',
  'tensen',
  'tenshi',
  'tenshu',
  'tensuu',
  'tentai',
  'tenten',
  'tentou',
  'tenuki',
  'tenyou',
  'tenzai',
  'teochi',
  'teppai',
  'teppan',
  'teppen',
  'teppou',
  'tera',
  'terasu',
  'tereru',
  'teru',
  'tesage',
  'tesei',
  'tessei',
  'tesuri',
  'tesuu',
  'tetori',
  'tetsu',
  'tettai',
  'tettei',
  'teusu',
  'tewake',
  'tf',
  'tg',
  'th',
  'ti',
  'tj',
  'tk',
  'tl',
  'tm',
  'tn',
  'to',
  'tobasu',
  'tobihi',
  'tobira',
  'tobosu',
  'tobu',
  'tochi',
  'tochou',
  'tochuu',
  'todana',
  'todoke',
  'todoku',
  'toei',
  'togaru',
  'toge',
  'togeru',
  'togi',
  'togu',
  'toha',
  'toho',
  'tohou',
  'toi',
  'toiya',
  'tojiru',
  'tojou',
  'toka',
  'tokai',
  'tokaku',
  'tokasu',
  'tokei',
  'token',
  'tokeru',
  'toki',
  'tokken',
  'tokki',
  'tokkun',
  'tokkyo',
  'toko',
  'tokoro',
  'tokou',
  'tokowo',
  'tokoya',
  'toku',
  'tokugi',
  'tokui',
  'tokuni',
  'tomari',
  'tomaru',
  'tomeru',
  'tomi',
  'tomin',
  'tomo',
  'tomoe',
  'tomoni',
  'tomosu',
  'tomu',
  'ton',
  'tonai',
  'tonari',
  'tonbo',
  'tonbou',
  'tonda',
  'tonton',
  'tonya',
  'too',
  'tooi',
  'tooka',
  'tooku',
  'toori',
  'tooru',
  'tooshi',
  'toosu',
  'toppa',
  'toppuu',
  'tora',
  'toreru',
  'tori',
  'torie',
  'torii',
  'toro',
  'toru',
  'toryou',
  'tosei',
  'toshi',
  'toshin',
  'tosho',
  'tosou',
  'totan',
  'totemo',
  'totte',
  'tou',
  'toua',
  'touan',
  'touban',
  'touben',
  'toubou',
  'toubu',
  'toubun',
  'touchi',
  'toudai',
  'touei',
  'toufu',
  'touge',
  'tougei',
  'tougi',
  'tougou',
  'touguu',
  'touha',
  'touhi',
  'touhou',
  'touin',
  'touji',
  'toujou',
  'touka',
  'toukai',
  'toukan',
  'toukei',
  'touki',
  'toukou',
  'toumei',
  'toumen',
  'toumin',
  'toumu',
  'tounai',
  'tounan',
  'tounin',
  'touno',
  'touou',
  'tourai',
  'touri',
  'touron',
  'tourui',
  'tousai',
  'tousan',
  'tousei',
  'tousen',
  'tousha',
  'toushi',
  'tousho',
  'toushu',
  'tousou',
  'tousui',
  'toutei',
  'touto',
  'toutoi',
  'toutou',
  'touyo',
  'touyou',
  'touyu',
  'touzai',
  'touzen',
  'towa',
  'tozan',
  'tozasu',
  'tp',
  'tq',
  'tr',
  'ts',
  'tsu',
  'tsuba',
  'tsubo',
  'tsubu',
  'tsuchi',
  'tsudo',
  'tsudoi',
  'tsue',
  'tsugi',
  'tsugou',
  'tsugu',
  'tsui',
  'tsuide',
  'tsuiji',
  'tsuika',
  'tsuini',
  'tsuite',
  'tsuji',
  'tsuka',
  'tsukai',
  'tsukau',
  'tsuke',
  'tsuki',
  'tsuku',
  'tsukue',
  'tsuma',
  'tsume',
  'tsumi',
  'tsumu',
  'tsuna',
  'tsuno',
  'tsura',
  'tsurai',
  'tsure',
  'tsuri',
  'tsuru',
  'tsushi',
  'tsutau',
  'tsutsu',
  'tsuu',
  'tsuuin',
  'tsuuji',
  'tsuuka',
  'tsuuro',
  'tsuuwa',
  'tsuwa',
  'tsuya',
  'tsuyoi',
  'tsuyu',
  'tt',
  'ttara',
  'tte',
  'ttt',
  'tttt',
  'tu',
  'tuv',
  'tv',
  'tw',
  'tx',
  'ty',
  'tz',
  'u',
  'ub',
  'uba',
  'ubau',
  'uc',
  'uchi',
  'uchiai',
  'uchiki',
  'uchiwa',
  'uchuu',
  'ud',
  'udaku',
  'ude',
  'udemae',
  'udon',
  'ue',
  'uejini',
  'ueki',
  'uemuki',
  'ueno',
  'ueru',
  'uf',
  'ug',
  'ugai',
  'ugoki',
  'ugoku',
  'uh',
  'uha',
  'ui',
  'uijin',
  'uj',
  'uji',
  'uk',
  'ukabu',
  'ukagau',
  'ukaru',
  'uke',
  'ukeire',
  'ukemi',
  'ukeoi',
  'ukeou',
  'ukeru',
  'uki',
  'ukiuki',
  'ukiyoe',
  'ukkari',
  'uku',
  'ul',
  'um',
  'uma',
  'umai',
  'umami',
  'umare',
  'umaru',
  'ume',
  'umeru',
  'umi',
  'umibe',
  'umou',
  'umu',
  'un',
  'unagi',
  'unaru',
  'unchi',
  'unchin',
  'undou',
  'uneri',
  'uneru',
  'unga',
  'unko',
  'unkou',
  'unkyuu',
  'unmei',
  'unpan',
  'unsou',
  'unten',
  'unyoku',
  'unyou',
  'unyu',
  'unzari',
  'uo',
  'up',
  'uq',
  'ur',
  'ura',
  'urame',
  'urami',
  'uramon',
  'uramu',
  'uranai',
  'uranau',
  'ureeru',
  'ureru',
  'uri',
  'uriage',
  'uriba',
  'urite',
  'urouro',
  'uru',
  'uruoi',
  'uruou',
  'urusai',
  'urushi',
  'uryou',
  'us',
  'usagi',
  'ushi',
  'ushio',
  'ushiro',
  'uso',
  'usoku',
  'usugi',
  'usui',
  'ut',
  'uta',
  'utagai',
  'utagau',
  'utage',
  'utagoe',
  'utau',
  'uten',
  'utotto',
  'utouto',
  'utsu',
  'utsumi',
  'utsuru',
  'utsusu',
  'utsuwa',
  'uttae',
  'uu',
  'uun',
  'uuu',
  'uuuu',
  'uv',
  'uvw',
  'uw',
  'uwagi',
  'uwaki',
  'uwan',
  'uwasa',
  'ux',
  'uy',
  'uyamau',
  'uyoku',
  'uz',
  'uzu',
  'uzuuzu',
  'v',
  'vb',
  'vc',
  'vd',
  've',
  'vf',
  'vg',
  'vh',
  'vi',
  'vj',
  'vk',
  'vl',
  'vm',
  'vn',
  'vo',
  'vp',
  'vq',
  'vr',
  'vs',
  'vt',
  'vu',
  'vv',
  'vvv',
  'vvvv',
  'vw',
  'vwx',
  'vx',
  'vy',
  'vz',
  'w',
  'wabi',
  'wabiru',
  'wada',
  'wadai',
  'waei',
  'wafuku',
  'wafuu',
  'waga',
  'wagaie',
  'wagaya',
  'wage',
  'wagi',
  'wagiri',
  'wago',
  'wagyuu',
  'wahei',
  'wairo',
  'waiwai',
  'waka',
  'wakaba',
  'wakai',
  'wakame',
  'wakare',
  'wakari',
  'wakaru',
  'wakasa',
  'wakasu',
  'wakate',
  'wake',
  'wakeru',
  'waki',
  'wakimi',
  'waku',
  'wameku',
  'wan',
  'wana',
  'wangan',
  'wanu',
  'wara',
  'warai',
  'warau',
  'ware',
  'wareme',
  'warera',
  'wareru',
  'wari',
  'wariai',
  'warini',
  'waro',
  'waru',
  'warugi',
  'warui',
  'warusa',
  'wasabi',
  'washi',
  'wata',
  'watari',
  'wataru',
  'watasu',
  'waza',
  'wazato',
  'wazuka',
  'wb',
  'wc',
  'wd',
  'we',
  'wf',
  'wg',
  'wh',
  'wi',
  'wj',
  'wk',
  'wl',
  'wm',
  'wn',
  'wo',
  'wp',
  'wq',
  'wr',
  'ws',
  'wt',
  'wu',
  'wv',
  'ww',
  'www',
  'wwww',
  'wx',
  'wxy',
  'wy',
  'wz',
  'x',
  'xb',
  'xc',
  'xd',
  'xe',
  'xf',
  'xg',
  'xh',
  'xi',
  'xj',
  'xk',
  'xl',
  'xm',
  'xn',
  'xo',
  'xp',
  'xq',
  'xr',
  'xs',
  'xt',
  'xu',
  'xv',
  'xw',
  'xx',
  'xxx',
  'xxxx',
  'xy',
  'xyz',
  'xz',
  'y',
  'yabai',
  'yaban',
  'yabo',
  'yabou',
  'yaburi',
  'yaburu',
  'yachin',
  'yachou',
  'yado',
  'yadoya',
  'yae',
  'yagai',
  'yagaku',
  'yagate',
  'yagou',
  'yagu',
  'yahari',
  'yakan',
  'yake',
  'yakedo',
  'yakei',
  'yakeru',
  'yakin',
  'yakka',
  'yakkai',
  'yakki',
  'yaku',
  'yakuba',
  'yakui',
  'yakuin',
  'yakume',
  'yakusu',
  'yakuza',
  'yakyuu',
  'yama',
  'yamai',
  'yamate',
  'yamato',
  'yameru',
  'yami',
  'yamome',
  'yamu',
  'yanagi',
  'yane',
  'yaoya',
  'yappa',
  'yari',
  'yaru',
  'yasai',
  'yasaki',
  'yasei',
  'yaseru',
  'yashin',
  'yashu',
  'yaso',
  'yasui',
  'yasumi',
  'yasumu',
  'yasune',
  'yatai',
  'yatou',
  'yatsu',
  'yatta',
  'yatto',
  'yattsu',
  'yauchi',
  'yaya',
  'yayoi',
  'yb',
  'yc',
  'yd',
  'ye',
  'yf',
  'yg',
  'yh',
  'yi',
  'yj',
  'yk',
  'yl',
  'ym',
  'yn',
  'yo',
  'yoake',
  'yobi',
  'yobihi',
  'yobina',
  'yobou',
  'yobu',
  'yobun',
  'yochi',
  'yodan',
  'yodare',
  'yodomu',
  'yofuke',
  'yogen',
  'yogore',
  'yogosu',
  'yoha',
  'yohodo',
  'yohou',
  'yoi',
  'yoin',
  'yojin',
  'yojiru',
  'yojou',
  'yoka',
  'yokan',
  'yokei',
  'yokeru',
  'yoki',
  'yokin',
  'yokka',
  'yoko',
  'yokode',
  'yokoku',
  'yokome',
  'yokosu',
  'yokote',
  'yoku',
  'yokui',
  'yokumo',
  'yome',
  'yomei',
  'yomi',
  'yomo',
  'yomu',
  'yon',
  'yonaka',
  'yone',
  'yonen',
  'yonhon',
  'yonin',
  'yonjuu',
  'yonsen',
  'yoreba',
  'yori',
  'yoron',
  'yorozu',
  'yoru',
  'yoruto',
  'yosa',
  'yosan',
  'yose',
  'yosen',
  'yoseru',
  'yoshi',
  'yoshin',
  'yoshuu',
  'yoso',
  'yosoku',
  'yosomi',
  'yosooi',
  'yosoou',
  'yosou',
  'yosu',
  'yotaku',
  'yotei',
  'yotou',
  'yottsu',
  'you',
  'youbi',
  'youbou',
  'youchi',
  'youeki',
  'youfu',
  'youfuu',
  'youga',
  'yougan',
  'yougi',
  'yougo',
  'yougu',
  'youha',
  'youhin',
  'youi',
  'youiku',
  'youin',
  'youji',
  'youjin',
  'youjou',
  'youka',
  'youkai',
  'youken',
  'youki',
  'youkou',
  'youma',
  'youmou',
  'youni',
  'younin',
  'yourei',
  'yourou',
  'yousai',
  'yousei',
  'yousha',
  'youshi',
  'yousho',
  'youshu',
  'youso',
  'yousou',
  'yousu',
  'yousui',
  'youten',
  'youto',
  'yowai',
  'yowaki',
  'yowami',
  'yowane',
  'yowaru',
  'yoyaku',
  'yoyo',
  'yoyuu',
  'yozora',
  'yp',
  'yq',
  'yr',
  'ys',
  'yt',
  'yu',
  'yubi',
  'yubiwa',
  'yudan',
  'yuden',
  'yuderu',
  'yueni',
  'yugamu',
  'yuge',
  'yugin',
  'yuigon',
  'yuisho',
  'yuitsu',
  'yuka',
  'yukai',
  'yukata',
  'yuki',
  'yukiki',
  'yuku',
  'yukue',
  'yume',
  'yumi',
  'yunomi',
  'yunyuu',
  'yuragi',
  'yuragu',
  'yurai',
  'yureru',
  'yuri',
  'yuru',
  'yurui',
  'yurumu',
  'yurusu',
  'yusai',
  'yusei',
  'yusou',
  'yusuru',
  'yutaka',
  'yutori',
  'yuu',
  'yuuai',
  'yuube',
  'yuuben',
  'yuubi',
  'yuubin',
  'yuubou',
  'yuuchi',
  'yuucho',
  'yuudai',
  'yuudou',
  'yuuduu',
  'yuueki',
  'yuuga',
  'yuugai',
  'yuugen',
  'yuugou',
  'yuuguu',
  'yuuhan',
  'yuuhi',
  'yuuho',
  'yuui',
  'yuuigi',
  'yuuji',
  'yuujin',
  'yuujou',
  'yuukai',
  'yuukan',
  'yuuki',
  'yuukou',
  'yuumei',
  'yuumu',
  'yuunou',
  'yuuran',
  'yuurei',
  'yuuri',
  'yuuryo',
  'yuusei',
  'yuusen',
  'yuushi',
  'yuusou',
  'yuusui',
  'yuusuu',
  'yuutai',
  'yuuwa',
  'yuuyo',
  'yuuyou',
  'yuuyuu',
  'yuuzai',
  'yuuzei',
  'yuuzuu',
  'yuzuru',
  'yv',
  'yw',
  'yx',
  'yy',
  'yyy',
  'yyyy',
  'z',
  'zaazaa',
  'zachou',
  'zai',
  'zaibei',
  'zaidan',
  'zaigai',
  'zaigen',
  'zaijuu',
  'zaikai',
  'zaiko',
  'zaimu',
  'zainin',
  'zairai',
  'zaisan',
  'zaisei',
  'zaiya',
  'zakka',
  'zakkyo',
  'zannen',
  'zanpai',
  'zanrui',
  'zansho',
  'zanson',
  'zantei',
  'zanzon',
  'zareru',
  'zari',
  'zaseki',
  'zasshi',
  'zassou',
  'zatsu',
  'zatto',
  'zattou',
  'zazen',
  'zb',
  'zc',
  'zd',
  'ze',
  'zehi',
  'zeihou',
  'zeikan',
  'zeikin',
  'zeimu',
  'zeisei',
  'zekkou',
  'zekku',
  'zeku',
  'zen',
  'zenbei',
  'zenbu',
  'zenbun',
  'zenchi',
  'zendo',
  'zengo',
  'zenhan',
  'zenji',
  'zenjin',
  'zenkai',
  'zenken',
  'zenki',
  'zenkou',
  'zenmai',
  'zenmen',
  'zennen',
  'zenpai',
  'zenpan',
  'zenpou',
  'zenrei',
  'zenrin',
  'zensei',
  'zensen',
  'zensha',
  'zentai',
  'zentei',
  'zento',
  'zenya',
  'zenyou',
  'zenzen',
  'zesei',
  'zettai',
  'zf',
  'zg',
  'zh',
  'zi',
  'zj',
  'zk',
  'zl',
  'zm',
  'zn',
  'zo',
  'zoi',
  'zokkou',
  'zoku',
  'zokugo',
  'zonbun',
  'zonpai',
  'zonzai',
  'zou',
  'zoudai',
  'zoueki',
  'zouge',
  'zougen',
  'zougo',
  'zouhan',
  'zouin',
  'zouka',
  'zoukei',
  'zouki',
  'zoukin',
  'zouni',
  'zouo',
  'zouri',
  'zousan',
  'zousei',
  'zousen',
  'zoushi',
  'zousho',
  'zousui',
  'zoutei',
  'zoutou',
  'zouwai',
  'zouyo',
  'zouzei',
  'zp',
  'zq',
  'zr',
  'zs',
  'zt',
  'zu',
  'zubari',
  'zugara',
  'zuhan',
  'zuhyou',
  'zuibun',
  'zuiin',
  'zuiji',
  'zuisho',
  'zujou',
  'zukai',
  'zukan',
  'zukei',
  'zumen',
  'zunou',
  'zurasu',
  'zureru',
  'zurui',
  'zusan',
  'zushi',
  'zutsu',
  'zutsuu',
  'zutto',
  'zuuchi',
  'zuutto',
  'zuzan',
  'zv',
  'zw',
  'zx',
  'zy',
  'zz',
  'zzz',
  'zzzz',
];
