export const swedish = [
  '=',
  '==',
  '===',
  '-',
  '--',
  '---',
  ';',
  ':',
  ':-(',
  ':-)',
  ':(',
  ':)',
  '!',
  '!!',
  '!!!',
  '?',
  '??',
  '???',
  '""""',
  '(',
  '()',
  ')',
  '@',
  '$',
  '$$',
  '$$$',
  '*',
  '**',
  '***',
  '&',
  '#',
  '##',
  '###',
  '%',
  '%%',
  '%%%',
  '+',
  '++',
  '+++',
  '0',
  '1',
  '10',
  '100',
  '1000',
  '101',
  '11',
  '111',
  '1111',
  '12',
  '123',
  '1234',
  '13',
  '14',
  '1492',
  '15',
  '1500',
  '16',
  '1600',
  '17',
  '1700',
  '18',
  '1800',
  '19',
  '1900',
  '1910',
  '1920',
  '1925',
  '1930',
  '1935',
  '1940',
  '1945',
  '1950',
  '1955',
  '1960',
  '1965',
  '1970',
  '1975',
  '1980',
  '1985',
  '1990',
  '1991',
  '1992',
  '1993',
  '1994',
  '1995',
  '1996',
  '1997',
  '1998',
  '1999',
  '2',
  '20',
  '200',
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2015',
  '2020',
  '2030',
  '2035',
  '2040',
  '2045',
  '2050',
  '21',
  '22',
  '222',
  '2222',
  '23',
  '234',
  '2345',
  '24',
  '2468',
  '25',
  '26',
  '27',
  '28',
  '29',
  '3',
  '30',
  '300',
  '3000',
  '31',
  '32',
  '33',
  '333',
  '3333',
  '34',
  '345',
  '3456',
  '35',
  '36',
  '37',
  '38',
  '39',
  '4',
  '40',
  '400',
  '4000',
  '41',
  '42',
  '43',
  '4321',
  '44',
  '444',
  '4444',
  '45',
  '456',
  '4567',
  '46',
  '47',
  '48',
  '49',
  '5',
  '50',
  '500',
  '5000',
  '51',
  '52',
  '53',
  '54',
  '55',
  '555',
  '5555',
  '56',
  '567',
  '5678',
  '57',
  '58',
  '59',
  '6',
  '60',
  '600',
  '6000',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '666',
  '6666',
  '67',
  '678',
  '6789',
  '68',
  '69',
  '7',
  '70',
  '700',
  '7000',
  '71',
  '72',
  '73',
  '74',
  '75',
  '76',
  '77',
  '777',
  '7777',
  '78',
  '789',
  '79',
  '8',
  '80',
  '800',
  '8000',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '88',
  '888',
  '8888',
  '89',
  '9',
  '90',
  '900',
  '9000',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '97',
  '98',
  '9876',
  '99',
  '999',
  '9999',
  'a',
  'aa',
  'aaa',
  'aaaa',
  'ab',
  'abakus',
  'abbot',
  'abc',
  'abc',
  'aber',
  'abort',
  'absid',
  'absint',
  'ac',
  'accis',
  'aceton',
  'ack',
  'ackja',
  'acne',
  'ad',
  'adb',
  'addera',
  'adel',
  'adept',
  'adla',
  'adlig',
  'advent',
  'ae',
  'aerob',
  'af',
  'afasi',
  'affix',
  'afton',
  'ag',
  'aga',
  'agat',
  'agave',
  'agens',
  'agent',
  'agera',
  'agg',
  'agio',
  'agmyr',
  'agn',
  'agna',
  'agning',
  'agoni',
  'agrar',
  'agtak',
  'ah',
  'aha',
  'ai',
  'aids',
  'aino',
  'aioli',
  'air',
  'aiss',
  'aj',
  'ajour',
  'ak',
  'akne',
  'akolyt',
  'akryl',
  'akt',
  'akta',
  'akter',
  'aktie',
  'aktiv',
  'aktor',
  'aktra',
  'aktre',
  'akut',
  'al',
  'alarm',
  'alba',
  'alban',
  'albino',
  'album',
  'ale',
  'alert',
  'alexi',
  'alf',
  'alfa',
  'alg',
  'alias',
  'alibi',
  'alika',
  'alka',
  'alkis',
  'alkov',
  'alkyd',
  'all',
  'alla',
  'allena',
  'allra',
  'alls',
  'allt',
  'allvar',
  'alm',
  'alme',
  'aln',
  'aloe',
  'alp',
  'alpby',
  'alpin',
  'alrot',
  'alster',
  'alt',
  'altan',
  'altea',
  'alumn',
  'alun',
  'alv',
  'alvar',
  'am',
  'ambarn',
  'ambis',
  'ambra',
  'amen',
  'amid',
  'amin',
  'amma',
  'amok',
  'amorf',
  'amour',
  'ampel',
  'amper',
  'ampere',
  'amt',
  'an',
  'ana',
  'anacka',
  'anal',
  'anarki',
  'anbud',
  'and',
  'anda',
  'andas',
  'ande',
  'andel',
  'andra',
  'andre',
  'androm',
  'anemi',
  'anfang',
  'ange',
  'angel',
  'angla',
  'anhang',
  'aning',
  'anis',
  'anjon',
  'anka',
  'ankar',
  'ankel',
  'ankra',
  'anlag',
  'anlete',
  'ann',
  'annan',
  'annex',
  'anno',
  'annons',
  'anod',
  'anomi',
  'anor',
  'anrik',
  'anrika',
  'anrop',
  'ans',
  'ansa',
  'anse',
  'ansols',
  'anta',
  'antal',
  'ante',
  'antik',
  'anus',
  'ao',
  'aorist',
  'aorta',
  'ap',
  'apa',
  'apart',
  'apati',
  'apbur',
  'apel',
  'aphus',
  'appell',
  'april',
  'aptit',
  'aq',
  'ar',
  'ara',
  'arab',
  'arbeta',
  'area',
  'areal',
  'arena',
  'arg',
  'argon',
  'argot',
  'aria',
  'arid',
  'arier',
  'arisk',
  'ark',
  'arkad',
  'arkiv',
  'arkont',
  'arla',
  'arm',
  'armod',
  'arom',
  'arr',
  'arra',
  'arrak',
  'arsel',
  'arsin',
  'arsle',
  'art',
  'arta',
  'artig',
  'artist',
  'arton',
  'arun',
  'arv',
  'arve',
  'arvord',
  'as',
  'asa',
  'asch',
  'asgam',
  'asiat',
  'asimut',
  'ask',
  'aska',
  'aske',
  'askes',
  'asket',
  'asp',
  'aspe',
  'ass',
  'astat',
  'asteni',
  'aster',
  'astma',
  'asyl',
  'at',
  'ataxi',
  'atcho',
  'atlant',
  'atlas',
  'atlet',
  'atoll',
  'atom',
  'atoni',
  'att',
  'attan',
  'attika',
  'attji',
  'attjo',
  'atypi',
  'au',
  'augur',
  'aula',
  'aura',
  'av',
  'avans',
  'avart',
  'avbasa',
  'avdra',
  'avdrag',
  'ave',
  'avec',
  'avel',
  'aveny',
  'avers',
  'avgas',
  'avge',
  'avgiva',
  'avgud',
  'avi',
  'avig',
  'aviga',
  'avisa',
  'avkall',
  'avkok',
  'avla',
  'avlat',
  'avlida',
  'avlut',
  'avluta',
  'avog',
  'avrad',
  'avrop',
  'avsalu',
  'avse',
  'avsky',
  'avslag',
  'avsyna',
  'avta',
  'avtal',
  'avund',
  'avvara',
  'aw',
  'ax',
  'axa',
  'axel',
  'axial',
  'axill',
  'axiom',
  'axla',
  'ay',
  'az',
  'azer',
  'azimut',
  'aztek',
  'azur',
  'b',
  'baby',
  'back',
  'backa',
  'backe',
  'backig',
  'bacon',
  'bad',
  'bada',
  'badd',
  'badda',
  'badort',
  'bag',
  'bageri',
  'bagg',
  'bagge',
  'bagis',
  'bah',
  'baj',
  'baja',
  'bajs',
  'bajsa',
  'bajsig',
  'bak',
  'baka',
  'bakfot',
  'baki',
  'bakis',
  'bakom',
  'bakre',
  'bakugn',
  'bakut',
  'bal',
  'bala',
  'bale',
  'balja',
  'balk',
  'balka',
  'ball',
  'balla',
  'balsa',
  'balsam',
  'balt',
  'bamba',
  'bambu',
  'bamse',
  'ban',
  'bana',
  'banal',
  'banan',
  'band',
  'banda',
  'bandy',
  'bane',
  'baner',
  'bang',
  'banga',
  'banjo',
  'bank',
  'banka',
  'bankir',
  'banko',
  'bann',
  'banna',
  'banta',
  'bantu',
  'bar',
  'bara',
  'barbi',
  'bard',
  'barett',
  'bark',
  'barka',
  'barm',
  'barn',
  'barock',
  'baron',
  'barr',
  'barra',
  'barsk',
  'baryt',
  'bas',
  'basa',
  'basal',
  'basar',
  'basis',
  'bask',
  'basker',
  'basse',
  'bast',
  'basta',
  'bastu',
  'basun',
  'batalj',
  'batat',
  'batik',
  'baud',
  'baxa',
  'baxna',
  'bb',
  'bbb',
  'bbbb',
  'bc',
  'bcd',
  'bd',
  'be',
  'bebis',
  'bebo',
  'bebop',
  'beck',
  'becka',
  'beckig',
  'bedja',
  'bedra',
  'begapa',
  'bege',
  'behag',
  'behov',
  'beige',
  'bekika',
  'bel',
  'belag',
  'bele',
  'ben',
  'bena',
  'benfri',
  'benig',
  'benlim',
  'bentyl',
  'berest',
  'berg',
  'bero',
  'beryll',
  'bese',
  'besk',
  'beska',
  'beslag',
  'best',
  'bet',
  'beta',
  'betala',
  'bete',
  'betel',
  'betro',
  'bets',
  'betsa',
  'betsla',
  'bett',
  'betyg',
  'bevars',
  'bevis',
  'bf',
  'bg',
  'bh',
  'bi',
  'bibba',
  'bibel',
  'bida',
  'bidan',
  'bidra',
  'bienn',
  'bier',
  'bifall',
  'biff',
  'bigata',
  'bikt',
  'bikta',
  'bil',
  'bila',
  'bilaga',
  'bild',
  'bilda',
  'biljon',
  'bill',
  'bimbo',
  'bind',
  'binda',
  'bindel',
  'binge',
  'bingo',
  'binka',
  'binom',
  'bio',
  'biogas',
  'biord',
  'bira',
  'birdie',
  'birot',
  'bisak',
  'bisam',
  'bismak',
  'bisol',
  'bison',
  'bisp',
  'bit',
  'bita',
  'bitas',
  'bitch',
  'biton',
  'bitsk',
  'bitti',
  'bivack',
  'bivax',
  'bj',
  'bjuda',
  'bjugg',
  'bk',
  'bl',
  'black',
  'blad',
  'blada',
  'blaha',
  'blaj',
  'blaja',
  'bland',
  'blank',
  'blanka',
  'blar',
  'blarr',
  'blask',
  'blast',
  'bleck',
  'blek',
  'bleka',
  'bleke',
  'blekna',
  'bli',
  'blick',
  'blid',
  'blida',
  'blig',
  'bliga',
  'blind',
  'blini',
  'blink',
  'bliva',
  'blixt',
  'block',
  'blod',
  'bloda',
  'blodig',
  'blom',
  'blond',
  'bloss',
  'blot',
  'blota',
  'blott',
  'blues',
  'bluff',
  'bluffa',
  'blund',
  'blus',
  'blusa',
  'bly',
  'blya',
  'blyg',
  'blygd',
  'bm',
  'bn',
  'bo',
  'boa',
  'board',
  'bob',
  'bobb',
  'bobba',
  'bobin',
  'boboll',
  'bock',
  'bocka',
  'bod',
  'boer',
  'boett',
  'bog',
  'boggi',
  'bogrem',
  'bohag',
  'bohem',
  'boj',
  'boja',
  'bojar',
  'bok',
  'boka',
  'boke',
  'boken',
  'bokna',
  'bol',
  'bola',
  'bolag',
  'boleri',
  'bolin',
  'boll',
  'bolla',
  'bolma',
  'bom',
  'bomb',
  'bomba',
  'bomma',
  'bona',
  'bonad',
  'bonde',
  'bondsk',
  'bong',
  'bonga',
  'bonus',
  'boom',
  'boots',
  'bop',
  'bor',
  'borax',
  'bord',
  'borda',
  'boren',
  'borg',
  'borga',
  'borgen',
  'borr',
  'borra',
  'borst',
  'bort',
  'borta',
  'bortre',
  'bosch',
  'boss',
  'bostad',
  'bot',
  'bota',
  'boule',
  'bov',
  'bovete',
  'bowla',
  'box',
  'boxa',
  'boxas',
  'boxer',
  'boyta',
  'bp',
  'bq',
  'br',
  'bra',
  'bragd',
  'braja',
  'brak',
  'braka',
  'brand',
  'brant',
  'brasa',
  'braska',
  'brass',
  'bravo',
  'bre',
  'break',
  'bred',
  'breda',
  'bredd',
  'bredda',
  'brev',
  'brie',
  'brigad',
  'brigg',
  'brink',
  'brio',
  'bris',
  'brisa',
  'brist',
  'brits',
  'britt',
  'bro',
  'broa',
  'brodd',
  'broder',
  'brok',
  'brom',
  'broms',
  'bronk',
  'brons',
  'bror',
  'brorsa',
  'brosk',
  'brott',
  'brr',
  'brud',
  'brugd',
  'bruk',
  'bruka',
  'brum',
  'brun',
  'brunn',
  'brunt',
  'brunte',
  'brus',
  'brusa',
  'bry',
  'brydd',
  'brygd',
  'bryn',
  'bryna',
  'bryne',
  'brynja',
  'brysk',
  'bryta',
  'bs',
  'bt',
  'bu',
  'bua',
  'bubon',
  'bud',
  'buda',
  'budbil',
  'budo',
  'buff',
  'buffa',
  'buffra',
  'buga',
  'bugg',
  'bugga',
  'buk',
  'bukig',
  'bukt',
  'bukta',
  'bula',
  'bulb',
  'bulgar',
  'bulk',
  'bulla',
  'bulle',
  'bulna',
  'bult',
  'bulta',
  'bulvan',
  'bums',
  'bunge',
  'bunke',
  'bunt',
  'bunta',
  'bur',
  'bura',
  'burk',
  'burka',
  'burkig',
  'burop',
  'burr',
  'burra',
  'bus',
  'busa',
  'buse',
  'bush',
  'busig',
  'busk',
  'buska',
  'buske',
  'buskis',
  'buss',
  'bussa',
  'butan',
  'butik',
  'butter',
  'butyl',
  'bv',
  'bw',
  'bx',
  'by',
  'bybo',
  'bygd',
  'bygel',
  'bygga',
  'bygge',
  'byig',
  'byk',
  'byka',
  'byke',
  'byling',
  'bylta',
  'bylte',
  'byst',
  'byta',
  'bytbar',
  'byte',
  'bytta',
  'byxa',
  'byxis',
  'bz',
  'c',
  'camp',
  'campa',
  'campus',
  'cape',
  'cb',
  'cc',
  'ccc',
  'cccc',
  'cd',
  'cde',
  'ce',
  'ceder',
  'cedera',
  'cell',
  'cello',
  'census',
  'cent',
  'cerat',
  'cert',
  'cess',
  'cesur',
  'cf',
  'cg',
  'ch',
  'chans',
  'chansa',
  'char',
  'chark',
  'charm',
  'check',
  'checka',
  'chef',
  'chefa',
  'chic',
  'chick',
  'chips',
  'chock',
  'choka',
  'choke',
  'chosa',
  'chosig',
  'chuck',
  'ci',
  'cider',
  'cif',
  'cigg',
  'cilie',
  'cirka',
  'cirkus',
  'ciss',
  'citat',
  'citrin',
  'city',
  'civil',
  'cj',
  'ck',
  'cl',
  'clips',
  'clou',
  'clown',
  'cm',
  'cn',
  'co',
  'coach',
  'coda',
  'cognac',
  'cool',
  'cord',
  'coupe',
  'cour',
  'cover',
  'cox',
  'cp',
  'cq',
  'cr',
  'crack',
  'crawl',
  'crazy',
  'credo',
  'cross',
  'cs',
  'ct',
  'cu',
  'culpa',
  'cup',
  'curium',
  'curla',
  'curry',
  'cv',
  'cw',
  'cx',
  'cy',
  'cyan',
  'cykel',
  'cykla',
  'cymbal',
  'cysta',
  'cz',
  'd',
  'dabba',
  'dadda',
  'dadel',
  'dag',
  'dagas',
  'dager',
  'dagg',
  'dagga',
  'daggig',
  'dagis',
  'dags',
  'dahlia',
  'dal',
  'dala',
  'dalbo',
  'daler',
  'dalj',
  'dalsk',
  'dalska',
  'dalt',
  'dalta',
  'dam',
  'damig',
  'damm',
  'damma',
  'dampa',
  'damrum',
  'dan',
  'dana',
  'dandy',
  'daner',
  'dank',
  'dann',
  'dans',
  'dansa',
  'dansk',
  'danska',
  'darr',
  'darra',
  'dart',
  'dask',
  'daska',
  'dass',
  'data',
  'dater',
  'dativ',
  'datja',
  'dato',
  'dator',
  'datt',
  'datum',
  'db',
  'dc',
  'dd',
  'ddd',
  'dddd',
  'ddt',
  'de',
  'debet',
  'debil',
  'debut',
  'def',
  'defekt',
  'deg',
  'dega',
  'degel',
  'degig',
  'deism',
  'deist',
  'dej',
  'deja',
  'dejsa',
  'deka',
  'dekad',
  'dekal',
  'dekan',
  'dekis',
  'dekor',
  'del',
  'dela',
  'delad',
  'delfin',
  'delge',
  'delo',
  'dels',
  'delta',
  'dem',
  'demens',
  'demon',
  'den',
  'denar',
  'denim',
  'denne',
  'deppa',
  'deppad',
  'deras',
  'derby',
  'dess',
  'desto',
  'det',
  'deuce',
  'devis',
  'devon',
  'devot',
  'df',
  'dg',
  'dh',
  'di',
  'dia',
  'diadem',
  'diesel',
  'diet',
  'dig',
  'digel',
  'diger',
  'digga',
  'digna',
  'dika',
  'dike',
  'dikt',
  'dikta',
  'diktat',
  'dill',
  'dilla',
  'dille',
  'dimma',
  'dimpa',
  'din',
  'dinar',
  'ding',
  'dinge',
  'dingla',
  'dingo',
  'diod',
  'direkt',
  'dirk',
  'dirka',
  'dirra',
  'dis',
  'disig',
  'disk',
  'diska',
  'disko',
  'diss',
  'dit',
  'dito',
  'ditt',
  'diva',
  'divan',
  'divig',
  'divis',
  'dixie',
  'dj',
  'djinn',
  'djonk',
  'djup',
  'djur',
  'dk',
  'dl',
  'dm',
  'dn',
  'dna',
  'do',
  'doa',
  'dobbla',
  'dock',
  'docka',
  'doft',
  'dofta',
  'doge',
  'dogg',
  'dogm',
  'doja',
  'dojo',
  'dok',
  'dold',
  'dolk',
  'dolma',
  'dolme',
  'dolsk',
  'dolus',
  'dom',
  'domare',
  'domna',
  'don',
  'dona',
  'donkey',
  'donna',
  'dop',
  'dopa',
  'dopp',
  'doppa',
  'dorer',
  'dorn',
  'dorsk',
  'dos',
  'dosa',
  'dosera',
  'dosis',
  'dov',
  'doyen',
  'dp',
  'dq',
  'dr',
  'dra',
  'drafta',
  'drag',
  'draga',
  'dragg',
  'drake',
  'drakma',
  'dram',
  'drama',
  'drank',
  'drapa',
  'dras',
  'drav',
  'dregel',
  'dreja',
  'dress',
  'drev',
  'dreva',
  'drift',
  'drill',
  'drilla',
  'drink',
  'driv',
  'driva',
  'drive',
  'drog',
  'droga',
  'dront',
  'dropp',
  'droska',
  'drots',
  'drott',
  'druid',
  'drul',
  'drus',
  'druva',
  'dryad',
  'dryck',
  'dryg',
  'dryga',
  'drypa',
  'ds',
  'dt',
  'du',
  'dua',
  'dual',
  'dualis',
  'dubb',
  'dubba',
  'ducka',
  'duell',
  'duenna',
  'duett',
  'duga',
  'dugg',
  'dugga',
  'duk',
  'duka',
  'dukat',
  'dukt',
  'duktyg',
  'dum',
  'duman',
  'dumma',
  'dummy',
  'dumpa',
  'dun',
  'duna',
  'dundra',
  'dunge',
  'dunig',
  'dunk',
  'dunka',
  'duns',
  'dunsa',
  'dunst',
  'duo',
  'dur',
  'durk',
  'durka',
  'durra',
  'dus',
  'dusch',
  'dusk',
  'duska',
  'duskap',
  'dust',
  'dutta',
  'duva',
  'duven',
  'duvna',
  'dv',
  'dvala',
  'dw',
  'dx',
  'dy',
  'dyft',
  'dygd',
  'dygn',
  'dyig',
  'dyk',
  'dyka',
  'dykand',
  'dylik',
  'dyn',
  'dyna',
  'dynga',
  'dynkam',
  'dynt',
  'dyr',
  'dyrk',
  'dyrka',
  'dyrort',
  'dysa',
  'dz',
  'e',
  'eagle',
  'earl',
  'eb',
  'ebb',
  'ebba',
  'ebbtid',
  'ec',
  'ecu',
  'ed',
  'eda',
  'edda',
  'eder',
  'edera',
  'edikt',
  'edil',
  'edlig',
  'ee',
  'eee',
  'eeee',
  'eeg',
  'ef',
  'efg',
  'efor',
  'efter',
  'eg',
  'egal',
  'egeisk',
  'egen',
  'egg',
  'egga',
  'egid',
  'ego',
  'eh',
  'eho',
  'ehuru',
  'ei',
  'eiss',
  'ej',
  'eja',
  'ejder',
  'ek',
  'eka',
  'eke',
  'eker',
  'ekfat',
  'ekg',
  'ekivok',
  'eklog',
  'eklut',
  'eko',
  'ekorre',
  'ekoxe',
  'eksem',
  'el',
  'elak',
  'elbil',
  'eld',
  'elda',
  'elddon',
  'eldig',
  'elegi',
  'elev',
  'elfte',
  'elit',
  'eller',
  'ellips',
  'ellok',
  'eloge',
  'eluera',
  'elugn',
  'elva',
  'em',
  'emalj',
  'emedan',
  'emfas',
  'emir',
  'emma',
  'emot',
  'empir',
  'empire',
  'emu',
  'en',
  'ena',
  'enare',
  'enas',
  'enbar',
  'enbo',
  'enda',
  'ende',
  'endels',
  'endiv',
  'ene',
  'enfas',
  'enhet',
  'enig',
  'ening',
  'enkel',
  'enkelt',
  'enkla',
  'enkom',
  'enorm',
  'enris',
  'enrum',
  'ens',
  'ensak',
  'ensam',
  'ensamt',
  'ense',
  'ental',
  'entums',
  'envar',
  'envig',
  'envis',
  'enzym',
  'eo',
  'eocen',
  'eoler',
  'eon',
  'eosin',
  'ep',
  'epik',
  'epilog',
  'episk',
  'epok',
  'epos',
  'eq',
  'er',
  'era',
  'eran',
  'erfara',
  'ergo',
  'es',
  'espri',
  'esprit',
  'ess',
  'essay',
  'esse',
  'est',
  'ester',
  'estet',
  'et',
  'etage',
  'etapp',
  'eten',
  'eter',
  'etik',
  'etisk',
  'etolog',
  'etsa',
  'ett',
  'etta',
  'etter',
  'etui',
  'etyd',
  'etyl',
  'eu',
  'eunuck',
  'euro',
  'ev',
  'eva',
  'evad',
  'evig',
  'ew',
  'ex',
  'exa',
  'exakt',
  'exil',
  'exkung',
  'expo',
  'export',
  'extas',
  'extra',
  'ey',
  'ez',
  'f',
  'fabel',
  'fabla',
  'facil',
  'facit',
  'fack',
  'fackla',
  'fadd',
  'fader',
  'faga',
  'fager',
  'fajt',
  'fajter',
  'fakir',
  'fakta',
  'fal',
  'falk',
  'falka',
  'fall',
  'falla',
  'fallen',
  'falna',
  'fals',
  'falsa',
  'falsk',
  'famla',
  'famn',
  'famna',
  'fan',
  'fana',
  'faner',
  'fanken',
  'fant',
  'far',
  'fara',
  'farad',
  'farao',
  'faren',
  'farin',
  'farlig',
  'farm',
  'farma',
  'fars',
  'farsa',
  'fart',
  'fas',
  'fasa',
  'fasad',
  'fasan',
  'fasen',
  'faskin',
  'fason',
  'fast',
  'fasta',
  'fat',
  'fatal',
  'fatt',
  'fatta',
  'fattas',
  'faun',
  'fauna',
  'fax',
  'faxa',
  'fb',
  'fc',
  'fd',
  'fe',
  'feber',
  'feeri',
  'feg',
  'fegis',
  'feja',
  'fejd',
  'fejka',
  'fejkad',
  'fejs',
  'fekal',
  'fel',
  'fela',
  'felas',
  'fem',
  'femma',
  'femmil',
  'femte',
  'femti',
  'fena',
  'fenol',
  'fenyl',
  'feodal',
  'ferie',
  'ferm',
  'fess',
  'fest',
  'festa',
  'fet',
  'feta',
  'fetera',
  'fetma',
  'fetna',
  'fett',
  'fez',
  'ff',
  'fff',
  'ffff',
  'fg',
  'fgh',
  'fh',
  'fi',
  'fia',
  'fial',
  'fiber',
  'fibros',
  'ficka',
  'fidus',
  'fiffa',
  'fiffig',
  'fight',
  'figur',
  'fik',
  'fika',
  'fiken',
  'fikon',
  'fikus',
  'fil',
  'fila',
  'filea',
  'filler',
  'film',
  'filma',
  'filt',
  'filta',
  'filur',
  'fimp',
  'fimpa',
  'fin',
  'final',
  'finger',
  'finit',
  'fink',
  'finka',
  'finlir',
  'finna',
  'finne',
  'finsk',
  'finsko',
  'fint',
  'finta',
  'fiol',
  'fira',
  'firma',
  'firn',
  'firre',
  'fis',
  'fisa',
  'fisig',
  'fisk',
  'fiska',
  'fiske',
  'fiss',
  'fitta',
  'fix',
  'fixa',
  'fixare',
  'fj',
  'fjant',
  'fjol',
  'fjor',
  'fjord',
  'fjun',
  'fjunig',
  'fk',
  'fl',
  'flabb',
  'flack',
  'flad',
  'flaga',
  'flagg',
  'flagra',
  'flak',
  'flake',
  'flams',
  'flank',
  'flark',
  'flarn',
  'flat',
  'flata',
  'flau',
  'flax',
  'flaxa',
  'flaxig',
  'flen',
  'fler',
  'flera',
  'flere',
  'flest',
  'flexa',
  'flik',
  'flika',
  'flikig',
  'flin',
  'flina',
  'flink',
  'flint',
  'flirt',
  'flis',
  'flisa',
  'flisig',
  'flit',
  'flita',
  'flo',
  'flock',
  'flod',
  'flopp',
  'flor',
  'flora',
  'flott',
  'flotta',
  'flow',
  'flox',
  'fluff',
  'fluga',
  'fluid',
  'flukt',
  'flum',
  'flumma',
  'fluor',
  'fluss',
  'flux',
  'fly',
  'flyg',
  'flyga',
  'flygg',
  'flykt',
  'flyt',
  'flyta',
  'flytt',
  'fm',
  'fn',
  'fnas',
  'fnasa',
  'fnask',
  'fnaska',
  'fnatt',
  'fniss',
  'fnyk',
  'fnysa',
  'fo',
  'fob',
  'fobi',
  'fock',
  'focka',
  'foder',
  'fodra',
  'fog',
  'foga',
  'fogde',
  'fogfri',
  'fokal',
  'fokus',
  'folie',
  'folio',
  'folk',
  'folka',
  'fon',
  'fond',
  'fondy',
  'fonem',
  'fora',
  'fordom',
  'form',
  'forma',
  'formel',
  'forn',
  'fors',
  'forsa',
  'fort',
  'forta',
  'forte',
  'forum',
  'fosfat',
  'fot',
  'fota',
  'fotbad',
  'foto',
  'foton',
  'foul',
  'fp',
  'fq',
  'fr',
  'frack',
  'frakt',
  'frakta',
  'fram',
  'frami',
  'franc',
  'frank',
  'franko',
  'frans',
  'fras',
  'frasa',
  'fred',
  'freda',
  'frejd',
  'freon',
  'fresk',
  'fresta',
  'frett',
  'fri',
  'fria',
  'frid',
  'frige',
  'frilla',
  'fris',
  'frisk',
  'frist',
  'frita',
  'fritis',
  'frodig',
  'from',
  'frond',
  'front',
  'frost',
  'fru',
  'frua',
  'fruga',
  'frugal',
  'frukt',
  'frusa',
  'fryle',
  'fryna',
  'frys',
  'frysa',
  'fryst',
  'fs',
  'ft',
  'fu',
  'fubbla',
  'fuga',
  'fukt',
  'fukta',
  'ful',
  'fuling',
  'full',
  'fullo',
  'fulna',
  'fumla',
  'funk',
  'funka',
  'funkig',
  'funt',
  'fur',
  'fura',
  'furie',
  'furir',
  'furor',
  'furst',
  'furu',
  'fusk',
  'fuska',
  'futil',
  'futt',
  'futta',
  'futtig',
  'fux',
  'fv',
  'fw',
  'fx',
  'fy',
  'fyka',
  'fylka',
  'fylke',
  'fylla',
  'fylld',
  'fylle',
  'fyllna',
  'fyllo',
  'fynd',
  'fynda',
  'fyr',
  'fyra',
  'fyrk',
  'fyrop',
  'fyrti',
  'fysik',
  'fysisk',
  'fz',
  'g',
  'gadd',
  'gadda',
  'gag',
  'gagat',
  'gage',
  'gagg',
  'gagga',
  'gaggig',
  'gagn',
  'gagna',
  'gaj',
  'gala',
  'galax',
  'galej',
  'galen',
  'galge',
  'gall',
  'galla',
  'galler',
  'galon',
  'galopp',
  'galt',
  'galva',
  'gam',
  'gama',
  'gamba',
  'game',
  'gamet',
  'gamig',
  'gamma',
  'gan',
  'gana',
  'gans',
  'gap',
  'gapa',
  'gapig',
  'gapsax',
  'gara',
  'gard',
  'garde',
  'garn',
  'garv',
  'garva',
  'garvad',
  'gas',
  'gasa',
  'gasad',
  'gask',
  'gaska',
  'gasol',
  'gass',
  'gassa',
  'gast',
  'gasta',
  'gata',
  'gate',
  'gathus',
  'gatt',
  'gavel',
  'gavla',
  'gay',
  'gb',
  'gc',
  'gd',
  'ge',
  'gebit',
  'gecko',
  'gegga',
  'geist',
  'gejd',
  'gejser',
  'gel',
  'gelea',
  'gem',
  'gemak',
  'gemen',
  'gems',
  'gemyt',
  'gen',
  'gena',
  'genbo',
  'genera',
  'genes',
  'geni',
  'genie',
  'genom',
  'genre',
  'genta',
  'gentil',
  'genua',
  'genus',
  'german',
  'gers',
  'ges',
  'gess',
  'gest',
  'get',
  'getto',
  'gf',
  'gg',
  'ggg',
  'gggg',
  'gh',
  'ghanan',
  'ghi',
  'gi',
  'gift',
  'gifta',
  'gifte',
  'giga',
  'gigant',
  'gigg',
  'gikt',
  'gilja',
  'gill',
  'gilla',
  'gille',
  'gin',
  'gina',
  'ginkgo',
  'ginst',
  'gip',
  'gipa',
  'gipp',
  'gippa',
  'gips',
  'gipsa',
  'gipyr',
  'gir',
  'gira',
  'girig',
  'giro',
  'giss',
  'gissa',
  'gist',
  'gista',
  'gistna',
  'gitta',
  'giv',
  'giva',
  'givas',
  'given',
  'givet',
  'gj',
  'gjord',
  'gjuse',
  'gjut',
  'gjuta',
  'gk',
  'gl',
  'glaci',
  'glacis',
  'glad',
  'glada',
  'glam',
  'glan',
  'glana',
  'glans',
  'glapp',
  'glas',
  'glasa',
  'glass',
  'glasyr',
  'glatt',
  'glav',
  'gles',
  'glesa',
  'glete',
  'gli',
  'glia',
  'glid',
  'glida',
  'glim',
  'glima',
  'glimt',
  'gliom',
  'glipa',
  'glira',
  'glis',
  'glisa',
  'glo',
  'glob',
  'global',
  'glop',
  'glopp',
  'glosa',
  'gloso',
  'glufsa',
  'glugg',
  'glunt',
  'glupa',
  'glutt',
  'glycin',
  'glytt',
  'gm',
  'gn',
  'gnabb',
  'gnag',
  'gnaga',
  'gnat',
  'gnata',
  'gnava',
  'gnejs',
  'gnek',
  'gneka',
  'gnet',
  'gneta',
  'gnida',
  'gniden',
  'gnist',
  'gno',
  'gnol',
  'gnola',
  'gnom',
  'gnu',
  'gnuko',
  'gny',
  'go',
  'god',
  'godbit',
  'gode',
  'godis',
  'gods',
  'godta',
  'goja',
  'golf',
  'golfa',
  'golv',
  'golva',
  'golvur',
  'gom',
  'gona',
  'gonad',
  'gorm',
  'gorma',
  'gorr',
  'gosa',
  'gosig',
  'gosing',
  'gosse',
  'got',
  'gotik',
  'gotsk',
  'gott',
  'gotta',
  'gp',
  'gq',
  'gr',
  'graal',
  'grabb',
  'grace',
  'gracer',
  'grad',
  'grada',
  'graf',
  'grain',
  'gral',
  'gram',
  'gran',
  'granat',
  'grand',
  'grann',
  'grav',
  'grava',
  'gravid',
  'green',
  'grej',
  'greja',
  'grek',
  'gren',
  'grena',
  'grep',
  'grepe',
  'grepp',
  'greve',
  'grift',
  'grill',
  'grilla',
  'grin',
  'grina',
  'grind',
  'grip',
  'gripa',
  'gris',
  'grisa',
  'grisig',
  'grisk',
  'gro',
  'groda',
  'grodd',
  'grogg',
  'groll',
  'grop',
  'gross',
  'grotta',
  'grov',
  'gruff',
  'grums',
  'grund',
  'grunda',
  'grupp',
  'grus',
  'grusa',
  'gruva',
  'gry',
  'grym',
  'grymt',
  'gryn',
  'gryna',
  'grynna',
  'gryt',
  'gryta',
  'gs',
  'gt',
  'gu',
  'guano',
  'guava',
  'gubba',
  'gubbe',
  'gubbs',
  'gud',
  'gudmor',
  'gudom',
  'guida',
  'guide',
  'gul',
  'gula',
  'guld',
  'guling',
  'gull',
  'gulla',
  'gulle',
  'gulna',
  'gult',
  'gumma',
  'gummi',
  'gump',
  'gumpa',
  'gumse',
  'gunga',
  'gunst',
  'gupp',
  'guppa',
  'guppig',
  'guppy',
  'gurka',
  'guru',
  'guska',
  'gute',
  'guvet',
  'gv',
  'gw',
  'gx',
  'gy',
  'gylf',
  'gyllne',
  'gylta',
  'gym',
  'gympa',
  'gynna',
  'gyro',
  'gz',
  'h',
  'habil',
  'habit',
  'hack',
  'hacka',
  'hackig',
  'haffa',
  'hafs',
  'hafsa',
  'hag',
  'hage',
  'hagel',
  'hagga',
  'hagla',
  'haha',
  'haiku',
  'haj',
  'haja',
  'hajk',
  'hajka',
  'hak',
  'haka',
  'hake',
  'hakig',
  'hal',
  'hala',
  'halis',
  'halka',
  'hall',
  'halm',
  'halma',
  'halmig',
  'halo',
  'hals',
  'halsa',
  'halt',
  'halta',
  'halv',
  'halva',
  'hambo',
  'hamit',
  'hamla',
  'hamn',
  'hamna',
  'hampa',
  'hamra',
  'han',
  'hand',
  'hands',
  'hane',
  'hang',
  'hangar',
  'hank',
  'hanka',
  'hanne',
  'hans',
  'hansa',
  'happ',
  'harang',
  'hare',
  'harem',
  'harg',
  'harig',
  'harka',
  'harm',
  'harma',
  'harpa',
  'harr',
  'hart',
  'harts',
  'harv',
  'harva',
  'has',
  'hasa',
  'hasard',
  'hasch',
  'hasp',
  'haspa',
  'hast',
  'hasta',
  'hastig',
  'hat',
  'hata',
  'hatt',
  'hatta',
  'hav',
  'hava',
  'haveri',
  'havre',
  'hb',
  'hc',
  'hd',
  'he',
  'heat',
  'hed',
  'heden',
  'heder',
  'hedra',
  'hej',
  'heja',
  'hejd',
  'hejda',
  'hejig',
  'hekto',
  'hel',
  'hela',
  'helbom',
  'helg',
  'helga',
  'helgd',
  'helhet',
  'helig',
  'hell',
  'helna',
  'helot',
  'helrim',
  'helst',
  'hem',
  'hembud',
  'hemby',
  'hemma',
  'hemman',
  'hemom',
  'hemsk',
  'hemul',
  'hemvan',
  'hen',
  'hena',
  'henna',
  'henne',
  'herde',
  'herm',
  'heros',
  'herr',
  'herran',
  'herre',
  'herse',
  'hertz',
  'hes',
  'het',
  'heta',
  'hets',
  'hetsa',
  'hetsig',
  'hetta',
  'hf',
  'hg',
  'hh',
  'hhh',
  'hhhh',
  'hi',
  'hicka',
  'hihi',
  'hij',
  'hilka',
  'himla',
  'hin',
  'hind',
  'hindi',
  'hindra',
  'hindu',
  'hink',
  'hinka',
  'hinna',
  'hipp',
  'hippa',
  'hird',
  'hirs',
  'hisna',
  'hiss',
  'hissa',
  'hissna',
  'hit',
  'hitom',
  'hitre',
  'hitta',
  'hiv',
  'hiva',
  'hj',
  'hjalt',
  'hjon',
  'hjord',
  'hjort',
  'hjul',
  'hjula',
  'hk',
  'hl',
  'hm',
  'hn',
  'ho',
  'hoa',
  'hobby',
  'hoj',
  'hoja',
  'hojt',
  'hojta',
  'holk',
  'holka',
  'holma',
  'holme',
  'holt',
  'homma',
  'hon',
  'hona',
  'honom',
  'hop',
  'hopa',
  'hopas',
  'hoper',
  'hopkok',
  'hopp',
  'hoppa',
  'hor',
  'hora',
  'hord',
  'horeri',
  'horn',
  'horst',
  'hos',
  'hosa',
  'hosta',
  'hostig',
  'hot',
  'hota',
  'houri',
  'hov',
  'hoveri',
  'hovra',
  'hp',
  'hq',
  'hr',
  'hs',
  'ht',
  'hu',
  'hud',
  'huda',
  'hug',
  'huga',
  'hugad',
  'hugg',
  'hugga',
  'hugna',
  'hugnad',
  'huj',
  'huk',
  'huka',
  'huld',
  'hulka',
  'hull',
  'hult',
  'hum',
  'human',
  'humid',
  'humla',
  'humle',
  'humma',
  'humor',
  'humus',
  'hund',
  'hundan',
  'hunsa',
  'hur',
  'huri',
  'hurr',
  'hurra',
  'hurril',
  'hurts',
  'huru',
  'hus',
  'husa',
  'husar',
  'husch',
  'husfar',
  'husly',
  'husman',
  'husse',
  'hustru',
  'hut',
  'huta',
  'hutt',
  'hutta',
  'hutu',
  'huv',
  'huva',
  'huve',
  'huvud',
  'hv',
  'hw',
  'hx',
  'hy',
  'hybble',
  'hydda',
  'hydra',
  'hyena',
  'hyende',
  'hyf',
  'hyfs',
  'hyfsa',
  'hygge',
  'hyl',
  'hyla',
  'hylla',
  'hylle',
  'hylsa',
  'hymen',
  'hymla',
  'hymn',
  'hynda',
  'hyra',
  'hysa',
  'hysch',
  'hyscha',
  'hyska',
  'hyss',
  'hysta',
  'hytt',
  'hytta',
  'hyva',
  'hyvel',
  'hyvla',
  'hz',
  'i',
  'ib',
  'iber',
  'ibis',
  'ibland',
  'ic',
  'icing',
  'icke',
  'id',
  'ida',
  'idag',
  'idas',
  'ide',
  'ideal',
  'idel',
  'idiom',
  'idiot',
  'idka',
  'ido',
  'idog',
  'idol',
  'idyll',
  'ie',
  'if',
  'ifall',
  'ifatt',
  'ifjol',
  'ifjor',
  'ifred',
  'ifylla',
  'ig',
  'igel',
  'igen',
  'iglo',
  'igloo',
  'ih',
  'ihop',
  'ii',
  'iii',
  'iiii',
  'ij',
  'ijk',
  'ik',
  'ikapp',
  'iklyv',
  'ikon',
  'iktus',
  'ikull',
  'il',
  'ila',
  'iland',
  'ilbud',
  'ilex',
  'ilfart',
  'illa',
  'iller',
  'ilsk',
  'ilska',
  'ilskna',
  'im',
  'image',
  'imago',
  'imam',
  'imfri',
  'imma',
  'immig',
  'immun',
  'in',
  'ina',
  'inatt',
  'inavel',
  'incest',
  'index',
  'indie',
  'indigo',
  'indra',
  'inert',
  'infall',
  'infam',
  'infix',
  'info',
  'inge',
  'ingen',
  'ingiva',
  'inhav',
  'ini',
  'injaga',
  'ink',
  'inka',
  'inkok',
  'inlagd',
  'inmat',
  'inmata',
  'innan',
  'inne',
  'inner',
  'inom',
  'inpass',
  'input',
  'inre',
  'inrim',
  'inrita',
  'inrop',
  'insats',
  'inse',
  'insult',
  'insyn',
  'inta',
  'intag',
  'inte',
  'intern',
  'intet',
  'intig',
  'intim',
  'intyg',
  'inuit',
  'inuti',
  'inval',
  'invid',
  'inviga',
  'invit',
  'io',
  'ip',
  'iq',
  'ir',
  'irer',
  'iris',
  'irisk',
  'irokes',
  'ironi',
  'irra',
  'is',
  'isa',
  'isas',
  'isbill',
  'isbit',
  'isch',
  'isfri',
  'ishall',
  'ishav',
  'isig',
  'iskarl',
  'iskub',
  'islag',
  'islam',
  'ism',
  'isnot',
  'isogon',
  'isop',
  'ispik',
  'istapp',
  'iste',
  'ister',
  'istid',
  'isvak',
  'isvit',
  'isydd',
  'isyxa',
  'it',
  'itu',
  'ity',
  'iu',
  'iv',
  'iver',
  'ivra',
  'ivran',
  'ivrig',
  'iw',
  'ix',
  'iy',
  'iz',
  'j',
  'jaa',
  'jabb',
  'jack',
  'jacka',
  'jade',
  'jag',
  'jaga',
  'jaguar',
  'jaha',
  'jaja',
  'jak',
  'jaka',
  'jakko',
  'jakt',
  'jalu',
  'jam',
  'jama',
  'jamb',
  'jamen',
  'jamma',
  'jams',
  'jamsa',
  'jan',
  'jaord',
  'japan',
  'jarl',
  'jarop',
  'jaspis',
  'java',
  'jazz',
  'jazza',
  'jb',
  'jc',
  'jd',
  'je',
  'jeans',
  'jeep',
  'jehu',
  'jenka',
  'jeppe',
  'jet',
  'jetset',
  'jf',
  'jg',
  'jh',
  'ji',
  'jigg',
  'jippo',
  'jj',
  'jjj',
  'jjjj',
  'jk',
  'jkl',
  'jl',
  'jm',
  'jn',
  'jo',
  'jobb',
  'jobba',
  'jod',
  'jodda',
  'jodera',
  'jogga',
  'joho',
  'jojk',
  'jojka',
  'jojo',
  'joker',
  'jokk',
  'jolle',
  'jolm',
  'jolt',
  'jolta',
  'jomen',
  'jon',
  'joner',
  'joo',
  'jord',
  'jorda',
  'jordbi',
  'jota',
  'joule',
  'jour',
  'jox',
  'joxa',
  'joxig',
  'jp',
  'jq',
  'jr',
  'js',
  'jt',
  'ju',
  'jubel',
  'jubla',
  'jucka',
  'jude',
  'judo',
  'juft',
  'juice',
  'jul',
  'jula',
  'juldag',
  'juli',
  'julig',
  'julle',
  'julros',
  'jumbo',
  'jumpa',
  'juni',
  'junta',
  'jura',
  'juris',
  'jurist',
  'jurt',
  'jurta',
  'jury',
  'just',
  'juste',
  'jute',
  'jutsk',
  'juvel',
  'juver',
  'jv',
  'jw',
  'jx',
  'jy',
  'jycke',
  'jympa',
  'jz',
  'k',
  'kabal',
  'kabel',
  'kabin',
  'kabla',
  'kabyl',
  'kacka',
  'kadens',
  'kader',
  'kadi',
  'kaffe',
  'kagge',
  'kaj',
  'kaja',
  'kajak',
  'kajbo',
  'kajka',
  'kajuta',
  'kaka',
  'kakao',
  'kakel',
  'kaki',
  'kakla',
  'kal',
  'kalas',
  'kalasa',
  'kali',
  'kalif',
  'kalk',
  'kalka',
  'kall',
  'kalla',
  'kallim',
  'kalna',
  'kalufs',
  'kaluv',
  'kalv',
  'kalva',
  'kam',
  'kamel',
  'kamin',
  'kamma',
  'kamp',
  'kams',
  'kamull',
  'kan',
  'kana',
  'kanak',
  'kanal',
  'kandi',
  'kanel',
  'kanik',
  'kanin',
  'kanna',
  'kanon',
  'kanot',
  'kant',
  'kanta',
  'kantat',
  'kanvas',
  'kanyl',
  'kaos',
  'kap',
  'kapa',
  'kapock',
  'kapok',
  'kapp',
  'kappa',
  'kappe',
  'kapsla',
  'kapun',
  'kar',
  'kara',
  'karaff',
  'karat',
  'karbol',
  'karda',
  'karel',
  'karess',
  'karet',
  'karg',
  'karib',
  'karit',
  'karl',
  'karm',
  'kaross',
  'karp',
  'karpa',
  'karsk',
  'karst',
  'kart',
  'karta',
  'karva',
  'kas',
  'kasa',
  'kase',
  'kasern',
  'kask',
  'kasko',
  'kass',
  'kassa',
  'kasse',
  'kast',
  'kasta',
  'kastby',
  'kasus',
  'katet',
  'katig',
  'katod',
  'katsa',
  'katse',
  'katt',
  'katta',
  'katten',
  'kauri',
  'kaus',
  'kav',
  'kava',
  'kavaj',
  'kavat',
  'kavel',
  'kavern',
  'kavla',
  'kavle',
  'kaxa',
  'kaxe',
  'kaxig',
  'kazak',
  'kb',
  'kc',
  'kd',
  'ke',
  'kebab',
  'ked',
  'kediv',
  'kedja',
  'kefir',
  'kel',
  'kela',
  'kelen',
  'kelig',
  'kelim',
  'kelp',
  'kemi',
  'kendo',
  'kenyan',
  'keps',
  'keram',
  'kerub',
  'kesa',
  'keso',
  'ketch',
  'kex',
  'kf',
  'kg',
  'kh',
  'khmer',
  'ki',
  'kiasm',
  'kick',
  'kicka',
  'kid',
  'kik',
  'kika',
  'kikna',
  'kil',
  'kila',
  'killa',
  'kille',
  'kilo',
  'kilrem',
  'kilt',
  'kimma',
  'kina',
  'kind',
  'kines',
  'kinin',
  'kink',
  'kinka',
  'kiosk',
  'kip',
  'kipa',
  'kippa',
  'kirra',
  'kis',
  'kisa',
  'kisel',
  'kiss',
  'kissa',
  'kisse',
  'kista',
  'kitin',
  'kitsch',
  'kitt',
  'kitta',
  'kiv',
  'kiva',
  'kivas',
  'kivi',
  'kiwi',
  'kix',
  'kixa',
  'kj',
  'kjol',
  'kjusa',
  'kk',
  'kkk',
  'kkkk',
  'kl',
  'klabb',
  'klack',
  'kladd',
  'kladda',
  'klaff',
  'klafs',
  'klaga',
  'klak',
  'klam',
  'klamp',
  'klamra',
  'klan',
  'klang',
  'klank',
  'klant',
  'klapp',
  'klar',
  'klara',
  'klase',
  'klass',
  'klatt',
  'klav',
  'klava',
  'klave',
  'klaver',
  'klema',
  'klen',
  'klena',
  'klerk',
  'klet',
  'kleta',
  'klev',
  'kli',
  'klia',
  'klibb',
  'klick',
  'klient',
  'klifs',
  'klimp',
  'kling',
  'klinik',
  'klink',
  'klint',
  'klipp',
  'klirr',
  'klitt',
  'kliv',
  'kliva',
  'klm',
  'klo',
  'kloak',
  'klok',
  'klolik',
  'klon',
  'klona',
  'klor',
  'kloss',
  'klot',
  'klots',
  'klott',
  'klotta',
  'klov',
  'klove',
  'klubb',
  'kluck',
  'kludd',
  'klump',
  'klunk',
  'klunka',
  'kluns',
  'klura',
  'klut',
  'kluta',
  'klyft',
  'klyka',
  'klynne',
  'klys',
  'klyva',
  'km',
  'kn',
  'knack',
  'knagg',
  'knak',
  'knaka',
  'knal',
  'knall',
  'knap',
  'knape',
  'knaper',
  'knapp',
  'knark',
  'knarp',
  'knarr',
  'knarra',
  'knas',
  'knasa',
  'knast',
  'knata',
  'kneg',
  'knega',
  'kneka',
  'knekt',
  'knep',
  'knepig',
  'knia',
  'knick',
  'knip',
  'knipa',
  'knips',
  'knirk',
  'knirka',
  'knirr',
  'knisp',
  'kniv',
  'knix',
  'knixa',
  'knixig',
  'knodd',
  'knog',
  'knoga',
  'knoge',
  'knop',
  'knopa',
  'knopp',
  'knorr',
  'knort',
  'knorta',
  'knorv',
  'knot',
  'knota',
  'knott',
  'knubb',
  'knuff',
  'knut',
  'knuta',
  'knutig',
  'knyck',
  'knyla',
  'knyst',
  'knyt',
  'knyta',
  'knyte',
  'knytt',
  'ko',
  'koala',
  'kobbe',
  'kobent',
  'kobra',
  'kock',
  'kocka',
  'kod',
  'koda',
  'kodex',
  'kofen',
  'koff',
  'kofot',
  'kofta',
  'koger',
  'kogg',
  'kohage',
  'koj',
  'koja',
  'kok',
  'koka',
  'kokare',
  'kokfru',
  'kokig',
  'kokos',
  'koks',
  'koksa',
  'koktid',
  'kol',
  'kola',
  'kolik',
  'kolin',
  'kolit',
  'kolja',
  'kolka',
  'koll',
  'kolla',
  'kolli',
  'kollo',
  'kollra',
  'kolm',
  'kolon',
  'kolos',
  'kolt',
  'kolumn',
  'kolv',
  'koma',
  'kombi',
  'komet',
  'komik',
  'komma',
  'komp',
  'kompa',
  'kompis',
  'kon',
  'kona',
  'konfys',
  'konst',
  'konsum',
  'kont',
  'konto',
  'konvoj',
  'kopal',
  'kopek',
  'kopia',
  'kopp',
  'koppa',
  'kopra',
  'kopt',
  'kor',
  'kora',
  'koral',
  'korall',
  'koran',
  'kord',
  'korda',
  'korg',
  'kork',
  'korka',
  'korkek',
  'korn',
  'korna',
  'korp',
  'korpa',
  'korr',
  'kors',
  'korsa',
  'korso',
  'kort',
  'korta',
  'korum',
  'korus',
  'korv',
  'korva',
  'korvig',
  'kos',
  'kosa',
  'kossa',
  'kost',
  'kosta',
  'kota',
  'kotben',
  'kott',
  'kotte',
  'kova',
  'koxa',
  'kp',
  'kpist',
  'kq',
  'kr',
  'kraal',
  'kraba',
  'krabb',
  'krafs',
  'krafsa',
  'kraft',
  'kraga',
  'krage',
  'kraka',
  'krake',
  'kral',
  'kram',
  'krama',
  'kramp',
  'krams',
  'kran',
  'krana',
  'krank',
  'krans',
  'kransa',
  'krapp',
  'kras',
  'krasa',
  'krass',
  'krats',
  'kratt',
  'krav',
  'kravel',
  'krax',
  'kraxa',
  'kreol',
  'kreta',
  'kretin',
  'krets',
  'kria',
  'krig',
  'kriga',
  'krill',
  'kring',
  'kris',
  'krisa',
  'krita',
  'kritig',
  'kroat',
  'krock',
  'krog',
  'krok',
  'kroka',
  'kroki',
  'krokna',
  'krom',
  'kroma',
  'krona',
  'kropp',
  'kross',
  'krubb',
  'kruka',
  'krull',
  'krum',
  'krumma',
  'krupp',
  'krus',
  'krusa',
  'krut',
  'kruta',
  'krux',
  'kruxa',
  'kry',
  'krya',
  'krylla',
  'kryp',
  'krypa',
  'kryss',
  'krysta',
  'ks',
  'kt',
  'ku',
  'kub',
  'kuban',
  'kubb',
  'kubbe',
  'kubik',
  'kuckel',
  'kucku',
  'kudde',
  'kuf',
  'kugg',
  'kugga',
  'kugge',
  'kujon',
  'kuk',
  'kul',
  'kula',
  'kulak',
  'kulen',
  'kuli',
  'kulig',
  'kull',
  'kulla',
  'kulle',
  'kullig',
  'kulm',
  'kulna',
  'kult',
  'kultur',
  'kund',
  'kung',
  'kunna',
  'kupa',
  'kupera',
  'kupig',
  'kupol',
  'kupp',
  'kuppa',
  'kur',
  'kura',
  'kurd',
  'kurer',
  'kurera',
  'kuria',
  'kurir',
  'kurr',
  'kurra',
  'kurre',
  'kurs',
  'kursa',
  'kurva',
  'kusch',
  'kuse',
  'kusin',
  'kusk',
  'kuska',
  'kuslig',
  'kust',
  'kut',
  'kuta',
  'kutig',
  'kuts',
  'kutym',
  'kuva',
  'kuvert',
  'kv',
  'kval',
  'kvala',
  'kvalm',
  'kvar',
  'kvarg',
  'kvark',
  'kvarn',
  'kvart',
  'kvarta',
  'kvass',
  'kvast',
  'kvav',
  'kved',
  'kvesa',
  'kvick',
  'kvida',
  'kvidan',
  'kvidd',
  'kviga',
  'kvint',
  'kvirr',
  'kvist',
  'kvitt',
  'kvitta',
  'kvot',
  'kw',
  'kx',
  'ky',
  'kyffa',
  'kyffe',
  'kyl',
  'kyla',
  'kylig',
  'kylna',
  'kylrum',
  'kylsa',
  'kymig',
  'kynne',
  'kyp',
  'kypert',
  'kyrka',
  'kysk',
  'kyss',
  'kyssa',
  'kytt',
  'kytta',
  'kz',
  'l',
  'labb',
  'laber',
  'labial',
  'labil',
  'lack',
  'lacka',
  'lad',
  'lada',
  'ladda',
  'lady',
  'lafs',
  'lafsa',
  'lag',
  'laga',
  'lagd',
  'lager',
  'lagg',
  'lagga',
  'lagisk',
  'lagom',
  'lagra',
  'lagun',
  'laka',
  'lakan',
  'lake',
  'lakej',
  'lakrom',
  'lakun',
  'lala',
  'lalla',
  'lam',
  'lama',
  'lamm',
  'lamma',
  'lampa',
  'lan',
  'lana',
  'land',
  'landa',
  'landis',
  'laner',
  'lang',
  'langa',
  'lank',
  'lanka',
  'lans',
  'lapa',
  'lapis',
  'lapp',
  'lappa',
  'lappri',
  'lapsk',
  'largo',
  'larm',
  'larma',
  'larv',
  'larva',
  'laser',
  'lasera',
  'lask',
  'laska',
  'lasra',
  'lass',
  'lassa',
  'lasso',
  'last',
  'lasta',
  'lasur',
  'lasyr',
  'lat',
  'lata',
  'later',
  'latex',
  'latin',
  'latta',
  'lattja',
  'laura',
  'lav',
  'lava',
  'lave',
  'lavin',
  'lavoar',
  'lavyr',
  'lax',
  'laxa',
  'lb',
  'lc',
  'ld',
  'le',
  'leasa',
  'led',
  'leda',
  'ledad',
  'ledas',
  'ledd',
  'ledera',
  'ledig',
  'ledsyn',
  'leg',
  'lega',
  'legal',
  'legat',
  'legera',
  'legio',
  'leja',
  'lejd',
  'lejon',
  'lek',
  'leka',
  'lekis',
  'lekman',
  'lekt',
  'lektyr',
  'lem',
  'lemma',
  'lemur',
  'len',
  'lena',
  'lepra',
  'ler',
  'lera',
  'lerig',
  'less',
  'lessen',
  'leta',
  'letal',
  'lett',
  'lev',
  'leva',
  'leve',
  'lever',
  'levit',
  'levra',
  'lf',
  'lg',
  'lh',
  'li',
  'lian',
  'lias',
  'libell',
  'licens',
  'lid',
  'lida',
  'lidar',
  'lider',
  'lie',
  'liera',
  'lift',
  'lifta',
  'liga',
  'ligg',
  'ligga',
  'lik',
  'lika',
  'likbil',
  'like',
  'likna',
  'likvit',
  'lila',
  'lilja',
  'lilla',
  'lille',
  'lim',
  'lime',
  'limit',
  'limma',
  'limpa',
  'lin',
  'lina',
  'lind',
  'linda',
  'lingul',
  'linje',
  'linka',
  'linne',
  'lins',
  'lip',
  'lipa',
  'lipas',
  'lipen',
  'lipig',
  'lir',
  'lira',
  'lire',
  'lirka',
  'lisa',
  'lisen',
  'lisma',
  'list',
  'lista',
  'lit',
  'lita',
  'lite',
  'liten',
  'liter',
  'litet',
  'litium',
  'litsa',
  'liv',
  'liva',
  'livad',
  'live',
  'liver',
  'livtag',
  'lix',
  'lj',
  'ljud',
  'ljuda',
  'ljug',
  'ljuga',
  'ljum',
  'ljung',
  'ljus',
  'ljuta',
  'ljuv',
  'lk',
  'll',
  'lll',
  'llll',
  'lm',
  'lmn',
  'ln',
  'lo',
  'lob',
  'lobb',
  'lobba',
  'lobby',
  'lock',
  'locka',
  'lod',
  'loda',
  'lodare',
  'lodda',
  'loden',
  'loft',
  'loge',
  'logg',
  'logga',
  'logi',
  'logik',
  'logisk',
  'loj',
  'lojal',
  'lok',
  'loka',
  'lokal',
  'loko',
  'lokus',
  'lolla',
  'lom',
  'loma',
  'lomma',
  'lomme',
  'look',
  'lopp',
  'loppa',
  'loran',
  'lord',
  'lort',
  'lorta',
  'loser',
  'loska',
  'loss',
  'lossa',
  'losta',
  'lotion',
  'lots',
  'lotsa',
  'lott',
  'lotta',
  'lotto',
  'lotus',
  'lov',
  'lova',
  'loven',
  'lovlig',
  'lp',
  'lq',
  'lr',
  'ls',
  'lt',
  'lu',
  'luad',
  'lubb',
  'lubba',
  'lucia',
  'lucka',
  'ludd',
  'ludda',
  'luden',
  'luder',
  'ludra',
  'lue',
  'lues',
  'luffa',
  'luffen',
  'lufs',
  'lufsa',
  'luft',
  'lufta',
  'lugg',
  'lugga',
  'lugn',
  'lugna',
  'luka',
  'lukt',
  'lukta',
  'lull',
  'lulla',
  'lullig',
  'lumen',
  'lump',
  'lumpa',
  'lumpor',
  'lunar',
  'lunch',
  'lund',
  'lunga',
  'lunk',
  'lunka',
  'lunna',
  'lunne',
  'luns',
  'lunsa',
  'lunta',
  'lupin',
  'lupp',
  'lupus',
  'lur',
  'lura',
  'luras',
  'lurig',
  'lurk',
  'lurt',
  'lurvig',
  'lus',
  'lusa',
  'lusig',
  'luska',
  'lussa',
  'lust',
  'lusta',
  'lut',
  'luta',
  'luv',
  'luva',
  'luver',
  'lux',
  'luxtal',
  'lv',
  'lw',
  'lx',
  'ly',
  'lya',
  'lybsk',
  'lycka',
  'lyda',
  'lydig',
  'lyft',
  'lyfta',
  'lygens',
  'lykta',
  'lymfa',
  'lynne',
  'lyra',
  'lyrik',
  'lyrisk',
  'lysa',
  'lyse',
  'lysin',
  'lysol',
  'lyss',
  'lyster',
  'lyte',
  'lytt',
  'lyx',
  'lyxig',
  'lz',
  'm',
  'mach',
  'mack',
  'macka',
  'mackla',
  'mad',
  'madam',
  'madra',
  'mage',
  'mager',
  'magi',
  'magik',
  'magma',
  'magnat',
  'magra',
  'maj',
  'maja',
  'major',
  'majs',
  'mak',
  'maka',
  'make',
  'maklig',
  'mako',
  'makt',
  'makta',
  'mal',
  'mala',
  'malaj',
  'malis',
  'malja',
  'mall',
  'malla',
  'mallig',
  'malm',
  'malma',
  'malt',
  'malva',
  'mamba',
  'mambo',
  'mamma',
  'man',
  'mana',
  'manad',
  'manbar',
  'maner',
  'manet',
  'mangan',
  'mango',
  'mani',
  'maniok',
  'manke',
  'manna',
  'manus',
  'maoism',
  'maori',
  'mapp',
  'mar',
  'mara',
  'marg',
  'marig',
  'marin',
  'mark',
  'marka',
  'marmor',
  'mars',
  'marsk',
  'mas',
  'masa',
  'maser',
  'masig',
  'mask',
  'maska',
  'maskin',
  'massa',
  'mast',
  'mastig',
  'masur',
  'mat',
  'mata',
  'matad',
  'match',
  'matcha',
  'mate',
  'matho',
  'matig',
  'matos',
  'matro',
  'mats',
  'matsal',
  'matt',
  'matta',
  'matte',
  'mauve',
  'max',
  'maxi',
  'maxim',
  'maxis',
  'mb',
  'mbl',
  'mc',
  'md',
  'me',
  'mecka',
  'med',
  'medan',
  'mede',
  'medel',
  'medels',
  'meder',
  'medge',
  'media',
  'medio',
  'medla',
  'medlem',
  'medta',
  'mej',
  'meja',
  'mejeri',
  'mejka',
  'mejl',
  'mejla',
  'meka',
  'mellan',
  'melon',
  'memma',
  'men',
  'mena',
  'menad',
  'mened',
  'menig',
  'menlig',
  'mens',
  'meny',
  'mer',
  'mera',
  'merit',
  'mes',
  'mesan',
  'mesart',
  'mesig',
  'meson',
  'mest',
  'meta',
  'metan',
  'mete',
  'meteor',
  'meter',
  'metod',
  'metro',
  'metyl',
  'mf',
  'mg',
  'mh',
  'mi',
  'miau',
  'mick',
  'midja',
  'mig',
  'mikra',
  'mikro',
  'mikrob',
  'mil',
  'mila',
  'mild',
  'milis',
  'milo',
  'mim',
  'mima',
  'mimik',
  'mimosa',
  'min',
  'mina',
  'mini',
  'mink',
  'minna',
  'minnas',
  'minne',
  'minst',
  'mint',
  'minus',
  'minut',
  'miss',
  'missa',
  'misse',
  'missne',
  'mist',
  'mista',
  'miste',
  'mitra',
  'mitt',
  'mittre',
  'mix',
  'mixa',
  'mixed',
  'mixer',
  'mj',
  'mjau',
  'mjugg',
  'mjuk',
  'mjuka',
  'mk',
  'ml',
  'mm',
  'mmm',
  'mmmm',
  'mn',
  'mno',
  'mo',
  'mobb',
  'mobba',
  'mobil',
  'mocka',
  'mod',
  'modal',
  'modd',
  'mode',
  'modell',
  'modem',
  'moder',
  'modig',
  'mods',
  'modul',
  'modus',
  'mogen',
  'mogna',
  'mogul',
  'moig',
  'moira',
  'moj',
  'moja',
  'mojig',
  'mojna',
  'mol',
  'mola',
  'molar',
  'moll',
  'moln',
  'molnig',
  'molo',
  'molok',
  'moms',
  'monad',
  'monist',
  'monke',
  'moped',
  'mopp',
  'moppa',
  'moppe',
  'mops',
  'mopsa',
  'mor',
  'mora',
  'moral',
  'moras',
  'mord',
  'morell',
  'mores',
  'morf',
  'morian',
  'morla',
  'morna',
  'morot',
  'morr',
  'morra',
  'morron',
  'mors',
  'morsa',
  'morse',
  'morsk',
  'mos',
  'mosa',
  'mosel',
  'mosig',
  'mossa',
  'mosse',
  'mossig',
  'mot',
  'mota',
  'motig',
  'motion',
  'motiv',
  'motor',
  'motse',
  'mott',
  'motta',
  'motto',
  'mp',
  'mq',
  'mr',
  'ms',
  'mt',
  'mu',
  'mua',
  'muck',
  'mucka',
  'mudd',
  'mudder',
  'muff',
  'mufti',
  'mugg',
  'mujka',
  'mula',
  'mule',
  'mulen',
  'mull',
  'mulla',
  'mullra',
  'mulna',
  'multe',
  'mumie',
  'mumla',
  'mumma',
  'mumrik',
  'mums',
  'mumsa',
  'mun',
  'mungo',
  'munk',
  'munta',
  'munvig',
  'mur',
  'mura',
  'mural',
  'murkna',
  'murr',
  'murra',
  'murre',
  'mus',
  'musa',
  'musch',
  'musik',
  'musisk',
  'muslin',
  'must',
  'musta',
  'muta',
  'mutism',
  'mv',
  'mw',
  'mx',
  'my',
  'mycel',
  'mycke',
  'mygel',
  'mygg',
  'mygga',
  'mygla',
  'mylla',
  'myller',
  'mylta',
  'mynda',
  'mynna',
  'mynt',
  'mynta',
  'myom',
  'myopi',
  'myr',
  'myra',
  'myrra',
  'myrten',
  'mys',
  'mysa',
  'mysig',
  'mysk',
  'myska',
  'mysko',
  'myt',
  'mz',
  'n',
  'nabb',
  'nabo',
  'nabob',
  'nacka',
  'nacke',
  'nadir',
  'nafs',
  'nafsa',
  'nafta',
  'nagel',
  'nagg',
  'nagga',
  'naggig',
  'nagla',
  'naiv',
  'naja',
  'najad',
  'naken',
  'nalla',
  'nalle',
  'namn',
  'namna',
  'namne',
  'napp',
  'nappa',
  'nappas',
  'nar',
  'nara',
  'nare',
  'narig',
  'narr',
  'narra',
  'narri',
  'narv',
  'narva',
  'narvig',
  'nasa',
  'nasal',
  'nasir',
  'nasse',
  'nate',
  'nativ',
  'natt',
  'natta',
  'nattro',
  'natur',
  'nav',
  'naval',
  'navel',
  'navla',
  'nazist',
  'nb',
  'nc',
  'nd',
  'ne',
  'ned',
  'nedan',
  'neder',
  'nedi',
  'nedom',
  'nedre',
  'nedta',
  'nedvid',
  'neger',
  'nehej',
  'nej',
  'nejd',
  'nek',
  'neka',
  'nektar',
  'neon',
  'ner',
  'nere',
  'nerts',
  'nertz',
  'nerv',
  'nesa',
  'neslig',
  'netto',
  'nevus',
  'newton',
  'nexus',
  'nf',
  'ng',
  'nh',
  'ni',
  'nia',
  'nick',
  'nicka',
  'nid',
  'nidsk',
  'nie',
  'niece',
  'niga',
  'nikt',
  'nikta',
  'nimbus',
  'nio',
  'niob',
  'nipa',
  'nipig',
  'nippa',
  'niptid',
  'nisch',
  'nissa',
  'nisse',
  'nit',
  'nita',
  'nitid',
  'nitrit',
  'nitti',
  'nix',
  'nj',
  'nja',
  'njugg',
  'njure',
  'njuta',
  'nk',
  'nl',
  'nm',
  'nn',
  'nnn',
  'nnnn',
  'no',
  'nobba',
  'nobel',
  'nock',
  'nocka',
  'nod',
  'nodul',
  'nog',
  'noga',
  'nogav',
  'noja',
  'nojig',
  'nojs',
  'nojsa',
  'noll',
  'nolla',
  'nolle',
  'noltig',
  'nomad',
  'nomen',
  'nona',
  'nonie',
  'nop',
  'nopp',
  'noppa',
  'nor',
  'nord',
  'norm',
  'normal',
  'norna',
  'norpa',
  'norr',
  'norra',
  'nors',
  'norsk',
  'nos',
  'nosa',
  'nosig',
  'not',
  'nota',
  'notis',
  'nougat',
  'nova',
  'novis',
  'np',
  'nq',
  'nr',
  'ns',
  'nt',
  'nu',
  'nubb',
  'nubba',
  'nubbe',
  'nucka',
  'nudda',
  'nudel',
  'nudist',
  'numen',
  'numer',
  'numro',
  'nuna',
  'nunna',
  'nuntie',
  'nutid',
  'nv',
  'nw',
  'nx',
  'ny',
  'nyans',
  'nyck',
  'nycka',
  'nydana',
  'nyhet',
  'nying',
  'nyis',
  'nyktra',
  'nylig',
  'nylle',
  'nylon',
  'nymf',
  'nynna',
  'nyp',
  'nypa',
  'nypas',
  'nypon',
  'nyrik',
  'nys',
  'nysa',
  'nyss',
  'nysta',
  'nyter',
  'nytta',
  'nyttja',
  'nyval',
  'nz',
  'o',
  'oadel',
  'oanad',
  'oart',
  'oas',
  'oasis',
  'ob',
  'obanad',
  'obedd',
  'oblat',
  'oblid',
  'oblik',
  'oblyg',
  'oboe',
  'oboist',
  'obol',
  'obs',
  'obygd',
  'obytt',
  'oc',
  'ocean',
  'och',
  'ock',
  'ocker',
  'ockra',
  'od',
  'odal',
  'odds',
  'ode',
  'odikad',
  'odjur',
  'odla',
  'odlad',
  'odon',
  'odryg',
  'odygd',
  'oe',
  'oenig',
  'oense',
  'oexakt',
  'of',
  'offer',
  'offra',
  'ofin',
  'ofog',
  'ofred',
  'ofri',
  'ofrid',
  'ofrom',
  'ofta',
  'oftast',
  'og',
  'ogagn',
  'ogen',
  'ogift',
  'ogill',
  'ogin',
  'oh',
  'ohelga',
  'ohm',
  'ohmsk',
  'ohoj',
  'ohyra',
  'oi',
  'oj',
  'oja',
  'ojoj',
  'ojust',
  'ok',
  'oka',
  'okapi',
  'okay',
  'okben',
  'okej',
  'okey',
  'oklar',
  'oklok',
  'okning',
  'okokt',
  'oktal',
  'oktan',
  'oktav',
  'okuvad',
  'okysk',
  'ol',
  'olag',
  'olaga',
  'olat',
  'olik',
  'olika',
  'oliv',
  'olja',
  'oljig',
  'oljud',
  'olla',
  'olle',
  'ollon',
  'olm',
  'olojal',
  'olust',
  'olvon',
  'om',
  'omak',
  'omaka',
  'ombe',
  'omblad',
  'ombud',
  'omdop',
  'omega',
  'omejad',
  'omen',
  'omge',
  'omgiv',
  'omild',
  'omkast',
  'omkok',
  'omnejd',
  'oms',
  'omse',
  'omsorg',
  'omsydd',
  'omsyn',
  'omtag',
  'omtal',
  'omval',
  'on',
  'ona',
  'onani',
  'ond',
  'ondo',
  'onkel',
  'onsdag',
  'ont',
  'onus',
  'onyx',
  'oo',
  'ooo',
  'oooo',
  'op',
  'opak',
  'opal',
  'opar',
  'opera',
  'opiat',
  'opium',
  'opp',
  'oppe',
  'opq',
  'opris',
  'optik',
  'opus',
  'oq',
  'or',
  'orakad',
  'oral',
  'orask',
  'ord',
  'orda',
  'ordal',
  'orden',
  'order',
  'ordlek',
  'ordna',
  'oread',
  'oreda',
  'oredd',
  'oren',
  'orena',
  'orera',
  'orfism',
  'organ',
  'orgel',
  'orgie',
  'orig',
  'origo',
  'ork',
  'orka',
  'orkan',
  'orlov',
  'orm',
  'orma',
  'ormbo',
  'ornat',
  'orne',
  'oro',
  'oroa',
  'orogen',
  'orre',
  'orsak',
  'ort',
  'ortsbo',
  'orund',
  'orv',
  'os',
  'osa',
  'osagd',
  'osams',
  'osann',
  'osed',
  'osedd',
  'osfri',
  'osig',
  'osis',
  'oskodd',
  'osmak',
  'osman',
  'osmium',
  'osmos',
  'oss',
  'ost',
  'osta',
  'ostan',
  'ostig',
  'ostjak',
  'ostor',
  'osund',
  'ot',
  'otack',
  'otakt',
  'otal',
  'otalt',
  'otid',
  'oting',
  'otit',
  'otium',
  'otolog',
  'otro',
  'otta',
  'otukt',
  'otur',
  'otydd',
  'otyg',
  'ou',
  'ouzo',
  'ov',
  'oval',
  'ovan',
  'ovana',
  'ovass',
  'ovett',
  'ovig',
  'ovigd',
  'ovikt',
  'ovilja',
  'ovis',
  'oviss',
  'ow',
  'ox',
  'oxe',
  'oxel',
  'oxer',
  'oxhud',
  'oxid',
  'oxygen',
  'oy',
  'oz',
  'ozon',
  'p',
  'pack',
  'packa',
  'packe',
  'padda',
  'paella',
  'paff',
  'page',
  'pagod',
  'paj',
  'paja',
  'pajas',
  'paket',
  'pakt',
  'pall',
  'palla',
  'pallra',
  'palm',
  'palma',
  'palp',
  'pals',
  'palt',
  'palta',
  'pamp',
  'panda',
  'panel',
  'panela',
  'pang',
  'panga',
  'panik',
  'pank',
  'panka',
  'panna',
  'pansra',
  'pant',
  'panta',
  'papist',
  'papp',
  'pappa',
  'papua',
  'par',
  'para',
  'parad',
  'parat',
  'parce',
  'pares',
  'parfym',
  'pari',
  'paria',
  'parig',
  'park',
  'parm',
  'paroll',
  'part',
  'parti',
  'party',
  'pascal',
  'pasha',
  'pasma',
  'pass',
  'passa',
  'pasta',
  'pastej',
  'pata',
  'paten',
  'pater',
  'patos',
  'patron',
  'patt',
  'patta',
  'patte',
  'paus',
  'pausa',
  'pava',
  'pax',
  'paxa',
  'pb',
  'pc',
  'pd',
  'pe',
  'peang',
  'pedal',
  'peg',
  'pegas',
  'pegel',
  'pejl',
  'pejla',
  'pek',
  'peka',
  'peke',
  'pektin',
  'pen',
  'penal',
  'pendyl',
  'peng',
  'penis',
  'penna',
  'penni',
  'penny',
  'peon',
  'pepita',
  'peppa',
  'per',
  'perenn',
  'perl',
  'perm',
  'person',
  'peruk',
  'peso',
  'pest',
  'pet',
  'peta',
  'petig',
  'petit',
  'pf',
  'pg',
  'ph',
  'pi',
  'piaff',
  'piano',
  'piazza',
  'pick',
  'picka',
  'picot',
  'piff',
  'piffa',
  'piga',
  'pigg',
  'pigga',
  'pik',
  'pika',
  'pikant',
  'piket',
  'pil',
  'pila',
  'pile',
  'pilk',
  'pilka',
  'pill',
  'pilla',
  'piller',
  'pilot',
  'pilsk',
  'pilt',
  'pin',
  'pina',
  'pinal',
  'pingel',
  'pinje',
  'pinka',
  'pinn',
  'pinna',
  'pinne',
  'pinnmo',
  'pins',
  'pint',
  'pion',
  'pip',
  'pipa',
  'pipig',
  'pippa',
  'pippi',
  'pir',
  'pirat',
  'pirig',
  'pirk',
  'pirka',
  'pirog',
  'pirra',
  'pirrig',
  'pirum',
  'pisk',
  'piska',
  'pisp',
  'pispa',
  'piss',
  'pissa',
  'pist',
  'pitt',
  'pivot',
  'pizza',
  'pj',
  'pjatt',
  'pjosk',
  'pjunk',
  'pjunka',
  'pk',
  'pl',
  'plack',
  'plage',
  'plagg',
  'plan',
  'plana',
  'plank',
  'plask',
  'plaska',
  'plast',
  'plats',
  'platt',
  'playa',
  'plebej',
  'plebs',
  'pleti',
  'pli',
  'pligg',
  'plikt',
  'pling',
  'plint',
  'plira',
  'plit',
  'plita',
  'plock',
  'plog',
  'ploga',
  'ploj',
  'plomb',
  'plopp',
  'ploppa',
  'plugg',
  'plump',
  'plums',
  'plural',
  'plurr',
  'plus',
  'pluta',
  'plutt',
  'plym',
  'plysa',
  'plysch',
  'plyte',
  'pm',
  'pn',
  'po',
  'pock',
  'pocka',
  'poem',
  'poesi',
  'poet',
  'pojke',
  'pokal',
  'poker',
  'pol',
  'polack',
  'polar',
  'polio',
  'polis',
  'polka',
  'pollen',
  'polo',
  'polsk',
  'polyp',
  'pomp',
  'pompa',
  'pond',
  'ponke',
  'ponny',
  'ponton',
  'pool',
  'pop',
  'poppa',
  'por',
  'porig',
  'porl',
  'porla',
  'porr',
  'porrig',
  'pors',
  'port',
  'porta',
  'porto',
  'pose',
  'post',
  'posta',
  'poster',
  'posto',
  'pott',
  'potta',
  'pp',
  'ppp',
  'pppp',
  'pq',
  'pqr',
  'pr',
  'pracka',
  'prakt',
  'prao',
  'prat',
  'prata',
  'prefix',
  'preja',
  'press',
  'presto',
  'prick',
  'prim',
  'prima',
  'primas',
  'primo',
  'prins',
  'prion',
  'prior',
  'pris',
  'prisa',
  'privat',
  'pro',
  'profit',
  'proms',
  'propp',
  'proppa',
  'props',
  'prosa',
  'prost',
  'prov',
  'prova',
  'prunk',
  'prusta',
  'prut',
  'pruta',
  'prutt',
  'prya',
  'pryd',
  'pryda',
  'pryl',
  'pryo',
  'ps',
  'psalm',
  'pst',
  'psyka',
  'psyke',
  'psykos',
  'pt',
  'ptro',
  'ptroa',
  'pu',
  'pub',
  'puck',
  'pucka',
  'pudel',
  'puder',
  'pudra',
  'puff',
  'puffa',
  'puffas',
  'puh',
  'puka',
  'puke',
  'pula',
  'pulka',
  'pull',
  'pulla',
  'pulpa',
  'puls',
  'pulsa',
  'pulsar',
  'pult',
  'puma',
  'pump',
  'pumpa',
  'pumps',
  'pund',
  'punda',
  'pung',
  'punga',
  'punk',
  'punka',
  'punkt',
  'puns',
  'punsa',
  'pupill',
  'puppa',
  'pur',
  'purjo',
  'purpur',
  'purra',
  'puss',
  'pussa',
  'pussig',
  'pust',
  'pusta',
  'puta',
  'putig',
  'puts',
  'putsa',
  'putt',
  'putta',
  'putte',
  'putti',
  'putto',
  'puttra',
  'pv',
  'pw',
  'px',
  'py',
  'pycke',
  'pyemi',
  'pylon',
  'pynt',
  'pynta',
  'pyra',
  'pyre',
  'pyrig',
  'pys',
  'pysa',
  'pyton',
  'pyts',
  'pytsa',
  'pytt',
  'pytte',
  'pz',
  'q',
  'qb',
  'qc',
  'qd',
  'qe',
  'qf',
  'qg',
  'qh',
  'qi',
  'qj',
  'qk',
  'ql',
  'qm',
  'qn',
  'qo',
  'qp',
  'qq',
  'qqq',
  'qqqq',
  'qr',
  'qrs',
  'qs',
  'qt',
  'qu',
  'quilta',
  'qv',
  'qw',
  'qx',
  'qy',
  'qz',
  'r',
  'rabbi',
  'rabiat',
  'race',
  'racer',
  'rack',
  'racka',
  'rad',
  'rada',
  'radar',
  'radda',
  'radhus',
  'radie',
  'radio',
  'radium',
  'radja',
  'radon',
  'rafs',
  'rafsa',
  'raft',
  'raga',
  'ragg',
  'ragga',
  'raggen',
  'ragla',
  'ragu',
  'raid',
  'raja',
  'rajd',
  'rak',
  'raka',
  'raken',
  'raket',
  'rakna',
  'rall',
  'ralla',
  'rally',
  'ram',
  'rama',
  'rami',
  'ramla',
  'ramm',
  'ramma',
  'rammel',
  'ramp',
  'rams',
  'ramsa',
  'rana',
  'ranch',
  'rand',
  'randa',
  'rang',
  'rank',
  'ranka',
  'ranson',
  'ranta',
  'rap',
  'rapa',
  'rapid',
  'rapp',
  'rappa',
  'raps',
  'rar',
  'rarka',
  'ras',
  'rasa',
  'rasera',
  'rask',
  'raska',
  'raslag',
  'rasp',
  'raspa',
  'rast',
  'rasta',
  'raster',
  'rata',
  'rate',
  'ratt',
  'ratta',
  'rauk',
  'rav',
  'rave',
  'ravin',
  'rayon',
  'rb',
  'rc',
  'rd',
  're',
  'rea',
  'real',
  'reale',
  'rebell',
  'rebus',
  'recit',
  'reda',
  'redan',
  'redare',
  'redd',
  'rede',
  'redig',
  'redo',
  'reds',
  'reell',
  'refill',
  'refug',
  'regal',
  'regel',
  'reggae',
  'regi',
  'regim',
  'regla',
  'regn',
  'regna',
  'rejv',
  'rek',
  'reka',
  'reko',
  'rekord',
  'rekyl',
  'relik',
  'reling',
  'rem',
  'remi',
  'remis',
  'remsa',
  'ren',
  'rena',
  'renat',
  'renett',
  'renko',
  'renons',
  'rens',
  'rensa',
  'reol',
  'rep',
  'repa',
  'repe',
  'repig',
  'res',
  'resa',
  'rese',
  'reseda',
  'reson',
  'rest',
  'restid',
  'reta',
  'retas',
  'retor',
  'retort',
  'retur',
  'reuma',
  'rev',
  'reva',
  'revel',
  'revig',
  'revir',
  'revolt',
  'revy',
  'rf',
  'rg',
  'rh',
  'ri',
  'ria',
  'ribb',
  'ribba',
  'ricin',
  'ricka',
  'rida',
  'ridhus',
  'riff',
  'rigel',
  'rigg',
  'rigga',
  'rigid',
  'rigla',
  'rik',
  'rike',
  'riks',
  'rikta',
  'riktig',
  'rilla',
  'rim',
  'rimma',
  'rimpa',
  'ring',
  'ringa',
  'ringel',
  'rink',
  'rinna',
  'ripa',
  'rips',
  'ris',
  'risa',
  'risig',
  'risk',
  'riska',
  'risp',
  'rispa',
  'rissel',
  'rist',
  'rista',
  'rit',
  'rita',
  'rits',
  'ritsa',
  'ritsch',
  'ritt',
  'riv',
  'riva',
  'rival',
  'rivas',
  'rivig',
  'rj',
  'rk',
  'rl',
  'rm',
  'rn',
  'ro',
  'roa',
  'road',
  'rob',
  'robe',
  'robot',
  'rock',
  'rocka',
  'rockad',
  'rodd',
  'rodel',
  'rodeo',
  'roder',
  'rodna',
  'roffa',
  'rolig',
  'roll',
  'rolla',
  'roller',
  'rom',
  'roman',
  'romb',
  'romsk',
  'rond',
  'ronda',
  'rondo',
  'rop',
  'ropa',
  'ror',
  'ros',
  'rosa',
  'rosett',
  'rosig',
  'rosk',
  'rost',
  'rosta',
  'rot',
  'rota',
  'rotad',
  'rote',
  'rotel',
  'rotig',
  'rotor',
  'rots',
  'rouge',
  'roulad',
  'route',
  'rov',
  'rova',
  'rp',
  'rq',
  'rr',
  'rrr',
  'rrrr',
  'rs',
  'rst',
  'rt',
  'ru',
  'rubb',
  'rubba',
  'rubel',
  'rubin',
  'rucka',
  'ruda',
  'rudis',
  'ruelse',
  'ruff',
  'ruffa',
  'rufs',
  'rufsa',
  'rugby',
  'rugg',
  'rugga',
  'rugge',
  'ruggig',
  'ruin',
  'rulad',
  'rulla',
  'rulle',
  'rulsa',
  'rulta',
  'rum',
  'rumba',
  'rumla',
  'rumor',
  'rumpa',
  'runa',
  'rund',
  'runda',
  'rundel',
  'runga',
  'runka',
  'runt',
  'rupie',
  'rus',
  'rusa',
  'rusch',
  'rush',
  'rusha',
  'rusig',
  'rusk',
  'ruska',
  'russ',
  'russin',
  'rusta',
  'ruta',
  'ruter',
  'rutig',
  'rutin',
  'rutt',
  'ruva',
  'ruvare',
  'rv',
  'rw',
  'rx',
  'ry',
  'rya',
  'rybs',
  'ryck',
  'rycka',
  'rygg',
  'rygga',
  'ryka',
  'rykt',
  'rykta',
  'rykte',
  'ryl',
  'rymd',
  'rymma',
  'rymmas',
  'rynk',
  'rynka',
  'ryps',
  'rysa',
  'rysch',
  'rysk',
  'ryska',
  'ryss',
  'ryta',
  'rytm',
  'rytmik',
  'rz',
  's',
  'sabba',
  'sabel',
  'sabin',
  'sabla',
  'sacka',
  'sadel',
  'sadist',
  'sadla',
  'safir',
  'safsa',
  'saft',
  'safta',
  'saga',
  'sagen',
  'sagg',
  'sagga',
  'sagla',
  'sago',
  'sajt',
  'sak',
  'saka',
  'saker',
  'sakna',
  'saknas',
  'sakta',
  'sal',
  'sala',
  'saldo',
  'salep',
  'salig',
  'salin',
  'saliv',
  'salong',
  'salsa',
  'salt',
  'salta',
  'salu',
  'salut',
  'salva',
  'samba',
  'sambo',
  'same',
  'samisk',
  'samka',
  'samla',
  'samma',
  'sammet',
  'sams',
  'samsa',
  'samsyn',
  'samt',
  'samum',
  'sand',
  'sanda',
  'sandmo',
  'sang',
  'sank',
  'sankt',
  'sann',
  'sanna',
  'sans',
  'sansa',
  'sard',
  'sarg',
  'sarga',
  'sari',
  'sars',
  'sarv',
  'satan',
  'sate',
  'satin',
  'satir',
  'satrap',
  'sats',
  'satsa',
  'satt',
  'satyr',
  'sauna',
  'sav',
  'sava',
  'savas',
  'savig',
  'sax',
  'saxa',
  'sb',
  'sc',
  'scanna',
  'scarf',
  'scen',
  'sch',
  'schah',
  'schal',
  'schas',
  'schejk',
  'scoop',
  'scout',
  'scouta',
  'sd',
  'se',
  'seans',
  'sebar',
  'sebra',
  'sebu',
  'sed',
  'sedan',
  'sedel',
  'sedes',
  'sedig',
  'sedum',
  'seeda',
  'sefir',
  'sefyr',
  'seg',
  'sega',
  'segdra',
  'segel',
  'seger',
  'segla',
  'segna',
  'segra',
  'sej',
  'sejd',
  'sejda',
  'sejsa',
  'sejte',
  'sekel',
  'sekin',
  'sekond',
  'sekt',
  'sel',
  'sela',
  'sele',
  'selen',
  'selot',
  'semem',
  'semit',
  'semla',
  'sen',
  'sena',
  'senap',
  'senar',
  'senast',
  'senat',
  'senig',
  'senil',
  'senna',
  'sepia',
  'seraf',
  'serb',
  'seren',
  'serge',
  'serie',
  'serum',
  'serva',
  'serve',
  'server',
  'servo',
  'ses',
  'sesam',
  'sessa',
  'set',
  'sex',
  'sexa',
  'sexig',
  'sext',
  'sexti',
  'sextio',
  'sf',
  'sfinx',
  'sg',
  'sh',
  'shagg',
  'shah',
  'shaka',
  'shake',
  'shejk',
  'shia',
  'shiit',
  'shop',
  'shoppa',
  'show',
  'showa',
  'shunt',
  'si',
  'sia',
  'siare',
  'sibet',
  'sick',
  'sicka',
  'sickla',
  'sid',
  'sida',
  'siden',
  'sifon',
  'sig',
  'sigel',
  'sigma',
  'signa',
  'signal',
  'sik',
  'sikel',
  'sikh',
  'sikt',
  'sikta',
  'sikte',
  'sil',
  'sila',
  'silben',
  'silen',
  'silke',
  'sill',
  'silo',
  'silur',
  'sim',
  'simfot',
  'simma',
  'simpa',
  'sims',
  'simsa',
  'simtag',
  'sin',
  'sina',
  'sink',
  'sinka',
  'sinko',
  'sinn',
  'sinne',
  'sinom',
  'sint',
  'sinter',
  'sinus',
  'sioux',
  'sipp',
  'sippa',
  'sir',
  'sira',
  'sirap',
  'sirat',
  'siren',
  'sirp',
  'sirpa',
  'sirra',
  'sis',
  'sisa',
  'sisal',
  'siska',
  'sist',
  'sista',
  'sisten',
  'sisu',
  'sita',
  'sitar',
  'sits',
  'sitta',
  'siva',
  'sj',
  'sjagg',
  'sjal',
  'sjana',
  'sjapp',
  'sjas',
  'sjasa',
  'sjatte',
  'sjava',
  'sjok',
  'sju',
  'sjua',
  'sjubb',
  'sjud',
  'sjuda',
  'sjuk',
  'sjuka',
  'sjutti',
  'sjyst',
  'sk',
  'ska',
  'skabb',
  'skack',
  'skada',
  'skaft',
  'skak',
  'skaka',
  'skakis',
  'skal',
  'skala',
  'skald',
  'skalk',
  'skall',
  'skalm',
  'skalp',
  'skalv',
  'skam',
  'skank',
  'skans',
  'skap',
  'skapa',
  'skara',
  'skare',
  'skarn',
  'skarp',
  'skarv',
  'skarva',
  'skat',
  'skata',
  'skate',
  'skatt',
  'skav',
  'skava',
  'skave',
  'ske',
  'sked',
  'skeda',
  'skede',
  'skeet',
  'skeka',
  'skela',
  'sken',
  'skena',
  'skepa',
  'skepp',
  'sketch',
  'skev',
  'skeva',
  'skick',
  'skida',
  'skift',
  'skifte',
  'skikt',
  'skild',
  'skina',
  'skinn',
  'skinna',
  'skipa',
  'skir',
  'skira',
  'skiss',
  'skit',
  'skita',
  'skiva',
  'skjul',
  'skjut',
  'skjuts',
  'sko',
  'skock',
  'skoft',
  'skog',
  'skoga',
  'skogig',
  'skoj',
  'skoja',
  'skol',
  'skola',
  'skolk',
  'skolt',
  'skona',
  'skopa',
  'skorpa',
  'skorr',
  'skorv',
  'skot',
  'skota',
  'skott',
  'skov',
  'skova',
  'skovel',
  'skraj',
  'skrak',
  'skral',
  'skrank',
  'skrap',
  'skred',
  'skrev',
  'skri',
  'skria',
  'skrift',
  'skrik',
  'skrin',
  'skrot',
  'skrota',
  'skrov',
  'skrud',
  'skruv',
  'skrymt',
  'skryp',
  'skryt',
  'skubb',
  'skuff',
  'skula',
  'skuld',
  'skull',
  'skulle',
  'skult',
  'skum',
  'skunk',
  'skur',
  'skura',
  'skurk',
  'skut',
  'skuta',
  'skutt',
  'skval',
  'skvala',
  'sky',
  'skya',
  'skydd',
  'skygd',
  'skygg',
  'skygge',
  'skyig',
  'skyl',
  'skyla',
  'skyld',
  'skyle',
  'skylt',
  'skymf',
  'skymt',
  'skymta',
  'skyt',
  'skytt',
  'sl',
  'slabb',
  'slack',
  'slacks',
  'sladd',
  'slaf',
  'slafa',
  'slafs',
  'slag',
  'slaga',
  'slagg',
  'slagur',
  'slak',
  'slaka',
  'slakt',
  'slam',
  'slampa',
  'slams',
  'slana',
  'slang',
  'slank',
  'slant',
  'slapp',
  'slarv',
  'slarva',
  'slas',
  'slasa',
  'slase',
  'slask',
  'slatt',
  'slav',
  'slava',
  'slejd',
  'slejf',
  'slejs',
  'sleke',
  'slem',
  'sleta',
  'slev',
  'sleva',
  'slice',
  'slick',
  'slicka',
  'slid',
  'slida',
  'slig',
  'slik',
  'sling',
  'slint',
  'slip',
  'slipa',
  'sliper',
  'slips',
  'slira',
  'slisk',
  'slit',
  'slita',
  'slits',
  'slitsa',
  'slog',
  'slok',
  'sloka',
  'slom',
  'slopa',
  'slott',
  'slubb',
  'slug',
  'slugga',
  'sluka',
  'slum',
  'slump',
  'slunk',
  'slup',
  'slurk',
  'slusk',
  'sluss',
  'slut',
  'sluta',
  'sluten',
  'sly',
  'slya',
  'slyna',
  'sm',
  'smack',
  'smak',
  'smaka',
  'smal',
  'smalt',
  'smart',
  'smash',
  'smasha',
  'smask',
  'smatt',
  'smed',
  'smek',
  'smeka',
  'smet',
  'smeta',
  'smida',
  'smide',
  'smidig',
  'smil',
  'smila',
  'smink',
  'smisk',
  'smita',
  'smock',
  'smocka',
  'smog',
  'smolk',
  'smolt',
  'smord',
  'smul',
  'smula',
  'smuts',
  'smutt',
  'smyg',
  'smyga',
  'sn',
  'snabb',
  'snabba',
  'snack',
  'snafs',
  'snapp',
  'snappa',
  'snaps',
  'snar',
  'snara',
  'snark',
  'snart',
  'snask',
  'snatta',
  'snava',
  'sned',
  'snes',
  'snesa',
  'snibb',
  'snida',
  'sniff',
  'snigla',
  'snika',
  'snilj',
  'snip',
  'snipa',
  'snipp',
  'snits',
  'snitsa',
  'snitt',
  'sno',
  'snoa',
  'snobb',
  'snodd',
  'snok',
  'snoka',
  'snopp',
  'snoppa',
  'snor',
  'snora',
  'snork',
  'snudd',
  'snudig',
  'snurr',
  'snus',
  'snusa',
  'snusk',
  'snut',
  'snutt',
  'snuva',
  'snuvig',
  'snyft',
  'snygg',
  'snyta',
  'snyte',
  'so',
  'sobel',
  'sober',
  'sock',
  'socka',
  'socken',
  'soda',
  'soffa',
  'sofist',
  'soft',
  'soja',
  'sol',
  'sola',
  'solar',
  'sold',
  'soldis',
  'solid',
  'solig',
  'solist',
  'solk',
  'solka',
  'solo',
  'solur',
  'solv',
  'solva',
  'som',
  'somlig',
  'somna',
  'somt',
  'son',
  'sona',
  'sonar',
  'sonat',
  'sonata',
  'sond',
  'sonor',
  'sonson',
  'sop',
  'sopa',
  'sopp',
  'soppa',
  'soprum',
  'sorb',
  'sorg',
  'sork',
  'sorl',
  'sorla',
  'sort',
  'sorti',
  'sos',
  'sosse',
  'sot',
  'sota',
  'sotig',
  'sotis',
  'sottis',
  'soul',
  'sound',
  'sova',
  'sovel',
  'sovra',
  'sp',
  'spad',
  'spada',
  'spade',
  'spagat',
  'spak',
  'spaka',
  'spalt',
  'spana',
  'spann',
  'spant',
  'spara',
  'spark',
  'sparra',
  'sparv',
  'spasm',
  'spat',
  'spatt',
  'spe',
  'speed',
  'spegel',
  'speja',
  'spel',
  'spela',
  'spene',
  'spens',
  'speta',
  'spets',
  'spett',
  'spetta',
  'spex',
  'spexa',
  'spigg',
  'spik',
  'spika',
  'spila',
  'spill',
  'spillo',
  'spink',
  'spinn',
  'spion',
  'spira',
  'spiral',
  'spis',
  'spisa',
  'spjut',
  'split',
  'splitt',
  'spola',
  'spole',
  'spont',
  'spor',
  'sport',
  'spott',
  'spotta',
  'spov',
  'sprak',
  'spray',
  'sprej',
  'spreta',
  'spri',
  'sprit',
  'sprits',
  'sprut',
  'spurt',
  'spy',
  'spya',
  'spydig',
  'sq',
  'squaw',
  'sr',
  'ss',
  'sss',
  'ssss',
  'st',
  'sta',
  'stab',
  'stack',
  'stacka',
  'stad',
  'stadd',
  'stag',
  'staga',
  'stagg',
  'staka',
  'stake',
  'stakig',
  'stall',
  'stalp',
  'stam',
  'stamp',
  'stank',
  'stans',
  'stapel',
  'stare',
  'stark',
  'starr',
  'start',
  'stas',
  'stass',
  'stat',
  'stator',
  'staty',
  'stav',
  'stava',
  'steg',
  'stega',
  'stege',
  'stegra',
  'stek',
  'steka',
  'stel',
  'stele',
  'sten',
  'stena',
  'stenos',
  'step',
  'stepp',
  'stia',
  'stick',
  'sticka',
  'stift',
  'stig',
  'stiga',
  'stil',
  'stila',
  'still',
  'stim',
  'stimma',
  'sting',
  'stinn',
  'stins',
  'stirr',
  'stirra',
  'sto',
  'stock',
  'stod',
  'stoff',
  'stoft',
  'stoj',
  'stoja',
  'stol',
  'stola',
  'stoll',
  'stolle',
  'stolt',
  'stomi',
  'stomp',
  'stop',
  'stopp',
  'stor',
  'store',
  'stork',
  'storm',
  'storma',
  'story',
  'strak',
  'stram',
  'strass',
  'strax',
  'stret',
  'strid',
  'strida',
  'stril',
  'strip',
  'strit',
  'strof',
  'stroke',
  'strul',
  'struma',
  'strut',
  'stryk',
  'strypa',
  'stu',
  'stubb',
  'stuck',
  'studio',
  'studs',
  'stuff',
  'stuga',
  'stuk',
  'stuka',
  'stum',
  'stump',
  'stund',
  'stuns',
  'stup',
  'stupa',
  'stupid',
  'stura',
  'stuss',
  'stut',
  'stuts',
  'stuv',
  'stuva',
  'stybb',
  'styck',
  'stycke',
  'stygg',
  'stygn',
  'styla',
  'styng',
  'styr',
  'styra',
  'styre',
  'styv',
  'styva',
  'styver',
  'styvt',
  'su',
  'subba',
  'suck',
  'sucka',
  'suckat',
  'sudd',
  'sudda',
  'sudla',
  'sug',
  'suga',
  'sugen',
  'sugfot',
  'sugga',
  'sukta',
  'sula',
  'sulfa',
  'sulky',
  'sultan',
  'sumak',
  'sumer',
  'summa',
  'sump',
  'sumpa',
  'sund',
  'sup',
  'supa',
  'supera',
  'supig',
  'suput',
  'sur',
  'sura',
  'surf',
  'surfa',
  'surna',
  'surr',
  'surra',
  'surven',
  'sus',
  'susa',
  'susen',
  'sussa',
  'sutan',
  'sutta',
  'sutur',
  'sv',
  'svabb',
  'svacka',
  'svada',
  'svag',
  'svaj',
  'svaja',
  'sval',
  'svala',
  'svale',
  'svalg',
  'svall',
  'svamla',
  'svamp',
  'svan',
  'svang',
  'svank',
  'svans',
  'svar',
  'svara',
  'svart',
  'svarv',
  'svass',
  'svavel',
  'svear',
  'sved',
  'sveda',
  'svejs',
  'svek',
  'sven',
  'svep',
  'svepa',
  'svepe',
  'svets',
  'svett',
  'svibla',
  'svid',
  'svida',
  'svika',
  'svikt',
  'svin',
  'svina',
  'sving',
  'svinn',
  'svir',
  'svira',
  'svirr',
  'svirra',
  'svit',
  'sw',
  'sweet',
  'swing',
  'sx',
  'sy',
  'syd',
  'syda',
  'sydlig',
  'syfta',
  'syfte',
  'syl',
  'sylf',
  'sylfid',
  'syll',
  'sylt',
  'sylta',
  'syn',
  'syna',
  'synas',
  'synbar',
  'synd',
  'synda',
  'synk',
  'synka',
  'synod',
  'synops',
  'synsk',
  'synt',
  'syo',
  'syra',
  'syrak',
  'syre',
  'syren',
  'syrier',
  'syrra',
  'syrsa',
  'syska',
  'syskon',
  'sz',
  't',
  'tabb',
  'tabba',
  'tabbe',
  'tabu',
  'tack',
  'tacka',
  'tackla',
  'tadel',
  'tadla',
  'tafs',
  'tafsa',
  'taft',
  'tag',
  'taga',
  'tagas',
  'tagbar',
  'tagel',
  'tagen',
  'tagg',
  'tagga',
  'tagla',
  'taiga',
  'tajga',
  'tajma',
  'tajt',
  'tajts',
  'tak',
  'taka',
  'taklag',
  'takt',
  'takta',
  'tal',
  'tala',
  'talan',
  'talar',
  'talas',
  'talent',
  'talg',
  'talga',
  'talja',
  'talk',
  'talka',
  'talko',
  'tall',
  'talman',
  'talmi',
  'tam',
  'tambi',
  'tamil',
  'tamp',
  'tampas',
  'tana',
  'tand',
  'tanda',
  'tango',
  'tanig',
  'tank',
  'tanka',
  'tanke',
  'tanks',
  'tant',
  'tantal',
  'tape',
  'tapet',
  'tapir',
  'tapp',
  'tappa',
  'tappt',
  'tapto',
  'tara',
  'tarera',
  'tarm',
  'tarok',
  'tars',
  'tarv',
  'tarva',
  'tas',
  'task',
  'taska',
  'tass',
  'tassa',
  'tassla',
  'tatar',
  'tavla',
  'tax',
  'taxa',
  'taxi',
  'tb',
  'tbc',
  'tc',
  'td',
  'te',
  'teak',
  'team',
  'teban',
  'tebord',
  'teddy',
  'tefat',
  'teflon',
  'teg',
  'tegel',
  'tehus',
  'tein',
  'teint',
  'teism',
  'teist',
  'tejp',
  'tejpa',
  'tejst',
  'teka',
  'telex',
  'telexa',
  'teln',
  'tema',
  'temp',
  'tempo',
  'ten',
  'tender',
  'tenn',
  'tenor',
  'tent',
  'tenta',
  'teori',
  'tepig',
  'terapi',
  'term',
  'teros',
  'terpen',
  'ters',
  'tes',
  'tesil',
  'test',
  'testa',
  'tetani',
  'tetas',
  'tetig',
  'tetra',
  'teve',
  'text',
  'texta',
  'tf',
  'tg',
  'th',
  'thai',
  'thymus',
  'ti',
  'tia',
  'tiara',
  'tick',
  'ticka',
  'tics',
  'tid',
  'tida',
  'tidig',
  'tidur',
  'tie',
  'tiga',
  'tiger',
  'tigga',
  'tight',
  'tik',
  'tilde',
  'tilja',
  'till',
  'tillbe',
  'tills',
  'tilta',
  'tima',
  'timbre',
  'timer',
  'timid',
  'timma',
  'timme',
  'timra',
  'timvis',
  'tina',
  'ting',
  'tinga',
  'tinne',
  'tio',
  'tiol',
  'tiotal',
  'tipp',
  'tippa',
  'tips',
  'tipsa',
  'tir',
  'tira',
  'tirad',
  'tita',
  'titan',
  'titel',
  'titt',
  'titta',
  'tittin',
  'tj',
  'tja',
  'tjabb',
  'tjack',
  'tjafs',
  'tjalk',
  'tjall',
  'tjalla',
  'tjat',
  'tjata',
  'tjeck',
  'tjej',
  'tji',
  'tjim',
  'tjing',
  'tjo',
  'tjoa',
  'tjock',
  'tjog',
  'tjonga',
  'tjuga',
  'tjuge',
  'tjugo',
  'tjugu',
  'tjur',
  'tjura',
  'tjusa',
  'tjusig',
  'tjut',
  'tjuta',
  'tjuv',
  'tjuva',
  'tjyv',
  'tk',
  'tl',
  'tm',
  'tn',
  'to',
  'toa',
  'tobak',
  'tobis',
  'tocka',
  'toddy',
  'tofs',
  'toft',
  'toga',
  'tok',
  'toka',
  'tokas',
  'toker',
  'tokig',
  'tolft',
  'tolfte',
  'tolk',
  'tolka',
  'tolta',
  'tolv',
  'tolva',
  'tom',
  'tomat',
  'tomme',
  'tomt',
  'tomta',
  'tomte',
  'ton',
  'tona',
  'tonal',
  'tonarm',
  'tonem',
  'tonic',
  'tonlig',
  'tonus',
  'topas',
  'topp',
  'toppa',
  'toppig',
  'toque',
  'torax',
  'torde',
  'torg',
  'torga',
  'tork',
  'torka',
  'torn',
  'torna',
  'tornur',
  'torp',
  'torr',
  'torsk',
  'torso',
  'torta',
  'torv',
  'torva',
  'tosa',
  'tosig',
  'tossa',
  'tossig',
  'tota',
  'total',
  'totem',
  'toto',
  'tott',
  'tour',
  'tova',
  'tovig',
  'toxin',
  'tp',
  'tq',
  'tr',
  'trad',
  'tragik',
  'traj',
  'traja',
  'trakt',
  'trala',
  'trall',
  'tramp',
  'trampa',
  'trams',
  'tran',
  'trana',
  'trans',
  'trapp',
  'trasa',
  'trasig',
  'trask',
  'trast',
  'tratt',
  'trav',
  'trava',
  'trave',
  'tre',
  'trea',
  'trefas',
  'trema',
  'trend',
  'tretti',
  'treva',
  'tri',
  'triad',
  'trial',
  'trias',
  'trick',
  'tricks',
  'trift',
  'trim',
  'trind',
  'trio',
  'triod',
  'triol',
  'tripod',
  'tripp',
  'trips',
  'triss',
  'trist',
  'tro',
  'trogen',
  'troll',
  'trolsk',
  'tromb',
  'tron',
  'trona',
  'trop',
  'tropp',
  'trosa',
  'tross',
  'trots',
  'truck',
  'trug',
  'truga',
  'truism',
  'trula',
  'trull',
  'truls',
  'trumf',
  'trunk',
  'trupp',
  'trust',
  'trut',
  'truta',
  'try',
  'tryart',
  'tryck',
  'tryga',
  'trygg',
  'tryna',
  'tryne',
  'tryta',
  'ts',
  'tsar',
  'tt',
  'ttt',
  'tttt',
  'tu',
  'tub',
  'tuba',
  'tubba',
  'tuff',
  'tuffa',
  'tufsa',
  'tuftad',
  'tugg',
  'tugga',
  'tuja',
  'tukan',
  'tukt',
  'tukta',
  'tull',
  'tulla',
  'tullag',
  'tulta',
  'tum',
  'tumla',
  'tumma',
  'tumme',
  'tun',
  'tundra',
  'tung',
  'tunga',
  'tunik',
  'tunn',
  'tunna',
  'tupera',
  'tupp',
  'tuppa',
  'tur',
  'turas',
  'turbo',
  'ture',
  'turf',
  'turig',
  'turk',
  'turlig',
  'turs',
  'tusan',
  'tusch',
  'tusen',
  'tuss',
  'tussa',
  'tut',
  'tuta',
  'tutsi',
  'tutt',
  'tutta',
  'tutti',
  'tuv',
  'tuva',
  'tuvad',
  'tuvig',
  'tuvull',
  'tv',
  'tvaga',
  'tveka',
  'tvi',
  'tvina',
  'tving',
  'tvinga',
  'tvist',
  'tw',
  'tweed',
  'twist',
  'twista',
  'tx',
  'ty',
  'tya',
  'tycka',
  'tycke',
  'tyda',
  'tyfon',
  'tyfus',
  'tyg',
  'tyga',
  'tygel',
  'tygla',
  'tyken',
  'tyll',
  'tymus',
  'tyna',
  'tynga',
  'tyngd',
  'tyning',
  'typ',
  'typa',
  'tysk',
  'tyska',
  'tyst',
  'tysta',
  'tz',
  'u',
  'ub',
  'uc',
  'ud',
  'udd',
  'udda',
  'uddad',
  'udde',
  'uddig',
  'ue',
  'uf',
  'uff',
  'ufo',
  'ug',
  'uggla',
  'ugn',
  'ugrisk',
  'uh',
  'uhu',
  'ui',
  'uj',
  'uk',
  'ukas',
  'ul',
  'ula',
  'ulan',
  'ulk',
  'ulka',
  'ull',
  'ullig',
  'ullus',
  'ulma',
  'ultra',
  'ulv',
  'um',
  'umbra',
  'umebo',
  'un',
  'uncial',
  'undan',
  'under',
  'undin',
  'undra',
  'undre',
  'ung',
  'unge',
  'unik',
  'union',
  'unisex',
  'unken',
  'unkna',
  'unna',
  'uns',
  'unt',
  'uo',
  'up',
  'upp',
  'uppe',
  'uppge',
  'uppmed',
  'uppom',
  'uppta',
  'uq',
  'ur',
  'uran',
  'urarta',
  'urban',
  'urcell',
  'urdu',
  'urdum',
  'urea',
  'ureas',
  'uremi',
  'urhem',
  'urin',
  'urkok',
  'urkokt',
  'urmyt',
  'urna',
  'uroxe',
  'urtag',
  'urtid',
  'urtida',
  'urtyp',
  'urval',
  'us',
  'usans',
  'usch',
  'usel',
  'uslig',
  'usuell',
  'ut',
  'utan',
  'utav',
  'utbe',
  'utbo',
  'utbud',
  'utdata',
  'ute',
  'uteliv',
  'utge',
  'utgift',
  'uthus',
  'uti',
  'utkant',
  'utkik',
  'utkok',
  'utled',
  'utlekt',
  'utmana',
  'utmed',
  'utom',
  'utopi',
  'utrop',
  'utropa',
  'utrum',
  'utse',
  'utsida',
  'utsot',
  'utspel',
  'utspy',
  'utsug',
  'utsyn',
  'utta',
  'uttag',
  'uttal',
  'utter',
  'uttra',
  'uttyda',
  'utur',
  'uu',
  'uuu',
  'uuuu',
  'uv',
  'uvbo',
  'uvw',
  'uw',
  'ux',
  'uy',
  'uz',
  'uzbek',
  'v',
  'vaccin',
  'vad',
  'vada',
  'vadan',
  'vadd',
  'vadmal',
  'vag',
  'vagel',
  'vagga',
  'vagn',
  'vaj',
  'vaja',
  'vajer',
  'vak',
  'vaka',
  'vakant',
  'vaken',
  'vakna',
  'vakt',
  'vakta',
  'val',
  'vala',
  'valack',
  'vale',
  'valen',
  'valk',
  'valka',
  'valkig',
  'vall',
  'valla',
  'valna',
  'valp',
  'valpa',
  'valpig',
  'vals',
  'valsa',
  'valv',
  'vamp',
  'vampa',
  'vampyr',
  'van',
  'vana',
  'vand',
  'vang',
  'vanilj',
  'vank',
  'vanka',
  'vanna',
  'vant',
  'vante',
  'vapen',
  'var',
  'vara',
  'varan',
  'varas',
  'varav',
  'varda',
  'varde',
  'varest',
  'varg',
  'vari',
  'varia',
  'varig',
  'varje',
  'varm',
  'varna',
  'varom',
  'varp',
  'varpa',
  'vars',
  'varse',
  'varsel',
  'varsk',
  'vart',
  'varur',
  'varv',
  'varva',
  'varvid',
  'vas',
  'vase',
  'vask',
  'vaska',
  'vass',
  'vatten',
  'vax',
  'vaxa',
  'vaxvit',
  'vb',
  'vc',
  'vd',
  've',
  'veck',
  'vecka',
  'ved',
  'vedel',
  'vedkap',
  'vejde',
  'vek',
  'veka',
  'veke',
  'vekna',
  'vela',
  'velar',
  'vele',
  'velig',
  'velour',
  'vem',
  'vemod',
  'ven',
  'venia',
  'vepa',
  'verb',
  'verba',
  'verist',
  'verk',
  'verka',
  'verop',
  'vers',
  'verst',
  'verv',
  'vesen',
  'vesir',
  'vestal',
  'veta',
  'vete',
  'veto',
  'vett',
  'vetta',
  'vette',
  'veum',
  'vev',
  'veva',
  'vevla',
  'vf',
  'vg',
  'vh',
  'vi',
  'via',
  'vial',
  'vice',
  'vichy',
  'vicka',
  'vickel',
  'vid',
  'vida',
  'vidd',
  'vide',
  'video',
  'vidga',
  'vidja',
  'vidsyn',
  'vidta',
  'vift',
  'vifta',
  'vig',
  'viga',
  'vigg',
  'vigga',
  'vigge',
  'vik',
  'vika',
  'vikt',
  'vikta',
  'viktig',
  'vila',
  'vild',
  'vilde',
  'vilja',
  'vilje',
  'vill',
  'villa',
  'vilse',
  'vilsen',
  'vilt',
  'vimba',
  'vimla',
  'vimma',
  'vimsa',
  'vin',
  'vina',
  'vind',
  'vinda',
  'vindel',
  'ving',
  'vinge',
  'vingla',
  'vink',
  'vinka',
  'vinn',
  'vinna',
  'vinst',
  'vinter',
  'vinyl',
  'viol',
  'viola',
  'vipa',
  'vipp',
  'vippa',
  'vips',
  'vira',
  'virak',
  'viril',
  'virka',
  'virke',
  'viros',
  'virra',
  'virus',
  'vis',
  'visa',
  'visare',
  'vise',
  'vishet',
  'visir',
  'visit',
  'viska',
  'visp',
  'vispa',
  'viss',
  'vissel',
  'visso',
  'visst',
  'viste',
  'visum',
  'vit',
  'vita',
  'vital',
  'vitbok',
  'vite',
  'vitna',
  'vitra',
  'vits',
  'vitsa',
  'vitt',
  'vitta',
  'vitten',
  'vittra',
  'viv',
  'viva',
  'vivel',
  'vivre',
  'vj',
  'vk',
  'vl',
  'vm',
  'vn',
  'vo',
  'voall',
  'vodka',
  'voile',
  'voj',
  'voja',
  'vojt',
  'vojta',
  'vokal',
  'volm',
  'volma',
  'volt',
  'volta',
  'volut',
  'volym',
  'vom',
  'vomera',
  'votum',
  'vov',
  'vovve',
  'vp',
  'vq',
  'vr',
  'vrak',
  'vraka',
  'vred',
  'vrede',
  'vresig',
  'vret',
  'vrida',
  'vril',
  'vrist',
  'vs',
  'vt',
  'vu',
  'vula',
  'vulka',
  'vulst',
  'vulva',
  'vurm',
  'vurma',
  'vurpa',
  'vurst',
  'vuxen',
  'vv',
  'vvv',
  'vvvv',
  'vw',
  'vwx',
  'vx',
  'vy',
  'vyss',
  'vyssa',
  'vyssja',
  'vz',
  'w',
  'watt',
  'wb',
  'wc',
  'wd',
  'we',
  'wf',
  'wg',
  'wh',
  'whist',
  'wi',
  'wire',
  'wj',
  'wk',
  'wl',
  'wm',
  'wn',
  'wo',
  'wok',
  'woka',
  'wp',
  'wq',
  'wr',
  'ws',
  'wt',
  'wu',
  'wv',
  'ww',
  'www',
  'wwww',
  'wx',
  'wxy',
  'wy',
  'wz',
  'x',
  'xantin',
  'xb',
  'xc',
  'xd',
  'xe',
  'xenon',
  'xf',
  'xg',
  'xh',
  'xi',
  'xj',
  'xk',
  'xl',
  'xm',
  'xn',
  'xo',
  'xp',
  'xq',
  'xr',
  'xs',
  'xt',
  'xu',
  'xv',
  'xw',
  'xx',
  'xxx',
  'xxxx',
  'xy',
  'xylos',
  'xyz',
  'xz',
  'y',
  'yacht',
  'yard',
  'yawl',
  'yb',
  'yc',
  'yd',
  'ye',
  'yen',
  'yf',
  'yg',
  'yh',
  'yi',
  'yj',
  'yk',
  'yl',
  'yla',
  'ylle',
  'yllen',
  'ym',
  'ymnig',
  'ymp',
  'ympa',
  'yn',
  'yngel',
  'yngla',
  'yngre',
  'yngst',
  'ynk',
  'ynka',
  'ynkan',
  'ynnest',
  'yo',
  'yoga',
  'yogi',
  'yp',
  'yppa',
  'yppig',
  'yq',
  'yr',
  'yra',
  'yrhet',
  'yrka',
  'yrkan',
  'yrke',
  'yrsel',
  'ys',
  'ysta',
  'yste',
  'yster',
  'yt',
  'yta',
  'ytlig',
  'ytter',
  'yttra',
  'yttre',
  'ytved',
  'yu',
  'yv',
  'yva',
  'yvas',
  'yvig',
  'yw',
  'yx',
  'yxa',
  'yxegg',
  'yxhugg',
  'yxne',
  'yy',
  'yyy',
  'yyyy',
  'yz',
  'z',
  'zappa',
  'zb',
  'zc',
  'zd',
  'ze',
  'zebra',
  'zelot',
  'zenit',
  'zf',
  'zg',
  'zh',
  'zi',
  'zink',
  'zj',
  'zk',
  'zl',
  'zloty',
  'zm',
  'zn',
  'zo',
  'zon',
  'zonal',
  'zoo',
  'zooma',
  'zp',
  'zq',
  'zr',
  'zs',
  'zt',
  'zu',
  'zulu',
  'zv',
  'zw',
  'zx',
  'zy',
  'zygot',
  'zz',
  'zzz',
  'zzzz',
];
