export const turkish = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
  '60',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '69',
  '70',
  '71',
  '72',
  '73',
  '74',
  '75',
  '76',
  '77',
  '78',
  '79',
  '80',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '88',
  '89',
  '90',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '97',
  '98',
  '99',
  '100',
  '101',
  '111',
  '123',
  '200',
  '222',
  '234',
  '300',
  '333',
  '345',
  '400',
  '444',
  '456',
  '500',
  '555',
  '567',
  '600',
  '666',
  '678',
  '700',
  '777',
  '789',
  '800',
  '888',
  '900',
  '999',
  '1000',
  '1111',
  '1234',
  '1492',
  '1500',
  '1600',
  '1700',
  '1800',
  '1900',
  '1910',
  '1920',
  '1925',
  '1930',
  '1935',
  '1940',
  '1945',
  '1950',
  '1955',
  '1960',
  '1965',
  '1970',
  '1975',
  '1980',
  '1985',
  '1990',
  '1991',
  '1992',
  '1993',
  '1994',
  '1995',
  '1996',
  '1997',
  '1998',
  '1999',
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2015',
  '2020',
  '2030',
  '2035',
  '2040',
  '2045',
  '2050',
  '2222',
  '2345',
  '2468',
  '3000',
  '3333',
  '3456',
  '4000',
  '4321',
  '4444',
  '4567',
  '5000',
  '5555',
  '5678',
  '6000',
  '6666',
  '6789',
  '7000',
  '7777',
  '8000',
  '8888',
  '9000',
  '9876',
  '9999',
  '-',
  '--',
  '---',
  ':',
  ':-(',
  ':-)',
  ':(',
  ':)',
  '!',
  '!!',
  '!!!',
  '?',
  '??',
  '???',
  '"',
  '(',
  '()',
  ')',
  '@',
  '*',
  '**',
  '***',
  '&',
  '#',
  '##',
  '###',
  '%',
  '%%',
  '%%%',
  '+',
  '++',
  '+++',
  '=',
  '==',
  '===',
  '$',
  '$$',
  '$$$',
  'a',
  'aa',
  'aaa',
  'aaaa',
  'ab',
  'abajur',
  'abanmak',
  'abanoz',
  'abazaca',
  'abazan',
  'abc',
  'abd',
  'abdal',
  'abdest',
  'abece',
  'abes',
  'abide',
  'abis',
  'abiye',
  'abla',
  'ablak',
  'ablatya',
  'abluka',
  'abone',
  'abonman',
  'abramak',
  'abuhava',
  'abuk',
  'abur',
  'ac',
  'acaba',
  'acar',
  'acayip',
  'acele',
  'aceleci',
  'acemi',
  'acente',
  'acep',
  'acil',
  'acilen',
  'aciz',
  'acun',
  'acur',
  'ad',
  'ada',
  'adak',
  'adale',
  'adalet',
  'adam',
  'adamak',
  'adamot',
  'adap',
  'adapte',
  'aday',
  'adem',
  'adenit',
  'adet',
  'adil',
  'adisyon',
  'adliye',
  'adres',
  'ae',
  'aerobik',
  'af',
  'afacan',
  'afak',
  'afal',
  'afat',
  'afazi',
  'aferin',
  'aferist',
  'afet',
  'affola',
  'afis',
  'afiyet',
  'aforizm',
  'aforoz',
  'afra',
  'afsun',
  'afyon',
  'afyonla',
  'ag',
  'agitato',
  'agora',
  'agreman',
  'agresif',
  'agu',
  'ah',
  'aha',
  'ahali',
  'ahar',
  'ahbap',
  'ahenk',
  'aheste',
  'ahfat',
  'ahi',
  'ahir',
  'ahiren',
  'ahiret',
  'ahit',
  'ahiz',
  'ahize',
  'ahlamak',
  'ahlat',
  'ahmak',
  'ahmetli',
  'ahraz',
  'ahret',
  'ahtapot',
  'ahu',
  'ahududu',
  'ahval',
  'ai',
  'aidat',
  'aidiyet',
  'aile',
  'ait',
  'aj',
  'ajan',
  'ajanda',
  'ajans',
  'ajur',
  'ak',
  'akademi',
  'akamber',
  'akar',
  'akarca',
  'akarlar',
  'akarsu',
  'akasma',
  'akasya',
  'akbaba',
  'akbakla',
  'akdeniz',
  'akdiken',
  'akdut',
  'akhisar',
  'akide',
  'akkavak',
  'akkefal',
  'akkor',
  'aklamak',
  'aklan',
  'akliyat',
  'akliye',
  'akmak',
  'akont',
  'akort',
  'akraba',
  'akran',
  'akrep',
  'akrobat',
  'akropol',
  'aks',
  'aksak',
  'aksakal',
  'aksam',
  'aksamak',
  'aksan',
  'aksaray',
  'aksata',
  'aksedir',
  'aksi',
  'aksilik',
  'aksine',
  'aksiyom',
  'aksiyon',
  'akson',
  'aktar',
  'aktif',
  'aktinit',
  'aktris',
  'akustik',
  'akut',
  'akvam',
  'akvarel',
  'akyuvar',
  'al',
  'ala',
  'alabora',
  'alabros',
  'alacak',
  'alamana',
  'alan',
  'alarga',
  'alavere',
  'alay',
  'alaz',
  'alaza',
  'albatr',
  'albay',
  'albeni',
  'albinos',
  'aldehit',
  'alegori',
  'aleksi',
  'alem',
  'alemdar',
  'alenen',
  'alerji',
  'alerjik',
  'alesta',
  'alet',
  'alev',
  'aleyh',
  'alfa',
  'alfabe',
  'alfenit',
  'alg',
  'alil',
  'alim',
  'alivre',
  'alize',
  'alkali',
  'alkalik',
  'alkarna',
  'alkol',
  'alkolik',
  'allak',
  'allamak',
  'allegro',
  'almak',
  'almanak',
  'almanca',
  'almanya',
  'alo',
  'alp',
  'alpaka',
  'alpaks',
  'alperen',
  'alt',
  'altayca',
  'altes',
  'alto',
  'alveol',
  'alyans',
  'alyuvar',
  'ama',
  'amabile',
  'amade',
  'amal',
  'aman',
  'amazon',
  'ambar',
  'ambargo',
  'amber',
  'amberbu',
  'amblem',
  'amca',
  'amel',
  'amele',
  'ameliye',
  'amenna',
  'amerika',
  'ametal',
  'ametist',
  'amfi',
  'amfibi',
  'amfibol',
  'amfizem',
  'amfor',
  'amfora',
  'amigo',
  'amil',
  'amin',
  'amip',
  'amir',
  'amiral',
  'amitoz',
  'amiyane',
  'amma',
  'amonyak',
  'amonyum',
  'amorti',
  'amper',
  'ampir',
  'ampirik',
  'ampul',
  'amut',
  'amyant',
  'an',
  'ana',
  'anadolu',
  'anadut',
  'anaerki',
  'anafor',
  'anagram',
  'anahtar',
  'analist',
  'analiz',
  'analoji',
  'ananas',
  'anapara',
  'anartri',
  'anason',
  'anatomi',
  'anayasa',
  'anayurt',
  'ancak',
  'andante',
  'andaval',
  'andezit',
  'anekdot',
  'anemi',
  'anemik',
  'anemon',
  'aneroit',
  'angarya',
  'angut',
  'animato',
  'animizm',
  'anjin',
  'anjiyo',
  'anket',
  'ankiloz',
  'anlak',
  'anlam',
  'anlamak',
  'anma',
  'anmak',
  'anne',
  'anofel',
  'anomali',
  'anonim',
  'anons',
  'anorak',
  'anormal',
  'anot',
  'ansefal',
  'ant',
  'antakya',
  'antalya',
  'antant',
  'anten',
  'anterit',
  'antet',
  'antidot',
  'antijen',
  'antik',
  'antika',
  'antikor',
  'antilop',
  'antimon',
  'antitez',
  'antrakt',
  'antre',
  'antrepo',
  'antrkot',
  'antrok',
  'anut',
  'anyon',
  'anzarot',
  'ao',
  'aort',
  'ap',
  'apak',
  'apalak',
  'apandis',
  'apar',
  'aparey',
  'aparkat',
  'aparmak',
  'apatit',
  'apaz',
  'apel',
  'apiko',
  'aplik',
  'aplike',
  'apolet',
  'aport',
  'apotr',
  'apre',
  'apse',
  'apsent',
  'apsis',
  'aptal',
  'aq',
  'ar',
  'ara',
  'araba',
  'araban',
  'arabesk',
  'arabist',
  'arak',
  'araka',
  'aramak',
  'aranje',
  'aranmak',
  'arapkir',
  'arapsun',
  'ararot',
  'arasta',
  'araz',
  'arazbar',
  'arazi',
  'arbalet',
  'arbede',
  'arda',
  'ardahan',
  'ardak',
  'ardiye',
  'arduaz',
  'arefe',
  'arena',
  'argali',
  'argo',
  'argon',
  'argonot',
  'arguvan',
  'aria',
  'arif',
  'arifane',
  'arife',
  'arioso',
  'aritmi',
  'aritmik',
  'ariya',
  'ariyet',
  'ariza',
  'ark',
  'arka',
  'arkaik',
  'arkaizm',
  'arkeen',
  'arkegon',
  'arkoz',
  'arktik',
  'arma',
  'armada',
  'armador',
  'armoni',
  'armonik',
  'armut',
  'armutlu',
  'armuz',
  'arnavut',
  'arnika',
  'aroma',
  'arp',
  'arpa',
  'arpej',
  'arsa',
  'arsenik',
  'arslan',
  'art',
  'arter',
  'arterit',
  'artist',
  'artmak',
  'artrit',
  'artroz',
  'aruz',
  'arya',
  'arz',
  'arziyat',
  'arzu',
  'as',
  'asa',
  'asabiye',
  'asal',
  'asalak',
  'asalet',
  'asamble',
  'asap',
  'asar',
  'asbest',
  'asepsi',
  'ases',
  'asetat',
  'asetik',
  'aseton',
  'asfalt',
  'ashap',
  'asi',
  'aside',
  'asil',
  'asistan',
  'asit',
  'ask',
  'askarit',
  'asker',
  'asla',
  'aslan',
  'aslen',
  'asliye',
  'asma',
  'asmak',
  'asmolen',
  'asonans',
  'asorti',
  'asosyal',
  'aspirin',
  'aspur',
  'assai',
  'ast',
  'astar',
  'astarya',
  'astat',
  'astatin',
  'asude',
  'asuman',
  'at',
  'ata',
  'atabek',
  'atabey',
  'ataerki',
  'atak',
  'atalet',
  'atamak',
  'ataman',
  'atari',
  'atavik',
  'atavizm',
  'ate',
  'ateh',
  'ateist',
  'ateizm',
  'aterina',
  'atfen',
  'ati',
  'atik',
  'atlamak',
  'atlas',
  'atlet',
  'atletik',
  'atmaca',
  'atmak',
  'atol',
  'atom',
  'atomal',
  'atomik',
  'atonal',
  'atropin',
  'attar',
  'au',
  'aut',
  'av',
  'aval',
  'avam',
  'avanak',
  'avans',
  'avanta',
  'avantaj',
  'avara',
  'avare',
  'avarya',
  'avaz',
  'avdet',
  'avene',
  'averaj',
  'avisto',
  'avize',
  'avlak',
  'avlamak',
  'avlu',
  'avokado',
  'avrasya',
  'avrat',
  'avret',
  'avrovil',
  'avukat',
  'avunmak',
  'avuntu',
  'avurt',
  'avutmak',
  'aw',
  'ax',
  'ay',
  'aya',
  'ayak',
  'ayakucu',
  'ayakyol',
  'ayal',
  'ayan',
  'ayandon',
  'ayar',
  'ayaz',
  'ayazma',
  'aybeay',
  'aydemir',
  'ayet',
  'ayin',
  'ayla',
  'aylak',
  'aylamak',
  'aymak',
  'ayna',
  'aynaz',
  'aynen',
  'ayniyat',
  'ayniyet',
  'ayol',
  'ayran',
  'aysberg',
  'aysfilt',
  'ayva',
  'ayvan',
  'ayvaz',
  'ayyar',
  'ayyuka',
  'az',
  'aza',
  'azalmak',
  'azamet',
  'azap',
  'azar',
  'azat',
  'azdavay',
  'azim',
  'azimet',
  'aziz',
  'azize',
  'aziziye',
  'azmak',
  'azman',
  'aznavur',
  'azot',
  'b',
  'baba',
  'babacan',
  'baca',
  'bacak',
  'bacanak',
  'badana',
  'badas',
  'badat',
  'bade',
  'badehu',
  'badem',
  'badema',
  'baderna',
  'badi',
  'badik',
  'badire',
  'bagaj',
  'baget',
  'baha',
  'bahane',
  'bahar',
  'baharat',
  'bahir',
  'bahis',
  'bahname',
  'bahri',
  'bahriye',
  'baht',
  'bahusus',
  'bakalit',
  'bakam',
  'bakan',
  'bakanak',
  'bakara',
  'bakaya',
  'baki',
  'bakir',
  'bakire',
  'bakiye',
  'bakkal',
  'bakkam',
  'bakla',
  'baklan',
  'baklava',
  'bakliye',
  'bakmak',
  'bakteri',
  'bal',
  'bala',
  'balaban',
  'balak',
  'balama',
  'balans',
  'balar',
  'balast',
  'balat',
  'balata',
  'balbal',
  'baldo',
  'bale',
  'balerin',
  'balet',
  'balgam',
  'balina',
  'balkan',
  'balkon',
  'balo',
  'balon',
  'balotaj',
  'baloz',
  'balsam',
  'balta',
  'balya',
  'balyos',
  'balyoz',
  'bam',
  'bambu',
  'bambul',
  'bamya',
  'banak',
  'banal',
  'bandaj',
  'bando',
  'bandrol',
  'bani',
  'bank',
  'banka',
  'banker',
  'banket',
  'bankiz',
  'banknot',
  'banko',
  'banmak',
  'bant',
  'banyo',
  'baobap',
  'bap',
  'bar',
  'baraj',
  'barak',
  'baraka',
  'baran',
  'barata',
  'barba',
  'barbar',
  'barbata',
  'barbut',
  'barda',
  'bardak',
  'barem',
  'baret',
  'barfiks',
  'bargam',
  'barhana',
  'bari',
  'barikat',
  'barit',
  'baritin',
  'bariton',
  'bariyer',
  'bariz',
  'bark',
  'barka',
  'barlam',
  'barmen',
  'baro',
  'barok',
  'baron',
  'barsam',
  'barut',
  'baryum',
  'bas',
  'basamak',
  'basarna',
  'basen',
  'basil',
  'basiret',
  'basit',
  'basket',
  'basma',
  'basmak',
  'basso',
  'bastika',
  'baston',
  'basur',
  'basya',
  'bat',
  'batak',
  'batar',
  'batarya',
  'bateri',
  'bati',
  'batik',
  'batmak',
  'batman',
  'battal',
  'batur',
  'batyal',
  'bav',
  'bavul',
  'bavyera',
  'bay',
  'bayan',
  'bayat',
  'bayburt',
  'bayi',
  'bayii',
  'baylan',
  'baymak',
  'bayrak',
  'bayram',
  'baysal',
  'baytar',
  'baz',
  'bazal',
  'bazalt',
  'bazen',
  'bazik',
  'bazit',
  'bazlama',
  'bazuka',
  'bb',
  'bbb',
  'bbbb',
  'bc',
  'bcd',
  'bd',
  'be',
  'bebe',
  'bebek',
  'beceri',
  'becet',
  'becit',
  'bedahet',
  'bedava',
  'bedbaht',
  'bedbin',
  'beddua',
  'bedel',
  'beden',
  'bedenen',
  'bedhah',
  'bedir',
  'bednam',
  'begonya',
  'beher',
  'behey',
  'behre',
  'beis',
  'bej',
  'bek',
  'beka',
  'bekar',
  'bekas',
  'bekri',
  'bel',
  'belde',
  'belek',
  'belemek',
  'belemir',
  'belen',
  'belge',
  'belgi',
  'belgin',
  'belgit',
  'belik',
  'belirli',
  'belirti',
  'belit',
  'belki',
  'bellek',
  'belli',
  'bellik',
  'bemol',
  'ben',
  'bencil',
  'bendir',
  'benek',
  'bengi',
  'beniz',
  'benlik',
  'benmari',
  'bent',
  'benzen',
  'benzer',
  'benzin',
  'benzol',
  'beraat',
  'beraber',
  'berat',
  'berbat',
  'berber',
  'bere',
  'bereket',
  'bereli',
  'bergama',
  'berhane',
  'berhava',
  'beri',
  'beriki',
  'beril',
  'berjer',
  'berk',
  'bermuda',
  'berrak',
  'bertik',
  'bertmek',
  'berzah',
  'besalet',
  'besi',
  'besin',
  'beslek',
  'besleme',
  'besmele',
  'beste',
  'bet',
  'beta',
  'beter',
  'beti',
  'betik',
  'betim',
  'beton',
  'bevliye',
  'bevvap',
  'bey',
  'beyaban',
  'beyan',
  'beyanat',
  'beyaz',
  'beybaba',
  'beygir',
  'beyhude',
  'beyin',
  'beyinli',
  'beyit',
  'beyiye',
  'beylik',
  'beysbol',
  'beyyine',
  'beyzade',
  'bez',
  'beze',
  'bezek',
  'bezeli',
  'bezelye',
  'bezemek',
  'bezgin',
  'bezik',
  'bezir',
  'bezmek',
  'bf',
  'bg',
  'bh',
  'bi',
  'biber',
  'biberon',
  'biblo',
  'bici',
  'bicili',
  'bidayet',
  'bide',
  'bidon',
  'biftek',
  'bigudi',
  'bikini',
  'bikir',
  'bildik',
  'bildiri',
  'bile',
  'bilecik',
  'bilek',
  'bilemek',
  'bilet',
  'bilezik',
  'bilfarz',
  'bilfiil',
  'bilge',
  'bilgi',
  'bilgin',
  'bili',
  'bilim',
  'bilmece',
  'bilmek',
  'bilsat',
  'bilumum',
  'bilye',
  'bilyon',
  'bin',
  'bina',
  'binaen',
  'binek',
  'binici',
  'binit',
  'binlik',
  'binmek',
  'bir',
  'bira',
  'birader',
  'biraz',
  'birbir',
  'birebir',
  'birecik',
  'birer',
  'birey',
  'biri',
  'biricik',
  'birikim',
  'birim',
  'birinci',
  'birisi',
  'birler',
  'birlik',
  'birsam',
  'bistro',
  'bisturi',
  'bit',
  'bitek',
  'bitelge',
  'bitevi',
  'bitey',
  'bitim',
  'bitirim',
  'bitki',
  'bitkin',
  'bitmek',
  'bitnik',
  'bittabi',
  'bitter',
  'biye',
  'biyel',
  'biyolog',
  'biyonik',
  'biyopsi',
  'biyotit',
  'biz',
  'bizimki',
  'bizmut',
  'bizon',
  'bizzat',
  'bj',
  'bk',
  'bl',
  'blender',
  'blok',
  'blokaj',
  'bloke',
  'bloknot',
  'bm',
  'bn',
  'bo',
  'boa',
  'bobin',
  'bobinaj',
  'boca',
  'boci',
  'bocuk',
  'bodrum',
  'bodur',
  'bohem',
  'bok',
  'boks',
  'boksit',
  'bol',
  'bolca',
  'bolero',
  'bolivya',
  'bolluk',
  'bom',
  'bomba',
  'bombe',
  'bombok',
  'bomboz',
  'bonbon',
  'boncuk',
  'bone',
  'bonfile',
  'bonjur',
  'bono',
  'bopstil',
  'bor',
  'bora',
  'boraks',
  'boran',
  'borani',
  'borasit',
  'borat',
  'borazan',
  'borda',
  'bordo',
  'bordro',
  'borik',
  'borina',
  'bornova',
  'bornoz',
  'borsa',
  'boru',
  'boslu',
  'bostan',
  'bot',
  'botanik',
  'boy',
  'boya',
  'boyabat',
  'boyamak',
  'boykot',
  'boylam',
  'boynuz',
  'boyun',
  'boyut',
  'boz',
  'boza',
  'bozca',
  'bozgun',
  'bozkurt',
  'bozlak',
  'bozmak',
  'bozrak',
  'boztepe',
  'bozuk',
  'bozum',
  'bp',
  'bq',
  'br',
  'brahman',
  'branda',
  'bravo',
  'bre',
  'brezil',
  'brifing',
  'brik',
  'briket',
  'brizbiz',
  'brokar',
  'brokoli',
  'brom',
  'bronz',
  'bs',
  'bt',
  'bu',
  'buat',
  'bucak',
  'budak',
  'budala',
  'budamak',
  'budun',
  'buhar',
  'buhran',
  'buhur',
  'buji',
  'buket',
  'bukle',
  'buklet',
  'bukran',
  'bulada',
  'bulak',
  'bulamak',
  'buldok',
  'bulgari',
  'bulgu',
  'bulgur',
  'bullak',
  'bulmaca',
  'bulmak',
  'buluntu',
  'bulut',
  'bulvar',
  'bumbar',
  'bumbuz',
  'bunak',
  'bunama',
  'bunamak',
  'bungun',
  'bura',
  'burgu',
  'burhan',
  'burjuva',
  'burkmak',
  'burma',
  'burmak',
  'burnaz',
  'burs',
  'burtlak',
  'buru',
  'buruk',
  'burun',
  'burundi',
  'buse',
  'but',
  'butafor',
  'butik',
  'butlan',
  'buton',
  'buut',
  'buymak',
  'buyot',
  'buyruk',
  'buz',
  'buzhane',
  'buzuki',
  'buzul',
  'bv',
  'bw',
  'bx',
  'by',
  'bz',
  'c',
  'caba',
  'cabadan',
  'cadaloz',
  'cadde',
  'cafcaf',
  'cahil',
  'caiz',
  'caize',
  'caka',
  'cali',
  'calip',
  'cam',
  'camadan',
  'cambaz',
  'cami',
  'camia',
  'camit',
  'can',
  'canan',
  'canavar',
  'candan',
  'canfes',
  'canfeza',
  'cani',
  'canip',
  'car',
  'carcar',
  'carcur',
  'cari',
  'cariye',
  'cart',
  'carta',
  'casino',
  'casus',
  'cavlak',
  'caymak',
  'caz',
  'cazbant',
  'cazibe',
  'cazip',
  'cb',
  'cc',
  'ccc',
  'cccc',
  'cd',
  'cde',
  'ce',
  'cebbar',
  'cebe',
  'cebeci',
  'cebel',
  'cebeli',
  'ceberut',
  'cebin',
  'cebir',
  'cebire',
  'cebrail',
  'cebren',
  'cebriye',
  'cedit',
  'cedre',
  'cefa',
  'cehalet',
  'cehil',
  'cehit',
  'cehre',
  'cehri',
  'ceket',
  'celbe',
  'celep',
  'celi',
  'celil',
  'celp',
  'celse',
  'cemaat',
  'cemadat',
  'cemal',
  'cembiye',
  'cemil',
  'cemile',
  'cemiyet',
  'cemre',
  'cenabet',
  'cenah',
  'cenap',
  'cenaze',
  'cendere',
  'ceneviz',
  'cengel',
  'cenin',
  'cenk',
  'cennet',
  'cenup',
  'cep',
  'cephane',
  'cephe',
  'cepken',
  'cer',
  'cerahat',
  'cerbeze',
  'cereme',
  'ceren',
  'cereyan',
  'cerh',
  'ceride',
  'ceriha',
  'cerime',
  'cerrah',
  'cerrar',
  'cesamet',
  'cesaret',
  'ceset',
  'cesim',
  'ceste',
  'cesur',
  'cet',
  'cetvel',
  'cevaben',
  'cevahir',
  'cevap',
  'cevaz',
  'cevher',
  'cevir',
  'ceviz',
  'cevval',
  'ceza',
  'cezayir',
  'cezbe',
  'cezerye',
  'cezir',
  'cezire',
  'cezp',
  'cezve',
  'cf',
  'cg',
  'ch',
  'ci',
  'cibre',
  'cici',
  'cicik',
  'cicoz',
  'cidal',
  'cidar',
  'cidden',
  'cif',
  'cife',
  'cihan',
  'cihar',
  'cihat',
  'cihaz',
  'cihet',
  'cilasun',
  'cilban',
  'cilbent',
  'cildiye',
  'cilt',
  'cilve',
  'cim',
  'cima',
  'cimcime',
  'cimri',
  'cin',
  'cinas',
  'cinayet',
  'cinnet',
  'cins',
  'cinsel',
  'cip',
  'cips',
  'ciranta',
  'cirim',
  'cirit',
  'ciro',
  'cisim',
  'cismen',
  'civan',
  'civar',
  'civciv',
  'civelek',
  'ciyak',
  'cizye',
  'cj',
  'ck',
  'cl',
  'cm',
  'cn',
  'co',
  'cokey',
  'conta',
  'cop',
  'corum',
  'cp',
  'cq',
  'cr',
  'cs',
  'ct',
  'cu',
  'cudam',
  'cukka',
  'cuma',
  'cumba',
  'cumbul',
  'cumhur',
  'cunda',
  'cungul',
  'cunta',
  'cup',
  'cura',
  'curcuna',
  'curnata',
  'curt',
  'cv',
  'cw',
  'cx',
  'cy',
  'cz',
  'd',
  'dadaist',
  'dadaizm',
  'daha',
  'dahdah',
  'dahi',
  'dahil',
  'dahra',
  'daim',
  'daima',
  'dair',
  'daire',
  'dakik',
  'dakika',
  'daktilo',
  'dal',
  'dalak',
  'dalamak',
  'dalaman',
  'dalan',
  'dalfes',
  'dalga',
  'dalmak',
  'daluyku',
  'dalya',
  'dalyan',
  'dam',
  'dama',
  'damak',
  'damar',
  'damasko',
  'damat',
  'damga',
  'damla',
  'damper',
  'damping',
  'dan',
  'dana',
  'dandini',
  'dang',
  'daniska',
  'dank',
  'dans',
  'dantel',
  'dapdar',
  'dar',
  'dara',
  'daraban',
  'darbe',
  'darbuka',
  'darende',
  'darp',
  'dasit',
  'dasitan',
  'dastar',
  'datif',
  'dav',
  'dava',
  'davar',
  'davet',
  'davul',
  'davya',
  'dayak',
  'dayamak',
  'dayanak',
  'daz',
  'dazlak',
  'db',
  'dc',
  'dd',
  'ddd',
  'dddd',
  'de',
  'debagat',
  'debbe',
  'debboy',
  'debdebe',
  'debi',
  'debil',
  'deccal',
  'dede',
  'def',
  'def',
  'defa',
  'defans',
  'defile',
  'defin',
  'define',
  'defne',
  'defo',
  'deforme',
  'defter',
  'deh',
  'deha',
  'dehalet',
  'dehliz',
  'dek',
  'dekadan',
  'dekan',
  'dekar',
  'dekolte',
  'dekont',
  'dekor',
  'dekore',
  'dekovil',
  'delecek',
  'delege',
  'delgi',
  'deli',
  'delik',
  'delil',
  'delk',
  'delmek',
  'delta',
  'dem',
  'demagog',
  'demek',
  'demet',
  'demin',
  'demir',
  'demirci',
  'demlik',
  'demode',
  'denaet',
  'denden',
  'denek',
  'deneme',
  'denemek',
  'denet',
  'denetim',
  'deney',
  'deneyim',
  'denge',
  'deniz',
  'denizat',
  'denizli',
  'denk',
  'denklem',
  'denli',
  'densiz',
  'depar',
  'depo',
  'depozit',
  'deprem',
  'derakap',
  'derbent',
  'derdest',
  'dere',
  'derece',
  'dereotu',
  'dergi',
  'deri',
  'derin',
  'derinti',
  'derk',
  'derman',
  'dermek',
  'dermit',
  'dernek',
  'ders',
  'dersiam',
  'dert',
  'dertop',
  'deruhte',
  'derun',
  'derya',
  'derz',
  'desen',
  'desibel',
  'desise',
  'despot',
  'dessas',
  'dest',
  'destan',
  'deste',
  'destek',
  'destur',
  'detant',
  'detay',
  'dev',
  'deva',
  'devam',
  'devasa',
  'deve',
  'deveran',
  'devim',
  'devinim',
  'devir',
  'devlet',
  'devran',
  'devre',
  'devren',
  'devrik',
  'devrim',
  'devriye',
  'deyi',
  'deyim',
  'deyyus',
  'df',
  'dg',
  'dh',
  'di',
  'dialkol',
  'diba',
  'dibace',
  'dibek',
  'didar',
  'didik',
  'didon',
  'difana',
  'difteri',
  'diftong',
  'dijital',
  'dik',
  'diken',
  'dikensi',
  'dikey',
  'dikgen',
  'dikili',
  'dikine',
  'dikit',
  'dikiz',
  'dikkat',
  'diklik',
  'dikmek',
  'dikmen',
  'dikse',
  'dikta',
  'dikte',
  'dil',
  'dilalt',
  'dilbaz',
  'dilber',
  'dilek',
  'dilemek',
  'dilemma',
  'dilenci',
  'dilim',
  'dilmek',
  'dimdik',
  'dimi',
  'dimnit',
  'dimyat',
  'din',
  'dinamik',
  'dinamit',
  'dinamo',
  'dinar',
  'dindar',
  'dinen',
  'dineri',
  'dingi',
  'dingil',
  'dingin',
  'dink',
  'dinleti',
  'dinmek',
  'dinozor',
  'dip',
  'dipdiri',
  'dipfriz',
  'diploma',
  'dipnot',
  'dirayet',
  'direk',
  'direkt',
  'diremek',
  'diren',
  'direnim',
  'direy',
  'dirgen',
  'dirhem',
  'diri',
  'diril',
  'dirlik',
  'dirsek',
  'disk',
  'disket',
  'disko',
  'diskur',
  'ditmek',
  'dival',
  'divan',
  'divane',
  'divik',
  'divit',
  'divitin',
  'divlek',
  'diyabaz',
  'diyabet',
  'diyadin',
  'diyakoz',
  'diyalel',
  'diyaliz',
  'diyalog',
  'diyanet',
  'diyar',
  'diye',
  'diyet',
  'diyez',
  'diyorit',
  'diz',
  'dizayn',
  'dizdar',
  'dize',
  'dizel',
  'dizge',
  'dizgi',
  'dizgin',
  'dizi',
  'dizim',
  'dizin',
  'dizmek',
  'dizyem',
  'dj',
  'dk',
  'dl',
  'dm',
  'dn',
  'do',
  'dobra',
  'dodurga',
  'dogma',
  'dok',
  'doksan',
  'doktor',
  'doktora',
  'doktrin',
  'doku',
  'dokuma',
  'dokumak',
  'dokuz',
  'dolak',
  'dolam',
  'dolamak',
  'dolap',
  'dolar',
  'dolay',
  'dolgu',
  'dolgun',
  'dolma',
  'dolmak',
  'dolomit',
  'dolu',
  'dolunay',
  'domalan',
  'domates',
  'dombay',
  'domdom',
  'domino',
  'domur',
  'domuz',
  'don',
  'donam',
  'donamak',
  'done',
  'donmak',
  'donra',
  'donuk',
  'dopdolu',
  'doping',
  'doru',
  'doruk',
  'dorum',
  'dost',
  'dostane',
  'dosya',
  'doygun',
  'doymak',
  'doz',
  'dozaj',
  'dozer',
  'dp',
  'dq',
  'dr',
  'dragon',
  'drahmi',
  'drahoma',
  'draje',
  'dram',
  'drama',
  'dren',
  'drenaj',
  'dretnot',
  'drezin',
  'drosera',
  'ds',
  'dt',
  'du',
  'dua',
  'duahan',
  'duayen',
  'duba',
  'dubar',
  'dubara',
  'duble',
  'dubleks',
  'dudak',
  'dudu',
  'duetto',
  'duhul',
  'duka',
  'dul',
  'dulda',
  'duman',
  'dumdum',
  'dumur',
  'dun',
  'duo',
  'dupduru',
  'durak',
  'dural',
  'durgu',
  'durgun',
  'durmak',
  'duru',
  'durum',
  'dut',
  'duvak',
  'duvar',
  'duy',
  'duyar',
  'duyarga',
  'duygu',
  'duygun',
  'duymak',
  'duyu',
  'duyum',
  'duyuru',
  'dv',
  'dw',
  'dx',
  'dy',
  'dz',
  'e',
  'eb',
  'ebabil',
  'ebat',
  'ebcet',
  'ebe',
  'ebet',
  'ebeveyn',
  'ebleh',
  'ebonit',
  'ebru',
  'ec',
  'ecdat',
  'ece',
  'eceabat',
  'ecel',
  'ecinni',
  'ecir',
  'ecnebi',
  'ecza',
  'eczane',
  'ed',
  'eda',
  'edat',
  'ede',
  'edep',
  'eder',
  'edevat',
  'edik',
  'edilgen',
  'edim',
  'edinmek',
  'edip',
  'edisyon',
  'edna',
  'edremit',
  'edvar',
  'ee',
  'eee',
  'eeee',
  'ef',
  'efe',
  'efekt',
  'efektif',
  'efendi',
  'efg',
  'efor',
  'efrat',
  'efsane',
  'efsun',
  'eg',
  'ege',
  'egemen',
  'eglog',
  'ego',
  'egoist',
  'egoizm',
  'egotizm',
  'egzama',
  'egzotik',
  'egzoz',
  'eh',
  'ehem',
  'ehil',
  'ehlidil',
  'ehliyet',
  'ehram',
  'ehrimen',
  'ehven',
  'ei',
  'ej',
  'ejder',
  'ejderha',
  'ek',
  'ekenek',
  'ekili',
  'ekim',
  'ekin',
  'ekinoks',
  'ekip',
  'ekipman',
  'eklem',
  'eklemek',
  'eklenti',
  'ekli',
  'ekmek',
  'ekol',
  'ekoloji',
  'ekonomi',
  'ekose',
  'ekran',
  'eksen',
  'ekser',
  'ekseri',
  'eksi',
  'eksibe',
  'eksik',
  'eksin',
  'eksper',
  'ekspoze',
  'ekspres',
  'ekstra',
  'ekstre',
  'ekstrem',
  'ekvator',
  'el',
  'elaman',
  'elan',
  'elbet',
  'elbette',
  'elbeyli',
  'elbise',
  'elde',
  'eldivan',
  'eldiven',
  'eleji',
  'elek',
  'elektro',
  'elem',
  'eleman',
  'elemek',
  'element',
  'elemge',
  'elenmek',
  'elense',
  'elenti',
  'elezer',
  'elgin',
  'elhak',
  'elif',
  'elifba',
  'elik',
  'elim',
  'elips',
  'eliptik',
  'elit',
  'ellemek',
  'elli',
  'elma',
  'elmas',
  'elti',
  'elvan',
  'elveda',
  'elyaf',
  'elzem',
  'em',
  'emanet',
  'emare',
  'emaret',
  'emay',
  'emaye',
  'emcek',
  'emcik',
  'emek',
  'emekli',
  'emektar',
  'emel',
  'emin',
  'emir',
  'emirber',
  'emisyon',
  'emlemek',
  'emmek',
  'emmi',
  'emniyet',
  'emoroit',
  'empoze',
  'emprime',
  'emraz',
  'emrihak',
  'emsal',
  'emtia',
  'emval',
  'emzik',
  'en',
  'enam',
  'enayi',
  'enberi',
  'enbiya',
  'encam',
  'encek',
  'encik',
  'endam',
  'endaze',
  'endeks',
  'endemik',
  'ender',
  'enderun',
  'enek',
  'enemek',
  'enerji',
  'enerjik',
  'enez',
  'enfes',
  'enfiye',
  'engebe',
  'engel',
  'engerek',
  'engin',
  'enginar',
  'enik',
  'enikonu',
  'enir',
  'enkaz',
  'enlem',
  'ense',
  'enser',
  'entari',
  'entegre',
  'entel',
  'enterne',
  'entimem',
  'entrika',
  'enva',
  'enzim',
  'eo',
  'eosen',
  'ep',
  'epe',
  'epey',
  'epeyce',
  'epeyi',
  'epidemi',
  'epiderm',
  'epifit',
  'epik',
  'epilog',
  'epitel',
  'epizot',
  'epope',
  'eprimek',
  'epsilon',
  'eq',
  'er',
  'erat',
  'erbain',
  'erbap',
  'erbin',
  'erbiyum',
  'erciyes',
  'erdem',
  'erdemli',
  'erek',
  'eren',
  'erendiz',
  'erfelek',
  'erg',
  'erganun',
  'ergen',
  'ergi',
  'ergime',
  'ergimek',
  'ergin',
  'erguvan',
  'erigen',
  'erik',
  'eril',
  'erim',
  'erimek',
  'erinmek',
  'eristik',
  'eriyik',
  'erk',
  'erke',
  'erkek',
  'erken',
  'erkete',
  'erkin',
  'ermek',
  'ermenek',
  'ermin',
  'eroin',
  'eros',
  'erotik',
  'erotizm',
  'erozyon',
  'erte',
  'ertesi',
  'ervah',
  'erzak',
  'erzurum',
  'es',
  'esans',
  'esaret',
  'esas',
  'esasen',
  'esatir',
  'esbap',
  'esef',
  'esen',
  'esenler',
  'eser',
  'esermek',
  'esham',
  'esik',
  'esim',
  'esin',
  'esinti',
  'esir',
  'eski',
  'eskimek',
  'eskiz',
  'eskort',
  'eskrim',
  'eslemek',
  'esmek',
  'esmer',
  'esna',
  'esnaf',
  'esnek',
  'esnemek',
  'espas',
  'esperi',
  'espri',
  'esrar',
  'esre',
  'esrik',
  'esrimek',
  'essah',
  'estamp',
  'ester',
  'estet',
  'estetik',
  'estomp',
  'esvap',
  'et',
  'etajer',
  'etalon',
  'etamin',
  'etanol',
  'etap',
  'etek',
  'eten',
  'etene',
  'eter',
  'etik',
  'etiket',
  'etil',
  'etilen',
  'etken',
  'etki',
  'etkimek',
  'etkin',
  'etmek',
  'etmen',
  'etnik',
  'etnolog',
  'etobur',
  'etol',
  'etraf',
  'etyemez',
  'eu',
  'ev',
  'evaze',
  'evcara',
  'evcek',
  'evcil',
  'evciler',
  'evcilik',
  'evcimen',
  'evecen',
  'evermek',
  'evet',
  'evgin',
  'evham',
  'evin',
  'evirgen',
  'evirmek',
  'evirtim',
  'eviye',
  'evkaf',
  'evlek',
  'evli',
  'evlilik',
  'evliya',
  'evrak',
  'evrat',
  'evre',
  'evren',
  'evrik',
  'evrim',
  'evsaf',
  'evsemek',
  'evvel',
  'evvelsi',
  'ew',
  'ex',
  'ey',
  'eyalet',
  'eyer',
  'eylem',
  'eylemek',
  'eynesil',
  'eytam',
  'eyvah',
  'eyvan',
  'eyyam',
  'ez',
  'eza',
  'ezan',
  'ezber',
  'ezel',
  'ezgi',
  'ezgin',
  'ezik',
  'ezilgen',
  'ezinti',
  'eziyet',
  'ezkaza',
  'ezmek',
  'f',
  'faal',
  'fabl',
  'facia',
  'fagot',
  'fahur',
  'faik',
  'fail',
  'faiz',
  'fak',
  'fakat',
  'fakfon',
  'fakih',
  'fakir',
  'faks',
  'fal',
  'falaka',
  'falan',
  'falanj',
  'falso',
  'falya',
  'fanfan',
  'fanfar',
  'fanfin',
  'fangri',
  'fani',
  'fanta',
  'fanti',
  'fanus',
  'fanya',
  'far',
  'faraza',
  'farba',
  'fare',
  'farika',
  'fariza',
  'fark',
  'fars',
  'farz',
  'fasa',
  'fasih',
  'fasile',
  'fasit',
  'faska',
  'fason',
  'fasone',
  'fasulye',
  'fatih',
  'fatiha',
  'fatura',
  'faul',
  'fava',
  'favori',
  'fay',
  'fayans',
  'fayda',
  'fayrap',
  'fayton',
  'faz',
  'fazla',
  'fb',
  'fc',
  'fd',
  'fe',
  'fecaat',
  'feci',
  'fecir',
  'feda',
  'federal',
  'federe',
  'feding',
  'fehva',
  'fek',
  'felek',
  'felsefe',
  'fen',
  'fena',
  'fener',
  'fenik',
  'fenol',
  'fenomen',
  'fent',
  'feodal',
  'fer',
  'ferace',
  'ferah',
  'ferda',
  'ferde',
  'feribot',
  'ferih',
  'ferik',
  'ferma',
  'ferman',
  'fersah',
  'fert',
  'feryat',
  'ferz',
  'fes',
  'fesat',
  'fesih',
  'fetih',
  'fetret',
  'fettan',
  'fetva',
  'fevk',
  'fevt',
  'feyiz',
  'feyyaz',
  'feza',
  'ff',
  'fff',
  'ffff',
  'fg',
  'fgh',
  'fh',
  'fi',
  'fiber',
  'fibrin',
  'fidan',
  'fide',
  'fidye',
  'fifre',
  'figan',
  'fiil',
  'fiilen',
  'fikir',
  'fikren',
  'fil',
  'file',
  'filenk',
  'filet',
  'fileto',
  'filika',
  'filiz',
  'film',
  'filo',
  'filoz',
  'filtre',
  'filum',
  'final',
  'finans',
  'fincan',
  'fingir',
  'fino',
  'firak',
  'firar',
  'fire',
  'firez',
  'firik',
  'firkat',
  'firma',
  'firuze',
  'fiske',
  'fiskos',
  'fiso',
  'fistan',
  'fisto',
  'fit',
  'fitil',
  'fitne',
  'fitre',
  'fiyaka',
  'fiyat',
  'fiyonk',
  'fiyort',
  'fizik',
  'fj',
  'fk',
  'fl',
  'flebit',
  'fleol',
  'flit',
  'flok',
  'flora',
  'flori',
  'florin',
  'fm',
  'fn',
  'fo',
  'fob',
  'fobi',
  'fodra',
  'fodul',
  'fok',
  'fokur',
  'fol',
  'folk',
  'fon',
  'fonda',
  'fondan',
  'fonem',
  'font',
  'fora',
  'forint',
  'form',
  'forma',
  'formen',
  'formol',
  'foroz',
  'fors',
  'forsa',
  'forte',
  'forum',
  'forvet',
  'fos',
  'fosfat',
  'fosfor',
  'fosgen',
  'fosil',
  'fosur',
  'fota',
  'foto',
  'foya',
  'fp',
  'fq',
  'fr',
  'frak',
  'frank',
  'frapan',
  'fren',
  'frengi',
  'fresk',
  'freze',
  'frigo',
  'frikik',
  'frisa',
  'friz',
  'fs',
  'ft',
  'fu',
  'fuar',
  'fuaye',
  'fujer',
  'fukara',
  'ful',
  'fule',
  'fulya',
  'funda',
  'furgon',
  'furya',
  'fut',
  'futa',
  'futbol',
  'fv',
  'fw',
  'fx',
  'fy',
  'fz',
  'g',
  'gabari',
  'gabi',
  'gabin',
  'gabro',
  'gabya',
  'gaco',
  'gaddar',
  'gadir',
  'gaf',
  'gaffar',
  'gafil',
  'gaflet',
  'gafur',
  'gag',
  'gaga',
  'gaile',
  'gaita',
  'gak',
  'gala',
  'galat',
  'galebe',
  'galeri',
  'galeta',
  'gali',
  'galiba',
  'galip',
  'galiz',
  'galon',
  'galyot',
  'galyum',
  'gam',
  'gama',
  'gambot',
  'gammaz',
  'gamze',
  'gani',
  'ganyan',
  'gar',
  'garaip',
  'garaj',
  'garaz',
  'gard',
  'garez',
  'gargar',
  'garibe',
  'garip',
  'gark',
  'garoz',
  'garp',
  'garson',
  'gasp',
  'gayda',
  'gaye',
  'gayet',
  'gayr',
  'gayret',
  'gayri',
  'gayur',
  'gayya',
  'gayz',
  'gayzer',
  'gaz',
  'gaza',
  'gazal',
  'gazap',
  'gazel',
  'gazete',
  'gazi',
  'gazino',
  'gazoil',
  'gazoyl',
  'gazoz',
  'gb',
  'gc',
  'gd',
  'ge',
  'gebe',
  'gece',
  'gececi',
  'gedik',
  'gedmek',
  'gelgel',
  'gelgit',
  'gelin',
  'gelir',
  'gelmek',
  'gem',
  'gemi',
  'gen',
  'gene',
  'genel',
  'geniz',
  'genlik',
  'genom',
  'geoit',
  'gerdan',
  'gerdek',
  'gerdel',
  'gerek',
  'geren',
  'gergef',
  'gergi',
  'gergin',
  'geri',
  'gerici',
  'geriz',
  'germek',
  'germen',
  'gerzek',
  'getiri',
  'getto',
  'geven',
  'geveze',
  'gevher',
  'gevmek',
  'gevrek',
  'geyik',
  'gez',
  'gezgin',
  'gezi',
  'gezmek',
  'gezmen',
  'gf',
  'gg',
  'ggg',
  'gggg',
  'gh',
  'ghi',
  'gi',
  'gibi',
  'gider',
  'gidi',
  'gidon',
  'gine',
  'giray',
  'girdap',
  'girdi',
  'girgin',
  'girift',
  'girmek',
  'gitar',
  'gitmek',
  'giyim',
  'giyit',
  'giymek',
  'giysi',
  'giz',
  'gizem',
  'gizli',
  'gj',
  'gk',
  'gl',
  'glikol',
  'glikoz',
  'global',
  'glokom',
  'gm',
  'gn',
  'gnays',
  'go',
  'goblen',
  'gocuk',
  'gofret',
  'gol',
  'golf',
  'gonca',
  'gondol',
  'gonk',
  'goril',
  'gotik',
  'gp',
  'gq',
  'gr',
  'grado',
  'grafik',
  'grafit',
  'gram',
  'gramaj',
  'gramer',
  'granat',
  'grandi',
  'granit',
  'gres',
  'grev',
  'gri',
  'gril',
  'grip',
  'grizu',
  'grosa',
  'grup',
  'gs',
  'gt',
  'gu',
  'guano',
  'guatr',
  'gucur',
  'gudde',
  'gufran',
  'guguk',
  'gulden',
  'gulet',
  'gulu',
  'gurbet',
  'gurk',
  'gurup',
  'gurur',
  'gusto',
  'gut',
  'gv',
  'gw',
  'gx',
  'gy',
  'gz',
  'h',
  'habbe',
  'haber',
  'habip',
  'habis',
  'hac',
  'hacet',
  'hacim',
  'hacir',
  'haciz',
  'had',
  'hadde',
  'hademe',
  'hadi',
  'hadim',
  'hadis',
  'haf',
  'hafif',
  'hafit',
  'hafiye',
  'hafta',
  'hah',
  'haham',
  'hahha',
  'hahhah',
  'hail',
  'haile',
  'hain',
  'haiz',
  'hak',
  'hakan',
  'hakem',
  'hakeza',
  'hakim',
  'hakir',
  'hal',
  'hala',
  'halat',
  'halay',
  'hale',
  'halef',
  'halel',
  'halet',
  'halfa',
  'halhal',
  'halife',
  'halik',
  'halim',
  'halis',
  'halita',
  'halk',
  'halka',
  'halt',
  'halter',
  'halvet',
  'ham',
  'hamail',
  'hamak',
  'hamal',
  'hamam',
  'hami',
  'hamil',
  'hamile',
  'hamle',
  'hamse',
  'hamsi',
  'hamsin',
  'hamt',
  'hamur',
  'hamut',
  'han',
  'hanay',
  'handan',
  'hande',
  'hane',
  'hanek',
  'hangar',
  'hangi',
  'hani',
  'hantal',
  'hap',
  'hapis',
  'har',
  'hara',
  'harabe',
  'haram',
  'harami',
  'harap',
  'harar',
  'haraza',
  'harbe',
  'harbi',
  'hardal',
  'hare',
  'hareke',
  'harem',
  'harf',
  'harika',
  'harir',
  'haris',
  'harita',
  'harlak',
  'harman',
  'harnup',
  'harp',
  'hars',
  'hart',
  'harta',
  'has',
  'hasar',
  'hasat',
  'haseki',
  'hasep',
  'haset',
  'hasis',
  'haslet',
  'haspa',
  'hasret',
  'hassa',
  'hassas',
  'hasse',
  'hasta',
  'hasut',
  'hat',
  'hata',
  'hatif',
  'hatim',
  'hatip',
  'hatmi',
  'hatta',
  'hattat',
  'hatun',
  'hav',
  'hava',
  'havale',
  'havan',
  'havas',
  'havi',
  'havil',
  'havlu',
  'havra',
  'havut',
  'havuz',
  'havya',
  'havyar',
  'havza',
  'hay',
  'haya',
  'hayal',
  'hayat',
  'haybe',
  'hayda',
  'haydi',
  'haydin',
  'haydut',
  'hayhay',
  'hayhuy',
  'haylaz',
  'hayli',
  'hayran',
  'hayrat',
  'hayret',
  'hayta',
  'hayvan',
  'haz',
  'haza',
  'hazan',
  'hazar',
  'hazin',
  'hazine',
  'hazne',
  'hazret',
  'hb',
  'hc',
  'hd',
  'he',
  'heba',
  'heccav',
  'hece',
  'hecin',
  'hedef',
  'heder',
  'hedik',
  'hediye',
  'hekim',
  'hektar',
  'hele',
  'helke',
  'helme',
  'helva',
  'helyum',
  'hem',
  'hemati',
  'hemen',
  'hempa',
  'hemze',
  'hendek',
  'hep',
  'hepsi',
  'hepten',
  'hepyek',
  'her',
  'herek',
  'herif',
  'herk',
  'herkes',
  'hertz',
  'herze',
  'hesap',
  'hevenk',
  'heves',
  'hey',
  'heybe',
  'heybet',
  'heyet',
  'heyhat',
  'heyhey',
  'heykel',
  'hezel',
  'hf',
  'hg',
  'hh',
  'hhh',
  'hhhh',
  'hi',
  'hibe',
  'hicap',
  'hicaz',
  'hiciv',
  'hicran',
  'hicret',
  'hiddet',
  'hidra',
  'hidrat',
  'hij',
  'hijyen',
  'hikmet',
  'hile',
  'hilkat',
  'himaye',
  'himen',
  'himmet',
  'hin',
  'hindi',
  'hipnoz',
  'hippi',
  'his',
  'hisar',
  'hisse',
  'hitabe',
  'hitam',
  'hitan',
  'hitap',
  'hiza',
  'hizip',
  'hizmet',
  'hj',
  'hk',
  'hl',
  'hm',
  'hn',
  'ho',
  'hobi',
  'hoca',
  'hodan',
  'hodbin',
  'hodri',
  'hokey',
  'hokka',
  'hol',
  'homur',
  'hona',
  'hop',
  'hoppa',
  'hor',
  'hora',
  'horhor',
  'hormon',
  'horon',
  'horoz',
  'hortum',
  'horul',
  'hostes',
  'hotoz',
  'hoyrat',
  'hoyuk',
  'hozan',
  'hp',
  'hq',
  'hr',
  'hs',
  'ht',
  'hu',
  'hudut',
  'hukuk',
  'hulya',
  'hum',
  'humar',
  'humma',
  'humus',
  'hun',
  'hunhar',
  'huni',
  'hunnak',
  'hunriz',
  'hupur',
  'hurafe',
  'hurda',
  'huri',
  'hurma',
  'hurra',
  'hurt',
  'husuf',
  'husul',
  'husus',
  'husye',
  'hutbe',
  'huy',
  'huzme',
  'huzur',
  'hv',
  'hw',
  'hx',
  'hy',
  'hz',
  'i',
  'iade',
  'iane',
  'iare',
  'ib',
  'ibadet',
  'ibare',
  'ibaret',
  'ibate',
  'ibda',
  'ibibik',
  'ibik',
  'ibis',
  'iblis',
  'ibra',
  'ibraz',
  'ibre',
  'ibret',
  'ibrik',
  'ibzal',
  'ic',
  'icabet',
  'icap',
  'icar',
  'icat',
  'icaz',
  'icazet',
  'icbar',
  'icmal',
  'icra',
  'icraat',
  'id',
  'idam',
  'idare',
  'iddia',
  'ide',
  'idea',
  'ideal',
  'idil',
  'idman',
  'idrak',
  'idrar',
  'ie',
  'if',
  'ifa',
  'ifade',
  'iffet',
  'ifna',
  'ifrat',
  'ifraz',
  'ifrit',
  'iftar',
  'iftira',
  'ig',
  'iguana',
  'ih',
  'ihale',
  'iham',
  'ihanet',
  'ihata',
  'ihbar',
  'ihdas',
  'ihmal',
  'ihram',
  'ihraz',
  'ihsan',
  'ihsas',
  'ihtar',
  'ihtida',
  'ihtira',
  'ihtiva',
  'ihvan',
  'ihya',
  'ihzar',
  'ii',
  'iii',
  'iiii',
  'ij',
  'ijk',
  'ik',
  'ika',
  'ikame',
  'ikamet',
  'ikaz',
  'ikbal',
  'ikdam',
  'iki',
  'ikilem',
  'ikindi',
  'iklim',
  'ikmal',
  'ikna',
  'ikon',
  'ikona',
  'ikrah',
  'ikram',
  'ikrar',
  'ikraz',
  'iks',
  'iksir',
  'iktifa',
  'iktiza',
  'il',
  'ilca',
  'ile',
  'ilerde',
  'ileri',
  'ileti',
  'iletki',
  'ilga',
  'ilgi',
  'ilhak',
  'ilham',
  'ilhan',
  'ilik',
  'ilim',
  'ilinti',
  'ilk',
  'ilke',
  'ilkel',
  'ilkin',
  'ilkyaz',
  'ille',
  'illet',
  'ilmek',
  'ilmik',
  'ilmiye',
  'iltica',
  'ilzam',
  'im',
  'ima',
  'imaj',
  'imal',
  'imale',
  'imam',
  'imame',
  'imamet',
  'iman',
  'imar',
  'imaret',
  'imbat',
  'imbik',
  'imdat',
  'imdi',
  'imece',
  'imge',
  'imha',
  'imik',
  'imren',
  'imsak',
  'imtina',
  'imza',
  'in',
  'inak',
  'inal',
  'inam',
  'inan',
  'inat',
  'inayet',
  'ince',
  'inci',
  'incik',
  'incir',
  'indeks',
  'indis',
  'inek',
  'infak',
  'infaz',
  'infial',
  'ingin',
  'inha',
  'inik',
  'inilti',
  'inme',
  'inmek',
  'insaf',
  'insan',
  'intak',
  'intan',
  'intiba',
  'intifa',
  'intiha',
  'inzal',
  'inziva',
  'io',
  'ip',
  'ipek',
  'iplik',
  'ipnoz',
  'ipotek',
  'ipsiz',
  'iptal',
  'iptida',
  'iq',
  'ir',
  'irade',
  'irfan',
  'iri',
  'irin',
  'iris',
  'irkmek',
  'irmik',
  'ironi',
  'irs',
  'irsal',
  'irsen',
  'irtica',
  'irtifa',
  'is',
  'isabet',
  'isaf',
  'isal',
  'isale',
  'ise',
  'ishal',
  'isilik',
  'isim',
  'iskele',
  'iskete',
  'iskota',
  'islim',
  'ismen',
  'ismet',
  'isnat',
  'ispat',
  'ispir',
  'ispit',
  'israf',
  'istek',
  'isteka',
  'istem',
  'isteri',
  'istiap',
  'istida',
  'istif',
  'istifa',
  'istika',
  'istim',
  'istiva',
  'istop',
  'isyan',
  'it',
  'ita',
  'itaat',
  'italik',
  'itenek',
  'itfa',
  'ithaf',
  'ithal',
  'itham',
  'itibar',
  'itidal',
  'itikat',
  'itimat',
  'itina',
  'itiraf',
  'itiraz',
  'itizar',
  'itki',
  'itmam',
  'itmek',
  'iu',
  'iv',
  'ivaz',
  'ivecen',
  'ivedi',
  'ivgi',
  'ivinti',
  'ivme',
  'ivmek',
  'iw',
  'ix',
  'iy',
  'iye',
  'iyi',
  'iyicil',
  'iyon',
  'iyonik',
  'iyot',
  'iz',
  'izabe',
  'izafe',
  'izafet',
  'izah',
  'izahat',
  'izale',
  'izam',
  'izaz',
  'izbe',
  'izbiro',
  'izci',
  'izhar',
  'izin',
  'izlek',
  'izlem',
  'izobar',
  'izole',
  'izomer',
  'izotop',
  'izzet',
  'j',
  'jaguar',
  'jakuzi',
  'jambon',
  'janjan',
  'janr',
  'jant',
  'japone',
  'jargon',
  'jarse',
  'jb',
  'jc',
  'jd',
  'je',
  'jel',
  'jeolog',
  'jest',
  'jet',
  'jeton',
  'jf',
  'jg',
  'jh',
  'ji',
  'jigolo',
  'jikle',
  'jile',
  'jilet',
  'jips',
  'jj',
  'jjj',
  'jjjj',
  'jk',
  'jkl',
  'jl',
  'jm',
  'jn',
  'jo',
  'joker',
  'jokey',
  'jorjet',
  'jp',
  'jq',
  'jr',
  'js',
  'jt',
  'ju',
  'judo',
  'jul',
  'jurnal',
  'jv',
  'jw',
  'jx',
  'jy',
  'jz',
  'k',
  'kaba',
  'kabak',
  'kabala',
  'kaban',
  'kabara',
  'kabare',
  'kabil',
  'kabile',
  'kabin',
  'kabine',
  'kabir',
  'kabl',
  'kablo',
  'kabuk',
  'kabul',
  'kabza',
  'kadana',
  'kadar',
  'kadeh',
  'kadem',
  'kademe',
  'kader',
  'kadife',
  'kadim',
  'kadir',
  'kadit',
  'kadran',
  'kadrat',
  'kadril',
  'kadro',
  'kafa',
  'kafein',
  'kafes',
  'kafile',
  'kafiye',
  'kaftan',
  'kahir',
  'kahpe',
  'kahve',
  'kaide',
  'kail',
  'kaim',
  'kaime',
  'kak',
  'kaka',
  'kakao',
  'kakma',
  'kakmak',
  'kaknem',
  'kakule',
  'kakum',
  'kal',
  'kala',
  'kalaba',
  'kalak',
  'kalan',
  'kalas',
  'kalay',
  'kalben',
  'kalbur',
  'kale',
  'kalem',
  'kalfa',
  'kaliko',
  'kalite',
  'kalkan',
  'kalker',
  'kalmak',
  'kalori',
  'kalp',
  'kalpak',
  'kalsit',
  'kalya',
  'kalyon',
  'kam',
  'kama',
  'kamara',
  'kamber',
  'kambur',
  'kamer',
  'kamera',
  'kamet',
  'kamga',
  'kamp',
  'kamu',
  'kamus',
  'kamyon',
  'kan',
  'kana',
  'kanaat',
  'kanal',
  'kanara',
  'kanat',
  'kanava',
  'kanca',
  'kandil',
  'kanepe',
  'kangal',
  'kani',
  'kankan',
  'kanmak',
  'kano',
  'kanon',
  'kanser',
  'kant',
  'kantar',
  'kantat',
  'kantin',
  'kanto',
  'kanton',
  'kanun',
  'kanyak',
  'kanyon',
  'kaolin',
  'kaos',
  'kap',
  'kapak',
  'kapama',
  'kapan',
  'kapari',
  'kaparo',
  'kapik',
  'kaplam',
  'kaplan',
  'kapmak',
  'kapris',
  'kapsam',
  'kaptan',
  'kaput',
  'kapuz',
  'kar',
  'kara',
  'karaca',
  'karafa',
  'karar',
  'karasu',
  'karate',
  'karbon',
  'kare',
  'karga',
  'kargo',
  'kari',
  'kariha',
  'karina',
  'karine',
  'karkas',
  'karma',
  'karmak',
  'karman',
  'karmuk',
  'karne',
  'karni',
  'karo',
  'karpit',
  'karpuz',
  'karsak',
  'karst',
  'kart',
  'kartal',
  'kartel',
  'karton',
  'kartuk',
  'karye',
  'kas',
  'kasa',
  'kasaba',
  'kasap',
  'kasara',
  'kasem',
  'kaset',
  'kaside',
  'kasis',
  'kask',
  'kaskat',
  'kasket',
  'kasko',
  'kasmak',
  'kasnak',
  'kast',
  'kastar',
  'kasten',
  'kastor',
  'kasvet',
  'kat',
  'katana',
  'katar',
  'katil',
  'katmak',
  'katman',
  'katmer',
  'katot',
  'katrak',
  'katran',
  'katre',
  'katur',
  'katyon',
  'kav',
  'kavaf',
  'kavak',
  'kaval',
  'kavara',
  'kavas',
  'kavat',
  'kavata',
  'kavga',
  'kavi',
  'kavil',
  'kavim',
  'kavis',
  'kavlak',
  'kavrak',
  'kavram',
  'kavruk',
  'kavuk',
  'kavun',
  'kay',
  'kaya',
  'kayak',
  'kaygan',
  'kaymak',
  'kayme',
  'kaynak',
  'kaynar',
  'kaypak',
  'kayra',
  'kayrak',
  'kayran',
  'kayser',
  'kaytak',
  'kaytan',
  'kayyum',
  'kaz',
  'kaza',
  'kazaen',
  'kazak',
  'kazan',
  'kazara',
  'kazaz',
  'kazbok',
  'kazein',
  'kazevi',
  'kaziye',
  'kazmak',
  'kb',
  'kc',
  'kd',
  'ke',
  'kebap',
  'kebe',
  'kebere',
  'kebir',
  'kebze',
  'keder',
  'kedi',
  'kefal',
  'kefe',
  'kefek',
  'kefeki',
  'kefen',
  'kefere',
  'kefil',
  'kefir',
  'kefiye',
  'kefne',
  'kehle',
  'kek',
  'keke',
  'kekeme',
  'kekik',
  'keklik',
  'kekre',
  'kel',
  'kelek',
  'kelem',
  'kelep',
  'keler',
  'kelik',
  'kelime',
  'kelle',
  'kem',
  'kemal',
  'keman',
  'kemane',
  'keme',
  'kement',
  'kemer',
  'kemha',
  'kemik',
  'kemre',
  'kenar',
  'kendi',
  'kendir',
  'kene',
  'kenef',
  'kenet',
  'kengel',
  'kenger',
  'kent',
  'kental',
  'kentet',
  'kep',
  'kepaze',
  'kepek',
  'kepenk',
  'kepez',
  'kepir',
  'kepmek',
  'kerde',
  'kere',
  'kerem',
  'keres',
  'kerh',
  'kerhen',
  'kerih',
  'kerim',
  'kerime',
  'keriz',
  'kerki',
  'kermen',
  'kermes',
  'kerrat',
  'kerte',
  'kerti',
  'kertik',
  'kervan',
  'kes',
  'kesat',
  'kese',
  'kesek',
  'kesel',
  'kesene',
  'keser',
  'kesif',
  'kesik',
  'kesin',
  'kesir',
  'kesit',
  'keski',
  'keskin',
  'kesme',
  'kesmek',
  'kesmik',
  'kesp',
  'kesre',
  'kesret',
  'ket',
  'ketal',
  'kete',
  'keten',
  'keton',
  'ketum',
  'kevel',
  'keven',
  'kevgir',
  'keyif',
  'kez',
  'keza',
  'kezzap',
  'kf',
  'kg',
  'kh',
  'ki',
  'kibar',
  'kibir',
  'kibrit',
  'kibutz',
  'kifaf',
  'kik',
  'kiklon',
  'kil',
  'kile',
  'kiler',
  'kilim',
  'kilise',
  'kilit',
  'kiliz',
  'kilo',
  'kils',
  'kim',
  'kimi',
  'kimisi',
  'kimlik',
  'kimono',
  'kimse',
  'kimya',
  'kimyon',
  'kin',
  'kinaye',
  'kinci',
  'kindar',
  'kinik',
  'kinin',
  'kinizm',
  'kip',
  'kir',
  'kira',
  'kiraz',
  'kirik',
  'kirloz',
  'kirmen',
  'kirpi',
  'kirpik',
  'kirtil',
  'kirve',
  'kispet',
  'kist',
  'kisve',
  'kit',
  'kitabe',
  'kitap',
  'kitin',
  'kitle',
  'kitre',
  'kivi',
  'kizir',
  'kj',
  'kk',
  'kkk',
  'kkkk',
  'kl',
  'klik',
  'klima',
  'klinik',
  'klip',
  'klips',
  'klm',
  'klon',
  'klor',
  'klozet',
  'km',
  'kn',
  'ko',
  'kobalt',
  'kobay',
  'kobra',
  'koca',
  'kodein',
  'kodeks',
  'kodes',
  'kof',
  'kofa',
  'kofana',
  'kofra',
  'koful',
  'kok',
  'koka',
  'kokain',
  'kokak',
  'kokart',
  'koket',
  'kokmak',
  'kokona',
  'kokot',
  'kokoz',
  'kokpit',
  'koku',
  'kol',
  'kola',
  'kolan',
  'kolay',
  'kolcu',
  'kolej',
  'kolera',
  'kolhoz',
  'koli',
  'kolik',
  'kolit',
  'kolluk',
  'koloit',
  'kolon',
  'koloni',
  'koltuk',
  'kolye',
  'kolyoz',
  'kolza',
  'kom',
  'koma',
  'komar',
  'kombi',
  'komedi',
  'komi',
  'komik',
  'komita',
  'komite',
  'komot',
  'kompas',
  'komple',
  'komplo',
  'komut',
  'komuta',
  'konak',
  'konfor',
  'kongre',
  'koni',
  'konik',
  'konje',
  'konkav',
  'konken',
  'konkre',
  'konkur',
  'konmak',
  'konsa',
  'konser',
  'konsey',
  'konsol',
  'konson',
  'kont',
  'kontak',
  'kontes',
  'kontra',
  'kontur',
  'konu',
  'konuk',
  'konum',
  'konur',
  'konut',
  'konvoy',
  'konyak',
  'kopal',
  'kopek',
  'kopil',
  'kopmak',
  'kopoy',
  'kopuk',
  'kopuz',
  'kopya',
  'kor',
  'kora',
  'koral',
  'kordon',
  'korekt',
  'korida',
  'korkak',
  'korku',
  'korna',
  'kornea',
  'korner',
  'kornet',
  'korno',
  'koro',
  'korsan',
  'korse',
  'kort',
  'korte',
  'kortej',
  'koru',
  'korucu',
  'koruk',
  'korvet',
  'korza',
  'kosa',
  'kostak',
  'kostik',
  'kot',
  'kota',
  'kotan',
  'kotlet',
  'koton',
  'kotra',
  'kov',
  'kova',
  'kovan',
  'kovboy',
  'kovcu',
  'kovmak',
  'kovuk',
  'koy',
  'koyak',
  'koygun',
  'koymak',
  'koyu',
  'koyun',
  'koyut',
  'koz',
  'koza',
  'kozak',
  'kozmik',
  'kozmos',
  'kp',
  'kq',
  'kr',
  'kral',
  'kramp',
  'krank',
  'krater',
  'kravat',
  'kravl',
  'kredi',
  'krem',
  'krema',
  'krep',
  'krepon',
  'kreten',
  'kreton',
  'krezol',
  'kriket',
  'kriko',
  'kripto',
  'kriter',
  'kritik',
  'kriz',
  'kroki',
  'krom',
  'kromaj',
  'kron',
  'kronik',
  'kros',
  'ks',
  'ksenon',
  'kt',
  'ku',
  'kubat',
  'kubbe',
  'kubur',
  'kucak',
  'kudas',
  'kudret',
  'kuduz',
  'kuka',
  'kukla',
  'kul',
  'kula',
  'kulak',
  'kule',
  'kulis',
  'kullap',
  'kulp',
  'kulun',
  'kulvar',
  'kum',
  'kuma',
  'kumar',
  'kumcul',
  'kumpas',
  'kumpir',
  'kumral',
  'kumru',
  'kumsal',
  'kumul',
  'kunda',
  'kundak',
  'kunduz',
  'kungfu',
  'kunt',
  'kup',
  'kupa',
  'kupes',
  'kupon',
  'kur',
  'kurak',
  'kural',
  'kuram',
  'kurban',
  'kurca',
  'kurgan',
  'kurgu',
  'kurk',
  'kurmak',
  'kurmay',
  'kurna',
  'kurnaz',
  'kuron',
  'kurs',
  'kursak',
  'kurt',
  'kuru',
  'kurul',
  'kurum',
  'kurye',
  'kuskun',
  'kuskus',
  'kusmak',
  'kusmuk',
  'kusur',
  'kut',
  'kutan',
  'kutlu',
  'kutnu',
  'kutsal',
  'kutu',
  'kutup',
  'kutur',
  'kuvars',
  'kuvve',
  'kuvvet',
  'kuymak',
  'kuyruk',
  'kuytu',
  'kuyu',
  'kuyum',
  'kuyut',
  'kuz',
  'kuzen',
  'kuzey',
  'kuzgun',
  'kuzine',
  'kuzu',
  'kv',
  'kw',
  'kx',
  'ky',
  'kz',
  'l',
  'lala',
  'largo',
  'lb',
  'lc',
  'ld',
  'le',
  'lef',
  'legal',
  'leh',
  'lehim',
  'lehtar',
  'lejant',
  'lejyon',
  'leke',
  'lemis',
  'lenf',
  'lenfa',
  'lenger',
  'lens',
  'lento',
  'leopar',
  'lep',
  'lepra',
  'leva',
  'levent',
  'levha',
  'levrek',
  'levye',
  'ley',
  'leylek',
  'lezar',
  'leziz',
  'lezyon',
  'lezzet',
  'lf',
  'lg',
  'lh',
  'li',
  'libas',
  'libero',
  'libido',
  'libre',
  'lider',
  'lif',
  'lift',
  'lig',
  'lika',
  'liken',
  'likide',
  'likit',
  'liman',
  'limbo',
  'lime',
  'limit',
  'limon',
  'lineer',
  'linet',
  'linin',
  'link',
  'linyit',
  'lir',
  'lira',
  'liret',
  'lirik',
  'lirizm',
  'lisan',
  'lisans',
  'lise',
  'liste',
  'litre',
  'lityum',
  'liva',
  'livar',
  'lj',
  'lk',
  'll',
  'lll',
  'llll',
  'lm',
  'lmn',
  'ln',
  'lo',
  'lobi',
  'lobut',
  'loca',
  'loda',
  'lodos',
  'logo',
  'logos',
  'lojman',
  'lokal',
  'lokavt',
  'lokma',
  'lokum',
  'lolo',
  'lombar',
  'lomboz',
  'lonca',
  'longa',
  'longoz',
  'lop',
  'lopur',
  'lor',
  'lort',
  'lorta',
  'lostra',
  'losyon',
  'lota',
  'lotus',
  'lp',
  'lq',
  'lr',
  'ls',
  'lt',
  'lu',
  'lungur',
  'lv',
  'lw',
  'lx',
  'ly',
  'lz',
  'm',
  'maada',
  'maaile',
  'maarif',
  'mabat',
  'mabet',
  'mablak',
  'mabude',
  'mabut',
  'macera',
  'macun',
  'madam',
  'madara',
  'madde',
  'madem',
  'maden',
  'madik',
  'madrup',
  'madun',
  'mafevk',
  'mafsal',
  'mafya',
  'magma',
  'magri',
  'mahal',
  'mahana',
  'mahbes',
  'mahbup',
  'mahcup',
  'mahcur',
  'mahcuz',
  'mahdum',
  'mahdut',
  'mahfe',
  'mahfil',
  'mahfuz',
  'mahir',
  'mahiye',
  'mahlep',
  'mahmul',
  'mahmur',
  'mahmuz',
  'mahna',
  'mahpus',
  'mahra',
  'mahrek',
  'mahrem',
  'mahrum',
  'mahrut',
  'mahsul',
  'mahsup',
  'mahsur',
  'mahsus',
  'mahur',
  'mahut',
  'mahya',
  'mahzar',
  'mahzen',
  'mahzun',
  'mahzur',
  'mail',
  'maile',
  'main',
  'maiyet',
  'maka',
  'makak',
  'makale',
  'makam',
  'makara',
  'makas',
  'makat',
  'makber',
  'makbul',
  'makbuz',
  'maket',
  'maki',
  'makine',
  'makro',
  'maksat',
  'maksut',
  'makta',
  'maktu',
  'maktul',
  'makul',
  'makule',
  'makyaj',
  'mal',
  'mala',
  'malafa',
  'malaga',
  'malak',
  'malaz',
  'malca',
  'malen',
  'malik',
  'maliye',
  'malt',
  'maltoz',
  'malul',
  'malum',
  'malya',
  'mama',
  'mambo',
  'mamul',
  'mamur',
  'mamure',
  'mamut',
  'mana',
  'manav',
  'manca',
  'manda',
  'mandal',
  'mandar',
  'manej',
  'manen',
  'manga',
  'mangal',
  'mangan',
  'mangiz',
  'mango',
  'mani',
  'manita',
  'manken',
  'mansap',
  'mantar',
  'mantin',
  'mantis',
  'manto',
  'manya',
  'manyak',
  'manyat',
  'manyok',
  'manzum',
  'mapa',
  'mapus',
  'maraba',
  'maral',
  'maraz',
  'maraza',
  'marda',
  'marina',
  'mariz',
  'marj',
  'mark',
  'marka',
  'markaj',
  'marke',
  'market',
  'marki',
  'markiz',
  'marley',
  'marn',
  'maron',
  'mars',
  'mart',
  'martin',
  'maruf',
  'marul',
  'maruz',
  'marya',
  'mas',
  'masa',
  'masaj',
  'masal',
  'masara',
  'masat',
  'masif',
  'masiko',
  'mask',
  'maske',
  'maskot',
  'maslak',
  'masnu',
  'mason',
  'masraf',
  'masruf',
  'mastar',
  'master',
  'mastor',
  'mastur',
  'masum',
  'masun',
  'masura',
  'mat',
  'matah',
  'matara',
  'matbaa',
  'matbu',
  'matbua',
  'matem',
  'matine',
  'matiz',
  'matkap',
  'matla',
  'matlup',
  'matrah',
  'matrak',
  'matris',
  'matrut',
  'matuh',
  'maun',
  'maval',
  'mavera',
  'mavi',
  'mavna',
  'mavzer',
  'maya',
  'mayi',
  'maymun',
  'mayna',
  'mayo',
  'maytap',
  'mazak',
  'mazbut',
  'mazgal',
  'mazhar',
  'mazi',
  'mazlum',
  'mazmun',
  'maznun',
  'mazot',
  'mazruf',
  'mazur',
  'mb',
  'mc',
  'md',
  'me',
  'meal',
  'mealen',
  'mebde',
  'mebiz',
  'mebni',
  'mebus',
  'mebzul',
  'mecal',
  'mecaz',
  'mecbur',
  'meclis',
  'mecmu',
  'mecmua',
  'mecnun',
  'mecra',
  'mecruh',
  'meczup',
  'medar',
  'meddah',
  'medet',
  'medya',
  'medyum',
  'medyun',
  'mefhum',
  'mefsuh',
  'meftun',
  'mega',
  'mehaz',
  'mehel',
  'mehil',
  'mehle',
  'mehtap',
  'mehter',
  'mekik',
  'meknuz',
  'mekruh',
  'mektep',
  'mektup',
  'mel',
  'melek',
  'meleke',
  'meles',
  'melez',
  'melfuf',
  'melhuz',
  'melik',
  'melike',
  'melisa',
  'melodi',
  'melon',
  'meltem',
  'memat',
  'memba',
  'meme',
  'memnu',
  'memnun',
  'memul',
  'memur',
  'memure',
  'men',
  'menafi',
  'mendil',
  'menfa',
  'menfez',
  'menfi',
  'menfur',
  'menhus',
  'meni',
  'menisk',
  'menkul',
  'mensup',
  'mensur',
  'mentol',
  'menus',
  'menzil',
  'mera',
  'merak',
  'meral',
  'meram',
  'mercan',
  'mercek',
  'merci',
  'merek',
  'meres',
  'meret',
  'mergup',
  'merhem',
  'merhum',
  'merkat',
  'merkep',
  'merkez',
  'merkum',
  'mermer',
  'mermi',
  'mersi',
  'mersin',
  'mert',
  'mertek',
  'mesabe',
  'mesafe',
  'mesaha',
  'mesai',
  'mesaj',
  'mesame',
  'mesane',
  'mescit',
  'mesel',
  'mesele',
  'mesen',
  'meses',
  'mesh',
  'mesire',
  'mesken',
  'meslek',
  'mesmu',
  'mesnet',
  'mesrur',
  'mest',
  'mestur',
  'met',
  'meta',
  'metal',
  'metan',
  'metbu',
  'meteor',
  'metfen',
  'metfun',
  'methal',
  'metil',
  'metin',
  'metis',
  'metot',
  'metraj',
  'metre',
  'metres',
  'metrik',
  'metris',
  'metro',
  'mevcut',
  'mevdu',
  'mevhum',
  'mevize',
  'mevki',
  'mevkuf',
  'mevkut',
  'mevlit',
  'mevrut',
  'mevsim',
  'mevsuf',
  'mevsuk',
  'mevt',
  'mevta',
  'mevut',
  'mevzi',
  'mevzu',
  'mevzun',
  'mey',
  'meyal',
  'meyan',
  'meyane',
  'meydan',
  'meyil',
  'meyus',
  'meyve',
  'meyyal',
  'meyyit',
  'mezar',
  'mezat',
  'meze',
  'mezgit',
  'mezhep',
  'mezon',
  'mezraa',
  'mezru',
  'mezun',
  'mezura',
  'mf',
  'mg',
  'mh',
  'mi',
  'miat',
  'mibzer',
  'mide',
  'midi',
  'midye',
  'miftah',
  'migren',
  'mihenk',
  'mihman',
  'mihnet',
  'mihrak',
  'mihrap',
  'mihver',
  'mika',
  'mikado',
  'mikoz',
  'mikro',
  'mikron',
  'mikrop',
  'mikser',
  'miktar',
  'mikyas',
  'mil',
  'milel',
  'milim',
  'milis',
  'millet',
  'milyar',
  'milyon',
  'mim',
  'mimar',
  'mimik',
  'mimoza',
  'minare',
  'minber',
  'minder',
  'mine',
  'mini',
  'minik',
  'mink',
  'minnet',
  'mintan',
  'minval',
  'minyon',
  'mir',
  'mira',
  'miras',
  'mirza',
  'mis',
  'misak',
  'misal',
  'misel',
  'misil',
  'misina',
  'misis',
  'misk',
  'miskal',
  'misket',
  'miskin',
  'mister',
  'mistik',
  'misvak',
  'misyon',
  'mit',
  'mitil',
  'miting',
  'mitos',
  'mitoz',
  'mitral',
  'miyar',
  'miyav',
  'miyaz',
  'miyom',
  'miyop',
  'miza',
  'mizah',
  'mizan',
  'mj',
  'mk',
  'ml',
  'mm',
  'mmm',
  'mmmm',
  'mn',
  'mno',
  'mo',
  'mobil',
  'moda',
  'model',
  'modern',
  'moka',
  'mola',
  'molas',
  'molla',
  'moloz',
  'moment',
  'monat',
  'monden',
  'monist',
  'monizm',
  'monokl',
  'mont',
  'montaj',
  'monte',
  'mor',
  'moral',
  'moren',
  'morfem',
  'morfin',
  'morg',
  'morina',
  'mors',
  'morto',
  'moruk',
  'mosmor',
  'mostra',
  'motel',
  'motif',
  'motor',
  'motris',
  'mozaik',
  'mozak',
  'mozole',
  'mp',
  'mq',
  'mr',
  'ms',
  'mt',
  'mu',
  'muadil',
  'muaf',
  'muamma',
  'muare',
  'muavin',
  'mubah',
  'mucip',
  'mucir',
  'mucit',
  'mucize',
  'mucuk',
  'mucur',
  'mudi',
  'mudil',
  'mufla',
  'muflon',
  'muhal',
  'muhat',
  'muhbir',
  'muhik',
  'muhil',
  'muhip',
  'muhit',
  'muhkem',
  'muhlis',
  'muhrik',
  'muhrip',
  'muhtar',
  'muhtel',
  'muin',
  'muit',
  'mujik',
  'mukaar',
  'mukim',
  'mukni',
  'mukoza',
  'mukriz',
  'mukus',
  'mum',
  'mumya',
  'mundar',
  'munis',
  'munsap',
  'munzam',
  'murana',
  'murat',
  'murdar',
  'muris',
  'murt',
  'musap',
  'musiki',
  'muska',
  'muslin',
  'musluk',
  'muson',
  'muta',
  'mutaf',
  'mutat',
  'mutena',
  'mutfak',
  'muti',
  'mutlak',
  'mutlu',
  'mutsuz',
  'muvazi',
  'muylu',
  'muymul',
  'muz',
  'muzip',
  'muzlim',
  'muztar',
  'mv',
  'mw',
  'mx',
  'my',
  'mz',
  'n',
  'naat',
  'nacak',
  'nadan',
  'nadas',
  'nadide',
  'nadim',
  'nadir',
  'nafaka',
  'nafi',
  'nafile',
  'nafiz',
  'nafta',
  'nah',
  'nahak',
  'nahif',
  'nahiv',
  'nahiye',
  'naif',
  'nail',
  'naip',
  'nakavt',
  'nakden',
  'nakil',
  'nakip',
  'nakisa',
  'nakit',
  'naklen',
  'nakzen',
  'nal',
  'nalbur',
  'nam',
  'namaz',
  'namdar',
  'name',
  'namert',
  'namlu',
  'namus',
  'namzet',
  'nanay',
  'nane',
  'nanik',
  'napalm',
  'nar',
  'nara',
  'nardin',
  'narh',
  'narin',
  'narkoz',
  'nas',
  'nasfet',
  'nasip',
  'nasir',
  'navlun',
  'naylon',
  'naz',
  'nazar',
  'nazik',
  'nazil',
  'nazir',
  'nazire',
  'nb',
  'nc',
  'nd',
  'ne',
  'nebat',
  'nebi',
  'nebze',
  'necat',
  'neci',
  'necip',
  'nedbe',
  'neden',
  'nedim',
  'nedime',
  'nedret',
  'nefer',
  'nefes',
  'nefha',
  'nefir',
  'nefis',
  'nefiy',
  'nefret',
  'nefrit',
  'neft',
  'nehir',
  'nehiy',
  'nekbet',
  'nekes',
  'nekre',
  'nekroz',
  'nektar',
  'nem',
  'nema',
  'nemcil',
  'nemf',
  'nemrut',
  'nene',
  'neodim',
  'neojen',
  'neon',
  'nere',
  'nergis',
  'nesep',
  'nesil',
  'nesir',
  'nesne',
  'nesnel',
  'net',
  'netice',
  'neva',
  'nevale',
  'nevi',
  'nevroz',
  'nevruz',
  'nevton',
  'nevzat',
  'ney',
  'neyzen',
  'nezif',
  'nezih',
  'nezir',
  'nezle',
  'nf',
  'ng',
  'nh',
  'ni',
  'nice',
  'nicel',
  'nida',
  'nifak',
  'nihale',
  'nikap',
  'nikbet',
  'nikbin',
  'nikel',
  'nimbus',
  'nimet',
  'nine',
  'ninni',
  'nipel',
  'nisan',
  'nisap',
  'nispet',
  'nisyan',
  'nitel',
  'nitrat',
  'niyaz',
  'niye',
  'niyet',
  'nizam',
  'nj',
  'nk',
  'nl',
  'nm',
  'nn',
  'nnn',
  'nnnn',
  'no',
  'nobran',
  'nodul',
  'nohut',
  'nokra',
  'noksan',
  'nokta',
  'nop',
  'norm',
  'normal',
  'nosyon',
  'not',
  'nota',
  'noter',
  'nova',
  'np',
  'nq',
  'nr',
  'ns',
  'nt',
  'nu',
  'nukut',
  'numara',
  'numune',
  'nur',
  'nutuk',
  'nv',
  'nw',
  'nx',
  'ny',
  'nz',
  'o',
  'ob',
  'oba',
  'oberj',
  'obje',
  'obruk',
  'obua',
  'obur',
  'oc',
  'ocak',
  'od',
  'oda',
  'odak',
  'odeon',
  'odun',
  'oe',
  'of',
  'ofis',
  'oflaz',
  'ofris',
  'ofsayt',
  'ofset',
  'og',
  'oh',
  'oha',
  'ohm',
  'oi',
  'oj',
  'oje',
  'ojit',
  'ok',
  'okapi',
  'okey',
  'okka',
  'oklava',
  'oksit',
  'oktan',
  'oktant',
  'oktav',
  'oktrua',
  'okul',
  'okumak',
  'okume',
  'okuntu',
  'okur',
  'okus',
  'ol',
  'olanak',
  'olanca',
  'olay',
  'ole',
  'olefin',
  'oleik',
  'olein',
  'olgu',
  'olgun',
  'olivin',
  'olmak',
  'olta',
  'oluk',
  'olumlu',
  'om',
  'ombra',
  'omlet',
  'omur',
  'omurga',
  'omuz',
  'on',
  'onamak',
  'onat',
  'onay',
  'onca',
  'onda',
  'onejit',
  'ongun',
  'oniks',
  'onlar',
  'onmak',
  'onmaz',
  'ons',
  'onu',
  'onum',
  'onun',
  'onur',
  'oo',
  'ooo',
  'oooo',
  'oosfer',
  'oosit',
  'op',
  'opal',
  'opalin',
  'opera',
  'operet',
  'opq',
  'optik',
  'opus',
  'oq',
  'or',
  'ora',
  'orak',
  'oral',
  'oran',
  'ordino',
  'ordu',
  'orfoz',
  'org',
  'organ',
  'orgazm',
  'orijin',
  'orkide',
  'orkit',
  'orlon',
  'orman',
  'orsa',
  'orta',
  'ortak',
  'ortam',
  'ortay',
  'os',
  'osuruk',
  'ot',
  'otamak',
  'otel',
  'otizm',
  'otlak',
  'otluk',
  'oto',
  'otoban',
  'otobur',
  'otogar',
  'otokar',
  'otolit',
  'otoman',
  'otomat',
  'otonom',
  'otopsi',
  'otoray',
  'otoyol',
  'otsu',
  'otsul',
  'oturak',
  'oturum',
  'otuz',
  'ou',
  'ov',
  'ova',
  'oval',
  'ovmak',
  'ovogon',
  'ovolit',
  'ow',
  'ox',
  'oy',
  'oya',
  'oyluk',
  'oylum',
  'oymak',
  'oynak',
  'oysa',
  'oysaki',
  'oyuk',
  'oyulga',
  'oyum',
  'oyun',
  'oyuntu',
  'oz',
  'ozalit',
  'ozan',
  'ozmos',
  'ozon',
  'ozuga',
  'p',
  'padok',
  'pafta',
  'pagan',
  'pagoda',
  'pah',
  'paha',
  'pahal',
  'pak',
  'paket',
  'pakt',
  'pal',
  'pala',
  'palan',
  'palas',
  'palaz',
  'palet',
  'palto',
  'pampa',
  'pamuk',
  'panama',
  'pancar',
  'panda',
  'panel',
  'panik',
  'panjur',
  'pano',
  'panter',
  'panzer',
  'papa',
  'papak',
  'papara',
  'papaz',
  'papel',
  'papura',
  'papyon',
  'para',
  'paraca',
  'paradi',
  'paraf',
  'parafe',
  'paraka',
  'pardon',
  'pare',
  'parite',
  'park',
  'parka',
  'parke',
  'parkur',
  'parlak',
  'parmak',
  'parodi',
  'parola',
  'parpa',
  'pars',
  'parsa',
  'parsel',
  'partal',
  'parter',
  'parti',
  'parya',
  'pas',
  'pasaj',
  'pasak',
  'pasif',
  'paskal',
  'paso',
  'paspal',
  'paspas',
  'pasta',
  'pastal',
  'pastav',
  'pastel',
  'pastil',
  'pastra',
  'pat',
  'pata',
  'patak',
  'paten',
  'patent',
  'pati',
  'patik',
  'patika',
  'patis',
  'patlak',
  'patpat',
  'patrik',
  'patron',
  'pavyon',
  'pay',
  'payam',
  'payan',
  'payda',
  'paydos',
  'paye',
  'payen',
  'payet',
  'paytak',
  'payton',
  'pazar',
  'pazen',
  'pazval',
  'pb',
  'pc',
  'pd',
  'pe',
  'pedal',
  'peder',
  'pehpeh',
  'pek',
  'pekent',
  'peki',
  'pekiyi',
  'pekmez',
  'peleng',
  'pelin',
  'pelit',
  'pelte',
  'peltek',
  'pembe',
  'pena',
  'pencik',
  'penes',
  'peni',
  'penis',
  'pens',
  'pense',
  'pentan',
  'penuar',
  'penye',
  'pepe',
  'pepeme',
  'pepsin',
  'pepton',
  'perdah',
  'perde',
  'pereme',
  'perese',
  'pergel',
  'perhiz',
  'peri',
  'perido',
  'perki',
  'perlit',
  'perlon',
  'perma',
  'permi',
  'peron',
  'peruk',
  'perva',
  'pervaz',
  'pes',
  'pesek',
  'peseta',
  'pestil',
  'petek',
  'petrol',
  'peynir',
  'peyzaj',
  'pf',
  'pg',
  'ph',
  'pi',
  'pide',
  'pijama',
  'pik',
  'pikaj',
  'pikap',
  'pike',
  'piket',
  'piknik',
  'piko',
  'pil',
  'pilot',
  'pim',
  'pinel',
  'pines',
  'pinhan',
  'pinpon',
  'pinti',
  'pipet',
  'pipi',
  'pipo',
  'pir',
  'pire',
  'pirina',
  'pirit',
  'pirohi',
  'piruhi',
  'pis',
  'pisi',
  'pisik',
  'pislik',
  'pist',
  'piston',
  'piti',
  'piton',
  'piyade',
  'piyale',
  'piyan',
  'piyano',
  'piyasa',
  'piyata',
  'piyaz',
  'piyes',
  'piyon',
  'piyore',
  'pizza',
  'pj',
  'pk',
  'pl',
  'plevra',
  'pli',
  'plise',
  'pm',
  'pn',
  'po',
  'podyum',
  'pof',
  'pofur',
  'pohpoh',
  'poker',
  'pokus',
  'polen',
  'polip',
  'polis',
  'polka',
  'polo',
  'pomat',
  'pompa',
  'pompaj',
  'ponje',
  'ponpon',
  'ponton',
  'ponza',
  'pop',
  'poplin',
  'popo',
  'porfir',
  'porsuk',
  'porte',
  'porto',
  'portre',
  'pos',
  'posa',
  'post',
  'posta',
  'postal',
  'poster',
  'pot',
  'pota',
  'potas',
  'potin',
  'potkal',
  'potuk',
  'potur',
  'pound',
  'poy',
  'poyra',
  'poyraz',
  'poz',
  'pp',
  'ppp',
  'pppp',
  'pq',
  'pqr',
  'pr',
  'prafa',
  'pranga',
  'pratik',
  'prens',
  'pres',
  'prese',
  'presto',
  'prim',
  'primat',
  'priz',
  'prizma',
  'profil',
  'proje',
  'prolog',
  'proses',
  'protez',
  'proton',
  'prova',
  'pruva',
  'ps',
  'psikoz',
  'pt',
  'pu',
  'puan',
  'puding',
  'pudra',
  'puf',
  'puhu',
  'pul',
  'pulat',
  'pulluk',
  'pulman',
  'puma',
  'punt',
  'punto',
  'pupa',
  'puro',
  'pus',
  'pusat',
  'puset',
  'pusmak',
  'pusu',
  'pusula',
  'pusval',
  'put',
  'putrel',
  'pv',
  'pw',
  'px',
  'py',
  'pz',
  'q',
  'qb',
  'qc',
  'qd',
  'qe',
  'qf',
  'qg',
  'qh',
  'qi',
  'qj',
  'qk',
  'ql',
  'qm',
  'qn',
  'qo',
  'qp',
  'qq',
  'qqq',
  'qqqq',
  'qr',
  'qrs',
  'qs',
  'qt',
  'qu',
  'qv',
  'qw',
  'qx',
  'qy',
  'qz',
  'r',
  'raca',
  'raci',
  'racon',
  'radar',
  'radde',
  'radika',
  'radon',
  'radyan',
  'radyo',
  'radyum',
  'raf',
  'rafine',
  'rafit',
  'rafya',
  'rahat',
  'rahibe',
  'rahim',
  'rahip',
  'rahle',
  'rahman',
  'rahmet',
  'rahne',
  'raht',
  'rahvan',
  'rakam',
  'raket',
  'rakibe',
  'rakik',
  'rakip',
  'rakit',
  'rakkas',
  'rakor',
  'raks',
  'ralli',
  'ramak',
  'rami',
  'ramp',
  'rampa',
  'randa',
  'rant',
  'ranza',
  'rap',
  'rapor',
  'rasat',
  'raspa',
  'rast',
  'raunt',
  'ravent',
  'ray',
  'rayba',
  'rayiha',
  'razmol',
  'rb',
  'rc',
  'rd',
  're',
  'reaya',
  'recep',
  'recim',
  'redif',
  'redoks',
  'reel',
  'refah',
  'refik',
  'refika',
  'reform',
  'regaip',
  'reha',
  'rehber',
  'rehin',
  'rehine',
  'reis',
  'reji',
  'rejim',
  'rekor',
  'rektum',
  'remel',
  'remi',
  'remil',
  'remiz',
  'rende',
  'renk',
  'renyum',
  'reosta',
  'replik',
  'repo',
  'resen',
  'resif',
  'resim',
  'resmen',
  'ressam',
  'rest',
  'resul',
  'ret',
  'retina',
  'reva',
  'revak',
  'revan',
  'revani',
  'revir',
  'revize',
  'revnak',
  'rey',
  'reyhan',
  'reyon',
  'reze',
  'rezene',
  'rezerv',
  'rezil',
  'rf',
  'rg',
  'rh',
  'ri',
  'riayet',
  'rica',
  'rical',
  'rikkat',
  'rimel',
  'rina',
  'ring',
  'ringa',
  'rint',
  'risale',
  'risk',
  'ritm',
  'ritmik',
  'riya',
  'riyal',
  'riziko',
  'rj',
  'rk',
  'rl',
  'rm',
  'rn',
  'ro',
  'roba',
  'robot',
  'roda',
  'rodaj',
  'rodeo',
  'rodyum',
  'roka',
  'roket',
  'rokfor',
  'rokoko',
  'rol',
  'rom',
  'roman',
  'romans',
  'rop',
  'rosto',
  'rot',
  'rota',
  'rotil',
  'rotor',
  'roza',
  'rozbif',
  'rozet',
  'rp',
  'rq',
  'rr',
  'rrr',
  'rrrr',
  'rs',
  'rst',
  'rt',
  'ru',
  'ruam',
  'ruba',
  'ruble',
  'rubu',
  'ruf',
  'rugan',
  'rugbi',
  'ruh',
  'ruhban',
  'ruhen',
  'ruhsat',
  'ruj',
  'rulet',
  'rulman',
  'rulo',
  'rumba',
  'rumuz',
  'rupi',
  'ruz',
  'rv',
  'rw',
  'rx',
  'ry',
  'rz',
  's',
  'saadet',
  'saat',
  'saba',
  'sabah',
  'saban',
  'sabi',
  'sabit',
  'sabo',
  'sabote',
  'sabun',
  'sabura',
  'sac',
  'sadak',
  'sadaka',
  'sade',
  'sadece',
  'sadet',
  'sadik',
  'sadist',
  'sadizm',
  'saf',
  'safari',
  'safdil',
  'safer',
  'saffet',
  'safha',
  'safi',
  'safiha',
  'safir',
  'safra',
  'safran',
  'sagar',
  'sagu',
  'sah',
  'saha',
  'sahabe',
  'sahaf',
  'sahan',
  'sahi',
  'sahibe',
  'sahici',
  'sahife',
  'sahih',
  'sahil',
  'sahip',
  'sahne',
  'sahra',
  'sahre',
  'sahte',
  'sahur',
  'saik',
  'saika',
  'sair',
  'sak',
  'saka',
  'sakaf',
  'sakak',
  'sakal',
  'sakar',
  'sakat',
  'saki',
  'sakil',
  'sakim',
  'sakin',
  'sakit',
  'sako',
  'sal',
  'salak',
  'salam',
  'salata',
  'salep',
  'salim',
  'salip',
  'salise',
  'salmak',
  'salon',
  'saloz',
  'salpa',
  'salpak',
  'salt',
  'salta',
  'salto',
  'salvo',
  'salya',
  'sam',
  'saman',
  'samba',
  'samsa',
  'samsun',
  'samur',
  'samut',
  'san',
  'sanal',
  'sanat',
  'sanayi',
  'sancak',
  'sandal',
  'sanem',
  'saniye',
  'sanki',
  'sanmak',
  'sansar',
  'santim',
  'santra',
  'santur',
  'sap',
  'sapa',
  'sapak',
  'sapan',
  'sapmak',
  'sara',
  'sarak',
  'sarat',
  'saray',
  'sardun',
  'sarf',
  'sari',
  'sarig',
  'sarih',
  'sarma',
  'sarmak',
  'sarmal',
  'sarman',
  'sarp',
  'sarpa',
  'sarraf',
  'sarsak',
  'saten',
  'satir',
  'satmak',
  'satrap',
  'satvet',
  'sauna',
  'sav',
  'sava',
  'savak',
  'savan',
  'savana',
  'savat',
  'savlet',
  'savmak',
  'savruk',
  'savsak',
  'say',
  'saya',
  'saydam',
  'saye',
  'sayfa',
  'sayha',
  'saymak',
  'sayman',
  'sayvan',
  'saz',
  'sazak',
  'sazan',
  'sb',
  'sc',
  'sd',
  'se',
  'seans',
  'sebat',
  'sebep',
  'sebil',
  'sebze',
  'secde',
  'seci',
  'seciye',
  'seda',
  'sedef',
  'sedir',
  'sedye',
  'sefa',
  'sefer',
  'sefih',
  'sefil',
  'sefine',
  'sefir',
  'sefire',
  'segman',
  'seher',
  'sehim',
  'sehiv',
  'sehpa',
  'sehven',
  'sek',
  'sekant',
  'sekban',
  'seki',
  'sekiz',
  'seklem',
  'sekmek',
  'sekmen',
  'sekoya',
  'seksek',
  'seksen',
  'sekte',
  'sekter',
  'sel',
  'sele',
  'selef',
  'selek',
  'selen',
  'selika',
  'selim',
  'selis',
  'selp',
  'selva',
  'sem',
  'sema',
  'semah',
  'seman',
  'semavi',
  'sembol',
  'semen',
  'semer',
  'semere',
  'semi',
  'semih',
  'semiz',
  'semt',
  'sen',
  'sena',
  'senato',
  'sendik',
  'sene',
  'senek',
  'senet',
  'sensen',
  'sentez',
  'sepek',
  'sepet',
  'sepken',
  'ser',
  'sera',
  'serak',
  'serap',
  'serapa',
  'serbaz',
  'serdar',
  'sere',
  'seren',
  'serf',
  'sergen',
  'sergi',
  'sergin',
  'serhat',
  'seri',
  'serian',
  'serin',
  'sermek',
  'sersem',
  'sert',
  'serum',
  'servet',
  'servi',
  'servis',
  'ses',
  'sesli',
  'sessiz',
  'set',
  'seter',
  'setir',
  'setre',
  'sevap',
  'sevda',
  'sevgi',
  'sevi',
  'sevici',
  'sevim',
  'seviye',
  'sevk',
  'sevmek',
  'seyek',
  'seyir',
  'seyis',
  'seyit',
  'seyran',
  'seyrek',
  'seyyah',
  'seyyal',
  'seyyar',
  'seyyie',
  'seza',
  'sezgi',
  'sezi',
  'sezmek',
  'sezon',
  'sezyum',
  'sf',
  'sfenks',
  'sg',
  'sh',
  'si',
  'sibak',
  'sicil',
  'sicim',
  'sidik',
  'sif',
  'sifon',
  'siftah',
  'sigar',
  'sigara',
  'sihir',
  'sikke',
  'siklon',
  'silgi',
  'silis',
  'sille',
  'silme',
  'silmek',
  'silo',
  'siluet',
  'sim',
  'sima',
  'simge',
  'simit',
  'simsar',
  'simya',
  'sin',
  'sinara',
  'sincap',
  'sine',
  'sinek',
  'sinema',
  'singin',
  'sini',
  'sinir',
  'sinmek',
  'sinsi',
  'sinsin',
  'sinyal',
  'sinyor',
  'sipahi',
  'siper',
  'sipsi',
  'siren',
  'sirk',
  'sirkat',
  'sirke',
  'sirmo',
  'siroko',
  'siroz',
  'sirrus',
  'sirto',
  'sis',
  'sismik',
  'sistem',
  'sistit',
  'sistol',
  'site',
  'sitem',
  'sitil',
  'sitrik',
  'sivil',
  'sivri',
  'siya',
  'siyah',
  'siyak',
  'siyasa',
  'siyek',
  'siyer',
  'siymek',
  'siz',
  'sj',
  'sk',
  'skavut',
  'ski',
  'skif',
  'skink',
  'skor',
  'sl',
  'slip',
  'slogan',
  'sm',
  'smokin',
  'sn',
  'snop',
  'so',
  'soba',
  'sobe',
  'soda',
  'sodyum',
  'sof',
  'sofa',
  'sofist',
  'sofizm',
  'sofra',
  'softa',
  'sofu',
  'sohbet',
  'sokak',
  'soket',
  'sokmak',
  'sokman',
  'sokra',
  'sokulu',
  'sokur',
  'sol',
  'solak',
  'solcu',
  'solfej',
  'solgun',
  'solist',
  'solmak',
  'solo',
  'soluk',
  'som',
  'soma',
  'somak',
  'somaki',
  'somon',
  'somun',
  'somut',
  'somya',
  'son',
  'sonar',
  'sonat',
  'soncul',
  'sonda',
  'sondaj',
  'sone',
  'sonlu',
  'sonra',
  'sonsuz',
  'sop',
  'sopa',
  'sorgu',
  'sorkun',
  'sormak',
  'sormuk',
  'sorti',
  'soru',
  'sorun',
  'sos',
  'sosis',
  'sosyal',
  'sote',
  'sovhoz',
  'soy',
  'soya',
  'soyad',
  'soygun',
  'soyka',
  'soylu',
  'soymak',
  'soymuk',
  'soysal',
  'soysuz',
  'soyut',
  'sp',
  'spazm',
  'sperm',
  'sperma',
  'spiker',
  'spiral',
  'spiril',
  'spor',
  'sporcu',
  'spot',
  'sprey',
  'sq',
  'sr',
  'ss',
  'sss',
  'ssss',
  'st',
  'stadya',
  'staj',
  'stant',
  'star',
  'start',
  'stat',
  'statik',
  'stator',
  'sten',
  'steno',
  'step',
  'stepne',
  'ster',
  'stereo',
  'steril',
  'sterol',
  'stil',
  'stok',
  'stop',
  'stopaj',
  'stres',
  'stu',
  'su',
  'sual',
  'suare',
  'subay',
  'subuk',
  'sucuk',
  'sucul',
  'sudak',
  'sudan',
  'sufle',
  'sukut',
  'sulak',
  'sulh',
  'sulp',
  'sulta',
  'sultan',
  'sulu',
  'sumak',
  'sumo',
  'suna',
  'sunak',
  'sungu',
  'sungur',
  'sunmak',
  'sunta',
  'sunu',
  'sunucu',
  'sunum',
  'sup',
  'sur',
  'surat',
  'sure',
  'sureta',
  'susak',
  'susam',
  'suskun',
  'susmak',
  'suspus',
  'susta',
  'susuz',
  'sut',
  'sutyen',
  'suvat',
  'suyol',
  'suyuk',
  'sv',
  'sw',
  'sx',
  'sy',
  'sz',
  't',
  'taam',
  'taba',
  'tabaat',
  'tabak',
  'tabaka',
  'taban',
  'tabi',
  'tabiat',
  'tabip',
  'tabir',
  'tabiye',
  'tabla',
  'tablet',
  'tablo',
  'tabu',
  'tabur',
  'tabure',
  'tabut',
  'tabya',
  'tacil',
  'tacir',
  'taciz',
  'tadat',
  'tadil',
  'taflan',
  'tafra',
  'tafsil',
  'tafta',
  'tafzih',
  'tahdit',
  'tahfif',
  'tahin',
  'tahkik',
  'tahkim',
  'tahkir',
  'tahlif',
  'tahlil',
  'tahlis',
  'tahmil',
  'tahmin',
  'tahmis',
  'tahnit',
  'tahra',
  'tahrif',
  'tahrik',
  'tahril',
  'tahrip',
  'tahrir',
  'tahsil',
  'tahsis',
  'taht',
  'tahta',
  'tahvil',
  'taife',
  'tak',
  'taka',
  'takas',
  'takat',
  'takaza',
  'takbih',
  'takdim',
  'takdir',
  'takdis',
  'takim',
  'takip',
  'takke',
  'takla',
  'taklak',
  'taklip',
  'taklit',
  'takmak',
  'takoz',
  'takrip',
  'takrir',
  'takriz',
  'taksa',
  'taksi',
  'taksim',
  'taksir',
  'taksit',
  'takt',
  'takti',
  'taktik',
  'taktir',
  'takva',
  'takvim',
  'takyit',
  'tal',
  'talan',
  'talaz',
  'talebe',
  'talep',
  'tali',
  'talih',
  'talik',
  'talika',
  'talil',
  'talim',
  'talip',
  'talk',
  'taltif',
  'talveg',
  'talyum',
  'tam',
  'tamah',
  'tamam',
  'tambur',
  'tamik',
  'tamim',
  'tamir',
  'tampon',
  'tamtam',
  'tamu',
  'tan',
  'tane',
  'tango',
  'tanin',
  'tank',
  'tanker',
  'tantal',
  'tanzim',
  'tanzir',
  'tapa',
  'tapan',
  'tapir',
  'tapmak',
  'tapon',
  'tapu',
  'tar',
  'taraba',
  'taraf',
  'tarak',
  'taraz',
  'taret',
  'tarh',
  'tarhun',
  'tarif',
  'tarife',
  'tarih',
  'tarik',
  'tariz',
  'tarla',
  'tarpan',
  'tart',
  'tarz',
  'tas',
  'tasa',
  'tasdik',
  'tasgir',
  'tashih',
  'taslak',
  'tasma',
  'tasmim',
  'tasni',
  'tasnif',
  'tasrif',
  'tasrih',
  'tastir',
  'tasvip',
  'tasvir',
  'tat',
  'tatar',
  'tatbik',
  'tatil',
  'tatmak',
  'tatmin',
  'tatula',
  'taun',
  'tav',
  'tava',
  'tavaf',
  'tavan',
  'tavik',
  'taviz',
  'tavla',
  'tavsif',
  'tavuk',
  'tavus',
  'tavzif',
  'tavzih',
  'tay',
  'taya',
  'tayf',
  'tayfa',
  'tayfun',
  'tayga',
  'tayin',
  'tayip',
  'taylak',
  'taytay',
  'tayyar',
  'taze',
  'tazim',
  'tazip',
  'taziye',
  'taziz',
  'tazmin',
  'tazyik',
  'tb',
  'tc',
  'td',
  'te',
  'teakup',
  'teala',
  'teali',
  'tearuz',
  'teati',
  'tebaa',
  'tebcil',
  'tebdil',
  'teber',
  'tebrik',
  'tebyiz',
  'tecdit',
  'tecil',
  'tecim',
  'tecrit',
  'tecvit',
  'tecviz',
  'tedai',
  'tedavi',
  'tedbir',
  'tedip',
  'tediye',
  'tedris',
  'tedvin',
  'tedvir',
  'teenni',
  'tef',
  'tefcir',
  'tefe',
  'tefeci',
  'tefek',
  'tefhim',
  'teflon',
  'tefrik',
  'tefrit',
  'tefsir',
  'teftih',
  'tefviz',
  'tehcir',
  'tehdit',
  'tehi',
  'tehir',
  'tehzil',
  'tein',
  'teizm',
  'tek',
  'tekbir',
  'tekdir',
  'teke',
  'tekel',
  'teker',
  'tekfin',
  'tekfir',
  'tekfur',
  'tekil',
  'tekin',
  'tekir',
  'tekit',
  'tekke',
  'teklif',
  'tekme',
  'tekmil',
  'tekne',
  'teknik',
  'tekrar',
  'tekrir',
  'teksif',
  'teksir',
  'tekst',
  'tekvin',
  'tekzip',
  'tel',
  'telef',
  'telek',
  'teleke',
  'teleks',
  'telem',
  'teleme',
  'teles',
  'telfin',
  'telhis',
  'telif',
  'telis',
  'telkih',
  'telkin',
  'telmih',
  'telsiz',
  'teltik',
  'telve',
  'tem',
  'tema',
  'temadi',
  'temas',
  'tembel',
  'tembih',
  'tembul',
  'temcit',
  'temdit',
  'temek',
  'temel',
  'temhir',
  'temin',
  'temiz',
  'temkin',
  'temlik',
  'temmuz',
  'tempo',
  'temren',
  'temrin',
  'temsil',
  'temyiz',
  'ten',
  'tender',
  'teneke',
  'tenha',
  'tenis',
  'tenkil',
  'tenkis',
  'tenkit',
  'tenor',
  'tensik',
  'tensil',
  'tensip',
  'tente',
  'tenvir',
  'tenya',
  'tenzih',
  'tenzil',
  'teolog',
  'teorem',
  'teori',
  'teorik',
  'tepe',
  'tephir',
  'tepi',
  'tepik',
  'tepir',
  'tepke',
  'tepki',
  'tepmek',
  'tepsi',
  'ter',
  'terane',
  'terapi',
  'teras',
  'terasa',
  'teravi',
  'terazi',
  'terbi',
  'tercih',
  'tere',
  'tereci',
  'terek',
  'tereke',
  'teres',
  'terfi',
  'terfih',
  'terfik',
  'tergal',
  'terhin',
  'terhis',
  'terim',
  'terk',
  'terki',
  'terkin',
  'terkip',
  'terlik',
  'termal',
  'terme',
  'termik',
  'termit',
  'termos',
  'ters',
  'tersim',
  'tertip',
  'terzi',
  'terzil',
  'tescil',
  'teshil',
  'teshin',
  'teshir',
  'tesir',
  'tesis',
  'teskin',
  'teslim',
  'teslis',
  'tespih',
  'tespit',
  'tesri',
  'test',
  'testi',
  'testis',
  'tesvit',
  'tetik',
  'tetir',
  'tetkik',
  'tevabi',
  'tevali',
  'tevazu',
  'tevbih',
  'tevcih',
  'tevdi',
  'tevek',
  'tevhit',
  'tevil',
  'tevki',
  'tevkif',
  'tevkil',
  'tevlit',
  'tevsi',
  'tevzi',
  'teyel',
  'teyit',
  'teyp',
  'teyze',
  'tez',
  'tezat',
  'tezek',
  'tezene',
  'tezhip',
  'tezvir',
  'tezyif',
  'tezyin',
  'tezyit',
  'tf',
  'tg',
  'th',
  'ti',
  'tifo',
  'tiftik',
  'tik',
  'tike',
  'tikel',
  'tilki',
  'tilmiz',
  'tim',
  'timbal',
  'timsah',
  'timsal',
  'tin',
  'tiner',
  'tip',
  'tipi',
  'tipik',
  'tir',
  'tiraj',
  'tiran',
  'tirat',
  'tire',
  'tirfil',
  'tirhos',
  'tiril',
  'tirit',
  'tiriz',
  'tirlin',
  'tiroit',
  'tirpit',
  'tiryak',
  'titan',
  'titiz',
  'titr',
  'titrek',
  'tiz',
  'tj',
  'tk',
  'tl',
  'tm',
  'tn',
  'to',
  'tohum',
  'tok',
  'toka',
  'tokat',
  'tokmak',
  'toksin',
  'tokuz',
  'tokyo',
  'tol',
  'tolga',
  'tomak',
  'tomar',
  'tombak',
  'tombaz',
  'tombil',
  'tombul',
  'tomruk',
  'ton',
  'tonaj',
  'tonga',
  'tonik',
  'tonoz',
  'tonton',
  'top',
  'topak',
  'topal',
  'topar',
  'topaz',
  'topik',
  'toplam',
  'toplu',
  'toplum',
  'toprak',
  'toptan',
  'topuk',
  'topuz',
  'tor',
  'torak',
  'torba',
  'torero',
  'torik',
  'torlak',
  'torluk',
  'torna',
  'torpil',
  'tortop',
  'tortu',
  'tortul',
  'torum',
  'torun',
  'toryum',
  'tos',
  'tost',
  'tosun',
  'total',
  'totem',
  'toto',
  'toy',
  'toyaka',
  'toyga',
  'toygar',
  'toynak',
  'toz',
  'tozmak',
  'tp',
  'tq',
  'tr',
  'trafik',
  'trafo',
  'trahom',
  'trajik',
  'trake',
  'trakit',
  'trampa',
  'trans',
  'trap',
  'trapez',
  'tras',
  'trata',
  'travma',
  'tren',
  'trend',
  'trias',
  'triko',
  'trio',
  'triton',
  'trok',
  'trol',
  'tromp',
  'troyka',
  'trup',
  'ts',
  'tt',
  'ttt',
  'tttt',
  'tu',
  'tuba',
  'tufan',
  'tugay',
  'tuh',
  'tuhaf',
  'tul',
  'tuluk',
  'tulum',
  'tulup',
  'tulyum',
  'tuman',
  'tumba',
  'tun',
  'tundra',
  'tungur',
  'tur',
  'tura',
  'turba',
  'turgay',
  'turgor',
  'turist',
  'turizm',
  'turna',
  'turne',
  'turp',
  'turta',
  'tutak',
  'tutam',
  'tutar',
  'tutkal',
  'tutku',
  'tutkun',
  'tutmak',
  'tutsak',
  'tutu',
  'tutuk',
  'tutum',
  'tutya',
  'tuv',
  'tuval',
  'tuz',
  'tuzak',
  'tuzla',
  'tv',
  'tw',
  'tx',
  'ty',
  'tz',
  'u',
  'ub',
  'uc',
  'ucube',
  'ucuz',
  'ud',
  'ue',
  'uf',
  'ufak',
  'ufarak',
  'ufuk',
  'ufunet',
  'ug',
  'uh',
  'uhde',
  'ui',
  'uj',
  'uk',
  'ukde',
  'uknum',
  'ul',
  'ulak',
  'ulam',
  'ulamak',
  'ulan',
  'ulema',
  'ulu',
  'ulumak',
  'ulus',
  'um',
  'umar',
  'ummak',
  'umman',
  'umre',
  'umum',
  'umur',
  'umut',
  'un',
  'unsur',
  'unvan',
  'uo',
  'up',
  'upuslu',
  'upuzun',
  'uq',
  'ur',
  'uran',
  'urba',
  'urgan',
  'uruk',
  'urup',
  'us',
  'usare',
  'uskur',
  'uskuru',
  'uslu',
  'usta',
  'ustura',
  'usul',
  'ut',
  'utku',
  'utmak',
  'uu',
  'uuu',
  'uuuu',
  'uv',
  'uvw',
  'uw',
  'ux',
  'uy',
  'uyak',
  'uydu',
  'uygar',
  'uygun',
  'uyku',
  'uyluk',
  'uymak',
  'uyruk',
  'uysal',
  'uyum',
  'uyumak',
  'uyuz',
  'uz',
  'uzak',
  'uzamak',
  'uzay',
  'uzlet',
  'uzman',
  'uzun',
  'uzuv',
  'v',
  'vaat',
  'vaaz',
  'vacip',
  'vade',
  'vadi',
  'vaftiz',
  'vagina',
  'vagon',
  'vah',
  'vaha',
  'vahdet',
  'vahi',
  'vahim',
  'vahit',
  'vahiy',
  'vaiz',
  'vakar',
  'vaketa',
  'vakfe',
  'vaki',
  'vakit',
  'vakum',
  'vakur',
  'vakvak',
  'vale',
  'valf',
  'vali',
  'valide',
  'valiz',
  'vals',
  'vamp',
  'vampir',
  'vana',
  'vandal',
  'vantuz',
  'vapur',
  'var',
  'varak',
  'varaka',
  'varan',
  'varda',
  'vargel',
  'varide',
  'varil',
  'varis',
  'varit',
  'varmak',
  'varta',
  'varyos',
  'vasat',
  'vasi',
  'vat',
  'vatan',
  'vatka',
  'vatman',
  'vatoz',
  'vay',
  'vazife',
  'vazo',
  'vb',
  'vc',
  'vd',
  've',
  'veba',
  'vebal',
  'veca',
  'vecibe',
  'vecih',
  'vecit',
  'veciz',
  'vecize',
  'veda',
  'vedia',
  'vefa',
  'vefat',
  'vehim',
  'vekil',
  'velet',
  'velev',
  'veli',
  'vere',
  'verem',
  'verese',
  'verev',
  'vergi',
  'veri',
  'verici',
  'verim',
  'verit',
  'vermek',
  'vermut',
  'vernik',
  'vesaik',
  'vesait',
  'vesika',
  'vesile',
  'vetire',
  'veto',
  'veya',
  'vezin',
  'vezir',
  'vezne',
  'vf',
  'vg',
  'vh',
  'vi',
  'vicdan',
  'vida',
  'video',
  'vido',
  'vikaye',
  'vikont',
  'vinter',
  'vinyet',
  'vira',
  'viraj',
  'viran',
  'virane',
  'vire',
  'virman',
  'virt',
  'visal',
  'viski',
  'viskoz',
  'vites',
  'vitir',
  'vitray',
  'vitrin',
  'viya',
  'viyak',
  'viyola',
  'vize',
  'vizite',
  'vizon',
  'vizyon',
  'vj',
  'vk',
  'vl',
  'vm',
  'vn',
  'vo',
  'vodvil',
  'vokal',
  'vole',
  'voli',
  'volkan',
  'volt',
  'volta',
  'voltaj',
  'vombat',
  'vonoz',
  'votka',
  'vp',
  'vq',
  'vr',
  'vs',
  'vt',
  'vu',
  'vuku',
  'vukuat',
  'vukuf',
  'vulva',
  'vurgu',
  'vurgun',
  'vurmak',
  'vurtut',
  'vuru',
  'vuslat',
  'vusul',
  'vuzuh',
  'vv',
  'vvv',
  'vvvv',
  'vw',
  'vwx',
  'vx',
  'vy',
  'vz',
  'w',
  'wb',
  'wc',
  'wd',
  'we',
  'wf',
  'wg',
  'wh',
  'wi',
  'wj',
  'wk',
  'wl',
  'wm',
  'wn',
  'wo',
  'wp',
  'wq',
  'wr',
  'ws',
  'wt',
  'wu',
  'wv',
  'ww',
  'www',
  'wwww',
  'wx',
  'wxy',
  'wy',
  'wz',
  'x',
  'xb',
  'xc',
  'xd',
  'xe',
  'xf',
  'xg',
  'xh',
  'xi',
  'xj',
  'xk',
  'xl',
  'xm',
  'xn',
  'xo',
  'xp',
  'xq',
  'xr',
  'xs',
  'xt',
  'xu',
  'xv',
  'xw',
  'xx',
  'xxx',
  'xxxx',
  'xy',
  'xyz',
  'xz',
  'y',
  'yaba',
  'yaban',
  'yafa',
  'yafta',
  'yahey',
  'yahni',
  'yahu',
  'yahut',
  'yaka',
  'yakin',
  'yakmak',
  'yakut',
  'yal',
  'yalak',
  'yalaka',
  'yalan',
  'yalap',
  'yalaz',
  'yallah',
  'yalman',
  'yalpa',
  'yalpak',
  'yaltak',
  'yama',
  'yamak',
  'yaman',
  'yamru',
  'yamuk',
  'yamyam',
  'yan',
  'yanak',
  'yanal',
  'yanay',
  'yani',
  'yanmak',
  'yapak',
  'yapay',
  'yapboz',
  'yapmak',
  'yaprak',
  'yar',
  'yara',
  'yarar',
  'yarasa',
  'yarbay',
  'yarda',
  'yardak',
  'yarka',
  'yarma',
  'yarmak',
  'yarpuz',
  'yas',
  'yasa',
  'yasak',
  'yasal',
  'yasama',
  'yasmak',
  'yat',
  'yatak',
  'yatay',
  'yatmak',
  'yatuk',
  'yavan',
  'yave',
  'yaver',
  'yavru',
  'yavuz',
  'yay',
  'yaya',
  'yayan',
  'yayla',
  'yaylak',
  'yaymak',
  'yayvan',
  'yaz',
  'yazar',
  'yazboz',
  'yazma',
  'yazmak',
  'yazman',
  'yb',
  'yc',
  'yd',
  'ye',
  'yedek',
  'yedi',
  'yedmek',
  'yeis',
  'yek',
  'yeksan',
  'yekta',
  'yel',
  'yele',
  'yelek',
  'yelken',
  'yelmek',
  'yelpik',
  'yeltek',
  'yelve',
  'yem',
  'yemek',
  'yemeni',
  'yemin',
  'yen',
  'yenge',
  'yeni',
  'yenik',
  'yenmek',
  'yer',
  'yerel',
  'yerey',
  'yergi',
  'yerli',
  'yermek',
  'yeter',
  'yeti',
  'yetik',
  'yetim',
  'yetke',
  'yetki',
  'yetkin',
  'yetmek',
  'yeygi',
  'yezit',
  'yf',
  'yg',
  'yh',
  'yi',
  'yine',
  'yirik',
  'yirmi',
  'yitik',
  'yitmek',
  'yiv',
  'yiyici',
  'yj',
  'yk',
  'yl',
  'ym',
  'yn',
  'yo',
  'yobaz',
  'yoga',
  'yogi',
  'yok',
  'yoksa',
  'yoksul',
  'yoksun',
  'yol',
  'yolak',
  'yolcu',
  'yolluk',
  'yolmak',
  'yolsuz',
  'yoluk',
  'yom',
  'yoma',
  'yonca',
  'yonga',
  'yongar',
  'yonmak',
  'yont',
  'yontu',
  'yontuk',
  'yordam',
  'yorga',
  'yorgan',
  'yorgun',
  'yormak',
  'yortu',
  'yorum',
  'yosma',
  'yosun',
  'yoz',
  'yp',
  'yq',
  'yr',
  'ys',
  'yt',
  'yu',
  'yudum',
  'yuf',
  'yufka',
  'yuha',
  'yulaf',
  'yular',
  'yuma',
  'yumak',
  'yummak',
  'yumru',
  'yumruk',
  'yumuk',
  'yunmak',
  'yunus',
  'yurt',
  'yutak',
  'yutmak',
  'yuva',
  'yv',
  'yw',
  'yx',
  'yy',
  'yyy',
  'yyyy',
  'yz',
  'z',
  'zaaf',
  'zafer',
  'zahir',
  'zahire',
  'zahit',
  'zahmet',
  'zait',
  'zakkum',
  'zalim',
  'zam',
  'zaman',
  'zamane',
  'zambak',
  'zamir',
  'zamk',
  'zan',
  'zanaat',
  'zanka',
  'zaping',
  'zapt',
  'zar',
  'zarar',
  'zarf',
  'zari',
  'zarif',
  'zarp',
  'zart',
  'zarta',
  'zat',
  'zaten',
  'zati',
  'zayiat',
  'zb',
  'zc',
  'zd',
  'ze',
  'zeamet',
  'zebra',
  'zehir',
  'zeki',
  'zembil',
  'zemin',
  'zemzem',
  'zen',
  'zenci',
  'zencir',
  'zengin',
  'zenne',
  'zeplin',
  'zer',
  'zerde',
  'zerk',
  'zerre',
  'zerrin',
  'zeval',
  'zevat',
  'zevce',
  'zevk',
  'zevzek',
  'zeybek',
  'zeyrek',
  'zeytin',
  'zf',
  'zg',
  'zh',
  'zi',
  'zibidi',
  'zifir',
  'zift',
  'zigot',
  'zihaf',
  'zihin',
  'zihnen',
  'zikir',
  'zikzak',
  'zil',
  'zillet',
  'zilyet',
  'zimmet',
  'zina',
  'zincir',
  'zindan',
  'zinde',
  'zinhar',
  'zira',
  'ziraat',
  'zirve',
  'zirzop',
  'ziya',
  'ziyade',
  'ziyan',
  'ziynet',
  'zj',
  'zk',
  'zl',
  'zm',
  'zn',
  'zo',
  'zoka',
  'zom',
  'zona',
  'zonk',
  'zoolog',
  'zor',
  'zoraki',
  'zorba',
  'zorgu',
  'zorla',
  'zp',
  'zq',
  'zr',
  'zs',
  'zt',
  'zu',
  'zuhur',
  'zula',
  'zulmet',
  'zurna',
  'zurt',
  'zv',
  'zw',
  'zx',
  'zy',
  'zz',
  'zzz',
  'zzzz',
];
