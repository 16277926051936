export const portuguese = [
  'a',
  'aa',
  'aaa',
  'aaaa',
  'ab',
  'aba',
  'abaco',
  'abade',
  'abadia',
  'abafa',
  'abafar',
  'abafo',
  'abaixo',
  'abajur',
  'abalar',
  'abalo',
  'abanar',
  'abano',
  'abate',
  'abater',
  'abc',
  'abdicar',
  'abdome',
  'abelha',
  'aberta',
  'aberto',
  'abeto',
  'abismo',
  'abjeto',
  'ablaut',
  'abobada',
  'abobado',
  'abobar',
  'abolir',
  'abonar',
  'abono',
  'aborto',
  'abraco',
  'abrico',
  'abrigo',
  'abril',
  'abrir',
  'abrupto',
  'abster',
  'abusar',
  'abuso',
  'abutre',
  'ac',
  'acabar',
  'acacia',
  'acai',
  'acaju',
  'acamar',
  'acanhar',
  'acao',
  'acaraje',
  'acaro',
  'acaso',
  'acatar',
  'acato',
  'ace',
  'aceder',
  'aceito',
  'acelga',
  'acem',
  'acenar',
  'aceno',
  'acento',
  'acerca',
  'acerto',
  'aceso',
  'acessar',
  'acesso',
  'acha',
  'achado',
  'achar',
  'achava',
  'acida',
  'acidez',
  'acido',
  'acima',
  'acinte',
  'aclive',
  'acne',
  'aco',
  'acoite',
  'acola',
  'acorde',
  'acordo',
  'acre',
  'acuado',
  'acuar',
  'acucar',
  'acude',
  'acudir',
  'acusar',
  'ad',
  'ada',
  'adaga',
  'adagio',
  'adega',
  'adendo',
  'adepto',
  'adereco',
  'aderir',
  'adesao',
  'adeus',
  'adiar',
  'adicao',
  'adido',
  'adn',
  'adobe',
  'adocao',
  'adocar',
  'adonde',
  'adonis',
  'adorar',
  'adorno',
  'adotar',
  'adubar',
  'adubo',
  'adular',
  'adulto',
  'adunco',
  'advir',
  'ae',
  'aerea',
  'aereo',
  'af',
  'afa',
  'afagar',
  'afago',
  'afamar',
  'afanar',
  'afano',
  'afasia',
  'afavel',
  'afegao',
  'afelio',
  'aferir',
  'afetar',
  'afeto',
  'afiada',
  'afiado',
  'afiar',
  'afinal',
  'afinar',
  'afinco',
  'afixar',
  'afixo',
  'aflito',
  'afluir',
  'afobar',
  'afofar',
  'afogado',
  'afogar',
  'afoito',
  'afora',
  'afro',
  'afta',
  'aftosa',
  'ag',
  'aga',
  'agarrar',
  'agata',
  'agenda',
  'agente',
  'agil',
  'agio',
  'agiota',
  'agir',
  'agitar',
  'agito',
  'agnus',
  'agogo',
  'agonia',
  'agora',
  'agosto',
  'agouro',
  'agrado',
  'agrados',
  'agriao',
  'agrura',
  'agua',
  'aguado',
  'aguar',
  'aguas',
  'agucado',
  'agucar',
  'agudo',
  'aguia',
  'agulha',
  'ah',
  'ai',
  'aia',
  'aids',
  'aimore',
  'ainda',
  'aino',
  'aipim',
  'aipo',
  'airoso',
  'aj',
  'ajeitar',
  'ajuda',
  'ajudar',
  'ajustar',
  'ajuste',
  'ak',
  'al',
  'ala',
  'alado',
  'alagar',
  'alamo',
  'alarde',
  'alarme',
  'alaude',
  'alazao',
  'albedo',
  'albino',
  'album',
  'albume',
  'alca',
  'alcada',
  'alcado',
  'alcaide',
  'alcali',
  'alcapao',
  'alcar',
  'alce',
  'alceno',
  'alcino',
  'alcool',
  'alcova',
  'aldeao',
  'aldeia',
  'alea',
  'alef',
  'alegar',
  'alegre',
  'aleia',
  'alem',
  'alemao',
  'aleno',
  'alento',
  'alerta',
  'alfa',
  'alface',
  'alfafa',
  'alga',
  'algema',
  'algo',
  'algol',
  'algoz',
  'alguem',
  'algum',
  'alguma',
  'alguns',
  'alheio',
  'alho',
  'alhos',
  'ali',
  'aliado',
  'aliar',
  'alias',
  'alibi',
  'alii',
  'alijar',
  'alinea',
  'alinhar',
  'alinho',
  'alisar',
  'alisio',
  'aliviar',
  'alivio',
  'aljava',
  'allen',
  'alma',
  'almejo',
  'almoco',
  'alo',
  'alocar',
  'aloe',
  'alojar',
  'alpaca',
  'alpino',
  'alta',
  'altar',
  'altas',
  'alter',
  'alteza',
  'altivo',
  'alto',
  'altos',
  'altura',
  'alua',
  'aludir',
  'alugar',
  'aluno',
  'alunos',
  'alusao',
  'alvara',
  'alvo',
  'alvura',
  'am',
  'ama',
  'amado',
  'amador',
  'amago',
  'amanha',
  'amante',
  'amar',
  'amargo',
  'amarra',
  'amasia',
  'amasio',
  'amavel',
  'ambar',
  'ambiguo',
  'ambito',
  'ambos',
  'ameba',
  'ameixa',
  'amelia',
  'amem',
  'ameno',
  'ame-o',
  'amical',
  'amido',
  'amigo',
  'amigos',
  'amina',
  'amiude',
  'amizade',
  'amo',
  'amolar',
  'amonia',
  'amonio',
  'amor',
  'amora',
  'amoral',
  'amorfo',
  'amparo',
  'ampere',
  'amplo',
  'ampola',
  'amuado',
  'amuar',
  'an',
  'ana',
  'anagua',
  'anais',
  'anal',
  'anao',
  'anca',
  'ancho',
  'anciao',
  'ancora',
  'anda',
  'andada',
  'andador',
  'andar',
  'andino',
  'andor',
  'andrajo',
  'anel',
  'anelar',
  'anemia',
  'anexar',
  'anexo',
  'anfora',
  'angina',
  'anglo',
  'angora',
  'angra',
  'angu',
  'angulo',
  'anil',
  'animal',
  'animar',
  'animo',
  'anion',
  'anis',
  'anjo',
  'ano',
  'anodo',
  'ano-luz',
  'anos',
  'anotada',
  'anotado',
  'anotar',
  'anseio',
  'ansia',
  'ansiar',
  'anta',
  'ante',
  'antena',
  'antes',
  'antigo',
  'antro',
  'anu',
  'anual',
  'anuenio',
  'anuir',
  'anular',
  'anus',
  'anzol',
  'ao',
  'aonde',
  'aorta',
  'aos',
  'ap',
  'apache',
  'apagar',
  'apara',
  'aparar',
  'aparece',
  'aparte',
  'apatia',
  'apear',
  'apegar',
  'apego',
  'apelar',
  'apelo',
  'apenas',
  'apepsia',
  'aperto',
  'apesar',
  'apex',
  'apice',
  'apicola',
  'apis',
  'apitar',
  'apito',
  'apneia',
  'apogeu',
  'apoiar',
  'apoio',
  'apolo',
  'apor',
  'aporte',
  'apos',
  'aposta',
  'aposto',
  'apre',
  'apreco',
  'aprumar',
  'aprumo',
  'apto',
  'apupar',
  'apupo',
  'apurar',
  'apuro',
  'apuros',
  'aq',
  'aquela',
  'aquele',
  'aquem',
  'aqui',
  'aquilo',
  'aquoso',
  'ar',
  'arabe',
  'arado',
  'aragem',
  'arame',
  'aranha',
  'arapuca',
  'arar',
  'arara',
  'arauto',
  'arbusto',
  'arca',
  'arcada',
  'arcado',
  'arcano',
  'arcar',
  'arco',
  'arder',
  'ardido',
  'ardil',
  'ardor',
  'arduo',
  'are',
  'area',
  'areia',
  'arejar',
  'arena',
  'ares',
  'aresta',
  'arfar',
  'argila',
  'argo',
  'argola',
  'argot',
  'arguir',
  'arguto',
  'aria',
  'ariano',
  'aridez',
  'arido',
  'aries',
  'ariete',
  'arisco',
  'arma',
  'armada',
  'armado',
  'armar',
  'armas',
  'aro',
  'aroma',
  'arpao',
  'arpejo',
  'arpoar',
  'arraia',
  'arre',
  'arriar',
  'arrimo',
  'arroba',
  'arroio',
  'arrojo',
  'arroto',
  'arroz',
  'arruda',
  'arruela',
  'art',
  'arte',
  'artes',
  'artico',
  'artigo',
  'artrite',
  'arvore',
  'as',
  'asa',
  'asceta',
  'ascii',
  'asco',
  'asilar',
  'asilo',
  'asma',
  'asno',
  'aspas',
  'aspero',
  'assado',
  'assalto',
  'assar',
  'assaz',
  'assear',
  'asseio',
  'assim',
  'assoar',
  'asteca',
  'astral',
  'astro',
  'astuto',
  'at',
  'ata',
  'atacar',
  'atache',
  'atadas',
  'atado',
  'atalho',
  'ataque',
  'atar',
  'ataude',
  'ate',
  'atear',
  'atelie',
  'ateneu',
  'atento',
  'ater',
  'aterro',
  'ateu',
  'aticar',
  'atimo',
  'atinar',
  'atirar',
  'ativa',
  'ativar',
  'ativo',
  'atlas',
  'atleta',
  'ato',
  'a-toa',
  'atol',
  'atolar',
  'atomo',
  'atona',
  'atonal',
  'atono',
  'ator',
  'atrair',
  'atras',
  'atraso',
  'atrio',
  'atritar',
  'atrito',
  'atriz',
  'atroz',
  'atual',
  'atuante',
  'atuar',
  'atum',
  'aturar',
  'au',
  'au-au',
  'audaz',
  'audio',
  'aue',
  'auge',
  'aula',
  'aura',
  'aurea',
  'aureo',
  'aurora',
  'author',
  'auto',
  'autor',
  'autos',
  'autuar',
  'av',
  'aval',
  'avanco',
  'avante',
  'avaria',
  'avaro',
  'avatar',
  'ave',
  'aveia',
  'avela',
  'avenca',
  'averso',
  'aves',
  'avesso',
  'avexar',
  'aviao',
  'aviar',
  'avidez',
  'avido',
  'avioes',
  'avisar',
  'aviso',
  'avivar',
  'avo',
  'avoado',
  'avocar',
  'avulso',
  'aw',
  'ax',
  'axe',
  'axial',
  'axila',
  'axilar',
  'axioma',
  'ay',
  'az',
  'azar',
  'azarao',
  'azarar',
  'azedar',
  'azedo',
  'azeite',
  'azia',
  'azimo',
  'azimute',
  'azoto',
  'azul',
  'azulao',
  'b',
  'baba',
  'babaca',
  'babacu',
  'babalao',
  'babar',
  'babel',
  'babosa',
  'bacana',
  'bacara',
  'bacia',
  'bacilo',
  'baco',
  'badalo',
  'badejo',
  'bafafa',
  'bafo',
  'bagaco',
  'bagre',
  'bah',
  'baia',
  'baiana',
  'baiano',
  'baiao',
  'bailar',
  'baile',
  'bainha',
  'baio',
  'bairro',
  'baita',
  'baiuca',
  'baixa',
  'baixar',
  'baixo',
  'baixos',
  'bala',
  'balaco',
  'balada',
  'balaio',
  'balanco',
  'balao',
  'balas',
  'balcao',
  'balde',
  'baldio',
  'bale',
  'balear',
  'baleia',
  'balela',
  'balido',
  'balines',
  'balir',
  'baliza',
  'balofo',
  'balsa',
  'bamba',
  'bambo',
  'bambu',
  'banal',
  'banana',
  'banca',
  'bancar',
  'banco',
  'banda',
  'bandas',
  'bando',
  'banha',
  'banhar',
  'banho',
  'banido',
  'banir',
  'banjo',
  'banto',
  'banze',
  'banzo',
  'baoba',
  'baque',
  'bar',
  'barao',
  'barata',
  'barato',
  'barba',
  'barca',
  'barco',
  'bardo',
  'bario',
  'barman',
  'barra',
  'barraco',
  'barrar',
  'barras',
  'barril',
  'barro',
  'basca',
  'basco',
  'base',
  'basear',
  'basic',
  'basica',
  'basico',
  'basse',
  'basta',
  'bastao',
  'bastar',
  'bata',
  'batata',
  'batavo',
  'batch',
  'bateia',
  'bater',
  'batida',
  'batido',
  'batina',
  'batista',
  'batom',
  'batuta',
  'bau',
  'baud',
  'bavaro',
  'bazar',
  'bazuca',
  'bb',
  'bbb',
  'bbbb',
  'bc',
  'bcd',
  'bd',
  'be',
  'beat',
  'beata',
  'beato',
  'bebaco',
  'bebado',
  'bebe',
  'bebedo',
  'beber',
  'bebes',
  'bebida',
  'bebum',
  'beca',
  'beco',
  'bedel',
  'beduino',
  'bege',
  'beico',
  'beijar',
  'beijo',
  'beira',
  'beirar',
  'bel',
  'beleleu',
  'beleza',
  'belga',
  'belico',
  'belo',
  'bem',
  'bemol',
  'bencao',
  'bendito',
  'bene',
  'bens',
  'benta',
  'bento',
  'benzer',
  'beocio',
  'beque',
  'bequer',
  'berber',
  'berco',
  'berne',
  'berrar',
  'berro',
  'besta',
  'beta',
  'betamax',
  'betula',
  'betume',
  'bexiga',
  'bf',
  'bg',
  'bh',
  'bi',
  'bibelo',
  'biblia',
  'biboca',
  'bica',
  'bicada',
  'bicama',
  'bicar',
  'bicas',
  'biceps',
  'bicha',
  'bichar',
  'bicho',
  'bichos',
  'bico',
  'bicota',
  'bicudo',
  'bid',
  'bide',
  'biela',
  'bienal',
  'bienio',
  'bife',
  'big',
  'biga',
  'bigamo',
  'bigle',
  'bigode',
  'bijecao',
  'bile',
  'bilhao',
  'bilhar',
  'biliar',
  'bilis',
  'biltre',
  'bimbar',
  'bimotor',
  'bingo',
  'biogas',
  'bioma',
  'biombo',
  'bipar',
  'bipe',
  'bipede',
  'bird',
  'biriba',
  'birita',
  'birra',
  'biruta',
  'bis',
  'bisao',
  'bisar',
  'bisavo',
  'bisca',
  'biscate',
  'bisneto',
  'bispado',
  'bispo',
  'bistro',
  'bit',
  'bitola',
  'bitter',
  'bituca',
  'bj',
  'bk',
  'bl',
  'bloco',
  'blusa',
  'blusao',
  'bm',
  'bn',
  'bo',
  'boa',
  'boa-fe',
  'boas',
  'boate',
  'boato',
  'bobagem',
  'bobear',
  'bobi',
  'bobina',
  'bobo',
  'boboca',
  'boca',
  'bocado',
  'bocal',
  'bocejo',
  'bocha',
  'bocio',
  'boco',
  'boda',
  'bodas',
  'bode',
  'bodega',
  'boemia',
  'boemio',
  'boer',
  'bofe',
  'bofete',
  'bohrio',
  'boi',
  'boia',
  'boiada',
  'boiar',
  'boina',
  'bois',
  'boista',
  'bojo',
  'bojudo',
  'bola',
  'bolaco',
  'bolada',
  'bolao',
  'bolar',
  'bolas',
  'boleia',
  'bolero',
  'boleto',
  'bolha',
  'bolide',
  'bolido',
  'bolo',
  'bolor',
  'bolota',
  'bolsa',
  'bolso',
  'bom',
  'bomba',
  'bombom',
  'bonde',
  'bone',
  'boneca',
  'boneco',
  'bongo',
  'bonito',
  'bons',
  'bonsai',
  'bonus',
  'bonzo',
  'book',
  'boom',
  'boot',
  'borato',
  'borax',
  'borco',
  'borda',
  'bordao',
  'bordar',
  'bordel',
  'bordo',
  'boreal',
  'boro',
  'borra',
  'borrao',
  'borrar',
  'borsch',
  'boson',
  'bosque',
  'bossa',
  'bosta',
  'bota',
  'botao',
  'botar',
  'botas',
  'bote',
  'boteco',
  'botica',
  'botija',
  'botina',
  'boto',
  'bovino',
  'boxe',
  'bp',
  'bq',
  'br',
  'brabo',
  'braca',
  'bracal',
  'braco',
  'bradar',
  'brado',
  'braile',
  'brama',
  'branca',
  'branco',
  'brandir',
  'brando',
  'brasa',
  'brasao',
  'brasil',
  'bravio',
  'bravo',
  'breca',
  'brecar',
  'brecha',
  'brecho',
  'brega',
  'brejo',
  'breque',
  'bretao',
  'breu',
  'breve',
  'brida',
  'bridge',
  'briga',
  'brigar',
  'brilho',
  'brim',
  'brinco',
  'brinde',
  'brio',
  'brioso',
  'brisa',
  'britar',
  'broa',
  'broca',
  'brocado',
  'broche',
  'bromato',
  'bromo',
  'bronca',
  'bronco',
  'bronha',
  'bronze',
  'brotar',
  'broto',
  'broxa',
  'broxar',
  'bruaca',
  'bruma',
  'brusco',
  'brutal',
  'bruto',
  'bruxa',
  'bruxo',
  'bs',
  'bt',
  'bu',
  'bucha',
  'bucho',
  'buda',
  'budista',
  'bueiro',
  'bufalo',
  'bufao',
  'bufar',
  'bufe',
  'buffer',
  'bug',
  'bugre',
  'bujao',
  'bula',
  'bulbo',
  'bule',
  'bum',
  'bumbum',
  'bunda',
  'bundao',
  'bunsen',
  'buque',
  'buraco',
  'burgo',
  'burla',
  'burlar',
  'burro',
  'burros',
  'busca',
  'buscar',
  'bustie',
  'busto',
  'butano',
  'buzina',
  'buzio',
  'buzios',
  'bv',
  'bw',
  'bx',
  'by',
  'bz',
  'c',
  'caaba',
  'cabaco',
  'cabal',
  'cabala',
  'cabana',
  'cabare',
  'cabeca',
  'cabelo',
  'caber',
  'cabide',
  'cabina',
  'cabine',
  'cabo',
  'cabra',
  'caca',
  'cacada',
  'cacao',
  'cacapa',
  'cacar',
  'cacau',
  'cacete',
  'cache',
  'cacho',
  'cacife',
  'caco',
  'cacoar',
  'cacto',
  'cacula',
  'cada',
  'cade',
  'cadeia',
  'cadela',
  'cadete',
  'cadmio',
  'caduco',
  'cafe',
  'cafezal',
  'cafila',
  'cafona',
  'cafune',
  'cafuzo',
  'cagado',
  'cai',
  'caiado',
  'caiapo',
  'caiar',
  'caibra',
  'caibro',
  'caida',
  'caido',
  'cair',
  'cais',
  'caiser',
  'caixa',
  'caixao',
  'cajado',
  'caju',
  'cal',
  'calado',
  'calao',
  'calar',
  'calca',
  'calcao',
  'calcar',
  'calcas',
  'calcio',
  'calco',
  'calda',
  'caldeu',
  'caldo',
  'calha',
  'calhar',
  'calice',
  'calido',
  'califa',
  'call',
  'calma',
  'calmo',
  'calo',
  'calombo',
  'calor',
  'calos',
  'caloso',
  'calota',
  'calote',
  'calva',
  'calvo',
  'cama',
  'camada',
  'camara',
  'cambio',
  'camelo',
  'camera',
  'camisa',
  'campal',
  'campea',
  'campi',
  'campo',
  'campus',
  'cana',
  'canal',
  'canape',
  'canario',
  'canca',
  'cancao',
  'cancer',
  'cancha',
  'cancro',
  'candor',
  'caneca',
  'caneco',
  'canela',
  'caneta',
  'canga',
  'cangaco',
  'canhao',
  'canico',
  'canil',
  'canino',
  'canja',
  'cano',
  'canoa',
  'canon',
  'canoro',
  'cansar',
  'cantao',
  'cantar',
  'cantil',
  'canto',
  'cantor',
  'canudo',
  'cao',
  'caol',
  'caolho',
  'caos',
  'capa',
  'capado',
  'capar',
  'capaz',
  'capela',
  'capeta',
  'capilar',
  'capim',
  'capita',
  'capitao',
  'capo',
  'capota',
  'captar',
  'captor',
  'caput',
  'capuz',
  'caqui',
  'cara',
  'carao',
  'carate',
  'cardume',
  'careca',
  'careta',
  'carga',
  'cargo',
  'cariar',
  'carie',
  'carijo',
  'caril',
  'carma',
  'carmim',
  'carnal',
  'carne',
  'caro',
  'caroco',
  'carola',
  'carona',
  'carpa',
  'carpal',
  'carpir',
  'carpo',
  'carro',
  'carta',
  'cartao',
  'cartaz',
  'carte',
  'cartel',
  'cartum',
  'carvao',
  'cas',
  'casa',
  'casaca',
  'casaco',
  'casado',
  'casal',
  'casar',
  'casario',
  'casba',
  'casca',
  'cascata',
  'casco',
  'casear',
  'caserna',
  'caso',
  'casos',
  'cassar',
  'casta',
  'casto',
  'castor',
  'casual',
  'casulo',
  'catalao',
  'catar',
  'catch',
  'cateto',
  'cation',
  'cativo',
  'catodo',
  'cauboi',
  'caucao',
  'cauda',
  'cauim',
  'caule',
  'causa',
  'causal',
  'causar',
  'causo',
  'cavado',
  'cavala',
  'cavalo',
  'cavar',
  'cave',
  'caviar',
  'cavilha',
  'cavo',
  'caxias',
  'cb',
  'cc',
  'ccc',
  'cccc',
  'cd',
  'cde',
  'ce',
  'cear',
  'cebola',
  'ceco',
  'ceder',
  'cedo',
  'cedro',
  'cedula',
  'cegar',
  'cego',
  'cei',
  'ceia',
  'ceifa',
  'ceifar',
  'cela',
  'celere',
  'celo',
  'celta',
  'celula',
  'cem',
  'cena',
  'cenico',
  'censo',
  'censor',
  'cento',
  'centro',
  'cep',
  'cepa',
  'cera',
  'cerca',
  'cercar',
  'cerco',
  'cerda',
  'cereal',
  'cereja',
  'ceres',
  'cerio',
  'cerne',
  'cerol',
  'cerrar',
  'cerro',
  'certo',
  'cervo',
  'cerzir',
  'cesar',
  'cesio',
  'cessao',
  'cessar',
  'cesta',
  'cesto',
  'cetim',
  'cetona',
  'cetro',
  'ceu',
  'ceus',
  'cevada',
  'cevado',
  'cevar',
  'cf',
  'cg',
  'ch',
  'cha',
  'chabu',
  'chador',
  'chaga',
  'chagas',
  'chale',
  'chama',
  'chamar',
  'chamine',
  'chance',
  'chao',
  'chapa',
  'chapada',
  'chapas',
  'chapeu',
  'charco',
  'charge',
  'charme',
  'chassi',
  'chat',
  'chata',
  'chato',
  'chavao',
  'chave',
  'che',
  'checar',
  'check',
  'checo',
  'chefe',
  'chefia',
  'chega',
  'chegar',
  'chegou',
  'cheia',
  'cheio',
  'cheire',
  'cheiro',
  'cheque',
  'chiado',
  'chiar',
  'chico',
  'chifre',
  'china',
  'chinela',
  'chines',
  'chintz',
  'chip',
  'chique',
  'chispa',
  'chiste',
  'chita',
  'choca',
  'chocar',
  'chocho',
  'chofer',
  'chop',
  'chope',
  'choque',
  'chorao',
  'chorar',
  'choro',
  'chova',
  'chover',
  'chua',
  'chuchar',
  'chuchu',
  'chue',
  'chule',
  'chulo',
  'chumbo',
  'chupar',
  'churro',
  'chutar',
  'chute',
  'chuva',
  'chuvao',
  'ci',
  'ciciar',
  'cicio',
  'ciclo',
  'cicuta',
  'cidade',
  'cidra',
  'ciente',
  'cifra',
  'cifrao',
  'cifrar',
  'cigano',
  'cilada',
  'cilha',
  'cilhar',
  'cilio',
  'cima',
  'cimo',
  'cinco',
  'cindir',
  'cine',
  'cinema',
  'cingir',
  'cinico',
  'cinta',
  'cinto',
  'cinza',
  'cinzas',
  'cinzel',
  'cio',
  'cioso',
  'cipo',
  'cipoal',
  'circo',
  'cirio',
  'cirro',
  'ciscar',
  'cisco',
  'cisma',
  'cismar',
  'cisne',
  'cisto',
  'citado',
  'citar',
  'citara',
  'ciume',
  'civel',
  'civico',
  'civil',
  'cj',
  'ck',
  'cl',
  'cla',
  'clamar',
  'clamor',
  'clara',
  'clarao',
  'clarim',
  'claro',
  'classe',
  'clava',
  'clave',
  'clero',
  'clicar',
  'cliche',
  'clima',
  'climax',
  'clip',
  'clipe',
  'cliper',
  'clique',
  'cloaca',
  'clonal',
  'clonar',
  'clone',
  'clorar',
  'cloro',
  'close',
  'clt',
  'clube',
  'cm',
  'cn',
  'co',
  'coacao',
  'coador',
  'coagir',
  'coala',
  'coalhar',
  'coar',
  'coaxar',
  'cobaia',
  'cobica',
  'cobol',
  'cobra',
  'cobrar',
  'cobre',
  'cobrir',
  'coca',
  'cocada',
  'cocar',
  'coccix',
  'coche',
  'cocker',
  'coco',
  'cocota',
  'codice',
  'codigo',
  'codon',
  'coelho',
  'coesao',
  'coeso',
  'cofre',
  'cogito',
  'coibir',
  'coice',
  'coio',
  'coiote',
  'coisa',
  'coisas',
  'coito',
  'cola',
  'colado',
  'colar',
  'colcha',
  'colcos',
  'coldre',
  'colega',
  'colera',
  'coleta',
  'colete',
  'colher',
  'colica',
  'colina',
  'colite',
  'colmar',
  'colo',
  'colon',
  'colono',
  'coluna',
  'com',
  'coma',
  'comeco',
  'comer',
  'comes',
  'cometa',
  'comeu',
  'comico',
  'comida',
  'comido',
  'comigo',
  'comite',
  'como',
  'comoda',
  'comodo',
  'complo',
  'compor',
  'compra',
  'comum',
  'comuna',
  'comunal',
  'concha',
  'condao',
  'conde',
  'condor',
  'conduz',
  'cone',
  'conego',
  'conexo',
  'confete',
  'conga',
  'conico',
  'conluio',
  'conosco',
  'consome',
  'consul',
  'conta',
  'contar',
  'contas',
  'contem',
  'conter',
  'conto',
  'contra',
  'conves',
  'convir',
  'copa',
  'copas',
  'copia',
  'copiao',
  'copiar',
  'copo',
  'copos',
  'copta',
  'coptico',
  'copula',
  'coque',
  'cor',
  'corado',
  'coragem',
  'coral',
  'corar',
  'corca',
  'corcel',
  'corda',
  'cordao',
  'cordas',
  'cordel',
  'coreto',
  'coriza',
  'corja',
  'cornea',
  'corneo',
  'corner',
  'corno',
  'coro',
  'coroa',
  'coroar',
  'coroca',
  'corpo',
  'corpus',
  'correr',
  'corso',
  'cortar',
  'corte',
  'cortes',
  'cortex',
  'coruja',
  'corvo',
  'cos',
  'coser',
  'cosmo',
  'costa',
  'costas',
  'cota',
  'cotacao',
  'cotado',
  'cotar',
  'cotejo',
  'coto',
  'couro',
  'couve',
  'cova',
  'covado',
  'covil',
  'coxa',
  'coxear',
  'coxo',
  'cozer',
  'cozido',
  'cp',
  'cpu',
  'cq',
  'cr',
  'cracha',
  'crack',
  'cranio',
  'craque',
  'crase',
  'crasso',
  'cravar',
  'cravo',
  'crawl',
  'creche',
  'credo',
  'credor',
  'cremar',
  'creme',
  'crenca',
  'crente',
  'crepe',
  'crepom',
  'crer',
  'crespo',
  'cria',
  'criacao',
  'criada',
  'criado',
  'criar',
  'cricri',
  'criei',
  'crime',
  'crimes',
  'crina',
  'cripta',
  'crise',
  'crisma',
  'crista',
  'cristo',
  'crivar',
  'crivel',
  'crivo',
  'croata',
  'croche',
  'cromado',
  'cromar',
  'cromo',
  'croqui',
  'crosta',
  'cru',
  'cruel',
  'cruz',
  'cruzar',
  'cruzes',
  'cs',
  'ct',
  'cu',
  'cuba',
  'cubano',
  'cubica',
  'cubico',
  'cubito',
  'cubo',
  'cuca',
  'cuco',
  'cucuia',
  'cueca',
  'cueiro',
  'cuia',
  'cuica',
  'cuidam',
  'cuidar',
  'cujo',
  'culote',
  'culpa',
  'culpar',
  'cultivo',
  'culto',
  'cultuar',
  'cume',
  'cumulo',
  'cunha',
  'cunhar',
  'cunho',
  'cupe',
  'cupido',
  'cupim',
  'cupom',
  'cupreo',
  'cupula',
  'cura',
  'curado',
  'curar',
  'curare',
  'curau',
  'curdo',
  'curia',
  'curie',
  'curio',
  'curra',
  'curral',
  'currar',
  'cursar',
  'cursivo',
  'curso',
  'cursor',
  'curta',
  'curtir',
  'curto',
  'curtos',
  'curva',
  'curvar',
  'curvas',
  'curvo',
  'cuscuz',
  'cuspe',
  'cuspir',
  'custa',
  'custar',
  'custas',
  'custe',
  'custo',
  'cutelo',
  'cutia',
  'cutis',
  'cv',
  'cw',
  'cx',
  'cy',
  'cz',
  'czar',
  'czarina',
  'd',
  'dablio',
  'dacha',
  'dacota',
  'dadiva',
  'dado',
  'dados',
  'dai',
  'daimio',
  'dali',
  'dalia',
  'dalton',
  'dama',
  'damas',
  'danado',
  'danar',
  'danca',
  'dancar',
  'dano',
  'danoso',
  'dantes',
  'daqui',
  'dar',
  'dardo',
  'darma',
  'das',
  'dasein',
  'data',
  'datar',
  'datcha',
  'dativo',
  'db',
  'dc',
  'dd',
  'ddd',
  'dddd',
  'de',
  'deao',
  'debaixo',
  'debate',
  'debil',
  'debito',
  'decada',
  'decair',
  'decano',
  'decidi',
  'decimo',
  'deco',
  'decorar',
  'decoro',
  'decote',
  'decurso',
  'dedal',
  'dedao',
  'dedar',
  'dedo',
  'dedos',
  'def',
  'defesa',
  'defumar',
  'degas',
  'degelo',
  'degola',
  'degrade',
  'degrau',
  'dei',
  'deitar',
  'deixa',
  'deixar',
  'deja',
  'dejeto',
  'dela',
  'delacao',
  'delas',
  'dele',
  'delfim',
  'delito',
  'delta',
  'demais',
  'demao',
  'demo',
  'demode',
  'demonio',
  'demora',
  'demover',
  'dende',
  'dengue',
  'denodo',
  'denso',
  'dental',
  'dente',
  'dentes',
  'dentre',
  'dentro',
  'depilar',
  'depois',
  'depor',
  'deque',
  'derby',
  'deriva',
  'derme',
  'des',
  'descer',
  'desde',
  'desdem',
  'desejo',
  'desenho',
  'design',
  'desova',
  'despir',
  'dessa',
  'desse',
  'desta',
  'deste',
  'destro',
  'desunir',
  'desuso',
  'desvio',
  'deter',
  'detido',
  'detras',
  'deu',
  'deum',
  'deus',
  'deusa',
  'deve',
  'dever',
  'devido',
  'devo',
  'devora',
  'devoto',
  'dez',
  'dezena',
  'dezoito',
  'df',
  'dg',
  'dh',
  'di',
  'dia',
  'diabo',
  'diacho',
  'dial',
  'diante',
  'diaria',
  'diario',
  'dias',
  'dica',
  'diccao',
  'didata',
  'diedro',
  'diesel',
  'dieta',
  'difusa',
  'difuso',
  'digito',
  'digno',
  'digo',
  'dilema',
  'dileto',
  'diluir',
  'dina',
  'dinamo',
  'dinar',
  'diodo',
  'dique',
  'direito',
  'direm',
  'direta',
  'direto',
  'discar',
  'disco',
  'dispar',
  'dispor',
  'disso',
  'distar',
  'disto',
  'dita',
  'ditado',
  'ditame',
  'ditar',
  'dito',
  'ditto',
  'diurno',
  'diva',
  'divida',
  'dividir',
  'divino',
  'divisa',
  'divisao',
  'dizer',
  'dizima',
  'dizimo',
  'dj',
  'dk',
  'dl',
  'dm',
  'dn',
  'do',
  'doacao',
  'doado',
  'doador',
  'doar',
  'dobra',
  'dobrar',
  'dobro',
  'doca',
  'doce',
  'doces',
  'docil',
  'docura',
  'dodo',
  'dodoi',
  'doenca',
  'doente',
  'doer',
  'dog',
  'doge',
  'dogma',
  'dogue',
  'doidao',
  'doido',
  'dois',
  'dolar',
  'dolby',
  'dolly',
  'dolmen',
  'dolo',
  'dolosa',
  'doloso',
  'dom',
  'domado',
  'domar',
  'dome',
  'domino',
  'domo',
  'dona',
  'donde',
  'dono',
  'dopado',
  'dopar',
  'doping',
  'dor',
  'dorido',
  'dormir',
  'dorsal',
  'dos',
  'dosar',
  'dose',
  'dossie',
  'dotado',
  'dotar',
  'dote',
  'dourar',
  'douto',
  'doutor',
  'doutro',
  'doze',
  'dp',
  'dq',
  'dr',
  'dracar',
  'dracma',
  'draga',
  'dragao',
  'dragar',
  'dragea',
  'drama',
  'drenar',
  'dreno',
  'driada',
  'driade',
  'drible',
  'drive',
  'driver',
  'droga',
  'drogar',
  'dropes',
  'druida',
  'druso',
  'ds',
  'dt',
  'du',
  'dual',
  'duas',
  'dubio',
  'dublar',
  'duble',
  'dubnio',
  'ducado',
  'ducal',
  'ducha',
  'ductil',
  'ducto',
  'duelar',
  'duelo',
  'duende',
  'dueto',
  'dulija',
  'dum',
  'duma',
  'duna',
  'dundum',
  'duo',
  'dupla',
  'duplex',
  'duplo',
  'duque',
  'dura',
  'durao',
  'durar',
  'dureza',
  'duro',
  'durou',
  'duvida',
  'duzia',
  'dv',
  'dw',
  'dx',
  'dy',
  'dz',
  'e',
  'eb',
  'ebano',
  'ebcdic',
  'ebrio',
  'eburneo',
  'ec',
  'eca',
  'ecg',
  'ecler',
  'eclusa',
  'eco',
  'ecoar',
  'eczema',
  'ed',
  'edema',
  'eden',
  'edicao',
  'edil',
  'edipo',
  'edital',
  'editar',
  'edito',
  'editor',
  'educar',
  'ee',
  'eee',
  'eeee',
  'ef',
  'efe',
  'efeito',
  'efendi',
  'efg',
  'eficaz',
  'efigie',
  'efusao',
  'eg',
  'egide',
  'ego',
  'egoismo',
  'egregio',
  'egua',
  'eh',
  'ei',
  'eia',
  'eira',
  'eis',
  'eivar',
  'eixo',
  'eixos',
  'ej',
  'ejecao',
  'ejetar',
  'ek',
  'el',
  'ela',
  'elas',
  'ele',
  'eleger',
  'elegia',
  'eleito',
  'elenco',
  'elepe',
  'elevar',
  'elipse',
  'elisao',
  'elite',
  'elixir',
  'elmo',
  'elo',
  'elogio',
  'em',
  'ema',
  'e-mail',
  'emanar',
  'embalo',
  'embate',
  'embolo',
  'embora',
  'eme',
  'emenda',
  'emfa',
  'emir',
  'emitir',
  'emocao',
  'empada',
  'empate',
  'empuxo',
  'emular',
  'en',
  'encaixe',
  'encher',
  'enduro',
  'ene',
  'enema',
  'enfado',
  'enfase',
  'enfermo',
  'enfiar',
  'enfim',
  'engano',
  'engaste',
  'engate',
  'engodo',
  'enguia',
  'enho',
  'enigma',
  'enjoar',
  'enjoo',
  'enlacar',
  'enlace',
  'enlevo',
  'enojar',
  'enol',
  'enorme',
  'enredo',
  'ensaio',
  'enseada',
  'ensejo',
  'ensino',
  'entao',
  'ente',
  'entoar',
  'entojo',
  'entorse',
  'entrar',
  'entre',
  'entulho',
  'enviar',
  'envio',
  'enxada',
  'enxame',
  'enxuto',
  'enzima',
  'eo',
  'eolico',
  'ep',
  'epica',
  'epico',
  'epoca',
  'epoxi',
  'epsom',
  'epura',
  'eq',
  'equino',
  'equipar',
  'equipe',
  'er',
  'era',
  'eram',
  'erario',
  'erbio',
  'erecao',
  'ereto',
  'erg',
  'ergo',
  'erguer',
  'erguido',
  'ericar',
  'erigir',
  'ermo',
  'erodido',
  'erodir',
  'eros',
  'erosao',
  'errado',
  'errar',
  'errata',
  'erre',
  'erro',
  'erros',
  'ersatz',
  'erupcao',
  'erva',
  'ervilha',
  'es',
  'esbelto',
  'esboco',
  'escada',
  'escala',
  'escama',
  'escape',
  'escoar',
  'escola',
  'escopa',
  'escopo',
  'escora',
  'escore',
  'escova',
  'escrava',
  'escudo',
  'escuna',
  'escura',
  'escuro',
  'escuta',
  'esfera',
  'esforco',
  'esgoto',
  'esguio',
  'eslavo',
  'esmero',
  'esmo',
  'esmola',
  'esnobe',
  'espaco',
  'espada',
  'espera',
  'espero',
  'espeto',
  'espiao',
  'espiar',
  'espiga',
  'espiral',
  'espora',
  'esposa',
  'esposo',
  'espuma',
  'esqui',
  'essa',
  'essas',
  'esse',
  'est',
  'esta',
  'estaca',
  'estacar',
  'estada',
  'estado',
  'estafa',
  'estalo',
  'estante',
  'estao',
  'estar',
  'estara',
  'estatal',
  'este',
  'esteio',
  'estepe',
  'ester',
  'esteta',
  'estiar',
  'estica',
  'estilo',
  'estima',
  'estio',
  'estiva',
  'estojo',
  'estopa',
  'estou',
  'estria',
  'estudo',
  'estufa',
  'esvair',
  'et',
  'eta',
  'etano',
  'etanol',
  'etapa',
  'etario',
  'etc',
  'eteno',
  'eter',
  'etereo',
  'eterno',
  'etica',
  'etico',
  'etiope',
  'etnia',
  'etnico',
  'etos',
  'eu',
  'eunuco',
  'eureca',
  'euro',
  'europa',
  'ev',
  'evadir',
  'evasao',
  'evasivo',
  'evento',
  'evitar',
  'evocar',
  'ew',
  'ex',
  'exalar',
  'exaltar',
  'exame',
  'exata',
  'exatas',
  'exato',
  'exceto',
  'excluir',
  'exibir',
  'exigir',
  'exiguo',
  'exilar',
  'exilio',
  'eximio',
  'eximir',
  'existe',
  'existem',
  'exito',
  'exodo',
  'expedir',
  'expert',
  'expiar',
  'expo',
  'expor',
  'extase',
  'externa',
  'extinto',
  'extra',
  'exu',
  'exumar',
  'ey',
  'ez',
  'f',
  'fab',
  'fabril',
  'fabula',
  'faca',
  'facada',
  'facao',
  'faccao',
  'face',
  'faceta',
  'facho',
  'facial',
  'facil',
  'factica',
  'facto',
  'fada',
  'fadado',
  'fadar',
  'fadiga',
  'fado',
  'fagote',
  'faina',
  'faisao',
  'faisca',
  'faixa',
  'faixas',
  'fajuto',
  'fala',
  'falado',
  'falador',
  'falar',
  'falcao',
  'falha',
  'falhar',
  'falho',
  'falico',
  'falida',
  'falido',
  'falir',
  'falo',
  'falou',
  'falsa',
  'falso',
  'falta',
  'faltar',
  'faltoso',
  'fama',
  'famoso',
  'fanho',
  'fao',
  'fapesp',
  'faquir',
  'farad',
  'farao',
  'farda',
  'fardao',
  'fardar',
  'fardo',
  'farelo',
  'faro',
  'farofa',
  'farol',
  'farpa',
  'farra',
  'farsa',
  'farsi',
  'fartar',
  'farto',
  'fase',
  'fases',
  'fastio',
  'fatal',
  'fatia',
  'fatiar',
  'fato',
  'fator',
  'fatos',
  'fatura',
  'fauna',
  'fauno',
  'fausto',
  'fava',
  'favela',
  'favo',
  'favor',
  'fax',
  'faxina',
  'faz',
  'fazer',
  'fb',
  'fc',
  'fd',
  'fe',
  'feb',
  'febem',
  'febrao',
  'febre',
  'febril',
  'fecal',
  'fechar',
  'fecho',
  'fecula',
  'feder',
  'fedido',
  'fedor',
  'feed',
  'feia',
  'feias',
  'feicao',
  'feijao',
  'feio',
  'feioso',
  'feira',
  'feita',
  'feitas',
  'feitio',
  'feito',
  'feitor',
  'feiura',
  'feixe',
  'fel',
  'felino',
  'feliz',
  'feltro',
  'femea',
  'femur',
  'fenda',
  'fender',
  'fenix',
  'feno',
  'fera',
  'feria',
  'ferias',
  'ferida',
  'ferido',
  'ferino',
  'ferir',
  'fermi',
  'fermio',
  'feroz',
  'ferrao',
  'ferrar',
  'ferrea',
  'ferreo',
  'ferro',
  'ferros',
  'ferry',
  'fertil',
  'ferver',
  'fervor',
  'festa',
  'festas',
  'festim',
  'fetal',
  'fetido',
  'feto',
  'feudal',
  'feudo',
  'fezes',
  'ff',
  'fff',
  'ffff',
  'fg',
  'fgh',
  'fh',
  'fi',
  'fia',
  'fiacao',
  'fiada',
  'fiado',
  'fiador',
  'fianca',
  'fiapo',
  'fiar',
  'fiasco',
  'fibra',
  'ficam',
  'ficar',
  'ficcao',
  'ficha',
  'fichar',
  'fico',
  'fiel',
  'fiesp',
  'fifa',
  'figa',
  'figado',
  'figo',
  'figura',
  'fila',
  'filao',
  'filar',
  'file',
  'fileira',
  'filha',
  'filho',
  'filial',
  'filiar',
  'filmar',
  'filme',
  'filmes',
  'filo',
  'filtrar',
  'filtro',
  'fim',
  'fina',
  'finado',
  'final',
  'finar',
  'fincar',
  'findar',
  'fineza',
  'fingido',
  'fingir',
  'finito',
  'fino',
  'finura',
  'fio',
  'fiorde',
  'fipe',
  'fiquei',
  'firma',
  'firmar',
  'firme',
  'firula',
  'fiscal',
  'fisco',
  'fisgar',
  'fisica',
  'fisico',
  'fissao',
  'fita',
  'fitar',
  'fivela',
  'fixa',
  'fixado',
  'fixar',
  'fixo',
  'fiz',
  'fj',
  'fk',
  'fl',
  'fla',
  'flagra',
  'flama',
  'flanco',
  'flap',
  'flash',
  'flauta',
  'flecha',
  'flerte',
  'fleuma',
  'flexao',
  'flexivo',
  'flexor',
  'floco',
  'flor',
  'flora',
  'floral',
  'florao',
  'florete',
  'florim',
  'florir',
  'fluido',
  'fluir',
  'fluor',
  'flush',
  'flute',
  'fluxo',
  'fm',
  'fn',
  'fo',
  'f.o.b.',
  'fobia',
  'fobias',
  'fobos',
  'foca',
  'focal',
  'foco',
  'fofo',
  'fofoca',
  'fofura',
  'fog',
  'fogaca',
  'fogao',
  'foge',
  'fogo',
  'fogos',
  'fogoso',
  'foi',
  'foice',
  'foie',
  'fole',
  'folego',
  'foles',
  'folga',
  'folgar',
  'folha',
  'folia',
  'foliao',
  'folico',
  'fome',
  'fonado',
  'fondue',
  'fone',
  'fonema',
  'fonfom',
  'fonte',
  'for',
  'fora',
  'foram',
  'forca',
  'forcar',
  'forcas',
  'forja',
  'forjar',
  'forma',
  'formal',
  'formao',
  'formar',
  'formas',
  'formol',
  'formou',
  'forno',
  'foro',
  'forra',
  'forrar',
  'forro',
  'forte',
  'forum',
  'fosco',
  'fossa',
  'fossil',
  'fosso',
  'foto',
  'foton',
  'foz',
  'fp',
  'fq',
  'fr',
  'fraca',
  'fracao',
  'fraco',
  'frade',
  'fragil',
  'fragor',
  'fralda',
  'franca',
  'franco',
  'frango',
  'franja',
  'frappe',
  'fraque',
  'frasal',
  'frasco',
  'frase',
  'fraude',
  'freada',
  'frear',
  'frei',
  'freio',
  'freira',
  'freire',
  'frenesi',
  'frente',
  'freon',
  'fresa',
  'fresar',
  'fresco',
  'fresta',
  'fretar',
  'frete',
  'frevo',
  'fria',
  'frieza',
  'frigido',
  'frigir',
  'frio',
  'frios',
  'frisa',
  'frisar',
  'fritar',
  'frito',
  'fronde',
  'fronha',
  'front',
  'fronte',
  'frota',
  'frouxo',
  'frufru',
  'frugal',
  'fruir',
  'fruta',
  'frutas',
  'fruto',
  'frutos',
  'fs',
  'ft',
  'fu',
  'fuba',
  'fuca',
  'fucar',
  'fucas',
  'fucsia',
  'fuga',
  'fugaz',
  'fugido',
  'fugir',
  'fuinha',
  'fujao',
  'fulano',
  'fulgor',
  'fulo',
  'fumaca',
  'fumar',
  'fume',
  'fumo',
  'funcao',
  'funda',
  'fundao',
  'fundar',
  'fundir',
  'fundo',
  'funesto',
  'fungar',
  'fungo',
  'funil',
  'furada',
  'furado',
  'furar',
  'furgao',
  'furia',
  'furias',
  'furo',
  'furor',
  'furtar',
  'furto',
  'fusa',
  'fusao',
  'fusca',
  'fuscao',
  'fusco',
  'fuso',
  'futil',
  'futuro',
  'fuxico',
  'fuzil',
  'fuzue',
  'fv',
  'fw',
  'fx',
  'fy',
  'fz',
  'g',
  'gabar',
  'gabiru',
  'gado',
  'gafe',
  'gag',
  'gaga',
  'gago',
  'gaiato',
  'gaijin',
  'gaiola',
  'gaita',
  'gajo',
  'gala',
  'galao',
  'gale',
  'galeao',
  'galego',
  'galera',
  'galeto',
  'galgar',
  'galgo',
  'galho',
  'galico',
  'galio',
  'galo',
  'galope',
  'galpao',
  'gama',
  'gamado',
  'gamao',
  'gamar',
  'gamba',
  'game',
  'gamela',
  'gameta',
  'gamo',
  'gana',
  'gancho',
  'ganglio',
  'ganhar',
  'ganho',
  'ganso',
  'garapa',
  'garbo',
  'garca',
  'garcom',
  'garfar',
  'garfo',
  'gari',
  'garoa',
  'garoar',
  'garota',
  'garoto',
  'garra',
  'gas',
  'gasoso',
  'gastao',
  'gastar',
  'gasto',
  'gata',
  'gato',
  'gatuno',
  'gaucho',
  'gaules',
  'gauss',
  'gaveta',
  'gaviao',
  'gaxeta',
  'gaze',
  'gazela',
  'gazeta',
  'gb',
  'gc',
  'gd',
  'ge',
  'geada',
  'gear',
  'geiser',
  'gel',
  'gelado',
  'gelar',
  'geleia',
  'gelido',
  'gelo',
  'gema',
  'gemada',
  'gemeo',
  'gemeos',
  'gemer',
  'gemido',
  'gene',
  'general',
  'genero',
  'genese',
  'gengiva',
  'genial',
  'genio',
  'genioso',
  'genoma',
  'genro',
  'gente',
  'gentil',
  'gentio',
  'geolog',
  'geral',
  'gerar',
  'gerir',
  'germe',
  'gesso',
  'gestao',
  'gesto',
  'gf',
  'gg',
  'ggg',
  'gggg',
  'gh',
  'ghi',
  'gi',
  'gibao',
  'gibi',
  'gigolo',
  'gilete',
  'gim',
  'ginete',
  'gira',
  'girafa',
  'girar',
  'giria',
  'girino',
  'girl',
  'giro',
  'giz',
  'gj',
  'gk',
  'gl',
  'glace',
  'gladio',
  'glande',
  'gleba',
  'glicol',
  'glide',
  'glifo',
  'global',
  'globo',
  'gloria',
  'glotal',
  'glote',
  'gluglu',
  'gluon',
  'glutao',
  'gluten',
  'gluteo',
  'gm',
  'gn',
  'gnomo',
  'gnose',
  'gnu',
  'go',
  'goela',
  'gogo',
  'goi',
  'goiaba',
  'goiano',
  'gol',
  'gola',
  'golaco',
  'gole',
  'golear',
  'goleiro',
  'golfada',
  'golfar',
  'golfe',
  'golfo',
  'golpe',
  'goma',
  'gomo',
  'gonada',
  'gongo',
  'gonzo',
  'gorar',
  'gorda',
  'gordio',
  'gordo',
  'gorila',
  'gorro',
  'gosma',
  'gospel',
  'gostar',
  'gosto',
  'gostou',
  'gota',
  'gotica',
  'gotico',
  'gouda',
  'gozado',
  'gozar',
  'gozo',
  'gp',
  'gq',
  'gr',
  'graal',
  'graca',
  'gracas',
  'grade',
  'grades',
  'gradil',
  'grafar',
  'grafema',
  'grafia',
  'gralha',
  'grama',
  'gramar',
  'grana',
  'grande',
  'granel',
  'grao',
  'gras',
  'grassar',
  'gratis',
  'grato',
  'grau',
  'gravar',
  'grave',
  'graxa',
  'graxo',
  'grega',
  'grego',
  'gregos',
  'grelha',
  'gremio',
  'greve',
  'grifar',
  'grifo',
  'grilar',
  'grilo',
  'gringo',
  'gripado',
  'gripal',
  'gripe',
  'grita',
  'gritar',
  'grito',
  'grogue',
  'grosa',
  'grossa',
  'grosso',
  'grua',
  'grudar',
  'grude',
  'grupal',
  'grupo',
  'grupos',
  'gruta',
  'gs',
  'gt',
  'gu',
  'guache',
  'guapo',
  'guara',
  'guarani',
  'guarda',
  'gude',
  'guei',
  'gueixa',
  'guelra',
  'guerra',
  'gueto',
  'guia',
  'guiado',
  'guiar',
  'guiche',
  'guidao',
  'guidom',
  'guincho',
  'guineu',
  'guisar',
  'guizo',
  'gula',
  'gulag',
  'guloso',
  'gume',
  'guri',
  'guru',
  'gusa',
  'gv',
  'gw',
  'gx',
  'gy',
  'gz',
  'h',
  'habil',
  'habito',
  'hacker',
  'hadron',
  'hafnio',
  'haia',
  'haicai',
  'halito',
  'hall',
  'halley',
  'halo',
  'haltere',
  'hand',
  'hangar',
  'hansa',
  'haras',
  'harem',
  'harpa',
  'harpia',
  'haste',
  'havana',
  'haver',
  'hb',
  'hc',
  'hd',
  'he',
  'hebreu',
  'hegira',
  'hein',
  'helice',
  'helio',
  'henry',
  'hera',
  'heranca',
  'herdar',
  'herege',
  'hernia',
  'heroi',
  'heroina',
  'herpes',
  'hertz',
  'hf',
  'hg',
  'hh',
  'hhh',
  'hhhh',
  'hi',
  'hiato',
  'hidra',
  'hiena',
  'hifen',
  'hij',
  'himen',
  'hinario',
  'hindu',
  'hino',
  'hipico',
  'hippie',
  'hit',
  'hitita',
  'hiv',
  'hj',
  'hk',
  'hl',
  'hm',
  'hn',
  'ho',
  'hobby',
  'hoje',
  'holismo',
  'holmio',
  'homem',
  'homens',
  'homilia',
  'homo',
  'homos',
  'homus',
  'honra',
  'honrar',
  'honras',
  'honroso',
  'hopi',
  'hoquei',
  'hora',
  'horario',
  'horas',
  'horda',
  'horror',
  'horta',
  'horto',
  'hostia',
  'hostil',
  'hot',
  'hotel',
  'hp',
  'hq',
  'hr',
  'hs',
  'ht',
  'hu',
  'hum',
  'humana',
  'humano',
  'humo',
  'humor',
  'humus',
  'huno',
  'huri',
  'hurra',
  'husky',
  'hutu',
  'hv',
  'hw',
  'hx',
  'hy',
  'hz',
  'i',
  'iaia',
  'ianque',
  'iansa',
  'iaque',
  'iara',
  'iataga',
  'iate',
  'ib',
  'ibis',
  'ic',
  'icar',
  'icone',
  'id',
  'ida',
  'idade',
  'ideal',
  'ideia',
  'idem',
  'idiche',
  'idilio',
  'idioma',
  'idiota',
  'ido',
  'idolo',
  'idoneo',
  'idos',
  'idoso',
  'ie',
  'ien',
  'iene',
  'if',
  'ig',
  'igapo',
  'igara',
  'iglu',
  'ignaro',
  'igneo',
  'ignicao',
  'igreja',
  'igual',
  'iguana',
  'ih',
  'ii',
  'iii',
  'iiii',
  'ij',
  'ijk',
  'ik',
  'il',
  'ilacao',
  'ilegal',
  'ileso',
  'ilha',
  'ilhar',
  'iliaco',
  'iludido',
  'iludir',
  'ilusao',
  'im',
  'ima',
  'imagem',
  'imbu',
  'imbuia',
  'imbuir',
  'imenso',
  'imerso',
  'imitar',
  'iml',
  'imolar',
  'imoral',
  'imovel',
  'impala',
  'impar',
  'impeto',
  'impio',
  'impor',
  'impune',
  'impuro',
  'imundo',
  'imune',
  'in',
  'inabil',
  'inacao',
  'inalar',
  'inapto',
  'inato',
  'inca',
  'incaico',
  'inchar',
  'inciso',
  'incolor',
  'incubo',
  'index',
  'indice',
  'indico',
  'indigo',
  'indio',
  'indole',
  'inepto',
  'inerte',
  'infame',
  'infiel',
  'infimo',
  'infixo',
  'inflar',
  'ingles',
  'ingua',
  'inhaca',
  'inhame',
  'inibir',
  'inicio',
  'iniquo',
  'injetar',
  'inning',
  'inocuo',
  'inovar',
  'input',
  'insano',
  'inseto',
  'insone',
  'instar',
  'insumo',
  'inte',
  'interim',
  'inti',
  'intimo',
  'intuir',
  'inutil',
  'invasor',
  'inveja',
  'inves',
  'io',
  'iodado',
  'iodar',
  'iodo',
  'iof',
  'ioga',
  'iogue',
  'ioio',
  'ion',
  'ionica',
  'ionico',
  'ioruba',
  'iota',
  'ip',
  'ipe',
  'iq',
  'ir',
  'ira',
  'irado',
  'irar',
  'iria',
  'iridio',
  'iris',
  'irma',
  'irmao',
  'irmaos',
  'ironia',
  'irra',
  'irreal',
  'ir-se',
  'is',
  'isbn',
  'isca',
  'isento',
  'isla',
  'iso',
  'isolar',
  'isopor',
  'isotopo',
  'iss',
  'isso',
  'istmo',
  'isto',
  'it',
  'italia',
  'item',
  'iterar',
  'itrio',
  'iu',
  'iuane',
  'iv',
  'iw',
  'ix',
  'iy',
  'iz',
  'j',
  'jaba',
  'jaburu',
  'jabuti',
  'jaca',
  'jacare',
  'jacta',
  'jacu',
  'jade',
  'jaguar',
  'jaina',
  'jaleco',
  'jamais',
  'jambo',
  'janela',
  'janota',
  'janta',
  'jantar',
  'japao',
  'japona',
  'jarda',
  'jardim',
  'jargao',
  'jarra',
  'jarro',
  'jasmim',
  'jato',
  'jaula',
  'java',
  'javali',
  'javel',
  'jaz',
  'jazer',
  'jazida',
  'jazigo',
  'jazz',
  'jb',
  'jc',
  'jd',
  'je',
  'jeca',
  'jegue',
  'jeitao',
  'jeito',
  'jejuar',
  'jejum',
  'jeova',
  'jerico',
  'jersei',
  'jesus',
  'jetom',
  'jf',
  'jg',
  'jh',
  'ji',
  'jiboia',
  'jihad',
  'jilo',
  'jingle',
  'jipe',
  'jj',
  'jjj',
  'jjjj',
  'jk',
  'jkl',
  'jl',
  'jm',
  'jn',
  'jo',
  'joana',
  'joao',
  'job',
  'joca',
  'jocoso',
  'joelho',
  'jogada',
  'jogado',
  'jogar',
  'jogo',
  'jogos',
  'jogral',
  'joia',
  'joio',
  'jojoba',
  'joquei',
  'jorge',
  'jornal',
  'jorrar',
  'jorro',
  'jota',
  'joule',
  'jovem',
  'jovial',
  'jp',
  'jq',
  'jr',
  'js',
  'jt',
  'ju',
  'juba',
  'jubilo',
  'judeu',
  'judia',
  'judiar',
  'judice',
  'judo',
  'judoca',
  'jugo',
  'jugular',
  'juiz',
  'juizo',
  'jujuba',
  'julgar',
  'julho',
  'juncao',
  'junco',
  'jungir',
  'junho',
  'junino',
  'junior',
  'junta',
  'juntar',
  'junto',
  'jura',
  'jurado',
  'jurar',
  'jure',
  'jurema',
  'juri',
  'juriti',
  'juro',
  'jururu',
  'jus',
  'justa',
  'justo',
  'juta',
  'juvenil',
  'jv',
  'jw',
  'jx',
  'jy',
  'jz',
  'k',
  'kb',
  'kc',
  'kd',
  'ke',
  'kf',
  'kg',
  'kh',
  'ki',
  'kj',
  'kk',
  'kkk',
  'kkkk',
  'kl',
  'klm',
  'km',
  'kn',
  'ko',
  'kp',
  'kq',
  'kr',
  'ks',
  'kt',
  'ku',
  'kv',
  'kw',
  'kx',
  'ky',
  'kz',
  'l',
  'labaro',
  'labia',
  'labial',
  'labio',
  'labios',
  'labor',
  'labuta',
  'labutar',
  'lacada',
  'lacaio',
  'lacar',
  'laco',
  'lacrar',
  'lacre',
  'lactar',
  'lactea',
  'lacteo',
  'lacuna',
  'ladino',
  'lado',
  'ladra',
  'ladrao',
  'ladrar',
  'ladroa',
  'lady',
  'lago',
  'lagoa',
  'laguna',
  'laia',
  'laico',
  'laje',
  'lajota',
  'lama',
  'lambda',
  'lamber',
  'lamela',
  'lamina',
  'laminar',
  'lanca',
  'lancar',
  'lance',
  'lancha',
  'lanche',
  'landau',
  'langor',
  'lanoso',
  'lapada',
  'lapao',
  'lapela',
  'lapide',
  'lapis',
  'lapso',
  'laque',
  'lar',
  'larga',
  'largar',
  'largo',
  'larva',
  'larval',
  'lasca',
  'lascar',
  'laser',
  'lasso',
  'lastro',
  'lata',
  'latao',
  'latex',
  'latido',
  'latim',
  'latino',
  'latir',
  'lato',
  'lauda',
  'laurea',
  'lauto',
  'lava',
  'lavabo',
  'lavada',
  'lavar',
  'lavra',
  'lavrado',
  'lavrar',
  'lazer',
  'lb',
  'lc',
  'ld',
  'le',
  'leal',
  'leao',
  'lebre',
  'ledo',
  'legacao',
  'legado',
  'legal',
  'legar',
  'legiao',
  'legivel',
  'legua',
  'legume',
  'lei',
  'leigo',
  'leilao',
  'leis',
  'leitao',
  'leite',
  'leito',
  'leitoa',
  'leitor',
  'lele',
  'lema',
  'leme',
  'lemure',
  'lenco',
  'lencol',
  'lenda',
  'lendea',
  'lenha',
  'lente',
  'lentes',
  'lento',
  'leoa',
  'leone',
  'lepido',
  'lepra',
  'lepton',
  'leque',
  'ler',
  'lerdo',
  'lerna',
  'lesado',
  'lesao',
  'lesar',
  'lesma',
  'leste',
  'letal',
  'letao',
  'letivo',
  'letra',
  'letras',
  'leu',
  'lev',
  'leva',
  'levado',
  'levar',
  'leve',
  'levedo',
  'leveza',
  'levita',
  'lexema',
  'lexico',
  'lf',
  'lg',
  'lh',
  'lhama',
  'lhe',
  'lhufas',
  'li',
  'liame',
  'libelo',
  'libero',
  'libido',
  'libio',
  'libra',
  'libre',
  'libreto',
  'licao',
  'liceu',
  'licito',
  'licor',
  'lida',
  'lidar',
  'lider',
  'liga',
  'ligada',
  'ligado',
  'ligar',
  'ligeiro',
  'light',
  'lilas',
  'lima',
  'limao',
  'limar',
  'limbo',
  'limiar',
  'limite',
  'limo',
  'limpa',
  'limpar',
  'limpeza',
  'limpo',
  'lince',
  'lindo',
  'linear',
  'linfa',
  'lingua',
  'linha',
  'linhas',
  'linho',
  'lipase',
  'liquen',
  'lira',
  'lirica',
  'lirico',
  'lirio',
  'lisa',
  'liso',
  'lisol',
  'lisp',
  'lista',
  'listar',
  'listas',
  'listra',
  'lisura',
  'litio',
  'litro',
  'lituano',
  'livido',
  'living',
  'livrar',
  'livre',
  'livro',
  'livros',
  'lixa',
  'lixao',
  'lixar',
  'lixeiro',
  'lixo',
  'lj',
  'lk',
  'll',
  'lll',
  'llll',
  'lm',
  'lmn',
  'ln',
  'lo',
  'lob',
  'lobby',
  'lobo',
  'lobulo',
  'locais',
  'local',
  'locao',
  'lockout',
  'locucao',
  'lodo',
  'log',
  'logica',
  'logico',
  'login',
  'logo',
  'logoff',
  'lograr',
  'logro',
  'loja',
  'lojista',
  'lombar',
  'lombo',
  'lona',
  'longa',
  'longe',
  'longo',
  'longos',
  'lontra',
  'loop',
  'loquaz',
  'lorde',
  'lorota',
  'lot',
  'lotar',
  'lote',
  'lotear',
  'loteca',
  'loto',
  'lotus',
  'louca',
  'louco',
  'loura',
  'louro',
  'louros',
  'lousa',
  'louvar',
  'louvor',
  'lp',
  'lq',
  'lr',
  'ls',
  'lt',
  'lu',
  'lua',
  'luar',
  'lucido',
  'lucio',
  'lucrar',
  'lucro',
  'lucros',
  'ludico',
  'ludo',
  'lugar',
  'luis',
  'lula',
  'lulu',
  'lume',
  'lumen',
  'lunar',
  'lundu',
  'luneta',
  'lupa',
  'lupulo',
  'lupus',
  'luso',
  'lustre',
  'lustro',
  'luta',
  'lutador',
  'lutar',
  'luto',
  'luva',
  'luvas',
  'lux',
  'luxacao',
  'luxar',
  'luxo',
  'luz',
  'lv',
  'lw',
  'lx',
  'ly',
  'lz',
  'm',
  'maca',
  'macaco',
  'macada',
  'macado',
  'macar',
  'machado',
  'macho',
  'macico',
  'maciez',
  'macio',
  'maco',
  'macom',
  'macudo',
  'macula',
  'madame',
  'madeira',
  'madre',
  'madura',
  'maduro',
  'mae',
  'ma-fe',
  'mafia',
  'magia',
  'magica',
  'magico',
  'magma',
  'magna',
  'magno',
  'mago',
  'magoa',
  'magoado',
  'magoar',
  'magos',
  'magras',
  'magro',
  'maia',
  'maio',
  'maior',
  'mais',
  'maisena',
  'major',
  'mal',
  'mala',
  'malaio',
  'malar',
  'maleta',
  'malha',
  'malhar',
  'malho',
  'maloca',
  'malote',
  'malta',
  'malte',
  'maltes',
  'maluco',
  'mama',
  'mamae',
  'mamao',
  'mamar',
  'mamata',
  'mambo',
  'mamilo',
  'mamona',
  'mamute',
  'mana',
  'manada',
  'mancal',
  'mancar',
  'mancha',
  'manchu',
  'manco',
  'mandao',
  'mandar',
  'mando',
  'mane',
  'manear',
  'manejo',
  'maneta',
  'manga',
  'mangar',
  'mangas',
  'mangue',
  'manha',
  'mania',
  'manjar',
  'mano',
  'mansao',
  'manso',
  'manta',
  'manter',
  'manto',
  'mantra',
  'manual',
  'mao',
  'maori',
  'maos',
  'mapa',
  'maqui',
  'mar',
  'maraca',
  'maraja',
  'marca',
  'marcar',
  'marcha',
  'marco',
  'mare',
  'mares',
  'marfim',
  'margem',
  'maria',
  'marido',
  'marina',
  'marinar',
  'marola',
  'maroto',
  'marrom',
  'marta',
  'marte',
  'martim',
  'martini',
  'martir',
  'marujo',
  'mas',
  'mascar',
  'maser',
  'massa',
  'mastim',
  'mastro',
  'mata',
  'matada',
  'matar',
  'match',
  'mate',
  'mater',
  'matina',
  'matine',
  'matiz',
  'mato',
  'matrio',
  'matriz',
  'matuto',
  'mau',
  'maus',
  'maxima',
  'maximo',
  'maxixe',
  'mazela',
  'mazorca',
  'mb',
  'mc',
  'md',
  'mdc',
  'me',
  'meada',
  'meado',
  'mece',
  'mecha',
  'media',
  'mediar',
  'medica',
  'medico',
  'medida',
  'medio',
  'medir',
  'medium',
  'medley',
  'medo',
  'medula',
  'medusa',
  'meeiro',
  'megera',
  'meia',
  'meigo',
  'meio',
  'meios',
  'meiose',
  'mel',
  'melaco',
  'melado',
  'melao',
  'melar',
  'mele',
  'meleca',
  'melhor',
  'meloso',
  'melro',
  'membro',
  'mencao',
  'menear',
  'meneio',
  'menina',
  'menino',
  'menir',
  'menor',
  'menora',
  'menos',
  'mensal',
  'menta',
  'mental',
  'mente',
  'mentir',
  'mentol',
  'mentor',
  'menu',
  'merce',
  'merito',
  'mero',
  'mes',
  'mesa',
  'mesada',
  'mesario',
  'mescal',
  'mescla',
  'meses',
  'mesma',
  'mesmo',
  'meson',
  'mestra',
  'mestre',
  'meta',
  'metade',
  'metais',
  'metal',
  'metano',
  'meter',
  'metida',
  'metido',
  'metila',
  'metodo',
  'metrica',
  'metro',
  'meu',
  'mexer',
  'mexida',
  'mexido',
  'mf',
  'mg',
  'mh',
  'mi',
  'miado',
  'miar',
  'miau',
  'mica',
  'micado',
  'miccao',
  'mico',
  'micose',
  'micro',
  'micron',
  'midi',
  'midia',
  'mig',
  'mignon',
  'migrar',
  'mil',
  'milha',
  'milhao',
  'milhar',
  'milho',
  'milico',
  'mim',
  'mimado',
  'mimar',
  'mimica',
  'mimico',
  'mimo',
  'mimosa',
  'mina',
  'minado',
  'minar',
  'minerio',
  'mingau',
  'mingua',
  'minha',
  'minhas',
  'minima',
  'minimo',
  'minucia',
  'minuta',
  'minuto',
  'miolo',
  'miope',
  'miopia',
  'mira',
  'mirar',
  'mirim',
  'mirra',
  'mirrar',
  'misero',
  'missa',
  'missal',
  'missao',
  'missil',
  'mista',
  'mister',
  'misto',
  'mitico',
  'mito',
  'mitose',
  'mitra',
  'miuda',
  'miudo',
  'miudos',
  'mixar',
  'mj',
  'mk',
  'ml',
  'mm',
  'mmc',
  'mmm',
  'mmmm',
  'mn',
  'mno',
  'mo',
  'moagem',
  'mobile',
  'moca',
  'mocada',
  'moco',
  'mocoto',
  'moda',
  'modal',
  'modas',
  'modelo',
  'modem',
  'modico',
  'modo',
  'modorra',
  'modos',
  'modulo',
  'moeda',
  'moedas',
  'moedor',
  'moela',
  'moenda',
  'moer',
  'mofado',
  'mofar',
  'mofo',
  'mogno',
  'moido',
  'moinho',
  'moises',
  'moita',
  'mol',
  'mola',
  'molar',
  'moldar',
  'molde',
  'moldura',
  'mole',
  'molejo',
  'moleza',
  'molhar',
  'molho',
  'momo',
  'moncao',
  'monge',
  'mongol',
  'montao',
  'montar',
  'monte',
  'montes',
  'mora',
  'morada',
  'morais',
  'moral',
  'morar',
  'mordaz',
  'morder',
  'mordomo',
  'morena',
  'moreno',
  'morgue',
  'mormon',
  'morno',
  'moroso',
  'morrer',
  'morro',
  'morsa',
  'morse',
  'morta',
  'mortal',
  'mortas',
  'morte',
  'mortem',
  'mortis',
  'morto',
  'mortos',
  'mosca',
  'mostra',
  'mote',
  'motel',
  'motete',
  'motim',
  'motivo',
  'moto',
  'motoca',
  'motor',
  'motora',
  'motriz',
  'mouco',
  'mouro',
  'mouse',
  'moveis',
  'movel',
  'movem',
  'mover',
  'mp',
  'mq',
  'mr',
  'ms',
  'mt',
  'mu',
  'muamba',
  'muar',
  'muarra',
  'mucama',
  'muco',
  'mucosa',
  'mucoso',
  'muda',
  'mudado',
  'mudar',
  'mudez',
  'mudo',
  'mufti',
  'mugido',
  'mugir',
  'muitas',
  'muito',
  'muitos',
  'mula',
  'mulata',
  'mulato',
  'muleta',
  'mulher',
  'mulo',
  'multa',
  'multar',
  'mumia',
  'mundial',
  'mundo',
  'munir',
  'muon',
  'muque',
  'murado',
  'mural',
  'murar',
  'murcho',
  'mureta',
  'muro',
  'murro',
  'murros',
  'musa',
  'museu',
  'musgo',
  'musica',
  'musico',
  'musse',
  'mutua',
  'mutuca',
  'mutuo',
  'mv',
  'mw',
  'mx',
  'my',
  'mz',
  'n',
  'nababo',
  'nabo',
  'nacao',
  'nacar',
  'nacela',
  'naco',
  'nacoes',
  'nada',
  'nadar',
  'nadega',
  'nadica',
  'nadinha',
  'nadir',
  'nado',
  'nafta',
  'nago',
  'naiade',
  'nailon',
  'naipe',
  'naja',
  'nalgum',
  'namoro',
  'nana',
  'nanar',
  'nanica',
  'nanico',
  'nanquim',
  'nao',
  'napa',
  'napalm',
  'narina',
  'nariz',
  'narrar',
  'narval',
  'nas',
  'nasa',
  'nasal',
  'nascer',
  'nata',
  'natal',
  'nativa',
  'nativo',
  'nato',
  'nau',
  'nausea',
  'navajo',
  'naval',
  'nave',
  'navio',
  'navios',
  'nazi',
  'nb',
  'nc',
  'nd',
  'ne',
  'neca',
  'necas',
  'nectar',
  'nefasto',
  'negar',
  'nego',
  'negra',
  'negro',
  'negus',
  'nele',
  'nelore',
  'nem',
  'nene',
  'nenem',
  'nenhum',
  'neon',
  'neonio',
  'nereida',
  'nervo',
  'nervos',
  'nervosa',
  'nescio',
  'nesga',
  'nessa',
  'nesse',
  'nesses',
  'neste',
  'net',
  'neta',
  'neto',
  'netuno',
  'neura',
  'neural',
  'neutra',
  'neutro',
  'nevado',
  'neve',
  'neves',
  'nevoa',
  'newton',
  'nexo',
  'nf',
  'ng',
  'nh',
  'nhoque',
  'ni',
  'niaia',
  'nicho',
  'nife',
  'nimbo',
  'ninar',
  'ninfa',
  'ninguem',
  'ninho',
  'niobio',
  'niple',
  'niquel',
  'nisa',
  'nisei',
  'nisso',
  'nisto',
  'nit',
  'nitido',
  'nitrato',
  'nitrilo',
  'nivel',
  'nivoso',
  'nj',
  'nk',
  'nl',
  'nm',
  'nn',
  'nnn',
  'nnnn',
  'no',
  'nobre',
  'nocao',
  'nocivo',
  'nodal',
  'nodoa',
  'noe',
  'noel',
  'noitada',
  'noite',
  'noiva',
  'noivar',
  'noivo',
  'nojo',
  'nomade',
  'nome',
  'nomeado',
  'nomear',
  'nomes',
  'nono',
  'nop',
  'nora',
  'norma',
  'normal',
  'normas',
  'norte',
  'nos',
  'nossa',
  'nosso',
  'nota',
  'notado',
  'notar',
  'notas',
  'nova',
  'novato',
  'nove',
  'novela',
  'novelo',
  'novena',
  'noves',
  'novica',
  'novico',
  'novo',
  'noz',
  'np',
  'nq',
  'nr',
  'ns',
  'nt',
  'nu',
  'nuanca',
  'nubente',
  'nubio',
  'nublado',
  'nublar',
  'nuca',
  'nuclear',
  'nucleo',
  'nudez',
  'nulo',
  'num',
  'numa',
  'numero',
  'numeros',
  'nunca',
  'nuncio',
  'nupcias',
  'nutrir',
  'nuvem',
  'nuvens',
  'nv',
  'nw',
  'nx',
  'ny',
  'nz',
  'o',
  'oab',
  'oasis',
  'ob',
  'oba',
  'obcecar',
  'obeso',
  'obito',
  'objeto',
  'oboe',
  'obolo',
  'obra',
  'obrar',
  'obras',
  'obs',
  'obstar',
  'obter',
  'obtuso',
  'obus',
  'obvio',
  'oc',
  'oca',
  'ocapi',
  'ocara',
  'ocaso',
  'oce',
  'oceano',
  'ocio',
  'ocioso',
  'oco',
  'ocre',
  'octal',
  'octano',
  'octeto',
  'ocular',
  'oculos',
  'oculto',
  'ocupar',
  'od',
  'ode',
  'odiar',
  'odio',
  'odioso',
  'odor',
  'oe',
  'oea',
  'oeste',
  'of',
  'ofegar',
  'ofensa',
  'oferta',
  'oficial',
  'oficio',
  'ofidico',
  'ofidio',
  'og',
  'ogiva',
  'ogum',
  'oh',
  'ohm',
  'oi',
  'oigale',
  'oit',
  'oitava',
  'oitavo',
  'oito',
  'oj',
  'ok',
  'ol',
  'ola',
  'olaria',
  'ole',
  'oleico',
  'oleiro',
  'oleo',
  'oleoso',
  'olfato',
  'olhada',
  'olhado',
  'olhar',
  'olhe',
  'olho',
  'olhos',
  'olimpo',
  'olmo',
  'olor',
  'om',
  'ombro',
  'ombros',
  'omega',
  'omisso',
  'omitir',
  'omolu',
  'on',
  'onca',
  'onda',
  'ondas',
  'onde',
  'onerar',
  'ong',
  'onibus',
  'onix',
  'ontem',
  'onu',
  'onus',
  'onze',
  'oo',
  'ooo',
  'oooo',
  'op',
  'opa',
  'opaco',
  'opala',
  'opcao',
  'open',
  'opep',
  'opera',
  'operar',
  'opinar',
  'opiniao',
  'opio',
  'opor',
  'oposta',
  'oposto',
  'opq',
  'optar',
  'optico',
  'opus',
  'oq',
  'or',
  'ora',
  'oracao',
  'orador',
  'oral',
  'orar',
  'orbita',
  'orca',
  'orcar',
  'ordem',
  'orelha',
  'orfao',
  'orgao',
  'orgia',
  'origem',
  'orion',
  'orixa',
  'orla',
  'ornar',
  'ornato',
  'os',
  'oscar',
  'osco',
  'osculo',
  'osmio',
  'osmose',
  'ossada',
  'ossea',
  'osseo',
  'osso',
  'ossudo',
  'ostra',
  'ot',
  'otario',
  'otica',
  'otico',
  'otimo',
  'otite',
  'ou',
  'oua',
  'ouricar',
  'ourico',
  'ouro',
  'ousado',
  'ousar',
  'out',
  'outono',
  'output',
  'outra',
  'outras',
  'outrem',
  'outro',
  'outros',
  'ouvi',
  'ouvido',
  'ouvir',
  'ov',
  'ova',
  'ovacao',
  'oval',
  'ovalar',
  'ovario',
  'ovelha',
  'over',
  'ovni',
  'ovo',
  'ovos',
  'ovulo',
  'ow',
  'ox',
  'oxala',
  'oxente',
  'oxidar',
  'oxido',
  'oxiuro',
  'oxossi',
  'oy',
  'oz',
  'ozonio',
  'p',
  'paca',
  'pacas',
  'pacato',
  'paco',
  'pacoca',
  'pacote',
  'pacto',
  'padrao',
  'padre',
  'paelha',
  'paete',
  'paga',
  'pagador',
  'pagao',
  'pagar',
  'pagina',
  'pagode',
  'pai',
  'painel',
  'paio',
  'paiol',
  'pairar',
  'pais',
  'paises',
  'paixao',
  'paje',
  'pajem',
  'pala',
  'palato',
  'palco',
  'paleta',
  'paleto',
  'palha',
  'paliar',
  'palido',
  'palito',
  'palma',
  'palmar',
  'palmas',
  'palmo',
  'palmos',
  'palpo',
  'palpos',
  'palrar',
  'pampa',
  'pampas',
  'panaca',
  'panama',
  'panca',
  'panda',
  'pane',
  'panela',
  'panico',
  'pano',
  'pao',
  'papa',
  'papada',
  'papado',
  'papai',
  'papaia',
  'papal',
  'papao',
  'papar',
  'papas',
  'papear',
  'papeis',
  'papel',
  'paper',
  'papila',
  'papiro',
  'papisa',
  'papo',
  'papua',
  'papudo',
  'par',
  'para',
  'parada',
  'parado',
  'parar',
  'parati',
  'parca',
  'parcial',
  'parco',
  'pardal',
  'pardo',
  'parede',
  'pareo',
  'paria',
  'parir',
  'paroco',
  'parque',
  'parsec',
  'partam',
  'parte',
  'partes',
  'partir',
  'parto',
  'parvo',
  'pascal',
  'pascoa',
  'pasmar',
  'pasmo',
  'passa',
  'passar',
  'passe',
  'passes',
  'passo',
  'passou',
  'pasta',
  'pastar',
  'pastel',
  'pasto',
  'pastor',
  'pastoso',
  'pata',
  'pataca',
  'patada',
  'patamar',
  'pataxo',
  'pate',
  'patela',
  'pateta',
  'patife',
  'patim',
  'patio',
  'pato',
  'patoa',
  'patota',
  'patrao',
  'patria',
  'patrio',
  'patroa',
  'patrono',
  'patua',
  'pau',
  'paulo',
  'paus',
  'pausa',
  'pausado',
  'pauta',
  'pautar',
  'pavao',
  'pave',
  'pavio',
  'pavoa',
  'pavor',
  'paxa',
  'paz',
  'pazada',
  'pazes',
  'pb',
  'pc',
  'pd',
  'pe',
  'peao',
  'peca',
  'pecado',
  'pecar',
  'pecas',
  'pecha',
  'pectina',
  'pedaco',
  'pedal',
  'pedida',
  'pedido',
  'pedir',
  'pedra',
  'pegada',
  'pegar',
  'pegaso',
  'peito',
  'peixe',
  'peixes',
  'pela',
  'pelada',
  'pelado',
  'pelar',
  'pelas',
  'pele',
  'pelego',
  'peleja',
  'pelica',
  'pelo',
  'pelos',
  'pelota',
  'peludo',
  'pelve',
  'pelvis',
  'pena',
  'penada',
  'penal',
  'penar',
  'penas',
  'penca',
  'pendao',
  'pender',
  'pendor',
  'pendulo',
  'penedo',
  'peneira',
  'penhor',
  'peniano',
  'penico',
  'penis',
  'penosa',
  'penoso',
  'pensao',
  'pensar',
  'pensil',
  'penso',
  'pente',
  'pepino',
  'pepita',
  'pequno',
  'per',
  'pera',
  'perda',
  'perdao',
  'perder',
  'perdiz',
  'perdoar',
  'pereba',
  'perene',
  'perfil',
  'perigo',
  'perito',
  'permuta',
  'perna',
  'pernas',
  'pernil',
  'peroba',
  'perola',
  'persa',
  'perseu',
  'perto',
  'peru',
  'perua',
  'peruar',
  'peruca',
  'pes',
  'pesa',
  'pesada',
  'pesado',
  'pesar',
  'pesca',
  'pescar',
  'peseta',
  'peso',
  'pessoa',
  'peste',
  'petala',
  'peteca',
  'petiz',
  'pezada',
  'pf',
  'pg',
  'ph',
  'philips',
  'pi',
  'pia',
  'piada',
  'piado',
  'piano',
  'piao',
  'piar',
  'pib',
  'pica',
  'picada',
  'picado',
  'picanha',
  'picape',
  'picar',
  'pichar',
  'piche',
  'pick-up',
  'picles',
  'pico',
  'picole',
  'picote',
  'pidao',
  'pidgin',
  'piegas',
  'pier',
  'pierro',
  'pifado',
  'pifar',
  'pigmeu',
  'pijama',
  'pila',
  'pilao',
  'pilar',
  'pilha',
  'pilhar',
  'piloro',
  'pilotar',
  'piloto',
  'pilula',
  'pimba',
  'pinca',
  'pincar',
  'pincel',
  'pinel',
  'pinga',
  'pingar',
  'pingo',
  'pinha',
  'pinhao',
  'pinho',
  'pino',
  'pinoia',
  'pinote',
  'pinta',
  'pintar',
  'pinto',
  'pintor',
  'pin-up',
  'pio',
  'piolho',
  'pior',
  'piora',
  'piorar',
  'pique',
  'pira',
  'pirado',
  'pirao',
  'pirar',
  'pirata',
  'pires',
  'pirex',
  'pirita',
  'piroca',
  'piroga',
  'pirua',
  'pis',
  'pisada',
  'pisar',
  'piscar',
  'piso',
  'pista',
  'pistao',
  'pistola',
  'pitada',
  'pitanga',
  'pitar',
  'pito',
  'piton',
  'pivete',
  'pivo',
  'pivotar',
  'pixaim',
  'pixel',
  'pixote',
  'pizza',
  'pj',
  'pk',
  'pl',
  'pla',
  'placa',
  'placar',
  'place',
  'plagio',
  'plaina',
  'planar',
  'plano',
  'planta',
  'plasma',
  'plato',
  'plebe',
  'plebeu',
  'pleito',
  'pleno',
  'plenos',
  'pleura',
  'plisse',
  'plotar',
  'plugar',
  'plugue',
  'pluma',
  'plural',
  'plutao',
  'pm',
  'pn',
  'pnb',
  'pneu',
  'po',
  'pobre',
  'pobres',
  'poca',
  'pocao',
  'poco',
  'poda',
  'podar',
  'pode',
  'podem',
  'poder',
  'podio',
  'podrao',
  'podre',
  'podres',
  'poe',
  'poeira',
  'poema',
  'poente',
  'poesia',
  'poeta',
  'pogrom',
  'pois',
  'polaca',
  'polaco',
  'polar',
  'polca',
  'polen',
  'polia',
  'polida',
  'polido',
  'polio',
  'polipo',
  'polir',
  'polo',
  'polpa',
  'poluir',
  'polux',
  'polvo',
  'pomada',
  'pomar',
  'pomba',
  'pombal',
  'pombas',
  'pombo',
  'pomo',
  'pompa',
  'pompom',
  'ponca',
  'ponche',
  'poncho',
  'ponei',
  'ponta',
  'pontas',
  'ponte',
  'ponto',
  'pontos',
  'poodle',
  'pool',
  'pop',
  'populi',
  'poquer',
  'por',
  'porao',
  'porca',
  'porcao',
  'porco',
  'porem',
  'porno',
  'poro',
  'poroso',
  'porque',
  'porra',
  'porre',
  'porreta',
  'por-se',
  'porta',
  'portal',
  'portao',
  'portar',
  'porte',
  'porto',
  'porvir',
  'posar',
  'pose',
  'posse',
  'posses',
  'posso',
  'post',
  'posta',
  'postal',
  'postar',
  'poste',
  'poster',
  'posto',
  'postos',
  'posudo',
  'pote',
  'potoca',
  'potro',
  'poucas',
  'pouco',
  'poucos',
  'poupar',
  'pousar',
  'pouso',
  'povao',
  'povinho',
  'povo',
  'povoar',
  'pp',
  'ppp',
  'pppp',
  'pq',
  'pqr',
  'pr',
  'pra',
  'praca',
  'prado',
  'praga',
  'praia',
  'pranto',
  'prata',
  'pratica',
  'prato',
  'pratos',
  'praxe',
  'prazer',
  'prazo',
  'prea',
  'prece',
  'preco',
  'precos',
  'predio',
  'prega',
  'pregao',
  'pregar',
  'prego',
  'preito',
  'prelo',
  'premer',
  'premie',
  'premio',
  'prenda',
  'prendas',
  'prenha',
  'prenhe',
  'prensa',
  'presa',
  'preso',
  'pressa',
  'presto',
  'preta',
  'preto',
  'pretor',
  'prever',
  'previa',
  'previo',
  'prezar',
  'prima',
  'primar',
  'primaz',
  'primo',
  'primor',
  'primos',
  'prior',
  'prisao',
  'prisma',
  'privar',
  'pro',
  'proa',
  'probo',
  'procon',
  'prodigo',
  'proeza',
  'prol',
  'prole',
  'prolog',
  'prom',
  'prompt',
  'pronto',
  'propina',
  'propor',
  'prosa',
  'proteu',
  'proton',
  'prova',
  'provar',
  'provas',
  'prover',
  'proveta',
  'provir',
  'prumo',
  'ps',
  'psi',
  'psique',
  'psit',
  'psiu',
  'pt',
  'pu',
  'pub',
  'pubere',
  'pubico',
  'pubis',
  'public',
  'pude',
  'pudera',
  'pudico',
  'pudim',
  'pudor',
  'pueril',
  'pufe',
  'puido',
  'puir',
  'pular',
  'pule',
  'pulga',
  'pulgao',
  'pulmao',
  'pulo',
  'pulsar',
  'pulso',
  'pum',
  'puma',
  'pumba',
  'puncao',
  'puncar',
  'punhal',
  'punho',
  'punico',
  'punir',
  'punk',
  'pura',
  'pure',
  'pureza',
  'purgar',
  'puro',
  'pus',
  'puta',
  'putsch',
  'puxa',
  'puxada',
  'puxado',
  'puxao',
  'puxar',
  'puxe',
  'puzzle',
  'pv',
  'pw',
  'px',
  'py',
  'pz',
  'q',
  'qb',
  'qc',
  'qd',
  'qe',
  'qf',
  'qg',
  'qh',
  'qi',
  'qj',
  'qk',
  'ql',
  'qm',
  'qn',
  'qo',
  'qp',
  'qq',
  'qqq',
  'qqqq',
  'qr',
  'qrs',
  'qs',
  'qt',
  'qu',
  'qua',
  'quadra',
  'quadro',
  'quais',
  'qual',
  'quando',
  'quanto',
  'quao',
  'quarar',
  'quark',
  'quarta',
  'quartel',
  'quarto',
  'quarup',
  'quasar',
  'quase',
  'quati',
  'quatro',
  'que',
  'quebra',
  'queda',
  'quedar',
  'quede',
  'queijo',
  'queima',
  'queixa',
  'queixar',
  'queixo',
  'quem',
  'quente',
  'quepe',
  'quer',
  'querer',
  'queria',
  'qui',
  'quiabo',
  'quibe',
  'quica',
  'quicar',
  'quiche',
  'quieto',
  'quilo',
  'quina',
  'quinta',
  'quinto',
  'quinze',
  'quiser',
  'quisto',
  'quitar',
  'quite',
  'quitute',
  'quo',
  'quorum',
  'quota',
  'qv',
  'qw',
  'qx',
  'qy',
  'qz',
  'r',
  'rabada',
  'rabano',
  'rabeca',
  'rabecao',
  'rabi',
  'rabino',
  'rabiola',
  'rabo',
  'raca',
  'racao',
  'racas',
  'racha',
  'rachar',
  'racial',
  'rad',
  'radar',
  'radial',
  'radiar',
  'radicar',
  'radio',
  'rafia',
  'ragu',
  'raia',
  'raiado',
  'raiar',
  'rainha',
  'raio',
  'raios',
  'raiva',
  'raiz',
  'raja',
  'rajada',
  'rajado',
  'ralar',
  'rale',
  'ralhar',
  'ralho',
  'rali',
  'ralo',
  'ram',
  'rama',
  'ramada',
  'ramado',
  'ramal',
  'ramela',
  'ramo',
  'ramos',
  'rampa',
  'rancho',
  'ranco',
  'rancor',
  'rangar',
  'ranger',
  'rango',
  'rap',
  'rapa',
  'rapar',
  'rapaz',
  'rape',
  'rapido',
  'rapina',
  'raposa',
  'raptar',
  'rapto',
  'raptor',
  'rarear',
  'raro',
  'rasar',
  'rasgao',
  'rasgar',
  'rasgo',
  'raso',
  'raspa',
  'raspao',
  'raspar',
  'rasto',
  'rastro',
  'rasura',
  'rata',
  'rateio',
  'rato',
  'ravina',
  'razao',
  'rb',
  'rc',
  'rd',
  're',
  'reaca',
  'reacao',
  'reagir',
  'real',
  'realce',
  'reatar',
  'reator',
  'reaver',
  'rebite',
  'reboco',
  'rebu',
  'recado',
  'recair',
  'recato',
  'recear',
  'receio',
  'recibo',
  'recife',
  'reco',
  'recuado',
  'recuar',
  'recuo',
  'recusa',
  'rede',
  'redea',
  'redigir',
  'redoma',
  'redor',
  'refem',
  'refere',
  'refil',
  'refino',
  'refrao',
  'refugo',
  'regaco',
  'regalo',
  'regar',
  'regata',
  'regato',
  'reger',
  'regiao',
  'regime',
  'regio',
  'rego',
  'regra',
  'regrar',
  'regras',
  'regua',
  'rei',
  'reich',
  'reinar',
  'reino',
  'reis',
  'reitor',
  'relar',
  'relato',
  'relaxo',
  'rele',
  'relegar',
  'reler',
  'reles',
  'relevo',
  'relha',
  'reluzir',
  'relva',
  'remar',
  'remate',
  'remela',
  'remido',
  'remir',
  'remo',
  'remocao',
  'remoer',
  'remora',
  'remoto',
  'rena',
  'renal',
  'renda',
  'render',
  'renio',
  'renome',
  'renque',
  'rente',
  'reparo',
  'repasse',
  'replay',
  'repleto',
  'repor',
  'reptil',
  'repuxo',
  'res',
  'resgate',
  'resina',
  'resma',
  'reso',
  'restar',
  'restia',
  'resto',
  'restos',
  'resumo',
  'reta',
  'retal',
  'reter',
  'retido',
  'retina',
  'retiro',
  'reto',
  'retrair',
  'retro',
  'retros',
  'reu',
  'reuniao',
  'reunir',
  'rever',
  'reves',
  'revide',
  'revoar',
  'reza',
  'rezado',
  'rezar',
  'rezas',
  'rf',
  'rg',
  'rh',
  'ri',
  'riacho',
  'ribeiro',
  'ricaco',
  'ricas',
  'ricino',
  'rico',
  'ricota',
  'rifa',
  'rifar',
  'rifle',
  'rigel',
  'rigido',
  'rigor',
  'rijo',
  'rim',
  'rima',
  'rimado',
  'rimar',
  'rimas',
  'rimel',
  'rincar',
  'rincho',
  'ringue',
  'rinite',
  'rinque',
  'rins',
  'rio',
  'rios',
  'rir',
  'risada',
  'risca',
  'riscar',
  'risco',
  'riscos',
  'riso',
  'rispido',
  'ritmar',
  'ritmo',
  'rito',
  'ritual',
  'rival',
  'rixa',
  'rj',
  'rk',
  'rl',
  'rm',
  'rn',
  'ro',
  'robalo',
  'robe',
  'robo',
  'roca',
  'rocar',
  'rocha',
  'rocim',
  'rock',
  'rococo',
  'roda',
  'rodada',
  'rodado',
  'rodape',
  'rodar',
  'rodas',
  'rodear',
  'rodeio',
  'rodela',
  'rodo',
  'roedor',
  'roer',
  'rogado',
  'rogar',
  'rogo',
  'rojao',
  'rol',
  'rola',
  'rolar',
  'roleta',
  'rolha',
  'rolico',
  'rolima',
  'rolo',
  'rom',
  'roma',
  'roman',
  'romana',
  'romano',
  'rombo',
  'romeno',
  'romper',
  'roncar',
  'ronco',
  'ronda',
  'rondar',
  'ronrom',
  'roque',
  'rosa',
  'rosado',
  'rosas',
  'rosca',
  'roseo',
  'rosnado',
  'rosnar',
  'rosto',
  'rota',
  'rotina',
  'rotor',
  'rotula',
  'rotulo',
  'roubar',
  'roubo',
  'rouco',
  'round',
  'roupa',
  'roupao',
  'roupas',
  'roxo',
  'royal',
  'rp',
  'rq',
  'rr',
  'rrr',
  'rrrr',
  'rs',
  'rst',
  'rt',
  'ru',
  'rua',
  'ruas',
  'rubi',
  'rubidio',
  'rublo',
  'rubor',
  'rubro',
  'ruco',
  'rucula',
  'rude',
  'rudeza',
  'rueiro',
  'ruela',
  'rufar',
  'rufiao',
  'rufo',
  'ruga',
  'rugbi',
  'ruge',
  'rugido',
  'rugir',
  'rugoso',
  'ruido',
  'ruim',
  'ruina',
  'ruinas',
  'ruins',
  'ruir',
  'ruivo',
  'rule',
  'rum',
  'rumar',
  'rumba',
  'rumo',
  'rumor',
  'runa',
  'runico',
  'rupia',
  'rural',
  'rusga',
  'rush',
  'russia',
  'russo',
  'rutilar',
  'rv',
  'rw',
  'rx',
  'ry',
  'rz',
  's',
  'sabado',
  'sabao',
  'saber',
  'sabia',
  'sabido',
  'sabio',
  'sabor',
  'sabre',
  'sabugo',
  'sabujo',
  'saca',
  'sacal',
  'sacana',
  'sacar',
  'sache',
  'saci',
  'saciar',
  'saco',
  'sacola',
  'sacro',
  'sadico',
  'sadio',
  'safa',
  'safado',
  'safar',
  'safari',
  'safena',
  'safira',
  'safra',
  'saga',
  'sagaz',
  'sagrar',
  'sagu',
  'saguao',
  'sagui',
  'saia',
  'saibro',
  'saida',
  'saido',
  'saiote',
  'sair',
  'sais',
  'sal',
  'sala',
  'salada',
  'salame',
  'salao',
  'saldar',
  'saldo',
  'saleta',
  'salgar',
  'salina',
  'salino',
  'saliva',
  'salmao',
  'salmo',
  'salsa',
  'salsao',
  'saltar',
  'salto',
  'saltos',
  'salva',
  'salvar',
  'salve',
  'salvo',
  'samba',
  'sambar',
  'sanar',
  'sancao',
  'sanear',
  'sangue',
  'sanha',
  'sansei',
  'santa',
  'santo',
  'sao',
  'sapato',
  'sape',
  'sapear',
  'sapeca',
  'sapo',
  'saque',
  'sarado',
  'sarar',
  'sarara',
  'sarau',
  'sarava',
  'sarda',
  'sardo',
  'sari',
  'sarna',
  'sarrafo',
  'sarro',
  'sata',
  'satira',
  'satiro',
  'saudar',
  'saude',
  'sauna',
  'saurio',
  'sauva',
  'savana',
  'sax',
  'saxao',
  'sazonal',
  'sb',
  'sc',
  'screen',
  'script',
  'sd',
  'se',
  'seara',
  'sebe',
  'sebo',
  'seca',
  'secante',
  'secao',
  'secar',
  'seco',
  'secos',
  'seculo',
  'secura',
  'seda',
  'sedan',
  'sedar',
  'sede',
  'sedoso',
  'seguem',
  'seguir',
  'seguro',
  'seio',
  'seis',
  'seita',
  'seiva',
  'seixo',
  'seja',
  'sela',
  'selado',
  'selar',
  'seleta',
  'seleto',
  'selim',
  'selo',
  'selva',
  'selvas',
  'sem',
  'sema',
  'semana',
  'semear',
  'semen',
  'seminu',
  'semita',
  'semola',
  'sempre',
  'sena',
  'senado',
  'senao',
  'senda',
  'sendo',
  'senha',
  'senhor',
  'senhora',
  'senil',
  'senior',
  'seno',
  'senso',
  'sensor',
  'sentar',
  'sentir',
  'sepala',
  'sepia',
  'septo',
  'sequaz',
  'sequer',
  'ser',
  'serao',
  'sereia',
  'serem',
  'sereno',
  'serial',
  'seriam',
  'serie',
  'serio',
  'sermao',
  'serra',
  'serrar',
  'serrote',
  'sertao',
  'serva',
  'serve',
  'servil',
  'servio',
  'servir',
  'servo',
  'sesamo',
  'sessao',
  'sesta',
  'set',
  'seta',
  'sete',
  'setima',
  'setimo',
  'setor',
  'seu',
  'seus',
  'severo',
  'sexo',
  'sexta',
  'sexto',
  'sexual',
  'sexy',
  'sf',
  'sg',
  'sh',
  'shalom',
  'sheik',
  'shevat',
  'short',
  'show',
  'si',
  'siames',
  'sicrano',
  'sida',
  'sidra',
  'sifao',
  'sigilo',
  'sigla',
  'siglas',
  'sigma',
  'signo',
  'signos',
  'silaba',
  'silex',
  'silfo',
  'silica',
  'silo',
  'silvo',
  'sim',
  'simio',
  'sina',
  'sinais',
  'sinal',
  'sincero',
  'sineta',
  'sinete',
  'sinha',
  'sinho',
  'sino',
  'sinodo',
  'sinos',
  'sinteco',
  'sinuca',
  'sir',
  'sirene',
  'siri',
  'sirio',
  'sisal',
  'sismo',
  'siso',
  'sisudo',
  'sitcom',
  'sitema',
  'sitiado',
  'sitiar',
  'sitio',
  'situar',
  'sivan',
  'sj',
  'sk',
  'sl',
  'slide',
  'slogan',
  'sm',
  'sn',
  'so',
  'soado',
  'soalho',
  'soante',
  'soar',
  'sob',
  'sobra',
  'sobrar',
  'sobras',
  'sobre',
  'sobrio',
  'socar',
  'social',
  'socio',
  'soco',
  'soda',
  'sodio',
  'sofa',
  'sofito',
  'sofrer',
  'sogra',
  'sogro',
  'soja',
  'sol',
  'sola',
  'solado',
  'solar',
  'solda',
  'soldar',
  'soldo',
  'solene',
  'solido',
  'solo',
  'soltar',
  'soluco',
  'solver',
  'som',
  'soma',
  'somali',
  'somar',
  'sombra',
  'sonante',
  'sonar',
  'sonata',
  'sonda',
  'sondar',
  'soneca',
  'soneto',
  'sonhar',
  'sonho',
  'sonico',
  'sono',
  'sonora',
  'sonoro',
  'sons',
  'sonso',
  'sopa',
  'sopapo',
  'sope',
  'soprar',
  'sopro',
  'soro',
  'soror',
  'sorrir',
  'sorte',
  'sorver',
  'sos',
  's.o.s.',
  'sosia',
  'soslaio',
  'sotao',
  'sou',
  'sova',
  'sovaco',
  'sovado',
  'sovar',
  'sovina',
  'sp',
  'spa',
  'spaniel',
  'spin',
  'spot',
  'spray',
  'spread',
  'sprint',
  'sq',
  'sr',
  'ss',
  'sss',
  'ssss',
  'st',
  'staff',
  'stand',
  'star',
  'status',
  'stf',
  'stress',
  'stu',
  'su',
  'sua',
  'suadar',
  'suado',
  'suaili',
  'suar',
  'suas',
  'suave',
  'sub',
  'subida',
  'subir',
  'subita',
  'subito',
  'sucata',
  'succao',
  'sucia',
  'sucio',
  'suco',
  'sucre',
  'sucubo',
  'sucuri',
  'sudam',
  'sudene',
  'sudito',
  'sueco',
  'sueter',
  'suey',
  'sufixar',
  'sufixo',
  'sufle',
  'sufoco',
  'sugar',
  'suicas',
  'suico',
  'suino',
  'suite',
  'sujar',
  'sujo',
  'sul',
  'sulcar',
  'sulco',
  'sulino',
  'sultana',
  'sultao',
  'sum',
  'suma',
  'sumico',
  'sumido',
  'sumir',
  'sumo',
  'sumula',
  'sunab',
  'sundae',
  'sunga',
  'sunita',
  'suor',
  'supino',
  'supor',
  'suprir',
  'sura',
  'surata',
  'surdez',
  'surdo',
  'surfar',
  'surfe',
  'surgir',
  'surra',
  'surrar',
  'sursis',
  'surtir',
  'surto',
  'suruba',
  'sushi',
  'sustar',
  'suster',
  'susto',
  'sutia',
  'sutil',
  'sutra',
  'sutura',
  'sv',
  'sw',
  'swing',
  'sx',
  'sy',
  'sz',
  't',
  'taba',
  'tabaco',
  'tabefe',
  'tabela',
  'tabu',
  'tabua',
  'tabuada',
  'taca',
  'tacada',
  'tacape',
  'tacar',
  'tachar',
  'tacho',
  'tacito',
  'taco',
  'tafeta',
  'tai',
  'taiga',
  'tainha',
  'tal',
  'tala',
  'talamo',
  'talao',
  'talco',
  'talha',
  'talhar',
  'talher',
  'talho',
  'taliao',
  'talio',
  'talo',
  'talvez',
  'tamara',
  'tambem',
  'tambor',
  'tamil',
  'tamoio',
  'tampa',
  'tampao',
  'tampar',
  'tanga',
  'tango',
  'tanque',
  'tanta',
  'tantalo',
  'tanto',
  'tantra',
  'tao',
  'tao-so',
  'tapa',
  'tapado',
  'tapajo',
  'tapar',
  'tapear',
  'tapera',
  'tapete',
  'tapir',
  'tapume',
  'tara',
  'tarado',
  'tarar',
  'tardar',
  'tarde',
  'tardio',
  'tarefa',
  'tarefas',
  'tarifa',
  'taro',
  'tarugo',
  'tascar',
  'tatame',
  'tatear',
  'tatica',
  'tato',
  'tatu',
  'tatuar',
  'taverna',
  'tavola',
  'taxa',
  'taxado',
  'taxar',
  'taxi',
  'tb',
  'tc',
  'tcha',
  'tchau',
  'tcheco',
  'td',
  'te',
  'tear',
  'teaser',
  'teatro',
  'tecela',
  'tecer',
  'tecido',
  'tecla',
  'teclar',
  'teclas',
  'tecnica',
  'tedio',
  'teflon',
  'teia',
  'teima',
  'teimar',
  'teipe',
  'teismo',
  'teista',
  'teixo',
  'tela',
  'telao',
  'telex',
  'telha',
  'telhado',
  'telugu',
  'tem',
  'tema',
  'temas',
  'temer',
  'temido',
  'temor',
  'tempao',
  'templo',
  'tempo',
  'tenaz',
  'tenda',
  'tendao',
  'tender',
  'tenho',
  'tenia',
  'tenis',
  'tenor',
  'tenro',
  'tensao',
  'tenso',
  'tentar',
  'tentei',
  'tento',
  'tenue',
  'teor',
  'teoria',
  'tepido',
  'ter',
  'terbio',
  'terca',
  'terco',
  'tercol',
  'tergal',
  'termal',
  'termas',
  'termo',
  'termos',
  'terno',
  'terra',
  'terras',
  'terreo',
  'terror',
  'tesao',
  'tese',
  'tesla',
  'teso',
  'testa',
  'testar',
  'teste',
  'teta',
  'tetano',
  'teto',
  'tetra',
  'teu',
  'teutao',
  'teuto',
  'teve',
  'texano',
  'textil',
  'texto',
  'texugo',
  'tez',
  'tf',
  'tg',
  'th',
  'ti',
  'tia',
  'tiara',
  'tibia',
  'ticao',
  'ticar',
  'tietar',
  'tiete',
  'tifo',
  'tigela',
  'tigre',
  'tigres',
  'tijolo',
  'til',
  'tilia',
  'timaco',
  'timao',
  'timbira',
  'timbre',
  'time',
  'timer',
  'times',
  'timido',
  'timing',
  'tina',
  'tiner',
  'tingir',
  'tinha',
  'tinido',
  'tinir',
  'tino',
  'tinta',
  'tintim',
  'tinto',
  'tio',
  'tipao',
  'tipico',
  'tipo',
  'tipoia',
  'tipos',
  'tique',
  'tira',
  'tirada',
  'tirado',
  'tirano',
  'tirar',
  'tiro',
  'tiros',
  'tishri',
  'tisica',
  'tisico',
  'tita',
  'titere',
  'titia',
  'titica',
  'tititi',
  'titulo',
  'tive',
  'tj',
  'tk',
  'tl',
  'tm',
  'tn',
  'to',
  'toada',
  'toalha',
  'toante',
  'toar',
  'toboga',
  'toca',
  'tocaia',
  'tocar',
  'tocata',
  'tocha',
  'toco',
  'toda',
  'todas',
  'todo',
  'todos',
  'tofe',
  'tofu',
  'toga',
  'toldo',
  'tolher',
  'tolice',
  'tolo',
  'tom',
  'toma',
  'tomada',
  'tomar',
  'tomara',
  'tomate',
  'tombar',
  'tombo',
  'tomo',
  'tonal',
  'tonel',
  'toner',
  'tonica',
  'tonico',
  'tonta',
  'tonto',
  'topada',
  'topar',
  'topete',
  'topico',
  'topo',
  'toque',
  'tora',
  'toranja',
  'torax',
  'torcao',
  'torcer',
  'torio',
  'tornado',
  'tornar',
  'tornear',
  'torno',
  'toro',
  'torpe',
  'torpor',
  'torque',
  'torrao',
  'torrar',
  'torre',
  'torso',
  'torta',
  'torto',
  'tosa',
  'tosado',
  'tosar',
  'tosco',
  'tosse',
  'tossir',
  'tostao',
  'tostar',
  'total',
  'totem',
  'toto',
  'touca',
  'touro',
  'toxico',
  'toxina',
  'tp',
  'tq',
  'tr',
  'trabuco',
  'traca',
  'tracao',
  'tracar',
  'traco',
  'tracos',
  'tragar',
  'trago',
  'traido',
  'trair',
  'traira',
  'trajar',
  'traje',
  'trajes',
  'tralha',
  'trama',
  'tramar',
  'trampo',
  'tranca',
  'tranco',
  'transa',
  'transe',
  'trapo',
  'tras',
  'traste',
  'tratam',
  'tratar',
  'trato',
  'trator',
  'trauma',
  'trava',
  'travar',
  'trave',
  'traves',
  'trazer',
  'tre',
  'trecho',
  'treco',
  'tregua',
  'treino',
  'trela',
  'trem',
  'trema',
  'tremer',
  'tremor',
  'trena',
  'treno',
  'trepar',
  'tres',
  'treta',
  'treva',
  'trevas',
  'trevo',
  'treze',
  'trf',
  'tri',
  'triade',
  'triar',
  'tribal',
  'tribo',
  'tribos',
  'trico',
  'trienal',
  'trigo',
  'trilha',
  'trilho',
  'trinar',
  'trinca',
  'trinco',
  'trinta',
  'trintao',
  'trio',
  'tripa',
  'tripas',
  'tripe',
  'triplo',
  'triste',
  'tritao',
  'troar',
  'troca',
  'trocar',
  'troco',
  'trofeu',
  'troia',
  'troica',
  'tromba',
  'trompa',
  'tronco',
  'trono',
  'tropa',
  'tropel',
  'trotar',
  'trote',
  'trouxa',
  'trova',
  'trovao',
  'trucar',
  'truco',
  'trufa',
  'trunfo',
  'trupe',
  'truque',
  'truta',
  'ts',
  'tt',
  'ttt',
  'tttt',
  'tu',
  'tuba',
  'tubo',
  'tubos',
  'tucano',
  'tudo',
  'tufao',
  'tufo',
  'tuiste',
  'tule',
  'tulio',
  'tulipa',
  'tumba',
  'tumor',
  'tumulo',
  'tunda',
  'tundra',
  'tunel',
  'tunica',
  'tupa',
  'tupi',
  'turba',
  'turco',
  'turfe',
  'turma',
  'turne',
  'turno',
  'turrao',
  'turvar',
  'turvo',
  'tussa',
  'tutano',
  'tutela',
  'tutor',
  'tutsi',
  'tutu',
  'tuv',
  'tv',
  'tw',
  'tx',
  'ty',
  'tz',
  'u',
  'uai',
  'uau',
  'ub',
  'ubere',
  'uc',
  'ucp',
  'ud',
  'ue',
  'uf',
  'ufa',
  'ufanar',
  'ufania',
  'ufo',
  'ug',
  'ugrico',
  'uh',
  'ui',
  'uisque',
  'uiste',
  'uivar',
  'uivo',
  'uj',
  'uk',
  'ul',
  'ulcera',
  'ulema',
  'ultima',
  'ultimo',
  'ulular',
  'um',
  'uma',
  'umbanda',
  'umbigo',
  'ume',
  'umero',
  'umido',
  'umlaut',
  'un',
  'uncao',
  'uncial',
  'unesco',
  'ungido',
  'ungir',
  'unha',
  'unhada',
  'unhar',
  'uniao',
  'unico',
  'unido',
  'unidos',
  'unir',
  'uno',
  'uns',
  'untar',
  'uo',
  'up',
  'upa',
  'upload',
  'uq',
  'ur',
  'uranio',
  'urano',
  'urbana',
  'urbano',
  'urdir',
  'ureia',
  'ureter',
  'uretra',
  'urico',
  'urina',
  'urinar',
  'urinol',
  'urna',
  'urrar',
  'urro',
  'ursa',
  'urso',
  'urtiga',
  'urubu',
  'uruca',
  'urupe',
  'us',
  'usa',
  'usado',
  'usados',
  'usar',
  'usavel',
  'usei',
  'usina',
  'usinar',
  'uso',
  'usp',
  'usual',
  'usura',
  'ut',
  'utero',
  'uti',
  'util',
  'utopia',
  'uu',
  'uuu',
  'uuuu',
  'uv',
  'uva',
  'uvula',
  'uvular',
  'uvw',
  'uw',
  'ux',
  'uy',
  'uz',
  'v',
  'vaca',
  'vacas',
  'vacina',
  'vacuo',
  'vadia',
  'vadiar',
  'vadio',
  'vaga',
  'vagao',
  'vagar',
  'vagem',
  'vagina',
  'vago',
  'vai',
  'vaia',
  'vaiar',
  'vaivem',
  'vala',
  'valao',
  'valdir',
  'vale',
  'valer',
  'valeta',
  'valete',
  'valia',
  'valido',
  'valise',
  'valor',
  'valsa',
  'valsar',
  'vampe',
  'vao',
  'vapor',
  'vara',
  'varal',
  'varao',
  'varar',
  'vareio',
  'varejo',
  'vareta',
  'variar',
  'varias',
  'vario',
  'variz',
  'varrer',
  'varzea',
  'vaso',
  'vasto',
  'vatapa',
  'vaza',
  'vazado',
  'vazao',
  'vazar',
  'vazia',
  'vazio',
  'vb',
  'vc',
  'vd',
  've',
  'veado',
  'veda',
  'vedado',
  'vedar',
  'vedete',
  'vedico',
  'vega',
  'veia',
  'veio',
  'veja',
  'vela',
  'velado',
  'velar',
  'velcro',
  'velha',
  'velho',
  'velhos',
  'veloz',
  'veludo',
  'vem',
  'venal',
  'vencer',
  'venda',
  'vendar',
  'vendas',
  'vender',
  'veneno',
  'veneta',
  'venia',
  'venoso',
  'venta',
  'ventar',
  'ventas',
  'vento',
  'ventral',
  'ventre',
  'venus',
  'ver',
  'verao',
  'verba',
  'verbal',
  'verbo',
  'verbos',
  'verde',
  'verdor',
  'vereda',
  'vergao',
  'vergar',
  'verme',
  'vermute',
  'verniz',
  'versao',
  'ver-se',
  'verso',
  'versus',
  'verter',
  'verve',
  'vesgo',
  'vespa',
  'vesper',
  'vestal',
  'veste',
  'vestir',
  'vetar',
  'veto',
  'vetor',
  'veu',
  'vexado',
  'vexame',
  'vexar',
  'vez',
  'vezes',
  'vf',
  'vg',
  'vh',
  'vi',
  'via',
  'viacao',
  'viaduto',
  'viagem',
  'viajar',
  'viario',
  'viavel',
  'vibora',
  'vibrar',
  'vicejo',
  'viciar',
  'vicio',
  'vicios',
  'vico',
  'vicoso',
  'vida',
  'vidao',
  'video',
  'vidrar',
  'vidro',
  'vidros',
  'viela',
  'vies',
  'viga',
  'vigia',
  'vigiar',
  'vigor',
  'vil',
  'vila',
  'vilao',
  'vime',
  'vinco',
  'vinda',
  'vingar',
  'vinha',
  'vinho',
  'vinil',
  'vino',
  'vinte',
  'vintem',
  'viola',
  'violado',
  'violao',
  'violar',
  'vip',
  'vir',
  'vira',
  'virada',
  'virado',
  'viral',
  'virar',
  'virgem',
  'viril',
  'virose',
  'virus',
  'visado',
  'visao',
  'visar',
  'visita',
  'visor',
  'vista',
  'visto',
  'visual',
  'vital',
  'vitela',
  'vitima',
  'vitral',
  'vitreo',
  'vitro',
  'viuva',
  'viuvez',
  'viuvo',
  'viva',
  'vivaz',
  'vive',
  'viver',
  'viveu',
  'vivido',
  'vivo',
  'vizir',
  'vj',
  'vk',
  'vl',
  'vm',
  'vn',
  'vo',
  'voador',
  'voar',
  'vocal',
  'voce',
  'vodca',
  'vodu',
  'voga',
  'vogal',
  'vogar',
  'voile',
  'volante',
  'volei',
  'voleio',
  'volt',
  'volta',
  'voltar',
  'volume',
  'volver',
  'vomer',
  'vomito',
  'voo',
  'voraz',
  'vos',
  'vossa',
  'vosso',
  'votado',
  'votar',
  'voto',
  'vou',
  'vovo',
  'vox',
  'voyeur',
  'voz',
  'vozes',
  'vp',
  'vq',
  'vr',
  'vs',
  'vt',
  'vu',
  'vulcao',
  'vulgar',
  'vulgo',
  'vulto',
  'vulva',
  'vupt',
  'vv',
  'vvv',
  'vvvv',
  'vw',
  'vwx',
  'vx',
  'vy',
  'vz',
  'w',
  'wb',
  'wc',
  'wd',
  'we',
  'wf',
  'wg',
  'wh',
  'wi',
  'wj',
  'wk',
  'wl',
  'wm',
  'wn',
  'wo',
  'wp',
  'wq',
  'wr',
  'ws',
  'wt',
  'wu',
  'wv',
  'ww',
  'www',
  'wwww',
  'wx',
  'wxy',
  'wy',
  'wz',
  'x',
  'xador',
  'xadrez',
  'xale',
  'xama',
  'xampu',
  'xango',
  'xarope',
  'xaual',
  'xaxado',
  'xaxim',
  'xb',
  'xc',
  'xd',
  'xe',
  'xepa',
  'xeque',
  'xereta',
  'xerez',
  'xerife',
  'xerocar',
  'xerox',
  'xf',
  'xg',
  'xh',
  'xi',
  'xicara',
  'xiita',
  'xingar',
  'xingo',
  'xinto',
  'xinxim',
  'xis',
  'xisto',
  'xixi',
  'xj',
  'xk',
  'xl',
  'xm',
  'xn',
  'xo',
  'xodo',
  'xogum',
  'xp',
  'xq',
  'xr',
  'xs',
  'xt',
  'xu',
  'xua',
  'xucro',
  'xv',
  'xw',
  'xx',
  'xxx',
  'xxxx',
  'xy',
  'xyz',
  'xz',
  'y',
  'yb',
  'yc',
  'yd',
  'ye',
  'yf',
  'yg',
  'yh',
  'yi',
  'yj',
  'yk',
  'yl',
  'ym',
  'yn',
  'yo',
  'yp',
  'yq',
  'yr',
  'ys',
  'yt',
  'yu',
  'yv',
  'yw',
  'yx',
  'yy',
  'yyy',
  'yyyy',
  'yz',
  'z',
  'zaga',
  'zangao',
  'zanzar',
  'zarpar',
  'zas',
  'zb',
  'zc',
  'zd',
  'ze',
  'zebra',
  'zebu',
  'zefir',
  'zefiro',
  'zelar',
  'zelo',
  'zeloso',
  'zen',
  'zenite',
  'zerado',
  'zerar',
  'zero',
  'zeta',
  'zeugma',
  'zf',
  'zg',
  'zh',
  'zi',
  'zigoma',
  'zigoto',
  'zinco',
  'ziper',
  'zippado',
  'zj',
  'zk',
  'zl',
  'zm',
  'zn',
  'zo',
  'zoada',
  'zoar',
  'zoeira',
  'zombar',
  'zona',
  'zonado',
  'zonal',
  'zonear',
  'zonzo',
  'zoo',
  'zoom',
  'zorra',
  'zp',
  'zq',
  'zr',
  'zs',
  'zt',
  'zu',
  'zulu',
  'zum',
  'zumbi',
  'zumbir',
  'zunido',
  'zunir',
  'zunzum',
  'zureta',
  'zurrar',
  'zv',
  'zw',
  'zx',
  'zy',
  'zz',
  'zzz',
  'zzzz',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
  '60',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '69',
  '70',
  '71',
  '72',
  '73',
  '74',
  '75',
  '76',
  '77',
  '78',
  '79',
  '80',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '88',
  '89',
  '90',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '97',
  '98',
  '99',
  '100',
  '101',
  '111',
  '123',
  '200',
  '222',
  '234',
  '300',
  '333',
  '345',
  '400',
  '444',
  '456',
  '500',
  '555',
  '567',
  '600',
  '666',
  '678',
  '700',
  '777',
  '789',
  '800',
  '888',
  '900',
  '999',
  '1000',
  '1111',
  '1234',
  '1492',
  '1500',
  '1600',
  '1700',
  '1776',
  '1800',
  '1812',
  '1900',
  '1910',
  '1920',
  '1925',
  '1930',
  '1935',
  '1940',
  '1945',
  '1950',
  '1955',
  '1960',
  '1965',
  '1970',
  '1975',
  '1980',
  '1985',
  '1990',
  '1991',
  '1992',
  '1993',
  '1994',
  '1995',
  '1996',
  '1997',
  '2000',
  '2001',
  '2020',
  '2222',
  '2345',
  '2468',
  '3000',
  '3333',
  '3456',
  '4000',
  '4321',
  '4444',
  '4567',
  '5000',
  '5555',
  '5678',
  '6000',
  '6666',
  '6789',
  '7000',
  '7777',
  '8000',
  '8888',
  '9000',
  '9876',
  '9999',
  '!',
  '!!',
  '"',
  '#',
  '##',
  '$',
  '$$',
  '%',
  '%%',
  '&',
  '(',
  '()',
  ')',
  '*',
  '**',
  '+',
  '-',
  ':',
  ';',
  '=',
  '?',
  '??',
  '@',
];
